export const STATUS_CODE_GRAPHQL = 400;
export const STATUS_CODE_AUTH = 401;
export const STATUS_CODE_ACCESS = 403;
export const STATUS_CODE_NOT_FOUND = 404;
export const STATUS_CODE_CONFLICT = 409;
export const STATUS_CODE_NETWORK = 1000;
export const STATUS_CODE_UNKNOWN = 1001;

export const mutationErrors = {
  login: { [STATUS_CODE_AUTH]: 'Неверный логин или пароль' },
  logOut: {
    [STATUS_CODE_GRAPHQL]:
      'Возникла ошибка при выходе из системы. Перезагрузите страницу'
  }
};

/**
 * @typedef ParsedError
 * @property error {string}
 * @property statusCode {number}
 * @property message {string}
 */

/**
 * Парсит ошибку GraphQL, пытаясь достать ошибку, возвращенную бекендом
 * Бекенд возвращает ошибку в виде json-строки, содержащей statusCode и message
 * Если ошибка не с бекенда, то возвращается объект у которого есть хотя бы message
 * и statusCode в худшем случае STATUS_CODE_UNKNOWN
 * Если какие-то проблемы с сетью, то возвращает STATUS_CODE_NETWORK
 *
 * Если переданная ошибка не определяется, как ошибка graphql, то просто возвращает ее
 *
 * @return {ParsedError|any}
 */
export function getGraphQLErrorInfo(error) {
  let graphQLErrors;
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    graphQLErrors = error.graphQLErrors;
  } else if (
    error.networkError &&
    error.networkError.result &&
    error.networkError.result.errors
  ) {
    graphQLErrors = error.networkError.result.errors;
  }

  if (graphQLErrors && graphQLErrors.length > 0) {
    try {
      return {
        ...error.networkError,
        ...JSON.parse(graphQLErrors[0].message)
      };
    } catch (e) {
      return {
        statusCode: STATUS_CODE_UNKNOWN,
        ...error.networkError,
        ...graphQLErrors[0]
      };
    }
  }

  if (error.networkError) {
    if (error.networkError.statusCode) {
      return {
        ...error.networkError,
        message: error.networkError.bodyText
      };
    }

    return {
      error: error.networkError,
      statusCode: STATUS_CODE_NETWORK
    };
  }

  return error;
}

/**
 * Возвращает текст ошибки для отображения пользователю
 * @param parsedError {ParsedError|any}
 */
export function getParsedErrorMessage(parsedError) {
  if (!parsedError) return null;
  if (typeof parsedError !== 'object') return parsedError + '';
  const codeMessage = getErrorCodeMessage(parsedError.statusCode);
  if (codeMessage) {
    return codeMessage;
  }
  const errorMessage = parsedError.message;
  if (errorMessage) {
    if (typeof errorMessage !== 'object') {
      return errorMessage + '';
    }
    return 'Произошла ошибка: ' + JSON.stringify(errorMessage);
  }
  return 'Произошла ошибка: ' + JSON.stringify(parsedError.error);
}

/**
 * Возвращает общеупотребимые сообщения ошибок
 * @param statusCode {number}
 * @returns {string|null}
 */
export function getErrorCodeMessage(statusCode) {
  switch (statusCode) {
    case STATUS_CODE_AUTH:
      return 'Требуется авторизация';
    case STATUS_CODE_ACCESS:
      return 'У вас недостаточно прав для выполнения данного действия';
    case STATUS_CODE_NOT_FOUND:
      return 'Не найдено';
    case STATUS_CODE_NETWORK:
      return 'Ошибка сети. Проверьте свой доступ к интернету';
    case STATUS_CODE_CONFLICT:
      return 'Конфликт. Такой документ уже существует';
    default:
      return null;
  }
}

/**
 * Позволяет выдать кастомное сообщение об ошибке по совпадению statusCode
 * @param parsedError {ParsedError} - ошибка
 * @param overrides {object} - объект кастомных сообщений вида {[code]: message}
 */
export function overrideErrorMessage(parsedError, overrides = null) {
  if (overrides) {
    const overrideMessage = overrides[parsedError.statusCode];
    if (overrideMessage) {
      return overrideMessage;
    }
  }
  return getParsedErrorMessage(parsedError);
}

/**
 * Возвращает стандартное либо кастомное сообщение об ошибке
 * @param error - ошибка graphql
 * @param overrides {object} - объект кастомных сообщений вида {[code]: message}
 * @returns {string}
 */
export function getGraphqlErrorMessage(error, overrides = null) {
  const parsedError = getGraphQLErrorInfo(error);
  return overrideErrorMessage(parsedError, overrides);
}
