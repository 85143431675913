import React, { useCallback } from 'react';
import classNames from 'classnames';
import DonutPieChart from '../../../common/charts/DonutPieChart';
import { DonutChartWrapper } from './Donuts';
import s from './ProjectsChartView.module.scss';
import color from '../../../colors.scss';

export const getDonutPositions = (adaptiveRatio) => [
  {
    size: 348 * adaptiveRatio,
    outerRadius: (348 * adaptiveRatio) / 2,
    innerRadius: (308 * adaptiveRatio) / 2,
    color: s.chartLightBlue,
    endColor: s.chartLightBlue2L
  },
  {
    size: 300 * adaptiveRatio,
    outerRadius: (300 * adaptiveRatio) / 2,
    innerRadius: (260 * adaptiveRatio) / 2,
    color: color.chartBlue2,
    endColor: s.chartBlue2L
  },
  {
    size: 260 * adaptiveRatio,
    outerRadius: (240 * adaptiveRatio) / 2,
    innerRadius: (232 * adaptiveRatio) / 2,
    color: color.chartBlue2,
    endColor: s.chartBlue2L
  }
];

const dashProps = {
  strokeDasharray: '6,6',
  isDash: true,
  dashColor: color.chartDashLine
};

export function DonutsSubject({
  className,
  selectedChartView,
  pies,
  adaptiveRatio,
  activeId,
  onSegmentClick,
  onMainChartClick,
  isChartViewOpen
}) {
  const chartPositions = getDonutPositions(adaptiveRatio);
  const handleSegmentClick = useCallback(
    (id) => onSegmentClick(id === activeId ? null : id),
    [activeId, onSegmentClick]
  );
  const handleDonutPartClick = (id) => () => onMainChartClick({ chart: id });

  return (
    <div className={classNames(s.ChartView__donutPieCharts, className)}>
      {/* 0) Голубой */}
      <DonutChartWrapper isShown={true}>
        <DonutPieChart
          gapAngle={1}
          {...pies[0]}
          {...chartPositions[0]}
          activeId={activeId}
          onPathClick={
            isChartViewOpen
              ? handleSegmentClick
              : handleDonutPartClick('federal')
          }
        />
      </DonutChartWrapper>
      {/* 1) Синий */}
      <DonutChartWrapper isShown={true}>
        <DonutPieChart
          gapAngle={1}
          onPathClick={
            isChartViewOpen
              ? handleSegmentClick
              : handleDonutPartClick('federal')
          }
          {...pies[1]}
          {...chartPositions[1]}
          activeId={activeId}
        />
      </DonutChartWrapper>
      {/* 2) Синий тонкий */}
      <DonutChartWrapper isShown={true}>
        <DonutPieChart
          gapAngle={1}
          onPathClick={
            isChartViewOpen
              ? handleSegmentClick
              : handleDonutPartClick('federal')
          }
          {...dashProps}
          {...pies[2]}
          {...chartPositions[2]}
          activeId={activeId}
        />
      </DonutChartWrapper>
    </div>
  );
}
