import { useCallback, useMemo } from 'react';
import { useUrlQuery } from './useUrlQuery';
import { useFactoryRef } from './useFactoryRef';

export function useSort(initialState) {
  const firstInitialStateRef = useFactoryRef(() => initialState);
  const [query, changeQuery] = useUrlQuery();
  const sort = useMemo(
    () => ({
      direction: query.direction || firstInitialStateRef.current.direction,
      indicator: query.indicator || firstInitialStateRef.current.indicator
    }),
    [query.direction, query.indicator, firstInitialStateRef]
  );

  const setSort = useCallback(
    (e) => {
      changeQuery(
        {
          direction: e.direction,
          indicator: e.indicator
        },
        true
      );
    },
    [changeQuery]
  );

  return {
    sort,
    setSort
  };
}
