/**
 * Фильтр результатов поиска
 * @param query - поисковый запрос
 * @param array - массив который фильтруем
 * @param field - свойство массива (если есть) с именем
 */
export function filterSearch(query, array, field = null) {
  if (!query) return array ? array : [];
  if (!array) return [];

  const keywords = query
    .toLowerCase()
    .split(' ')
    .filter((x) => x);
  if (keywords.length === 0) return array;
  return array.filter((object) => {
    let item;
    if (field) {
      if (typeof field === 'string') {
        item = object[field];
      } else if (typeof field === 'function') {
        item = field(object);
      } else {
        throw new Error(
          `Argument 'field' should be either string or function. Got ${typeof field}`
        );
      }
    } else {
      item = object;
    }
    let index = 0;
    let newIndex;
    let find = false;
    for (const keyword of keywords) {
      newIndex = item.toLowerCase().indexOf(keyword, index);
      if (newIndex >= index) {
        index = newIndex;
        find = true;
      } else {
        find = false;
        break;
      }
    }
    return find;
  });
}
