import gql from 'graphql-tag';

export const MUTATION_FINANCIAL_REPORT_DOCUMENT_TOKEN = gql`
  mutation createReferenceFinancialDocumentOneTimeToken {
    createReferenceFinancialDocumentOneTimeToken
  }
`;

export const MUTATION_INTERNET_REPORT_DOCUMENT_TOKEN = gql`
  mutation createReferenceInternetDocumentOneTimeToken {
    createReferenceInternetDocumentOneTimeToken
  }
`;

export const MUTATION_SUBJECT_REPORT_DOCUMENT_TOKEN = gql`
  mutation createReferenceSubjectsDocumentOneTimeToken {
    createReferenceSubjectsDocumentOneTimeToken
  }
`;

export const MUTATION_RESULTS_REPORT_DOCUMENT_TOKEN = gql`
  mutation createReferenceResultsDocumentOneTimeToken {
    createReferenceResultsDocumentOneTimeToken
  }
`;

export const MUTATION_OPERATIONAL_REPORT_DOCUMENT_TOKEN = gql`
  mutation createReferenceOperationalDocumentOneTimeToken {
    createReferenceOperationalDocumentOneTimeToken
  }
`;

export const MUTATION_REFERENCE_REPORT_DOCUMENT_TOKEN = gql`
  mutation createReferenceReportDocumentOneTimeToken {
    createReferenceReportDocumentOneTimeToken
  }
`;

export const MUTATION_REFERENCE_EVENT_REPORT_DOCUMENT_TOKEN = gql`
  mutation createReferenceEventsReportDocumentOneTimeToken {
    createReferenceEventsReportDocumentOneTimeToken
  }
`;

export const MUTATION_INFOGRAPHICS_EVENT_REPORT_DOCUMENT_TOKEN = gql`
  mutation createReferenceInfographicsPdfDocumentOneTimeToken {
    createReferenceInfographicsPdfDocumentOneTimeToken
  }
`;
