import React, { useCallback, useMemo, useState } from 'react';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { pickBy } from 'lodash-es';
import { TopBarProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { SwipeBox } from '../../common/SwipeBox/SwipeBox';
import { FlyingButton } from '../../common/FlyingBox/FlyingBox';
import { queryLoader } from '../../common/QueryLoader';
import { ReactHelmet } from '../../common/ReactHelmet';
import { MapPageLayout } from '../_layouts/MapPageLayout';
import { useFPRoadmapQuery } from '../../store/useRoadmaps';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { useRatingAchievement, useRegion } from '../../store/useRegions';
import { useWindowSize } from '../../store/useWindowSize';
import { useSubsidiaryEntitiesForMap } from '../../store/useSubsidiaryEntities';
import { useBudgetChartRpBase } from '../IndicatorsPage/useBudgetChartRp';
import { QUERY_SEARCH_ORGANIZATIONS } from '../../graphql/queries/organization';
import {
  URL_QUERY_INDICATOR_TYPE,
  URL_QUERY_ORGANIZATION_TYPE,
  URL_QUERY_RES
} from '../../store/stores';
import { RegionMap } from '../../common/RegionMap/RegionMap';
import ErrorPage from '../../common/ErrorPage';
import MapView from '../../common/Map/MapView/MapView';
import { MapFiltersPopup } from '../../common/Map/MapFiltersPopup/MapFiltersPopup';
import { TopBar } from '../../common/TopBar/TopBar';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import {
  getInternetColor,
  percentageToColor
} from '../../common/Map/CommonMap/utils';
import { tryNumber } from '../../utils/math';
import { SubsidiaryEntity } from '../../data/subsidiaryEntity';
import { EMDASH } from '../../utils/utf';
import {
  INDICATOR_INTERNET_SPEED,
  INDICATOR_RESULT_ACH,
  INDICATOR_TARGET_ACH,
  SPEED_TYPE_COUNTRYSIDE
} from '../../utils/indicators';
import { getFirst } from '../../utils/data';
import { formatDateIso } from '../../utils/date';
import SubjectView from './SubjectView/SubjectView';
import { mediaWidthTabletLand } from '../../_vars.scss';
import colors from '../../colors.scss';
import s from '../../common/Map/MapView/MapView.module.scss';

const organizationsOptions = {
  query: QUERY_SEARCH_ORGANIZATIONS,
  mapData: (results) => results.organizations.list,
  skipQuery: skipIfNull(null)
};

function SubjectPage({ match, location, history }) {
  const [isFiltersOpen, setIsFilterOpen] = useState(false);
  const [queryParams, changeQuery] = useUrlQuery();
  const preserved = usePreserveQueryFast(queryParams, [
    URL_QUERY_INDICATOR_TYPE,
    URL_QUERY_ORGANIZATION_TYPE,
    URL_QUERY_RES
  ]);
  const { codeNalog } = match.params;
  const { year, yearMonth, month } = useQueryPeriodFast(
    queryParams,
    changeQuery
  );
  const { indicator_type, speed_type, fp } = queryParams;
  const rangeMin = tryNumber(queryParams.range_min, 0);
  const rangeMax = tryNumber(queryParams.range_max, 100);

  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const [rightOpened, setRightOpened] = useState(false);

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  const selectedFpCode = +queryParams.fp;

  const regionQuery = useRegion(codeNalog, {
    averageSpeed: {
      yearMonths: [yearMonth],
      organizations_filter: {
        is_rural: speed_type === SPEED_TYPE_COUNTRYSIDE,
        federal_project_codes: selectedFpCode
          ? [selectedFpCode + '']
          : undefined
      }
    }
  });
  const region = regionQuery.state.data;

  const { res, roadmap } = queryParams;

  const { createLink } = preserved;
  const onNavigateToObject = useCallback(
    ({ id }) =>
      history.push(
        createLink(roadmap ? `/entities/${id}` : `/organizations/${id}`)
      ),
    [history, createLink, roadmap]
  );

  const projects = useMemo(
    () =>
      region &&
      region.regional_projects.map((rp) => rp.federal_project).filter((x) => x),
    [region]
  );

  const pickedFederalProject = useMemo(
    () =>
      selectedFpCode > 0 &&
      projects &&
      projects.find((fp) => +fp.project_code === +selectedFpCode),
    [projects, selectedFpCode]
  );

  const rqVars = useMemo(
    () => ({
      federal_project_id: pickedFederalProject ? pickedFederalProject.id : null,
      region_code_nalog: codeNalog,
      years: year
    }),
    [codeNalog, pickedFederalProject, year]
  );

  const dcCompletionFilter = useMemo(
    () => ({
      year,
      region_codes: codeNalog,
      federal_project_codes: selectedFpCode ? [selectedFpCode + ''] : undefined,
      date: formatDateIso(new Date())
    }),
    [codeNalog, selectedFpCode, year]
  );

  const roadmapQuery = useFPRoadmapQuery(
    rqVars,
    {
      month: yearMonth,
      budget_levels: ['subject'],
      region_code_nalogs: codeNalog
    },
    dcCompletionFilter
  );

  const organizationTypeId = queryParams.organization_type || 'ALL';

  const filter = useMemo(
    () =>
      pickBy(
        {
          region_ids: region && region.id,
          // has_coordinates: true,
          bs_organization_type_okved_ids:
            organizationTypeId !== 'ALL' && organizationTypeId,
          federal_project_codes: selectedFpCode
            ? selectedFpCode + ''
            : undefined,
          result_ids: res ? res.split(',') : undefined,
          roadmap_ids: roadmap ? [roadmap] : undefined,
          roadmap_year: roadmap && year,
          is_rural: speed_type === SPEED_TYPE_COUNTRYSIDE,
          internet_speed_filter:
            indicator_type === INDICATOR_INTERNET_SPEED ? {} : undefined
        },
        (a) => a
      ),
    [
      region,
      organizationTypeId,
      selectedFpCode,
      res,
      roadmap,
      year,
      speed_type,
      indicator_type
    ]
  );

  const objectsQuery = useGraphqlQuery({
    queryOptions: organizationsOptions,
    variables:
      region && !roadmap
        ? {
            input: {
              filter
            },
            averageSpeed: {
              yearMonths: [yearMonth]
            }
          }
        : null
  });

  const subsidiaryEntitiesForMapQuery = useSubsidiaryEntitiesForMap(
    roadmap
      ? {
          year: year + '',
          region_ids: region?.id,
          owner_roadmap_ids: roadmap,
          federal_project_codes: selectedFpCode
            ? selectedFpCode + ''
            : undefined
        }
      : null
  );
  const subsidiaryEntities = useMemo(
    () =>
      subsidiaryEntitiesForMapQuery.state.data?.map((subsidiaryEntity) => ({
        id: subsidiaryEntity.id,
        bs_organization_type_okved_id:
          subsidiaryEntity.base_organization?.bs_organization_type_okved_id,
        bs_latitude:
          subsidiaryEntity.latitude ||
          subsidiaryEntity.base_organization?.bs_latitude ||
          '',
        bs_longitude:
          subsidiaryEntity.longitude ||
          subsidiaryEntity.base_organization?.bs_longitude ||
          '',
        name_full: SubsidiaryEntity.getCompleteName(subsidiaryEntity)
      })),
    [subsidiaryEntitiesForMapQuery.state.data]
  );

  const grbs = queryParams.grbs;
  const budgetsFilter = useMemo(
    () =>
      region
        ? {
            regionIds: [region.id],
            federalProjectCodes: selectedFpCode
              ? [selectedFpCode + '']
              : undefined,
            grbsCodes: grbs && grbs !== 'all' ? [grbs] : undefined
          }
        : null,
    [region, selectedFpCode, grbs]
  );
  const budgetCharts = useBudgetChartRpBase({
    yearMonth,
    filter: budgetsFilter
  });

  const achievementQuery = useRatingAchievement(
    region
      ? {
          yearMonth,
          federalProjectCodes: selectedFpCode
            ? [selectedFpCode + '']
            : undefined,
          regionIds: [region.id]
        }
      : null
  );

  const [regionAchievement, achievementActualityDate] = useMemo(() => {
    const achievementRatingData = achievementQuery.state.data;
    if (!achievementRatingData) {
      return [{}, {}];
    }

    const target_ach = getFirst(achievementRatingData.indicatorsRatings)?.value;
    const result_ach = getFirst(achievementRatingData.resultsRatings)?.value;
    const targetAchActualityDate =
      achievementRatingData.indicatorsRatingActualityDate;
    const resultAchActualityDate =
      achievementRatingData.resultsRatingActualityDate;

    return [
      {
        target_ach: target_ach,
        result_ach: result_ach
      },
      {
        [INDICATOR_TARGET_ACH]: targetAchActualityDate,
        [INDICATOR_RESULT_ACH]: resultAchActualityDate
      }
    ];
  }, [achievementQuery.state.data]);

  const mapColor = useMemo(() => {
    if (!budgetCharts) return null;

    const { criteria } = budgetCharts;
    let color = colors.gray;
    if (!indicator_type || indicator_type === 'none') {
      color = colors.success;
    } else if (indicator_type === INDICATOR_INTERNET_SPEED) {
      color = getInternetColor(
        region?.average_internet_speed,
        speed_type,
        rangeMin,
        rangeMax
      );
    } else if (
      indicator_type === INDICATOR_TARGET_ACH ||
      indicator_type === INDICATOR_RESULT_ACH
    ) {
      const indicatorValue = regionAchievement[indicator_type];
      color = percentageToColor(
        indicatorValue,
        rangeMin,
        rangeMax,
        indicator_type
      );
    } else if (criteria) {
      const indicatorValue = criteria[indicator_type];
      color = percentageToColor(
        indicatorValue,
        rangeMin,
        rangeMax,
        indicator_type
      );
    }
    return color;
  }, [
    budgetCharts,
    indicator_type,
    region,
    speed_type,
    rangeMin,
    rangeMax,
    regionAchievement
  ]);

  if (regionQuery.check.error) {
    throw regionQuery.state.error;
  }

  if (!regionQuery.check.spinner && !region) {
    return <ErrorPage text="404" description="Регион не найден" />;
  }

  const roadmapCards = roadmapQuery.state.data;
  const objects = objectsQuery.state.data;

  const districtId =
    region &&
    (region.federal_district.code === '9' // Крымского федерального округа давно нет
      ? '3'
      : region.federal_district.code);

  return (
    <MapPageLayout className="_with-column">
      <ReactHelmet title={region ? region.name : null} />
      <TopBar>
        <TopBarProjects projects={projects} replaceQuery={true} />
      </TopBar>
      <MapView
        cards={roadmapCards}
        changeQuery={changeQuery}
        history={history}
        onOpenFilter={() => setIsFilterOpen(true)}
        preserved={preserved}
        queryParams={queryParams}
        region={region}
        titleText={region ? region.name : EMDASH}
        isSubjectView={true}
        actualityDate={achievementActualityDate[indicator_type]}
        onGoBack={
          districtId
            ? () => history.goBack(createLink(`/districts/${districtId}`))
            : () => history.goBack()
        }
        overlayClassName={s._subject}
        isMobile={
          ws.window.innerWidth < parseFloat(mediaWidthTabletLand) ||
          ws.window.innerHeight < parseFloat(s.mediaHeightTabletLand)
        }
      >
        {queryLoader(regionQuery) ||
          queryLoader(objectsQuery) ||
          queryLoader(subsidiaryEntitiesForMapQuery) || (
            <RegionMap
              className={s.MapView__regionMap}
              code={region && region.code_nalog}
              region={region}
              objects={roadmap ? subsidiaryEntities : objects}
              onNavigateToObject={onNavigateToObject}
              color={mapColor}
              indicator_type={indicator_type}
              speed_type={speed_type}
            />
          )}
      </MapView>

      {region &&
        (isMobile ? (
          <SwipeBox
            isOpened={rightOpened}
            onClose={onRightClose}
            // headClassName={s.Subject__sbHead}
            // contentClassName={s.Subject__sbContent}
          >
            <SubjectView
              projects={region.regional_projects}
              queryParams={queryParams}
              region={region}
              yearMonth={yearMonth}
              selectedFpCode={selectedFpCode}
              budgetCharts={budgetCharts}
              regionalRoadmapQuery={roadmapQuery}
            />
          </SwipeBox>
        ) : (
          <SubjectView
            projects={region.regional_projects}
            queryParams={queryParams}
            region={region}
            yearMonth={yearMonth}
            selectedFpCode={selectedFpCode}
            budgetCharts={budgetCharts}
            regionalRoadmapQuery={roadmapQuery}
          />
        ))}
      <MapFiltersPopup
        isVisible={isFiltersOpen}
        onClose={() => setIsFilterOpen(false)}
        regionCode={codeNalog}
        regionalProjects={projects}
      />

      {isMobile && (
        <FlyingButton
          boxClassName={s.Map__flyingBox}
          className={s.Map__flyingButton}
          onClick={onRightOpen}
        >
          <span>Паспорт субъекта</span>
        </FlyingButton>
      )}
    </MapPageLayout>
  );
}

export default SubjectPage;
