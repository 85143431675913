import React from 'react';
import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { addDays } from 'date-fns';
import Dropdown from '../../../common/ui/Dropdown/Dropdown';
import { QUERY_GET_RP_INDICATORS } from '../../../graphql/queries/indicators';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { RightMenuPopup } from '../../../common/ui/RightMenuPopup/RightMenuPopup';
import { useQueryPeriod } from '../../../utils/useQueryPeriod';
import { IndicatorGoals } from './IndicatorGoals';
import { IndicatorGroupTitle } from './IndicatorGroup';

const indicatorsQueryOptions = {
  query: QUERY_GET_RP_INDICATORS,
  mapData: (result) => result.goals,
  skipQuery: skipIfNull(null)
};

export const GoalsRp = React.memo(function GoalsRp({
  onClose,
  isActive,
  federalProjectId,
  regionalProjectId
}) {
  const { year } = useQueryPeriod();

  const indicatorsQuery = useGraphqlQueryVars({
    queryOptions: indicatorsQueryOptions,
    variableCreator: [
      () =>
        federalProjectId && regionalProjectId
          ? {
              federalProjectId,
              regionalProjectId
            }
          : null,
      [federalProjectId, regionalProjectId]
    ]
  });

  const goals = indicatorsQuery.state.data;

  // todo дата актуальности из gql
  const actualityDate = addDays(new Date(), -1);

  return (
    <RightMenuPopup onClose={onClose} isActive={isActive}>
      <Dropdown
        top={({ opened, setOpened }) => (
          <IndicatorGroupTitle
            title={'Цели и показатели РП'}
            opened={opened}
            setOpened={setOpened}
          />
        )}
        defaultOpened={true}
      >
        {({ opened }) =>
          indicatorsQuery.check.spinner ? (
            <CircleProgressIndicator />
          ) : opened && isActive ? (
            <IndicatorGoals
              goals={goals}
              activeYear={year}
              actualityDate={actualityDate}
            />
          ) : null
        }
      </Dropdown>
    </RightMenuPopup>
  );
});
