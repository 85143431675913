import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useStore } from '@proscom/prostore-react';
import { ErrorBoundary } from '../common/ErrorBoundary';
import ErrorPage from '../common/ErrorPage';
import { NavBar } from '../common/NavBar/NavBar';
import { ActualityDateProvider } from '../store/useActualityDateContext';
import { STORE_MOBILE_APP } from '../store/stores';
import { useWindowSize } from '../store/useWindowSize';
import { ReactHelmet } from '../common/ReactHelmet';
import { Watermark } from '../common/Watermark/Watermark';
import { isWatermarkActive } from '../config';
import { blueDark2, grayLight } from '../colors.scss';

function Wrapper({ location, children }) {
  const { isTablet, isTabletLand } = useWindowSize();
  const isTabletDevice = isTablet || isTabletLand;

  const [mobileAppState, mobileAppStore] = useStore(STORE_MOBILE_APP);
  const [height, setHeight] = useState(null);
  const resizeTORef = useRef(null);
  const gestTORef = useRef(null);

  const setPageHeight = useCallback(() => {
    setHeight(window.innerHeight);
  }, []);

  const onResizeHandler = useCallback(() => {
    setPageHeight();
    /**
     * без таймаута на ios порой непрально считается высота при смене ориентации
     */
    resizeTORef.current = setTimeout(setPageHeight, 1000);
  }, [setPageHeight]);

  const onGestureHandler = useCallback(() => {
    gestTORef.current = setTimeout(setPageHeight, 200);
  }, [setPageHeight]);

  useEffect(() => {
    setPageHeight();
    onResizeHandler();
    window.addEventListener('resize', onResizeHandler);
    /**
     * resize на ios триггерится до окончания анимации зума и scroll-overflow,
     * поэтому ловим окончание этих событий по `gestureend`
     * и с задержкой (конец анимации) еще раз подстраиваем размер страницы
     */
    window.addEventListener('gestureend', onGestureHandler);
    return () => {
      window.removeEventListener('resize', onResizeHandler);
      window.removeEventListener('gestureend', onGestureHandler);
      clearTimeout(resizeTORef.current);
      clearTimeout(gestTORef.current);
    };
  }, [onGestureHandler, onResizeHandler, setPageHeight]);

  const statusBarHeight = mobileAppState.statusBarHeight;
  const styles = useMemo(() => {
    const st = {
      height,
      minHeight: height
    };

    if (statusBarHeight) {
      st.paddingTop = isTabletDevice ? 0 : statusBarHeight + 'px';
    }

    return st;
  }, [height, isTabletDevice, statusBarHeight]);

  useEffect(() => {
    if (statusBarHeight) {
      const bgColor = location.pathname === '/' ? blueDark2 : grayLight;
      mobileAppStore.setBgColor(bgColor);
    }
  }, [location, statusBarHeight, mobileAppStore]);

  const bgColor = mobileAppState.bgColor;
  useEffect(() => {
    if (bgColor) {
      document.documentElement.style.backgroundColor = bgColor;
    }
  }, [bgColor]);

  return height !== null ? (
    <div id="wrapper-offset" style={styles}>
      {isWatermarkActive && <Watermark />}
      {children}
    </div>
  ) : null;
}

export const DefaultLayout = (Comp, title) => (props) => {
  return (
    <Wrapper location={props.location}>
      <ReactHelmet title={title} />
      <NavBar {...props} />
      <div className="page">
        <ErrorBoundary component={ErrorPage}>
          <Comp {...props} />
        </ErrorBoundary>
      </div>
    </Wrapper>
  );
};

export const EmptyLayout = (Comp, title) => (props) => {
  return (
    <ErrorBoundary component={ErrorPage}>
      <ReactHelmet title={title} />
      {isWatermarkActive && <Watermark />}
      <Comp {...props} />
    </ErrorBoundary>
  );
};

export const LoginLayout = (Comp, title) => (props) => {
  return (
    <Wrapper location={props.location}>
      <ErrorBoundary component={ErrorPage}>
        <ReactHelmet title={title} />
        <Comp {...props} />
      </ErrorBoundary>
    </Wrapper>
  );
};

export const ActualityDateLayout = (Comp) => (props) => {
  return (
    <ActualityDateProvider>
      <Comp {...props} />
    </ActualityDateProvider>
  );
};
