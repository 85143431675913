import React, { Component } from 'react';
import { getErrorProps } from './errorUtil';

export class ErrorBoundary extends Component {
  state = {
    error: null
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.children !== prevProps.children) {
      this.setState({ error: null });
    }
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children, component: ErrorComponent, renderError } = this.props;

    if (error) {
      const props = getErrorProps(error);

      if (ErrorComponent) {
        return <ErrorComponent {...props} />;
      } else if (renderError) {
        return renderError(props) || false;
      } else {
        return false;
      }
    }

    return children || false;
  }
}
