export const LAQUO = '\u00AB';
export const RAQUO = '\u00BB';
export const EMDASH = '–';
export const THINSP = '\u2009';
export const QUARTERSP = '\u2005';
export const THIRDSP = '\u2004';
export const ENSP = '\u2002';
export const EMSP = '\u2001';
export const NBSP = '\u00A0';
export const HELLIP = '\u2026';
export const LSAQUO = '\u2039';
export const RSAQUO = '\u203A';
export const RUBLE = '\u20BD';
export const LEQ = '\u2264';
export const UPARROW = '\u2191';
export const DOWNARROW = '\u2193';
export const BULLET = '\u2022';
