import React from 'react';
import { CardTitle } from '../../../common/ui/CardSmall/CardTitle';
import { HorizontalScroll } from '../../../common/HorizontalScroll/HorizontalScroll';
import s from './MapCards.module.scss';

export const MapCards = ({ cards }) => {
  return (
    <div className={s.MapCards}>
      {cards && !!cards.length && (
        <HorizontalScroll
          height={130}
          containerCN={s.MapCards__scroll}
          listCN={s.MapCards__list}
          buttonPosition={HorizontalScroll.INSIDE}
          scrollItems={3}
        >
          {cards.map((card, i) => (
            <div key={i} className={s.MapCards__item}>
              <CardTitle
                number={+card.code || i + 1}
                description={card.name}
                additionalData={card}
              />
            </div>
          ))}
        </HorizontalScroll>
      )}
    </div>
  );
};
