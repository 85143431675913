import React, { useMemo } from 'react';
import classNames from 'classnames';
import { addDays } from 'date-fns';
import objectPhoto1 from '../../assets/img/content/object/photo-1.png';
import { ImageSlider } from '../../common/ImageSlider/ImageSlider';
import { AboutContainer } from '../../common/RegionPages/AboutContainer/AboutContainer';
import { Separator } from '../../common/Separator/Separator';
import { PageInventory } from '../../common/RegionPages/Inventory/PageInventory';
import { useWindowSize } from '../../store/useWindowSize';
import { ObjectAbout } from './ObjectAbout/ObjectAbout';
import s from './ObjectView.module.scss';

const objectPhotos = [
  {
    img: objectPhoto1
  },
  {
    img: objectPhoto1
  }
];

export const ObjectView = ({ organization, className }) => {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  const inventoryVars = useMemo(
    () => ({
      organization_id: organization.id
    }),
    [organization.id]
  );

  // todo graphql
  // Дата обновления данных 22:30
  const currentDate = new Date();

  let actualityDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 1,
    22,
    30
  );
  const nextDate = addDays(actualityDate, 1);
  if (currentDate > nextDate) {
    actualityDate = nextDate;
  }
  const yesterday = addDays(new Date(), -1);

  return (
    <div className={classNames(s.ObjectView, className)}>
      <ImageSlider
        className={s.ObjectView__photosSlider}
        actualityDate={actualityDate}
        slides={objectPhotos}
        sliderProps={{
          showIndicators: false,
          infiniteLoop: true,
          showThumbs: false
        }}
      />
      <AboutContainer>
        <ObjectAbout
          organization={organization}
          actualityDate={actualityDate}
        />
        <Separator className={s.ObjectView__separator} />
        <PageInventory
          variables={inventoryVars}
          isMobile={isMobile}
          actualityDate={yesterday}
        />
        {/*<Actions> Скрыто пока нет данных */}
        {/*  <ButtonExternal variant={Button.SECONDARY} href={'http://bus.gov.ru'}>*/}
        {/*    Реестр закупок и заказов*/}
        {/*  </ButtonExternal>*/}
        {/*  <ButtonExternal variant={Button.SECONDARY} href={'http://bus.gov.ru'}>*/}
        {/*    План фин-хоз деятельности*/}
        {/*  </ButtonExternal>*/}
        {/*  <ButtonExternal variant={Button.SECONDARY} href={'http://bus.gov.ru'}>*/}
        {/*    Государственное задание*/}
        {/*  </ButtonExternal>*/}
        {/*</Actions>*/}
      </AboutContainer>
    </div>
  );
};
