import React, { useMemo } from 'react';
import { useRegions } from '../../../store/useRegions';
import { Select } from '../../../common/ui/Select/Select';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import {
  URL_QUERY_EVENT_TYPE,
  URL_QUERY_LEVEL,
  URL_QUERY_REGION_CODE
} from '../../../store/stores';
import s from './EventsFilter.module.scss';

export const ROADMAP_EVENTS = 'roadmapEvents';
export const CONTROL_POINTS = 'controlPoints';
export const CONTROL_POINT_EVENTS = 'controlPointEvents';
export const FEDERAL = 'federal';
export const REGIONAL = 'regional';
export const ALL_EVENT = 'allEvent';

const eventTypes = [
  {
    label: 'Все типы событий',
    value: ALL_EVENT
  },
  {
    label: 'Мероприятие ДК',
    value: ROADMAP_EVENTS
  },
  {
    label: 'КТ',
    value: CONTROL_POINTS
  },
  {
    label: 'Мероприятие КТ',
    value: CONTROL_POINT_EVENTS
  }
];

const eventLevels = [
  {
    label: 'Все',
    value: undefined
  },
  {
    label: 'Федеральные',
    value: FEDERAL
  },
  {
    label: 'Региональные',
    value: REGIONAL
  }
];

const processRegionsData = (query) => {
  if (!query?.state.data) {
    return null;
  }
  return query.state.data.map((region) => ({
    label: region.name,
    value: region.code_nalog
  }));
};

const findData = (array, value) => {
  if (!array || !value) {
    return undefined;
  }
  return array.find((event) => event.value === value);
};

export function EventsFilter() {
  const [query, changeQuery] = useUrlQuery();

  const regions = useRegions();
  const regionData = useMemo(() => processRegionsData(regions), [regions]);

  const selectedFilters = useMemo(() => {
    const eventType = query[URL_QUERY_EVENT_TYPE];
    const level = query[URL_QUERY_LEVEL];
    const regionCode = query[URL_QUERY_REGION_CODE];

    const selectedEventType = findData(eventTypes, eventType);
    const selectedLevel = findData(eventLevels, level);
    const selectedRegion = findData(regionData, regionCode);
    return {
      type: selectedEventType,
      level: selectedLevel,
      region: selectedRegion
    };
  }, [query, regionData]);

  const clearFilters = () => {
    const newQueryParams = {};
    newQueryParams[URL_QUERY_EVENT_TYPE] = undefined;
    newQueryParams[URL_QUERY_LEVEL] = undefined;
    newQueryParams[URL_QUERY_REGION_CODE] = undefined;
    changeQuery(newQueryParams);
  };

  const handleChangeEventType = ({ value }) => {
    const newQueryParams = {};

    if (value === ALL_EVENT) {
      clearFilters();
      return;
    }

    newQueryParams[URL_QUERY_EVENT_TYPE] = value;
    changeQuery(newQueryParams);
  };

  const handleChangeLevelFilters = ({ value }) => {
    const newQueryParams = {};
    newQueryParams[URL_QUERY_LEVEL] = value;

    if (value === FEDERAL) {
      newQueryParams[URL_QUERY_REGION_CODE] = undefined;
    }

    changeQuery(newQueryParams);
  };

  const handleChangeRegion = (data) => {
    const newQueryParams = {};
    newQueryParams[URL_QUERY_REGION_CODE] = data?.value;
    changeQuery(newQueryParams);
  };

  const isShowLevelFilter =
    selectedFilters.type?.value === CONTROL_POINTS ||
    selectedFilters.type?.value === CONTROL_POINT_EVENTS;

  const isShowRegionsFilter =
    isShowLevelFilter && selectedFilters.level?.value === REGIONAL;

  return (
    <div className={s.EventsFilter}>
      {/* Тип мероприятия */}
      <Select
        className={s.EventsFilter__element}
        values={eventTypes}
        variant={Select.DARKER}
        height={Select.SMALL}
        value={selectedFilters.type}
        defaultValue={eventTypes[0]}
        onChange={handleChangeEventType}
      />
      {isShowLevelFilter ? (
        <Select
          className={s.EventsFilter__element}
          values={eventLevels}
          variant={Select.DARKER}
          height={Select.SMALL}
          value={selectedFilters.level}
          defaultValue={eventLevels[0]}
          onChange={handleChangeLevelFilters}
        />
      ) : null}
      {regionData && isShowRegionsFilter ? (
        <Select
          className={s.EventsFilter__element}
          values={regionData}
          variant={Select.DARKER}
          height={Select.SMALL}
          placeholder={'Все регионы'}
          searchable
          clearable
          value={selectedFilters.region}
          onChange={handleChangeRegion}
        />
      ) : null}
    </div>
  );
}
