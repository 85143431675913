import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Chip } from './Chip';

export function ChipRadio({ selected, value, onChange, ...props }) {
  const handleClick = useMemo(() => {
    return onChange ? () => onChange(value) : undefined;
  }, [onChange, value]);

  return <Chip {...props} active={selected === value} onClick={handleClick} />;
}

ChipRadio.propTypes = {
  icon: PropTypes.elementType,
  title: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};
