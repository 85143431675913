import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as DownloadIcon } from '../../../assets/Icons/Download.svg';
import { createDownloadLink } from '../../../store/usePreserveQuery';
import { useCookieLoader } from '../../../utils/useCookieLoader';
import { ButtonComponent } from '../Button/Button';
import { CardSmallLayout } from './CardSmallLayout';
import s from './CardSmallLayout.module.scss';

export function LoaderCardDownload({
  onClick,
  cookieLoader,
  onDownloadFinish,
  loading,
  ...props
}) {
  const { downloadId, handleClick, isLoading } = useCookieLoader(
    cookieLoader,
    onDownloadFinish,
    onClick
  );

  return (
    <CardDownload
      {...props}
      downloadId={downloadId}
      onClick={handleClick}
      isLoading={loading || isLoading}
    />
  );
}

export function CardDownload({
  article,
  link,
  downloadId,
  onClick,
  isLoading,
  ...props
}) {
  return (
    <div className={s.CardDownload} {...props}>
      <ButtonComponent
        className={s.CardDownload__link}
        href={link && createDownloadLink(link, downloadId)}
        onClick={onClick}
        download
      >
        <CardSmallLayout className={s.CardDownload__layout} loading={isLoading}>
          <div className={s.CardDownload__icon}>
            <DownloadIcon />
          </div>
          <p className={s.CardDownload__article}>{article}</p>
        </CardSmallLayout>
      </ButtonComponent>
    </div>
  );
}

CardDownload.propTypes = {
  number: PropTypes.number,
  article: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string
};

CardDownload.defaultProps = {
  number: 0
};
