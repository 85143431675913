import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as Circle } from '../../../assets/Icons/CircleEmpty.svg';
import BaseButton from '../Button/BaseButton';
import s from './Chip.module.scss';

export function Chip({
  icon: Icon,
  title,
  label,
  color,
  className,
  active,
  onClick,
  ...props
}) {
  return (
    <BaseButton
      className={classNames(s.Chip, className, { [s._active]: active })}
      onClick={onClick}
      {...props}
    >
      <div className={s.Chip__icon} style={{ color }}>
        {Icon ? <Icon /> : <Circle />}
      </div>
      <div className={s.Chip__title}>{label || title}</div>
    </BaseButton>
  );
}

Chip.propTypes = {
  icon: PropTypes.elementType,
  title: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};
