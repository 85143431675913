import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { useMemo } from 'react';
import {
  QUERY_GET_ROADMAP_EVENTS,
  QUERY_GET_ROADMAP_FEDERAL_EVENTS
} from '../graphql/queries/federalProjects';

const roadmapEventsOptions = {
  query: QUERY_GET_ROADMAP_EVENTS,
  mapData: (result) => result.roadmapEventsPage.list,
  skipQuery: skipIfNull(null)
};

const roadmapFederalEventsOptions = {
  query: QUERY_GET_ROADMAP_FEDERAL_EVENTS,
  mapData: (result) => result.roadmapEventsPage.list,
  skipQuery: skipIfNull(null)
};

export const useRoadmapEvents = (
  roadmapId,
  year = undefined,
  regionCode = undefined
) => {
  const variables = useMemo(
    () =>
      roadmapId
        ? {
            filter: {
              roadmap_id: roadmapId,
              year
            },
            regionalEventsFilter: regionCode
              ? {
                  region_code_nalogs: regionCode
                }
              : undefined
          }
        : null,
    [roadmapId, regionCode, year]
  );

  return useGraphqlQuery({
    queryOptions: regionCode
      ? roadmapEventsOptions
      : roadmapFederalEventsOptions,
    variables
  });
};
