import React from 'react';
import { DayEventStatus } from '../../CalendarPage/EventList/DayEventList';
import { lFormat } from '../../../utils/date';
import { EMDASH, NBSP } from '../../../utils/utf';
import { ProjectIndicator } from '../../../common/ui/Project/ProjectIndicator';
import s from '../ReportPage.module.scss';

export const StatusView = ({ statusGroups }) => {
  return (
    <div className={s.StatusView}>
      <div className={s.StatusView__item}>
        <div className={s.StatusView__number}>{statusGroups.null || 0}</div>
        <div className={s.StatusView__title}>В процессе выполнения</div>
      </div>
      <div className={s.StatusView__item}>
        <div className={s.StatusView__number}>
          {statusGroups[ProjectIndicator.WARN] || 0}
        </div>
        <div className={s.StatusView__title}>
          Выполнено с{NBSP}нарушением сроков
        </div>
      </div>
      <div className={s.StatusView__item}>
        <div className={s.StatusView__number}>
          {statusGroups[ProjectIndicator.ERROR] || 0}
        </div>
        <div className={s.StatusView__title}>Не выполнено</div>
      </div>
      <div className={s.StatusView__item}>
        <div className={s.StatusView__number}>
          {statusGroups[ProjectIndicator.SUCCESS] || 0}
        </div>
        <div className={s.StatusView__title}>Выполнено</div>
      </div>
    </div>
  );
};

export const RoadmapEvent = ({ event, isControlPoints }) => {
  return (
    <li className={s.RoadmapEvent}>
      <DayEventStatus item={event} isControlPoints={isControlPoints} />
      <p className={s.RoadmapEvent__text}>
        {event.date_start && event.date_end
          ? `${lFormat(event.date_start, 'dd MMMM')} ${EMDASH} ${lFormat(
              event.date_end,
              'dd MMMM'
            )}`
          : event.date || event.date_end
          ? lFormat(event.date || event.date_end, 'dd MMMM')
          : null}
      </p>
      <p className={s.RoadmapEvent__text}>{event.text}</p>
    </li>
  );
};

export function groupBy(data, keyCb, extractGroup, fieldName = 'list') {
  const result = {};
  for (let item of data) {
    const key = keyCb(item);
    if (!result[key]) {
      result[key] = {
        ...extractGroup(item),
        [fieldName]: [item]
      };
    } else {
      result[key][fieldName].push(item);
    }
  }
  return result;
}

export function groupsToArray(groups, sortFn) {
  return Object.values(groups).sort(sortFn);
}

export const projectCodeSort = (a, b) =>
  a.project_code < b.project_code
    ? -1
      ? a.project_code > b.project_code
      : 1
    : 0;
