import React, { useMemo } from 'react';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { addMonths, startOfMonth } from 'date-fns';
import {
  QUERY_GET_EVENT_REPORT,
  QUERY_GET_REPORT
} from '../../graphql/queries/reports';
import { queryLoader } from '../../common/QueryLoader';
import ReportTextContent from '../ReportsPage/common/ReportTextContent';
import ReportTitle from '../ReportsPage/common/ReportTitle';
import { getMonthName } from '../../utils/constants';
import { EVENTS_REPORT_TYPE } from '../ReportsPage/ReportsForm';
import { getFileLinksFromQuery } from '../../utils/data';
import { reportScopeType } from '../../store/DocumentTokenStore';

const reportTextQueryOptions = {
  query: QUERY_GET_REPORT,
  mapData: (data) => data.report,
  skipQuery: skipIfNull(null)
};

const reportTextEventQueryOptions = {
  query: QUERY_GET_EVENT_REPORT,
  mapData: (data) => data.eventsReport,
  skipQuery: skipIfNull(null)
};

const useReportQueries = (variables, type) => {
  const reportTextQuery = useGraphqlQuery({
    queryOptions: reportTextQueryOptions,
    variables: type !== EVENTS_REPORT_TYPE ? variables : null
  });

  const reportTextEventQuery = useGraphqlQuery({
    queryOptions: reportTextEventQueryOptions,
    variables: type === EVENTS_REPORT_TYPE ? variables : null
  });

  return type === EVENTS_REPORT_TYPE ? reportTextEventQuery : reportTextQuery;
};

export function ReportTextView({
  year,
  month,
  result,
  region,
  federalProject,
  reportType,
  ...props
}) {
  const variables = useMemo(() => {
    return {
      input: {
        year: +year,
        month: +month,
        result_id: result && result.id,
        region_id: region && region.id,
        federal_project_id: federalProject && federalProject.id
      }
    };
  }, [federalProject, month, region, result, year]);

  const reportQuery = useReportQueries(variables, reportType);

  const downloadOptions = getFileLinksFromQuery(reportQuery);

  const actualityDate = useMemo(() => {
    const selectedDate = new Date(year, month - 1);
    const currentMonth = startOfMonth(new Date());

    // Если сгенерирован отчёт за один из прошлых месяцев,
    // отображать "По состоянию на 01.хх.хх" следующего за выбранным месяца.
    if (selectedDate < currentMonth) {
      return startOfMonth(addMonths(selectedDate, 1));
    }
    // Если выбран текущий или будущие месяца, нужно отображать текущую дату
    return new Date();
  }, [month, year]);

  return (
    queryLoader(reportQuery) || (
      <div>
        <ReportTitle
          files={downloadOptions}
          type={reportScopeType.REFERENCE_DOCUMENT_REPORT}
          title={`Отчёт за ${getMonthName(month)} ${year}`}
          onClick={props.onGoBack}
        />
        <ReportTextContent
          html={reportQuery.state.data?.html}
          actualityDate={actualityDate}
        />
      </div>
    )
  );
}
