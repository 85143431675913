import { sum } from 'd3-array';
import { degreeToRad } from './math';

export function pie() {
  let value = (d) => d;
  let gapAngle = 0;
  let total = null;
  let rotateAngle = 0;
  let maxAngle = 2 * Math.PI;
  let collapseEmpty = false;
  let minSegmentAngle = 0;
  let angleFormula = ({ alpha, value, total, tau }) => {
    return alpha + (value / total) * tau;
  };

  function pie(data) {
    if (!data) return new Error('Invalid Arguments');
    let elementCount = data.length;
    const dataArray = data.map(value);
    const gapAngleRad = degreeToRad(gapAngle);
    const sumDataArray = sum(dataArray);
    const rotateAngleRad = degreeToRad(rotateAngle);
    const totalCount = total
      ? total > sumDataArray
        ? total
        : sumDataArray
      : sumDataArray;

    let nonEmptyElements = 0;
    for (let i = 0; i < elementCount; i++) {
      if (!collapseEmpty || dataArray[i] > 0) {
        nonEmptyElements++;
      }
    }
    const tau =
      maxAngle -
      nonEmptyElements * gapAngleRad -
      nonEmptyElements * minSegmentAngle;

    let alpha = rotateAngleRad;
    const newData = [];

    dataArray.forEach((value, key) => {
      const addMinAngle = !collapseEmpty || value > 0 ? minSegmentAngle : 0;
      const delta =
        angleFormula({
          alpha,
          value,
          total: totalCount,
          tau
        }) + addMinAngle;

      newData.push({
        startAngle: alpha,
        index: key,
        endAngle: delta,
        data: data[key],
        value: value
      });

      const addGapAngle = !collapseEmpty || value > 0 ? gapAngleRad : 0;
      alpha = delta + addGapAngle;
    });

    return newData;
  }

  /**
   * Установка собственной формулы для расчета углов и отступов
   * Принимает функцию, которая принимает 4 аргумента
   * alpha - конечный угол прошлого элемента,
   * value - текущее значение данных,
   * total - сумма значений данных,
   * tau - Math.Pi * 2 - delta (где delta - это угол между элементами * кол-во элементов)
   * @param formula
   */
  pie.angleFormula = function(formula) {
    if (arguments.length) {
      angleFormula =
        typeof formula === 'function' ? formula : ((x) => () => x)(formula);
      return pie;
    } else {
      return angleFormula;
    }
  };

  pie.value = function(path) {
    if (arguments.length) {
      value = typeof path === 'function' ? path : ((x) => () => x)(+path);
      return pie;
    }
    return value;
  };

  pie.gapAngle = function(value) {
    if (arguments.length) {
      gapAngle = +value;
      return pie;
    }
    return gapAngle;
  };

  pie.rotateAngle = function(value) {
    if (arguments.length) {
      rotateAngle = value;
      return pie;
    }
    return rotateAngle;
  };

  pie.totalCount = function(value) {
    if (arguments.length) {
      total = +value;
      return pie;
    }
    return total;
  };

  pie.maxAngle = function(value) {
    if (arguments.length) {
      maxAngle = value;
      return pie;
    }
    return total;
  };

  pie.collapseEmpty = function(value) {
    if (arguments.length) {
      collapseEmpty = value;
      return pie;
    }
    return total;
  };

  pie.minSegmentAngle = function(value) {
    if (arguments.length) {
      minSegmentAngle = value;
      return pie;
    }
    return total;
  };

  return pie;
}
