import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as Checked } from '../../../assets/Icons/Check.svg';
import { ReactComponent as Indeterminate } from '../../../assets/Icons/Minus.svg';
import s from './CheckBox.module.scss';

const DEFAULT = 'default';
const CARD = 'card';

export function CheckBox({
  checked,
  indeterminate,
  label,
  className,
  style,
  disabled,
  variant,
  id,
  ...props
}) {
  return (
    <div className={classNames(s.checkBox, className)}>
      <input
        className={classNames(s.checkBox__input, {
          [s.checkBox__card]: variant === CARD
        })}
        id={id}
        disabled={disabled}
        type="checkbox"
        checked={checked}
        ref={(el) => el && (el.indeterminate = indeterminate)}
        {...props}
      />
      <label htmlFor={id} className={s.checkBox__label}>
        {indeterminate ? (
          <Indeterminate
            className={classNames(s.checkBox__svg, {
              [s._card]: variant === CARD
            })}
          />
        ) : checked ? (
          <Checked
            className={classNames(s.checkBox__svg, {
              [s._card]: variant === CARD
            })}
          />
        ) : null}
        {label}
      </label>
    </div>
  );
}

CheckBox.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.any.isRequired
};

CheckBox.DEFAULT = DEFAULT;
CheckBox.CARD = CARD;
