import classNames from 'classnames';
import { max as d3max } from 'd3';
import React from 'react';
import { getRoundLegendSteps, roundToRound } from '../../../utils/math';
import { ResizeContainer } from '../../../common/ResizeContainer';
import { ChartWithLegend } from '../../../common/charts/ChartWithLegend';
import LineChart from '../../../common/charts/LineChart/LineChart';
import s from './ResultsInfoView.module.scss';

export function PercentChart({ data, title, indices, classes }) {
  const totalMax = roundToRound(d3max(data, (d) => d3max(d.values)));
  const legendSteps = getRoundLegendSteps(totalMax);

  return (
    <div className={s.PercentChart}>
      <div className={classNames(s.PercentChart__title, classes?.title)}>
        {title}
      </div>
      <div className={s.PercentChart__chart}>
        <ResizeContainer>
          {({ width, height }) => {
            return (
              <ChartWithLegend
                width={width}
                height={height}
                min={0}
                max={totalMax}
                steps={legendSteps}
                columnData={data.map((item) => item.name)}
              >
                {({ width, height, ...props }) => (
                  <LineChart
                    sizeWidth={width}
                    sizeHeight={height}
                    data={data}
                    indices={indices}
                    lineStrokeWidth={2}
                    circleStrokeWidth={2}
                    circleRadius={4}
                    total={totalMax}
                    {...props}
                  />
                )}
              </ChartWithLegend>
            );
          }}
        </ResizeContainer>
      </div>
    </div>
  );
}
