import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as Circle } from '../../../assets/Icons/CircleEmpty.svg';
import { RatingStatus } from '../../../utils/constants';
import s from './Chip.module.scss';

export function ChipText({
  icon: Icon,
  iconElement,
  title,
  className,
  status,
  ...props
}) {
  return (
    <div
      className={classNames(s.ChipText, className, {
        [s._success]: status === RatingStatus.SUCCESS,
        [s._warn]: status === RatingStatus.WARN,
        [s._error]: status === RatingStatus.ERROR
      })}
      {...props}
    >
      <div className={s.ChipText__icon}>
        {iconElement || (Icon && <Icon />) || <Circle />}
      </div>
      <div className={s.ChipText__title}>{title}</div>
    </div>
  );
}

ChipText.propTypes = {
  icon: PropTypes.elementType,
  iconElement: PropTypes.node,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};
