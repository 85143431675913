import React from 'react';
import { useUrlQueryParams } from '../../utils/useUrlQuery';
import { URL_QUERY_FP } from '../../store/stores';
import { IndicatorsPageCommon } from './IndicatorsPageCommon';
import { IndicatorsPageFp } from './IndicatorsPageFp';

const queryParams = [URL_QUERY_FP];

export default function IndicatorsPage({ history }) {
  const [query] = useUrlQueryParams(queryParams);
  const fpCode = +query.fp || 0;

  return fpCode === 0 ? (
    <IndicatorsPageCommon />
  ) : (
    <IndicatorsPageFp history={history} />
  );
}
