import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ButtonComponent } from '../Button/Button';
import s from './IconButton.module.scss';

export function IconButton({
  icon: Icon,
  text = '',
  iconClassName,
  className,
  ...props
}) {
  return (
    <ButtonComponent className={classNames(className, s.IconButton)} {...props}>
      {Icon ? (
        <Icon className={classNames(iconClassName, s.IconButton__icon)} />
      ) : (
        <p className={s.IconButton__text}>{text}</p>
      )}
    </ButtonComponent>
  );
}

IconButton.propTypes = {
  ...ButtonComponent.propTypes,
  icon: PropTypes.elementType,
  text: PropTypes.string
};
