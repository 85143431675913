import React, { useMemo } from 'react';
import { useRequestStore } from '@proscom/prostore-react';
import { STORE_MAP } from '../../store/stores';
import { useFederalDistrictsWithFinancialRatings } from '../../store/useFederalDistricts';
import CommonMap from '../../common/Map/CommonMap/CommonMap';
import { useQueryPeriod } from '../../utils/useQueryPeriod';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { tryNumber } from '../../utils/math';
import { SPEED_TYPE_COUNTRYSIDE } from '../../utils/indicators';
import { queryLoader } from '../../common/QueryLoader';
import { useColorifiedMapItems } from '../../utils/useColoredRegionsData';
import { formatDateIso } from '../../utils/date';

const mapVariables = { type: 'russia' };

export const RussiaMap = ({ queryParams, selectedFp, ...props }) => {
  const rangeMin = tryNumber(queryParams.range_min, 0);
  const rangeMax = tryNumber(queryParams.range_max, 100);
  const { yearMonth, year, month } = useQueryPeriod();
  const query = useRequestStore(STORE_MAP, mapVariables);
  const {
    organization_type,
    indicator_type,
    res,
    roadmap,
    speed_type
  } = queryParams;
  const countOrganizationsFilter = useMemo(
    () => ({
      bs_organization_type_okved_ids:
        organization_type && organization_type !== 'ALL'
          ? [organization_type]
          : null,
      federal_project_ids: selectedFp ? selectedFp.id : undefined,
      result_ids: res ? res.split(',') : undefined,
      roadmap_ids: roadmap ? [roadmap] : undefined,
      roadmap_year: roadmap && year,
      is_rural: speed_type === SPEED_TYPE_COUNTRYSIDE
    }),
    [organization_type, selectedFp, res, roadmap, year, speed_type]
  );
  const districtsQuery = useFederalDistrictsWithFinancialRatings({
    countOrganizationsFilter,
    yearMonth,
    federalProjectIds: selectedFp ? [selectedFp.id] : undefined,
    internetSpeedFilter: {
      yearMonths: [yearMonth],
      organizations_filter: countOrganizationsFilter
    },
    dcCompletionFilter: {
      federal_project_ids: selectedFp ? selectedFp.id : undefined,
      roadmap_ids: roadmap ? [roadmap] : undefined,
      year: year,
      date: formatDateIso(new Date())
    }
  });

  const districtsData = districtsQuery.state.data;

  const colorifiedMapItems = useColorifiedMapItems(districtsData, {
    indicator_type,
    rangeMax,
    query,
    rangeMin,
    speed_type
  });

  return (
    queryLoader(query) ||
    queryLoader(districtsQuery) || (
      <>
        <CommonMap
          {...props}
          data={query.state.data}
          mapItems={colorifiedMapItems}
          mapItemProp="code"
          indicator_type={indicator_type}
        />
        {(query.state.loading || districtsQuery.state.loading) && (
          <CircleProgressIndicator absolute />
        )}
      </>
    )
  );
};
