import React from 'react';
import { Link } from 'react-router-dom';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { usePreserveQueryFast } from '../../../store/usePreserveQuery';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { CardChart } from '../../../common/ui/Card/Card';
import { INDICATOR_DC_COMPLETION } from '../../../utils/indicators';
import { getStatusByValue, STATUS_TYPES } from '../../../utils/constants';
import s from './RegionalRoadmapCards.module.scss';

/**
 * @return {null}
 */
export function RegionalRoadmapCards({ regionalRoadmapQuery, region }) {
  const [queryParams] = useUrlQuery();
  const regionalRoadmap = regionalRoadmapQuery.state.data;
  const { createLink } = usePreserveQueryFast(queryParams);

  if (regionalRoadmapQuery.check.spinner) {
    return (
      <div className={s.roadmapCards}>
        <CircleProgressIndicator />
      </div>
    );
  }

  if (regionalRoadmapQuery.check.error) {
    throw regionalRoadmapQuery.state.error;
  }

  if (!regionalRoadmap) {
    return null;
  }

  return (
    <div className={s.roadmapCards}>
      <div className={s.roadmapCards__title}>Дорожные карты</div>
      {regionalRoadmap.map((item, position) => {
        return (
          <Link
            key={position}
            to={createLink(`/indicators/roadmap/${item.id}`, {
              region_code: region ? region.code_nalog : undefined
            })}
            className={s.roadmapCard}
          >
            <div className={s.roadmapCard__title}>
              <div className={s.roadmapCard__number}>
                {+item.code || position + 1}
              </div>
              <div className={s.roadmapCard__title}>{item.name}</div>
            </div>
            <div className={s.roadmapCard__charts}>
              {[
                {
                  title: 'Выполнение мероприятий',
                  value: item.dc_completion?.dc_completion_percent,
                  unit: '%',
                  type: INDICATOR_DC_COMPLETION
                },
                {
                  title: 'Кассовое исполнение',
                  value: item.result?.finances?.cash_expenses_percent || null,
                  unit: '%'
                }
              ].map((item, iItem) => {
                return (
                  <CardChart
                    key={iItem}
                    item={item}
                    status={
                      item.type === INDICATOR_DC_COMPLETION
                        ? getStatusByValue(item.value, STATUS_TYPES.COMPLETION)
                        : getStatusByValue(item.value)
                    }
                    decimals={2}
                  />
                );
              })}
            </div>
          </Link>
        );
      })}
    </div>
  );
}
