import React, { useMemo } from 'react';

import { isAfter, parseISO } from 'date-fns';

import { StatusChipText } from '../Chip/StatusChipText';
import { reformatDate } from '../../../utils/date';
import DonutPieChart from '../../charts/DonutPieChart';

import { formatNumber } from '../../../utils/formatNumber';
import BaseButton from '../Button/BaseButton';
import { ReactComponent as ArrowRight } from '../../../assets/Icons/Arrow/Front.svg';
import { useCurrentDate } from '../../../utils/useCurrentDate';
import { RatingStatus } from '../../../utils/constants';

import { BULLET } from '../../../utils/utf';
import { SlotsDocumentList } from '../../EventsList/RegionDocumentList';
import { compareSimple, makeComparator } from '../../../utils/data';

import s from './EventCard.module.scss';
import colors from '../../../colors.scss';

export const CONTROL_POINT = 'control_point';
export const CONTROL_POINT_EVENT = 'control_point_event';
export const ROADMAP_EVENT = 'roadmap_event';

export function EventCard({
  title,
  planDate,
  factDate,
  done,
  total,
  type,
  event,
  overdue: overdueProp,
  onOpenDocuments
}) {
  const today = useCurrentDate();
  const overdue = planDate && isAfter(today, parseISO(planDate));

  const completedStatus = done >= 0 && total >= 0 && done === total;

  let statusName = completedStatus
    ? 'Выполнено'
    : overdue || overdueProp
    ? 'Не выполнено'
    : 'В процессе выполнения';

  if (type === CONTROL_POINT) {
    statusName = statusName + ' ' + BULLET + ' КТ';
  } else if (type === CONTROL_POINT_EVENT) {
    statusName = statusName + ' ' + BULLET + ' мероприятие КТ';
  }

  const status = completedStatus
    ? RatingStatus.SUCCESS
    : overdue || overdueProp
    ? RatingStatus.ERROR
    : null;

  const documentFederalSlots = useMemo(() => {
    return (
      event?.roadmap_event_document_slots
        ?.filter((slot) => slot.federal)
        .sort(makeComparator(compareSimple, (s) => s.code)) || []
    );
  }, [event]);

  const federalDocuments = event?.roadmap_event_documents;

  return (
    <div className={s.RoadmapEvent}>
      <div className={s.RoadmapEvent__left}>
        <div className={s.RoadmapEvent__head}>
          <StatusChipText
            className={s.RoadmapEvent__statusChip}
            title={statusName}
            status={status}
          />
          <div className={s.RoadmapEvent__dateChip}>
            <span>План</span>
            <span className={s.RoadmapEvent__dateChip__date}>
              {reformatDate(planDate)}
            </span>
          </div>
          <div className={s.RoadmapEvent__dateChip}>
            <span>Факт</span>
            <span className={s.RoadmapEvent__dateChip__date}>
              {reformatDate(factDate)}
            </span>
          </div>
        </div>
        <div className={s.RoadmapEvent__title}>{title}</div>
      </div>
      <div className={s.RoadmapEvent__circle}>
        <DonutPieChart
          data={[{ id: 0, value: done || 0 }]}
          size={30}
          outerRadius={15}
          innerRadius={13}
          color={colors.greyDark}
          total={total}
          dashColor={s.greyLight}
          strokeDasharray={null}
          dashWidth={'2px'}
          isDash={true}
          backgroundDash={true}
          className={s.TaskStatus__circle}
        />
        <div className={s.RoadmapEvent__points_text}>
          {formatNumber(done, 0)}/{formatNumber(total, 0)}
        </div>
      </div>
      <div className={s.RoadmapEvent__bottom}>
        {documentFederalSlots?.length > 0 || federalDocuments?.length > 0 ? (
          <>
            <div className={s.RoadmapEvent__separator} />
            <div>
              <SlotsDocumentList
                slots={documentFederalSlots}
                documents={federalDocuments}
              />
            </div>
          </>
        ) : null}
        <div className={s.RoadmapEvent__separator} />
        <div className={s.RoadmapEvent__statusBar}>
          <div className={s.RoadmapEvent__statuses}>
            <div className={s.RoadmapEvent__status}>
              <span className={s.__text}>Выполнено</span>
              <span className={s.__value}>{formatNumber(done, 0)}</span>
            </div>
            <div className={s.RoadmapEvent__status}>
              <span className={s.__text}>Не выполнено</span>
              <span className={s.__value}>{formatNumber(total - done, 0)}</span>
            </div>
          </div>
          <div className={s.RoadmapEvent__action}>
            <BaseButton
              className={s.RoadmapEvent__button}
              onClick={onOpenDocuments}
            >
              <span className={s.__text}>Документы</span>
              <ArrowRight className={s.__icon} />
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RegionalEventCard({
  documentView,
  done,
  planDate,
  factDate,
  status,
  displayDocuments,
  type,
  title
}) {
  const today = useCurrentDate();
  const overdue = planDate && isAfter(today, parseISO(planDate));

  let statusName = done
    ? 'Выполнено'
    : overdue
    ? 'Не выполнено'
    : 'В процессе выполнения';

  if (type === CONTROL_POINT) {
    statusName = statusName + ' ' + BULLET + ' КТ';
  } else if (type === CONTROL_POINT_EVENT) {
    statusName = statusName + ' ' + BULLET + ' мероприятие КТ';
  }

  return (
    <div className={s.RoadmapEvent}>
      <div className={s.RoadmapEvent__left}>
        <div className={s.RoadmapEvent__head}>
          <StatusChipText
            className={s.RoadmapEvent__statusChip}
            title={statusName}
            status={status}
          />
          <div className={s.RoadmapEvent__dateChip}>
            <span>План</span>
            <span className={s.RoadmapEvent__dateChip__date}>
              {reformatDate(planDate)}
            </span>
          </div>
          <div className={s.RoadmapEvent__dateChip}>
            <span>Факт</span>
            <span className={s.RoadmapEvent__dateChip__date}>
              {reformatDate(factDate)}
            </span>
          </div>
        </div>
        <div className={s.RoadmapEvent__title}>{title}</div>
      </div>
      {displayDocuments && (
        <div className={s.RoadmapEvent__bottom}>
          <div className={s.RoadmapEvent__separator} />
          <div>
            <div>{documentView}</div>
          </div>
        </div>
      )}
    </div>
  );
}
