import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { usePreserveQuery } from '../../../store/usePreserveQuery';
import { Project } from '../../../data/project';
import { useWindowSize } from '../../../store/useWindowSize';
import { enableBodyScroll } from '../../../utils/scrollFix';
import { ProjectCard } from './ProjectCard';
import CardsView from './CardsView';

export const useHistoryWithEnableScroll = () => {
  // slickSlider на ipad имеет баг. Он блокирует скрол на событие touchStart,
  // но переход по ссылке происходит быстрее, чем событие touchEnd,
  // и скрол так и остается заблокированным
  const history = useHistory();

  return useCallback(
    (link) => {
      enableBodyScroll();
      history.push(link);
    },
    [history]
  );
};

export function ProjectCardsView({
  data,
  tab,
  onCardClick,
  selectedIndex,
  onChangeIndex,
  ...props
}) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;
  const { createLink } = usePreserveQuery();
  const onClickCard = useHistoryWithEnableScroll();

  const dataItems = useMemo(() => {
    if (data && data.length > 0) {
      const items = data.map((item, i) => ({
        index: i,
        ...item
      }));

      if (isMobile) {
        items.reverse();
      }

      return items;
    } else return [];
  }, [data, isMobile]);

  const reverseIndex = (index) => dataItems.length - index - 1;

  const handleIndexChange = (newIndex) => {
    onChangeIndex(isMobile ? reverseIndex(newIndex) : newIndex);
  };

  const activeIndex = isMobile ? reverseIndex(selectedIndex) : selectedIndex;

  if (!data) return null;

  const activeItemColors =
    (data[selectedIndex] && data[selectedIndex].colors) || null;

  return (
    <CardsView
      selectedIndex={activeIndex}
      dotColors={activeItemColors}
      onChangeIndex={handleIndexChange}
      {...props}
    >
      {dataItems.map((item) => {
        const code = Project.getFederalProjectCode(item.data.federal_project);
        return (
          <ProjectCard
            key={item.index}
            item={item}
            pageTab={tab}
            active={item.index === selectedIndex}
            onClick={() => {
              if (item.index === selectedIndex) {
                const link = createLink('/indicators', { fp: code });
                onClickCard(link);
              } else {
                handleIndexChange(item.index);
              }
            }}
          />
        );
      })}
    </CardsView>
  );
}
