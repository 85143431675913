export const STORE_PORTALS = 'STORE_PORTALS';
export const STORE_MAP = 'STORE_MAP';
export const STORE_AUTH = 'STORE_AUTH';
export const STORE_LOCATION = 'STORE_LOCATION';
export const STORE_MOBILE_APP = 'STORE_MOBILE_APP';
export const STORE_DOCUMENT_TOKEN = 'STORE_DOCUMENT_TOKEN';
export const STORE_CACHE = 'STORE_CACHE';

export const PORTAL_ACTUALITY_DATE = 'ACTUALITY_DATE';

export const URL_QUERY_YEAR = 'year';
export const URL_QUERY_QUARTER = 'quarter';
export const URL_QUERY_MONTH = 'month';
export const URL_QUERY_GRBS = 'grbs';
export const URL_QUERY_FP = 'fp';
export const URL_QUERY_RP = 'rp';
export const URL_QUERY_REGION_CODE = 'region_code';
export const URL_QUERY_ORGANIZATION_TYPE = 'organization_type';
export const URL_QUERY_INDICATOR_TYPE = 'indicator_type';
export const URL_QUERY_RES = 'res';
export const URL_QUERY_TAB = 'tab';
export const URL_QUERY_EVENT_TYPE = 'eventType';
export const URL_QUERY_LEVEL = 'level';
export const URL_QUERY_FD = 'fd';
export const URL_QUERY_REGION = 'region';
export const URL_QUERY_DISTRICT = 'district';
