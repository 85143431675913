import React from 'react';
import s from './Watermark.module.scss';

export function Watermark() {
  const watermarkSpans = [];

  for (let i = 0; i <= 50; i++) {
    watermarkSpans.push(<div>Тестовые данные</div>);
  }

  return <div className={s.Watermark}>{watermarkSpans}</div>;
}
