import {
  useContextStore,
  useObservableState,
  useStore
} from '@proscom/prostore-react';
import { useMemo } from 'react';
import { STORE_LOCATION } from '../store/stores';

export function useUrlQuery() {
  const [state, store] = useStore(STORE_LOCATION);
  return [state.query, store.changeQuery];
}

export function useUrlQueryParams(params) {
  const store = useContextStore(STORE_LOCATION);
  const query$ = useMemo(() => store.get$(...params), [store, params]);
  const query = useObservableState(query$, store.state.query);
  return [query, store.changeQuery];
}
