import React, { useMemo } from 'react';
import { InfographicChartsNpFp } from './InfographicChartsNpFp';
import { InfographicChartsRp } from './InfographicChartsRp';
import { InfographicChartsResult } from './InfographicChartsResult';

export function InfographicCharts(props) {
  const { region, result } = props;
  const regions = useMemo(() => [region], [region]);
  const results = useMemo(() => [result], [result]);

  if (region) {
    return <InfographicChartsRp {...props} regions={regions} />;
  } else if (result) {
    return <InfographicChartsResult {...props} results={results} />;
  } else {
    return <InfographicChartsNpFp {...props} />;
  }
}
