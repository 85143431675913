import React, { Fragment } from 'react';
import ErrorPage from '../../common/ErrorPage';
import { ReactHelmet } from '../../common/ReactHelmet';

export default function NotFoundPage(props) {
  return (
    <Fragment>
      <ReactHelmet title={'Ошибка 404'} />
      <ErrorPage text="404" description="Страница не найдена" />
    </Fragment>
  );
}
