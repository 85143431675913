import { useMemo } from 'react';
import { skipIfNull } from '@proscom/prostore';
import {
  addMonths,
  endOfMonth,
  isBefore,
  parseISO,
  startOfMonth
} from 'date-fns';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';

import {
  QUERY_GET_ALL_CALENDAR_EVENTS,
  QUERY_GET_ALL_CALENDAR_EVENTS_STATUS,
  QUERY_GET_CALENDAR_CONTROL_POINTS,
  QUERY_GET_CONTROL_POINT_EVENTS,
  QUERY_GET_ROADMAPS_CALENDAR_EVENTS
} from '../graphql/queries/calendar';

import { ProjectIndicator } from '../common/ui/Project/ProjectIndicator';
import {
  formatDateIso,
  isBeforeOrEqual,
  lEndOfWeek,
  lStartOfWeek,
  tryParseIso
} from '../utils/date';
import {
  endOfYearMonth,
  startOfQuarter,
  startOfYearMonth
} from '../utils/constants';
import { useCurrentDate } from '../utils/useCurrentDate';

import {
  CONTROL_POINT_EVENTS,
  CONTROL_POINTS,
  FEDERAL,
  ROADMAP_EVENTS
} from '../routes/CalendarPage/EventsFilter/EventsFilter';
import { RoadmapEventStatus } from '../data/roadmapEvent';
import { getDataName } from '../utils/data';

import { mapRequestQuery } from './mapRequestQuery';
import { createPreservedLink } from './usePreserveQuery';

const controlPointsOptions = {
  query: QUERY_GET_CONTROL_POINT_EVENTS,
  mapData: (result) => result.calendarControlPointEvents,
  skipQuery: skipIfNull(null)
};

const roadmapOptions = {
  query: QUERY_GET_ROADMAPS_CALENDAR_EVENTS,
  mapData: (result) => result.calendarRoadmapEvents,
  skipQuery: skipIfNull(null)
};

const calendarControlPointsOptions = {
  query: QUERY_GET_CALENDAR_CONTROL_POINTS,
  mapData: (result) => result.calendarControlPoints,
  skipQuery: skipIfNull(null)
};

const allEventsOptions = {
  query: QUERY_GET_ALL_CALENDAR_EVENTS,
  skipQuery: skipIfNull(null)
};

const allEventsStatusOptions = {
  query: QUERY_GET_ALL_CALENDAR_EVENTS_STATUS,
  skipQuery: skipIfNull(null)
};

function filtersAdapter({
  day,
  month,
  quarter,
  federalProjects,
  yearMonth,
  regionCode,
  eventLevel
}) {
  const options = {};
  let hasFilter = false;

  if (day) {
    options.date_start = formatDateIso(day);
    options.date_end = formatDateIso(day);
    hasFilter = true;
  }

  if (month) {
    const firstDay = lStartOfWeek(startOfMonth(month));
    const lastDay = lEndOfWeek(endOfMonth(month));
    options.date_start = formatDateIso(firstDay);
    options.date_end = formatDateIso(lastDay);
    hasFilter = true;
  }

  if (yearMonth) {
    options.date_start = formatDateIso(startOfYearMonth(yearMonth));
    options.date_end = formatDateIso(endOfYearMonth(yearMonth));
    hasFilter = true;
  }

  if (quarter) {
    const firstDayOfMonth = startOfQuarter(quarter);
    const firstDayOfNextMonth = addMonths(firstDayOfMonth, 3);
    options.date_start = formatDateIso(firstDayOfMonth);
    options.date_end = formatDateIso(firstDayOfNextMonth);
    hasFilter = true;
  }

  if (federalProjects) {
    options.federal_project_ids = federalProjects;
    hasFilter = true;
  }

  if (regionCode) {
    options.region_code_nalogs = regionCode;
    hasFilter = true;
  }

  if (eventLevel) {
    options.control_point_owner_type =
      eventLevel === FEDERAL ? 'FEDERAL_RESULT' : 'REGIONAL_RESULT';
    hasFilter = true;
  }

  return [options, hasFilter];
}

function getEventStatus(today, planDate, factDate, completed) {
  const planBeforeToday = isBefore(planDate, today);
  const factBeforePlan = isBeforeOrEqual(factDate, planDate);
  if (completed) {
    if (factBeforePlan) {
      return ProjectIndicator.SUCCESS;
    } else {
      return ProjectIndicator.WARN;
    }
  } else {
    if (planBeforeToday) {
      return ProjectIndicator.ERROR;
    } else {
      return null;
    }
  }
}

function convertControlPointEvents(events, today) {
  const totalData = [];
  events.forEach((item) => {
    item.plan_events &&
      item.plan_events.forEach((event) => {
        if (!event.date_end) {
          return;
        }

        let { date_start, date_end } = event;
        date_start = tryParseIso(date_start);
        date_end = tryParseIso(date_end);

        // todo подтянуть поле из БД
        const completed = isBefore(date_end, today);
        const newData = {
          date_start,
          date_end,
          status: getEventStatus(today, date_end, date_end, completed),
          until: null,
          text: item.description || item.name,
          id: item.id,
          type: 'Мероприятие контрольной точки'
        };
        const planData = item.plan_event && item.plan_event.plan_data;
        if (planData) {
          newData.project = parseFederalOrRegionalProjectData({
            federal_project: planData.federal_project,
            regional_project: planData.regional_project
          });
        }
        if (item.executor && item.executor.name) {
          newData.executor = {
            avatar: null,
            name: item.executor.name
          };
        }
        if (item.control_point) {
          newData.owner = {
            id: item.control_point.id,
            title: 'Контрольная точка',
            name: getDataName(item.control_point)
          };
        }
        const owner_federal_result = item.control_point.owner_federal_result;
        if (owner_federal_result) {
          newData.description = owner_federal_result.description;
          newData.result = {
            id: owner_federal_result.id,
            title: 'Результат',
            name: getDataName(owner_federal_result),
            link: `/indicators/results/${owner_federal_result.id}`
          };
        }

        const owner_regional_result = item.control_point.owner_regional_result;
        if (owner_regional_result) {
          const region = owner_regional_result.region;

          newData.regionalResult = {
            id: owner_regional_result.id,
            name: getDataName(owner_regional_result),
            title: 'Региональный результат',
            region: getDataName(region),
            link: region
              ? `/regions/${+region.code_nalog}/results/${
                  owner_regional_result.id
                }`
              : undefined
          };

          if (region) {
            newData.region = {
              id: region.id,
              title: 'Регион',
              code_nalog: region.code_nalog,
              name: getDataName(region),
              link: `/regions/${+region.code_nalog}`
            };
          }
        }

        totalData.push(newData);
      });
  });
  return totalData;
}

function convertRoadmapEvents(events, today) {
  const totalData = [];
  events.forEach((item) => {
    if (item.plan_term || (item.plan_roadmap && item.plan_roadmap.term)) {
      const date_end = parseISO(item.plan_term || item.plan_roadmap.term);
      const fact_date = parseISO(item.fact_term);
      const completed =
        item.count_region_status?.length === 1 &&
        item.count_region_status[0].status === RoadmapEventStatus.COMPLETED;
      const newData = {
        date_end,
        status: getEventStatus(today, date_end, fact_date, completed),
        until: null,
        text: getDataName(item),
        id: item.id,
        executor: null,
        responsibilityFederal: item.responsibility_level_vo,
        responsibilitySubject: item.responsibility_level_rf_subject,
        responsiblePerson: item.responsible_person,
        description: item.description,
        type: 'Мероприятие дорожной карты',
        sourceRoadmapEvent: item
      };
      const planData = item.plan_roadmap && item.plan_roadmap.plan_data;
      if (planData) {
        newData.project = parseFederalOrRegionalProjectData({
          federal_project: planData.federal_project,
          regional_project: planData.regional_project
        });
      }
      if (item.roadmap) {
        newData.owner = {
          id: item.roadmap.id,
          title: 'Дорожная карта',
          name: getDataName(item.roadmap),
          link: `/indicators/roadmap/${item.roadmap.id}`
        };
      }

      if (item.roadmap && item.roadmap.result) {
        newData.result = {
          id: item.roadmap.result.id,
          title: 'Результат',
          name: getDataName(item.roadmap.result),
          link: `/indicators/results/${item.roadmap.result.id}`
        };
      }

      totalData.push(newData);
    }
  });
  return totalData;
}

function convertControlPoints(control_points, today) {
  const totalData = [];
  control_points.forEach((item) => {
    item.plan_events &&
      item.plan_events.forEach((event) => {
        if (!event.implementation_period_of_cp) {
          return;
        }
        const date_end = parseISO(event.implementation_period_of_cp);
        const completed = isBefore(date_end, today);
        const newData = {
          date_end,
          status: getEventStatus(today, date_end, date_end, completed),
          until: null,
          text: getDataName(item),
          id: item.id,
          executor: null,
          description: item.description,
          type: 'Контрольная точка'
        };
        if (event.plan_data) {
          newData.project = parseFederalOrRegionalProjectData({
            federal_project: event.plan_data.federal_project,
            regional_project: event.plan_data.regional_project
          });
        }
        if (item.owner_federal_result) {
          newData.result = {
            id: item.owner_federal_result.id,
            title: 'Результат',
            name: getDataName(item.owner_federal_result),
            link: `/indicators/results/${item.owner_federal_result.id}`
          };
        }
        if (item.owner_regional_result) {
          newData.regionalResult = {
            id: item.owner_regional_result.id,
            name: getDataName(item.owner_regional_result),
            region: getDataName(item.owner_regional_result.region)
          };

          const region = item.owner_regional_result?.region;
          if (region) {
            newData.region = {
              id: region.id,
              title: 'Регион',
              code_nalog: region.code_nalog,
              name: getDataName(region)
            };
          }
        }

        totalData.push(newData);
      });
  });
  return totalData;
}

// todo удалить старый код после перехода на useAllCalendarEvents
export function useCalendarControlPointEvents(filters) {
  const [options, hasFilter] = filtersAdapter(filters);

  return useGraphqlQuery({
    queryOptions: controlPointsOptions,
    variables: hasFilter ? { filter: options } : null
  });
}

export function calendarControlPointEventsAdapter(events) {
  if (!events || !events.state.data) return [];
  const today = new Date();
  return convertControlPointEvents(events.state.data, today);
}

export function useCalendarRoadmapEvents(filters) {
  const [options, hasFilter] = filtersAdapter(filters);

  return useGraphqlQuery({
    queryOptions: roadmapOptions,
    variables: hasFilter ? { filter: options } : null
  });
}

export function calendarRoadmapEventsAdapter(events) {
  if (!events || !events.state.data) return [];
  const today = new Date();
  return convertRoadmapEvents(events.state.data, today);
}

export function useCalendarControlPoints(filter) {
  const [options, hasFilter] = filtersAdapter(filter);

  return useGraphqlQuery({
    queryOptions: calendarControlPointsOptions,
    variables: hasFilter ? { filter: options } : null
  });
}

export function calendarControlPointsAdapter(events) {
  if (!events || !events.state.data) return [];
  const today = new Date();
  return convertControlPoints(events.state.data, today);
}
// todo end

function parseFederalOrRegionalProjectData({
  federal_project,
  regional_project
}) {
  let project;
  if (regional_project) {
    project = {
      id: regional_project.id,
      name: getDataName(regional_project),
      title: 'Региональный проект',
      link: createPreservedLink('/indicators', {
        fp: regional_project.federal_project.project_code,
        rp: regional_project.code
        //todo: понять, где используется rp и link в целом. И используется ли вообще?!
      }),
      project_code: regional_project.project_code
    };
  } else if (federal_project) {
    project = {
      id: federal_project.id,
      name: getDataName(federal_project),
      title: 'Федеральный проект',
      link: createPreservedLink('/indicators', {
        fp: federal_project.project_code
      }),
      project_code: federal_project.project_code
    };
  }
  return project;
}

export function useAllCalendarEvents(filters, eventType) {
  const [options, hasFilter] = filtersAdapter(filters);
  const query = useGraphqlQuery({
    queryOptions: allEventsOptions,
    variables: hasFilter ? { filter: options } : {}
  });
  const currentDate = useCurrentDate();
  const [controlPointEvents, controlPoints, roadmapEvents] = useMemo(() => {
    return [
      !eventType || eventType === CONTROL_POINT_EVENTS,
      !eventType || eventType === CONTROL_POINTS,
      !eventType || eventType === ROADMAP_EVENTS
    ];
  }, [eventType]);

  return useMemo(() => {
    return mapRequestQuery(query, (data) => {
      const result = [];

      if (data.controlPointEvents && controlPointEvents) {
        result.push(
          ...convertControlPointEvents(data.controlPointEvents, currentDate)
        );
      }

      if (data.controlPoints && controlPoints) {
        result.push(...convertControlPoints(data.controlPoints, currentDate));
      }

      if (data.roadmapEvents && roadmapEvents) {
        result.push(...convertRoadmapEvents(data.roadmapEvents, currentDate));
      }

      return result;
    });
  }, [query, controlPointEvents, controlPoints, roadmapEvents, currentDate]);
}

export function useCalendarEventsStatus(filters, eventType) {
  const [options, hasFilter] = filtersAdapter(filters);
  const query = useGraphqlQuery({
    queryOptions: allEventsStatusOptions,
    variables: hasFilter ? { filter: options } : {}
  });
  const [controlPointEvents, controlPoints, roadmapEvents] = useMemo(() => {
    return [
      !eventType || eventType === CONTROL_POINT_EVENTS,
      !eventType || eventType === CONTROL_POINTS,
      !eventType || eventType === ROADMAP_EVENTS
    ];
  }, [eventType]);

  return useMemo(() => {
    return mapRequestQuery(query, (data) => {
      let result = [];

      if (data.controlPointEvents && controlPointEvents) {
        result.push(...data.controlPointEvents);
      }

      if (data.controlPoints && controlPoints) {
        result.push(...data.controlPoints);
      }

      if (data.roadmapEvents && roadmapEvents) {
        result.push(...data.roadmapEvents);
      }

      return result;
    });
  }, [query, controlPointEvents, controlPoints, roadmapEvents]);
}
