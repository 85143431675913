import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3';
import { formatNumber } from '../../utils/formatNumber';
import colors from '../../colors.scss';

const labels = ['', 'k', 'm', 'mm', 't'];
export function getRowLabelShort(value, decimals) {
  let i = 0;
  let result = value;
  while (i < labels.length - 1 && result >= 1000) {
    result /= 1000;
    i++;
  }
  return Math.round(result) + labels[i];
}

/**
 *
 * @param min - минимальное значение
 * @param max - максимальное значение
 * @param steps - количество шагов интерполяции
 * @param decimals - количетсво знаков после запятой
 * @param paddingVertical - Отступы сверху и снизу
 * @param legendPadding - отступ от легенды до начала линий
 * @param legendWidth - ширина легенды
 * @param sizeHeight - Общая высота
 * @param sizeWidth - общая ширина
 * @param columnLeftPadding - левый отступ начала легенды вертикальной
 * @param lineRightPadding
 * @param columnRightPadding - правый отступ окончания легенды вертикальной
 * @param columnData
 * @param unit - Единица измерения
 * @param firstLineBold - Слитная ли или пунктирная первая линия (горизонтальная
 * линия от нулевого значения)
 * @param getRowLabel - Функция отображения подписи в вертикальной легенде
 * @param className - Стиль
 * @constructor
 */
export function ChartLegend({
  min,
  max,
  steps,
  decimals,
  paddingTop,
  paddingBottom,
  legendPadding,
  legendWidth,
  sizeHeight,
  sizeWidth,
  columnLeftPadding,
  lineRightPadding,
  columnRightPadding,
  columnData,
  unit,
  firstLineBold,
  className,
  getRowLabel = formatNumber,
  ...props
}) {
  const positionInterpolator = scaleLinear()
    .domain([1, steps])
    .range([sizeHeight - paddingBottom, paddingTop]);

  const positionLineInterpolator = scaleLinear()
    .domain([1, steps])
    .range([sizeHeight - paddingBottom, paddingTop]);

  const valueInterpolator = scaleLinear()
    .domain([1, steps])
    .range([min, max]);

  const xInterpolator = scaleLinear()
    .domain([1, columnData && columnData.length])
    .range([
      legendWidth + columnLeftPadding,
      sizeWidth - columnRightPadding - lineRightPadding
    ]);

  const NumberCreator = ({ value, x, y }, iStep) => {
    return (
      <text
        key={`text-${iStep}`}
        x={x}
        y={y}
        fontSize={10}
        fill={colors.grayDark}
        alignmentBaseline={'central'}
        textAnchor={'end'}
      >
        {getRowLabel(value, decimals)}
      </text>
    );
  };

  const LineCreator = ({ x, y, width, bold }, iStep) => {
    return (
      <line
        key={`line-${iStep}`}
        x1={x}
        y1={y}
        x2={x + width - lineRightPadding}
        y2={y}
        strokeWidth={1}
        stroke={colors.grayDark}
        opacity={0.2}
        strokeDasharray={firstLineBold && bold ? bold : '4,6'}
        shapeRendering="crispEdges"
      />
    );
  };

  const legendColumnCreator = ({ x, y, step }, iStep) => {
    return (
      <text
        key={iStep}
        x={x}
        y={y}
        textAnchor={'middle'}
        fontSize={10}
        fill={colors.grayDark}
      >
        {columnData[step - 1]}
      </text>
    );
  };

  const legendRows = [];
  const lineRows = [];
  const legendColumn = [];

  for (let iStep = 1; iStep <= steps; iStep++) {
    const rowLegend = {
      x: legendWidth - legendPadding,
      y: positionInterpolator(iStep),
      value: valueInterpolator(iStep)
    };

    const rowLine = {
      x: legendWidth,
      y: positionLineInterpolator(iStep),
      width: sizeWidth - legendWidth,
      bold: iStep === 1 ? '4,6' : null
    };

    lineRows.push(LineCreator(rowLine, iStep));
    legendRows.push(NumberCreator(rowLegend, iStep));
  }

  if (columnData) {
    for (let i = 1; i <= columnData.length; i++) {
      const columnLegendData = {
        x: xInterpolator(i),
        y: sizeHeight,
        step: i
      };

      legendColumn.push(legendColumnCreator(columnLegendData, i));
    }
  }

  return (
    <svg
      width={sizeWidth}
      height={sizeHeight}
      className={className}
      viewBox={`0 0 ${sizeWidth} ${sizeHeight}`}
      {...props}
    >
      <g
        style={{
          transform: `rotate(-90deg) translate(${-sizeHeight / 2}px, 10px)`
        }}
      >
        <text
          x={0}
          y={0}
          width={10}
          textAnchor={'middle'}
          fontSize={10}
          fill={colors.grayDark}
        >
          {unit}
        </text>
      </g>
      {lineRows}
      {legendRows}
      {legendColumn}
    </svg>
  );
}

ChartLegend.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  steps: PropTypes.number,
  decimals: PropTypes.number,
  paddingVertical: PropTypes.number,
  legendPadding: PropTypes.number,
  legendWidth: PropTypes.number,
  sizeHeight: PropTypes.number,
  sizeWidth: PropTypes.number,
  unit: PropTypes.string,
  className: PropTypes.string,
  columnLeftPadding: PropTypes.number,
  lineRightPadding: PropTypes.number,
  columnRightPadding: PropTypes.number,
  firstLineBold: PropTypes.bool
};

ChartLegend.defaultProps = {
  steps: 6,
  decimals: 0,
  paddingTop: 6,
  paddingBottom: 6,
  legendPadding: 8,
  legendWidth: 32,
  columnLeftPadding: 0,
  columnRightPadding: 0,
  lineRightPadding: 0,
  firstLineBold: true
};
