import React from 'react';
import classNames from 'classnames';
import { ChipRadio } from './ChipRadio';
import s from './Chip.module.scss';

export function ChipRadioGroup({
  className,
  chipClassName,
  value,
  options,
  onChange,
  chipProps,
  ...props
}) {
  return (
    <div className={classNames(s.ChipRadioGroup, className)} {...props}>
      {options.map((option, index) => {
        return (
          <ChipRadio
            key={index}
            className={chipClassName}
            selected={value}
            onChange={onChange}
            {...option}
            {...chipProps}
          />
        );
      })}
    </div>
  );
}
