import React, { useState } from 'react';
import classNames from 'classnames';
import { Select } from '../ui/Select/Select';
import { GrbsFilter } from '../Filters/GrbsFilter';
import { ResizeContainer } from '../ResizeContainer';
import { ChartViewChart } from '../ChartView/ChartView';
import { queryLoader } from '../QueryLoader';
import { hideOutBudgetSources } from '../../config';
import s from './RegionalBudgetCharts.module.scss';
import colors from '../../colors.scss';

const outbudgetChart = [
  {
    id: 1,
    name: 'План',
    values: [
      {
        id: 2,
        name: 'План',
        value: 0
      }
    ]
  },
  {
    id: 2,
    name: 'Факт',
    values: [
      {
        id: 1,
        name: 'Факт',
        value: 0
      }
    ]
  }
];
const budgets = [
  { label: 'Региональный бюджет в разрезе видов расходов', value: 'types' },
  {
    label: 'Региональный бюджет по источникам финансирования',
    value: 'sources'
  },
  { label: 'Бюджет муниципальных образований', value: 'local' }
];

if (!hideOutBudgetSources) {
  budgets.push({ label: 'Внебюджетные источники', value: 'outbudget' });
}

export const MainChart = ({
  budgetQuery,
  budgetData: data,
  vrChart,
  sourcesChart,
  localChart
}) => {
  const allCharts = [
    {
      id: 'types',
      name: `Региональный бюджет в разрезе видов расходов`,
      charts: vrChart
        ? [
            {
              name: '',
              data: vrChart
            }
          ]
        : null
    },
    {
      id: 'sources',
      name: 'Бюджет субъекта по источникам финансирования',
      charts: sourcesChart
        ? [
            {
              name: '',
              data: sourcesChart
            }
          ]
        : null
    },
    {
      id: 'local',
      name: 'Бюджет муниципальных образований',
      charts: localChart
        ? [
            {
              name: '',
              data: localChart
            }
          ]
        : null
    }
  ];

  if (!hideOutBudgetSources) {
    allCharts.push({
      id: 'outbudget',
      name: 'Внебюджетные источники',
      charts: [{ data: outbudgetChart }]
    });
  }

  const [budget, setBudget] = useState(budgets[0]);

  const currentCharts = allCharts.find((v) => v.id === budget.value).charts;

  return (
    <div className={s.MainChart}>
      <div className={s.MainChart__filters}>
        <div className={s.MainChart__filter}>
          <Select
            height={Select.SMALL}
            variant={Select.SECONDARY}
            className={s.MainChart__filterSelect}
            value={budget}
            values={budgets}
            onChange={setBudget}
          />
        </div>
        <div
          className={classNames(s.MainChart__filter, s.MainChart__filterRow)}
        >
          <GrbsFilter height={Select.SMALL} variant={Select.SECONDARY} />
        </div>
      </div>
      <div className={s.MainChart__view}>
        {queryLoader(budgetQuery) ||
          (currentCharts && (
            <ResizeContainer>
              {({ width }) => (
                <ChartViewChart
                  data={currentCharts}
                  width={width}
                  height={192}
                  chartProps={{
                    color: colors.white,
                    textColor: colors.white
                  }}
                  titleClassName={s.ChartData__title}
                />
              )}
            </ResizeContainer>
          ))}
      </div>
    </div>
  );
};
