import { filesUrl } from '../../config';

const url = (path) => `${filesUrl}${path}`;

export const fpFilesPath = {
  np: [
    { fileName: 'pdf', url: url('/НП Образование.pdf') },
    { fileName: 'docx', url: url('/Паспорт НПО.docx') }
  ],
  federalProject: [
    {
      fpCode: 1,
      fileName: 'docx',
      url: url('/ФП. Е1. Современная школа.docx')
    },
    {
      fpCode: 2,
      fileName: 'docx',
      url: url('/ФП. Е2. Успех каждого ребенка.docx')
    },
    {
      fpCode: 3,
      fileName: 'docx',
      url: url('/ФП. Е3. Поддержка семей, имеющих детей.docx')
    },
    {
      fpCode: 4,
      fileName: 'docx',
      url: url('/ФП. Е4. Цифровая образовательная среда.docx')
    },
    {
      fpCode: 5,
      fileName: 'docx',
      url: url('/ФП. Е5. Учитель будущего.docx')
    },
    {
      fpCode: 6,
      fileName: 'docx',
      url: url('/ФП. Е6. Молодые профессионалы.docx')
    },
    {
      fpCode: 7,
      fileName: 'docx',
      url: url('/ФП. Е7. Новые возможности для каждого.docx')
    },
    {
      fpCode: 8,
      fileName: 'docx',
      url: url('/ФП. Е8. Социальная активность.docx')
    },
    {
      fpCode: 9,
      fileName: 'docx',
      url: url('/ФП. Е9. Экспорт образования.docx')
    },
    {
      fpCode: 10,
      fileName: 'docx',
      url: url('/ФП. Е10. Социальные лифты для каждого.docx')
    }
  ]
};
