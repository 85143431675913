import React, { useState } from 'react';
import ResizeObserver from 'react-resize-observer';

export function ResizeContainer({ children }) {
  const [size, setSize] = useState(null);
  return (
    <>
      <ResizeObserver onResize={setSize} />
      {size && children(size)}
    </>
  );
}
