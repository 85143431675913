import React, { useCallback, useState } from 'react';
import { TopBar } from '../../common/TopBar/TopBar';
import { MapFiltersPopup } from '../../common/Map/MapFiltersPopup/MapFiltersPopup';
import MapView from '../../common/Map/MapView/MapView';
import { useSelectedFp } from '../../store/useFederalProjects';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { useFPRoadmapQuery } from '../../store/useRoadmaps';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { MapPageLayout } from '../_layouts/MapPageLayout';
import { TopBarAllProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { useFederalDistrictAchActualityDate } from '../../store/useFederalDistricts';
import { RussiaMap } from './RussiaMap';

function MapPage({ location, history }) {
  const [isFiltersOpen, setIsFilterOpen] = useState(false);
  const [queryParams, changeQuery] = useUrlQuery();
  const { year, yearMonth } = useQueryPeriodFast(queryParams, changeQuery);
  const selectedFp = useSelectedFp();

  const { indicator_type } = queryParams;
  const { createLink } = usePreserveQueryFast(queryParams, [
    'indicator_type',
    'organization_type',
    'res',
    'roadmap'
  ]);
  const onNavigateToDistrict = useCallback(
    (code) => history.push(createLink(`/districts/${code}`)),
    [history, createLink]
  );

  const roadmapQuery = useFPRoadmapQuery(
    {
      federal_project_id: selectedFp && selectedFp.id,
      subsidiary_entity_filter: {
        years: year
      }
    },
    {
      month: yearMonth,
      budget_levels: ['federal']
    }
  );
  const cards = roadmapQuery.state.data;

  const achievementsActualityDate = useFederalDistrictAchActualityDate(
    yearMonth
  );

  return (
    <MapPageLayout>
      <TopBar>
        <TopBarAllProjects replaceQuery={true} />
      </TopBar>
      <MapView
        cards={cards}
        changeQuery={changeQuery}
        history={history}
        onOpenFilter={() => setIsFilterOpen(true)}
        queryParams={queryParams}
        titleText={'Российская Федерация'}
        onGoBack={() => history.goBack(createLink('/'))}
        actualityDate={achievementsActualityDate[indicator_type]}
      >
        <RussiaMap
          onNavigate={onNavigateToDistrict}
          queryParams={queryParams}
          selectedFp={selectedFp}
        />
      </MapView>
      <MapFiltersPopup
        isVisible={isFiltersOpen}
        onClose={() => setIsFilterOpen(false)}
      />
    </MapPageLayout>
  );
}

export default MapPage;
