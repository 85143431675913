import React from 'react';
import { addDays, format } from 'date-fns';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import { DATE_FORMAT_RUSSIAN, reformatDate } from '../../../utils/date';
import { singleItemArray } from '../../../utils/data';
import { ExternalLinkDomain } from '../../../common/ui/Link/ExternalLink';
import {
  BadgeText,
  InfoItem,
  InfoItemsList,
  MultiInfoItem,
  SingleInfoItem,
  TitleInfoItem
} from '../../../common/InfoItem/InfoItem';
import IconLoc from '../../../assets/img/icons/objectAbout/i-loc.svg';
import IconFlag from '../../../assets/img/icons/objectAbout/i-flag.svg';
import IconPhone from '../../../assets/img/icons/objectAbout/i-phone.svg';
import IconLetter from '../../../assets/img/icons/objectAbout/i-letter.svg';
import IconWeb from '../../../assets/img/icons/objectAbout/i-web.svg';
import IconBuilding from '../../../assets/img/icons/objectAbout/i-building.svg';
import IconAccess from '../../../assets/img/icons/objectAbout/i-access.svg';
import IconPerson from '../../../assets/img/icons/objectAbout/i-person.svg';
import s from './ObjectAbout.module.scss';

export const ObjectAbout = ({ organization, actualityDate }) => {
  const nextDate = addDays(actualityDate, 1);
  return (
    <div className={s.ObjectAbout}>
      <PageSubTitle
        className={s.ObjectAbout__title}
        text={organization.name_full}
      />
      <div className={s.ObjectAbout__infos}>
        <InfoItemsList>
          <TitleInfoItem
            icon={IconFlag}
            item={reformatDate(organization.registration_date)}
            title="Дата постановки на учёт"
          />
          <SingleInfoItem
            icon={IconLoc}
            item={singleItemArray(organization.bs_address)}
          />
          <MultiInfoItem icon={IconPhone} items={organization.phones} />
          <MultiInfoItem icon={IconLetter} items={organization.emails} />
          <InfoItem icon={IconWeb}>
            {organization.bs_organization_site && (
              <ExternalLinkDomain href={organization.bs_organization_site} />
            )}
          </InfoItem>
          <InfoItem icon={IconBuilding}>
            <p>{organization.bs_organization_subtype}</p>
            <p className={s._infoItemTitle}>
              {organization.bs_organization_type}
            </p>
          </InfoItem>
          <SingleInfoItem
            icon={IconAccess}
            item={organization.inn ? `ИНН ${organization.inn}` : null}
          />
          {organization.directors &&
            organization.directors.map(
              (item, iItem) =>
                item.individual && (
                  <TitleInfoItem
                    key={iItem}
                    icon={IconPerson}
                    item={item.individual.name}
                    title="Руководитель"
                  />
                )
            )}
          <TitleInfoItem
            icon={IconPerson}
            item={organization.bs_chief_manager}
            title="Учредитель"
          />
          <BadgeText
            title={'Малокомплектная школа'}
            isShow={organization.is_small_school}
          />
        </InfoItemsList>
        {actualityDate ? (
          <div className={s.ObjectAbout__actualDate}>
            Общие данные загружены с сайта bus.gov.ru по состоянию на{' '}
            {format(actualityDate, DATE_FORMAT_RUSSIAN)} (22:30 по московскому
            времени),
            <br />
            следующее обновление произойдет{' '}
            {format(nextDate, DATE_FORMAT_RUSSIAN)} (22:30 по московскому
            времени)
          </div>
        ) : null}
      </div>
    </div>
  );
};
