import React, { useCallback, useEffect, useState } from 'react';
import { Portal } from '../../portal';
import { PageTitle } from '../../PageTitle/PageTitle';
import { Button } from '../../ui/Button/Button';
import { useSearchResults } from '../../../routes/SearchPage/entities/result';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { useSearchFederalProjects } from '../../../routes/SearchPage/entities/federalProjects';
import { Project } from '../../../data/project';
import { ItemList } from './ItemList';
import { FiltersHead } from './FiltersHead';
import s from './MapFiltersPopup.module.scss';

const links = [
  {
    label: 'Федеральные проекты',
    value: 'federalProjects'
  },
  {
    label: 'Результаты',
    value: 'results'
  }
];

const defaultFilters = {
  federalProjects: {},
  results: {}
};

function parseQueryFp(param) {
  if (+param) {
    return {
      [param]: true
    };
  }
  return {};
}

function parseQueryArray(param) {
  const items = (param && param.split(',')) || [];
  const result = {};
  for (const item of items) {
    result[item] = true;
  }
  return result;
}

function formatQueryArray(items) {
  const result = getTypeFilters(items);
  return result.join(',') || undefined;
}

function formatQuerySingle(items) {
  for (let [key, value] of Object.entries(items)) {
    if (value) {
      return key;
    }
  }
  return undefined;
}

function parseQueryFilters(query) {
  const { fp, res } = query;
  const filters = { ...defaultFilters };
  filters.federalProjects = parseQueryFp(fp);
  filters.results = parseQueryArray(res);
  return filters;
}

function encodeQueryFilters(filters) {
  return {
    fp: formatQuerySingle(filters.federalProjects),
    res: formatQueryArray(filters.results)
  };
}

function getTypeFilters(items) {
  const result = [];
  for (let [key, value] of Object.entries(items)) {
    if (value) {
      result.push(key);
    }
  }
  return result;
}

const fpSort = (a, b) =>
  Project.getFederalProjectCode(a) < Project.getFederalProjectCode(b) ? -1 : 1;

function MapFiltersPopupInstance({ onClose, regionCode, regionalProjects }) {
  const [query, changeQuery] = useUrlQuery();
  const { search } = query;
  const [view, setView] = useState(null);

  const [filters, setFilters] = useState(() => parseQueryFilters(query));

  const fpQuery = useSearchFederalProjects(search, fpSort, regionalProjects);
  const federalProjects = fpQuery.state.data;

  const resultsQuery = useSearchResults(
    search,
    {
      region_code_nalogs: regionCode,
      federal_project_codes: formatQuerySingle(filters.federalProjects)
    },
    true
  );
  const results = resultsQuery.state.data;

  const onChangeFilters = useCallback((newValue) => {
    setFilters((filters) => ({ ...filters, ...newValue }));
  }, []);

  const handleAccept = useCallback(() => {
    changeQuery(encodeQueryFilters(filters), true);
    onClose();
  }, [changeQuery, onClose, filters]);

  const handleClear = useCallback(() => {
    changeQuery(encodeQueryFilters(defaultFilters), true);
    onClose();
  }, [changeQuery, onClose]);

  const selectedFps = getTypeFilters(filters.federalProjects);
  const selectedResults = getTypeFilters(filters.results);

  useEffect(() => {
    if (resultsQuery.check.component) {
      const resultsMap = {};
      for (let result of results.list) {
        resultsMap[result.id] = true;
      }

      let changed = false;
      const newSelectedResults = [];
      for (let resultId of selectedResults) {
        if (resultsMap[resultId]) {
          newSelectedResults.push(resultsMap[resultId]);
        } else {
          changed = true;
        }
      }

      if (changed) {
        onChangeFilters({
          results: newSelectedResults
        });
      }
    }
  }, [resultsQuery, results, selectedResults, onChangeFilters]);

  return (
    <Portal id="MapFiltersPopup">
      <div className={s.MapFiltersPopup}>
        <div className={s.MapFiltersPopup__content}>
          <PageTitle
            className={s.MapFiltersPopup__PageTitle}
            iconClassName={s.MapFiltersPopup__BackBtn}
            text={
              (view && links.filter((item) => item.value === view)[0].label) ||
              'Расширенная фильтрация'
            }
            onClick={() => (view ? setView(null) : onClose())}
          />
          {view === 'federalProjects' ? (
            <ItemList
              filters={filters.federalProjects}
              onChangeFilters={(federalProjects) =>
                onChangeFilters({ federalProjects: federalProjects })
              }
              single={true}
              itemsQuery={fpQuery}
              itemId={Project.getFederalProjectCode}
            />
          ) : view === 'results' ? (
            <ItemList
              filters={filters.results}
              onChangeFilters={(results) => onChangeFilters({ results })}
              itemsQuery={resultsQuery}
            />
          ) : (
            <FiltersHead
              setView={setView}
              query={query}
              changeQuery={changeQuery}
              links={[
                {
                  label: 'Федеральные проекты',
                  value: 'federalProjects',
                  count: federalProjects && federalProjects.header.totalCount,
                  selected: selectedFps.length
                },
                {
                  label: 'Результаты',
                  value: 'results',
                  count: results && results.header.totalCount,
                  selected: selectedResults.length
                }
              ]}
            />
          )}
        </div>
        <div className={s.MapFiltersPopup__btns}>
          <Button className={s.MapFiltersPopup__apply} onClick={handleAccept}>
            Применить
          </Button>
          <Button
            variant={Button.SECONDARY}
            className={s.MapFiltersPopup__clear}
            onClick={handleClear}
          >
            Сбросить все
          </Button>
        </div>
      </div>
    </Portal>
  );
}

export function MapFiltersPopup({ isVisible, ...props }) {
  if (!isVisible) return null;
  return <MapFiltersPopupInstance {...props} />;
}
