import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { QUERY_GET_ACTUALITY_DATE_BY_FINANCE } from '../graphql/queries/federalDistricts';
import { getPlanAndObligationDate } from '../utils/date';

const actualityDateByFinanceOptions = {
  query: QUERY_GET_ACTUALITY_DATE_BY_FINANCE,
  mapData: (x) => x.finances,
  skipQuery: skipIfNull(null)
};

export const useActualityDateByFinance = (variables) => {
  const query = useGraphqlQuery({
    queryOptions: actualityDateByFinanceOptions,
    variables: variables
  });

  return getPlanAndObligationDate(query.state.data);
};
