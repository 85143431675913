import { BehaviorStore } from '@proscom/prostore';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { isEqual } from 'lodash-es';

export class LocationStore extends BehaviorStore {
  history;

  constructor({ history }) {
    super({
      location: null,
      query: null
    });

    this.history = history;
  }

  updateLocation(location) {
    const query = parseQuery(location.search);

    this.setState({
      location,
      query
    });
  }

  changeQuery = (changes, replace = false) => {
    const { location, query } = this.state;

    const newLocation = {
      ...location,
      search: stringifyQuery({
        ...query,
        ...changes
      })
    };

    if (replace) {
      this.history.replace(newLocation);
    } else {
      this.history.push(newLocation);
    }
  };

  get$(...items) {
    return this.state$.pipe(
      map((state) => {
        const result = {};
        for (let item of items) {
          result[item] = state.query[item];
        }
        return result;
      }),
      distinctUntilChanged(isEqual)
    );
  }
}
