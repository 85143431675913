import React from 'react';
import classNames from 'classnames';
import { getStatusByValue } from '../../utils/constants';
import { formatNumber } from '../../utils/formatNumber';
import { unificationNumber } from '../../utils/math';
import s from './ProgressBar.module.scss';

export const ProgressBar = ({
  maxValue = 100,
  value,
  text,
  className,
  statusType
}) => {
  const percentValue = formatNumber(
    unificationNumber(value && (value / maxValue) * 100, maxValue),
    1
  );

  return (
    <div className={classNames(s.ProgressBar, className)}>
      <div className={s.ProgressBar__title}>
        {text && <p title={text}>{text}</p>}
        <div className={s.ProgressBar__value}>
          <span>{percentValue}%</span>
        </div>
      </div>
      <div className={s.ProgressBar__bar}>
        <div
          className={classNames(s.ProgressBar__barValue, {
            [s[`_${getStatusByValue(value, statusType)}`]]: value
          })}
          style={{
            width: `${value ? value : 0}%`
          }}
        />
      </div>
    </div>
  );
};
