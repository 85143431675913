import React from 'react';
import { isSameDay } from 'date-fns';
import { ButtonComponent } from '../../common/ui/Button/Button';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { lFormat, tryParseIso } from '../../utils/date';
import { User } from '../../data/user';
import { EMDASH } from '../../utils/utf';
import { DayEventStatus } from './EventList/DayEventList';
import { RoadmapRealization } from './RoadmapRealization/RoadmapRealization';
import s from './CalendarPage.module.scss';

export const Title = ({ title, name, link, content: contentProp }) => {
  const content = contentProp || name;
  return (
    <div className={s.CalendarPageSidebar__additional}>
      <div className={s.CalendarPageSidebar__executorTitle}>{title}</div>
      {content && (
        <div className={s.CalendarPageSidebar__additionalContent}>
          {content}
        </div>
      )}
      {link ? (
        <ButtonComponent to={link} className={s.CalendarPageSidebar__button}>
          Подробнее
        </ButtonComponent>
      ) : null}
    </div>
  );
};

export const FullEvent = ({ date, event, popupRef }) => {
  const currentDate = new Date();
  const [query] = useUrlQuery();
  const calendarDate = tryParseIso(query.date) || currentDate;

  let oppositeDate;
  let isStart;
  if (event.date_start && event.date_end) {
    isStart = isSameDay(event.date_start, calendarDate);
    oppositeDate = isStart
      ? {
          title: 'Дата окончания реализации',
          name: lFormat(event.date_end, 'd MMMM yyyy')
        }
      : {
          title: 'Дата начала реализации',
          name: lFormat(event.date_start, 'd MMMM yyyy')
        };
  }

  let responsiblePerson = EMDASH;
  if (event.responsiblePerson) {
    const {
      first_name = '',
      surname = '',
      patronymic = ''
    } = event.responsiblePerson;
    responsiblePerson = `${surname} ${first_name} ${patronymic}`;
  }
  const fact_term = tryParseIso(event?.sourceRoadmapEvent?.fact_term);

  return (
    <>
      <h3 className={s.CalendarPageSidebar__title}>
        <span className={s.CalendarPage__capitalized}>
          {lFormat(date, 'eeee')}
        </span>
        {lFormat(date, ', d MMMM yyyy')}
      </h3>
      <DayEventStatus item={event} />
      <p className={s.CalendarPageSidebar__fullName}>{event.text}</p>
      {oppositeDate ? <Title {...oppositeDate} /> : null}
      {event.owner ? <Title {...event.owner} /> : null}
      {event.region ? <Title {...event.region} /> : null}
      {event.result ? <Title {...event.result} /> : null}
      {event.regionalResult ? <Title {...event.regionalResult} /> : null}
      {event.project ? <Title {...event.project} /> : null}
      {event.responsibilityFederal ? (
        <Title
          title={'Ответственный за реализацию'}
          content={
            <div>
              Ведомственный проектный офис нацпроекта «Образование»
              <br />
              ФИО: {responsiblePerson}
            </div>
          }
        />
      ) : null}
      {event.responsibilitySubject ? (
        <Title
          title={'Ответственный за реализацию'}
          content={<div>Субъект Российской Федерации</div>}
        />
      ) : null}
      {event.executor && (
        <div className={s.CalendarPageSidebar__executor}>
          <span className={s.CalendarPageSidebar__executorAvatar}>
            {User.getUserAbbreviation(event.executor)}
          </span>
          <div>
            <h4 className={s.CalendarPageSidebar__executorTitle}>
              Исполнитель
            </h4>
            <p className={s.CalendarPageSidebar__executorName}>
              {event.executor.name}
            </p>
          </div>
        </div>
      )}
      {event.description && (
        <div className={s.CalendarPageSidebar__descriptionSection}>
          <h3 className={s.CalendarPageSidebar__title}>Описание</h3>
          <p className={s.CalendarPageSidebar__descriptionText}>
            {event.description}
          </p>
        </div>
      )}
      <RoadmapRealization
        event={event.sourceRoadmapEvent}
        popupRef={popupRef}
      />
      {event.sourceRoadmapEvent && event.sourceRoadmapEvent.fact_term ? (
        <div>
          <div className={s.CalendarPageSidebar__descriptionSection}>
            <h3 className={s.CalendarPageSidebar__title}>
              Фактический срок выполнения
            </h3>
            <p className={s.CalendarPageSidebar__descriptionText}>
              {lFormat(fact_term, 'd MMMM yyyy')}
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
};
