import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'react-resize-observer';
import { degreeToRad, findMinWrapped } from '../../../utils/math';
import { formatNumber } from '../../../utils/formatNumber';
import PlanetPieChart from '../../../common/charts/PlanetPieChart';
import { EMDASH } from '../../../utils/utf';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { useWindowSize } from '../../../store/useWindowSize';
import { Donuts } from './Donuts';
import s from './ProjectsChartView.module.scss';
import color from '../../../colors.scss';

function createPlanetGradient(id, start, end) {
  return [
    {
      id,
      rotate: 90,
      colors: [
        { offset: 0, color: start },
        { offset: 100, color: end }
      ]
    }
  ];
}

const numRings = 2;
const moonSize = 3;
const minPlanetRadius = 3;

function shiftTargetAngle(prevAngle, targetAngle) {
  return findMinWrapped(prevAngle, targetAngle, 360);
}

function ProjectsChartView({
  activeId,
  className,
  chartClassName,
  donutsClassName,
  titleClassName,
  onMainChartClick,
  onSegmentClick,
  isChartViewOpen,
  newData,
  onChangeIndex,
  selectedChartView,
  selectedIndex,
  planetMode,
  isLoading,
  ...props
}) {
  const central = newData?.central;

  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;
  const [size, setSize] = useState(null);
  const prevRotationAngleRef = useRef(0);
  const prevRotationAngle = prevRotationAngleRef.current;
  const mobileBaseAngle = 90;
  const baseWidth = 630;

  const numProjects = newData?.projects?.length;
  let baseRotationAngle =
    numProjects > 0 ? ((360 / numProjects) * selectedIndex) % 360 : 0;
  baseRotationAngle = isMobile
    ? baseRotationAngle - mobileBaseAngle
    : baseRotationAngle;
  const rotationAngle = shiftTargetAngle(prevRotationAngle, baseRotationAngle);

  prevRotationAngleRef.current = rotationAngle;

  let total = null;
  if (activeId) {
    if (central) {
      for (let pie of central.pies) {
        const { data, sum } = pie;
        const segment = data.find((s) => s.id === activeId);
        if (segment) {
          total = segment.value;
          break;
        } else if (data.length > 0 && data[0].pie === activeId) {
          total = sum;
          break;
        }
      }
    }
  } else {
    total =
      selectedChartView === 'mbt'
        ? central?.mbt_total_budget
        : central?.total_budget;
  }

  const totalSize = size ? Math.min(size.width, size.height) : baseWidth;

  const adaptiveRatio = totalSize / baseWidth;
  const maxPlanetSize = 156 * adaptiveRatio;
  const planetRadius = (totalSize - maxPlanetSize) / 2;
  const ringDistance = 12 * adaptiveRatio;
  const maxPlanetRadius =
    maxPlanetSize / 2 - numRings * ringDistance - moonSize;

  return (
    <div
      className={classNames(s.ChartView, className, {
        [s._loading]: isLoading
      })}
      {...props}
    >
      <ResizeObserver onResize={setSize} />
      {isLoading && <CircleProgressIndicator className={s.ChartView__loader} />}
      {size && central && (
        <div className={s.ChartView__content}>
          <PlanetCharts
            totalSize={totalSize}
            planetRadius={planetRadius}
            maxPlanetRadius={maxPlanetRadius}
            maxPlanetSize={maxPlanetSize}
            ringDistance={ringDistance}
            className={chartClassName}
            isBlurred={isChartViewOpen}
            items={newData.projects}
            onPlanetSelect={onChangeIndex}
            rotationAngle={rotationAngle}
            valueMode={planetMode}
          />
          <Donuts
            className={donutsClassName}
            selectedChartView={selectedChartView}
            pies={central.pies}
            adaptiveRatio={adaptiveRatio}
            activeId={activeId}
            onSegmentClick={onSegmentClick}
            onMainChartClick={onMainChartClick}
            isChartViewOpen={isChartViewOpen}
          />
          <div className={classNames(s.ChartView__title, titleClassName)}>
            <div>
              <div>
                {typeof total === 'number'
                  ? formatNumber(total, 2)
                  : typeof total === 'string'
                  ? total
                  : EMDASH}
              </div>
              <div className={s.ChartView__unit}>млрд руб.</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const PlanetCharts = React.memo(
  ({
    isBlurred,
    items,
    onPlanetSelect,
    rotationAngle,
    className,
    totalSize,
    planetRadius,
    maxPlanetRadius,
    maxPlanetSize,
    ringDistance
  }) => {
    if (items.length === 0) {
      return null;
    }

    return (
      <div
        className={classNames(s.ChartView__planets, className, {
          _blurred: isBlurred
        })}
        style={{
          transform: `rotate(${-rotationAngle}deg)`
        }}
      >
        <svg
          className={s.ChartView__planetLine}
          viewBox={`0 0 ${totalSize} ${totalSize}`}
          style={{
            width: totalSize,
            height: totalSize
          }}
        >
          <circle
            cx={totalSize / 2}
            cy={totalSize / 2}
            r={planetRadius}
            fillOpacity={0}
            strokeDasharray={'4, 4'}
            stroke={color.chartDashLine}
          />
        </svg>
        {items.map((item, index) => {
          let rotateAngle = degreeToRad((360 / items.length) * index);
          let rotateAngleDeg = (360 / items.length) * index + 90;
          const { data, colors = {} } = item;

          const chartRadius =
            item.size * (maxPlanetRadius - minPlanetRadius) + minPlanetRadius;

          const value = data.cash_expenses;

          return (
            <PlanetPieChart
              key={index}
              className={s.ChartView__planet}
              onClick={() => onPlanetSelect(index)}
              chartInnerRadius={chartRadius * 0.8}
              chartOuterRadius={chartRadius}
              moonSize={moonSize}
              ringCount={numRings}
              ringDistance={ringDistance}
              size={maxPlanetSize}
              value={value}
              total={data.total_budget}
              rotateAngle={rotateAngleDeg}
              moon={data.budget_limits !== data.total_budget}
              moonColor={color.error}
              totalColor={colors.darkenColor}
              valueColor={colors.secondColor}
              linearGradientLibrary={createPlanetGradient(
                `gradient_${index}`,
                colors.firstColor,
                colors.secondColor
              )}
              style={{
                transform: `translate(
                  ${planetRadius * Math.cos(rotateAngle)}px,
                  ${planetRadius * Math.sin(rotateAngle)}px
                )`,
                width: maxPlanetSize,
                height: maxPlanetSize
              }}
              shadow={
                <div
                  className={s.ChartView__planetShadow}
                  style={{
                    width: chartRadius * 2.5 + 'px',
                    height: chartRadius * 2.5 + 'px',
                    background: `radial-gradient(ellipse at center, ${colors.firstColor} 0%, ${colors.secondColor} 100%)`,
                    transform: `translate(
                    ${planetRadius * Math.cos(rotateAngle)}px,
                    ${planetRadius * Math.sin(rotateAngle)}px
                  )`
                  }}
                />
              }
            />
          );
        })}
      </div>
    );
  }
);

export default ProjectsChartView;
