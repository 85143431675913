import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { PageTitle } from '../../PageTitle/PageTitle';
import { MapFilters } from '../MapFilters/MapFilters';
import RangeSlider from '../../ui/RangeSlider/RangeSlider';
import { MapCards } from '../../../routes/SubjectPage/MapCards/MapCards';
import { ChipRadioGroup } from '../../ui/Chip/ChipRadioGroup';
import { tryNumber } from '../../../utils/math';
import { formatValueUnit } from '../../../utils/formatNumber';
import {
  INDICATOR_INTERNET_SPEED,
  mapIndicators,
  MAX_INTERNET_CITY,
  MAX_INTERNET_COUNTRYSIDE,
  organizationTypes,
  SPEED_TYPE_COUNTRYSIDE,
  speedSources,
  speedTypes
} from '../../../utils/indicators';
import { useWindowSize } from '../../../store/useWindowSize';
import { ReactComponent as IconClose } from '../../../assets/Icons/Check.svg';
import s from './MapView.module.scss';

const MapViewTitle = ({ text, onClick }) => (
  <PageTitle
    className={s.MapView__PageTitle}
    iconClassName={s.pointer__activeEvent}
    titleClassName={s.pointer__activeEvent}
    text={text}
    onClick={onClick}
  />
);

const MapViewRanger = ({
  indicatorId,
  speedType,
  rangeMin,
  rangeMax,
  formatRangeValue,
  changeQuery,
  isMobile,
  isSubjectView
}) => {
  const [paneOpened, setPaneOpened] = useState(false);

  const onPaneOpen = () => {
    setPaneOpened(true);
  };
  const onPaneClose = () => {
    setPaneOpened(false);
  };

  if (isSubjectView && !(indicatorId === INDICATOR_INTERNET_SPEED)) {
    return null;
  }

  return (
    <div className={s.MapView__ranger}>
      <div
        className={classNames(s.MapView__pane, {
          [s._opened]: paneOpened
        })}
        onClick={isMobile && !paneOpened ? onPaneOpen : null}
      >
        {indicatorId === INDICATOR_INTERNET_SPEED && (
          <ChipRadioGroup
            value={speedType}
            options={speedTypes}
            onChange={(value) => changeQuery({ speed_type: value }, true)}
          />
        )}
        {!isSubjectView ? (
          <div className={s.MapView__rangerContainer}>
            <RangeSlider
              min={0}
              max={100}
              value={[rangeMin, rangeMax]}
              collapsed={!paneOpened}
              formatValue={formatRangeValue}
              onChange={(range) =>
                changeQuery({ range_min: range[0], range_max: range[1] }, true)
              }
              isMobile={isMobile}
              indicatorId={indicatorId}
            />
          </div>
        ) : null}
        {isMobile && paneOpened && (
          <div className={s.MapView__paneClose} onClick={onPaneClose}>
            <IconClose />
          </div>
        )}
      </div>
    </div>
  );
};

const MapView = ({
  cards,
  changeQuery,
  children,
  onOpenFilter,
  queryParams,
  titleText,
  onGoBack,
  className,
  isSubjectView,
  overlayClassName,
  actualityDate,
  selectedFp,
  isMobile: isMobileProp,
  districtCode
}) => {
  const ws = useWindowSize();
  const isMobile = isMobileProp ? isMobileProp : ws.isMobile;

  const typeId = queryParams.organization_type || 'ALL';
  const speedSource = queryParams.speed_source || speedSources[0].value;

  const indicatorId = queryParams.indicator_type || 'none';
  const speedType = queryParams.speed_type || speedTypes[0].value;
  const max =
    indicatorId === INDICATOR_INTERNET_SPEED
      ? speedType === SPEED_TYPE_COUNTRYSIDE
        ? MAX_INTERNET_COUNTRYSIDE
        : MAX_INTERNET_CITY
      : 100;
  const rangeMin = tryNumber(queryParams.range_min, 0);
  const rangeMax = tryNumber(queryParams.range_max, 100);
  const unit = indicatorId === INDICATOR_INTERNET_SPEED ? ' мбит/с' : '%';
  const formatRangeValue = (v) => formatValueUnit((v * max) / 100, unit, 0);

  const organizationType = useMemo(
    () => organizationTypes.find((t) => t.value === typeId),
    [typeId]
  );
  const indicatorType = useMemo(
    () => mapIndicators.find((t) => t.value === indicatorId),
    [indicatorId]
  );

  return (
    <>
      <div className={classNames(s.MapView, className)}>
        <div className={classNames(s.MapView__overlay, overlayClassName)}>
          <MapViewTitle text={titleText} onClick={onGoBack} />
          <MapFilters
            className={s.MapView__filters}
            changeQuery={changeQuery}
            onOpenFilter={onOpenFilter}
            queryParams={queryParams}
            indicatorId={indicatorId}
            indicatorType={indicatorType}
            organizationType={organizationType}
            speedSource={speedSource}
            selectedFp={selectedFp}
            districtCode={districtCode}
            actualityDate={actualityDate}
            isMobile={isMobile}
            cards={cards}
          />
          <MapViewRanger
            indicatorId={indicatorId}
            speedType={speedType}
            rangeMin={rangeMin}
            rangeMax={rangeMax}
            changeQuery={changeQuery}
            isMobile={isMobile}
            formatRangeValue={formatRangeValue}
            isSubjectView={isSubjectView}
          />
          {!isMobile ? (
            <div className={s.MapView__cards}>
              <MapCards cards={cards} />
            </div>
          ) : null}
        </div>
        {children}
      </div>
    </>
  );
};

export default MapView;
