import React, { useMemo } from 'react';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { max as d3max } from 'd3';
import { CircleIndicator } from '../../common/ui/Project/ProjectIndicator';
import { HorizontalBarChart } from '../../common/charts/HorizontalBarChart';
import { Button } from '../../common/ui/Button/Button';
import { ReactComponent as CloseIcon } from '../../assets/Icons/Close.svg';
import { IconButton } from '../../common/ui/IconButton/IconButton';
import { formatNumber } from '../../utils/formatNumber';
import { QUERY_GET_SUBJECT_FINANCE } from '../../graphql/queries/finance';
import { useQueryPeriod } from '../../utils/useQueryPeriod';
import { ExpensesType } from '../../data/expensesType';
import { ResizeContainer } from '../../common/ResizeContainer';
import s from './RegionsIndicatorPage.module.scss';

const subjectFinanceOptions = {
  query: QUERY_GET_SUBJECT_FINANCE,
  mapData: (result) => result.financesGrouped,
  skipQuery: skipIfNull(null)
};

function RegionView({ region, onClose }) {
  const { yearMonth } = useQueryPeriod();

  const subjectFinanceVariables = useMemo(() => {
    if (!region || !region.region) return null;

    return {
      yearMonth,
      region_ids: [region.region.id]
    };
  }, [region, yearMonth]);

  const subjectFinanceQuery = useGraphqlQuery({
    queryOptions: subjectFinanceOptions,
    variables: subjectFinanceVariables
  });

  const subjectFinance = subjectFinanceQuery.state.data;

  if (!region) return null;

  const tabs = [
    {
      color: 'rgba(87, 179, 86, 0.5)',
      value: formatNumber(region.plan / 1e6, 1),
      title: 'ПОФ, млн'
    },
    {
      color: 'rgba(87,179,86,1)',
      value: formatNumber(region.otfg_kr_total / 1e6, 1),
      title: (
        <p>
          Кассовое исполнение
          <br />
          по ПОФ, млн
        </p>
      )
    },
    {
      color: 'rgba(87, 179, 86, 0.5)',
      value: formatNumber(region.total_budget / 1e6, 1),
      title: (
        <p>
          План бюджета
          <br />
          субъекта, млн
        </p>
      )
    },
    {
      color: 'rgba(87,179,86,1)',
      value: formatNumber(region.cash_expenses / 1e6, 1),
      title: (
        <p>
          Кассовое исполнение
          <br />
          бюджета субъекта, млн
        </p>
      )
    },
    {
      color: 'rgba(87,179,86,0.5)',
      value: formatNumber(region.pofSum / 1e6 || 0, 1),
      title: <p>ПОФ из посистемы сбора, млн</p>
    }
  ];

  // Конвертация названия субъекта в аббревиатуру
  // для заглушки если нету картинки герба
  const getAbbreviation = (string) => {
    if (!string) return '';
    return string
      .replace('г.', '')
      .replace(/-/g, ' ')
      .split(/\s+/)
      .filter((i) => i)
      .map((i) => i[0])
      .join('');
  };

  const financesMax =
    subjectFinance && d3max(subjectFinance, (s) => s.cash_expenses / 1e6);

  return (
    <div className={s.MapView}>
      <div className={s.MapView__content}>
        <div className={s.MapView__flag}>
          <img
            src={`/img/regions/${region.region.code_nalog}.png`}
            alt="Герб"
          />
        </div>
        <div className={s.MapView__title}>{region.region.name}</div>
        <div className={s.finance}>
          {tabs.map((item, iItem) => (
            <div key={iItem} className={s.finance__item}>
              {item.color ? (
                <div className={s.finance__status}>
                  <CircleIndicator
                    color={item.color}
                    className={s.finance__status_svg}
                  />
                </div>
              ) : null}
              <div className={s.finance__data}>
                <div className={s.finance__number}>{item.value}</div>
                <div className={s.finance__description}>{item.title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {subjectFinance ? (
        <div className={s.charts}>
          {subjectFinance.map((item, iItem) => (
            <div key={iItem} className={s.charts__item}>
              <div className={s.charts__title}>
                {ExpensesType.getDisplayName(item.expenses_type)}
              </div>
              <ResizeContainer>
                {({ width }) => (
                  <HorizontalBarChart
                    data={[
                      {
                        id: 0,
                        value: item.cash_expenses / 1e6
                      }
                    ]}
                    total={financesMax}
                    decimals={1}
                    sizeWidth={width}
                    sizeHeight={24 + 16 + 16}
                    color={'rgba(87,179,86,1)'}
                  />
                )}
              </ResizeContainer>
            </div>
          ))}
        </div>
      ) : null}
      <Button
        className={s.charts__button}
        to={`/regions/${region.region.code_nalog}`}
      >
        Подробнее
      </Button>
      <IconButton
        icon={CloseIcon}
        className={s.MapView__close}
        onClick={onClose}
      />
    </div>
  );
}

RegionView.propTypes = {};

export default RegionView;
