import React from 'react';
import classNames from 'classnames';
import { IconButton } from '../ui/IconButton/IconButton';
import { ReactComponent as Back } from '../../assets/Icons/Arrow/Back.svg';
import s from './PageTitle.module.scss';

export const PageTitle = React.memo(function PageTitle({
  className,
  text,
  to,
  onClick,
  iconClassName,
  titleClassName
}) {
  return (
    <div className={classNames(s.PageTitle, className)}>
      {(to || onClick) && (
        <IconButton
          className={classNames(s.PageTitle__backBtn, iconClassName)}
          icon={Back}
          onClick={onClick}
          to={to}
        />
      )}
      <h1 className={titleClassName}>{text}</h1>
    </div>
  );
});

export const PageSubTitle = ({ className, text }) => {
  return (
    <div className={classNames(s.PageSubTitle, className)}>
      <p>{text}</p>
    </div>
  );
};
