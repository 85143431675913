import React from 'react';
import classNames from 'classnames';
import s from './InfoItem.module.scss';

export function InfoItemsList({ className, children }) {
  return (
    <ul className={classNames(s.InfoItemsList, className)}>
      {React.Children.map(children, (item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ul>
  );
}

export function InfoItem({ icon, children }) {
  if (!children) return null;

  return (
    <div className={s.InfoItem}>
      <i>
        <img src={icon} alt="" />
      </i>
      <div className={s.InfoItem__text}>{children}</div>
    </div>
  );
}

export function PersonItem({ title, name, children }) {
  return (
    <div className={s.PersonItem}>
      <p className={s.PersonItem__title}>{title}</p>
      <p className={s.PersonItem__name}>{name}</p>
      {children}
    </div>
  );
}

export function MultiInfoItem({ icon, items }) {
  if (!items || items.length === 0) return null;

  return (
    <InfoItem icon={icon}>
      {items.map((text, i) => (
        <p key={i}>{text}</p>
      ))}
    </InfoItem>
  );
}

export function SingleInfoItem({ icon, item }) {
  if (!item) return null;

  return (
    <InfoItem icon={icon}>
      <p>{item}</p>
    </InfoItem>
  );
}

export function TitleInfoItem({ icon, item, title }) {
  if (!item) return null;

  return (
    <InfoItem icon={icon}>
      <p>{item}</p>
      <p className={s.InfoItem__title}>{title}</p>
    </InfoItem>
  );
}

export function BadgeText({ title, isShow }) {
  return isShow ? <div className={s.InfoItem__smallSchool}>{title}</div> : null;
}
