import React, { useCallback, useEffect, useMemo } from 'react';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { Project } from '../../../data/project';
import { useFederalProjects } from '../../../store/useFederalProjects';
import { compareNumericStrings } from '../../../utils/data';
import { TopBarProjectsContent } from './TopBarProjectsContent';

export const commonProject = {
  name_full: 'Общие показатели',
  project_code: 0
};

export const TopBarProjects = ({
  projects,
  noCommon,
  replaceQuery = false,
  ...props
}) => {
  const [queryLocation, changeQuery] = useUrlQuery();

  const selectedFpCode = +queryLocation.fp || 0;

  const selectFp = useCallback(
    (fp, replace) => {
      changeQuery(
        {
          fp: Project.getFederalProjectCode(fp)
        },
        replace
      );
    },
    [changeQuery]
  );

  const projectsWithCommon = useMemo(() => {
    if (!projects) return null;

    const result = [];
    if (!noCommon) {
      result.push(commonProject);
    }
    if (projects) {
      result.push(...projects.filter(Boolean));
    }

    result.sort((a, b) => {
      return compareNumericStrings(a.project_code, b.project_code);
    });

    return result;
  }, [projects, noCommon]);

  const selectedProject =
    projectsWithCommon &&
    projectsWithCommon.find((p) => +p.project_code === selectedFpCode);

  useEffect(() => {
    if (
      projectsWithCommon &&
      !selectedProject &&
      projectsWithCommon.length > 0
    ) {
      selectFp(projectsWithCommon[0], true);
    }
  }, [projectsWithCommon, selectedProject, selectFp]);

  return (
    <TopBarProjectsContent
      projects={projectsWithCommon}
      selectedFpCode={selectedFpCode}
      onProjectClick={(p) => selectFp(p, replaceQuery)}
      {...props}
    />
  );
};

export const TopBarAllProjects = (props) => {
  const query = useFederalProjects();
  const queryData = query.state.data;

  return <TopBarProjects projects={queryData} {...props} />;
};
