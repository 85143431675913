import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { color as dColor, interpolateHcl, scaleLinear, sum as d3Sum } from 'd3';
import { formatNumber } from '../../utils/formatNumber';

export function HorizontalBarChart({
  data,
  sizeHeight,
  sizeWidth,
  paddingTop = 16,
  paddingBottom = 16,
  total,
  minimum,
  decimals,
  color,
  textColor,
  paddingRight = 0,
  paddingLeft = 0
}) {
  const itemsRef = useRef([]);
  const [textWidths, setTextWidths] = useState([]);
  const chartHeight = Math.max(sizeHeight - paddingTop - paddingBottom, 0);

  const createMinimumLine = (interpolator, barWidth) => {
    if (!minimum) return null;

    const xCoord = interpolator(minimum);

    return (
      <line
        x1={xCoord}
        x2={xCoord}
        y1={0 - 8}
        y2={chartHeight + 10}
        strokeWidth={1}
        strokeDasharray={'3, 3'}
        stroke={'black'}
      />
    );
  };

  const createRect = (id, name, value, data, index, oldX) => {
    let legend = {};

    const { x, y, width, color: elementColor, position } = data;
    if (position === 'bottom') {
      legend = {
        y1: 0,
        y2: chartHeight + 14,
        textY: chartHeight + 14
      };
    } else {
      legend = {
        y1: -14,
        y2: chartHeight,
        textY: -4
      };
    }

    const textWidth = Math.ceil(textWidths[index]);
    const prevTextWidth = Math.ceil(textWidths[index - 1]);
    const targetX = x + width - 4;
    let textLeft = true;
    let textX = targetX;
    if (textWidth > targetX) {
      textX = x + width + 4;
      textLeft = false;
    }

    // При 3 значениях графика, нижние значения могут наслаиваться
    if (
      !Number.isNaN(prevTextWidth) &&
      index > 1 &&
      oldX + prevTextWidth > textX - textWidth
    ) {
      textX = x + width + 4;
      textLeft = false;
    }

    return (
      <g key={id}>
        <rect
          fill={elementColor}
          key={`StackedBarChart_${id}`}
          x={x}
          y={y}
          width={width}
          height={chartHeight}
        />
        <line
          x1={x + width}
          strokeWidth={1}
          y1={legend.y1}
          x2={x + width}
          y2={legend.y2}
          stroke={elementColor}
        />
        <text
          x={textX}
          y={legend.textY}
          fill={textColor}
          style={{ fontSize: '12px' }}
          ref={(ref) => (itemsRef.current[index] = ref)}
          textAnchor={textLeft ? 'end' : 'start'}
        >
          {value}
        </text>
      </g>
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const newTextWidths = [];
    const items = itemsRef.current;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!item) {
        newTextWidths.push(0);
        continue;
      }

      newTextWidths.push(item.getBoundingClientRect().width);
    }

    let hasChanges = newTextWidths.length !== textWidths.length;
    if (!hasChanges) {
      for (let i = 0; i < newTextWidths.length; i++) {
        if (newTextWidths[i] !== textWidths[i]) {
          hasChanges = true;
          break;
        }
      }
    }

    if (hasChanges) {
      setTextWidths(newTextWidths);
    }
  });

  let colorInterpolator = () => '#fff';

  const totalMax = total ? total : d3Sum(data, (i) => i.value);

  if (color) {
    const opacityColor = dColor(color);
    opacityColor.opacity = 0;
    const endColor = opacityColor.formatRgb();

    // Интерполятор цветов
    colorInterpolator = interpolateHcl(color, endColor);
  }

  const valueInterpolator = scaleLinear()
    .domain([0, totalMax])
    .range([paddingLeft, sizeWidth - paddingRight]);

  let oldX = valueInterpolator(0);
  let oldPosition = 'bottom';

  const barsData = data.map(({ id, name, value, color }, index) => {
    const newX = valueInterpolator(value);
    const barData = {
      x: oldX,
      y: 0,
      width: Math.max(newX - oldX, 0),
      color: color ? color : colorInterpolator(index / data.length),
      position: 'bottom'
    };

    const parsedValue = formatNumber(value, decimals);

    if (oldPosition === 'bottom' && index > 0) {
      barData.position = 'top';
      oldPosition = 'top';
    } else {
      barData.position = 'bottom';
      oldPosition = 'bottom';
    }

    oldX += barData.width;
    return createRect(
      id,
      name,
      parsedValue,
      barData,
      index,
      oldX - barData.width
    );
  });

  const minimumLine = createMinimumLine(valueInterpolator);

  return (
    <svg
      width={sizeWidth}
      height={sizeHeight}
      viewBox={`${0} ${-paddingTop} ${sizeWidth} ${sizeHeight}`}
    >
      {barsData}
      {minimumLine}
    </svg>
  );
}

HorizontalBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string
    })
  ).isRequired,
  total: PropTypes.number,
  decimals: PropTypes.number,
  color: PropTypes.string,
  textColor: PropTypes.string
};

HorizontalBarChart.defaultProps = {
  decimals: 2
};
