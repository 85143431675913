import React, { useEffect } from 'react';
import { useBudgetChart } from '../../IndicatorsPage/useBudgetChart';
import { NBSP } from '../../../utils/utf';
import { ResizeContainer } from '../../../common/ResizeContainer';
import { ChartViewChart } from '../../../common/ChartView/ChartView';
import { queryLoader } from '../../../common/QueryLoader';
import { ReportChartView } from './ReportChartView';

export function InfographicChartsNpFp({
  federalProject,
  year,
  yearMonth,
  month,
  onLoad = () => {}
}) {
  const {
    budgetCharts,
    kassCharts,
    grants,
    budgetData,
    budgetQuery
  } = useBudgetChart({
    filter: federalProject
      ? {
          federal_project_ids: federalProject.id,
          federal_project_codes: federalProject.project_code
        }
      : undefined,
    yearMonth
  });

  useEffect(() => {
    if (!budgetQuery.check.spinner) {
      onLoad(true);
    }
  }, [budgetQuery.check.spinner, onLoad]);

  return (
    <div>
      <ReportChartView
        title={`Бюджетные ассигнования ФБ${NBSP}и${NBSP}принятие${NBSP}БО`}
        year={year}
        month={month}
      >
        {queryLoader(budgetQuery) ||
          (budgetCharts && (
            <ResizeContainer>
              {({ width }) => (
                <ChartViewChart
                  data={budgetCharts}
                  legend
                  width={width}
                  height={192}
                />
              )}
            </ResizeContainer>
          ))}
      </ReportChartView>
      <ReportChartView title={`Субсидии и гранты`} year={year} month={month}>
        {queryLoader(budgetQuery) ||
          (grants && (
            <ResizeContainer>
              {({ width }) => (
                <ChartViewChart
                  data={grants}
                  legend
                  width={width}
                  height={192}
                />
              )}
            </ResizeContainer>
          ))}
      </ReportChartView>
      <ReportChartView
        title={`Кассовое исполнение по видам бюджетов в разрезе ВР`}
        year={year}
        month={month}
      >
        {queryLoader(budgetQuery) ||
          (kassCharts && (
            <ResizeContainer>
              {({ width }) => (
                <ChartViewChart
                  data={kassCharts}
                  legend
                  width={width}
                  height={192}
                />
              )}
            </ResizeContainer>
          ))}
      </ReportChartView>
    </div>
  );
}
