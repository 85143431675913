import classNames from 'classnames';
import React from 'react';
import { ReactComponent as Circle } from '../../assets/Icons/CircleEmpty.svg';
import s from './IndexPage.module.scss';

export const CashExecutionTable = ({
  activeSegmentId,
  onClick,
  data: fullData
}) => {
  const { data, togglers } = fullData;
  return (
    <>
      <table className={classNames(s.ChartTable)}>
        <thead>
          <tr>
            <th className={s.ChartTable__headerCell} />
            <th className={s.ChartTable__headerCell}>Млрд руб.</th>
            <th className={s.ChartTable__headerCell}>%</th>
          </tr>
        </thead>
        <tbody>
          {togglers.map(({ id, values }) => {
            return (
              <tr
                key={id}
                onClick={() => onClick(id)}
                className={classNames(
                  {
                    [s.ChartTable__activeMode]: activeSegmentId,
                    [s.ChartTable__active]:
                      !activeSegmentId || id === activeSegmentId
                  },
                  s.ChartTable__clickable,
                  s.ChartTable__mainText
                )}
              >
                {values.map((value, j) => (
                  <td key={id + j} className={s.ChartTable__cell}>
                    {j === 0 ? (
                      <div className={s.ChartTable__cellContent}>
                        <span className={s.ChartTable__cellIcon}>
                          <Circle />
                        </span>
                        <span>{value}</span>
                      </div>
                    ) : (
                      value
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <table
        className={classNames(s.ChartTable, s._smallPad, s._overflowHidden)}
      >
        <thead>
          <tr>
            <th className={s.ChartTable__headerCell} />
            <th className={s.ChartTable__headerCell}>План</th>
            <th className={s.ChartTable__headerCell}>Принято</th>
            <th className={s.ChartTable__headerCell}>Факт</th>
          </tr>
        </thead>
        <tbody>
          {data.map((cells, i) => (
            <tr className={s.ChartTable__row} key={i}>
              {cells.map(({ id, value }, j) => {
                const isCellActive =
                  j === 0
                    ? !activeSegmentId || id === activeSegmentId.split('&')[0]
                    : !activeSegmentId || activeSegmentId === id;
                return (
                  <td
                    key={id}
                    className={classNames(
                      s.ChartTable__mainText,
                      s.ChartTable__cell,
                      {
                        [s.ChartTable__clickable]: j > 0,
                        [s.ChartTable__activeMode]: activeSegmentId,
                        [s.ChartTable__active]: isCellActive
                      }
                    )}
                    onClick={j > 0 ? () => onClick(id) : () => {}}
                  >
                    {value}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
