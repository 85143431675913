import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { flatMap } from 'lodash-es';
import { useContextStore } from '@proscom/prostore-react';
import { formatFileSize } from '../../utils/formatNumber';
import { apiUrl } from '../../config';
import { ReactComponent as DownloadIcon } from '../../assets/Icons/Download.svg';
import { RoadmapEventDocumentStatus } from '../../data/roadmapEvent';
import { IconButton } from '../ui/IconButton/IconButton';
import { compareSimple, makeComparator } from '../../utils/data';
import { createDownloadLink } from '../../store/usePreserveQuery';
import { getFullFileName } from '../../data/file';
import { EMDASH } from '../../utils/utf';
import { STORE_DOCUMENT_TOKEN } from '../../store/stores';
import s from './RoadmapEventPopup.module.scss';

const getDocumentDownloadLink = (id, auth) => {
  return createDownloadLink(
    `${apiUrl}/files/roadmapDocument/${id}${auth ? '?token=' + auth : ''}`
  );
};

export function DocumentInfo({ document, slot }) {
  const tokensStore = useContextStore(STORE_DOCUMENT_TOKEN);
  const file = document?.connected_file;
  const filename = slot
    ? file
      ? getFullFileName({ ...file, name: slot.name })
      : slot.name
    : getFullFileName(file) || EMDASH;

  const handleSubmit = useCallback(
    (id) => {
      tokensStore.roadmapDocumentFileToken(id).then((auth) => {
        window.open(getDocumentDownloadLink(id, auth));
      });
    },
    [tokensStore]
  );

  return (
    <div className={s.RegionDropdownContent__document}>
      <div className={s.RegionDropdownContent__about}>
        <div className={s.RegionDropdownContent__filename}>{filename}</div>
        <div className={s.RegionDropdownContent__status}>
          {document
            ? document.status === RoadmapEventDocumentStatus.APPROVED
              ? 'Подтверждено'
              : document.status === RoadmapEventDocumentStatus.APPROVAL
              ? 'На подтверждении'
              : document.status === RoadmapEventDocumentStatus.NOT_APPROVED
              ? 'Не подтверждено'
              : 'Не подтверждено'
            : 'Не загружено'}
        </div>
      </div>
      <div className={s.RegionDropdownContent__filesize}>
        {file && formatFileSize(file.file_size)}
      </div>
      {file && (
        <IconButton
          className={s.RegionDropdownContent__link}
          onClick={() => handleSubmit(document.id)}
          icon={DownloadIcon}
        />
      )}
    </div>
  );
}

export function SlotsDocumentList({
  slots,
  documents: documentsProps,
  noDocuments
}) {
  const documents = Array.isArray(documentsProps) ? documentsProps : [];

  const withSlots = useMemo(() => {
    return flatMap(slots, (slot) => {
      const slotDocs = documents
        .filter((doc) => doc.document_slot_id === slot.id)
        .map((document) => ({
          document,
          slot
        }));

      if (slotDocs.length > 0) {
        return slotDocs;
      } else {
        return [{ slot }];
      }
    });
  }, [slots, documents]);

  const withoutSlots = useMemo(() => {
    return documents.filter(
      (document) => !slots.find((s) => s.id === document.document_slot_id)
    );
  }, [documents, slots]);

  const totalLength = withSlots.length + withoutSlots.length;

  return (
    <>
      {totalLength > 0 ? (
        <>
          {withSlots.map((ds, iDoc) => {
            return (
              <DocumentInfo key={iDoc} document={ds.document} slot={ds.slot} />
            );
          })}
          {withoutSlots.map((document, iDoc) => {
            return <DocumentInfo key={iDoc} document={document} />;
          })}
        </>
      ) : (
        noDocuments
      )}
    </>
  );
}

export function RegionDocumentList({ className, regionalEvent, event }) {
  const regionDocuments = useMemo(() => {
    const data = regionalEvent?.roadmap_event_documents || [];
    return data.slice().sort(makeComparator(compareSimple, (doc) => doc.code));
  }, [regionalEvent]);

  const slots = useMemo(() => {
    const data = event?.roadmap_event_document_slots || [];
    return data
      .filter((slot) => !slot.federal)
      .sort(makeComparator(compareSimple, (doc) => doc.code));
  }, [event]);

  return (
    <div className={classNames(className, s.RegionDropdownContent)}>
      <SlotsDocumentList
        slots={slots}
        documents={regionDocuments}
        noDocuments={<div>Нет документов</div>}
      />
    </div>
  );
}
