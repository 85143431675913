import React, { useCallback, useMemo, useState } from 'react';
import { groupBy } from 'lodash-es';
import { RoadmapEventPopup } from '../../../common/EventsList/RoadmapEventPopup';
import {
  compareSimple,
  getStatusCount,
  makeComparator
} from '../../../utils/data';
import { Title } from '../FullEvent';
import { formatNumber } from '../../../utils/formatNumber';
import { RoadmapEventStatus } from '../../../data/roadmapEvent';
import { ButtonComponent } from '../../../common/ui/Button/Button';
import s from '../CalendarPage.module.scss';

export function RoadmapRealization({ event, popupRef }) {
  const [isOpenedPopup, setOpenedPopup] = useState(false);

  const handleCloseDocumentPopup = useCallback(() => {
    setOpenedPopup(false);
  }, []);

  const handleOpenDocumentPopup = useCallback(() => {
    setOpenedPopup(true);
  }, []);

  const allRegions = useMemo(() => {
    const regions =
      event &&
      event.roadmap &&
      event.roadmap.subsidiary_entity_plans &&
      event.roadmap.subsidiary_entity_plans.map((plan) => plan.region);

    return Object.values(groupBy(regions, (r) => r.id))
      .map((r) => r[0])
      .sort(makeComparator(compareSimple, (region) => region.name));
  }, [event]);

  const totalRegions = (allRegions && allRegions.length) || 0;

  const done = getStatusCount(
    event && event.count_region_status && event.count_region_status,
    RoadmapEventStatus.COMPLETED
  );

  if (!event) {
    return null;
  }

  return (
    <div>
      <Title
        title={'Результат реализации мероприятия'}
        content={
          <div>
            <div className={s.RoadmapEvent__status}>
              <span className={s.__text}>Выполнено: </span>
              <span className={s.__value}>{formatNumber(done, 0)}</span>
            </div>
            <div className={s.RoadmapEvent__status}>
              <span className={s.__text}>Не выполнено: </span>
              <span className={s.__value}>
                {formatNumber(totalRegions - done, 0)}
              </span>
            </div>
            <ButtonComponent
              onClick={handleOpenDocumentPopup}
              className={s.CalendarPageSidebar__document}
            >
              Документы
            </ButtonComponent>
          </div>
        }
      />
      {event && isOpenedPopup ? (
        <RoadmapEventPopup
          popupRef={popupRef}
          isOpen={isOpenedPopup}
          onClose={handleCloseDocumentPopup}
          event={event}
          regions={allRegions}
          totalRegions={totalRegions}
        />
      ) : null}
    </div>
  );
}

RoadmapRealization.propTypes = {};
