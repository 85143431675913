import React, { useState } from 'react';
import classNames from 'classnames';
import ReactSlick from 'react-slick';
import TextEllipsis from 'react-text-ellipsis';
import { Link } from 'react-router-dom';
import { ReactComponent as Problem } from '../../../assets/Icons/Close.svg';
import { ReactComponent as Plan } from '../../../assets/Icons/Reload.svg';
import { ReactComponent as Ready } from '../../../assets/Icons/Check.svg';
import { ChipRadio } from '../../../common/ui/Chip/ChipRadio';
import { useWindowSize } from '../../../store/useWindowSize';
import colors from '../../../colors.scss';
import s from './Results.module.scss';

const resultLabels = {
  0: {
    type: '_red',
    icon: <Problem />,
    text: 'Проблемы'
  },
  1: {
    type: '_orange',
    icon: <Plan />,
    text: 'План'
  },
  2: {
    type: '_green',
    icon: <Ready />,
    text: 'Готово'
  }
};

function ResultText({ isMobile, text }) {
  return isMobile ? (
    <TextEllipsis
      lines={5}
      tag={'p'}
      ellipsisChars={'...'}
      debounceTimeoutOnResize={200}
      useJsOnly={true}
    >
      {text}
    </TextEllipsis>
  ) : (
    <p>{text}</p>
  );
}

const Result = ({ isMobile, result }) => {
  return (
    <Link
      to={`/indicators/results/${result.id}`}
      className={classNames(s.Result, s[resultLabels[result.status].type])}
    >
      <div className={s.Result__wrapper}>
        <div className={s.Result__label}>
          <i>{resultLabels[result.status].icon}</i>
          <p>{resultLabels[result.status].text}</p>
        </div>
        <div className={s.Result__text}>
          <ResultText isMobile={isMobile} text={result.name_full} />
        </div>
      </div>
    </Link>
  );
};

const ResultsSlider = ({ children }) => {
  return (
    <div className={s.Results__slider}>
      <ReactSlick
        className={s.Slick}
        dots={false}
        arrows={false}
        slidesToShow={1}
        centerPadding={0}
        swipeToSlide
        focusOnSelect
        centerMode={true}
        variableWidth={true}
        infinite={true}
        initialSlide={0}
      >
        {children}
      </ReactSlick>
    </div>
  );
};
const ResultsList = ({ children }) => {
  return <div className={s.Results__list}>{children}</div>;
};

const statusChipOptions = [
  {
    title: 'Все',
    color: null,
    value: null
  },
  {
    title: 'Проблемы',
    color: colors.error,
    icon: Problem,
    value: 0
  },
  {
    title: 'План',
    color: colors.warn,
    icon: Plan,
    value: 1
  },
  {
    title: 'Готово',
    color: colors.success,
    icon: Ready,
    value: 2
  }
];

export const Results = ({ results, federalProject }) => {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  const [selected, setSelected] = useState(null);
  const filteredResults = results
    .filter((res) => selected === null || res.status === selected)
    .filter(
      (res) =>
        federalProject === null ||
        (res.owner_task && res.owner_task.owner_id === federalProject.id)
    );

  // todo: запилить text-oveflow для текста внутри карточки результата в слайдере
  return (
    <div className={s.Results}>
      <div className={s.Results__filters}>
        <div className={s.Results__filtersWrapper}>
          <div className={s.Results__filtersItems}>
            {statusChipOptions.map((status, i) => (
              <ChipRadio
                key={i}
                className={s.Results__filter}
                icon={status.icon}
                title={status.title}
                color={status.color}
                selected={selected}
                value={status.value}
                onChange={setSelected}
              />
            ))}
          </div>
        </div>
      </div>
      {filteredResults.length === 0 ? (
        <p className={s.Results__empty}>Нет результатов</p>
      ) : isMobile ? (
        <ResultsSlider>
          {filteredResults.map((res, i) => (
            <Result key={i} result={res} isMobile={isMobile} />
          ))}
        </ResultsSlider>
      ) : (
        <ResultsList>
          {filteredResults.map((res, i) => (
            // todo: сделать как readonly Chip
            <Result key={i} result={res} />
          ))}
        </ResultsList>
      )}
    </div>
  );
};
