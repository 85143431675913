import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash-es';
import { useStoreState } from '@proscom/prostore-react';
import { useWindowSize } from '../../store/useWindowSize';
import { BackButtonBox } from '../../common/ui/BackButton/BackButton';
import { useSearch } from '../../utils/useSearch';
import { ChipRadio } from '../../common/ui/Chip/ChipRadio';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { SearchInput } from '../../common/ui/SearchInput/SearchInput';
import { STORE_AUTH } from '../../store/stores';
import { User } from '../../data/user';
import { useSearchRegions } from './entities/regions';
import { useSearchFederalProjects } from './entities/federalProjects';
import {
  entities,
  FEDERAL_PROJECTS,
  ORGANIZATIONS,
  REGIONS,
  RESULTS,
  ROADMAPS,
  SUBSIDIARY_ENTITY,
  tabRadio
} from './entities/_all';
import { useSearchOrganizations } from './entities/organizations';
import { useSearchResults } from './entities/result';
import { processResults } from './entities/_util';
import { useSearchRoadmaps } from './entities/roadmap';
import SearchResultGroup from './SearchResultGroup';
import { useSearchSubsidiaryEntity } from './entities/subsidiaryEntity';
import s from './SearchPage.module.scss';

function SearchLayout(props) {
  return <div className={s.SearchLayout} {...props} />;
}

export default function SearchPage(props) {
  const [queryParams, changeQuery] = useUrlQuery();
  const { tab } = queryParams;
  const { history } = props;
  const inputRef = useRef(null);
  const auth = useStoreState(STORE_AUTH);
  const user = User.getUser(auth);
  const isRegionAdmin = user.role === User.roles.REGION_ADMIN;

  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const { search, handleSearch, handleChange, clearInput } = useSearch(
    queryParams,
    changeQuery
  );

  const handleChangeTab = useCallback(
    (value) => {
      changeQuery({ tab: value }, true);
    },
    [changeQuery]
  );

  const orgsQuery = useSearchOrganizations(search);
  const regionsQuery = useSearchRegions(search);
  const roadmapsQuery = useSearchRoadmaps(search);
  const fpQuery = useSearchFederalProjects(search);
  const resultsQuery = useSearchResults(search);
  const subsidiaryEntityQuery = useSearchSubsidiaryEntity(search);

  const results = {
    [REGIONS]: processResults(regionsQuery, tab),
    [ORGANIZATIONS]: processResults(orgsQuery, tab),
    [SUBSIDIARY_ENTITY]: processResults(subsidiaryEntityQuery, tab),
    [ROADMAPS]: processResults(roadmapsQuery, tab)
  };

  if (!isRegionAdmin) {
    results[FEDERAL_PROJECTS] = processResults(fpQuery, tab);
    results[RESULTS] = processResults(resultsQuery, tab);
  }

  const resultsArray = Object.values(results);

  const getResult = (tab) => results[tab] || {};

  const tabAll = isNil(tab);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const selectedLoading = tabAll
    ? resultsArray.some((res) => res.loading)
    : getResult(tab).loading;

  const selectedEmpty = tabAll
    ? resultsArray.every((res) => res.totalCount === 0)
    : getResult(tab).totalCount === 0;

  const searchEntities = useMemo(() => {
    return entities.filter((entity) => results[entity.value] !== undefined);
  }, [results]);
  const searchFilters = useMemo(() => {
    return tabRadio.filter(
      (tab) => !tab.value || results[tab.value] !== undefined
    );
  }, [results]);

  return (
    <SearchLayout>
      {isMobile && (
        <BackButtonBox
          className={s.SearchBack}
          onClick={() => history.goBack()}
        />
      )}
      <div className={s.SearchForm}>
        <form onSubmit={handleSearch}>
          <SearchInput
            onCloseBtnClick={clearInput}
            defaultValue={search}
            variant={SearchInput.DARKER}
            onChange={handleChange}
            isSearchBtn={true}
            fwdInputRef={inputRef}
          />
        </form>
      </div>
      <div className={s.SearchFilter}>
        {searchFilters.map((item, iItem) => (
          <ChipRadio
            key={iItem}
            className={s.SearchFilter__item}
            title={item.title}
            value={item.value}
            selected={tab}
            onChange={handleChangeTab}
          />
        ))}
      </div>
      <div className={s.SearchResults}>
        {search ? (
          <>
            {searchEntities.map((entity) => {
              const entityResult = results[entity.value];
              return (
                <SearchResultGroup
                  key={entity.value}
                  className={s.SearchResults__item}
                  variant={tab}
                  isHidden={tab && tab !== entity.value}
                  searchResult={entityResult}
                  type={entity.value}
                  showMore={() => handleChangeTab(entity.value)}
                  title={entity.title}
                  isRegionAdmin={isRegionAdmin}
                  user={user}
                  {...entity.props}
                />
              );
            })}
            {!selectedLoading && selectedEmpty ? (
              <div className={classNames(s.SearchInfo, s._noData)}>
                По вашему запросу ничего не найдено
              </div>
            ) : null}
          </>
        ) : (
          <>
            <div className={s.SearchInfo}>Введите поисковый запрос</div>
          </>
        )}
      </div>
    </SearchLayout>
  );
}
