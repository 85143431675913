import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { ProjectIndicator } from '../Project/ProjectIndicator';
import { ChipText } from '../Chip/ChipText';
import { CardSmallLayout } from './CardSmallLayout';
import s from './CardSmallLayout.module.scss';

export function CardStatus({
  title,
  status,
  to,
  description,
  loading,
  ...props
}) {
  const getColor = (status) => {
    switch (status) {
      case CardStatus.SUCCESS:
        return s._success;
      case CardStatus.WARN:
        return s._warn;
      case CardStatus.ERROR:
        return s._error;
      default:
        return s._default;
    }
  };

  const getTitle = (status) => {
    switch (status) {
      case CardStatus.SUCCESS:
        return 'Положительно';
      case CardStatus.WARN:
        return 'Умеренно';
      case CardStatus.ERROR:
        return 'Критично';
      default:
        return 'Положительно';
    }
  };

  return (
    <div className={s.CardStatus} {...props}>
      <CardSmallLayout to={to} loading={loading}>
        <ChipText
          title={getTitle(status)}
          className={classNames(getColor(status), s.CardStatus__status)}
          iconElement={
            <ProjectIndicator className={s.CardStatus__svg} status={status} />
          }
        />
        <div className={s.CardStatus__title}>{title}</div>
        <div className={s.CardStatus__article}>{description}</div>
      </CardSmallLayout>
    </div>
  );
}

CardStatus.SUCCESS = 'success';
CardStatus.WARN = 'warn';
CardStatus.ERROR = 'error';
CardStatus.DEFAULT = 'default';

CardStatus.propTypes = {
  title: PropTypes.node,
  status: PropTypes.oneOf([
    CardStatus.SUCCESS,
    CardStatus.WARN,
    CardStatus.ERROR,
    CardStatus.DEFAULT
  ]),
  description: PropTypes.node,
  loading: PropTypes.bool
};

CardStatus.defaultProps = {
  status: CardStatus.DEFAULT
};
