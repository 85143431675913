import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import s from './Project.module.scss';

function getStatus(status) {
  switch (status) {
    case ProjectIndicator.SUCCESS:
      return s._success;
    case ProjectIndicator.WARN:
      return s._warn;
    case ProjectIndicator.ERROR:
      return s._error;
    default:
      return null;
  }
}

export function ProjectIndicator({ status, className }) {
  return (
    <svg
      className={classNames(s.ProjectIndicator, className, getStatus(status))}
      viewBox={`-12 -12 24 24`}
    >
      <circle
        fill={'currentColor'}
        className={s.ProjectIndicator__background}
        cx="0"
        cy="0"
        r="12"
      />
      <circle
        fill={'currentColor'}
        className={s.ProjectIndicator__main}
        cx="0"
        cy="0"
        r="6"
      />
    </svg>
  );
}

export function CircleIndicator({ status, className, color }) {
  return (
    <svg
      style={{ color }}
      className={classNames(s.ProjectIndicator, className, getStatus(status))}
      viewBox={`-12 -12 24 24`}
    >
      <circle
        fill={'currentColor'}
        className={s.ProjectIndicator__main}
        cx="0"
        cy="0"
        r="12"
      />
    </svg>
  );
}

ProjectIndicator.SUCCESS = 'success';
ProjectIndicator.WARN = 'warn';
ProjectIndicator.ERROR = 'error';
ProjectIndicator.IN_PROGRESS = 'in_progress';

ProjectIndicator.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string
};

ProjectIndicator.defaultProps = {
  status: 'good'
};
