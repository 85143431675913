import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { SpringSystem } from 'rebound';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as IconArrow } from '../../assets/Icons/Arrow/Front.svg';
import s from './HorizontalScroll.module.scss';

const NORMAL = 'normal';
const DARKER = 'darker';

const INSIDE = 'inside';
const EDGE = 'edge';
const OUTSIDE = 'outside';

const ScrollBtn = ({ position = 'left', variant }) => {
  return (
    <div
      className={classNames(
        s.scrollBtn,
        position === 'right' ? s._right : s._left,
        {
          [s._darker]: variant === 'darker'
        }
      )}
    >
      <div className={classNames(s.scrollBtn__icon)}>
        <IconArrow />
      </div>
    </div>
  );
};

const HorizontalThumb = (props) => {
  return <div {...props} className={s.horizontalScrollThumb} />;
};

const getItemMargins = (elem) => {
  let style = window.getComputedStyle(elem);
  return parseFloat(style.marginLeft) + parseFloat(style.marginRight);
};

export class HorizontalScroll extends Component {
  static defaultProps = {
    load: () => {},
    initialLeft: 0,
    scrollItems: 1,
    buttonPosition: EDGE,
    disableArrow: false
  };

  static propTypes = {
    load: PropTypes.func,
    listCN: PropTypes.string,
    containerCN: PropTypes.string,
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    initialLeft: PropTypes.number,
    variant: PropTypes.oneOf([NORMAL, DARKER]),
    buttonPosition: PropTypes.oneOf([INSIDE, EDGE, OUTSIDE]),
    buttonPositionRight: PropTypes.oneOf([INSIDE, EDGE, OUTSIDE]),
    scrollItems: PropTypes.number,
    disableArrow: PropTypes.bool
  };

  scrollbars = React.createRef();
  scrollContainer = React.createRef();
  itemsList = React.createRef();
  itemRef = React.createRef();
  horizontalScrollConstant = 600;
  targetLeft = 0;

  state = { left: this.props.initialLeft, needButtons: false };

  componentDidMount() {
    this._initSpring();
    this.checkItemSizes();
    this.handleScrollUpdate();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate() {
    this.checkItemSizes();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this._destroySpring();
  }

  _initSpring() {
    this.springSystem = new SpringSystem();
    this.spring = this.springSystem.createSpringWithBouncinessAndSpeed(0, 5);
    this.spring.addListener({ onSpringUpdate: this.handleSpringUpdate });
  }

  _destroySpring() {
    this.springSystem.deregisterSpring(this.spring);
    this.springSystem.removeAllListeners();
    this.springSystem = undefined;
    this.spring.destroy();
    this.spring = undefined;
  }

  handleResize = () => {
    this.checkItemSizes();
  };

  checkItemSizes() {
    if (this.itemRef.current) {
      this.horizontalScrollConstant =
        (this.itemsList.current.offsetWidth +
          getItemMargins(this.itemRef.current)) /
        this.props.children.length;
    }
    if (this.scrollbars.current) {
      const { scrollWidth, clientWidth } = this.scrollbars.current.getValues();
      const needButtons = scrollWidth > clientWidth;
      if (this.state.needButtons !== needButtons) {
        this.setState({ needButtons });
      }
    }
  }

  handleSpringUpdate = (spring) => {
    const scrollbars = this.scrollbars.current;
    const val = spring.getCurrentValue();
    scrollbars.scrollLeft(val);
  };

  arrowScroll = (dir) => () => {
    const scrollWidth = this.scrollbars.current.getScrollWidth();
    const clientWidth = this.scrollbars.current.getClientWidth();
    const scrollLeft = this.scrollbars.current.getScrollLeft();
    const currentItemLeft =
      Math.floor(this.targetLeft / this.horizontalScrollConstant) *
      this.horizontalScrollConstant;
    let val =
      currentItemLeft +
      dir * this.horizontalScrollConstant * this.props.scrollItems;
    if (val > scrollWidth - clientWidth) val = scrollWidth - clientWidth;
    if (val < 0) val = 0;
    this.spring.setCurrentValue(scrollLeft).setAtRest();
    this.spring.setEndValue(val);
    this.targetLeft = val;
  };

  arrowScrollLeft = this.arrowScroll(-1);
  arrowScrollRight = this.arrowScroll(1);

  canLoadData = () => {
    return (
      !this.props.isLoading &&
      !this.props.loadedAll &&
      this.scrollbars.current.getValues().left > 0.95
    );
  };

  handleScroll = () => {
    if (this.scrollbars.current && this.spring.isAtRest()) {
      this.targetLeft = this.scrollbars.current.getScrollLeft();
    }
    if (this.canLoadData()) {
      this.props.load();
    }
  };

  renderHorizontalTrack = (props) => {
    return (
      <div
        {...props}
        className={classNames(
          s.horizontalScrollTrack,
          '__horizontalScrollTrack'
        )}
      />
    );
  };

  handleScrollUpdate = () => {
    const left = this.scrollbars.current
      ? this.scrollbars.current.getValues().left
      : null;
    this.setState({
      left
    });
  };

  render() {
    const {
      containerCN,
      listCN,
      children,
      isLoading,
      variant,
      buttonPosition,
      buttonPositionRight,
      disableArrow
    } = this.props;
    const { left, needButtons } = this.state;
    return (
      <div
        className={classNames(s.scrollContainer, containerCN, {
          // [s._macos]: ['iOS', 'Mac OS'].indexOf(osName) !== -1
        })}
        ref={this.scrollContainer}
      >
        <Scrollbars
          autoHeight
          autoHeightMin="100%"
          autoHeightMax="100%"
          renderTrackHorizontal={this.renderHorizontalTrack}
          renderThumbHorizontal={HorizontalThumb}
          ref={this.scrollbars}
          hideTracksWhenNotNeeded
          onScroll={this.handleScroll}
          onScrollStop={this.handleScrollUpdate}
        >
          <div
            className={classNames(s.scrollListWrapper, '__scrollListWrapper')}
          >
            <ul
              className={classNames(s.scrollList, listCN, {
                _centered: React.Children.count(children) === 0 && isLoading
              })}
              ref={this.itemsList}
            >
              {React.Children.map(children, (child, i) => (
                <li
                  className={classNames(s.scrollList__item, 'scrollList__item')}
                  ref={i === 0 ? this.itemRef : null}
                  key={i}
                >
                  {child}
                </li>
              ))}
            </ul>
          </div>
        </Scrollbars>
        <CSSTransition
          in={left > 0 && needButtons && !disableArrow}
          timeout={300}
          classNames="fade-in"
          mountOnEnter
          unmountOnExit
        >
          <div
            className={classNames(
              s.scrollNav__btn,
              s['_' + buttonPosition],
              s._left,
              'scrollNav__btn _left'
            )}
            onClick={this.arrowScrollLeft}
          >
            <ScrollBtn variant={variant} position={'left'} />
          </div>
        </CSSTransition>
        <CSSTransition
          in={left < 1 && needButtons && !disableArrow}
          timeout={300}
          classNames="fade-in"
          mountOnEnter
          unmountOnExit
        >
          <div
            className={classNames(
              s.scrollNav__btn,
              s['_' + (buttonPositionRight || buttonPosition)],
              s._right,
              'scrollNav__btn _right'
            )}
            onClick={this.arrowScrollRight}
          >
            <ScrollBtn variant={variant} position={'right'} />
          </div>
        </CSSTransition>
      </div>
    );
  }
}

HorizontalScroll.NORMAL = NORMAL;
HorizontalScroll.DARKER = DARKER;

HorizontalScroll.INSIDE = INSIDE;
HorizontalScroll.EDGE = EDGE;
HorizontalScroll.OUTSIDE = OUTSIDE;
