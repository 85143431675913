import React, { useEffect, useRef, useState } from 'react';

export function HorizontalBarLabel({
  width,
  y,
  style,
  legendPadding,
  titleColor,
  children
}) {
  const [position, setPosition] = useState({});
  const textRef = useRef(null);

  useEffect(() => {
    const textWidth = textRef.current.clientWidth;
    const isEnoughSpace = width > textWidth + 10;

    setPosition({
      x: isEnoughSpace ? width - 5 + legendPadding : width + 5 + legendPadding,
      fill: isEnoughSpace ? 'white' : 'black',
      anchor: isEnoughSpace ? 'end' : 'start',
      visible: true
    });
  }, [width, legendPadding]);

  return (
    <text
      ref={textRef}
      x={position.x ? position.x : 0}
      y={y}
      fill={titleColor ? titleColor : position.fill ? position.fill : 'white'}
      textAnchor={position.anchor ? position.anchor : 'start'}
      style={{ ...style, opacity: position.visible ? 1 : 0 }}
    >
      {children}
    </text>
  );
}
