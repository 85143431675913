import React, { useState } from 'react';
import classNames from 'classnames';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import DonutPieChart from '../../../common/charts/DonutPieChart';
import { Select } from '../../../common/ui/Select/Select';
import { CircleProgressIndicator } from '../../ui/ProgressIndicator/CircleProgressIndicator';
import { declineUnit, formatIndexValue } from '../../../utils/formatNumber';
import { NumberTooltip } from '../../ui/ToolTip/NumberTooltip';
import s from './Types.module.scss';

const tooltip = (actualityDate) => (data) => {
  return (
    <NumberTooltip
      data={[
        {
          id: 0,
          name: data.title,
          color: data.color,
          value: data.value
        }
      ]}
      actualityDate={actualityDate}
    />
  );
};

const Chart = ({ index, data, actualityDate }) => {
  let summ = 0;
  data.forEach((item) => (summ += +item.value));

  return (
    <div className={s.Chart}>
      <div className={s.Chart__view}>
        <DonutPieChart
          className={s.Chart__chart}
          data={data}
          tooltip={tooltip(actualityDate)}
          outerRadius={256}
          innerRadius={152}
          size={512}
        />
        <div className={s.Chart__info}>
          <div className={s.Chart__infoContent}>
            <div className={s.Chart__infoValue}>
              <p>{formatIndexValue(summ, index)}</p>
            </div>
            <div className={s.Chart__infoUnits}>
              <p>{declineUnit(summ, index.unit)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Legend = ({ data, index }) => {
  return (
    <div className={s.ChartLegend}>
      {data.map((item, i) => (
        <div key={i} className={s.ChartLegend__item}>
          <div
            className={s.ChartLegend__itemDot}
            style={{ backgroundColor: item.color }}
          />
          <div className={s.ChartLegend__itemTitle}>
            <p>{item.title}</p>
          </div>
          <div className={s.ChartLegend__itemValue}>
            <p>{formatIndexValue(item.value, index)}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export const Types = ({
  className,
  indices,
  items,
  loading,
  actualityDate
}) => {
  const options = indices.map((item, index) => ({
    label: item.name,
    value: index
  }));
  const [selected, setSelected] = useState(options[0]);
  const index = indices[selected.value];
  const selectedItems = items && items[index.field];
  return (
    <div className={classNames(s.Types, className)}>
      <PageSubTitle
        className={s.Types__title}
        text={'Объекты по направленности'}
      />
      {loading && <CircleProgressIndicator />}
      {!loading && items && (
        <div className="Types__chart">
          <div className={s.Types__filter}>
            <Select
              className={s.Types__select}
              height={Select.SMALL}
              value={selected}
              values={options}
              onChange={setSelected}
            />
          </div>
          <Chart
            data={selectedItems}
            index={index}
            actualityDate={actualityDate}
          />
          <Legend data={selectedItems} index={index} />
        </div>
      )}
      {!loading && !items && 'Нет данных'}
    </div>
  );
};
