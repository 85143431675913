import { mean, scaleLinear } from 'd3';
import { isValidNumber } from '../../../utils/math';
import {
  getStatusByValue,
  getStatusColor,
  STATUS_TYPES
} from '../../../utils/constants';
import {
  INDICATOR_DC_COMPLETION,
  INDICATOR_INTERNET_SPEED,
  MAX_INTERNET_CITY,
  MAX_INTERNET_COUNTRYSIDE,
  SPEED_TYPE_COUNTRYSIDE
} from '../../../utils/indicators';
import colors from '../../../colors.scss';

export const getScaledMapColor = scaleLinear()
  .domain([0, 50, 80, 100])
  .range([colors.error, colors.warn, colors.warn, colors.success])
  .clamp(true);

export const percentageToColor = (
  indicatorValue,
  rangeMin,
  rangeMax,
  indicatorType
) => {
  if (!isValidNumber(indicatorValue)) {
    return colors.gray;
  } else if (indicatorValue < rangeMin) {
    return colors.gray;
  } else if (indicatorValue > rangeMax) {
    return colors.gray;
  } else if (indicatorValue >= rangeMin && indicatorValue <= rangeMax) {
    const statusType =
      indicatorType === INDICATOR_DC_COMPLETION
        ? STATUS_TYPES.COMPLETION
        : STATUS_TYPES.RATING;
    return getStatusColor(getStatusByValue(indicatorValue, statusType));
  }
};

export function getInternetColor(
  internetSpeed,
  speed_type,
  rangeMin,
  rangeMax
) {
  const upload = internetSpeed?.upload_speed;
  const download = internetSpeed?.download_speed;
  const averageSpeed = mean([upload, download]);

  let rangeMaxSpeed = rangeMax;
  let maxRange = MAX_INTERNET_CITY;

  if (speed_type === SPEED_TYPE_COUNTRYSIDE) {
    // Можем делить на 2 т.к слайдед работает на костантных значения от
    // 0 до 100, а сельская скорость от 0 до 50
    rangeMaxSpeed = rangeMax / 2;
    maxRange = MAX_INTERNET_COUNTRYSIDE;
  }

  return percentageToColor(
    Math.min(averageSpeed, maxRange),
    rangeMin,
    rangeMaxSpeed,
    INDICATOR_INTERNET_SPEED
  );
}
