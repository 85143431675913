import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import React from 'react';
import { Types } from '../Types/Types';
import { QUERY_GET_INVENTORY } from '../../../graphql/queries/inventory';
import { createFadingPalette } from '../../../utils/colors';
import { Inventory } from './Inventory';
import colors from '../../../colors.scss';

const inventoryQueryOptions = {
  query: QUERY_GET_INVENTORY,
  skipQuery: skipIfNull({})
};

const specialistIndices = [
  {
    field: 'count_specialists_ready',
    name:
      'Число специалистов, потенциально готовых ' +
      '(дополнительно к текущей нагрузке) осуществлять ' +
      'образовательную и (или) воспитательную деятельность ' +
      'с детьми, молодежью и (или) взрослыми',
    unit: ' чел.'
  },
  {
    field: 'count_additional_hours',
    name:
      'Количество часов в неделю, которые специалисты (дополнительно к текущей нагрузке) ' +
      'готовы посвящать ведению образовательной, внеучебной и (или) воспитательной работы',
    unit: ' ч.'
  },
  {
    field: 'avg_additional_hours_weekly',
    name:
      'Среднее количество часов в неделю, которые специалист ' +
      'готов посвящать ведению образовательной, внеучебной и (или) воспитательной работы',
    unit: ' ч.'
  }
];

function getMaxPeriod(items) {
  let maxPeriod = null;
  for (const item of items) {
    if (!maxPeriod || maxPeriod.start < item.period.start) {
      maxPeriod = item.period;
    }
  }
  return maxPeriod;
}

function convertSpecialistsData(items) {
  if (!items) return null;
  const maxPeriod = getMaxPeriod(items);
  const yearData = maxPeriod
    ? items.find((item) => item.period && item.period.id === maxPeriod.id)
    : null;

  if (!yearData) return null;
  return specialistIndices.map((index) => {
    const value = yearData[index.field];
    return {
      value,
      unit: index.unit,
      desc: index.name,
      decimals: value < 1000 ? 2 : 0
    };
  });
}

const objectIndices = [
  {
    field: 'count_objects_hours_idle',
    name: 'Количество объектов с часами простоя',
    unit: [' объект', ' объекта', ' объектов'],
    decimals: 0
  },
  {
    field: 'count_hours_idle',
    name: 'Количество часов простоя',
    unit: [' час', ' часа', ' часов'],
    decimals: 0
  },
  {
    field: 'objects_area',
    name: 'Площадь объектов',
    unit: ' м²',
    decimals: (v) => (v < 1000 ? 2 : 0)
  }
];

const objectTypes = [
  {
    label: 'Широкопрофильные',
    value: 'WIDE_AREA'
  },
  {
    label: 'Естественно-научные',
    value: 'SCIENCE'
  },
  {
    label: 'Технические',
    value: 'TECH'
  },
  {
    label: 'Художественные',
    value: 'ART'
  },
  {
    label: 'Физкультурно-спортивные',
    value: 'PHYS_ED'
  },
  {
    label: 'Туристско-краеведческие',
    value: 'TOURIST'
  },
  {
    label: 'Социально-педагогические',
    value: 'SOC_PED'
  },
  {
    label: 'Иные',
    value: 'OTHER'
  }
];

const colorInterpolator = createFadingPalette(
  colors.chartPurple,
  objectTypes.length
);

function convertObjectsData(items) {
  if (!items) return null;
  const maxPeriod = getMaxPeriod(items);
  const yearData = maxPeriod
    ? items.filter((item) => item.period && item.period.id === maxPeriod.id)
    : items;
  if (yearData.length === 0) return null;
  const result = {};
  for (const index of objectIndices) {
    result[index.field] = objectTypes
      .map((type, iType) => {
        const d = yearData.find((d) => d.object_type === type.value);

        return {
          id: type.value,
          title: type.label,
          value: (d && d[index.field]) || 0,
          color: colorInterpolator(iType)
        };
      })
      .filter((x) => x.value);
  }
  return result;
}

export function PageInventory({
  variables,
  actualityDate,
  classNameInventory,
  classNameTypes,
  isMobile
}) {
  const query = useGraphqlQuery({
    queryOptions: inventoryQueryOptions,
    variables
  });
  const loading = query.check.spinner;

  const specialists =
    query.state.data &&
    convertSpecialistsData(query.state.data.specialistsInventory);

  const objects =
    query.state.data && convertObjectsData(query.state.data.objectsInventory);

  return (
    <>
      <Inventory
        loading={loading}
        items={specialists}
        actualityDate={actualityDate}
        className={classNameInventory}
        isMobile={isMobile}
      />
      <Types
        loading={loading}
        items={objects}
        indices={objectIndices}
        actualityDate={actualityDate}
        className={classNameTypes}
      />
    </>
  );
}
