import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useContextStore, useStoreState } from '@proscom/prostore-react';
import classNames from 'classnames';
import { matchPath } from 'react-router';
import LogoSvg from '../../assets/img/logo-bw.svg';
import { ReactComponent as IconMap } from '../../assets/Icons/Menu/Map.svg';
import { ReactComponent as IconReport } from '../../assets/Icons/Menu/Report.svg';
import { ReactComponent as IconSearch } from '../../assets/Icons/Search Copy 1.svg';
import { ReactComponent as IconIndicators } from '../../assets/Icons/Menu/Indicators.svg';
import { ReactComponent as IconCalendar } from '../../assets/Icons/Menu/Calendar.svg';
import { ReactComponent as IconMore } from '../../assets/Icons/Menu/More.svg';
import { IconButton } from '../ui/IconButton/IconButton';
import {
  PORTAL_ACTUALITY_DATE,
  STORE_AUTH,
  STORE_PORTALS,
  URL_QUERY_RP
} from '../../store/stores';
import { User } from '../../data/user';
import { usePreserveQuery } from '../../store/usePreserveQuery';
import { useWindowSize } from '../../store/useWindowSize';
import { SwipeBox } from '../SwipeBox/SwipeBox';
import s from './NavBar.module.scss';

const navLinks = [
  {
    icon: IconIndicators,
    text: 'Показатели',
    to: '/indicators',
    roles: [User.roles.ADMIN, User.roles.FEDERAL_ADMIN]
  },
  {
    icon: IconIndicators,
    text: 'Паспорт',
    to: '/regions',
    toPassport: true,
    roles: [User.roles.REGION_ADMIN]
  },
  {
    icon: IconMap,
    text: 'Карта',
    to: '/map',
    toMap: true,
    additionalPrefix: ['/districts', '/regions', '/organizations']
  },
  {
    icon: IconReport,
    text: 'Отчеты',
    to: '/reports',
    roles: [User.roles.ADMIN, User.roles.FEDERAL_ADMIN]
  },
  {
    icon: IconCalendar,
    text: 'Календарь',
    to: '/calendar',
    roles: [User.roles.ADMIN, User.roles.FEDERAL_ADMIN]
  },
  {
    icon: IconMore,
    text: 'Еще',
    forMobile: true,
    forSwipeBox: true
  }
];

const matchActivePath = (additionalLocations) => {
  const locations =
    typeof additionalLocations === 'string'
      ? [additionalLocations]
      : additionalLocations;
  if (!Array.isArray(locations)) {
    return null;
  }

  return (match, currentLocation) => {
    if (match) {
      return true;
    }
    for (const item of locations) {
      const match = matchPath(currentLocation.pathname, item);
      if (match) {
        return true;
      }
    }
    return false;
  };
};

function NavBarLink(props) {
  const {
    to,
    icon: Icon,
    text,
    forMobile,
    onClick,
    preserved,
    isMobile,
    isActive
  } = props;

  const LinkComponent = !!to ? (
    <NavLink
      to={preserved.createLink(to)}
      isActive={isActive}
      className={s.NavBarMenuLink}
      activeClassName={s._active}
      onClick={onClick}
    >
      <div className={s.NavBarMenuLink__icon}>
        <Icon />
      </div>
      <span className={s.NavBarMenuLink__text}>{text}</span>
    </NavLink>
  ) : (
    <div className={s.NavBarMenuLink} onClick={onClick}>
      <div className={s.NavBarMenuLink__icon}>
        <Icon />
      </div>
      <span className={s.NavBarMenuLink__text}>{text}</span>
    </div>
  );

  return !isMobile && forMobile ? null : LinkComponent;
}

function SwipeBoxContent({ preserved, user, location }) {
  const pathname = location.pathname;

  return (
    <Fragment>
      {pathname !== '/search' && (
        <div className={s.NavBar__sbSearch}>
          <Link
            to={preserved.createLink('/search')}
            className={s.NavBar__sbSearchLink}
          >
            <div className={s.NavBar__searchImg}>
              <IconSearch />
            </div>
            <span>Поиск по приложению</span>
          </Link>
        </div>
      )}
      <div className={s.NavBar__sbExt}>
        {User.canAccessMainPage(user) && (
          <div className={s.NavBar__sbExtItem}>
            <Link
              to={preserved.createLink('/')}
              className={s.NavBar__sbLogoLink}
            >
              <img className={s.NavBar__logoImg} src={LogoSvg} alt="" />
              <span>Инфографика по проекту</span>
            </Link>
          </div>
        )}
        {user && (
          <div className={s.NavBar__sbExtItem}>
            <div className={s.NavBar__sbUser}>
              <div className={s.NavBar__sbUserIcon}>
                <span>{User.getUserAbbreviation(user)}</span>
              </div>
              <div className={s.NavBar__sbUserInfo}>
                <p className={s.NavBar__sbUserName}>{user.name}</p>
                <Link
                  to={preserved.createLink('/logout')}
                  className={s.NavBar__sbLogoutLink}
                >
                  Выйти из аккаунта
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}

const preservedQueryParams = [URL_QUERY_RP];

export function NavBar(props) {
  const preserved = usePreserveQuery(preservedQueryParams);
  const auth = useStoreState(STORE_AUTH);
  const portals = useContextStore(STORE_PORTALS);
  const user = User.getUser(auth);

  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  const [sbOpened, setSpOpened] = useState(false);

  const closeSwipeBox = () => {
    setSpOpened(false);
  };
  const onMoreClick = () => {
    setSpOpened(!sbOpened);
  };

  const setRef = useCallback(
    (node) => {
      portals.set(PORTAL_ACTUALITY_DATE, node);
    },
    [portals]
  );

  const passportLocation = useMemo(() => {
    return user && user.region_code_nalog
      ? `/regions/${user.region_code_nalog}`
      : null;
  }, [user]);

  return (
    <Fragment>
      <div className={classNames('navbar', s.NavBar)}>
        {user && (
          <div className={s.NavBar__content}>
            <div className={s.NavBar__logo}>
              <Link
                to={preserved.createLink('/')}
                className={s.NavBar__logoLink}
              >
                <img className={s.NavBar__logoImg} src={LogoSvg} alt="" />
              </Link>
            </div>
            <div className={s.NavBar__menu}>
              <ul className={s.NavBarMenu__list}>
                {navLinks
                  .filter(
                    (link) => !link.roles || link.roles.includes(user.role)
                  )
                  .map((link, i) => {
                    const currLink = { ...link };
                    if (currLink.toPassport && passportLocation) {
                      currLink.to = passportLocation;
                    }

                    return (
                      <li key={i} className={s.NavBarMenu__item}>
                        <NavBarLink
                          preserved={preserved}
                          isMobile={isMobile}
                          onClick={currLink.forSwipeBox ? onMoreClick : null}
                          isActive={
                            /**
                             * колхоз:
                             * если есть passportLocation (есть только у REGION_ADMIN),
                             * и loc.pathname === passportLocation,
                             * то активна кнопка паспорта, а не карты
                             */
                            currLink.toMap &&
                            props.location.pathname === passportLocation
                              ? () => false
                              : currLink.additionalPrefix &&
                                matchActivePath(currLink.additionalPrefix)
                          }
                          {...currLink}
                        />
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className={s.NavBar__ext}>
              <div className={s.NavExtButtons}>
                <IconButton
                  className={s.NavIconButton}
                  icon={IconSearch}
                  to={preserved.createLink('/search')}
                />
                {user && (
                  <IconButton
                    className={s.NavIconButton}
                    text={User.getUserAbbreviation(user)}
                    to={preserved.createLink('/logout')}
                  />
                )}
              </div>
              <div className={s.ActualityDateContainer} ref={setRef} />
            </div>
          </div>
        )}
      </div>
      {isMobile && (
        <SwipeBox
          className={s.NavBar__swipeBox}
          innerClassName={s.NavBar__swipeBoxInner}
          contentClassName={s.NavBar__swipeBoxContent}
          isOpened={sbOpened}
          onClose={closeSwipeBox}
        >
          <SwipeBoxContent
            preserved={preserved}
            user={user}
            location={props.location}
          />
        </SwipeBox>
      )}
    </Fragment>
  );
}
