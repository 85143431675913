export const TO_RAD = Math.PI / 180;

/**
 * Переводит градусы в радианы
 * @param degree {number}
 * @returns {number}
 */
export function degreeToRad(degree) {
  return degree * TO_RAD;
}

/**
 * Переводит полярные координаты в декартовы
 * @param radius {number}
 * @param angle {number}
 * @returns {{x: number, y: number}}
 */
export function polarToCartesian(radius, angle) {
  return {
    x: radius * Math.cos(angle),
    y: radius * Math.sin(angle)
  };
}

/**
 * Возвращает случайное целое число между ceil(min) и floor(max), не включая max
 * @param min {number}
 * @param max {number}
 * @returns {number}
 */
export function getRandomInteger(min = 0, max = 100) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

/**
 * Возвращает красивое круглое число, не сильно больше переданного
 * @param number {number}
 * @returns {number}
 */
export function roundToRound(number) {
  if (!number) return number;
  const log10 = Math.log10(number);
  const floor = Math.floor(log10);
  const ceil = Math.ceil(log10);
  const fl10 = Math.pow(10, floor);
  const steps = number / fl10;
  if (steps >= 8) {
    return Math.pow(10, ceil);
  }
  if (steps <= 3) {
    return (Math.ceil(steps * 2) * fl10) / 2;
  }
  if (steps <= 1) {
    return (Math.ceil(steps * 10) * fl10) / 10;
  }
  return Math.ceil(steps) * fl10;
}

/**
 * Подбирает количество шагов легенды, чтобы цифры были красивые
 * @param max {number} - верхняя граница
 * @param min {number} - нижняя граница
 * @param decimals {number} - количество точек после запятой
 * @param maxSteps {number} - максимум шагов
 * @param minSteps {number} - миниммум шагов
 * @returns {number}
 */
export function getRoundLegendSteps(
  max,
  min = 0,
  decimals = 0,
  maxSteps = 11,
  minSteps = 5
) {
  let maxRounds = 0;
  let maxRoundsSteps = minSteps;
  for (let steps = minSteps; steps <= maxSteps; steps++) {
    const step = (max - min) / (steps - 1);

    let nRounds = 0;
    let value = min;
    for (let i = 0; i <= steps; i++) {
      const label = value.toFixed(decimals).toString();
      let zeros = 0;
      for (let k = label.length - 1; k >= 0; k--) {
        if (label[k] === '0') {
          zeros++;
        } else {
          break;
        }
      }

      nRounds += zeros;
      value += step;
    }

    if (nRounds > maxRounds) {
      maxRounds = nRounds;
      maxRoundsSteps = steps;
    }
  }
  return maxRoundsSteps;
}

export function getLegendDecimals(min, max, legendSteps = 5) {
  let step = Math.abs((max - min) / legendSteps);
  const log10 = Math.floor(Math.log10(step));

  if (step === 0) {
    return 0;
  }

  if (log10 < 0) {
    return Math.abs(log10);
  }

  return 0;
}

export function findMinIndex(array, metric) {
  let minMetric = null;
  let minIndex = null;
  for (let i = 0; i < array.length; i++) {
    const m = metric(array[i], i);
    if (minMetric === null || m < minMetric) {
      minMetric = m;
      minIndex = i;
    }
  }
  return minIndex;
}

export function findMinWrapped(current, next, wrap) {
  const shift = wrap * Math.floor(current / wrap);
  const options = [shift + next, shift - wrap + next, shift + wrap + next];
  const minIndex = findMinIndex(options, (v) => Math.abs(v - current));
  return options[minIndex];
}

export function positiveMod(a, b) {
  const res = a % b;
  return res >= 0 ? res : res + b;
}

export function isValidNumber(n) {
  return typeof n == 'number' && !isNaN(n) && isFinite(n);
}

export function tryNumber(value, defaultValue) {
  const number = +value;
  if (isValidNumber(number)) {
    return number;
  }
  return defaultValue;
}

/**
 *
 * @param number
 * @param {null|number} base
 * @returns {number}
 */
export function unificationNumber(number, base) {
  if (number < 0) {
    return 0;
  } else if (base && number > base) {
    return base;
  }
  return number;
}
