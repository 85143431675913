import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { LineProgressIndicator } from '../ProgressIndicator/LineProgressIndicator';
import BaseButton from './BaseButton';
import s from './Button.module.scss';

const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const DARKER = 'darker';
const CARD = 'card';

export function ButtonComponent({ to, href, icon, ...props }) {
  return to ? (
    <Link
      to={to}
      {...props}
      className={classNames(props.className, props.disabled && 'linkDisabled')}
    />
  ) : href ? (
    <a
      href={href}
      {...props}
      className={classNames(props.className, props.disabled && 'linkDisabled')}
    >
      {props.children}
      {icon && <i>{icon}</i>}
    </a>
  ) : (
    <BaseButton {...props} />
  );
}

export function Button({
  children,
  variant,
  onClick,
  disabled,
  loading,
  className,
  to,
  href,
  ...props
}) {
  return (
    <ButtonComponent
      className={classNames(s.Button, className, {
        [s.Button_secondary]: variant === SECONDARY,
        [s.Button_darker]: variant === DARKER,
        [s.Button_card]: variant === CARD,
        [s._link]: !!to || !!href,
        [s._withIcon]: !!href
      })}
      // Эти пропы прокидываются отдельно, чтобы обозначить их в аргументах функции
      onClick={onClick}
      disabled={disabled}
      to={to}
      href={href}
      {...props}
    >
      {children}
      {loading ? <LineProgressIndicator className={s.Button__loader} /> : null}
    </ButtonComponent>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.any,
  href: PropTypes.string
};

Button.PRIMARY = PRIMARY;
Button.SECONDARY = SECONDARY;
Button.DARKER = DARKER;
Button.CARD = CARD;
