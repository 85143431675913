import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import classNames from 'classnames';
import { format } from 'date-fns';
import { DATE_FORMAT_RUSSIAN } from '../../utils/date';
import s from './ImageSlider.module.scss';

export class ImageSlider extends Component {
  state = {
    activeIndex: this.props.selectedItem || 0
  };

  onChange = (activeIndex, element) => {
    this.setState({ activeIndex });
  };

  render() {
    const {
      className,
      slides,
      sliderProps,
      actualityDate,
      children
    } = this.props;

    return (
      <div className={classNames(s.ImageSlider, className)}>
        <Carousel
          className={s.ImageSlider__slider}
          onChange={this.onChange}
          showStatus={false}
          {...sliderProps}
        >
          {slides.map((slide, i) => (
            <div key={i} className={s.ImageSlider__slide}>
              <img
                className={s.ImageSlider__slideImage}
                src={slide.img}
                alt=""
              />
              {slide.desc && (
                <p className={s.ImageSlider__slideDesc}>{slide.desc}</p>
              )}
            </div>
          ))}
        </Carousel>
        <div className={s.ImageSlider__status}>
          <p>
            {this.state.activeIndex + 1}/{slides.length}
          </p>
          {actualityDate && (
            <p className={s.ImageSlider__actualityDate}>
              {format(actualityDate, DATE_FORMAT_RUSSIAN)}
            </p>
          )}
        </div>
        {children}
      </div>
    );
  }
}
