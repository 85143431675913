import React from 'react';
import PropTypes from 'prop-types';
import { arc, color as dColor } from 'd3';
import { TO_RAD } from '../../../utils/math';
import { pie } from '../../../utils/pie';
import s from '../../../routes/IndexPage/ProjectsChartView/ProjectsChartView.module.scss';

function HistogramSectionChart({
  data,
  color,
  innerRadius,
  lineStroke,
  innerBarRadius,
  outerBarRadius,
  barGapAngle,
  barWidthAngle,
  segmentOpacity,
  rotateAngle,
  total,
  onSectionClick,
  onBarClick
}) {
  const pieGenerator = pie()
    .value((item) => item.value)
    .gapAngle(barGapAngle)
    .rotateAngle(barGapAngle + rotateAngle)
    .angleFormula(({ alpha }) => alpha + barWidthAngle * TO_RAD);

  const chartData = pieGenerator(data.values);

  const arcGenerator = arc()
    .innerRadius(innerBarRadius)
    .outerRadius(outerBarRadius);

  const clickPaths = chartData.map((item) => {
    return {
      item,
      path: arcGenerator({
        startAngle: item.startAngle - (barGapAngle / 2) * TO_RAD,
        endAngle: item.endAngle + (barGapAngle / 2) * TO_RAD
      })
    };
  });

  const backPathArray = chartData.map((item) => {
    return {
      id: `bar_back_${data.id}_${item.data.id ? item.data.id : item.index}`,
      path: arcGenerator({
        startAngle: item.startAngle,
        endAngle: item.endAngle
      }),
      isSelected: item.data.isSelected
    };
  });

  const mainPathArray = chartData.map((item) => {
    const totalValue = item.value <= total ? item.value : total;
    const arcOuterRadius =
      (outerBarRadius - innerBarRadius) * (totalValue / total) + innerBarRadius;
    return {
      id: `bar_${data.id}_${item.data.id ? item.data.id : item.index}`,
      path: arcGenerator.outerRadius(arcOuterRadius)({
        startAngle: item.startAngle,
        endAngle: item.endAngle
      }),
      isSelected: item.data.isSelected
    };
  });

  const underLineEndAngle =
    chartData.length * barWidthAngle +
    chartData.length * barGapAngle +
    barGapAngle;

  const underLinePath = arc()({
    startAngle: rotateAngle * TO_RAD,
    endAngle: underLineEndAngle * TO_RAD + rotateAngle * TO_RAD,
    innerRadius: innerRadius - lineStroke / 2,
    outerRadius: innerRadius + lineStroke / 2
  });

  const outlinePath = arc()({
    startAngle: rotateAngle * TO_RAD,
    endAngle: underLineEndAngle * TO_RAD + rotateAngle * TO_RAD,
    innerRadius: innerRadius - lineStroke / 2,
    outerRadius: outerBarRadius + lineStroke / 2
  });

  let colorOpacity;
  let colorFaded;
  let colorBarActive;
  if (color) {
    colorOpacity = dColor(color);
    colorOpacity.opacity = segmentOpacity;
    colorFaded = dColor(color);
    colorFaded.opacity = 0.5;
    colorBarActive = dColor(color);
    colorBarActive.opacity = 0.2;
  } else {
    colorOpacity = dColor('rgba(255,255,255,0.2)');
    colorBarActive = dColor('rgba(255,255,255,0.2)');
    colorFaded = dColor('rgba(255,255,255,0.5)');
  }

  return (
    <g>
      {(!('isSelected' in data) || data.isSelected) &&
        backPathArray.map((item) => (
          <path
            key={item.id}
            fill={
              !('isSelected' in item) || item.isSelected
                ? colorBarActive.formatRgb()
                : colorOpacity.formatRgb()
            }
            d={item.path}
          />
        ))}
      {mainPathArray.map((item) => {
        return (
          <path
            key={item.id}
            fill={
              !('isSelected' in item) || item.isSelected
                ? color
                : colorFaded.formatRgb()
            }
            d={item.path}
          />
        );
      })}
      <path fill={color} d={underLinePath} />
      <g
        style={{ cursor: 'pointer' }}
        onClick={onSectionClick}
        className={s.ChartView__HistogramSection}
      >
        <path fill="transparent" d={outlinePath} />
        {clickPaths.map((item, iItem) => {
          return (
            <path
              key={iItem}
              fill="transparent"
              d={item.path}
              onClick={(e) => onBarClick(item.item, iItem, e)}
            />
          );
        })}
      </g>
    </g>
  );
}

HistogramSectionChart.propTypes = {
  data: PropTypes.object.isRequired,
  innerRadius: PropTypes.number.isRequired,
  innerBarRadius: PropTypes.number.isRequired,
  outerBarRadius: PropTypes.number.isRequired,
  barGapAngle: PropTypes.number.isRequired,
  barWidthAngle: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  color: PropTypes.string,
  lineStroke: PropTypes.number,
  segmentOpacity: PropTypes.number,
  rotateAngle: PropTypes.number
};

HistogramSectionChart.defaultProps = {
  color: 'white',
  lineStroke: 2,
  segmentOpacity: 0.2,
  rotateAngle: 0
};

export default HistogramSectionChart;
