import queryString from 'query-string';
import { apiUrl } from '../config';

export function getParentUrl(path, depth = 1) {
  let cpath = path;
  const last = cpath.length - 1;
  if (cpath[last] === '/') {
    cpath = path.slice(0, last);
  }
  for (let i = 0; i < depth; i++) {
    let end = cpath.lastIndexOf('/');
    if (end > 0) {
      cpath = cpath.slice(0, end);
    } else {
      cpath = '/';
    }
  }
  return cpath;
}

export function parseQueryArray(query) {
  return query ? query.split(',') : null;
}

export function encodeQueryArray(data) {
  return data ? data.join(',') : undefined;
}

export function applyUrlAuth(url, auth) {
  return queryString.stringifyUrl({
    url: url,
    query: { token: auth }
  });
}
