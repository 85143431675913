import React from 'react';
import { useSearch } from '../../../utils/useSearch';
import { SearchInput } from '../../ui/SearchInput/SearchInput';
import { Button } from '../../ui/Button/Button';
import { ReactComponent as ArrowIcon } from '../../../assets/Icons/Arrow.svg';
import s from './MapFiltersPopup.module.scss';

export function FiltersHead({ setView, query, changeQuery, links }) {
  const { search, handleSearch, handleChange, clearInput } = useSearch(
    query,
    changeQuery
  );

  return (
    <>
      <div className={s.FiltersLink__subTitle}>
        Начните вводить название субъекта, федерального проекта или результата
      </div>
      <div className={s.FiltersLink__search}>
        <form onSubmit={handleSearch}>
          <SearchInput
            name="search"
            placeholder={'ФП, результаты'}
            defaultValue={search}
            isSearchBtn
            isCloseBtn
            onCloseBtnClick={clearInput}
            onChange={handleChange}
          />
        </form>
      </div>
      <div className={s.FiltersLink__links}>
        {links
          .filter((item) => item.count > 0)
          .map((item, index) => (
            <Button
              key={index}
              variant={Button.CARD}
              onClick={() => {
                setView(item.value);
              }}
              className={s.FiltersLink__link}
            >
              <div>{item.label + ` (${item.selected} / ${item.count})`}</div>
              <div className={s.FiltersLink__arrow}>
                <ArrowIcon />
              </div>
            </Button>
          ))}
      </div>
    </>
  );
}
