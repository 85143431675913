import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useStateTabs } from '../../../common/ui/Tab/TabRadio';
import { CardsScroll } from '../CardsScroll/CardsScroll';
import { ChipRadio } from '../../../common/ui/Chip/ChipRadio';
import { declinedText } from '../../../utils/string';
import { LEQ, NBSP } from '../../../utils/utf';
import {
  getStatusByValue,
  RatingStatus,
  STATUS_TYPES
} from '../../../utils/constants';
import { INDICATOR_DC_COMPLETION, indicators } from '../../../utils/indicators';
import { useWindowSize } from '../../../store/useWindowSize';
import { isBOCardsActive } from '../../../config';
import s from './CardsView.module.scss';
import colors from '../../../colors.scss';

const SUCCESS = 'success';
const WARN = 'warn';
const ERROR = 'error';
const chipRadioData = [
  {
    title: 'все',
    value: null
  },
  {
    title: 'Критично',
    value: ERROR,
    color: colors.error
  },
  {
    title: 'Умеренно',
    value: WARN,
    color: colors.warn
  },
  {
    title: 'Положительно',
    value: SUCCESS,
    color: colors.success
  }
];

const processCardData = (regionsAndRatings) => {
  if (!regionsAndRatings.state.data || !regionsAndRatings.state.data.regions) {
    return [];
  }

  const parsedQuery = {};
  regionsAndRatings.state.data.regions.forEach((regionAndRatings) => {
    indicators.forEach(({ label, value }) => {
      if (!parsedQuery[value]) {
        parsedQuery[value] = {
          title: label + ' (%)',
          indicator: value,
          success: 0,
          warn: 0,
          error: 0
        };
      }
      const currentStatus = getStatusByValue(
        regionAndRatings.ratings[value],
        value === INDICATOR_DC_COMPLETION
          ? STATUS_TYPES.COMPLETION
          : STATUS_TYPES.RATING
      );
      switch (currentStatus) {
        case RatingStatus.SUCCESS:
          parsedQuery[value].success++;
          break;
        case RatingStatus.WARN:
          parsedQuery[value].warn++;
          break;
        case RatingStatus.ERROR:
          parsedQuery[value].error++;
          break;
        default:
        // do nothing
      }
    });
  });

  const result = [];

  Object.values(parsedQuery).forEach((item) => {
    if (isBOCardsActive && item.indicator === 'accept') {
      return;
    }

    let descSuccess = '',
      descWarn = '',
      descError = '';
    if (item.indicator === INDICATOR_DC_COMPLETION) {
      descSuccess = item.title + ' =' + NBSP + '100%';
      descWarn = '50% ' + LEQ + ' ' + item.title + ' <' + NBSP + '100%';
      descError = item.title + ' <' + NBSP + '50%';
    } else {
      descSuccess = item.title + ' >' + NBSP + '80%';
      descWarn = '50%' + NBSP + '< ' + item.title + ' ' + LEQ + NBSP + '80%';
      descError = item.title + ' ' + LEQ + NBSP + '50%';
    }

    result.push({
      description: descSuccess,
      title: declinedText(
        item.success,
        '$n субъект',
        '$n субъекта',
        '$n субъектов'
      ),
      status: SUCCESS,
      indicator: item.indicator
    });
    result.push({
      description: descWarn,
      title: declinedText(
        item.warn,
        '$n субъект',
        '$n субъекта',
        '$n субъектов'
      ),
      status: WARN,
      indicator: item.indicator
    });
    result.push({
      description: descError,
      title: declinedText(
        item.error,
        '$n субъект',
        '$n субъекта',
        '$n субъектов'
      ),
      status: ERROR,
      indicator: item.indicator
    });
  });

  return result;
};

export const CardsView = React.memo(function CardsView({
  className,
  regionsAndRatings
}) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;
  const [status, statusProps] = useStateTabs(chipRadioData, (x) => x.value);

  const cardData = useMemo(() => {
    return processCardData(regionsAndRatings);
  }, [regionsAndRatings]);

  const cardsFiltered = cardData.filter(
    (card) => !status || card.status === status
  );

  return (
    <div className={classNames(s.CardView, className)}>
      <div className={s.CardView__filter}>
        <div className={s.CardView__filterWrapper}>
          <div className={s.CardView__filterItems}>
            {chipRadioData.map((item, iItem) => (
              <ChipRadio
                key={iItem}
                className={s.CardView__item}
                title={item.title}
                color={item.color}
                {...statusProps(item.value)}
              />
            ))}
          </div>
        </div>
      </div>
      <CardsScroll cards={cardsFiltered} isMobile={isMobile} />
    </div>
  );
});
