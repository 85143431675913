import { useMemo } from 'react';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import {
  QUERY_GET_FEDERAL_PROJECT_RESULTS,
  QUERY_GET_FEDERAL_PROJECTS
} from '../graphql/queries/federalProjects';
import { useUrlQuery } from '../utils/useUrlQuery';
import { compareNumericStrings } from '../utils/data';

const getFederalProjectsQueryOptions = {
  query: QUERY_GET_FEDERAL_PROJECTS,
  mapData: (result) =>
    result.federalProjects
      .slice()
      .sort((a, b) => compareNumericStrings(a.project_code, b.project_code))
};

const getFederalProjectResultsQueryOptions = {
  query: QUERY_GET_FEDERAL_PROJECT_RESULTS,
  mapData: (result) => result.resultFederals.list,
  skipQuery: skipIfNull(null)
};

const defaultVariables = {};

export function useFederalProjectResults(federalProjects) {
  return useGraphqlQuery({
    queryOptions: getFederalProjectResultsQueryOptions,
    variables: federalProjects
  });
}

export function useFederalProjects(variables) {
  return useGraphqlQuery({
    queryOptions: getFederalProjectsQueryOptions,
    variables: variables ? variables : defaultVariables
  });
}

export const useSelectedFp = () => {
  const fpQuery = useFederalProjects();
  const [locationQuery] = useUrlQuery();
  const fpCode = +locationQuery.fp || 0;
  const fpQueryData = fpQuery.state.data;
  return useMemo(() => {
    return fpQueryData && fpQueryData.find((fp) => +fp.project_code === fpCode);
  }, [fpQueryData, fpCode]);
};
