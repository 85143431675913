import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as MainLogo } from '../../assets/img/logo-color.svg';
import s from '../LoginPage/LoginPage.module.scss';

export const LoginPageLayout = ({ className, title, children }) => {
  return (
    <div className={classNames(s.PageOffset, className)}>
      <div className={s.PageCard}>
        <div className={s.PageLogo}>
          <MainLogo />
        </div>
        {title && <h1 className={s.PageTitle}>{title}</h1>}
        {children}
      </div>
    </div>
  );
};

LoginPageLayout.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
};
