import React from 'react';
import classNames from 'classnames';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { Button, ButtonComponent } from '../../common/ui/Button/Button';
import { getDataName } from '../../utils/data';
import { ROADMAPS } from './entities/_all';
import s from './SearchPage.module.scss';

function SearchResultGroup({
  className,
  isHidden,
  variant,
  searchResult,
  title,
  getDataId,
  getDataLink,
  getDataName: getDataNameProp = getDataName,
  showMore,
  type,
  isRegionAdmin,
  user
}) {
  const { totalCount, list, loading } = searchResult;

  if (isHidden) {
    return null;
  }

  if (!loading && list && list.length === 0) {
    return null;
  }

  return (
    <div className={classNames(s.SearchResult, className)}>
      <div className={s.SearchResult__header}>
        <div className={s.SearchResult__title}>{title}</div>
        {!loading && totalCount ? (
          <div className={s.SearchResult__count}>{totalCount}</div>
        ) : null}
      </div>
      {loading ? (
        <CircleProgressIndicator className={s.Spinner} />
      ) : list ? (
        <>
          {list.map((item) => (
            <ButtonComponent
              key={getDataId ? getDataId(item) : item.id}
              to={
                type === ROADMAPS && isRegionAdmin
                  ? `${getDataLink(item)}/?region_code=${
                      user.region_code_nalog
                    }`
                  : getDataLink(item)
              }
              className={s.SearchResult__item}
            >
              {getDataNameProp(item)}
            </ButtonComponent>
          ))}
          {!variant ? (
            totalCount > list.length && (
              <Button
                className={s.SearchResult__showMore}
                variant={Button.DARKER}
                onClick={showMore}
              >
                Показать еще
              </Button>
            )
          ) : totalCount > list.length ? (
            <div className={classNames(s.SearchInfo, s._noData)}>
              Уточните поисковый запрос, чтобы получить больше результатов
            </div>
          ) : (
            false
          )}
        </>
      ) : null}
    </div>
  );
}

SearchResultGroup.propTypes = {};

export default SearchResultGroup;
