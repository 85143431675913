import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { EMDASH } from '../utils/utf';
import { joinNonEmpty } from '../utils/data';
import { shortenText } from '../utils/string';

const PROJECT_NAME = 'НП Образование';

export function ReactHelmet({ title, entity, type }) {
  const resultTitle = joinNonEmpty(
    title ? [title, PROJECT_NAME] : [shortenText(entity), type, PROJECT_NAME],
    ` ${EMDASH} `
  );

  return (
    <Helmet>
      <title>{resultTitle}</title>
    </Helmet>
  );
}

ReactHelmet.propTypes = {
  title: PropTypes.string,
  entity: PropTypes.string,
  type: PropTypes.string
};
