import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import s from '../ReportsPage.module.scss';

export const ReportSidebar = ({ title, children }) => {
  return (
    <div className={s.ReportSidebar}>
      <h3 className={s.ReportSidebar__title}>{title}</h3>
      {children}
    </div>
  );
};

ReportSidebar.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export const ReportSwipeBoxContent = ({ title, children }) => {
  return (
    <div className={s.ReportSwipeBox__content}>
      <h3 className={s.ReportSwipeBox__title}>{title}</h3>
      {children}
    </div>
  );
};

ReportSwipeBoxContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};
