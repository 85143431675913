import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  addDays,
  addWeeks,
  endOfMonth,
  isSameDay,
  isSameMonth,
  isWeekend,
  startOfMonth
} from 'date-fns';

import {
  isBeforeOrEqual,
  lIsSameWeek,
  lStartOfWeek,
  tryParseIso
} from '../../../utils/date';
import { ProjectIndicator } from '../../../common/ui/Project/ProjectIndicator';

import BaseButton from '../../../common/ui/Button/BaseButton';

import s from './Calendar.module.scss';

const noop = () => {};

export function CalendarHead() {
  const items = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
  return (
    <div className={s.week}>
      {items.map((item, iItem) => {
        return (
          <div
            key={iItem}
            className={classNames(s.dayHeadEntry, {
              [s.headWeekend]: item === 'сб' || item === 'вс'
            })}
          >
            <div className={s.dayHead}>{item}</div>
          </div>
        );
      })}
    </div>
  );
}

export function CalendarIndicators({ event }) {
  const statuses = [];

  const parseArr = (type) => {
    event.some((item) => item[type] && item[type] > 0) && statuses.push(type);
  };
  parseArr(ProjectIndicator.SUCCESS);
  parseArr(ProjectIndicator.WARN);
  parseArr(ProjectIndicator.ERROR);
  parseArr(ProjectIndicator.IN_PROGRESS);

  return (
    <div className={s.indicators}>
      {statuses.map((item, index) => (
        <ProjectIndicator
          key={index}
          status={item}
          className={s.indicators__item}
        />
      ))}
    </div>
  );
}

export function CalendarDay({
  date,
  currentMonth,
  events,
  selected,
  today,
  onClick
}) {
  const sameMonth = isSameMonth(date, currentMonth);
  const weekend = isWeekend(date);
  const dateDate = date.getDate();
  const dayEvents =
    events && events.filter((item) => isSameDay(tryParseIso(item.date), date));

  return (
    <div
      className={classNames(s.calendarEntry, {
        [s.calendarEntrySelected]: selected
      })}
      onClick={() => onClick(date)}
    >
      <BaseButton
        className={classNames(s.day, {
          [s.dayDifferent]: !sameMonth,
          [s.dayWeekend]: weekend,
          [s.dayToday]: today
        })}
      >
        {dateDate}
      </BaseButton>
      {dayEvents && dayEvents.length ? (
        <CalendarIndicators event={dayEvents} />
      ) : null}
    </div>
  );
}

CalendarDay.propTypes = {
  date: PropTypes.instanceOf(Date),
  currentMonth: PropTypes.instanceOf(Date),
  selected: PropTypes.bool,
  today: PropTypes.bool,
  events: PropTypes.array,
  onClick: PropTypes.func
};

CalendarDay.defaultProps = {
  onClick: noop
};

function CalendarWeek_({
  start,
  value,
  currentMonth,
  currentDate,
  onDayClick,
  events
}) {
  const days = useMemo(() => {
    const days = [];
    for (let i = 0, cdate = start; i < 7; i++, cdate = addDays(cdate, 1)) {
      days.push(cdate);
    }
    return days;
  }, [start]);

  return (
    <div className={s.week}>
      {days.map((day, iDay) => {
        return (
          <CalendarDay
            key={iDay}
            date={day}
            currentMonth={currentMonth}
            selected={isSameDay(day, value)}
            today={isSameDay(day, currentDate)}
            onClick={onDayClick}
            events={events}
          />
        );
      })}
    </div>
  );
}

const CalendarWeek = React.memo(CalendarWeek_);

function CalendarMonth({ month, currentDate, events, value, onChange }) {
  const weeks = useMemo(() => {
    const monthEnd = endOfMonth(month);
    const weekFirstDay = lStartOfWeek(month);
    const weeks = [];
    for (
      let cdate = weekFirstDay;
      isBeforeOrEqual(cdate, monthEnd);
      cdate = addWeeks(cdate, 1)
    ) {
      weeks.push(cdate);
    }
    return weeks;
  }, [month]);

  return (
    <div className={s.month}>
      <CalendarHead />
      <div className={s.monthContent}>
        {weeks.map((week, iWeek) => {
          return (
            <CalendarWeek
              key={iWeek}
              start={week}
              currentMonth={month}
              currentDate={lIsSameWeek(week, currentDate) ? currentDate : null}
              value={lIsSameWeek(week, value) ? value : null}
              onDayClick={onChange}
              events={events}
            />
          );
        })}
      </div>
    </div>
  );
}

export default function Calendar({
  selectedMonth,
  calendarDate,
  currentDate,
  onChangeCalendarDate,
  events
}) {
  const nowDate = startOfMonth(selectedMonth);

  return (
    <CalendarMonth
      key={nowDate.toISOString()}
      onChange={onChangeCalendarDate}
      month={nowDate}
      currentDate={currentDate}
      value={calendarDate}
      events={events}
    />
  );
}

Calendar.propTypes = {
  onChangeCalendarDate: PropTypes.func,
  calendarDate: PropTypes.object.isRequired,
  selectedMonth: PropTypes.object.isRequired,
  currentDate: PropTypes.object.isRequired
};

Calendar.defaultProps = {
  onChangeCalendarDate: noop
};
