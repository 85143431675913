import React, { useMemo, useState } from 'react';
import { Select } from '../../../common/ui/Select/Select';
import { NBSP } from '../../../utils/utf';
import { GrbsFilter } from '../../../common/Filters/GrbsFilter';
import { ResizeContainer } from '../../../common/ResizeContainer';
import { ChartViewChart } from '../../../common/ChartView/ChartView';
import { queryLoader } from '../../../common/QueryLoader';
import { useResultBudgetChart } from './useResultBudgetChart';
import s from './ResultsInfoView.module.scss';
import colors from '../../../colors.scss';

const BUDGET = 'budget';
const KASS = 'kass';

const chartTypes = [
  {
    value: BUDGET,
    label: `Бюджетные ассигнования ФБ${NBSP}и${NBSP}принятие${NBSP}БО`
  },
  {
    value: KASS,
    label: 'Кассовое исполнение по видам бюджетов в разрезе ВР'
  }
];

export function ChartData({ budgetData, query }) {
  const [chartType, setChartType] = useState(chartTypes[0]);

  const { budgetCharts, kassCharts, actualityDate } = useResultBudgetChart(
    budgetData
  );

  const currentCharts = useMemo(() => {
    switch (chartType.value) {
      case BUDGET:
        return budgetCharts;
      case KASS:
        return kassCharts;
      default:
        return null;
    }
  }, [budgetCharts, chartType, kassCharts]);

  return (
    <div className={s.ChartData}>
      <div className={s.ChartData__type}>
        <Select
          height={Select.SMALL}
          variant={Select.SECONDARY}
          values={chartTypes}
          value={chartType}
          onChange={setChartType}
          className={s.ChartData__select}
        />
      </div>
      <div className={s.ChartData__filters}>
        <GrbsFilter variant={Select.SECONDARY} />
      </div>
      <div className={s.ChartData__charts}>
        {queryLoader(query) ||
          (currentCharts && (
            <div className={s.ChartData__scroll}>
              <div className={s.ChartData__inner}>
                <ResizeContainer>
                  {({ width }) => (
                    <ChartViewChart
                      data={currentCharts}
                      width={width}
                      height={192}
                      actualityDate={actualityDate}
                      chartProps={{
                        color: colors.white,
                        textColor: colors.white
                      }}
                      titleClassName={s.ChartData__title}
                    />
                  )}
                </ResizeContainer>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
