import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { color as dColor, interpolateHcl, max } from 'd3';
import { Indicator } from '../Indicator';
import { HorizontalBarChart } from '../../../common/charts/HorizontalBarChart';
import { ResizeContainer } from '../../../common/ResizeContainer';
import { useQueryPeriodFast } from '../../../utils/useQueryPeriod';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { Select } from '../../../common/ui/Select/Select';
import { QUERY_GET_ROADMAP_INDICATORS } from '../../../graphql/queries/indicators';
import { IndicatorsLegend } from '../IndicatorsLegend';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import { queryLoader } from '../../../common/QueryLoader';
import colors from '../../../colors.scss';
import s from './Indicators.module.scss';

const indicatorQueryOptions = {
  query: QUERY_GET_ROADMAP_INDICATORS,
  mapData: (x) => x.seRoadmapIndicatorsSum,
  skipQuery: skipIfNull(null)
};

function processIndicatorData(query) {
  const data = query.state.data;
  if (!data) {
    return [];
  }
  const opacityColor = dColor(colors.chartPurple);
  opacityColor.opacity = 0;
  const endColor = opacityColor.formatRgb();

  // Интерполятор цветов
  const colorInterpolator = interpolateHcl(colors.chartPurple, endColor);

  return data.map((indicator, i) => {
    const values = [
      {
        id: 0,
        value: indicator.value_fact,
        color: colorInterpolator(0)
      },
      {
        id: 1,
        value: indicator.value_from_tender_documentation,
        color: colorInterpolator(0.5)
      }
    ];
    // Если фактическое значение больше планового, то рисуем полоску плана первой,
    // т.к 2 значение не может быть < 1
    if (indicator.value_fact > indicator.value_from_tender_documentation) {
      values.reverse();
    }

    return {
      id: indicator.indicator_id,
      minimum:
        indicator.indicator?.plan_roadmap_indicator?.min_numeric_value_per_year,
      title: indicator.indicator?.name_full || indicator.indicator?.name,
      total: max(values, (x) => x.value),
      values: values
    };
  });
}

function Indicators({ className, indicatorsQuery, selectProps }) {
  const indicators = processIndicatorData(indicatorsQuery);
  return (
    <div className={classNames(s.Indicators, className)}>
      <PageSubTitle className={s.Indicators__title} text={'Индикаторы'} />
      {selectProps && (
        <Select
          className={s.Indicators__select}
          height={Select.SMALL}
          variant={Select.PRIMARY}
          {...selectProps}
        />
      )}
      <IndicatorsLegend
        color={colors.chartPurple}
        legends={['План', 'Факт']}
        isMinimum
      />
      {queryLoader(indicatorsQuery) ||
        (indicators.length > 0 ? (
          <ResizeContainer>
            {({ width }) =>
              indicators.map((item, i) => (
                <Indicator
                  key={item.id}
                  title={item.title}
                  minimum={item.minimum}
                >
                  <HorizontalBarChart
                    data={item.values}
                    total={item.total}
                    minimum={item.minimum}
                    sizeWidth={width}
                    sizeHeight={50}
                  />
                </Indicator>
              ))
            }
          </ResizeContainer>
        ) : (
          <div>Нет данных</div>
        ))}
    </div>
  );
}

export function SubsidiaryIndicators({ subsidiaryId, className }) {
  const [queryParams, changeQuery] = useUrlQuery();
  const { year, month } = useQueryPeriodFast(queryParams, changeQuery);

  const indicatorsQuery = useGraphqlQuery({
    queryOptions: indicatorQueryOptions,
    variables: subsidiaryId
      ? {
          filter: {
            subsidiary_entity_id: subsidiaryId,
            year: year,
            month: month
          }
        }
      : null
  });

  return <Indicators indicatorsQuery={indicatorsQuery} className={className} />;
}

export function SubjectIndicators({ region, regionalRoadmapQuery }) {
  const [queryParams, changeQuery] = useUrlQuery();
  const { year, month } = useQueryPeriodFast(queryParams, changeQuery);
  const regionalRoadmap = regionalRoadmapQuery?.state?.data;

  const roadmapValues = useMemo(() => {
    if (regionalRoadmap) {
      return regionalRoadmap.map((item) => ({
        value: item.id,
        label: item.name
      }));
    }
    return null;
  }, [regionalRoadmap]);

  const [roadmapValue, setRoadmapValue] = useState(
    roadmapValues && roadmapValues[0]
  );

  const indicatorsQuery = useGraphqlQuery({
    queryOptions: indicatorQueryOptions,
    variables:
      region && roadmapValue
        ? {
            filter: {
              region_id: region?.id,
              roadmap_id: roadmapValue?.value,
              year: year,
              month: month,
              status: 'CONFIRMED'
            }
          }
        : null
  });

  return (
    <Indicators
      indicatorsQuery={indicatorsQuery}
      selectProps={{
        value: roadmapValue,
        values: roadmapValues,
        onChange: setRoadmapValue
      }}
    />
  );
}

Indicators.propTypes = {};
