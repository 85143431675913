import React from 'react';

import { max as d3max } from 'd3';

import {
  getLegendDecimals,
  getRoundLegendSteps,
  roundToRound
} from '../../../utils/math';
import LineChart from '../../../common/charts/LineChart/LineChart';
import { Indicator } from '../../../data/indicator';

import { ChartWithLegend } from '../../../common/charts/ChartWithLegend';
import { ResizeContainer } from '../../../common/ResizeContainer';
import {
  createQuarterValues,
  fillQuarterValues,
  mapQuarterValues
} from '../../../utils/fillQuarterValues';

import { getRowLabelShort } from '../../../common/charts/ChartLegend';

import s from './Goals.module.scss';
import colors from '../../../colors.scss';

function processData(indicatorData, activeYear) {
  if (!indicatorData) return null;

  const filteredValues = indicatorData.values
    ? indicatorData.values.filter((v) => v.period)
    : [];

  const valuesCount = indicatorData?.values?.length;

  const filteredFactValues = indicatorData.fact_values
    ? indicatorData.fact_values.filter((f) => f.period)
    : [];

  const factValuesCount = indicatorData?.fact_values?.length;

  const quarterValues = createQuarterValues();
  fillQuarterValues(quarterValues, filteredValues, (plan) => plan);
  fillQuarterValues(quarterValues, filteredFactValues, (fact) => ({
    value_fact: fact.value_number
  }));

  // Пройдемся по полученному массиву для определения последнего
  // доступного фактического значения.
  let lastActiveIndex = 0;
  quarterValues.forEach((item, index) => {
    if (item.value_fact !== null) {
      lastActiveIndex = index;
    }
  });

  // А затем заполним пустые данные 0 до последнего доступного фактического значения.
  for (let i = 0; i < lastActiveIndex; i++) {
    if (quarterValues[i].value_fact === null) {
      quarterValues[i].value_fact = 0;
    }
  }

  return {
    name: indicatorData.name_full,
    data: mapQuarterValues(quarterValues, activeYear),
    indices: [
      {
        id: 1,
        name: 'План',
        unit: Indicator.getUnit(indicatorData),
        color: colors.grayDark,
        showCircles: valuesCount <= 1
      },
      {
        id: 2,
        name: 'Факт',
        unit: Indicator.getUnit(indicatorData),
        color: colors.chartPurple,
        showCircles: factValuesCount <= 1
      }
    ]
  };
}

export function IndicatorGoalChart({ indicator, activeYear, actualityDate }) {
  const chart = processData(indicator, activeYear);
  if (!chart) return null;
  const max = roundToRound(d3max(chart.data, (d) => d3max(d.values)));
  const legendSteps = getRoundLegendSteps(max);
  const decimals = getLegendDecimals(0, max, legendSteps);

  return (
    <div className={s.chart}>
      <div className={s.chart__title}>
        <div className={s.chart__name}>{chart.name}</div>
        {indicator.unit && (
          <div className={s.chart__unit}>{indicator.unit?.name}</div>
        )}
      </div>
      <div className={s.chart__wrapper}>
        <ResizeContainer>
          {({ width, height }) => (
            <ChartWithLegend
              width={width}
              height={height}
              min={0}
              max={max}
              decimals={decimals}
              legendWidth={40}
              legendPadding={16}
              steps={legendSteps}
              columnData={chart.data.map((item) => item.name)}
              getRowLabel={getRowLabelShort}
            >
              {({ width, height, ...props }) => (
                <LineChart
                  data={chart.data}
                  indices={chart.indices}
                  sizeWidth={width}
                  sizeHeight={height}
                  lineStrokeWidth={2}
                  circleStrokeWidth={2}
                  circleRadius={4}
                  total={max}
                  actualityDate={actualityDate}
                  {...props}
                />
              )}
            </ChartWithLegend>
          )}
        </ResizeContainer>
      </div>
    </div>
  );
}

export function IndicatorCharts({ indicators, activeYear, actualityDate }) {
  return (
    <div className={s.Goals__charts}>
      {indicators &&
        indicators.map((item) => (
          <IndicatorGoalChart
            key={item.id}
            indicator={item}
            activeYear={activeYear}
            actualityDate={actualityDate}
          />
        ))}
    </div>
  );
}

export function IndicatorGoal({ goal, activeYear, actualityDate }) {
  const { indicators } = goal;
  return (
    <>
      <div className={s.Goals__name}>{goal.name_full}</div>
      <IndicatorCharts
        indicators={indicators}
        activeYear={activeYear}
        actualityDate={actualityDate}
      />
    </>
  );
}

export function IndicatorGoals({ goals, activeYear, actualityDate }) {
  return (
    goals &&
    goals.map((goal) => {
      return (
        <IndicatorGoal
          key={goal.id}
          goal={goal}
          activeYear={activeYear}
          actualityDate={actualityDate}
        />
      );
    })
  );
}
