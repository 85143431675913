import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TextInput } from '../TextInput/TextInput';
import s from './TextInputQuestions.module.scss';

export function TextInputQuestions({
  placeholder,
  onChange,
  children,
  ...props
}) {
  const [hasValue, setHasValue] = useState(false);

  useEffect(() => {
    setHasValue(!!(props.value || props.defaultValue));
  }, [props.value, props.defaultValue]);

  const onChangeEvent = useCallback(
    (event) => {
      const newHasValue = !!event.currentTarget.value;
      if (newHasValue !== hasValue) {
        setHasValue(newHasValue);
      }
      if (typeof onChange === 'function') {
        onChange(event);
      }
    },
    [onChange, hasValue]
  );

  return (
    <TextInput
      className={s.TextInputQuestions__input}
      {...props}
      onChange={onChangeEvent}
      placeholder={''}
    >
      <div
        className={classNames(s.TextInputQuestions__question, {
          [s._hasValue]: hasValue
        })}
      >
        {placeholder}
      </div>
    </TextInput>
  );
}

TextInputQuestions.ERROR = 'error';
TextInputQuestions.SUCCESS = 'success';

TextInputQuestions.propTypes = {
  wrapperClassName: PropTypes.string,
  status: PropTypes.oneOf([
    TextInputQuestions.ERROR,
    TextInputQuestions.SUCCESS
  ]),
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape(),
  form: PropTypes.shape()
};
