import { BehaviorStore } from '@proscom/prostore';
import { rnMessenger } from '../rnMessenger';
import { blueDark2 } from '../colors.scss';

const initialState = {
  isMobileApp: rnMessenger.isActive,
  statusBarHeight: 0,
  bgColor: null
};

export class MobileAppStore extends BehaviorStore {
  constructor() {
    super(initialState);

    if (rnMessenger.isActive) {
      rnMessenger.ready
        .then((statusBarHeight) => this.handleNativeReady(statusBarHeight))
        .catch((e) => console.log(e));
    }
  }

  setStatusBarHeight(statusBarHeight) {
    this.setState({ statusBarHeight: Math.floor(+statusBarHeight) });
  }

  setBgColor(bgColor) {
    this.setState({ bgColor });

    if (rnMessenger.isActive) {
      rnMessenger
        .call('setBarStyle', {
          barStyle: bgColor === blueDark2 ? 'light' : 'dark'
        })
        .then()
        .catch((e) => {
          console.error(e);
        });
    }
  }

  handleNativeReady(statusBarHeight) {
    this.setStatusBarHeight(+statusBarHeight || 0);
  }
}
