import { BehaviorStore } from '@proscom/prostore';
import { MUTATION_RESET_CACHE } from '../graphql/mutations/cache';

export class CacheStore extends BehaviorStore {
  client;

  constructor({ client }) {
    super({});

    this.client = client;
  }

  async resetCache() {
    try {
      return await this.client.mutate({
        mutation: MUTATION_RESET_CACHE
      });
    } catch (error) {
      return { error };
    }
  }
}
