import React from 'react';
import { useQueryPeriod } from '../../utils/useQueryPeriod';
import { GrbsFilter } from '../../common/Filters/GrbsFilter';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { useStateTabs } from '../../common/ui/Tab/TabRadio';
import { useUrlQueryParams } from '../../utils/useUrlQuery';
import { URL_QUERY_GRBS } from '../../store/stores';
import { ChartViewChart } from '../../common/ChartView/ChartView';
import { useBudgetChartRp } from './useBudgetChartRp';
import { ChartView } from './ChartView/ChartView';

const tabs = [
  {
    value: 'types',
    label: `Региональный бюджет в разрезе видов расходов`
  },
  {
    value: 'source',
    label: 'Региональный бюджет по источникам финансирования'
  }
];

const queryParams = [URL_QUERY_GRBS];

export const BudgetChartRp = React.memo(function BudgetChartRp({
  filter = false,
  className,
  wrapperClassName
}) {
  const [query] = useUrlQueryParams(queryParams);
  const { month, yearMonth, changeMonth } = useQueryPeriod();
  const [tab, tabProps] = useStateTabs(tabs, (x) => x.value);

  const grbs = query.grbs || 'all';
  const { typesCharts, sourcesCharts, budgetData, pof } = useBudgetChartRp({
    yearMonth,
    filter: {
      ...filter,
      grbsCodes: grbs && grbs !== 'all' ? [grbs] : undefined
    }
  });

  return (
    <>
      <ChartView
        tabs={tabs}
        tabProps={tabProps}
        className={className}
        wrapperClassName={wrapperClassName}
        controls={<GrbsFilter />}
        renderChart={({ width }) => {
          const data =
            tab === 'types'
              ? typesCharts
              : tab === 'source'
              ? sourcesCharts
              : null;
          return data ? (
            <ChartViewChart
              data={data}
              legend
              width={width}
              height={192}
              minValueOptions={grbs === '073' && { name: 'ПОФ', value: pof }}
            />
          ) : (
            <CircleProgressIndicator />
          );
        }}
        month={month}
        onMonthChange={(q) => changeMonth(q.value)}
      />
    </>
  );
});
