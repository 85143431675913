import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { format } from 'date-fns';
import React from 'react';
import { skipIfNull } from '@proscom/prostore';
import { useCurrentDate } from '../../utils/useCurrentDate';
import { getFileLinksFromQuery } from '../../utils/data';
import { queryLoader } from '../../common/QueryLoader';
import { DATE_FORMAT_RUSSIAN } from '../../utils/date';
import { QUERY_GET_OPERATIVE_REFERENCE } from '../../graphql/queries/reports';
import { HorizontalScroll } from '../../common/HorizontalScroll/HorizontalScroll';
import { reportScopeType } from '../../store/DocumentTokenStore';
import { apiUrl } from '../../config';
import { DownloadReportButton } from '../../common/ui/DownloadButton/DownloadReportButton';
import { CardReportStaticDownload } from './common/CardReportDownload';
import ReportViewLayout from './common/ReportViewLayout';
import ReportTitle from './common/ReportTitle';
import ReportTextContent from './common/ReportTextContent';
import s from './ReportsPage.module.scss';

const operativeReferenceOptions = {
  query: QUERY_GET_OPERATIVE_REFERENCE,
  mapData: (data) => data.operativeReference,
  skipQuery: skipIfNull(null)
};

const cards = [
  {
    link: '/reference/financial?type=docx',
    text: 'Типовая справка по финансам ФБ',
    cookieLoader: true,
    type: reportScopeType.REFERENCE_DOCUMENT_FINANCIAL
  },
  {
    link: '/reference/results?type=docx',
    text: 'Типовая справка по результатам',
    cookieLoader: true,
    type: reportScopeType.REFERENCE_DOCUMENT_RESULTS
  },
  {
    link: '/reference/subjects?type=docx',
    text: 'Типовая справка по субъектам',
    cookieLoader: true,
    type: reportScopeType.REFERENCE_DOCUMENT_SUBJECTS
  },
  {
    link: '/reference/internet?type=docx',
    text: 'Типовая справка по  доведению интернета в школы',
    cookieLoader: true,
    type: reportScopeType.REFERENCE_DOCUMENT_INTERNET
  }
];

const ReportCards = ({ isMobile }) => {
  const urlBase = apiUrl;
  return (
    <div className={s.Report__Cards}>
      <HorizontalScroll
        buttonPosition={HorizontalScroll.INSIDE}
        disableArrow={isMobile}
        scrollItems={isMobile ? 1 : 3}
      >
        {cards.map((card, i) => (
          <CardReportStaticDownload
            key={i}
            href={urlBase + card.link}
            article={card.text}
            cookieLoader={card.cookieLoader}
            type={card.type}
          />
        ))}
      </HorizontalScroll>
    </div>
  );
};

export const ReportView = ({ onGoBack, isMobile }) => {
  const currentDate = useCurrentDate();
  const operativeReferenceQuery = useGraphqlQuery({
    queryOptions: operativeReferenceOptions,
    variables: {
      date: currentDate
    }
  });

  const operativeReference = operativeReferenceQuery.state.data;

  const downloadOptions = getFileLinksFromQuery(operativeReferenceQuery);

  return (
    queryLoader(operativeReferenceQuery) || (
      <ReportViewLayout>
        <ReportTitle
          title={`Оперативная справка на ${format(
            currentDate,
            DATE_FORMAT_RUSSIAN
          )}`}
          type={reportScopeType.REFERENCE_DOCUMENT_OPERATIONAL}
          files={downloadOptions}
          onClick={onGoBack}
        />
        <ReportTextContent html={operativeReference?.html} />
        <ReportCards isMobile={isMobile} />
      </ReportViewLayout>
    )
  );
};
