import React from 'react';
import PropTypes from 'prop-types';
import { ChipRadio } from '../../../common/ui/Chip/ChipRadio';
import s from '../ReportsPage.module.scss';

const ReportRadioGroup = ({ onChange, selected, options }) => {
  return (
    <div className={s.ReportRadioGroup}>
      {options.map(({ title, value }, index) => (
        <ChipRadio
          key={index}
          className={s.ReportRadioGroup__item}
          title={title}
          value={value}
          selected={selected}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

ReportRadioGroup.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.string
};

export default ReportRadioGroup;
