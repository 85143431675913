import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'react-resize-observer';
import { isNumber } from 'lodash-es';
import { formatNumber } from '../../../utils/formatNumber';
import HistogramPieChart from '../../../common/charts/HistogramPieChart/HistogramPieChart';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { useWindowSize } from '../../../store/useWindowSize';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { DonutsSubject } from './DonutsSubject';
import s from './ProjectsChartView.module.scss';

export function SubjectChartView({
  activeId,
  className,
  donutsClassName,
  titleClassName,
  selectedChartView,
  newData,
  activeDistrict,
  onMainChartClick,
  onSegmentClick,
  isChartViewOpen,
  selectedIndex,
  selectedSubIndex,
  onChangeIndex,
  onBarClick,
  isLoading,
  ...props
}) {
  const [size, setSize] = useState(null);
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;
  const central = newData && newData.central;
  const districts = newData && newData.districts;
  const baseWidth = 630;

  const [query, changeQuery] = useUrlQuery();

  const totalSize = size
    ? Math.min(size.width, size.height, baseWidth)
    : baseWidth;
  const adaptiveRatio = totalSize / baseWidth;
  //todo пока выводим данные, которые есть
  const centralTotal =
    (query.region ? central?.mbt_total_budget : central?.total_budget) ||
    central?.pies[0].total / 1e9;

  const total =
    (districts?.[selectedIndex] &&
      isNumber(selectedSubIndex) &&
      districts[selectedIndex].data.total_budget / 1e9) ||
    centralTotal;

  return (
    <div
      className={classNames(s.ChartView, className, {
        [s._loading]: isLoading
      })}
      {...props}
    >
      <ResizeObserver onResize={setSize} />
      {isLoading && <CircleProgressIndicator className={s.ChartView__loader} />}
      {size && central && districts && (
        <div className={s.ChartView__content}>
          <SubjectHistogram
            activeDistrict={activeDistrict}
            adaptiveRatio={adaptiveRatio}
            districts={districts}
            isBlurred={isChartViewOpen}
            onSectionClick={onChangeIndex}
            onBarClick={onBarClick}
            targetSection={selectedIndex}
            selectedSubIndex={selectedSubIndex}
            isMobile={isMobile}
          />
          <DonutsSubject
            activeId={activeId}
            className={donutsClassName}
            selectedChartView={selectedChartView}
            pies={central.pies}
            adaptiveRatio={adaptiveRatio}
            onSegmentClick={onSegmentClick}
            onMainChartClick={onMainChartClick}
            isChartViewOpen={isChartViewOpen}
          />
          <div className={classNames(s.ChartView__title, titleClassName)}>
            <div>
              {formatNumber(total, selectedChartView === 'mbt' ? 0 : 2)}
            </div>
            <div className={s.ChartView__unit}>млрд руб.</div>
          </div>
        </div>
      )}
    </div>
  );
}

const colors = [
  '#47307A',
  '#2997D4',
  '#008E83',
  '#A4036F',
  '#B56A08',
  '#CC406E',
  '#D69834',
  '#1F54B2'
];

function SubjectHistogram({
  adaptiveRatio,
  activeDistrict,
  districts,
  isBlurred,
  onSectionClick,
  onBarClick,
  targetSection,
  selectedSubIndex,
  isMobile
}) {
  const data = useMemo(() => {
    return districts.map((district) => {
      const isSelected =
        !activeDistrict ||
        activeDistrict.data.federal_district.code ===
          district.data.federal_district.code;
      return {
        id: district.id,
        color: isSelected
          ? district.colors.firstColor
          : 'rgba(108,123,153,0.46)',
        isSelected,
        values: district.regions.map(({ data: region }, index) => {
          return {
            id: region.id,
            value: (region.cash_expenses / region.total_budget) * 100,
            isSelected:
              isSelected &&
              (selectedSubIndex === undefined
                ? true
                : selectedSubIndex === index)
          };
        })
      };
    });
  }, [activeDistrict, districts, selectedSubIndex]);

  const total = 100; //d3max(data, (d) => d3max(d.values, (v) => v.value));

  const size = 630 * adaptiveRatio;

  return (
    <HistogramPieChart
      size={size}
      barWidthAngle={1.8}
      innerRadius={(adaptiveRatio * 368) / 2}
      innerBarRadius={(adaptiveRatio * 388) / 2}
      outerBarRadius={(adaptiveRatio * 540) / 2}
      className={classNames(s.SubjectHistogram, {
        _blurred: isBlurred
      })}
      colors={colors}
      data={data}
      total={total}
      style={{
        width: size,
        height: size
      }}
      targetSection={targetSection}
      onSectionClick={onSectionClick}
      onBarClick={onBarClick}
      isMobile={isMobile}
    />
  );
}
