import React from 'react';
import classNames from 'classnames';
import { components } from 'react-select';
import { ReactComponent as Check } from '../../../assets/Icons/Check.svg';
import { ReactComponent as Clear } from '../../../assets/Icons/Close.svg';
import { ReactComponent as DropDown } from '../../../assets/Icons/Arrow.svg';
import s from './Select.module.scss';

const PRIMARY = 'primary';
const SECONDARY = 'secondary';
const MENU = 'menu';
const DARKER = 'darker';

const SMALL = 'small';
const NORMAL = 'normal';

export const constants = { PRIMARY, SECONDARY, DARKER, MENU, SMALL, NORMAL };

export const getAdditionalClassnames = (variant, height) => {
  const variantCN =
    variant === SECONDARY
      ? s._secondary
      : variant === DARKER
      ? s._darker
      : variant === MENU
      ? s._menu
      : s._primary;
  const heightCN = height === SMALL ? s._small : s._normal;
  return [variantCN, heightCN];
};

const DropdownIndicator = (props) => {
  const { clearable, value, onChange } = props.selectProps;
  return (
    <components.DropdownIndicator {...props}>
      {clearable && value ? (
        <Clear onClick={() => onChange(null)} className={s.Select__clearable} />
      ) : null}
      <DropDown className={s.Select__dropDown} />
    </components.DropdownIndicator>
  );
};

const Input = (props) => {
  return <components.Input {...props} className={s.Select__input} />;
};

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={s.Select__content}>{props.label}</div>
      {props.isSelected ? <Check className={s.Select__check} /> : null}
    </components.Option>
  );
};

const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};

const ValueContainer = ({ children, ...props }) => {
  // компонент для мультиселекта
  const [child, ...rest] = children; // первым параметром всегда идут значения, которые нужно заменить в случае массива
  const selectedValue = Array.isArray(child) ? (
    <p className={s.Select__multiCount}>
      {child.length > 1 ? `Выбрано: ${child.length}` : child[0].props.children}
    </p>
  ) : (
    child
  );
  return (
    <components.ValueContainer
      {...props}
      className={classNames({
        'has-placeholder': props.selectProps.showPlaceholder,
        'has-input-value': props.selectProps.inputValue.length > 0
      })}
    >
      {props.selectProps.showPlaceholder && (
        <components.Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </components.Placeholder>
      )}
      {selectedValue}
      {rest}
    </components.ValueContainer>
  );
};

export const customComponents = {
  DropdownIndicator,
  Input,
  Option,
  ValueContainer,
  Placeholder
};
