import React from 'react';
import classNames from 'classnames';
import { ImageSlider } from '../../../common/ImageSlider/ImageSlider';
import objectPhoto1 from '../../../assets/img/content/object/photo-1.png';
import { ChipRadio } from '../../../common/ui/Chip/ChipRadio';
import { useStateTabs } from '../../../common/ui/Tab/TabRadio';
import { Select } from '../../../common/ui/Select/Select';
import { HorizontalBarChart } from '../../../common/charts/HorizontalBarChart';
import StackedBarChart from '../../../common/charts/StackedBarChart/StackedBarChart';
import { AboutContainer } from '../../../common/RegionPages/AboutContainer/AboutContainer';
import { ResizeContainer } from '../../../common/ResizeContainer';
import { SubsidiaryIndicators } from '../../SubjectPage/Indicators/Indicators';
import { MainChart } from '../../../common/RegionalBudgetCharts/RegionalBudgetCharts';
import { SubsidiaryEntityAbout } from '../SubjectAbout/SubsidiaryEntityAbout';
import colors from '../../../colors.scss';
import s from './SubsidiaryView.module.scss';

const objectPhotos = [
  {
    img: objectPhoto1
  },
  {
    img: objectPhoto1
  }
];

// Это константы
const BEGIN = 'begin';
const CREATE = 'create';
const RESULTS = 'results';

const chipRadioData = [
  {
    title: 'Начало',
    value: BEGIN
  },
  {
    title: 'Создание',
    value: CREATE
  },
  {
    title: 'Результат',
    value: RESULTS
  }
];

const externalBtn = [
  {
    label: 'Реестр закупок и заказов',
    href: ''
  },
  {
    label: 'План фин-хоз деятельности',
    href: ''
  },
  {
    label: 'Государственное задание',
    href: ''
  }
];

function ChartData({ data, title }) {
  return (
    <div className={s.ChartData}>
      <div className={s.Chart__title}>{title}</div>
      <Select
        className={s.ChartData__select}
        variant={Select.SECONDARY}
        value={{ label: 'II квартал', value: 'II квартал' }}
        values={[{ label: 'II квартал', value: 'II квартал' }]}
      />
      <div className={s.ChartData__charts}>
        {data.map((item, iItem) => (
          <div key={iItem} className={s.ChartData__chartItem}>
            <StackedBarChart
              wrapperClassName={s.ChartData__chart}
              sizeWidth={130}
              size={158}
              barWidth={36}
              barGap={10}
              data={item.chart}
              color={colors.white}
              textColor={colors.white}
            />
            <div className={s.ChartData__chartTooltip}>{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

const legendsData = [
  {
    title: 'Факт',
    color: 'rgba(136,108,255,1)'
  },
  {
    title: 'План',
    color: 'rgba(190,176,251,1)'
  }
];

function TotalData({ title, horizontalCharts }) {
  return (
    <div className={s.TotalData}>
      <div className={s.TotalData__title}>{title}</div>
      <div className={s.TotalData__legends}>
        {legendsData.map((item, iItem) => (
          <div className={s.TotalData__legends__item} key={iItem}>
            <div
              className={s.TotalData__legends__dots}
              style={{ background: item.color }}
            />
            <div className={s.TotalData__legends__title}>{item.title}</div>
          </div>
        ))}
      </div>
      <div className={s.TotalData__charts}>
        <ResizeContainer>
          {({ width }) =>
            horizontalCharts.map((item, iItem) => (
              <div className={s.TotalData__chart} key={iItem}>
                <div className={s.TotalData__chartTitle}>{item.title}</div>
                <HorizontalBarChart
                  className={s.TotalData__horizontalChart}
                  data={item.chartData}
                  total={item.total}
                  decimals={1}
                  color={s.purpleL}
                  sizeHeight={16 + 16 + 16}
                  sizeWidth={width}
                />
              </div>
            ))
          }
        </ResizeContainer>
      </div>
    </div>
  );
}

function SubsidiaryView({
  className,
  actualityDate,
  subsidiaryEntity,
  budgetCharts
}) {
  const [status, statusProps] = useStateTabs(chipRadioData, (x, i) => x.value);

  return (
    <div className={classNames(s.SubsidiaryView, className)}>
      <ImageSlider
        className={s.SubsidiaryView__photosSlider}
        slides={objectPhotos}
        actualityDate={actualityDate}
        sliderProps={{
          showIndicators: false,
          infiniteLoop: true,
          showThumbs: false
        }}
      >
        <div className={s.SubsidiaryView__filtersWrapper}>
          <div className={s.SubsidiaryView__filters}>
            {chipRadioData.map((item) => (
              <ChipRadio
                key={`chipRadio__${item.value}`}
                className={s.SubsidiaryView__filterItem}
                title={item.title}
                color={item.color}
                {...statusProps(item.value)}
              />
            ))}
          </div>
        </div>
      </ImageSlider>
      <AboutContainer>
        <SubsidiaryEntityAbout
          subsidiaryEntity={subsidiaryEntity}
          actualityDate={actualityDate}
        />
      </AboutContainer>
      <MainChart {...budgetCharts} />
      <SubsidiaryIndicators
        className={s.TotalData}
        subsidiaryId={subsidiaryEntity.id}
      />
    </div>
  );
}

export default SubsidiaryView;
