function env(variable, defaultValue) {
  if (typeof variable === 'undefined') {
    return defaultValue;
  }
  return variable;
}

function requireEnv(variable, name) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`);
  }
  return variable;
}

function booleanEnv(variable) {
  return env(variable, false) === 'true';
}

export const apiUrl = requireEnv(
  process.env.REACT_APP_API_URL,
  'REACT_APP_API_URL'
);
export const apiGraphqlUrl = apiUrl + '/graphql';

export const filesUrl = requireEnv(
  process.env.REACT_APP_FILES_URL,
  'REACT_APP_FILES_URL'
);

export const hideResultAch = booleanEnv(process.env.REACT_APP_HIDE_RESULT_ACH);

export const hideTargetAch = booleanEnv(process.env.REACT_APP_HIDE_TARGET_ACH);

export const isWatermarkActive = booleanEnv(process.env.REACT_APP_WATERMARK);

export const isBOCardsActive = booleanEnv(process.env.REACT_APP_HIDE_BO_CARDS);

export const hideOutBudgetSources = booleanEnv(
  process.env.REACT_APP_HIDE_OUTBUDGET_SOURCES
);

export const hideFinancialData = booleanEnv(
  process.env.REACT_APP_HIDE_FINANCIAL_DATA
);

export const hideAcceptBOInRatingPage = booleanEnv(
  process.env.REACT_APP_HIDE_BO_RATING_PAGE
);

export const hideSubjectsOnIndexPage = booleanEnv(
  process.env.REACT_APP_HIDE_SUBJECTS_ON_INDEX_PAGE
);

export const yandexMetrikaId = env(process.env.REACT_APP_METRIKA_ID, '');
