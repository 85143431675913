import Popper from 'popper.js';

export class PopperManager {
  popper = null;
  targetNode = null;
  popperNode = null;
  arrowNode = null;

  update(params) {
    const { targetNode, popperNode, arrowNode, placement, offset } = params;
    if (
      targetNode !== this.targetNode ||
      popperNode !== this.popperNode ||
      arrowNode !== this.arrowNode ||
      placement !== this.placement ||
      offset !== this.offset
    ) {
      this.recreate(params);
    } else if (this.popper) {
      this.popper.scheduleUpdate();
    }
  }

  destroy() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
      this.unregisterBodyListener();
    }
  }

  scheduleUpdate() {
    if (this.popper) {
      this.popper.scheduleUpdate();
    }
  }

  registerBodyListener() {
    document.body.addEventListener('scroll', this.handleBodyScroll, {
      passive: true
    });
  }

  unregisterBodyListener() {
    document.body.removeEventListener('scroll', this.handleBodyScroll);
  }

  handleBodyScroll = () => {
    this.scheduleUpdate();
  };

  recreate({
    targetNode,
    popperNode,
    arrowNode,
    placement = 'top',
    offset,
    options
  }) {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
      this.unregisterBodyListener();
    }

    if (targetNode && popperNode) {
      this.popper = new Popper(targetNode, popperNode, {
        placement: placement,
        ...options,
        positionFixed: true,
        modifiers: {
          preventOverflow: {
            boundariesElement: 'viewport',
            padding: 16
          },
          ...(offset
            ? {
                offset: {
                  offset
                }
              }
            : undefined),
          ...(arrowNode && { arrow: { element: arrowNode } }),
          ...(options && options.modifiers)
        }
      });
      this.registerBodyListener();
    }

    this.targetNode = targetNode;
    this.popperNode = popperNode;
    this.arrowNode = arrowNode;
  }
}
