import { useEffect } from 'react';

export function usePreventBodyOverScroll() {
  useEffect(() => {
    document.body.classList.add('blockPageScroll');
    return () => {
      document.body.classList.remove('blockPageScroll');
    };
  }, []);
}

export function enableBodyScroll() {
  window.ontouchmove = null;
}
