import { formatNumber } from '../../../utils/formatNumber';

export function getCentralChartDataRp(financesData, districtIndex = 0) {
  if (!financesData) {
    return {};
  }

  const subjectsData = financesData.subjectDistricts
    ? financesData.subjectDistricts[districtIndex]
    : financesData.subjects;

  const {
    total_budget,
    budget_limits,
    cash_expenses,
    obligations
  } = subjectsData;

  const max = Math.max(total_budget, budget_limits, cash_expenses, obligations);

  return {
    chart: {
      total_budget: total_budget / 1e9,
      pies: [
        {
          data: [
            {
              id: 'total_budget',
              value: total_budget
            }
          ],
          total: max
        },
        {
          data: [
            {
              id: 'obligations',
              value: obligations
            }
          ],
          total: max
        },
        {
          data: [
            {
              id: 'cash_expenses',
              value: cash_expenses
            }
          ],
          total: max
        }
      ]
    },
    table: [
      {
        id: 'total_budget',
        label: 'План',
        value: formatNumber(total_budget / 1e9, 2),
        percent: 100.0
      },
      {
        id: 'obligations',
        label: 'Принято Бюджетных Обязательств',
        value: formatNumber(obligations / 1e9, 2),
        percent: formatNumber((obligations / total_budget) * 100, 1)
      },
      {
        id: 'cash_expenses',
        label: 'Кассовое исполнение',
        value: formatNumber(cash_expenses / 1e9, 2),
        percent: formatNumber((cash_expenses / total_budget) * 100, 1)
      }
    ]
  };
}
