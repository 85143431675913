export const NativeMethods = {
  saveAuthData: 'saveAuthData',
  loadAuthData: 'loadAuthData'
};

export class ReactNativeMessenger {
  callTimeout = 1000;
  isActive = !!(
    window.ReactNativeWebView && window.ReactNativeWebView.postMessage
  );
  appVersion = null;
  appPlatform = null;
  statusBarHeight = 0;
  lastCallId = 0;
  calls = {};
  debug = false;
  callbackName = undefined;

  constructor() {
    this.ready = new Promise((resolve, reject) => {
      this.readyResolve = resolve;
      this.readyReject = reject;
    });
    this.ready.catch(() => console.log('Web environment'));
    this.readyRejectTimeout = setTimeout(() => this.readyReject(), 1000);

    if (window.ReactNativeInfo) {
      this.handleReactNativeLoad(window.ReactNativeInfo);
    } else {
      window.ReactNativeCallback = this.handleReactNativeLoad;
    }
  }

  handleReactNativeLoad = ({ appVersion, appPlatform, statusBarHeight }) => {
    if (this.debug) {
      console.log('ReactNativeMessenger.isActive =', this.isActive);
    }
    if (appVersion) {
      this.appVersion = appVersion;
      console.log(`appVersion=${this.appVersion}`);
    }
    if (appPlatform) {
      this.appPlatform = appPlatform;
      console.log(`appPlatform=${this.appPlatform}`);
    }
    if (statusBarHeight) {
      this.statusBarHeight = statusBarHeight;
      console.log(`statusBarHeight=${this.statusBarHeight}`);
    }
    clearTimeout(this.readyRejectTimeout);
    this.readyResolve(statusBarHeight);
  };

  handleMessage = (messageData) => {
    if (!messageData || typeof messageData !== 'object') return;
    if (this.debug) {
      console.log('received message', messageData);
    }
    if (messageData.type === 'methodResult') {
      const { result, error, callId } = messageData;
      const callInfo = this.calls[callId];
      if (!callInfo) return;

      clearTimeout(callInfo.timeoutId);

      if (error) {
        callInfo.reject(error);
      }

      callInfo.resolve(result);
    }
  };

  send(message) {
    if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage) {
      if (this.debug) {
        console.log('sending message', message);
      }
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    } else {
      this.isActive = false;
    }
  }

  call(methodName, ...args) {
    this.lastCallId++;
    const callId = this.lastCallId;

    let resolvePromise = null;
    let rejectPromise = null;
    const resultPromise = new Promise((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
    });

    this.calls[callId] = {
      resolve: resolvePromise,
      reject: rejectPromise,
      timeoutId: setTimeout(() => {
        rejectPromise('timeout');
      }, this.callTimeout)
    };

    this.send({
      type: 'callMethod',
      callId,
      methodName,
      args,
      callback: this.callbackName
    });

    return resultPromise;
  }
}
