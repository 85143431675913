import React, { useImperativeHandle, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { ReactComponent as Pointer } from '../../../assets/Icons/Pointer.svg';

import { useFactoryRef } from '../../../utils/useFactoryRef';
import { PopperManager } from './PopperManager';
import s from './Popover.module.scss';

const popperManagerFactory = () => new PopperManager();

export const Popover = React.forwardRef(function Popover(
  {
    targetNode,
    className,
    children,
    appendTo,
    interactive = true,
    options,
    placement,
    offset,
    ...props
  },
  ref
) {
  const popperNodeRef = useRef(null);
  const arrowRef = useRef(null);
  const popperRef = useFactoryRef(popperManagerFactory);

  useLayoutEffect(() => {
    if (popperRef.current) {
      popperRef.current.update({
        targetNode,
        popperNode: popperNodeRef.current,
        arrowNode: arrowRef.current,
        placement,
        offset,
        options
      });
    }
  }, [targetNode, placement, popperRef, offset, options]);

  useImperativeHandle(
    ref,
    () => ({
      scheduleUpdate: () => {
        popperRef.current.scheduleUpdate();
      },
      update: () => {
        popperRef.current.forceUpdate();
      }
    }),
    [popperRef]
  );

  useLayoutEffect(() => {
    return () => {
      // popperRef.current не меняется
      // eslint-disable-next-line react-hooks/exhaustive-deps
      popperRef.current.destroy();
    };
  }, [popperRef]);

  return ReactDOM.createPortal(
    <div
      ref={popperNodeRef}
      className={classNames(
        s.Popover,
        {
          [s._active]: targetNode,
          [s._interactive]: interactive
        },
        className
      )}
      {...props}
    >
      {children}
      <span ref={arrowRef} className={s.Popover__arrow}>
        <Pointer />
      </span>
    </div>,
    appendTo || document.body
  );
});
