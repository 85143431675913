import React, { useCallback, useMemo } from 'react';
import { useWindowSize } from '../../store/useWindowSize';
import { ChartViewTabsSlider } from '../../common/ChartView/ChartViewTabsSlider';
import { Tab } from '../../common/ui/Tab/Tab';
import { TabRadio } from '../../common/ui/Tab/TabRadio';
import { pageTabs } from './data/constants';
import s from './IndexPage.module.scss';

export function ChartTabs({ pageTabProps, onChangeActiveSegmentId }) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  const onTabChange = useCallback(
    (id, tabProps) => {
      tabProps.onChange(id);
      onChangeActiveSegmentId(null);
    },
    [onChangeActiveSegmentId]
  );

  const activeTabIndex = useMemo(() => {
    return pageTabs.findIndex((tab) => {
      const tabObj = pageTabProps(tab.id);
      return tabObj.value === tabObj.selected;
    });
  }, [pageTabProps]);

  const onSliderIndexChange = useCallback(
    (index) => {
      const activeTab = pageTabs[index];
      onTabChange(activeTab.id, pageTabProps(activeTab.id));
    },
    [onTabChange, pageTabProps]
  );

  return (
    <div className={s.ChartTabs}>
      {isMobile ? (
        <ChartViewTabsSlider
          className={s.ChartViewTabsSlider}
          selectedIndex={activeTabIndex}
          onChangeIndex={onSliderIndexChange}
        >
          {pageTabs.map(({ id, label }, index) => {
            const tabProps = pageTabProps(id);
            return (
              <Tab
                key={`tabs__${index}`}
                className={s.ChartTabs__tab}
                variant={'SECONDARY'}
                active={tabProps.value === tabProps.selected}
              >
                {label}
              </Tab>
            );
          })}
        </ChartViewTabsSlider>
      ) : (
        pageTabs.map(({ id, label }, index) => {
          const tabProps = pageTabProps(id);
          return (
            <TabRadio
              className={s.ChartTabs__tab}
              variant={'SECONDARY'}
              key={`tabs__${index}`}
              {...tabProps}
              onChange={(id) => onTabChange(id, tabProps)}
            >
              {label}
            </TabRadio>
          );
        })
      )}
    </div>
  );
}
