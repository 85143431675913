import React, { useCallback, useMemo, useState } from 'react';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { useSelectedFp } from '../../store/useFederalProjects';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { TopBar } from '../../common/TopBar/TopBar';
import { MapFiltersPopup } from '../../common/Map/MapFiltersPopup/MapFiltersPopup';
import { useFederalDistricts } from '../../store/useFederalDistricts';
import { MapPageLayout } from '../_layouts/MapPageLayout';
import MapView from '../../common/Map/MapView/MapView';
import { useFPRoadmapQuery } from '../../store/useRoadmaps';
import { TopBarAllProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { queryLoader } from '../../common/QueryLoader';
import { EMDASH } from '../../utils/utf';
import { BaseErrorMessage } from '../../common/ErrorMessage';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { ReactHelmet } from '../../common/ReactHelmet';
import { useRegionAchActualityDate } from '../../store/useRegions';
import { DistrictMap } from './DistrictMap';
import s from '../../common/Map/MapView/MapView.module.scss';

function DistrictPage({ history, location, match }) {
  const [isFiltersOpen, setIsFilterOpen] = useState(false);
  const [queryParams, changeQuery] = useUrlQuery();
  const { year, yearMonth } = useQueryPeriodFast(queryParams, changeQuery);
  const selectedFp = useSelectedFp();

  const { indicator_type } = queryParams;
  const { createLink } = usePreserveQueryFast(queryParams, [
    'indicator_type',
    'organization_type',
    'res',
    'roadmap'
  ]);
  const onNavigateToRegion = useCallback(
    (code_nalog) => history.push(createLink(`/regions/${code_nalog}`)),
    [history, createLink]
  );

  const districtsQuery = useFederalDistricts();
  const districtCode = match.params.code;
  const district = useMemo(
    () =>
      districtsQuery.state.data
        ? districtsQuery.state.data.find(({ code }) => code === districtCode)
        : null,
    [districtsQuery.state.data, districtCode]
  );

  const roadmapQuery = useFPRoadmapQuery(
    {
      federal_project_id: selectedFp?.id,
      subsidiary_entity_filter: {
        federal_district_codes: districtCode,
        years: year
      }
    },
    {
      month: yearMonth,
      budget_levels: ['federal']
    }
  );
  const cards = roadmapQuery.state.data;

  const regionAchActualityDate = useRegionAchActualityDate(
    selectedFp ? selectedFp.id : undefined,
    yearMonth
  );

  return (
    <MapPageLayout>
      <ReactHelmet title={district ? district.name : null} />
      <TopBar>
        <TopBarAllProjects />
      </TopBar>
      <MapView
        cards={cards}
        changeQuery={changeQuery}
        history={history}
        onOpenFilter={() => setIsFilterOpen(true)}
        selectedFp={selectedFp}
        queryParams={queryParams}
        titleText={district ? district.name : EMDASH}
        onGoBack={() => history.goBack(createLink('/map'))}
        districtCode={districtCode}
        actualityDate={regionAchActualityDate[indicator_type]}
      >
        {queryLoader(districtsQuery) ||
          (districtsQuery.check.component && !district && (
            <BaseErrorMessage text="404" description="Округ не найден" />
          )) || (
            <DistrictMap
              className={s.MapView__regionMap}
              code={districtCode}
              onNavigate={onNavigateToRegion}
              queryParams={queryParams}
              selectedFp={selectedFp}
              type="district"
              changeQuery={changeQuery}
            />
          )}
      </MapView>
      <MapFiltersPopup
        isVisible={isFiltersOpen}
        onClose={() => setIsFilterOpen(false)}
      />
    </MapPageLayout>
  );
}

export default DistrictPage;
