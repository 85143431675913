import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { isAfter, parseISO } from 'date-fns';

import { RoadmapEventStatus } from '../../data/roadmapEvent';
import { RatingStatus } from '../../utils/constants';
import { useCurrentDate } from '../../utils/useCurrentDate';

import { getStatusCount } from '../../utils/data';

import { EventCard, RegionalEventCard } from '../ui/Card/EventCard';

import { RegionDocumentList } from './RegionDocumentList';

import s from './EventsList.module.scss';

export function RoadmapEvent({ event, totalRegions, onOpenModal }) {
  // полное имя мероприятия хранится в базе как description("Описание"), которое в скором будущем будет заменено на name_full("НаименованиеПолное")
  const name = event.name_full || event.description || event.name;

  const done = getStatusCount(
    event.count_region_status,
    RoadmapEventStatus.COMPLETED
  );

  const planDate =
    event.plan_term || (event.plan_roadmap && event.plan_roadmap.term) || null;
  const factDate = event.fact_term || null;

  const handleOpenModal = useCallback(() => {
    onOpenModal(event);
  }, [onOpenModal, event]);

  return (
    <EventCard
      title={name}
      done={done}
      factDate={factDate}
      event={event}
      onOpenDocuments={handleOpenModal}
      planDate={planDate}
      total={totalRegions}
    />
  );
}

export function RoadmapRegionalEvent({ event, currentRegion }) {
  const currentRegionalEvent = useMemo(() => {
    return (
      event.regional_roadmap_events &&
      event.regional_roadmap_events.find(
        (item) => item.region_id === currentRegion.id
      )
    );
  }, [currentRegion.id, event.regional_roadmap_events]);

  const name = event.name_full || event.description || event.name;

  const done =
    currentRegionalEvent &&
    currentRegionalEvent.status === RoadmapEventStatus.COMPLETED;

  const today = useCurrentDate();
  const planDate =
    event.plan_term || (event.plan_roadmap && event.plan_roadmap.term) || null;
  const factDate =
    (currentRegionalEvent && currentRegionalEvent.fact_term) || null;
  const overdue = planDate && isAfter(today, parseISO(planDate));

  const status = done
    ? RatingStatus.SUCCESS
    : overdue
    ? RatingStatus.ERROR
    : null;

  const canHaveDocuments =
    event.display_document_slots || event.upload_arbitrary_documents;
  const displayDocuments =
    canHaveDocuments ||
    (currentRegionalEvent &&
      currentRegionalEvent.roadmap_event_documents &&
      currentRegionalEvent.roadmap_event_documents.length > 0);

  return (
    <RegionalEventCard
      status={status}
      planDate={planDate}
      factDate={factDate}
      done={done}
      title={name}
      displayDocuments={displayDocuments}
      documentView={
        <RegionDocumentList
          className={s.RoadmapEvent__files}
          region={currentRegion}
          event={event}
          regionalEvent={currentRegionalEvent}
          overdue={overdue}
        />
      }
    />
  );
}

export function RoadmapEventsList({
  events,
  totalRegions,
  className,
  onOpenModal,
  currentRegion,
  onlyRegional
}) {
  if (!events) {
    return null;
  }

  return events.length > 0 ? (
    currentRegion || onlyRegional ? (
      <div className={classNames(s.RoadmapEventsList, className)}>
        {events.map((item, iItem) => (
          <RoadmapRegionalEvent
            key={iItem}
            event={item}
            currentRegion={currentRegion}
          />
        ))}
      </div>
    ) : (
      <div className={classNames(s.RoadmapEventsList, className)}>
        {events.map((item, iItem) => (
          <RoadmapEvent
            key={iItem}
            event={item}
            totalRegions={totalRegions}
            onOpenModal={onOpenModal}
          />
        ))}
      </div>
    )
  ) : (
    <div className={s.RoadmapEventsList__Placeholder}>
      <p>Нет мероприятий, относящихся к выбранному году</p>
    </div>
  );
}
