import React, { useCallback, useMemo, useState } from 'react';
import {
  processRegionsWithFinancialRatings,
  useRegionsWithFinancialRatings
} from '../../store/useRegions';
import { IndicatorPageLayout } from '../../common/GridLayout/IndicatorPageLayout';
import { TopBar } from '../../common/TopBar/TopBar';
import { TopBarAllProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { useQueryPeriod } from '../../utils/useQueryPeriod';
import { mapRequestQuery } from '../../store/mapRequestQuery';
import { queryLoader } from '../../common/QueryLoader';
import { formatDateIso } from '../../utils/date';
import { BudgetChart } from './BudgetChart';
import { fpFilesPath } from './filesPath';
import { GoalsTotal } from './Goals/GoalsTotal';
import { CardsView } from './CardsView/CardsView';
import { IndicatorsView } from './IndicatorsView/IndicatorsView';
import s from './IndicatorsPageCommon.module.scss';

export const IndicatorsPageCommon = React.memo(function IndicatorsPageCommon() {
  const [isGoalsOpen, setIsGoalsOpen] = useState(false);
  const { yearMonth, year } = useQueryPeriod();

  const dcCompletionFilter = {
    year,
    date: formatDateIso(new Date())
  };

  const regionsRatingsQuery = useRegionsWithFinancialRatings(yearMonth, {
    dcCompletionFilter
  });

  const regionsRatingsProcessedQuery = useMemo(
    () =>
      mapRequestQuery(regionsRatingsQuery, (data) => {
        return {
          ...data,
          regions: processRegionsWithFinancialRatings({
            regionsAndRatings: data.regions
          })
        };
      }),
    [regionsRatingsQuery]
  );

  const topBar = useMemo(
    () => (
      <TopBar>
        <TopBarAllProjects />
      </TopBar>
    ),
    []
  );

  const handleGoalsOpen = useCallback(() => setIsGoalsOpen(true), []);
  const handleGoalsClose = useCallback(() => setIsGoalsOpen(false), []);

  return (
    <IndicatorPageLayout
      right={
        queryLoader(regionsRatingsProcessedQuery) || (
          <IndicatorsView
            regionsAndRatings={regionsRatingsProcessedQuery}
            onOpenGoals={handleGoalsOpen}
            fpFilesPath={fpFilesPath}
          />
        )
      }
      topBar={topBar}
      bottom={<CardsView regionsAndRatings={regionsRatingsProcessedQuery} />}
      popup={<GoalsTotal onClose={handleGoalsClose} isActive={isGoalsOpen} />}
    >
      <BudgetChart wrapperClassName={s.BudgetChart__wrapper} />
    </IndicatorPageLayout>
  );
});
