import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import { useQueryPeriod } from '../../utils/useQueryPeriod';
import { getMonthName, months, years } from '../../utils/constants';
import { ReactComponent as IconArrLeft } from '../../assets/Icons/Arrow/Back.svg';
import { ReactComponent as IconDropdown } from '../../assets/Icons/Arrow.svg';
import { Select } from '../ui/Select/Select';
import { useClickOutside } from '../../utils/useClickOutside';
import s from './DatePicker.module.scss';

const KEYS = {
  DOWN: 'ArrowDown',
  UP: 'ArrowUp',
  LEFT: 'ArrowLeft',
  RIGHT: 'ArrowRight',
  ESC: 'Escape'
};

function getKey(key) {
  return {
    isDownKey: key === KEYS.DOWN,
    isUpKey: key === KEYS.UP,
    isLeftKey: key === KEYS.LEFT,
    isRightKey: key === KEYS.RIGHT,
    isEscKey: key === KEYS.ESC,
    isSomeOfKeys: Object.values(KEYS)
      .filter((item) => item !== KEYS.ESC)
      .some((item) => item === key)
  };
}
function getMonthNameShort(month) {
  return getMonthName(month).substr(0, 3);
}

function YearsSheet({ activeYear, isFocused, onClick }) {
  return (
    <table>
      <tbody>
        {[1, 2, 3].map((row, iRow) => (
          <tr key={iRow}>
            {years.slice(3 * iRow, 3 * iRow + 3).map((item) => (
              <td key={item.value}>
                <div
                  className={classNames(s.DatePicker__sheetItem, {
                    [s._active]: item.value === activeYear,
                    [s._focused]: isFocused && item.value === activeYear
                  })}
                  onClick={() => onClick(item)}
                >
                  {item.value}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
function MonthsSheet({ activeMonth, isFocused, onClick }) {
  return (
    <table>
      <tbody>
        {[1, 2, 3, 4].map((row, iRow) => (
          <tr key={iRow}>
            {months.slice(3 * iRow, 3 * iRow + 3).map((item) => (
              <td key={item.value}>
                <div
                  className={classNames(s.DatePicker__sheetItem, {
                    [s._active]: item.value === activeMonth,
                    [s._focused]: isFocused && item.value === activeMonth
                  })}
                  onClick={() => onClick(item)}
                >
                  {getMonthNameShort(item.value)}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function DatePickerComponent({
  className,
  inputClassName,
  variant = Select.PRIMARY,
  month,
  year,
  onChangeHandler
}) {
  const [isOpened, setOpened] = useState(false);
  const [isYearTab, setYearTab] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [cursorPos, setCursorPos] = useState(1);
  const [keyboardControl, setKeyboardContol] = useState(false);
  const dpRef = useRef(null);
  const inpRef = useRef(null);

  const stateRef = useRef({});
  stateRef.current.isOpened = isOpened;
  stateRef.current.isFocused = isFocused;
  stateRef.current.isYearTab = isYearTab;
  stateRef.current.cursorPos = cursorPos;
  stateRef.current.month = month;
  stateRef.current.keyboardControl = keyboardControl;
  stateRef.current.availYears = useMemo(() => {
    const currYear = year;
    const prevYear =
      years.find((item) => item.value === year - 1)?.value || null;
    const nextYear =
      years.find((item) => item.value === year + 1)?.value || null;
    return {
      prevYear,
      currYear,
      nextYear
    };
  }, [year]);
  stateRef.current.monthName = useMemo(() => {
    return getMonthNameShort(month);
  }, [month]);

  const toggleDropdown = useCallback(
    (opened) => {
      if (!opened) {
        setYearTab(false);
        setCursorPos(1);
      } else {
        inpRef.current.focus();
      }
      setOpened(opened);
    },
    [setOpened, setYearTab, setCursorPos, inpRef]
  );

  // ACTIONS
  const onPickerClick = useCallback(() => {
    const isOpened = stateRef.current.isOpened;

    toggleDropdown(!isOpened);
  }, [toggleDropdown, stateRef]);
  const onHeadValueClick = useCallback(() => {
    setYearTab(!isYearTab);
  }, [isYearTab, setYearTab]);

  const setPrevMonth = useCallback(() => {
    const availYears = stateRef.current.availYears;
    const month = stateRef.current.month;

    if (month === months[0].value) {
      if (availYears.prevYear) {
        onChangeHandler({
          year: availYears.prevYear,
          month: months[months.length - 1].value
        });
      }
    } else {
      onChangeHandler({
        month: month - 1
      });
    }
  }, [stateRef, onChangeHandler]);
  const setNextMonth = useCallback(() => {
    const availYears = stateRef.current.availYears;
    const month = stateRef.current.month;

    if (month === months[months.length - 1].value) {
      if (availYears.nextYear) {
        onChangeHandler({
          year: availYears.nextYear,
          month: months[0].value
        });
      }
    } else {
      onChangeHandler({
        month: month + 1
      });
    }
  }, [stateRef, onChangeHandler]);

  const setPrevYear = useCallback(() => {
    const availYears = stateRef.current.availYears;

    if (availYears.prevYear) {
      onChangeHandler({
        year: availYears.prevYear
      });
    }
  }, [stateRef, onChangeHandler]);
  const setNextYear = useCallback(() => {
    const availYears = stateRef.current.availYears;

    if (availYears.nextYear) {
      onChangeHandler({
        year: availYears.nextYear
      });
    }
  }, [stateRef, onChangeHandler]);

  const onPrevClick = useCallback(() => {
    const isYearTab = stateRef.current.isYearTab;

    if (isYearTab) {
      setPrevMonth();
    } else {
      setPrevYear();
    }
  }, [stateRef, setPrevMonth, setPrevYear]);
  const onNextClick = useCallback(() => {
    const isYearTab = stateRef.current.isYearTab;

    if (isYearTab) {
      setNextMonth();
    } else {
      setNextYear();
    }
  }, [stateRef, setNextMonth, setNextYear]);

  const onMonthClick = useCallback(
    (month) => {
      onChangeHandler({
        month: month.value
      });
    },
    [onChangeHandler]
  );
  const onYearClick = useCallback(
    (year) => {
      onChangeHandler({
        year: year.value
      });
    },
    [onChangeHandler]
  );

  // FOCUS
  const onFocus = useCallback(() => {
    setFocused(true);
  }, [setFocused]);
  const onBlur = useCallback(() => {
    setFocused(false);
  }, [setFocused]);

  useClickOutside(() => {
    const isOpened = stateRef.current.isOpened;

    setKeyboardContol(false);

    if (isOpened) {
      toggleDropdown(false);
    }
  }, [dpRef]);

  const onKeyDown = useCallback(
    (e) => {
      const isOpened = stateRef.current.isOpened;
      const isFocused = stateRef.current.isFocused;
      const cursorPos = stateRef.current.cursorPos;
      const isYearTab = stateRef.current.isYearTab;
      const {
        isDownKey,
        isUpKey,
        isLeftKey,
        isRightKey,
        isEscKey,
        isSomeOfKeys
      } = getKey(e.key);

      /**
       * если пикер открыт или ТАБнут
       * и нажата одна из кнопок, кроме ESC,
       * то вкл.подсветку элементов пикера
       */
      if (isSomeOfKeys && (isFocused || isOpened)) {
        setKeyboardContol(true);
        inpRef.current.focus();
      }

      /**
       * открываем\закрываем пикер
       * стрелками вниз\вверх
       */
      if (isDownKey && isFocused && !isOpened) {
        toggleDropdown(true);
      }
      if (isUpKey && isOpened && cursorPos === 1) {
        toggleDropdown(false);
      }
      if (isEscKey && isOpened) {
        toggleDropdown(false);
      }

      if (isOpened) {
        /**
         * меняем управляемый элемент (года\месяцы)
         * стрелками вверх\вниз
         */
        if (isDownKey) {
          setCursorPos(cursorPos === 2 ? cursorPos : cursorPos + 1);
        } else if (isUpKey) {
          setCursorPos(cursorPos === 1 ? cursorPos : cursorPos - 1);
        }

        /**
         * переключаем год\месяц
         * стрелками влево\вправо
         */
        if (cursorPos === 1) {
          if (isLeftKey) {
            onPrevClick();
          } else if (isRightKey) {
            onNextClick();
          }
        } else if (cursorPos === 2) {
          if (isLeftKey) {
            if (isYearTab) {
              setPrevYear();
            } else {
              setPrevMonth();
            }
          } else if (isRightKey) {
            if (isYearTab) {
              setNextYear();
            } else {
              setNextMonth();
            }
          }
        }
      }
    },
    [
      stateRef,
      setCursorPos,
      toggleDropdown,
      setKeyboardContol,
      onPrevClick,
      onNextClick,
      setPrevYear,
      setPrevMonth,
      setNextYear,
      setNextMonth,
      inpRef
    ]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  /**
   * отключаем выделение элементов пикера,
   * когда пикер закрывается
   */
  useEffect(() => {
    if (!isOpened) {
      setKeyboardContol(false);
    }
  }, [isOpened]);

  const availYears = stateRef.current.availYears;
  const monthName = stateRef.current.monthName;

  return (
    <div
      className={classNames(s.DatePicker, className, {
        [s[`_${variant}`]]: !!variant,
        [s._yeartab]: isYearTab,
        [s._focused]: isFocused
      })}
      ref={dpRef}
    >
      <div
        className={classNames(s.DatePicker__input, inputClassName, {
          [s._active]: isOpened
        })}
        onClick={onPickerClick}
      >
        <p>
          {monthName} {year}
        </p>
        <div className={s.DatePicker__arrow}>
          <IconDropdown />
        </div>
        <input
          readOnly={true}
          tabIndex={0}
          className={s.DatePicker__hiddenInput}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={inpRef}
        />
      </div>
      {isOpened && (
        <div className={s.DatePicker__dropdown}>
          <div className={s.DatePicker__picker}>
            <div className={s.DatePicker__head}>
              <div className={s.DatePicker__value} onClick={onHeadValueClick}>
                {isYearTab ? getMonthNameShort(month) : year}
              </div>
              <div
                className={classNames(s.DatePicker__btns, {
                  [s._focused]: keyboardControl && cursorPos === 1
                })}
              >
                <div
                  className={classNames(s.DatePicker__btn, s._prev, {
                    [s._disabled]: isYearTab
                      ? !availYears.prevYear && month === months[0].value
                      : !availYears.prevYear
                  })}
                  onClick={onPrevClick}
                >
                  <IconArrLeft />
                </div>
                <div
                  className={classNames(s.DatePicker__btn, s._next, {
                    [s._disabled]: isYearTab
                      ? !availYears.nextYear &&
                        month === months[months.length - 1].value
                      : !availYears.nextYear
                  })}
                  onClick={onNextClick}
                >
                  <IconArrLeft />
                </div>
              </div>
            </div>
            <div className={s.DatePicker__sheet}>
              {isYearTab ? (
                <YearsSheet
                  activeYear={year}
                  isFocused={keyboardControl && cursorPos === 2}
                  onClick={onYearClick}
                />
              ) : (
                <MonthsSheet
                  activeMonth={month}
                  isFocused={keyboardControl && cursorPos === 2}
                  onClick={onMonthClick}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function DatePicker(props) {
  const {
    month,
    year,
    changeMonth,
    changeYear,
    changeYearMonth
  } = useQueryPeriod();

  const onChange = useCallback(
    (valueObj = {}) => {
      if (valueObj.year && valueObj.month) {
        changeYearMonth(valueObj.year, valueObj.month);
      } else if (valueObj.year) {
        changeYear(valueObj.year);
      } else if (valueObj.month) {
        changeMonth(valueObj.month);
      }
    },
    [changeMonth, changeYear, changeYearMonth]
  );

  return (
    <DatePickerComponent
      month={month}
      year={year}
      onChangeHandler={onChange}
      {...props}
    />
  );
}
