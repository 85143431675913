import React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import { IconButton } from '../ui/IconButton/IconButton';
import { ReactComponent as IconClose } from '../../assets/Icons/Close.svg';
import s from './Popup.module.scss';

export function Popup({
  isOpen,
  onClose,
  children,
  className,
  popupRef,
  ...props
}) {
  // todo адаптивность под телефончик (фулскрин или выезд снизу)

  return (
    <ReactModal
      contentRef={(node) => {
        if (popupRef) {
          popupRef.current = node;
        }
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={s.PopupOverlay}
      className={classNames(className, s.Popup)}
      {...props}
    >
      <IconButton
        className={s.CloseButton}
        icon={IconClose}
        onClick={onClose}
      />
      {children}
    </ReactModal>
  );
}
