import React, { useMemo } from 'react';
import classNames from 'classnames';
import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { Select } from '../ui/Select/Select';
import { QUERY_MAIN_GRBS } from '../../graphql/queries/finance';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import s from './GrbsFilter.module.scss';

const grbsOptions = [
  {
    label: 'Все ГРБС',
    value: 'all'
  }
];

const budgetQueryOptions = {
  query: QUERY_MAIN_GRBS,
  skipQuery: skipIfNull(null)
};

export function GrbsFilter({ className, ...props }) {
  const [query, changeQuery] = useUrlQuery();
  const { yearMonth } = useQueryPeriodFast(query, changeQuery);
  const grbs = query.grbs || 'all';

  const financesQuery = useGraphqlQuery({
    queryOptions: budgetQueryOptions,
    variables: yearMonth ? { yearMonth } : null
  });
  const financesData = financesQuery.state.data;

  const grbsSorted = useMemo(
    () =>
      financesData &&
      financesData.grbs &&
      [...financesData.grbs].sort((a, b) =>
        a.grbs.code < b.grbs.code ? -1 : a.grbs.code > b.grbs.code ? 1 : 0
      ),
    [financesData]
  );

  const options = useMemo(() => {
    const optionsArray = [...grbsOptions];
    if (grbsSorted) {
      for (let item of grbsSorted) {
        optionsArray.push({
          label: item.grbs.name_full,
          value: item.grbs.code
        });
      }
    }
    return optionsArray;
  }, [grbsSorted]);

  const value = useMemo(() => {
    return options.length > 0
      ? options.find((item) => item.value === grbs)
      : options[0];
  }, [grbs, options]);

  return (
    <Select
      height={Select.SMALL}
      className={classNames(s.GrbsFilter, className)}
      value={value}
      values={options}
      onChange={(opt) => changeQuery({ grbs: opt.value }, true)}
      {...props}
    />
  );
}
