import React, { useContext, useMemo } from 'react';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { subDays } from 'date-fns';
import { getMonthQuarter } from '../utils/constants';
import { CircleProgressIndicator } from '../common/ui/ProgressIndicator/CircleProgressIndicator';
import { ErrorMessage } from '../common/ErrorMessage';
import { QUERY_GET_FEDERAL_DATE } from '../graphql/queries/finance';
import { tryParseIso } from '../utils/date';

const federalDateQueryOptions = {
  query: QUERY_GET_FEDERAL_DATE,
  mapData: (data) => data.financesActualityDate,
  skipQuery: skipIfNull(null)
};

const getDateOptions = (date) => {
  if (!date) {
    return {};
  }

  // По финансам первый день относится к предыдущему месяцу
  const shifted = subDays(date, 1);
  const year = shifted.getFullYear();
  const quarter = getMonthQuarter(shifted.getMonth());
  const month = shifted.getMonth() + 1;

  return { year, quarter, month, date: shifted };
};

const actualityDateContext = React.createContext(null);

export const useActualityDateContext = () => {
  return useContext(actualityDateContext);
};

export const ActualityDateProvider = ({ children }) => {
  const dateFilter = {
    budgetLevels: ['federal']
  };

  const actualityDate = useGraphqlQuery({
    queryOptions: federalDateQueryOptions,
    variables: dateFilter
  });

  const contextValue = useMemo(
    () => getDateOptions(tryParseIso(actualityDate.state.data)),
    [actualityDate.state.data]
  );

  if (actualityDate.check.spinner) {
    return <CircleProgressIndicator />;
  }

  if (actualityDate.check.error) {
    return (
      <ErrorMessage
        error={actualityDate.state.error}
        onReload={actualityDate.load}
      />
    );
  }

  return (
    <actualityDateContext.Provider value={contextValue}>
      {children}
    </actualityDateContext.Provider>
  );
};
