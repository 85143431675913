import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { useMemo } from 'react';
import { QUERY_GET_SUBJECT_FINANCE_AND_RATING_BY_FP } from '../graphql/queries/finance';
import { getPlanAndObligationDate, tryParseIso } from '../utils/date';
import {
  INDICATOR_ACCEPT,
  INDICATOR_CASH_COMPLETION,
  INDICATOR_DC_COMPLETION,
  INDICATOR_RESULT_ACH,
  INDICATOR_TARGET_ACH
} from '../utils/indicators';

const getSubjectFinanceQueryOptions = {
  query: QUERY_GET_SUBJECT_FINANCE_AND_RATING_BY_FP
};

const addValueToProjects = (
  projects = [],
  financeData = [],
  getterFinance = (x) => x,
  getterFederalProject = (x) => x.federal_project_id
) => {
  if (!financeData) return [];
  return projects.map((projectItem) => {
    const projectFinance = financeData.find(
      (item) => getterFederalProject(item) === projectItem.federal_project_id
    );

    let value = null;

    if (projectFinance) {
      value = getterFinance(projectFinance);
    }

    return {
      ...projectItem,
      value
    };
  });
};

export function useRegionalFinance(
  projects,
  { regionIds, yearMonth, dcCompletionFilter }
) {
  const budgetQuery = useGraphqlQuery({
    queryOptions: getSubjectFinanceQueryOptions,
    variables: { regionIds, month: yearMonth, dcCompletionFilter }
  });
  const budgetData = budgetQuery.state.data;

  const data = {};
  const actualityDate = {};

  data[INDICATOR_CASH_COMPLETION] = useMemo(
    () =>
      addValueToProjects(
        projects,
        budgetData?.financesGrouped,
        (x) => x.cash_expenses_percent
      ),
    [budgetData, projects]
  );

  data[INDICATOR_ACCEPT] = useMemo(
    () =>
      addValueToProjects(
        projects,
        budgetData?.financesGrouped,
        (x) => x.obligations_percent
      ),
    [budgetData, projects]
  );

  data[INDICATOR_DC_COMPLETION] = useMemo(
    () =>
      addValueToProjects(
        projects,
        budgetData?.dcCompletion,
        (x) => x.dc_completion_percent,
        (x) => x.regional_project?.federal_project_id,
        true
      ),
    [budgetData, projects]
  );

  data[INDICATOR_RESULT_ACH] = useMemo(
    () =>
      addValueToProjects(
        projects,
        budgetData?.resultRating,
        (x) => x.value,
        (x) => x.regional_project?.federal_project_id
      ),
    [budgetData, projects]
  );

  data[INDICATOR_TARGET_ACH] = useMemo(
    () =>
      addValueToProjects(
        projects,
        budgetData?.indicatorRating,
        (x) => x.value,
        (x) => x.regional_project?.federal_project_id
      ),
    [budgetData, projects]
  );

  const [planDate, obligationDate] = getPlanAndObligationDate(
    budgetData?.financesGrouped
  );
  const [resultRatingDate, indicatorRatingDate] = [
    tryParseIso(budgetData?.indicatorRatingActualityDate),
    tryParseIso(budgetData?.resultRatingActualityDate)
  ];

  actualityDate[INDICATOR_ACCEPT] = obligationDate;
  actualityDate[INDICATOR_CASH_COMPLETION] = planDate;
  actualityDate[INDICATOR_DC_COMPLETION] = new Date();
  actualityDate[INDICATOR_RESULT_ACH] = resultRatingDate;
  actualityDate[INDICATOR_TARGET_ACH] = indicatorRatingDate;

  return [data, actualityDate];
}
