import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as Circle } from '../../../assets/Icons/CircleEmpty.svg';
import BaseButton from '../Button/BaseButton';
import s from './Project.module.scss';

export function ProjectSelector({
  number,
  title,
  className,
  active,
  ...props
}) {
  return (
    <BaseButton
      type="button"
      className={classNames(
        s.ProjectSelector,
        {
          [s._active]: !!active
        },
        className
      )}
      {...props}
    >
      {number ? (
        <div className={s.ProjectSelector__number}>{number}</div>
      ) : (
        <div className={s.ProjectSelector__number}>
          <Circle className={s.ProjectSelector__circle} />
        </div>
      )}
      <div className={s.ProjectSelector__title}>{title}</div>
    </BaseButton>
  );
}

ProjectSelector.propTypes = {
  number: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
  type: PropTypes.string
};
