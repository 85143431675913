import React from 'react';
import PropTypes from 'prop-types';
import { PageTitle } from '../../../common/PageTitle/PageTitle';
import { DownloadButton } from '../../../common/ui/DownloadButton/DownloadButton';
import { DownloadReportButton } from '../../../common/ui/DownloadButton/DownloadReportButton';
import s from '../ReportsPage.module.scss';

const ReportTitle = ({ files, title, type, ...props }) => (
  <div className={s.Report__Title}>
    <PageTitle className={s.Report__PageTitle} text={title} {...props} />
    <DownloadButton
      data={files}
      popupPosition={'left'}
      size={DownloadButton.SMALL}
      customDownloadButton={(props) => (
        <DownloadReportButton {...props} type={type} />
      )}
    />
  </div>
);

ReportTitle.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({ fileName: PropTypes.string, url: PropTypes.string })
  ),
  title: PropTypes.string.isRequired
};

ReportTitle.defaultProps = {
  files: [],
  title: 'Оперативная справка'
};

export default ReportTitle;
