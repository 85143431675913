import React from 'react';
import classNames from 'classnames';
import { formatNumber } from '../../utils/formatNumber';
import s from './common.module.scss';

export const Indicator = ({ title, className, children, minimum }) => {
  return (
    <div className={classNames(s.Indicator, className)}>
      <div className={s.Indicator__title}>
        <p>{title}</p>
      </div>
      <div className={s.Indicator__chartView}>{children}</div>
      {typeof minimum !== 'undefined' && (
        <div className={s.Indicator__minumum}>
          Минимальное значение: {formatNumber(minimum, 0)}
        </div>
      )}
    </div>
  );
};
