import React from 'react';
import classNames from 'classnames';
import { ButtonComponent } from '../../../common/ui/Button/Button';
import { ReactComponent as Pointer } from '../../../assets/Icons/Pointer.svg';
import s from './Goals.module.scss';

export function IndicatorGroupTitle({
  title,
  opened,
  setOpened,
  className,
  iconClassName
}) {
  return (
    <ButtonComponent
      className={classNames(s.TopMenu, className)}
      onClick={() => setOpened(!opened)}
    >
      <div>
        <Pointer
          className={classNames(s.TopMenu__icon, iconClassName, {
            [s._active]: opened
          })}
        />
      </div>
      <div>{title}</div>
    </ButtonComponent>
  );
}
