import React, { useMemo } from 'react';

import ReactModal from 'react-modal';

import { useGraphqlQuery } from '@proscom/prostore-apollo-react';

import { isAfter, parseISO } from 'date-fns';

import { Heading2 } from '../PageTitle/Heading';
import { SearchInput } from '../ui/SearchInput/SearchInput';
import { useStateSearch } from '../../utils/useSearch';
import { Popup } from '../Popup/Popup';
import { filterSearch } from '../../utils/filterSearch';
import { QUERY_GET_ROADMAP_EVENT_DOCUMENTS } from '../../graphql/queries/roadmapEvents';

import { ErrorMessage } from '../ErrorMessage';
import Dropdown from '../ui/Dropdown/Dropdown';

import { useCurrentDate } from '../../utils/useCurrentDate';

import { useWindowSize } from '../../store/useWindowSize';

import { SwipeBox } from '../SwipeBox/SwipeBox';

import { queryLoader } from '../QueryLoader';

import { RegionDropdownTitle } from './RegionDropdownTitle';
import { RegionDocumentList } from './RegionDocumentList';

import s from './RoadmapEventPopup.module.scss';

const eventQueryOptions = {
  query: QUERY_GET_ROADMAP_EVENT_DOCUMENTS,
  mapData: (result) => result.roadmapEvent
};

export function RoadmapEventPopupContent({ event, regions }) {
  const eventQuery = useGraphqlQuery({
    queryOptions: eventQueryOptions,
    variables: {
      id: event.id
    }
  });

  const search = useStateSearch(true);

  const filteredRegions = useMemo(() => {
    return filterSearch(search.search, regions, (r) => r.name);
  }, [search.search, regions]);

  const eventData = eventQuery.state.data;

  const today = useCurrentDate();
  const planDate =
    event.plan_term || (event.plan_roadmap && event.plan_roadmap.term) || null;
  const overdue = planDate && isAfter(today, parseISO(planDate));

  const documentTotalCount = useMemo(() => {
    if (!eventData || !eventData.regional_roadmap_events) {
      return null;
    }
    let count = 0;

    eventData.regional_roadmap_events.forEach((item) => {
      count += item.roadmap_event_documents
        ? item.roadmap_event_documents.length
        : 0;
    });

    return count;
  }, [eventData]);

  const resultName = useMemo(() => {
    const result = eventData && eventData.roadmap && eventData.roadmap.result;
    if (!result) {
      return null;
    }

    return result.name_full || result.name;
  }, [eventData]);

  return (
    <div className={s.RoadmapEventPopupContent}>
      <Heading2 className={s.title}>
        Документы{' '}
        {documentTotalCount ? (
          <span className={s.title__gray}>{documentTotalCount}</span>
        ) : null}
      </Heading2>
      <p className={s.description}>{event.name_full || event.name}</p>
      {event.event_result || event.plan_roadmap?.event_result ? (
        <p className={s.description}>
          Наименование результата мероприятия:{' '}
          {event.event_result || event.plan_roadmap.event_result}
        </p>
      ) : null}

      <form className={s.searchForm} onSubmit={search.handleSearch}>
        <SearchInput
          className={s.searchInput}
          onCloseBtnClick={search.clearInput}
          defaultValue={search.search}
          onChange={search.handleChange}
        />
      </form>
      <div className={s.itemsContainer}>
        {queryLoader(eventQuery) ||
          (filteredRegions ? (
            filteredRegions.length > 0 ? (
              <div className={s.regionsList}>
                {filteredRegions.map((region, iRegion) => {
                  const regionalEvent = eventData.regional_roadmap_events.find(
                    (r) => r.region_id === region.id
                  );

                  return (
                    <div key={iRegion} className={s.region}>
                      <Dropdown
                        top={(props) => (
                          <RegionDropdownTitle
                            title={region.name}
                            regionalEvent={regionalEvent}
                            overdue={overdue}
                            {...props}
                          />
                        )}
                      >
                        {({ opened }) => {
                          if (!opened) return null;
                          return (
                            <RegionDocumentList
                              region={region}
                              event={eventData}
                              regionalEvent={regionalEvent}
                              overdue={overdue}
                            />
                          );
                        }}
                      </Dropdown>
                    </div>
                  );
                })}
              </div>
            ) : (
              <ErrorMessage error={'Ничего не найдено'} />
            )
          ) : (
            <ErrorMessage error="Нет данных" />
          ))}
      </div>
    </div>
  );
}

export function RoadmapEventPopup({ event, regions, popupRef, ...props }) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  if (isMobile) {
    return (
      <ReactModal
        contentRef={(node) => {
          if (popupRef) {
            popupRef.current = node;
          }
        }}
        popupRef={popupRef}
        overlayClassName={s.PopupOverlay}
        className={s.Popup}
        {...props}
      >
        <SwipeBox
          contentClassName={s.RoadmapEventPopup__swipeBox}
          isOpened={props.isOpen}
          onClose={props.onClose}
        >
          {event && (
            <RoadmapEventPopupContent event={event} regions={regions} />
          )}
        </SwipeBox>
      </ReactModal>
    );
  }

  return (
    <Popup className={s.RoadmapEventPopup} popupRef={popupRef} {...props}>
      {event && <RoadmapEventPopupContent event={event} regions={regions} />}
    </Popup>
  );
}
