import React from 'react';
import { CircleProgressIndicator } from '../../ui/ProgressIndicator/CircleProgressIndicator';
import { CheckBox } from '../../ui/CheckBox/CheckBox';
import s from './MapFiltersPopup.module.scss';

export function ItemList({
  itemsQuery,
  filters,
  onChangeFilters,
  single,
  itemId = (x) => x.id
}) {
  if (itemsQuery.check.spinner || !itemsQuery.state.data) {
    return <CircleProgressIndicator />;
  }

  const handleCheck = (id) => {
    const state = single ? {} : { ...filters };
    state[id] = !filters[id];
    onChangeFilters(state);
  };

  return (
    <div className={s.FederalProjects__checkboxes}>
      {itemsQuery.state.data.list.map((item) => {
        const id = itemId(item);
        return (
          <CheckBox
            key={id}
            className={s.FederalProjects__checkbox}
            id={id}
            label={item.name_full || item.name}
            variant={CheckBox.CARD}
            checked={!!filters[id]}
            onChange={() => handleCheck(id)}
          />
        );
      })}
    </div>
  );
}

export function ItemListSimple({ items, selectedItem, onChangeFilters }) {
  const handleCheck = (id) => {
    onChangeFilters(items.filter((item) => item.id === id)[0]);
  };

  return (
    <div className={s.FederalProjects__checkboxes}>
      {items.map((item) => {
        const id = item.id;

        return (
          <CheckBox
            key={id}
            className={s.FederalProjects__checkbox}
            id={id}
            label={item.label}
            variant={CheckBox.CARD}
            checked={id === selectedItem.id}
            onChange={() => handleCheck(id)}
          />
        );
      })}
    </div>
  );
}
