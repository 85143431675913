import React, { useLayoutEffect, useRef, useState } from 'react';
import { Map } from 'react-leaflet';
import L from 'leaflet';
import { ZoomControl } from './ZoomControl';
import ResetControl from './ResetControl';

export function BaseMap({ children, className, ...props }) {
  const mapRef = useRef(null);
  const defaultViewport = useRef(null);
  const [viewport, setViewport] = useState(null);

  useLayoutEffect(() => {
    if (!defaultViewport.current && mapRef.current) {
      defaultViewport.current = mapRef.current.viewport;
    }
  });

  return (
    <Map
      className={className}
      crs={L.CRS.Simple}
      ref={mapRef}
      viewport={viewport}
      zoomControl={false}
      {...props}
    >
      {children}
      <ZoomControl
        onZoomChange={(change) => {
          const map = mapRef.current.leafletElement;
          map.setZoom(map.getZoom() + change);
        }}
      />
      <ResetControl
        onClick={(e) => {
          e.preventDefault();
          setViewport({ ...defaultViewport.current });
        }}
      />
    </Map>
  );
}
