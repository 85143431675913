import React, { useMemo, useState } from 'react';
import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { QUERY_GET_RESULT_FEDERAL } from '../../graphql/queries/federalProjects';
import { ControlPointsList } from '../../common/EventsList/ControlPointsList';
import { TopBar } from '../../common/TopBar/TopBar';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { TopBarProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { Result } from '../../data/result';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { RightPaneLayout } from '../../common/GridLayout/RightPaneLayout';
import { RightMenuPopup } from '../../common/ui/RightMenuPopup/RightMenuPopup';
import { useWindowSize } from '../../store/useWindowSize';
import { FlyingButton } from '../../common/FlyingBox/FlyingBox';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { ReactComponent as ReportIcon } from '../../assets/Icons/Document.svg';
import { queryLoader } from '../../common/QueryLoader';
import { useRoadmapEvents } from '../../store/useRoadmapEvents';
import { RF_VALUE } from '../IndicatorsPage/ProgressView/ProgressView';
import { useActualityDateContext } from '../../store/useActualityDateContext';
import { ReactHelmet } from '../../common/ReactHelmet';
import { Title } from './Title.js/Title';
import ResultInfoView from './ResultsInfoView/ResultInfoView';
import { MainChart } from './MainCharts/MainCharts';
import { processSubsidiaryEntityPlans } from './processSubsidiaryEntityPlans';
import { RoadmapEvents } from './RoadmapEvents/RoadmapEvents';
import s from './ResultPage.module.scss';

const resultOptions = {
  query: QUERY_GET_RESULT_FEDERAL,
  mapData: (data) => {
    return data.resultFederal;
  },
  skipQuery: skipIfNull(null)
};
const useResultQuery = (id = undefined, subsidiaryEntityPlanFilter) => {
  return useGraphqlQuery({
    queryOptions: resultOptions,
    variables: id
      ? {
          id,
          subsidiaryEntityPlanFilter: {
            active: true,
            ...subsidiaryEntityPlanFilter
          }
        }
      : null
  });
};

function ResultPage({ match, history }) {
  const resultId = match.params.resultId;
  const [query, changeQuery] = useUrlQuery();
  const preserved = usePreserveQueryFast(query);
  const { year, yearMonth } = useQueryPeriodFast(query, changeQuery);
  const regionCode =
    query.region_code !== RF_VALUE ? query.region_code : undefined;

  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;
  const [popupActive, setPopupActive] = useState(false);
  const [rightOpened, setRightOpened] = useState(false);

  const onPopupOpen = () => {
    setPopupActive(true);
  };
  const onPopupClose = () => {
    setPopupActive(false);
  };

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  const resultQuery = useResultQuery(resultId, { year });
  const resultData = resultQuery.state.data;

  const description = resultData && resultData.description;
  const controlPoints = resultData && resultData.control_points;
  const characteristic =
    resultData && resultData.characteristics && resultData.characteristics[0];
  const roadmapData = resultData && resultData.roadmap;
  const subsidiaryEntityPlans =
    roadmapData && roadmapData?.subsidiary_entity_plans;

  const roadmapId = resultData && resultData.roadmap_id;

  const federalProject = resultData?.owner_task?.federal_project || undefined;

  const eventsQuery = useRoadmapEvents(roadmapId, year, regionCode);

  const { allRegions, currentRegion, totalRegions } = useMemo(() => {
    return processSubsidiaryEntityPlans(subsidiaryEntityPlans, regionCode);
  }, [subsidiaryEntityPlans, regionCode]);

  const actualityDate = useActualityDateContext();

  return (
    <RightPaneLayout
      topBar={
        <TopBar>
          <TopBarProjects
            projects={federalProject ? [federalProject] : undefined}
            noCommon={true}
          />
        </TopBar>
      }
      right={
        <ResultInfoView
          resultId={resultId}
          year={year}
          yearMonth={yearMonth}
          grbsCodes={
            query.grbs && query.grbs !== 'all' ? [query.grbs] : undefined
          }
        />
      }
      popup={
        description && (
          <RightMenuPopup isActive={popupActive} onClose={onPopupClose}>
            <div className={s.Popup__descriptionTitle}>Описание результата</div>
            {description.split('\n').map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </RightMenuPopup>
        )
      }
      extra={
        isMobile && (
          <FlyingButton
            className={s.Results__flyingButton}
            onClick={onRightOpen}
          >
            <ReportIcon />
            <span>Финансирование</span>
          </FlyingButton>
        )
      }
      onRightClose={onRightClose}
      rightOpened={rightOpened}
    >
      <ReactHelmet entity={Result.getName(resultData)} type={'Результат'} />
      <Title
        title={Result.getName(resultData)}
        description={description}
        onGoBack={() =>
          history.goBack(preserved.createLink('/indicators?tab=results'))
        }
        onPopupOpen={onPopupOpen}
        currentRegion={currentRegion ? currentRegion.name : null}
      />
      {queryLoader(resultQuery) || (
        <>
          <MainChart
            resultData={resultData}
            year={year}
            characteristic={characteristic}
            actualityDate={actualityDate}
          />
          {roadmapId ? (
            <div className={s.ControlPoints}>
              <RoadmapEvents
                currentRegion={currentRegion}
                totalRegions={totalRegions}
                eventsQuery={eventsQuery}
                allRegions={allRegions}
                regionCode={regionCode}
              />
            </div>
          ) : (
            <div className={s.ControlPoints}>
              <div className={s.SubTitle}>Контрольные точки</div>
              {controlPoints ? (
                <ControlPointsList
                  className={s.TaskStatus}
                  eventsQuery={eventsQuery}
                  controlPoints={controlPoints}
                  currentRegion={currentRegion}
                  totalRegions={totalRegions}
                  regionCode={regionCode}
                />
              ) : (
                <div className={s.RoadmapPage__eventListPlaceholder}>
                  <p>В текущем году контрольных точек нет</p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </RightPaneLayout>
  );
}

ResultPage.propTypes = {};

export default ResultPage;
