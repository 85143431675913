import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { stringify } from 'query-string';
import { useUrlQuery } from '../../utils/useUrlQuery';
import ReportViewLayout from '../ReportsPage/common/ReportViewLayout';
import ReportTitle from '../ReportsPage/common/ReportTitle';
import { ReportSidebar } from '../ReportsPage/common/ReportSidebar';
import ReportRadioGroup from '../ReportsPage/common/ReportRadioGroup';
import { Button } from '../../common/ui/Button/Button';
import FileCard from '../../common/ui/FileCard/FileCard';
import { ReactComponent as XlsxIcon } from '../../assets/Icons/Files/xlsx.svg';
import { DATE_FORMAT_RUSSIAN } from '../../utils/date';
import { RightPaneLayout } from '../../common/GridLayout/RightPaneLayout';
import { useRegions } from '../../store/useRegions';
import { useFederalProjects } from '../../store/useFederalProjects';
import { QUERY_GET_RESULT_FEDERALS } from '../../graphql/queries/federalProjects';
import { Project } from '../../data/project';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { useWindowSize } from '../../store/useWindowSize';
import { FlyingButton } from '../../common/FlyingBox/FlyingBox';
import { ReactComponent as ReportIcon } from '../../assets/Icons/Document.svg';
import { getMonthName } from '../../utils/constants';
import { getYearMonth } from '../../utils/useQueryPeriod';
import { reportScopeType } from '../../store/DocumentTokenStore';
import { InfographicEvents } from './infographic/InfographicEvents';
import { InfographicCharts } from './infographic/InfographicCharts';
import InfographicChip from './InfographicChip';
import { ReportTextView } from './ReportTextView';
import s from './ReportPage.module.scss';

const TEXT = 'text';
const GRAPHICAL = 'graphical';
const TABLE = 'table';

const possibleTabs = new Set([TEXT, GRAPHICAL, TABLE]);

const reportTypes = {
  [TEXT]: {
    component: ReportTextView
  },
  [GRAPHICAL]: {
    component: InfographicView,
    file: ReportTitle.defaultProps.file
  },
  [TABLE]: {
    component: TableView,
    file: ReportTitle.defaultProps.file,
    title: 'Таблица XLSX'
  }
};

const controlPoints = {
  [InfographicChip.ERROR]: [
    {
      date: new Date(2019, 2, 3),
      text:
        'Принято решение о создании (реорганизации) организации (структурного подразделения)'
    },
    {
      date: new Date(2019, 2, 3),
      text:
        'Принято решение о создании (реорганизации) организации (структурного подразделения)'
    },
    {
      date: new Date(2019, 2, 3),
      text:
        'Принято решение о создании (реорганизации) организации (структурного подразделения)'
    }
  ],
  [InfographicChip.SUCCESS]: [
    {
      date: new Date(2019, 2, 3),
      text:
        'Принято решение о создании (реорганизации) организации (структурного подразделения)'
    },
    {
      date: new Date(2019, 2, 3),
      text:
        'Принято решение о создании (реорганизации) организации (структурного подразделения)'
    },
    {
      date: new Date(2019, 2, 3),
      text:
        'Принято решение о создании (реорганизации) организации (структурного подразделения)'
    }
  ],
  [InfographicChip.WARN]: [
    {
      date: new Date(2019, 2, 3),
      text:
        'Принято решение о создании (реорганизации) организации (структурного подразделения)'
    },
    {
      date: new Date(2019, 2, 3),
      text:
        'Принято решение о создании (реорганизации) организации (структурного подразделения)'
    },
    {
      date: new Date(2019, 2, 3),
      text:
        'Принято решение о создании (реорганизации) организации (структурного подразделения)'
    }
  ]
};

const radioButtons = [
  { title: 'Текстовая справка', value: TEXT },
  { title: 'Инфографика', value: GRAPHICAL },
  { title: 'Табличная справка', value: TABLE }
];

const ReportView = ({ type, query, ...props }) => {
  const { year, month } = query;
  const yearMonth = getYearMonth(year, month);
  const { component: Comp, ...typeProps } = reportTypes[type];
  return (
    <ReportViewLayout>
      <Comp
        className={classNames(s.Report__Content, s['_' + type])}
        query={query}
        {...typeProps}
        {...props}
        year={year}
        month={month}
        yearMonth={yearMonth}
      />
    </ReportViewLayout>
  );
};

function InfographicView({ className, ...props }) {
  const [query, changeQuery] = useUrlQuery();

  return (
    <>
      <ReportTitle
        files={[
          {
            fileName: 'pdf',
            url: '/api/reference/infographics_pdf?' + stringify(query),
            cookieLoader: true
          }
        ]}
        type={reportScopeType.REFERENCE_DOCUMENT_INFOGRAPHICS_PDF}
        title={`Отчёт за ${getMonthName(props.month)} ${props.year}`}
        onClick={props.onGoBack}
      />
      <div className={className}>
        <InfographicCharts {...props} />
        <InfographicEvents {...props} />
      </div>
    </>
  );
}

const ControlPointsInfo = () => {
  return (
    <div className={s.InfoGroup}>
      <h2 className={s.InfoGroup__title}>
        Сведения о выполнении контрольных точек
      </h2>
      <div className={s.ControlPoints}>
        {Object.entries(controlPoints).map(([status, points], i) => (
          <ul className={s.ControlPointsList} key={i}>
            <InfographicChip status={status} />
            {points.map((point, i) => (
              <ControlPoint {...point} key={i} />
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

const ControlPoint = ({ date, text }) => {
  return (
    <li className={s.ControlPoint}>
      <p className={s.ControlPoint__text}>
        {format(date, DATE_FORMAT_RUSSIAN, { locale: ru })}
      </p>
      <p className={s.ControlPoint__text}>{text}</p>
    </li>
  );
};

function TableView({ className, ...props }) {
  const { year, month, result, region, federalProject } = props;

  const variables = useMemo(() => {
    return {
      year: +year,
      month: +month,
      result_id: result && result.id,
      region_id: region && region.id,
      federal_project_id: federalProject && federalProject.id,
      type: 'xlsx'
    };
  }, [federalProject, month, region, result, year]);

  return (
    <>
      <ReportTitle
        files={[
          {
            fileName: 'xlsx',
            url: '/api/reference/report?' + stringify(variables),
            cookieLoader: true
          }
        ]}
        type={reportScopeType.REFERENCE_DOCUMENT_REPORT}
        title={`Отчёт за ${getMonthName(props.month)} ${props.year}`}
        onClick={props.onGoBack}
      />
      <div className={classNames(s.ReportTypeTable, className)}>
        <FileCard icon={XlsxIcon} {...props} />
      </div>
    </>
  );
}

const Sidebar = ({
  handleRadioChange,
  selected,
  region,
  federalProject,
  result
}) => {
  return (
    <ReportSidebar title="Стандартный отчет">
      <div className={s.ReportGeneratorFields}>
        <div className={s.ReportGeneratorField}>
          <p className={s.ReportGeneratorField__label}>
            {!region ? 'Субъекты' : 'Субъект'}
          </p>
          <div className={s.ReportGeneratorField__value}>
            {!region ? 'Все' : region.name}
          </div>
        </div>
        <div className={s.ReportGeneratorField}>
          <p className={s.ReportGeneratorField__label}>
            {!federalProject ? 'Федеральные проекты' : 'Федеральный проект'}
          </p>
          <div className={s.ReportGeneratorField__value}>
            {!federalProject ? 'Все' : federalProject.name}
          </div>
        </div>
        <div className={s.ReportGeneratorField}>
          <p className={s.ReportGeneratorField__label}>
            {!result ? 'Результаты' : 'Результат'}
          </p>
          <div className={s.ReportGeneratorField__value}>
            {!result ? 'Все' : result.name_full || result.name}
          </div>
        </div>
      </div>
      <ReportRadioGroup
        onChange={handleRadioChange}
        options={radioButtons}
        selected={selected}
      />
      <div className={s.ButtonGroup}>
        {/*<Button className={s.ButtonGroup__btn}>Скачать все</Button>*/}
        <Button
          className={s.ButtonGroup__btn}
          to={'/reports'}
          variant={Button.SECONDARY}
        >
          Сформировать другой отчет
        </Button>
      </div>
    </ReportSidebar>
  );
};

const resultsQueryOptions = {
  query: QUERY_GET_RESULT_FEDERALS,
  mapData: (result) => result.resultFederals.list,
  skipQuery: skipIfNull(null)
};

function anyQuerySpinner(...queries) {
  return queries.some((q) => q.check.spinner);
}

export function useReportRequest(query) {
  const regionCode = query.region_code;
  const resultId = query.result;
  const regionsQuery = useRegions();
  const federalProjectsQuery = useFederalProjects();
  const resultsQuery = useGraphqlQuery({
    queryOptions: resultsQueryOptions,
    variables: resultId
      ? {
          ids: [resultId]
        }
      : null
  });

  const result = resultsQuery.state.data && resultsQuery.state.data[0];

  const region =
    regionsQuery.state.data && regionCode
      ? regionsQuery.state.data.find((reg) => reg.code_nalog === regionCode)
      : null;
  const federalProject = federalProjectsQuery.state.data
    ? federalProjectsQuery.state.data.find(
        (p) => Project.getFederalProjectCode(p) === +query.fp
      )
    : null;

  const isLoading = anyQuerySpinner(
    regionsQuery,
    federalProjectsQuery,
    resultsQuery
  );

  return { isLoading, result, region, federalProject };
}

function ReportPage({ history }) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const [rightOpened, setRightOpened] = useState(false);

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  const [query, changeQuery] = useUrlQuery();
  const setTab = useCallback(
    (tab) => {
      changeQuery({ tab }, true);
      onRightClose();
    },
    [changeQuery]
  );
  const reportType = possibleTabs.has(query.tab) ? query.tab : TEXT;

  const { isLoading, result, region, federalProject } = useReportRequest(query);

  return (
    <RightPaneLayout
      className={s.Layout}
      fixed={true}
      right={
        isLoading ? (
          <CircleProgressIndicator />
        ) : (
          <Sidebar
            handleRadioChange={setTab}
            selected={reportType}
            region={region}
            federalProject={federalProject}
            result={result}
          />
        )
      }
      extra={
        isMobile && (
          <FlyingButton
            className={s.Report__flyingButton}
            onClick={onRightOpen}
          >
            <ReportIcon />
            <span>Информация по отчету</span>
          </FlyingButton>
        )
      }
      onRightClose={onRightClose}
      rightOpened={rightOpened}
    >
      {isLoading ? (
        <CircleProgressIndicator />
      ) : (
        <ReportView
          query={query}
          type={reportType}
          onGoBack={() => history.goBack('/reports')}
          region={region}
          federalProject={federalProject}
          result={result}
          reportType={query.report || 'standard'}
        />
      )}
    </RightPaneLayout>
  );
}

export default ReportPage;
