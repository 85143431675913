import React from 'react';
import classNames from 'classnames';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { addDays } from 'date-fns';
import Dropdown from '../../../common/ui/Dropdown/Dropdown';
import {
  QUERY_GET_GENERAL_INDICATORS,
  QUERY_GET_GENERAL_INDICATORS_AP
} from '../../../graphql/queries/indicators';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { RightMenuPopup } from '../../../common/ui/RightMenuPopup/RightMenuPopup';
import { useQueryPeriod } from '../../../utils/useQueryPeriod';
import { IndicatorGroupTitle } from './IndicatorGroup';
import { IndicatorCharts, IndicatorGoals } from './IndicatorGoals';
import { NpoTasks } from './NpoTasks';
import s from './Goals.module.scss';

const indicatorsQueryOptions = {
  query: QUERY_GET_GENERAL_INDICATORS,
  mapData: (result) => result
};

const apQueryOptions = {
  query: QUERY_GET_GENERAL_INDICATORS_AP,
  mapData: (result) => result.indicators_ap
};

const variables = {};

export const GoalsTotal = React.memo(function GoalsTotal({
  onClose,
  isActive
}) {
  const { year } = useQueryPeriod();

  const indicatorsQuery = useGraphqlQuery({
    queryOptions: indicatorsQueryOptions,
    variables
  });

  const apQuery = useGraphqlQuery({
    queryOptions: apQueryOptions,
    variables
  });

  const { goals, npoTasks } = indicatorsQuery.state.data || {};
  const ap = apQuery.state.data;

  // todo дата актуальности из gql
  const actualityDate = addDays(new Date(), -1);

  return (
    <RightMenuPopup onClose={onClose} isActive={isActive}>
      <div>
        <Dropdown
          className={s.GoalGroup}
          childrenClassName={s.GoalGroup__dropdown}
          childrenOpenedClassName={s.GoalGroup__dropdown_opened}
          top={({ opened, setOpened }) => (
            <IndicatorGroupTitle
              title={'Цели и показатели НПО'}
              opened={opened}
              setOpened={setOpened}
            />
          )}
        >
          {({ opened }) =>
            indicatorsQuery.check.spinner ? (
              <CircleProgressIndicator />
            ) : opened && isActive ? (
              <IndicatorGoals
                goals={goals}
                activeYear={year}
                actualityDate={actualityDate}
              />
            ) : null
          }
        </Dropdown>
        <Dropdown
          className={classNames(s.GoalGroup, s.GoalGroup_separator)}
          childrenClassName={s.GoalGroup__dropdown}
          childrenOpenedClassName={s.GoalGroup__dropdown_opened}
          top={({ opened, setOpened }) => (
            <IndicatorGroupTitle
              title={
                'Мониторинг показателей НПО для Администрации Президента РФ'
              }
              opened={opened}
              setOpened={setOpened}
            />
          )}
        >
          {({ opened }) =>
            apQuery.check.spinner ? (
              <CircleProgressIndicator />
            ) : opened && isActive ? (
              <IndicatorCharts
                indicators={ap}
                activeYear={year}
                actualityDate={actualityDate}
              />
            ) : null
          }
        </Dropdown>
        <h3 className={s.Title}>Задачи и вехи</h3>
        <NpoTasks
          npoTasks={npoTasks}
          year={year}
          actualityDate={actualityDate}
        />
      </div>
    </RightMenuPopup>
  );
});
