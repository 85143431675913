import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import {
  QUERY_GET_SUBSIDIARY_ENTITIES,
  QUERY_GET_SUBSIDIARY_ENTITIES_FOR_MAP,
  QUERY_GET_SUBSIDIARY_ENTITY
} from '../graphql/queries/organization';
import { formatDateIso } from '../utils/date';

const subsidiaryEntitiesOptions = {
  query: QUERY_GET_SUBSIDIARY_ENTITIES,
  mapData: (data) => data.subsidiaryEntitiesPage.list,
  skipQuery: skipIfNull(null)
};

const subsidiaryEntitiesForMapOptions = {
  query: QUERY_GET_SUBSIDIARY_ENTITIES_FOR_MAP,
  mapData: (data) => data.subsidiaryEntitiesPage.list,
  skipQuery: skipIfNull(null)
};

const subsidiaryEntityOptions = {
  query: QUERY_GET_SUBSIDIARY_ENTITY,
  mapData: (data) => ({
    ...data.subsidiaryEntity,
    financesActualityDate: data.financesActualityDate
  }),
  skipQuery: skipIfNull(null)
};

export const useSubsidiaryEntities = (organizationId, year, yearMonth) => {
  return useGraphqlQuery({
    queryOptions: subsidiaryEntitiesOptions,
    variables: organizationId
      ? {
          filter: {
            organization_ids: [organizationId],
            years: year
          },
          date: formatDateIso(new Date()),
          yearMonth
        }
      : null
  });
};

export const useSubsidiaryEntitiesForMap = (filter, pagination) => {
  return useGraphqlQuery({
    queryOptions: subsidiaryEntitiesForMapOptions,
    variables: filter
      ? {
          input: {
            filter,
            pagination
          }
        }
      : null
  });
};

export const useSubsidiaryEntity = (id, yearMonth) => {
  return useGraphqlQuery({
    queryOptions: subsidiaryEntityOptions,
    variables: id ? { id, date: formatDateIso(new Date()), yearMonth } : null
  });
};
