import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useStore } from '@proscom/prostore-react';
import { STORE_MOBILE_APP } from '../../store/stores';
import s from './TopBar.module.scss';

export const TopBar = ({ className, children, classNameInner, ...props }) => {
  const [mobileAppState, mobileAppStore] = useStore(STORE_MOBILE_APP);

  const statusBarHeight = mobileAppState.statusBarHeight;
  const style = useMemo(() => {
    return statusBarHeight
      ? {
          paddingTop: statusBarHeight + 'px'
        }
      : {};
  }, [statusBarHeight]);

  return (
    <div
      className={classNames('topbar', s.TopBar, className)}
      {...props}
      style={style}
    >
      <div className={classNames(s.TopBar__inner, classNameInner)}>
        {children}
      </div>
    </div>
  );
};
