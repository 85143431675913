import React, { useMemo, useState } from 'react';
import { addDays } from 'date-fns';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { TopBarProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { RightPaneLayout } from '../../common/GridLayout/RightPaneLayout';
import { useWindowSize } from '../../store/useWindowSize';
import { FlyingButton } from '../../common/FlyingBox/FlyingBox';
import { ReactComponent as ReportIcon } from '../../assets/Icons/Document.svg';
import { PageTitle } from '../../common/PageTitle/PageTitle';
import { TopBar } from '../../common/TopBar/TopBar';
import { useSubsidiaryEntity } from '../../store/useSubsidiaryEntities';
import { formatValueUnit } from '../../utils/formatNumber';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { useBudgetChartRpBase } from '../IndicatorsPage/useBudgetChartRp';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { RoadmapEvents } from '../ResultPage/RoadmapEvents/RoadmapEvents';
import { useRoadmapEvents } from '../../store/useRoadmapEvents';
import { processSubsidiaryEntityPlans } from '../ResultPage/processSubsidiaryEntityPlans';
import { queryLoader } from '../../common/QueryLoader';
import { getStatusByValue } from '../../utils/constants';
import { ReactHelmet } from '../../common/ReactHelmet';
import { SubsidiaryEntity } from '../../data/subsidiaryEntity';
import SubsidiaryView from './SubsidiaryView/SubsidiaryView';
import { MainCards } from './MainCards/MainCards';
import s from './SubsidiaryEntityPage.module.scss';

function InfoView({ onGoBack, subsidiaryEntity, year, financesActualityDate }) {
  const roadmap = subsidiaryEntity.owner_roadmap;
  const eventCompletedPercent =
    subsidiaryEntity.dc_completion?.dc_completion_percent;
  const cashExpensesPercent = subsidiaryEntity.finances?.cash_expenses_percent;
  const actualityDate = new Date();
  const regionCode = subsidiaryEntity?.region?.code_nalog;
  const roadmapId = roadmap.id;

  const { allRegions, totalRegions, currentRegion } = useMemo(() => {
    return processSubsidiaryEntityPlans(null, regionCode);
  }, [regionCode]);

  const eventsQuery = useRoadmapEvents(roadmapId, year, regionCode);

  return (
    <div className={s.InfoView}>
      <PageTitle
        text={subsidiaryEntity.name_full || subsidiaryEntity.name}
        onClick={onGoBack}
      />
      <MainCards
        cards={[
          {
            label: 'Дата начала занятий',
            value: <p className={s.DateCard}>01.03.2020</p>
            // status: RatingStatus.WARN
          },
          {
            label: 'Выполнение мероприятий',
            value: (
              <p className={s.PercentCard}>
                {formatValueUnit(eventCompletedPercent, '%')}
              </p>
            ),
            status: getStatusByValue(eventCompletedPercent),
            actualityDate: actualityDate
          },
          {
            label: 'Кассовое исполнение',
            value: (
              <p className={s.PercentCard}>
                {formatValueUnit(cashExpensesPercent, '%')}
              </p>
            ),
            status: getStatusByValue(cashExpensesPercent),
            actualityDate: financesActualityDate
          }
        ]}
      />
      <div className={s.InfoView__eventList}>
        <RoadmapEvents
          currentRegion={currentRegion}
          totalRegions={totalRegions}
          eventsQuery={eventsQuery}
          allRegions={allRegions}
          regionCode={regionCode}
        />
      </div>
    </div>
  );
}

function SubsidiaryEntityPage({ match, history }) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const [rightOpened, setRightOpened] = useState(false);

  const [queryParams, changeQuery] = useUrlQuery();

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  const { organizationId, subsidiaryEntityId } = match.params;
  const preserved = usePreserveQueryFast(queryParams);
  const { year, yearMonth, month } = useQueryPeriodFast(
    queryParams,
    changeQuery
  );

  // todo дата актуальности из gql
  const actualityDate = addDays(new Date(), -1);

  const subsidiaryEntityQuery = useSubsidiaryEntity(
    subsidiaryEntityId,
    yearMonth
  );
  const subsidiaryEntityData = subsidiaryEntityQuery.state.data;

  const org = subsidiaryEntityData?.base_organization;

  const federalProjects = useMemo(() => {
    const project =
      subsidiaryEntityData?.owner_roadmap?.result?.owner_task?.federal_project;
    return project ? [project] : null;
  }, [subsidiaryEntityData]);

  const selectedFpCode = +queryParams.fp;

  const grbs = queryParams.grbs;

  const resultId =
    subsidiaryEntityData &&
    subsidiaryEntityData.owner_roadmap &&
    subsidiaryEntityData.owner_roadmap.result &&
    subsidiaryEntityData.owner_roadmap.result.id;

  const budgetsFilter = useMemo(
    () =>
      subsidiaryEntityData?.region?.id && selectedFpCode && resultId
        ? {
            resultIds: [resultId],
            regionIds: subsidiaryEntityData.region.id,
            federalProjectCodes: selectedFpCode + '',
            grbsCodes: grbs && grbs !== 'all' ? grbs : undefined
          }
        : null,
    [grbs, resultId, selectedFpCode, subsidiaryEntityData]
  );

  const budgetCharts = useBudgetChartRpBase({
    yearMonth,
    filter: budgetsFilter
  });

  const regionCode = subsidiaryEntityData?.region?.code_nalog;

  return (
    <RightPaneLayout
      topBar={
        <TopBar>
          <TopBarProjects projects={federalProjects} noCommon={true} />
        </TopBar>
      }
      right={
        queryLoader(subsidiaryEntityQuery) || (
          <SubsidiaryView
            actualityDate={actualityDate}
            subsidiaryEntity={subsidiaryEntityData}
            budgetCharts={budgetCharts}
          />
        )
      }
      extra={
        isMobile && (
          <FlyingButton
            className={s.SubsidiaryEntity__flyingButton}
            onClick={onRightOpen}
          >
            <ReportIcon />
            <span>Информация</span>
          </FlyingButton>
        )
      }
      onRightClose={onRightClose}
      rightOpened={rightOpened}
    >
      <ReactHelmet
        entity={SubsidiaryEntity.getCompleteName(subsidiaryEntityData)}
        type={'Субсидиарная сущность'}
      />
      {queryLoader(subsidiaryEntityQuery) || (
        <InfoView
          subsidiaryEntity={subsidiaryEntityData}
          financesActualityDate={budgetCharts.federalDate}
          year={year}
          onGoBack={() =>
            history.goBack(
              preserved.createLink(
                organizationId
                  ? `/organizations/${organizationId}`
                  : regionCode
                  ? `/regions/${regionCode}`
                  : `/`
              )
            )
          }
        />
      )}
    </RightPaneLayout>
  );
}

SubsidiaryEntityPage.propTypes = {};

export default SubsidiaryEntityPage;
