import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { filterSearch } from '../../../utils/filterSearch';
import { mapRequestQuery } from '../../../store/mapRequestQuery';

export const SLICE_COUNT = 5;

// Должно быть больше, чем количество результатов (их 136)
export const ONE_PAGE_ITEMS = 200;

export const processResults = (query, selected) => {
  if (!query || !query.state.data) {
    return {
      loading: true
    };
  }

  const data = query.state.data;
  const result = {};

  let listItems = data.list ? data.list : [];
  result.totalCount = data.header.totalCount;

  if (!selected) {
    result.list = listItems.slice(0, SLICE_COUNT);
  } else {
    result.list = listItems.slice(0, ONE_PAGE_ITEMS);
  }

  result.loading = query.check.spinner;

  return result;
};
const searchArray = (array, search, fieldCb) => {
  if (!array) {
    return null;
  }

  const filtered = filterSearch(search, array, fieldCb);

  return {
    header: {
      page: 0,
      onePage: filtered.length,
      totalCount: filtered.length,
      hasNext: false
    },
    list: filtered
  };
};

export function useSearchArrayQuery(
  query,
  search,
  fieldCb,
  sortBy = null,
  array = undefined
) {
  const data = searchArray(array || query.state.data, search, fieldCb);
  const result = { ...data };
  if (sortBy && data && data.list) {
    result.list = data.list.slice().sort(sortBy);
  }
  return mapRequestQuery(query, () => result);
}

export function useSearchQuery(
  queryOptions,
  search,
  filter,
  showEmpty = false
) {
  return useGraphqlQueryVars({
    queryOptions,
    variableCreator: [
      () =>
        search || showEmpty || filter
          ? {
              input: {
                filter: {
                  ...filter,
                  search
                },
                pagination: {
                  onePage: ONE_PAGE_ITEMS
                }
              }
            }
          : null,
      [search, filter, showEmpty]
    ]
  });
}
