import { Project } from '../../../data/project';

export function findProjectIndex(projects, code, defaultValue = null) {
  if (!projects) return defaultValue;
  const index = projects.findIndex(
    (d) => Project.getFederalProjectCode(d.data.federal_project) === code
  );
  if (index >= 0) return index;
  return defaultValue;
}

export function findDistrictIndex(districts, code, defaultValue = null) {
  if (!districts) return defaultValue;
  const index = districts.findIndex(
    (d) => d.data.federal_district.code === code
  );
  if (index >= 0) return index;
  return defaultValue;
}

export function findSubjectIndex(subjects, code, defaultValue = null) {
  if (!subjects) return defaultValue;
  const index = subjects.findIndex((d) => d.data.region.code === code);
  if (index >= 0) return index;
  return defaultValue;
}

export function getProjectCode(projects, index) {
  const projectsList = projects[index];
  if (!projectsList) return null;
  return Project.getFederalProjectCode(projectsList.data.federal_project);
}

export function getDistrictCode(districts, index) {
  const districtsList = districts[index];
  if (!districtsList) return null;
  return +districtsList.data.federal_district.code;
}
