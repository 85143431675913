import { hideResultAch, hideTargetAch } from '../config';

export const INDICATOR_ACCEPT = 'accept';
export const INDICATOR_CASH_COMPLETION = 'cash_completion';
export const INDICATOR_DC_COMPLETION = 'dc_completion_percent';
export const INDICATOR_RESULT_ACH = 'result_ach';
export const INDICATOR_TARGET_ACH = 'target_ach';
export const INDICATOR_INTERNET_SPEED = 'average_internet_speed';

export const MAX_INTERNET_CITY = 100;
export const MAX_INTERNET_COUNTRYSIDE = 50;

export const indicators = [
  { label: 'Принятие бюджетных обязательств', value: INDICATOR_ACCEPT },
  { label: 'Кассовое исполнение', value: INDICATOR_CASH_COMPLETION },
  { label: 'Выполнение дорожных карт', value: INDICATOR_DC_COMPLETION }
];

if (!hideResultAch) {
  indicators.push({
    label: 'Достижение результатов',
    value: INDICATOR_RESULT_ACH
  });
}

if (!hideTargetAch) {
  indicators.push({
    label: 'Достижение показателей',
    value: INDICATOR_TARGET_ACH
  });
}

export const mapIndicators = [
  { label: 'Не выбрано', value: 'none' },
  ...indicators,
  { label: 'Скорость интернета в школах', value: INDICATOR_INTERNET_SPEED }
];
export const speedSources = [
  { label: 'Наши данные', value: 'our' },
  { label: 'Данные Минкомсвязи', value: 'minkomsvyaz' }
];
export const SPEED_TYPE_CITY = 'city';
export const SPEED_TYPE_COUNTRYSIDE = 'countryside';
export const speedTypes = [
  { label: 'Город', value: SPEED_TYPE_CITY },
  { label: 'Село', value: SPEED_TYPE_COUNTRYSIDE }
];

export const organizationTypes = [
  { id: 0, value: 'ALL', label: 'Все типы организаций' },
  { id: 1, value: 'PRESCHOOL', label: 'Дошкольное образование' },
  { id: 2, value: 'SCHOOL', label: 'Общее образование' },
  { id: 3, value: 'SPO', label: 'Среднее профессиональное образование' },
  {
    id: 4,
    value: 'VO',
    label: 'Высшее и дополнительное профессиональное образование'
  },
  {
    id: 5,
    value: 'ADDITIONAL_CHILDREN',
    label: 'Дополнительное образование детей'
  }
];
