import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { GeoJSON } from 'react-leaflet';
import { useRequestStore } from '@proscom/prostore-react';
import { bbox } from '@turf/turf';
import L, { point } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { STORE_MAP } from '../../store/stores';
import { BaseMap } from '../Map/CommonMap/BaseMap';
import { Organization } from '../../data/object';
import { CircleProgressIndicator } from '../ui/ProgressIndicator/CircleProgressIndicator';
import { INDICATOR_INTERNET_SPEED } from '../../utils/indicators';
import { getInternetColor } from '../Map/CommonMap/utils';
import { createDivIcon } from './JsxMarker';
import {
  highlightFeature,
  projectCoords,
  resetHighlight,
  toLeafletBounds
} from './utils';
import {
  InternetObjectMarker,
  ObjectClusterIcon,
  ObjectMarker
} from './ObjectMarker';
import s from '../Map/CommonMap/CommonMap.module.scss';
import ms from './MapMarker.module.scss';

function createClusterIcon(cluster) {
  return createDivIcon({
    content: <ObjectClusterIcon count={cluster.getChildCount()} />,
    className: ms.div_ObjectClusterIcon,
    iconSize: point(30, 30, true)
  });
}

function getPoints(cbounds, objects) {
  let iRow = 0;
  let iCol = 0;
  const xGap = 10000;
  const yGap = 10000;
  const xStart = cbounds._northEast.lng + xGap;
  const yStart = cbounds._northEast.lat;
  const nRows = Math.ceil((yStart - cbounds._southWest.lat) / yGap);
  const gridPosition = (y, x) => [-y * yGap + yStart, x * xGap + xStart];
  const maxBounds = new L.LatLngBounds();
  maxBounds.extend(cbounds);
  const objectsWithCoords =
    objects &&
    objects.map((item) => {
      const result = {
        bs_latitude: '',
        bs_longitude: ''
      };

      const coords = Organization.getCoordinates(item);

      if (coords) {
        [result.bs_longitude, result.bs_latitude] = projectCoords([
          coords[1],
          coords[0]
        ]);
      } else {
        [result.bs_latitude, result.bs_longitude] = gridPosition(iRow, iCol);
        // Т.к нажно схлопнуть объекты без координат в одну точку, закоменитруем.
        // iRow++;
        // if (iRow >= nRows) {
        //   iRow = 0;
        //   iCol++;
        // }
        maxBounds.extend([result.bs_latitude, result.bs_longitude]);
      }

      return {
        ...item,
        ...result
      };
    });

  return [objectsWithCoords, maxBounds];
}

function RegionMapComponent({
  data,
  objects,
  region,
  className,
  onNavigateToObject,
  color,
  indicator_type,
  speed_type
}) {
  if (!data) {
    throw new Error(`Регион с id=${region.code_nalog} не найден`);
  }

  const geojsonLayerRef = useRef(null);
  const cbounds = useMemo(() => toLeafletBounds(bbox(data)), [data]);

  const [objectsMapped, maxBounds] = useMemo(
    () => getPoints(cbounds, objects),
    [cbounds, objects]
  );

  const processFeature = useCallback((feature, layer) => {
    const name = feature.properties.name;
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight
    });
    if (name) {
      layer.bindTooltip(name);
    }
  }, []);

  const objectsMarkers = useMemo(() => {
    return (
      objectsMapped &&
      objectsMapped.map((object, iObject) => {
        const { average_internet_speed } = object;

        if (indicator_type === INDICATOR_INTERNET_SPEED) {
          const upload = average_internet_speed?.upload_speed;
          const download = average_internet_speed?.download_speed;

          const color = getInternetColor(
            average_internet_speed,
            speed_type,
            0,
            100
          );

          return (
            <InternetObjectMarker
              key={iObject}
              object={object}
              onClick={onNavigateToObject}
              upload={upload}
              download={download}
              color={color}
            />
          );
        }
        // todo key = object.id понять почему есть дубликаты
        return (
          <ObjectMarker
            key={iObject}
            object={object}
            onClick={onNavigateToObject}
          />
        );
      })
    );
  }, [indicator_type, objectsMapped, onNavigateToObject, speed_type]);

  const stylize = useCallback(
    (feature) => {
      const code = feature.properties.code;
      return { color, weight: 1 };
    },
    [color]
  );

  return (
    <>
      <BaseMap
        className={classNames(s.RegionMap, className)}
        bounds={cbounds}
        minZoom={-25}
        maxZoom={-1}
      >
        <GeoJSON
          ref={geojsonLayerRef}
          data={data}
          style={stylize}
          onEachFeature={processFeature}
        />
        {objectsMapped && (
          <MarkerClusterGroup
            spiderfyOnMaxZoom={true}
            maxClusterRadius={40}
            iconCreateFunction={createClusterIcon}
          >
            {objectsMarkers}
          </MarkerClusterGroup>
        )}
      </BaseMap>
    </>
  );
}

export const RegionMap = ({ code, ...props }) => {
  const mapVariables = useMemo(
    () =>
      code
        ? {
            type: 'region',
            code: `REG${code}`
          }
        : null,
    [code]
  );
  const query = useRequestStore(STORE_MAP, mapVariables);

  if (query.check.spinner || !query.state.data) {
    return <CircleProgressIndicator absolute />;
  }

  return <RegionMapComponent {...props} data={query.state.data} />;
};
