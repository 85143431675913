import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { Tab } from './Tab';

export function TabRadio({ value, selected, onChange, ...props }) {
  const onClick = useCallback(() => onChange(value), [onChange, value]);
  return <Tab active={value === selected} onClick={onClick} {...props} />;
}

TabRadio.propTypes = {
  value: PropTypes.any,
  selected: PropTypes.any,
  onChange: PropTypes.func
};

export function useStateTabs(tabs, valueFn = (x) => x, defaultTab) {
  const [selected, setSelected] = useState(defaultTab || valueFn(tabs[0], 0));
  const getTabProps = (value) => ({
    value,
    selected,
    onChange: setSelected
  });
  return [selected, getTabProps, setSelected];
}

export function useQueryTabs(
  tabs,
  valueFn = (x) => x,
  queryName,
  replace = false
) {
  const [query, changeQuery] = useUrlQuery();
  const selected =
    query[queryName] || (tabs.length > 0 ? valueFn(tabs[0], 0) : null);
  const setSelected = useCallback(
    (v) => {
      const newQuery = {};
      newQuery[queryName] = v;
      changeQuery(newQuery, replace);
    },
    [changeQuery, queryName, replace]
  );
  const getTabProps = (value) => ({
    value,
    selected,
    onChange: setSelected
  });
  return [selected, getTabProps, setSelected];
}
