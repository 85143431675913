import React from 'react';
import { HELLIP } from './utf';

const formattedCountCases = [2, 0, 1, 1, 1, 2];

/**
 * Функция возвращает текст, соответствующий склонению числа.
 * Строка может содержать специальный символ $n, заменяемый на число
 * @param number - число
 * @param single - формат строки для чисел, заканчивающихся на 1
 * @param special - формат строки для чисел, заканчивающихся на 2, 3, 4
 * @param plural - формат строки для всех остальных + исключения (...11, ...12, ...13, ...14)
 * @param none - формат строки, если number = null или undefined
 * @returns {string}
 * @example
 *  ```
 *  declinedText(2, 'Выбрана $n организация', 'Выбрано $n организации', 'Выбрано $n организаций') -> 'Выбрано 2 организации'
 *  ```
 */
export function declinedText(number, single, special, plural, none = '—') {
  if (number === null || number === undefined) return none;
  const text = [single, special, plural][
    number % 100 > 4 && number % 100 < 20
      ? 2
      : formattedCountCases[number % 10 < 5 ? number % 10 : 5]
  ];
  return text.replace(/\$n/g, number);
}

export function lpad(str, num, sym) {
  return str.padStart(num, sym);
}

/**
 * Обрезает текст до заданного числа символов +- fuzziness
 * Старается обрезать по пробелу
 *
 * @param text
 * @param maxLength
 * @param fuzziness - количество символов до maxLength, где искать пробел
 */
export function shortenText(text, maxLength = 30, fuzziness = 5) {
  if (!text) {
    return text;
  }
  const textString = text + '';
  if (textString.length > maxLength) {
    let spaceIndex = -1;
    // Находим самый левый из группы самых правых пробельных символов
    for (let i = maxLength - 1; i >= maxLength - fuzziness; i--) {
      if (/\s/.test(textString[i])) {
        spaceIndex = i;
      } else if (spaceIndex !== -1) {
        break;
      }
    }
    if (spaceIndex >= 0) {
      return textString.slice(0, spaceIndex) + HELLIP;
    }
    return textString.slice(0, maxLength) + HELLIP;
  }
  return textString;
}
