import { useMemo } from 'react';

const getDefaultLabel = (item) => item.name;
const getDefaultId = (item) => item.id;

export function useMemoSuggestions(
  data,
  label = getDefaultLabel,
  id = getDefaultId
) {
  return useMemo(() => {
    if (data) {
      return data.map((item) => ({
        value: id(item),
        label: label(item)
      }));
    }
  }, [data, label, id]);
}
