import { skipIfNull } from '@proscom/prostore';
import { QUERY_SEARCH_ORGANIZATIONS } from '../../../graphql/queries/organization';
import { useSearchQuery } from './_util';

const searchOrganizationsQueryOptions = {
  query: QUERY_SEARCH_ORGANIZATIONS,
  mapData: (result) => result.organizations,
  skipQuery: skipIfNull(null)
};

export function useSearchOrganizations(search) {
  return useSearchQuery(searchOrganizationsQueryOptions, search);
}
