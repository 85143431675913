import React from 'react';
import { formatNumber } from '../../../utils/formatNumber';
import { HorizontalBarLabel } from './HorizontalBarLabel';

export function HorizontalBar({
  id,
  index,
  title,
  titleColor,
  legendPadding,
  barHeight,
  data
}) {
  const { x, y, width, color: elementColor, legend } = data;
  return (
    <g style={{ position: 'relative' }} key={`HorizontalBar_${id}_${index}`}>
      <text
        x={0}
        y={y + barHeight - barHeight / 3}
        fill={'#6C7B99'}
        style={{ fontSize: '8px', fontFamily: 'inherit' }}
      >
        {legend}
      </text>
      <rect fill={elementColor} x={x} y={y} width={width} height={barHeight} />
      <HorizontalBarLabel
        key={width}
        y={y + barHeight - barHeight / 4}
        width={width}
        style={{ fontSize: '12px', fontFamily: 'inherit' }}
        legendPadding={legendPadding}
        titleColor={titleColor}
      >
        {typeof title === 'number' ? formatNumber(title) : title}
      </HorizontalBarLabel>
    </g>
  );
}
