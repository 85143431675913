import { RequestStore } from '@proscom/prostore';

export class MapStore extends RequestStore {
  constructor() {
    super({
      regions: null,
      loading: false,
      loaded: false
    });
  }

  cache = {};

  getMapName({ type, code }) {
    return type !== 'russia'
      ? `/${type}s/lambert.${code}.geo.json`
      : '/lambert.russia.geo.json';
  }

  isMapInCache(mapName) {
    return mapName in this.cache;
  }

  getMapFromCache(mapName) {
    return this.cache[mapName];
  }

  saveMapToCache(mapName, map) {
    this.cache[mapName] = map;
  }

  async performRequest(variables, options) {
    // если карты будут кешироваться в браузере, то тогда не особо нужно
    if (!variables) return null;
    const path = this.getMapName(variables);
    let map = null;
    if (!this.isMapInCache(path)) {
      const response = await fetch(path);
      map = await response.json();
      this.saveMapToCache(path, map);
    } else {
      map = this.getMapFromCache(path);
    }

    return map;
  }
}
