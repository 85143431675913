import React, { useMemo } from 'react';
import classNames from 'classnames';
import { format, isSameDay } from 'date-fns';
import { ProjectIndicator } from '../../../common/ui/Project/ProjectIndicator';
import { lFormat, tryParseIso } from '../../../utils/date';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { StatusChipText } from '../../../common/ui/Chip/StatusChipText';
import { BULLET, EMDASH } from '../../../utils/utf';
import { shortenText } from '../../../utils/string';
import s from './DayEventList.module.scss';

export function EventCard({ event, className, ...props }) {
  const region = event.region;
  const separator = ' ' + BULLET + ' ';
  return (
    <div className={classNames(s.DayEventList__content, className)} {...props}>
      <DayEventStatus item={event} />
      <div className={s.DayEventList__text}>{event.text}</div>
      <div className={s.DayEventList__type}>
        {event.type} {separator} {region ? region.name : 'федеральное'}
      </div>
      {event.result && event.result.name ? (
        <div className={s.DayEventList__name}>
          Результат: {shortenText(event.result.name, 200)}
        </div>
      ) : null}
    </div>
  );
}

export function DayEventList({ date, events, className, onEventClick }) {
  return (
    <div className={classNames(s.DayEventList, className)}>
      <div className={s.DayEventList__date}>
        <span className={s.DayEventList__capitalize}>
          {lFormat(date, 'eeee')}
        </span>
        {lFormat(date, ', d MMMM')}
      </div>
      {events.length > 0 ? (
        events.map((item, index) => (
          <EventCard
            key={index}
            event={item}
            onClick={() => onEventClick(item)}
          />
        ))
      ) : (
        <div className={s.DayEventList__noData}>
          Нет событий на данный день.
        </div>
      )}
    </div>
  );
}

export const DayEventStatus = ({ item, isControlPoints }) => {
  const [query] = useUrlQuery();
  const calendarDate = tryParseIso(query.date) || new Date();
  const isStart =
    item.date_start &&
    item.date_end &&
    isSameDay(item.date_start, calendarDate);
  const formatPattern = 'dd.MM.yyyy';

  const eventDate = useMemo(() => {
    if (item.date_start && item.date_end) {
      return (
        format(item.date_start, formatPattern) +
        ` ${EMDASH} ` +
        format(item.date_end, formatPattern)
      );
    } else {
      return item.date_start
        ? format(item.date_start, formatPattern)
        : format(item.date_end, formatPattern);
    }
  }, [item.date_end, item.date_start]);

  const additional =
    item.date_start && item.date_end && isControlPoints
      ? isStart
        ? ' • Начало реализации'
        : ' • Конец реализации'
      : '';

  const eventStatusName =
    item.status === ProjectIndicator.SUCCESS
      ? 'Выполнено'
      : item.status === ProjectIndicator.WARN
      ? 'Выполнено с нарушением сроков'
      : item.status === ProjectIndicator.ERROR
      ? 'Не выполнено'
      : 'В процессе выполнения';

  return (
    <div className={s.DayEventList__status}>
      <StatusChipText
        title={`${eventDate} • ${eventStatusName}${additional}`}
        className={classNames(s.chipText)}
        status={item.status}
      />
    </div>
  );
};
