import React from 'react';
import { Tooltip } from 'react-leaflet';
import classNames from 'classnames';

import MarkerIcon from '../../assets/Icons/Map/Marker.svg';
import AdditionalChildren from '../../assets/Icons/Map/additionalChildren.svg';
import School from '../../assets/Icons/Map/school.svg';
import VOIcon from '../../assets/Icons/Map/VO.svg';
import SPOIcon from '../../assets/Icons/Map/SPO.svg';
import Preschool from '../../assets/Icons/Map/preschool.svg';

import DonutPieChart from '../charts/DonutPieChart';
import { formatNumber } from '../../utils/formatNumber';
import { Organization } from '../../data/object';
import { ReactComponent as SpeedIcon } from '../../assets/Icons/Arrow/SpeedIcon.svg';
import { DOWNARROW, UPARROW } from '../../utils/utf';
import { JsxMarker } from './JsxMarker';
import colors from '../../colors.scss';
import s from './MapMarker.module.scss';

const orgType = {
  PRESCHOOL: Preschool,
  SCHOOL: School,
  SPO: SPOIcon,
  VO: VOIcon,
  ADDITIONAL_CHILDREN: AdditionalChildren
};

export function ObjectMarkerIcon({ object }) {
  const getIcon = (iconType) => {
    switch (iconType) {
      case 'PRESCHOOL':
        return <img src={Preschool} alt="PRESCHOOL" />;
      case 'SCHOOL':
        return <img src={School} alt="SCHOOL" />;
      case 'SPO':
        return <img src={SPOIcon} alt="SPO" />;
      case 'VO':
        return <img src={VOIcon} alt="VO" />;
      case 'ADDITIONAL_CHILDREN':
        return <img src={AdditionalChildren} alt="ADDITIONAL_CHILDREN" />;
      default:
        return null;
    }
  };

  return (
    <div className={s.ObjectMarkerIcon}>
      <img src={MarkerIcon} className={s.ObjectMarkerIcon__svg} alt="" />
      <div className={s.ObjectMarkerIcon__orgType}>
        {getIcon(object && object.bs_organization_type_okved_id)}
      </div>
    </div>
  );
}

// todo оставил, но нужно выбрать один из вариантов
export function InternetObjectMarkerIcon({ upload, download }) {
  return (
    <div className={s.ObjectMarkerIcon_internet}>
      <img src={MarkerIcon} className={s.ObjectMarkerIcon__svg} alt="" />
      <div className={classNames(s.ObjectMarkerIcon__text)}>
        <div>
          {formatNumber(upload, 1)} {UPARROW}
        </div>
        <div>
          {formatNumber(download, 1)} {DOWNARROW}
        </div>
      </div>
    </div>
  );
}

export function ObjectClusterIcon({ className, count, color }) {
  return (
    <div className={classNames(className, s.ObjectClusterIcon)}>
      <DonutPieChart
        className={s.ObjectClusterIcon__chart}
        outerRadius={13}
        innerRadius={11}
        data={[{ value: 1, id: 0, color: color || colors.success }]}
        size={28}
      />
      <span className={s.ObjectClusterIcon__text}>
        {formatNumber(count, 0)}
      </span>
    </div>
  );
}

export function InternetSpeedClusterIcon({
  className,
  color,
  upload,
  download
}) {
  return (
    <div className={classNames(className, s.InternetClusterIcon)}>
      <DonutPieChart
        className={s.ObjectClusterIcon__chart}
        outerRadius={13}
        innerRadius={11}
        data={[{ value: 1, id: 0, color: color || colors.success }]}
        size={28}
      />
      <div
        className={classNames(
          s.ObjectClusterIcon__text,
          s.ObjectClusterIcon__internet
        )}
      >
        <div>
          <SpeedIcon className={s.InternetClusterIcon__speedIcon} />{' '}
          {formatNumber(upload, 1)}
        </div>
        <div className={s.ObjectClusterIcon__download}>
          <SpeedIcon
            className={classNames(
              s.InternetClusterIcon__download,
              s.InternetClusterIcon__speedIcon
            )}
          />{' '}
          {formatNumber(download, 1)}
        </div>
      </div>
    </div>
  );
}

export function ObjectMarker({ object, position, onClick }) {
  const coords = position || Organization.getCoordinates(object);
  return coords ? (
    <JsxMarker
      position={coords}
      iconProps={{ iconSize: [32, 39], iconAnchor: [16, 39] }}
      content={<ObjectMarkerIcon object={object} />}
      onClick={() => onClick(object)}
    >
      <Tooltip direction="top" offset={[0, -39]} opacity={1}>
        <span>{object.name_short ? object.name_short : object.name_full}</span>
      </Tooltip>
    </JsxMarker>
  ) : (
    false
  );
}

export function InternetObjectMarker({
  object,
  position,
  onClick,
  upload,
  download,
  color
}) {
  const coords = position || Organization.getCoordinates(object);
  return coords ? (
    <JsxMarker
      position={coords}
      iconProps={{ iconSize: [32, 39], iconAnchor: [16, 39] }}
      // content={<ObjectMarkerIcon object={object} />}
      content={
        <InternetSpeedClusterIcon
          className={s.CountIcon_internet}
          // object={object}
          upload={upload}
          download={download}
          color={color}
        />
      }
      onClick={() => onClick(object)}
    >
      <Tooltip direction="top" offset={[0, -39]} opacity={1}>
        <span>{object.name_short ? object.name_short : object.name_full}</span>
      </Tooltip>
    </JsxMarker>
  ) : (
    false
  );
}

export function CountMarker({ object, onClick }) {
  return (
    <JsxMarker
      position={object.position}
      iconProps={{ iconSize: [40, 40], iconAnchor: [20, 20] }}
      content={
        <ObjectClusterIcon
          className={s.CountIcon}
          count={object.count}
          color={object.color}
        />
      }
      onClick={() => onClick(object.code)}
    >
      <Tooltip direction="top" offset={[0, -20]} opacity={1}>
        <span>{object.name}</span>
      </Tooltip>
    </JsxMarker>
  );
}

export function InternetMarker({ object, onClick, upload, download }) {
  return (
    <JsxMarker
      position={object.position}
      iconProps={{ iconSize: [40, 40], iconAnchor: [20, 20] }}
      content={
        <InternetSpeedClusterIcon
          className={s.CountIcon}
          upload={upload}
          download={download}
          color={object.color}
        />
      }
      onClick={() => onClick(object.code)}
    >
      <Tooltip direction="top" offset={[0, -20]} opacity={1}>
        <span>{object.name}</span>
      </Tooltip>
    </JsxMarker>
  );
}
