import { useCallback, useRef, useState } from 'react';

export function useBaseSearch(search, onSearch, immediate = false) {
  search = search || '';
  const searchRef = useRef(search);

  const handleSearch = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      onSearch(searchRef.current || '');
    },
    [onSearch]
  );

  const handleChange = useCallback(
    (e) => {
      searchRef.current = e.target.value;
      if (immediate) {
        handleSearch();
      }
    },
    [immediate, handleSearch]
  );

  const clearInput = useCallback(() => {
    searchRef.current = '';
    onSearch(null);
  }, [onSearch]);

  return {
    search,
    handleSearch,
    handleChange,
    clearInput
  };
}

export function useSearch(
  queryParams,
  changeQuery,
  replaceQuery = false,
  immediate = false
) {
  const onSearch = useCallback(
    (search) => {
      changeQuery(
        {
          search: search
        },
        replaceQuery
      );
    },
    [changeQuery, replaceQuery]
  );

  return useBaseSearch(queryParams.search, onSearch, immediate);
}

export function useStateSearch(immediate = false) {
  const [search, setSearch] = useState('');
  return useBaseSearch(search, setSearch, immediate);
}
