import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { constants, customComponents, getAdditionalClassnames } from './common';
import s from './Select.module.scss';

const { PRIMARY, DARKER, NORMAL, MENU, SECONDARY, SMALL } = constants;

const selectComponents = {
  DropdownIndicator: customComponents.DropdownIndicator,
  Input: customComponents.Input,
  Option: customComponents.Option,
  Placeholder: customComponents.Placeholder,
  ValueContainer: customComponents.ValueContainer
};

export function Select({
  className,
  clearable,
  defaultValue,
  disabled,
  frwdRef,
  height,
  loading,
  multiple,
  name,
  noOptionsMessage,
  onChange,
  loadingMessage,
  placeholder,
  searchable,
  showPlaceholder,
  values,
  value,
  variant,
  ...props
}) {
  const noOptionsMessageCb = useCallback(() => noOptionsMessage, [
    noOptionsMessage
  ]);
  const loadingMessageCb = useCallback(() => loadingMessage, [loadingMessage]);

  return (
    <div
      className={classNames(
        className,
        s.Select,
        getAdditionalClassnames(variant, height)
      )}
      {...props}
    >
      <ReactSelect
        className="select-container"
        classNamePrefix="select"
        clearable={clearable}
        components={selectComponents}
        defaultValue={defaultValue}
        ref={frwdRef}
        hideSelectedOptions={false}
        isLoading={loading}
        isSearchable={!!searchable}
        isDisabled={!!disabled}
        isMulti={!!multiple}
        // menuIsOpen // debug props
        loadingMessage={loadingMessageCb}
        name={name}
        noOptionsMessage={noOptionsMessageCb}
        onChange={onChange}
        options={values}
        placeholder={placeholder}
        showPlaceholder={showPlaceholder}
        value={value}
      />
    </div>
  );
}

Select.PRIMARY = PRIMARY;
Select.SECONDARY = SECONDARY;
Select.DARKER = DARKER;
Select.MENU = MENU;

Select.SMALL = SMALL;
Select.NORMAL = NORMAL;

Select.propTypes = {
  className: PropTypes.string,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  placeholder: PropTypes.string,
  searchable: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ]),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  variant: PropTypes.string
};

Select.defaultProps = {
  loadingMessage: 'Загрузка',
  placeholder: 'Выберите...',
  showPlaceholder: false,
  noOptionsMessage: 'Не найдено',
  variant: Select.PRIMARY,
  height: Select.NORMAL
};
