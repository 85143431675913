import { formatNumber } from '../../../utils/formatNumber';

export function getCentralChartData(financesData) {
  const { federal, mbt, mbtSubjects } = financesData;

  let { total_budget, budget_limits, cash_expenses, obligations } = federal;

  const dividedTotalBudget = total_budget / 1e9;
  const dividedObligations = obligations / 1e9;

  const notAcceptedObligations = budget_limits - obligations;
  const notAcceptedBudget = total_budget - budget_limits;

  const dividedMbtTotalBudget = mbt.total_budget / 1e9;
  const notAcceptedMBTBudget = mbt.total_budget - mbt.budget_limits;

  return {
    chart: {
      total_budget: dividedTotalBudget,
      mbt_total_budget: dividedMbtTotalBudget,
      pies: [
        {
          data: [
            {
              id: 'accepted_obligations',
              value: dividedObligations
            },
            {
              id: 'not_accepted_obligations',
              value: notAcceptedObligations / 1e9
            }
          ],
          total: dividedTotalBudget
        },
        {
          data: [
            {
              id: 'accepted_budget',
              value: budget_limits / 1e9
            },
            {
              id: 'not_accepted_budget',
              value: notAcceptedBudget / 1e9
            }
          ],
          total: dividedTotalBudget
        },
        {
          data: [
            {
              id: 'cash_expenses',
              value: cash_expenses / 1e9
            }
          ],
          total: dividedTotalBudget
        },
        {
          data: [
            {
              id: 'mbt_total',
              value: mbt.total_budget / 1e9
            }
            // {
            //   id: 'mbt_accepted',
            //   value: null // mbt.budget_limits / 1e9
            // },
            // {
            //   id: 'mbt_not_accepted',
            //   value: null // notAcceptedMBTBudget / 1e9
            // }
          ],
          total: dividedTotalBudget
        },
        {
          data: [
            {
              id: 'mbt_cash_expenses',
              value: mbtSubjects.otfg_kr_total / 1e9 // mbt.cash_expenses / 1e9
            }
          ],
          total: dividedTotalBudget
        }
      ]
    },
    federalTable: [
      {
        label: 'Бюджетные ассигнования',
        value: formatNumber(dividedTotalBudget, 2),
        percent: formatNumber(100, 1)
      },
      {
        id: 'accepted_budget',
        label: 'Доведенные',
        value: formatNumber(budget_limits / 1e9, 2),
        percent: formatNumber((budget_limits / total_budget) * 100, 1)
      },
      {
        id: 'accepted_obligations',
        label: 'Принято бюджетных обязательств',
        hasPadding: true,
        value: formatNumber(dividedObligations, 2),
        percent: formatNumber((obligations / total_budget) * 100, 1)
      },
      {
        id: 'not_accepted_obligations',
        label: 'Не принято бюджетных обязательств',
        hasPadding: true,
        value: formatNumber(notAcceptedObligations / 1e9, 2),
        percent: formatNumber((notAcceptedObligations / total_budget) * 100, 1)
      },
      {
        id: 'not_accepted_budget',
        label: 'Недоведенные',
        value: formatNumber(notAcceptedBudget / 1e9, 2),
        percent: formatNumber((notAcceptedBudget / total_budget) * 100, 1)
      },
      {
        id: 'cash_expenses',
        label: 'Кассовое исполнение',
        value: formatNumber(cash_expenses / 1e9, 2),
        percent: formatNumber((cash_expenses / total_budget) * 100, 1)
      }
    ],
    mbtTable: [
      {
        id: 'mbt_total',
        label: 'План межбюджетных трансфертов',
        value: formatNumber(mbt.total_budget / 1e9, 2),
        percent: 100.0
      },
      {
        id: 'mbt_accepted',
        label: 'Принято субъектами межбюджетных трансфертов',
        value: formatNumber(null, 2),
        percent: formatNumber(null, 1)
      },
      {
        id: 'mbt_cash_expenses',
        label: 'Кассовое исполнение межбюджетных трансфертов субъектами',
        value: formatNumber(mbtSubjects.otfg_kr_total / 1e9, 2),
        percent: formatNumber(
          (mbtSubjects.otfg_kr_total / mbt.total_budget) * 100,
          1
        )
      }
    ]
  };
}
