import React from 'react';
import { ReactComponent as IconReload } from '../assets/Icons/Reload.svg';
import { getErrorProps } from './errorUtil';
import { IconButton } from './ui/IconButton/IconButton';
import s from './ErrorMessage.module.scss';

export function BaseErrorMessage({ text, description, children }) {
  return (
    <div className={s.ErrorMessage}>
      <div>
        {text && <div className={s.ErrorMessage__text}>{text}</div>}
        {description && (
          <div className={s.ErrorMessage__description}>{description}</div>
        )}
        {children && <div className={s.ErrorMessage__children}>{children}</div>}
      </div>
    </div>
  );
}

export function ErrorMessage({ error, onReload, ...props }) {
  return (
    <BaseErrorMessage
      {...getErrorProps(error)}
      children={
        onReload ? (
          <IconButton icon={IconReload} onClick={onReload} />
        ) : (
          undefined
        )
      }
      {...props}
    />
  );
}
