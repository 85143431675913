import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { CSSTransition } from 'react-transition-group';
import { isNumber } from 'lodash-es';
import { useStoreState } from '@proscom/prostore-react';
import { ReactComponent as CloseIcon } from '../../assets/Icons/Close.svg';
import { GridLayout } from '../../common/GridLayout/GridLayout';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { Select } from '../../common/ui/Select/Select';
import { ButtonComponent } from '../../common/ui/Button/Button';
import { useQueryTabs } from '../../common/ui/Tab/TabRadio';
import {
  QUERY_FINANCES_MAIN,
  QUERY_GET_MAIN_SUBJECT_FINANCES
} from '../../graphql/queries/finance';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { NavBarActualityDate } from '../../common/NavBar/NavBarActualityDate';
import { STORE_MOBILE_APP, URL_QUERY_TAB } from '../../store/stores';
import { useWindowSize } from '../../store/useWindowSize';
import { DatePicker } from '../../common/Filters/DatePicker';
import { usePreventBodyOverScroll } from '../../utils/scrollFix';
import ProjectsChartView from './ProjectsChartView/ProjectsChartView';
import { SubjectChartView } from './ProjectsChartView/SubjectChartView';
import {
  pageLevels,
  pageTabs,
  PROJECTS_LEVEL,
  selectionChartParts,
  SUBJECTS_LEVEL
} from './data/constants';
import {
  findDistrictIndex,
  findProjectIndex,
  findSubjectIndex,
  getDistrictCode,
  getProjectCode
} from './data/data';
import { ProjectCardsView } from './CardsView/ProjectCardsView';
import { SubjectCardsView } from './CardsView/SubjectCardsView';
import { GrbsTable } from './GrbsTable';
import { TableInfoButton } from './TableInfoButton';
import { ChartTabs } from './ChartTabs';
import { BudgetAssignmentTable } from './BudgetAssignmentTable';
import { CashExecutionTable } from './CashExecutionTable';
import { getDistrictsData } from './data/getDistrictsData';
import { getFederalProjectsData } from './data/getFederalProjectsData';
import { getCentralChartDataRp } from './data/getCentralChartDataRp';
import { getCentralChartDataVR } from './data/getCentralChartDataVR';
import { getCentralChartData } from './data/getCentralChartData';
import s from './IndexPage.module.scss';

const budgetQueryOptions = {
  query: QUERY_FINANCES_MAIN,
  skipQuery: skipIfNull(null)
};

const subjectsQueryOptions = {
  query: QUERY_GET_MAIN_SUBJECT_FINANCES,
  skipQuery: skipIfNull(null)
};

const MainChartPartPopup = ({ children }) => {
  const mobileAppState = useStoreState(STORE_MOBILE_APP);

  const statusBarHeight = mobileAppState.statusBarHeight;
  const style = useMemo(() => {
    return statusBarHeight
      ? {
          top: statusBarHeight + 'px'
        }
      : {};
  }, [statusBarHeight]);

  return (
    <div className={s.MainChartPartPopup} style={style}>
      <div className={s.MainChartPartPopup__inner}>{children}</div>
    </div>
  );
};

function FadeInTransition({ isOpen, children }) {
  return (
    <CSSTransition
      in={isOpen}
      timeout={200}
      classNames="fade-in"
      mountOnEnter
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
}

export default function IndexPage() {
  const { isMobile } = useWindowSize();
  const [query, changeQuery] = useUrlQuery();
  const selectedNpCode = +query.np;
  const selectedFdCode = query.fd;
  const selectedRegCode = query.region;
  const { yearMonth } = useQueryPeriodFast(query, changeQuery);
  const { createLink } = usePreserveQueryFast(query);

  usePreventBodyOverScroll();

  const financesQuery = useGraphqlQuery({
    queryOptions: budgetQueryOptions,
    variables: yearMonth ? { yearMonth } : null
  });

  const subjectFinancesQuery = useGraphqlQuery({
    queryOptions: subjectsQueryOptions,
    variables: yearMonth ? { yearMonth } : null
  });

  const financesLoading =
    financesQuery.check.spinner || subjectFinancesQuery.check.spinner;

  const [pageLevel, , changeLevel] = useQueryTabs(
    pageLevels,
    ({ id }) => id,
    'level'
  );

  const [pageTab, pageTabProps] = useQueryTabs(
    pageTabs,
    ({ id }) => id,
    URL_QUERY_TAB
  );

  const selectionChartPart = query.chart;
  const isRegionalChart = query.region;
  const isChartSelected = selectionChartParts.has(selectionChartPart);

  const financesData = financesQuery.state.data;

  const subjectFinancesData = subjectFinancesQuery.state.data;
  const centralChartData = useMemo(() => {
    if (!financesData) return null;
    if (pageTab === 'cash_exec') {
      return getCentralChartDataVR(financesData);
    } else {
      return getCentralChartData(financesData);
    }
  }, [financesData, pageTab]);

  const federalProjectsData = useMemo(() => {
    return getFederalProjectsData(financesData);
  }, [financesData]);

  const newData = useMemo(
    () => ({
      central: centralChartData && centralChartData.chart,
      projects: federalProjectsData
    }),
    [centralChartData, federalProjectsData]
  );

  const [activeSegmentId, setActiveSegmentId] = useState(null);

  const handleCloseBtnClick = useCallback(() => {
    setActiveSegmentId(null);
    changeQuery({ chart: undefined });
  }, [setActiveSegmentId, changeQuery]);

  const handleCloseDistrictClick = useCallback(() => {
    changeQuery({ region: undefined });
  }, [changeQuery]);

  const districtsData = useMemo(() => {
    if (!subjectFinancesData) return null;
    return getDistrictsData(subjectFinancesData);
  }, [subjectFinancesData]);

  const centralChartDataRp = useMemo(() => {
    if (!financesData) return null;
    return getCentralChartDataRp(
      query.region ? subjectFinancesData : financesData,
      findDistrictIndex(districtsData, selectedFdCode, 0)
    );
  }, [
    query.region,
    districtsData,
    subjectFinancesData,
    financesData,
    selectedFdCode
  ]);

  let selectedChartTableData = null;
  if (selectionChartPart && centralChartData) {
    if (pageLevel !== SUBJECTS_LEVEL) {
      if (selectionChartPart === 'federal') {
        selectedChartTableData = centralChartData.federalTable;
      } else if (selectionChartPart === 'mbt') {
        selectedChartTableData = centralChartData.mbtTable;
      }
    } else {
      selectedChartTableData = centralChartDataRp?.table;
    }
  }

  // selectionChartPart && centralChartData
  //   ? pageLevel !== SUBJECTS_LEVEL && selectionChartPart === 'federal'
  //     ? centralChartData.federalTable
  //     : pageLevel !== SUBJECTS_LEVEL && selectionChartPart === 'mbt'
  //     ? centralChartData.mbtTable
  //     : centralChartDataRp?.table
  //   : null;

  const newDataRp = useMemo(
    () => ({
      central: centralChartDataRp?.chart,
      districts: districtsData
    }),
    [centralChartDataRp, districtsData]
  );

  const latestStateRef = useRef({});
  const latestState = latestStateRef.current;

  const selectedIndex = (latestState.selectedIndex =
    pageLevel === SUBJECTS_LEVEL
      ? findDistrictIndex(districtsData, selectedFdCode, 0)
      : findProjectIndex(federalProjectsData, selectedNpCode, 0));

  const selectedSubIndex = (latestState.selectedSubIndex =
    pageLevel === SUBJECTS_LEVEL &&
    selectedRegCode &&
    districtsData &&
    findSubjectIndex(districtsData[selectedIndex].regions, selectedRegCode, 0));

  const onChangeProjectsIndex = useCallback(
    (index) => changeQuery({ np: getProjectCode(federalProjectsData, index) }),
    [changeQuery, federalProjectsData]
  );

  const onChangeDistrictsIndex = useCallback(
    (index) => {
      const districtCode = getDistrictCode(districtsData, index);

      changeQuery({
        fd: districtCode,
        region: undefined
      });
    },
    [changeQuery, districtsData]
  );

  const onChangeRegionsIndex = useCallback(
    (index) => {
      changeQuery({
        region: districtsData[selectedIndex].regions[index].data.region.code
      });
    },
    [changeQuery, districtsData, selectedIndex]
  );

  const onCardClick = useCallback(
    (item) => {
      changeQuery({
        region: item.regions[0].data.region.code
      });
    },
    [changeQuery]
  );

  const onHistogramBarClick = useCallback(
    (item, iBar, iSection, e) => {
      if (iSection === selectedIndex) {
        e.stopPropagation();
        onChangeRegionsIndex(iBar);
      }
    },
    [selectedIndex, onChangeRegionsIndex]
  );

  const selectedDistrict =
    pageLevel === SUBJECTS_LEVEL &&
    districtsData &&
    districtsData[selectedIndex];

  const regions = selectedDistrict && districtsData[selectedIndex].regions;

  const handleChangeActiveSegment = useCallback(
    (id) => setActiveSegmentId(activeSegmentId !== id ? id : null),
    [activeSegmentId]
  );

  return (
    <GridLayout
      className={s.MainGridLayout}
      pageClassName={s.MainPage}
      popup={
        <>
          <div
            className={classNames(s.TopBar, {
              [s._opaque]: isChartSelected
            })}
          >
            <div className={s.TopBar__inner}>
              <div className={s.TopBar__date}>
                <DatePicker
                  variant={Select.MENU}
                  className={s.TopBar__selectLayout}
                  inputClassName={s.TopBar__datePicker}
                />
              </div>
              <div className={s.TopBar__section}>
                {pageLevels.map(({ icon: Icon, label, id }, i) => (
                  <ButtonComponent
                    key={i}
                    onMouseUp={() => changeLevel(id)}
                    className={classNames(s.TopBar__item, {
                      [s._active]: pageLevel === id
                    })}
                  >
                    <Icon />
                    <span>{label}</span>
                  </ButtonComponent>
                ))}
              </div>
              {pageLevel === SUBJECTS_LEVEL &&
                selectedRegCode &&
                selectedDistrict && (
                  <button
                    className={classNames(s.CloseBtn, s.TopBar__closeBtn)}
                    onClick={handleCloseDistrictClick}
                  >
                    <CloseIcon />
                    {selectedRegCode &&
                      selectedDistrict &&
                      selectedDistrict.data &&
                      selectedDistrict.data.federal_district.name.replace(
                        'федеральный округ',
                        'ФО'
                      )}
                  </button>
                )}
            </div>
          </div>
          {/*<div className={s.Topbar__background} />*/}
        </>
      }
    >
      {(!isMobile || !isChartSelected) && (
        <>
          <NavBarActualityDate
            date={
              pageLevel === PROJECTS_LEVEL
                ? financesData?.federalDate
                : subjectFinancesData?.subjectDate
            }
          />
          {pageLevel === PROJECTS_LEVEL && (
            <ChartTabs
              pageTabProps={pageTabProps}
              onChangeActiveSegmentId={setActiveSegmentId}
            />
          )}
          <div
            className={classNames(s.IndexPage__chart, {
              [s._selected]: isChartSelected
            })}
          >
            {pageLevel === PROJECTS_LEVEL ? (
              <ProjectsChartView
                activeId={activeSegmentId}
                className={s.ChartView}
                chartClassName={s.ChartView__planets}
                donutsClassName={s.ChartView__donutPieCharts}
                titleClassName={s.ChartView__title}
                onMainChartClick={changeQuery}
                onSegmentClick={setActiveSegmentId}
                isChartViewOpen={isChartSelected}
                newData={newData}
                onChangeIndex={onChangeProjectsIndex}
                selectedChartView={selectionChartPart}
                selectedIndex={selectedIndex}
                planetMode={pageTab}
                isLoading={financesLoading}
              />
            ) : (
              <SubjectChartView
                activeDistrict={
                  query.region && districtsData && districtsData[selectedIndex]
                }
                activeId={activeSegmentId}
                className={s.ChartView}
                onMainChartClick={changeQuery}
                onSegmentClick={setActiveSegmentId}
                isChartViewOpen={isChartSelected}
                newData={newDataRp}
                selectedChartView={selectionChartPart}
                selectedIndex={selectedIndex}
                selectedSubIndex={selectedSubIndex}
                onChangeIndex={onChangeDistrictsIndex}
                onBarClick={onHistogramBarClick}
                isLoading={financesLoading}
              />
            )}
          </div>
          {pageLevel === PROJECTS_LEVEL ? (
            <ProjectCardsView
              className={classNames(s.CardsView, {
                _blurred: isChartSelected
              })}
              data={newData.projects}
              onChangeIndex={onChangeProjectsIndex}
              onCardClick={onCardClick}
              selectedIndex={selectedIndex}
              scrollToClosest={true}
              tab={pageTab}
              isLoading={financesLoading}
            />
          ) : (
            <SubjectCardsView
              className={classNames(s.CardsView, {
                _blurred: isChartSelected
              })}
              selectedRegCode={selectedRegCode}
              data={selectedRegCode ? regions : newDataRp.districts}
              onChangeIndex={
                selectedRegCode ? onChangeRegionsIndex : onChangeDistrictsIndex
              }
              onCardClick={!selectedRegCode && onCardClick}
              selectedIndex={
                isNumber(selectedSubIndex) ? selectedSubIndex : selectedIndex
              }
              scrollToClosest={!selectedRegCode}
              isLoading={financesLoading}
            />
          )}
        </>
      )}

      <FadeInTransition isOpen={isChartSelected}>
        <button
          className={classNames(s.CloseBtn, s.ChartSelected__Btn)}
          onClick={handleCloseBtnClick}
        >
          <CloseIcon />
        </button>
      </FadeInTransition>
      <FadeInTransition isOpen={isChartSelected}>
        <MainChartPartPopup>
          {selectedChartTableData && (
            <>
              {pageLevel === SUBJECTS_LEVEL || pageTab === 'budget_assign' ? (
                <BudgetAssignmentTable
                  activeSegmentId={activeSegmentId}
                  data={selectedChartTableData}
                  onClick={handleChangeActiveSegment}
                />
              ) : pageTab === 'cash_exec' ? (
                <CashExecutionTable
                  activeSegmentId={activeSegmentId}
                  data={selectedChartTableData}
                  onClick={handleChangeActiveSegment}
                />
              ) : (
                false
              )}
              {pageLevel !== SUBJECTS_LEVEL && pageTab === 'budget_assign' && (
                <GrbsTable yearMonth={yearMonth} />
              )}
              {pageLevel !== SUBJECTS_LEVEL && (
                <TableInfoButton
                  createLink={createLink}
                  tab={pageTab}
                  chartPart={selectionChartPart}
                />
              )}
            </>
          )}
        </MainChartPartPopup>
      </FadeInTransition>
    </GridLayout>
  );
}
