export const User = {
  getUser(auth) {
    return auth.authData && auth.authData && auth.authData.user;
  },

  getUserAbbreviation(user) {
    if (!user) return null;
    if (!user.name) return 'п';
    return user.name
      .split(/\s+/)
      .filter((x) => x)
      .map((word) => word[0].toUpperCase())
      .slice(0, 2)
      .join('');
  },

  canAccessMainPage(user) {
    return (
      user &&
      (user.role === User.roles.ADMIN || user.role === User.roles.FEDERAL_ADMIN)
    );
  },

  roles: {
    ADMIN: 'ADMIN',
    FEDERAL_ADMIN: 'FEDERAL_ADMIN',
    REGION_ADMIN: 'REGION_ADMIN'
  }
};
