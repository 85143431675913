import gql from 'graphql-tag';

export const FRAGMENT_ROADMAP_EVENT_DOCUMENT = gql`
  fragment RoadmapEventDocument on RoadmapEventDocumentType {
    id
    name
    document_slot_id
    type
    number
    date_approval
    license_expiration_date
    content
    created_at
    status
    connected_file {
      id
      file_extension
      file_size
      name
    }
  }
`;

export const QUERY_GET_ROADMAP_EVENT_DOCUMENTS = gql`
  query getRoadmapEventDocuments($id: ID!) {
    roadmapEvent(id: $id) {
      id
      name
      name_full
      roadmap_event_document_slots {
        id
        code
        name
        name_full
        federal
        type
      }
      roadmap {
        id
        result {
          id
          name
          name_full
        }
      }
      roadmap_event_documents {
        ...RoadmapEventDocument
      }
      regional_roadmap_events {
        id
        region_id
        fact_term
        status
        roadmap_event_documents {
          ...RoadmapEventDocument
        }
      }
    }
  }

  ${FRAGMENT_ROADMAP_EVENT_DOCUMENT}
`;
