import { EMDASH } from './utf';
import {
  mediaWidthMobile,
  mediaWidthTablet,
  mediaWidthTabletLand
} from '../_vars.scss';
import colors from '../colors.scss';

export const quarters = [
  {
    label: 'I квартал',
    value: 1
  },
  {
    label: 'II квартал',
    value: 2
  },
  {
    label: 'III квартал',
    value: 3
  },
  {
    label: 'IV квартал',
    value: 4
  }
];

export const months = [
  {
    label: 'Январь',
    value: 1
  },
  {
    label: 'Февраль',
    value: 2
  },
  {
    label: 'Март (I)',
    month: 'Март',
    value: 3
  },
  {
    label: 'Апрель',
    value: 4
  },
  {
    label: 'Май',
    value: 5
  },
  {
    label: 'Июнь (II)',
    month: 'Июнь',
    value: 6
  },
  {
    label: 'Июль',
    value: 7
  },
  {
    label: 'Август',
    value: 8
  },
  {
    label: 'Сентябрь (III)',
    month: 'Сентябрь',
    value: 9
  },
  {
    label: 'Октябрь',
    value: 10
  },
  {
    label: 'Ноябрь',
    value: 11
  },
  {
    label: 'Декабрь (IV)',
    month: 'Декабрь',
    value: 12
  }
];

export const getMonthName = (month) => {
  const nmonth = +month;
  const mm = months.find((m) => m.value === +nmonth);
  return mm ? mm.month || mm.label : EMDASH;
};

export const getQuarterName = (quarter) => {
  const qq = quarters.find((q) => q.value === quarter);
  return qq ? qq.label : EMDASH;
};

export const getMonthQuarter = (month) => {
  return Math.floor(month / 3) + 1;
};
export const getCurrentYear = () => new Date().getFullYear();
export const getCurrentQuarter = () => getMonthQuarter(new Date().getMonth());
export const getCurrentMonth = () => new Date().getMonth() + 1;

const currentYear = getCurrentYear();
const currentQuarter = getCurrentQuarter();
const currentMonth = getCurrentMonth();
const maxPossibleYear = 2024;
const maxYear = currentYear <= maxPossibleYear ? currentYear : maxPossibleYear;
const minYear = 2019;

export const yearNumbers = [];
for (let i = minYear; i <= maxPossibleYear; i++) {
  yearNumbers.push(i);
}

export const years = yearNumbers.map((year) => ({
  label: String(year),
  value: year
}));

export const currentYearObject =
  years.find((y) => y.value === currentYear) || years[years.length - 1];
export const currentQuarterObject =
  quarters.find((q) => q.value === currentQuarter) ||
  quarters[quarters.length - 1];
export const currentMonthObject =
  months.find((m) => m.value === currentMonth) || months[months.length - 1];

export const clampYear = (year) =>
  Math.max(Math.min(year, maxPossibleYear), minYear);

export const shortMonthNames = [
  'Янв',
  'Фев',
  'Мар',
  'Апр',
  'Май',
  'Июн',
  'Июл',
  'Авг',
  'Сен',
  'Окт',
  'Ноя',
  'Дек'
];

export function startOfQuarter(yearQuarter) {
  const [year, quarter] = yearQuarter.split('-').map((v) => +v);
  const month = (quarter - 1) * 3;
  return new Date(year, month, 1, 0, 0, 0, 0);
}

export function startOfYearMonth(yearMonth) {
  const [year, month] = yearMonth.split('-').map((v) => +v);
  return new Date(year, month - 1, 1, 0, 0, 0, 0);
}

export function endOfYearMonth(yearMonth) {
  const [year, month] = yearMonth.split('-').map((v) => +v);
  return new Date(year, month, 1, 0, 0, 0, 0);
}

// RESPONSIVE

export function getRemSize() {
  if (typeof window !== 'undefined') {
    return extractPxValue(
      getComputedStyle(window.document.body, null).fontSize
    );
  }
  return 16;
}
export function extractUnitValue(str) {
  const match = str.match(/([\d.]+)(\w+)/);
  if (!match) {
    throw new Error(`Cannot extract value from '${str}'`);
  }
  const value = +match[1];
  const unit = match[2];
  if (Number.isNaN(value) || !unit) {
    throw new Error(`Cannot extract value from '${str}'`);
  }
  return [value, unit];
}
export function extractPxValue(str) {
  const [value, unit] = extractUnitValue(str);
  if (unit === 'rem' || unit === 'em') {
    return value * getRemSize();
  } else if (unit === 'px') {
    return value;
  } else {
    throw new Error(
      `Unknown unit '${unit}' when extracting px value from '${str}'`
    );
  }
}

export const MEDIA_QUERIES = {
  mobile: extractPxValue(mediaWidthMobile),
  tablet: extractPxValue(mediaWidthTablet),
  tablet_land: extractPxValue(mediaWidthTabletLand)
};

export const RatingStatus = {
  SUCCESS: 'success',
  WARN: 'warn',
  ERROR: 'error'
};

export const getStatusColor = (status) => {
  if (status === null) {
    return colors.gray;
  }
  return colors[status] || colors.success;
};

/**
 * Выдает статус в зависимости от процентов
 * @param value
 * @returns {string || null}
 */
export const STATUS_TYPES = {
  RATING: 'rating',
  COMPLETION: 'completion'
};
export function getStatusByValue(value, statusType = STATUS_TYPES.RATING) {
  if (value !== 0 && !value) {
    return null;
  }

  let parsedValue = value;
  if (typeof parsedValue === 'string') {
    parsedValue = Number.parseInt(parsedValue);
    if (Number.isNaN(parsedValue)) {
      return null;
    }
  }

  let status = RatingStatus.ERROR;

  if (statusType === STATUS_TYPES.RATING) {
    if (value > 80) {
      status = RatingStatus.SUCCESS;
    } else if (value > 50 && value <= 80) {
      status = RatingStatus.WARN;
    }
  } else if (statusType === STATUS_TYPES.COMPLETION) {
    if (value === 100) {
      status = RatingStatus.SUCCESS;
    } else if (value >= 50 && value < 100) {
      status = RatingStatus.WARN;
    }
  }

  return status;
}

export function getStatusTitle(status) {
  switch (status) {
    case RatingStatus.SUCCESS:
      return 'Положительно';
    case RatingStatus.WARN:
      return 'Умеренно';
    case RatingStatus.ERROR:
      return 'Критично';
    default:
      return 'Нет данных';
  }
}
