import React from 'react';
import classNames from 'classnames';
import { ButtonComponent } from '../ui/Button/Button';
import { ReactComponent as Arrow } from '../../assets/Icons/Arrow.svg';
import { StatusChipText } from '../ui/Chip/StatusChipText';
import { RatingStatus } from '../../utils/constants';
import { RoadmapEventStatus } from '../../data/roadmapEvent';
import s from './RegionDropdownTitle.module.scss';

export function RegionDropdownTitle({
  title,
  opened,
  setOpened,
  overdue,
  className,
  iconClassName,
  regionalEvent
}) {
  const regionDocumentsCount =
    regionalEvent?.roadmap_event_documents?.length ?? null;

  const status = regionalEvent?.status;

  return (
    <ButtonComponent
      className={classNames(s.TopMenu, className)}
      onClick={() => setOpened(!opened)}
    >
      <div className={s.TopMenu__title}>
        <div className={s.TopMenu__name}>
          {title}
          {regionDocumentsCount ? (
            <span className={s.TopMenu__count}>{regionDocumentsCount}</span>
          ) : null}
        </div>
        <div
          className={classNames(s.TopMenu__icon, iconClassName, {
            [s._active]: opened
          })}
        >
          <Arrow />
        </div>
      </div>
      <div className={s.TopMenu__number}>
        <StatusChipText
          className={s.TopMenu__chip}
          status={
            status === RoadmapEventStatus.COMPLETED
              ? RatingStatus.SUCCESS
              : status === RoadmapEventStatus.APPROVAL
              ? RatingStatus.WARN
              : overdue
              ? RatingStatus.ERROR
              : null
          }
          title={
            status === RoadmapEventStatus.COMPLETED
              ? 'Выполнено'
              : overdue
              ? 'Не выполнено'
              : 'В процессе выполнения'
          }
        />
      </div>
    </ButtonComponent>
  );
}
