import { getMonthQuarter, yearNumbers } from './constants';

export function isPeriodYear(period) {
  return period && period.start.match(/-01-01/) && period.end.match(/-12-31/);
}

export function isPeriodQuarter(period) {
  return (
    period &&
    ((period.start.match(/-01-01/) && period.end.match(/-03-31/)) ||
      (period.start.match(/-04-01/) && period.end.match(/-06-30/)) ||
      (period.start.match(/-07-01/) && period.end.match(/-09-30/)) ||
      (period.start.match(/-10-01/) && period.end.match(/-12-31/)))
  );
}

export function createQuarterValues() {
  return [].concat(
    ...yearNumbers.map((year) =>
      [1, 2, 3, 4].map((quarter) => ({ year, quarter }))
    )
  );
}

export function fillQuarterValues(quarterValues, values, dataFn) {
  if (!values) return;
  for (let value of values) {
    if (isPeriodYear(value.period)) {
      const year = +value.period.start.slice(0, 4);
      const iStart = (year - yearNumbers[0]) * 4;
      if (iStart >= 0 && iStart + 4 <= quarterValues.length) {
        for (let i = 0; i < 4; i++) {
          quarterValues[iStart + i] = {
            ...quarterValues[iStart + i],
            ...dataFn(value),
            year,
            quarter: i + 1
          };
        }
      }
    } else if (isPeriodQuarter(value.period)) {
      const year = +value.period.start.slice(0, 4);
      const month = +value.period.start.slice(5, 7);
      const quarter = getMonthQuarter(+month);
      const i = (year - yearNumbers[0]) * 4 + quarter - 1;
      quarterValues[i] = {
        ...quarterValues[i],
        ...dataFn(value),
        year,
        quarter
      };
    }
  }
}

export function mapQuarterValues(quarterValues, activeYear) {
  return quarterValues.map((value) => {
    const { year, quarter } = value;
    return {
      id: `${year}-${quarter}`,
      name: quarter === 1 ? year + ' г.' : undefined,
      title: `${quarter} квартал ${year}`,
      values: [value.value_number, value.value_fact],
      active: year === activeYear
    };
  });
}
