import React, { useEffect } from 'react';
import { useBudgetChartRp } from '../../IndicatorsPage/useBudgetChartRp';
import { ResizeContainer } from '../../../common/ResizeContainer';
import { ChartViewChart } from '../../../common/ChartView/ChartView';
import { queryLoader } from '../../../common/QueryLoader';
import { ReportChartView } from './ReportChartView';

export function InfographicChartsRp({
  federalProject,
  regions,
  results,
  year,
  month,
  yearMonth,
  onLoad = () => {}
}) {
  const {
    typesCharts,
    sourcesCharts,
    budgetData,
    budgetQuery
  } = useBudgetChartRp({
    filter: {
      federalProjectCodes: federalProject
        ? [federalProject.project_code]
        : undefined,
      resultIds: results ? results.map((r) => r.id) : undefined,
      regionIds: regions.map((r) => r.id)
    },
    yearMonth
  });

  useEffect(() => {
    if (!budgetQuery.check.spinner) {
      onLoad(true);
    }
  }, [budgetQuery.check.spinner, onLoad]);

  return (
    <div>
      <ReportChartView
        title={`Региональный бюджет в разрезе видов расходов`}
        year={year}
        month={month}
      >
        {queryLoader(budgetQuery) ||
          (typesCharts && (
            <ResizeContainer>
              {({ width }) => (
                <ChartViewChart
                  data={typesCharts}
                  legend
                  width={width}
                  height={192}
                />
              )}
            </ResizeContainer>
          ))}
      </ReportChartView>
      <ReportChartView
        title={`Региональный бюджет по источникам финансирования`}
        year={year}
        month={month}
      >
        {queryLoader(budgetQuery) ||
          (sourcesCharts && (
            <ResizeContainer>
              {({ width }) => (
                <ChartViewChart
                  data={sourcesCharts}
                  legend
                  width={width}
                  height={192}
                />
              )}
            </ResizeContainer>
          ))}
      </ReportChartView>
    </div>
  );
}
