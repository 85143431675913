import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from '../ui/Button/Button';
import { ReactComponent as FilterMenu } from '../../assets/Icons/FiltersMenu.svg';
import { Portal } from '../portal';
import { PageTitle } from '../PageTitle/PageTitle';
import { formatNumber } from '../../utils/formatNumber';
import s from './FiltersPopup.module.scss';

export function FiltersPopup({
  filtersCount,
  title,
  buttonClassName,
  portalClassName,
  children
}) {
  const [isActive, setActive] = useState(false);

  return (
    <>
      <Button
        className={classNames(s.FilterMenu, buttonClassName)}
        variant={Button.DARKER}
        onClick={() => setActive(true)}
      >
        Настройки <FilterMenu className={s.FilterMenu__svg} />
        {filtersCount !== undefined ? (
          <div className={s.FilterMenu__count}>
            {formatNumber(filtersCount, 0)}
          </div>
        ) : null}
      </Button>
      {isActive && (
        <Portal id="CalendarFiltersPopup">
          <div className={classNames(s.ModalFilterMenu, portalClassName)}>
            <div className={s.ModalFilterMenu__content}>
              <PageTitle
                className={s.ModalFilterMenu__PageTitle}
                text={title}
                onClick={() => setActive(false)}
              />
              {children}
              <Button
                className={s.ModalFilterMenu__button}
                onClick={() => setActive(false)}
              >
                Применить
              </Button>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}
