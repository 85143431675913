import React from 'react';
import classNames from 'classnames';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import { HorizontalScroll } from '../../../common/HorizontalScroll/HorizontalScroll';
import { CircleProgressIndicator } from '../../ui/ProgressIndicator/CircleProgressIndicator';
import { formatValueUnit } from '../../../utils/formatNumber';
import { ActualityDate } from '../../NavBar/ActualityDate';
import s from './Inventory.module.scss';

export const Inventory = ({
  className,
  items,
  loading,
  actualityDate,
  isMobile
}) => {
  return (
    <div className={classNames(s.Inventory, className)}>
      <PageSubTitle className={s.Inventory__title} text={'Инвентаризация'} />
      {loading && <CircleProgressIndicator />}
      {!loading && items && (
        <div className={s.Inventory__scrollWrapper}>
          <HorizontalScroll
            className={s.Inventory__scroll}
            listClassName={s.Inventory__list}
            buttonPosition={HorizontalScroll.INSIDE}
            disableArrow={isMobile}
            scrollItems={isMobile ? 1 : 3}
          >
            {items.map((item, i) => (
              <div key={i} className={s.Inventory__item}>
                <div className={s.Inventory__itemValue}>
                  <p>{formatValueUnit(item.value, item.unit, item.decimals)}</p>
                </div>
                <div className={s.Inventory__itemDesc}>
                  <p>{item.desc}</p>
                </div>
                <ActualityDate
                  className={s.Inventory__actualityDate}
                  date={actualityDate}
                  variant={ActualityDate.Variant.DESCRIPTION}
                />
              </div>
            ))}
          </HorizontalScroll>
        </div>
      )}
      {!loading && !items && 'Нет данных'}
    </div>
  );
};
