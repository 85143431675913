import React from 'react';
import PropTypes from 'prop-types';
import { line, max as d3max, scaleLinear } from 'd3';
import classNames from 'classnames';
import { isValidNumber } from '../../../utils/math';
import { BaseTooltip } from '../../ui/ToolTip/BaseTooltip';
import { NumberTooltip } from '../../ui/ToolTip/NumberTooltip';
import s from './LineChart.module.scss';

const lineCreator = line()
  .x((i) => i.x)
  .y((i) => i.y);

/**
 * @param data
 * @param indices - Настройки
 * @param total - Максимальное значение
 * @param sizeHeight - Общая высота
 * @param sizeWidth - Общая ширина
 * @param paddingTop - Отступ сверху до графика
 * @param paddingBottom - Отступ снизу до графика
 * @param paddingLeft - Отступ слева до графика
 * @param className - Дополнительные стили
 * @param paddingRight - Отступ справа от графика
 * @param actualityDate
 * @param isCircleHidden - Показывать ли кружки только при наведении (Также есть
 * доп настройка в indices: {showCircles}, которая влияет только на
 * определенную линию). isCircleHidden является приорететной.
 * @param lineStrokeWidth - ширина линий между точками (в indices есть доп настройки
 * определенных линий)
 * @param circleStrokeWidth - ширина оконтовки точек
 * @param circleRadius - внутренний радиус точек
 * @param style
 * @param props
 * @returns {null|*} - Если не переданно никаких данных, то возвращается null, иначе график.
 * @constructor
 */
function LineChart({
  data,
  indices,
  total,
  sizeHeight,
  sizeWidth,
  paddingTop,
  paddingBottom,
  paddingLeft,
  className,
  paddingRight,
  actualityDate,
  isCircleHidden,
  lineStrokeWidth,
  circleStrokeWidth,
  circleRadius,
  style,
  ...props
}) {
  const chartWidth = sizeWidth - paddingLeft - paddingRight;
  const chartHeight = sizeHeight - paddingTop - paddingBottom;

  const valueInterpolatorX = scaleLinear()
    .domain([0, data.length - 1])
    .range([paddingLeft, sizeWidth - paddingRight]);

  const totalY = total || d3max(data, (d) => d3max(d.values, (d) => d)) || 1;
  const valueInterpolatorY = scaleLinear()
    .domain([0, totalY])
    .range([sizeHeight - paddingBottom, paddingTop]);

  const indicesData = indices.map((item, index) => {
    const points = [];

    const paths = [];

    let path = [];
    for (let iD = 0; iD < data.length; iD++) {
      const d = data[iD];

      let point = null;

      if (isValidNumber(d.values[index])) {
        point = {
          id: d.id,
          name: d.name,
          x: valueInterpolatorX(iD),
          y: valueInterpolatorY(d.values[index] || 0)
        };
        path.push(point);
        points.push(point);
      } else {
        if (path.length > 0) {
          paths.push(path);
        }
        path = [];
        points.push(null);
      }
    }
    if (path.length > 0) {
      paths.push(path);
    }

    const d = paths.map((p) => lineCreator(p)).join('');

    return {
      ...item,
      path: d,
      points
    };
  });

  const itemsData = data.map((item, iItem) => {
    if (data.length === 1) return null;

    const points = indicesData
      .filter((index) => index.points[iItem])
      .map((index) => ({
        showCircles: index.showCircles,
        color: index.color,
        x: index.points[iItem].x,
        y: index.points[iItem].y
      }));

    const itemData = {
      id: item.id,
      lineX: valueInterpolatorX(iItem),
      y: paddingTop,
      height: chartHeight,
      name: item.name,
      active: item.active,
      title: item.title,
      actualityDate: item.actualityDate || actualityDate,
      tooltipData: indicesData.map((index, iIndex) => {
        return {
          id: item.id,
          color: index.color,
          unit: index.unit,
          decimals: index.decimals,
          name: index.name,
          title: item.title,
          value: item.values[iIndex]
        };
      }),
      points
    };

    const prevX = valueInterpolatorX(iItem - 1);
    const currentX = valueInterpolatorX(iItem);
    const nextX = valueInterpolatorX(iItem + 1);

    if (iItem === 0) {
      itemData.x = currentX;
      itemData.width = (nextX - currentX) / 2;
    } else if (iItem === data.length - 1) {
      itemData.x = (currentX + prevX) / 2;
      itemData.width = (currentX - prevX) / 2;
    } else {
      itemData.x = (currentX + prevX) / 2;
      itemData.width = (nextX - prevX) / 2;
    }

    return itemData;
  });

  return (
    <svg
      className={className}
      style={{
        ...style,
        width: sizeWidth,
        height: sizeHeight
      }}
      viewBox={`${0} ${0} ${sizeWidth} ${sizeHeight}`}
      {...props}
    >
      {indicesData.map((item, iItem) => (
        <path
          key={iItem}
          fill="none"
          strokeLinecap="round"
          strokeWidth={
            lineStrokeWidth
              ? lineStrokeWidth
              : item.strokeWidth
              ? item.strokeWidth
              : 2
          }
          stroke={item.color}
          d={item.path}
        />
      ))}
      {itemsData.map((item, iItem) => (
        <BaseTooltip
          key={iItem}
          tooltip={
            <NumberTooltip
              title={item.title || item.name}
              data={item.tooltipData}
              actualityDate={item.actualityDate}
            />
          }
        >
          <g className={s.LineChart__bar} name={item.name}>
            <rect
              className={classNames(s.LineChart__rect, {
                [s._active]: item.active
              })}
              x={item.x}
              y={item.y}
              fillOpacity={0}
              width={item.width}
              height={item.height}
            />
            <line
              className={s.LineChart__line}
              x1={item.lineX}
              y1={item.y}
              x2={item.lineX}
              y2={item.y + item.height}
              fill="none"
              stroke="black"
              strokeDasharray="14,4"
              strokeWidth={1}
            />
            {item.points.map((point, iPoint) => (
              <g key={iPoint} name={point.name}>
                <circle
                  className={classNames(s.LineChart__circle, {
                    [s.circleHidden]: !point.showCircles || isCircleHidden
                  })}
                  stroke={point.color}
                  strokeWidth={circleStrokeWidth}
                  cx={point.x}
                  cy={point.y}
                  r={circleRadius}
                />
              </g>
            ))}
          </g>
        </BaseTooltip>
      ))}
    </svg>
  );
}

LineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.number)
    })
  ),
  indices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      unit: PropTypes.node,
      decimals: PropTypes.number,
      strokeWidth: PropTypes.number,
      showCircles: PropTypes.bool
    })
  ),
  total: PropTypes.number,
  className: PropTypes.string,
  sizeWidth: PropTypes.number,
  sizeHeight: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  circleRadius: PropTypes.number,
  isCircleHidden: PropTypes.bool
};

LineChart.defaultProps = {
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 10,
  paddingRight: 10,
  circleStrokeWidth: 6,
  circleRadius: 6
};

export default function LineChartSafe(props) {
  if (!props.data) {
    return null;
  }

  return <LineChart {...props} />;
}
