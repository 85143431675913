import { useRegions } from '../../../store/useRegions';
import { useSearchArrayQuery } from './_util';

export function useSearchRegions(search) {
  const regionsQuery = useRegions();
  return useSearchArrayQuery(
    regionsQuery,
    search,
    (d) => d.name_full || d.name
  );
}
