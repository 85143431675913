import React, { useEffect, useState } from 'react';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';
import { MEDIA_QUERIES } from '../utils/constants';
import { createBatcher } from './createBatcher';

const queueSubjectUpdate = createBatcher();

export function convertToBehaviorSubject(obs$, initialValue) {
  const subj$ = new BehaviorSubject(initialValue);
  subj$.destroy = obs$.subscribe((v) => subj$.next(v));
  return subj$;
}

export function useSubject(obs$) {
  const [state, setState] = useState(obs$.value);
  useEffect(() => {
    let active = true;
    const sub = obs$.subscribe((value) => {
      queueSubjectUpdate(() => active && setState(value));
    });
    return () => {
      active = false;
      sub.unsubscribe();
    };
  }, [obs$]);
  return state;
}

export const DEVICE_TABLET = 'tablet';
export const DEVICE_TABLET_LAND = 'tabletLand';
export const DEVICE_MOBILE = 'mobile';
export const DEVICE_DESKTOP = 'desktop';

export function getDeviceType(windowWidth) {
  if (windowWidth <= MEDIA_QUERIES.mobile) {
    return DEVICE_MOBILE;
  } else if (windowWidth <= MEDIA_QUERIES.tablet) {
    return DEVICE_TABLET;
  } else if (windowWidth <= MEDIA_QUERIES.tablet_land) {
    return DEVICE_TABLET_LAND;
  } else {
    return DEVICE_DESKTOP;
  }
}

function createWindowStore() {
  const obs$ = fromEvent(window, 'resize').pipe(
    auditTime(100),
    map(() => ({ window }))
  );

  return convertToBehaviorSubject(obs$, { window });
}

export const window$ = createWindowStore();

function getWindowSize(ws) {
  const window = ws.window;
  const device = getDeviceType(window.innerWidth);
  console.log(device);

  return {
    window,
    isMobile: device === DEVICE_MOBILE,
    isTablet: device === DEVICE_TABLET,
    isTabletLand: device === DEVICE_TABLET_LAND
  };
}

function createWindowSizeStore(window$, getWindowSize) {
  const initialValue = getWindowSize(window$.value);
  const result$ = window$.pipe(map(getWindowSize));

  return convertToBehaviorSubject(result$, initialValue);
}

export const windowSize$ = createWindowSizeStore(window$, getWindowSize);

export function useWindowSize() {
  return useSubject(windowSize$);
}
