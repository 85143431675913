import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import ResizeObserver from 'react-resize-observer';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { useStore } from '@proscom/prostore-react';
import { STORE_MOBILE_APP } from '../../store/stores';
import s from './SwipeBox.module.scss';

export function SwipeBox({
  className,
  headClassName,
  innerClassName,
  contentClassName,
  children,
  isOpened,
  onClose
}) {
  const [mobileAppState, mobileAppStore] = useStore(STORE_MOBILE_APP);
  const [boxHeight, setBoxHeight] = useState(0);
  const boxRef = useRef();
  const headRef = useRef();
  const contentRef = useRef();
  const scrollRef = useRef();
  const topBarRef = useRef();
  const lastData = useRef({ boxHeight, isOpened });

  const statusBarHeight = mobileAppState.statusBarHeight;
  const updateSize = useCallback(() => {
    if (headRef.current && contentRef.current) {
      const boxHeight =
        headRef.current.clientHeight + contentRef.current.clientHeight;
      let windowHeight = window.innerHeight;
      if (!!topBarRef.current) {
        windowHeight = windowHeight - topBarRef.current.clientHeight - 8;
      }
      if (statusBarHeight) {
        windowHeight = windowHeight - statusBarHeight;
      }
      setBoxHeight(Math.min(windowHeight, boxHeight));
    }
  }, [statusBarHeight]);

  useLayoutEffect(() => {
    topBarRef.current = document.getElementsByClassName('topbar')[0];
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  useEffect(() => {
    const topBar = document.getElementsByClassName('topbar');
    const navBar = document.getElementsByClassName('navbar');
    const elements = [...topBar, ...navBar];
    elements.forEach((el) => {
      el.addEventListener('click', onClose);
    });
    return () => {
      elements.forEach((el) => {
        el.removeEventListener('click', onClose);
      });
    };
  }, [onClose]);

  // Инициализация аниматора
  const [{ marginTop }, setSpring] = useSpring(() => ({
    marginTop: isOpened ? -boxHeight : 0
  }));

  // Инициализация drag'n'drop
  const bind = useDrag(({ movement, down, velocity }) => {
    const yDelta = movement[1] > 0 ? movement[1] : 0;
    let options = {
      marginTop: isOpened ? (down ? -boxHeight + yDelta : -boxHeight) : 0
    };
    // Если пользователь отпустил ЛКМ, и хватило усилий, то вызываем
    // callback закрытия и передаем в useSpring состояние закрытия
    if (!down) {
      if (velocity > 0.8 || yDelta > boxHeight / 3) {
        options = {
          marginTop: 0
        };
        onClose();
      }
    }

    setSpring(options);
  });

  // При обновлении состояния, вызывается анимированное изменение позиции
  useLayoutEffect(() => {
    const {
      boxHeight: lastBoxHeight,
      isOpened: lastIsOpened
    } = lastData.current;
    lastData.current.boxHeight = boxHeight;
    lastData.current.isOpened = isOpened;
    setSpring({
      marginTop: isOpened ? -boxHeight : 0,
      /**
       * Когда пользователь открывает клавиатуру в мобильном приложении,
       * происходит ресайз окна. Если при этом открыт SwipeBox, то есть
       * неприятное последствие в виде свайпбокса, который резко поменял
       * свою высоту и плавно съезжает на новое место.
       * Поэтому в таких случаях (когда высота бокса резко меняется при
       * открытом боксе) мы пропускаем анимацию смещения по вертикали
       */
      immediate:
        lastBoxHeight !== boxHeight &&
        lastIsOpened &&
        isOpened &&
        lastBoxHeight > 0
    });
    if (isOpened) {
      document.documentElement.classList.add('swipebox-opened');
    } else {
      document.documentElement.classList.remove('swipebox-opened');
    }
  }, [boxHeight, isOpened, setSpring]);

  return (
    <Fragment>
      <div
        className={classNames(s.SwipeBox__offset, {
          [s._opened]: isOpened
        })}
        onClick={onClose}
      />
      <animated.div
        className={classNames(s.SwipeBox, className)}
        style={{
          height: boxHeight,
          transform: marginTop.interpolate((m) => `translate(0, ${m}px`)
        }}
        ref={boxRef}
      >
        <div className={classNames(s.SwipeBox__inner, innerClassName)}>
          <div
            {...bind()}
            className={classNames(s.SwipeBox__head, headClassName)}
            ref={headRef}
          >
            <div className={s.SwipeBox__swiper} />
          </div>
          <div className={s.SwipeBox__scroll} ref={scrollRef}>
            <div
              className={classNames(s.SwipeBox__content, contentClassName)}
              ref={contentRef}
            >
              {children}
              <ResizeObserver onResize={updateSize} />
            </div>
          </div>
        </div>
      </animated.div>
    </Fragment>
  );
}
