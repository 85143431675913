import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  color as dColor,
  interpolateHcl,
  max as d3max,
  scaleLinear,
  sum as d3sum
} from 'd3';
import { createSVGGradient } from '../../../utils/colors';
import { isValidNumber } from '../../../utils/math';
import { BaseTooltip } from '../../ui/ToolTip/BaseTooltip';
import { NumberTooltip } from '../../ui/ToolTip/NumberTooltip';
import s from './StackedBarChart.module.scss';

/**
 *
 * @param data - данные для графика
 * @param total - максимальное значение графика
 * @param size - высота графика
 * @param sizeWidth - ширина графика
 * @param barWidth - ширина колонки
 * @param barGap - отступ между колонками
 * @param color - общий цвет графика
 * @param wrapperClassName
 * @param legendItemClassName - css класс для графика
 * @param hideLegend - Скрывать ли легенду
 * @param onSelectedBar - функция при нажатии на колонку
 * @param textColor - цвет текста
 * @param isSegmented - Сегментированны ли значения в колонки. (Отсчет каждого
 *    сегметна в графике начинается с 0 или с позиции прошлого значения)
 * @param {{id: string, colors: {offset: number, color: string}[] }[]} linearGradientLibrary - создание градиентов, которые используются
 * для окрашивания колонок
 * @param props - Другие данные, пробрасываемые в svg компонент
 * @returns {*}
 */
const StackedBarChart = React.memo(
  ({
    data,
    total,
    size,
    barWidth,
    barGap,
    color,
    sizeWidth,
    wrapperClassName,
    legendItemClassName,
    hideLegend,
    onSelectedBar,
    textColor,
    isSegmented,
    barBackgroundOptions,
    linearGradientLibrary,
    actualityDate: actualityDateProp,
    ...props
  }) => {
    if (sizeWidth) {
      if (!barWidth) {
        barWidth = (sizeWidth - barGap * (data.length - 1)) / data.length;
      } else if (!barGap) {
        barGap = (sizeWidth - barWidth * data.length) / (data.length - 1);
      }
    }

    const createRect = ({ id, barId, data, ...additional }) => {
      const { x, y, height, linearGradientId, color: elementColor } = data;
      return (
        <rect
          fill={
            linearGradientLibrary && linearGradientId
              ? `url(#${linearGradientId})`
              : elementColor
          }
          key={`StackedBarChart_${barId}_${id}`}
          x={x}
          y={y}
          width={barWidth}
          height={height}
          {...additional}
        />
      );
    };

    const LinearGradient = createSVGGradient(linearGradientLibrary, {
      reCalcPosition: true
    });

    const onClickBar = (data) => {
      if (typeof onSelectedBar === 'function') {
        onSelectedBar(data);
      }
    };

    let colorInterpolator = () => s.defaultColor;

    if (color) {
      const opacityColor = dColor(color);
      opacityColor.opacity = 0;
      const endColor = opacityColor.formatRgb();

      // Интерполятор цветов
      colorInterpolator = interpolateHcl(color, endColor);
    }

    const totalValue = isValidNumber(total)
      ? total
      : d3max(data, (col) => d3sum(col.values, (item) => item.value));

    const topPadding = !hideLegend ? 24 : 0;
    const svgSize = size - topPadding;
    const valueInterpolator =
      totalValue === 0
        ? (v) => (v > 0 ? svgSize : 0)
        : scaleLinear()
            .domain([0, totalValue])
            .range([0, svgSize]);

    const barData = data.map(
      ({ id: barId, name, values, actualityDate, title }, index) => {
        let y = svgSize;
        const tooltipData = [];
        let valuesData = values;
        if (!isSegmented) {
          valuesData = [...values].sort((item, nextItem) =>
            item.value > nextItem.value ? -1 : 1
          );
        }

        let sum = 0;

        const barRects = valuesData.map(
          ({ id, name, color, linearGradientId, value }, barIndex) => {
            const height = valueInterpolator(value);
            const data = {
              x: barWidth * index + barGap * index,
              y: y - height,
              height,
              color: color
                ? color
                : colorInterpolator(barIndex / values.length),
              linearGradientId
            };
            y = isSegmented ? y - height : y;
            tooltipData.push({
              id: id,
              name: name,
              value: value,
              color: color
                ? color
                : colorInterpolator(barIndex / values.length),
              decimal: 2
            });

            sum += value;

            return createRect({
              id,
              barId,
              data
            });
          }
        );

        tooltipData.reverse();

        if (isSegmented && tooltipData.length > 1) {
          tooltipData.push({
            id: tooltipData.length + 1,
            name: 'Итоговая сумма',
            value: sum
          });
        }

        let defaultBarOptions = {
          id: `backgroundRect_${barId}`,
          barId: '',
          data: {
            x: barWidth * index + barGap * index,
            y: 0,
            height: svgSize
          },
          fillOpacity: 0
        };

        if (barBackgroundOptions) {
          defaultBarOptions = {
            ...defaultBarOptions,
            fillOpacity: 1,
            ...barBackgroundOptions
          };
        }

        const backgroundRect = createRect(defaultBarOptions);

        return (
          <BaseTooltip
            key={barId}
            tooltip={
              <NumberTooltip
                data={tooltipData}
                title={title}
                actualityDate={actualityDate || actualityDateProp}
              />
            }
          >
            <g
              key={`StackedBarChart_${barId}`}
              onClick={() => onClickBar({ barId, name, values })}
            >
              {backgroundRect}
              {barRects}
            </g>
          </BaseTooltip>
        ); // `;
      }
    );

    const totalWidth =
      sizeWidth || data.length * barWidth + data.length * barGap - barGap;
    return (
      <div
        className={classNames(s.StackedBarChart, wrapperClassName, {
          [s._padding]: !hideLegend
        })}
      >
        <svg
          style={{
            width: sizeWidth,
            height: svgSize
          }}
          preserveAspectRatio="xMinYMid meet"
          viewBox={`${0} ${0} ${totalWidth} ${svgSize}`}
          {...props}
        >
          <defs>{LinearGradient}</defs>
          {barData}
        </svg>
        {!hideLegend ? (
          <div className={s.StackedBarChart__legend}>
            {data.map((item, index) => {
              return (
                <div
                  key={item.id}
                  className={classNames(
                    s.StackedBarChart__legendItem,
                    legendItemClassName,
                    `_i${index}`
                  )}
                  style={{
                    width: barWidth,
                    left: barWidth * index + barGap * index,
                    color: textColor
                  }}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
);

StackedBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string,
          value: PropTypes.number.isRequired,
          color: PropTypes.string,
          linearGradientId: PropTypes.string
        })
      )
    })
  ),
  linearGradientLibrary: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      rotate: PropTypes.number,
      colors: PropTypes.arrayOf(
        PropTypes.shape({
          offset: PropTypes.number,
          color: PropTypes.string,
          opacity: PropTypes.number
        })
      )
    })
  ),
  total: PropTypes.number,
  size: PropTypes.number,
  barWidth: PropTypes.number,
  barGap: PropTypes.number,
  textColor: PropTypes.string,
  className: PropTypes.string,
  isSegmented: PropTypes.bool
};

StackedBarChart.defaultProps = {
  color: s.defaultColor,
  textColor: s.defaultTextColor,
  size: 100,
  barWidth: 24,
  barGap: 8,
  isSegmented: true
};

export default StackedBarChart;
