import React, { useMemo } from 'react';
import classNames from 'classnames';
import { color as dColor, interpolateHcl } from 'd3';
import colors from '../../colors.scss';
import s from './common.module.scss';

export function IndicatorsLegendItem({ color, name }) {
  return (
    <div className={s.Legend__item}>
      <div
        className={s.Legend__color}
        style={{
          backgroundColor: color
        }}
      />
      <div className={s.Legend__title}>{name}</div>
    </div>
  );
}

export function IndicatorsLegend({
  legends,
  className,
  color,
  isMinimum = false
}) {
  const colorInterpolator = useMemo(() => {
    const opacityColor = dColor(color || colors.black);
    opacityColor.opacity = 0;
    const endColor = opacityColor.formatRgb();
    return interpolateHcl(endColor, color || colors.black);
  }, [color]);

  if (!legends) return null;

  return (
    <div className={classNames(s.IndicatorsLegend__legend, className)}>
      {legends.map((item, i) => {
        return (
          <IndicatorsLegendItem
            key={i}
            name={item}
            color={colorInterpolator((i + 1) / legends.length)}
          />
        );
      })}
      {isMinimum && (
        <div className={s.legend__minumum}>
          <svg>
            <line
              x1={0}
              x2={16}
              y1={0}
              y2={0}
              strokeDasharray={'3, 3'}
              strokeWidth={3}
              stroke={'black'}
            />
          </svg>
          <p>Минимальное значение</p>
        </div>
      )}
    </div>
  );
}

IndicatorsLegend.propTypes = {};
