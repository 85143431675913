import { useEffect } from 'react';
import ym from 'react-yandex-metrika';
import { useStoreState } from '@proscom/prostore-react';
import { STORE_AUTH } from './store/stores';
import { User } from './data/user';

export function UserTracker() {
  const auth = useStoreState(STORE_AUTH);
  const user = User.getUser(auth);

  useEffect(() => {
    if (ym) {
      if (user) {
        ym('userParams', {
          UserID: user.id,
          regionId: user.region?.id,
          organizationId: user.organization?.id,
          regionCode: user.region_code_nalog,
          role: user.role,
          roles: user.roles?.map((r) => r?.id)
        });
      }
    }
  }, [user]);

  return null;
}
