import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './TooltipCommon.module.scss';

export function TextTooltip({ text, className }) {
  return <div className={classNames(s.TooltipChart, className)}>{text}</div>;
}

TextTooltip.propTypes = {
  text: PropTypes.string
};
