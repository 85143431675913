import React from 'react';
import classNames from 'classnames';
import { formatNumber } from '../../../utils/formatNumber';
import { unificationNumber } from '../../../utils/math';
import { CardSmallLayout } from './CardSmallLayout';
import s from './CardSmallLayout.module.scss';

// todo propTypes
export function CardCharts({
  title,
  data,
  loading,
  layoutClassName,
  to,
  layoutProps,
  headCells,
  ...props
}) {
  return (
    <div className={s.CardCharts} {...props}>
      <CardSmallLayout
        spinnerColor={'#fff'}
        spinnerBGColor={'dark'}
        loading={loading}
        to={to}
        className={classNames(s.CardCharts__background, layoutClassName)}
        {...layoutProps}
      >
        <div className={s.CardCharts__title}>{title}</div>
        <div className={s.CardCharts__main}>
          <div className={s.CardCharts__main__total}>
            {formatNumber(data.brought, 1)}
          </div>
          <div className={s.CardCharts__about}>
            <div className={s.CardCharts__main__percent}>
              {formatNumber((data.brought / data.budget) * 100, 1)}%
            </div>
            <div className={s.CardCharts__main__title}>Доведено ЛБО</div>
          </div>
        </div>
        <div className={s.CardCharts__bottom}>
          <div className={s.CardCharts__bottom__item}>
            <div className={s.CardCharts__bottom__percent}>
              {formatNumber(data.accepted, 1)} |{' '}
              {formatNumber((data.accepted / data.brought) * 100, 1)}%
            </div>
            <div className={s.CardCharts__bottom__title}>Принято БО</div>
          </div>
          <div className={s.CardCharts__bottom__item}>
            <div className={s.CardCharts__bottom__percent}>
              {unificationNumber(formatNumber(data.notAccepted, 1), null)} |{' '}
              {unificationNumber(
                formatNumber((data.notAccepted / data.brought) * 100, 1),
                null
              )}
              %
            </div>
            <div className={s.CardCharts__bottom__title}>Не принято БО</div>
          </div>
        </div>
      </CardSmallLayout>
    </div>
  );
}
