import { useCallback, useMemo, useRef } from 'react';
import { useFederalDistricts } from '../store/useFederalDistricts';

const allFederalDistrictsOption = {
  label: 'Все ФО',
  value: '0'
};

export function useQueryDistrict(query, changeQuery) {
  const initialStateRef = useRef(allFederalDistrictsOption);
  const districtsQuery = useFederalDistricts();
  const districtValues = useMemo(() => {
    const result = [allFederalDistrictsOption];

    if (districtsQuery.state.data) {
      result.push(
        ...districtsQuery.state.data.map((districtValue) => ({
          label: districtValue.name,
          value: districtValue.id
        }))
      );
    }

    return result;
  }, [districtsQuery.state.data]);

  const queryDistrict = query.district || '0';
  const district = useMemo(() => {
    const district = districtValues.find(
      (item) => item.value === queryDistrict
    );

    return {
      label: queryDistrict
        ? district
          ? district.label
          : '...'
        : initialStateRef.current.label,
      value: queryDistrict || initialStateRef.current.value
    };
  }, [queryDistrict, districtValues]);

  const setDistrict = useCallback(
    (option) => {
      changeQuery(
        {
          district: option.value
        },
        true
      );
    },
    [changeQuery]
  );

  return {
    district,
    districts: districtValues,
    setDistrict
  };
}
