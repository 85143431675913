import React from 'react';
import classNames from 'classnames';
import { ChipText } from '../../common/ui/Chip/ChipText';
import { ReactComponent as Check } from '../../assets/Icons/Check.svg';
import { ReactComponent as Reload } from '../../assets/Icons/Reload.svg';
import { ReactComponent as Close } from '../../assets/Icons/Close.svg';
import s from './ReportPage.module.scss';

export const getColor = (status) => {
  switch (status) {
    case InfographicChip.SUCCESS:
      return s._success;
    case InfographicChip.WARN:
      return s._warn;
    case InfographicChip.ERROR:
      return s._error;
    default:
      return s._default;
  }
};

export const getIcon = (status) => {
  switch (status) {
    case InfographicChip.SUCCESS:
      return Check;
    case InfographicChip.WARN:
      return Reload;
    case InfographicChip.ERROR:
      return Close;
    default:
      return Reload;
  }
};

export const getTitle = (status) => {
  switch (status) {
    case InfographicChip.SUCCESS:
      return 'Выполнено в срок';
    case InfographicChip.WARN:
      return 'Предстоящее мероприятие';
    case InfographicChip.ERROR:
      return 'Не выполнено в срок';
    default:
      return 'Предстоящее мероприятие';
  }
};

function InfographicChip({ status }) {
  return (
    <ChipText
      title={getTitle(status)}
      className={classNames(getColor(status), s.RoadmapEvent__status)}
      icon={getIcon(status)}
    />
  );
}

InfographicChip.SUCCESS = 'success';
InfographicChip.WARN = 'warn';
InfographicChip.ERROR = 'error';
InfographicChip.DEFAULT = 'default';

export default InfographicChip;
