import React from 'react';
import classNames from 'classnames';
import s from './MapPageLayout.module.scss';

export function MapPageLayout({ pageClassName, className, children }) {
  return (
    <div className={classNames(s.PageOffset, pageClassName)}>
      <div className={classNames(s.PageLayout, className, s[className])}>
        {children}
      </div>
    </div>
  );
}
