import { compareAsc } from 'date-fns';
import { get } from 'lodash-es';
import { apiUrl } from '../config';
import { getRandomInteger } from './math';
import { tryParseIso } from './date';

export function singleItemArray(item, defaultValue = []) {
  return item ? [item] : defaultValue;
}

export function getFirst(array) {
  return (array && array[0]) || null;
}

export const getRandomNumbersArray = (length, min = 0, max = 101) =>
  new Array(length).fill(null).map(() => getRandomInteger(min, max));

export function tryParseJson(json, defaultValue = null) {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error('Error parsing json', json, e);
    return defaultValue;
  }
}

/**
 * Компаратор для сортировки по возрастанию
 */
export const compareSimple = (a, b) => (a < b ? -1 : a > b ? 1 : 0);

export function compareNumericStrings(a, b, dir = 1) {
  const aNumber = +a;
  const bNumber = +b;
  const aNaN = isNaN(aNumber);
  const bNaN = isNaN(bNumber);
  if (aNaN && bNaN) {
    return a < b ? -dir : a > b ? dir : 0;
  }
  if (aNaN) return 1;
  if (bNaN) return -1;
  return aNumber < bNumber ? -dir : aNumber > bNumber ? dir : 0;
}

export function compareDateStrings(a, b) {
  const aDate = a && tryParseIso(a);
  const bDate = b && tryParseIso(b);
  if (aDate && bDate) {
    return compareAsc(aDate, bDate);
  }

  if (!aDate) return 1;
  if (!bDate) return -1;
  return 0;
}

export function makeComparator(compFn, ...fieldFns) {
  return (a, b) => {
    for (const fieldFn of fieldFns) {
      const compRes = compFn(fieldFn(a), fieldFn(b));
      if (compRes !== 0) return compRes;
    }
    return 0;
  };
}

export function getStatusCount(data, status) {
  return ((data && data.find((s) => s.status === status)) || {}).count || 0;
}

export function mapNumber(n, cb) {
  const result = [];
  for (let i = 0; i < n; i++) {
    result.push(cb(i));
  }
  return result;
}

export function getFileLinksFromQuery(query) {
  const data = query.state.data;
  if (!data) {
    return [];
  }

  const urlBase = apiUrl;
  const downloadLinks = [
    {
      fileName: 'docx',
      url: data.docxUrl && urlBase + '/' + data.docxUrl,
      cookieLoader: true
    },
    {
      fileName: 'pdf',
      url: data.pdfUrl && urlBase + '/' + data.pdfUrl,
      cookieLoader: true
    },
    {
      fileName: 'xls',
      url: data.xlsUrl && urlBase + '/' + data.xlsUrl,
      cookieLoader: true
    }
  ];
  return downloadLinks.filter((linkOption) => linkOption.url);
}

export function getDataName(data) {
  if (!data) {
    return null;
  }
  return data.name_full || data.name;
}

export function joinNonEmpty(array, glue) {
  return array.filter((x) => x).join(glue);
}

export const findItemByFiledName = (data, matchValue, field, defaultValue) => {
  if (!data || !Array.isArray(data)) {
    return defaultValue;
  }
  const item = data.find((item) => get(item, field) === matchValue);
  if (item) {
    return item;
  }
  return defaultValue;
};
