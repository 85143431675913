import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import composeRefs from '@seznam/compose-react-refs';
import { ReactComponent as SearchIcon } from '../../../assets/Icons/Search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/Icons/Close.svg';
import { Button } from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';
import { useWindowSize } from '../../../store/useWindowSize';
import { mediaWidthSmallMobile } from '../../../_vars.scss';
import s from './SearchInput.module.scss';

const DARKER = 'DARKER';
const SMALL = 'SMALL';

export function SearchInput({
  placeholder,
  variant,
  className,
  onChange,
  size,
  disabled,
  isCloseBtn: isClearBtn,
  onCloseBtnClick: onClearBtnClick,
  isSearchBtn,
  onSearchBtnClick,
  value,
  defaultValue,
  fwdInputRef,
  ...props
}) {
  const { window } = useWindowSize();
  const [visibleCloseBtn, setVisibleCloseBtn] = useState(!!defaultValue);
  const inputRef = useRef(null);

  useEffect(() => {
    setVisibleCloseBtn(!!value);
  }, [value]);

  useEffect(() => {
    inputRef.current.value = defaultValue || '';
    setVisibleCloseBtn(!!defaultValue);
  }, [defaultValue]);

  const handleChangeInput = (event) => {
    const value = event.target.value;
    setVisibleCloseBtn(!!value);
    if (typeof onChange === 'function') {
      onChange(event);
    }
  };

  const handleClearClick = (event) => {
    inputRef.current.value = '';
    setVisibleCloseBtn(false);
    if (typeof onClearBtnClick === 'function') {
      onClearBtnClick(event);
    }
  };

  return (
    <div
      className={classNames(className, s.searchInput, {
        [s._darker]: variant === DARKER,
        [s._small]: size === SMALL,
        [s._withSearchBtn]: isSearchBtn
      })}
    >
      <div className={s.searchInput__inputBox}>
        <SearchIcon className={s.searchInput__icon} />
        <input
          ref={composeRefs(inputRef, fwdInputRef)}
          onChange={handleChangeInput}
          placeholder={placeholder}
          className={s.searchInput__input}
          value={value}
          defaultValue={defaultValue}
          disabled={disabled}
          {...props}
        />
        {isClearBtn && visibleCloseBtn && !disabled ? (
          <IconButton
            icon={CloseIcon}
            className={s.searchInput__close}
            onClick={handleClearClick}
          />
        ) : null}
      </div>
      {isSearchBtn && (
        <Button
          type="submit"
          className={s.searchInput__searchBtn}
          onClick={onSearchBtnClick}
        >
          {window.innerWidth <= parseInt(mediaWidthSmallMobile) ? (
            <SearchIcon />
          ) : (
            'Найти'
          )}
        </Button>
      )}
    </div>
  );
}

SearchInput.DARKER = DARKER;

SearchInput.SMALL = SMALL;

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isCloseBtn: PropTypes.bool,
  onCloseBtnClick: PropTypes.func,
  size: PropTypes.oneOf([SMALL]),
  variant: PropTypes.oneOf([DARKER])
};

SearchInput.defaultProps = {
  placeholder: 'Введите...',
  isCloseBtn: true
};
