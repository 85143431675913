import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { max as d3max } from 'd3-array';
import { Select } from '../../../common/ui/Select/Select';
import { ProgressBar } from '../../../common/ProgressBar/ProgressBar';
import { Button } from '../../../common/ui/Button/Button';
import { ReactComponent as ArrowRight } from '../../../assets/Icons/Arrow/Front.svg';
import { DownloadButton } from '../../../common/ui/DownloadButton/DownloadButton';
import { TabRadio, useStateTabs } from '../../../common/ui/Tab/TabRadio';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { INDICATOR_DC_COMPLETION, indicators } from '../../../utils/indicators';
import { usePreserveQuery } from '../../../store/usePreserveQuery';
import {
  getCurrentMonth,
  getCurrentYear,
  getStatusByValue,
  STATUS_TYPES,
  yearNumbers
} from '../../../utils/constants';
import {
  compareNumericStrings,
  compareSimple,
  makeComparator
} from '../../../utils/data';
import { ProgressView, russia } from '../ProgressView/ProgressView';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { QUERY_GET_FINANCES_YEARLY } from '../../../graphql/queries/finance';
import { tryParseIso } from '../../../utils/date';
import { useRegionsWithRProjects } from '../../../store/useRegions';
import { useFederalProjects } from '../../../store/useFederalProjects';
import color from '../../../colors.scss';
import s from './IndicatorsView.module.scss';

const TOP = 'TOP';
const LOW = 'LOW';

const Ratings = ({ regionsAndRatings }) => {
  const { createLink } = usePreserveQuery();
  const [tab, getTabProps] = useStateTabs([TOP, LOW]);
  const [select, setSelect] = useState(indicators[1]);

  const sortConditional =
    tab === 'TOP'
      ? (item, nextItem) =>
          compareNumericStrings(
            item.ratings[select.value],
            nextItem.ratings[select.value],
            -1
          )
      : (item, nextItem) =>
          compareNumericStrings(
            item.ratings[select.value],
            nextItem.ratings[select.value],
            1
          );

  const sortedRegionsAndRatings =
    regionsAndRatings.state.data &&
    regionsAndRatings.state.data.regions &&
    regionsAndRatings.state.data.regions.slice().sort(sortConditional);

  return (
    <div className={s.ratings}>
      <div className={s.ratings__title}>
        <TabRadio {...getTabProps(TOP)}>Лидеры</TabRadio>
        <TabRadio {...getTabProps(LOW)}>Отстающие</TabRadio>
      </div>
      <Select
        className={s.ratings__select}
        values={indicators}
        onChange={setSelect}
        value={select}
      />
      <div className={s.ratings__list}>
        {regionsAndRatings.check.spinner ? (
          <CircleProgressIndicator />
        ) : (
          sortedRegionsAndRatings &&
          sortedRegionsAndRatings
            .slice(0, 3)
            .map(
              (item, index) =>
                item.region && (
                  <ProgressBar
                    className={s.ratings__progressBar}
                    key={item.region.code}
                    value={item.ratings[select.value]}
                    text={
                      (tab === 'TOP'
                        ? index + 1
                        : sortedRegionsAndRatings.length - index) +
                      '. ' +
                      item.region.name
                    }
                    statusType={
                      select.value === INDICATOR_DC_COMPLETION
                        ? STATUS_TYPES.COMPLETION
                        : STATUS_TYPES.RATING
                    }
                  />
                )
            )
        )}
      </div>
      <Button
        className={s.ratings__additional}
        to={createLink(`/indicators/ratings`, {
          direction: tab === 'TOP' ? 'desc' : 'asc',
          indicator: select.value
        })}
      >
        Подробнее о рейтингах
      </Button>
    </div>
  );
};

const progressFinancesQueryOptions = {
  query: QUERY_GET_FINANCES_YEARLY,
  skipQuery: skipIfNull(null)
};

export const IndicatorsView = React.memo(function IndicatorsView({
  regionsAndRatings,
  onOpenGoals,
  fpFilesPath,
  className
}) {
  const regions = useRegionsWithRProjects();
  const [query, changeQuery] = useUrlQuery();

  const regionsForSelect = useMemo(() => {
    if (!regions.state.data) return [];
    return [
      russia,
      ...regions.state.data
        .sort(makeComparator(compareSimple, (a) => a.name.toLowerCase()))
        .map((item) => ({
          label: item.name,
          value: item.code_nalog,
          region: item
        }))
    ];
  }, [regions]);

  const regionCode = +query.region_code || null;
  const selectedRegion = useMemo(() => {
    if (!regions.state.data) {
      return russia;
    }

    const result =
      regionCode && regionsForSelect.find((item) => +item.value === regionCode);

    return result || russia;
  }, [regionCode, regions.state.data, regionsForSelect]);

  const federalProjectCode = query.fp;
  const currentYear = +query.year || getCurrentYear();
  const financesQuery = useGraphqlQueryVars({
    queryOptions: progressFinancesQueryOptions,
    variableCreator: [
      () =>
        selectedRegion && selectedRegion.region
          ? {
              region_ids: selectedRegion.region.id,
              budget_levels: 'subject',
              intersect_mbt: 'intersect'
            }
          : {
              budget_levels: 'federal'
            },
      [federalProjectCode, selectedRegion]
    ]
  });
  const financesData = financesQuery.state.data;

  const actualityDate = financesData && tryParseIso(financesData.actualityDate);

  // По июнь включительно = false
  const isAfterJune = query.month ? query.month > 6 : getCurrentMonth() > 6;

  const financesChartData = useMemo(() => {
    if (!financesData || !financesData.data) return null;

    const currentYearData = financesData.data.find(
      (row) => row.year === currentYear
    );

    const bars = yearNumbers.map((year) => {
      const item = financesData.data.find((row) => row.year === year);
      if (!item) {
        return {
          id: year,
          title: year,
          values: [],
          actualityDate
        };
      }

      const statusColor = getStatusByValue(
        isAfterJune
          ? currentYearData && currentYearData.cash_expenses_percent
          : currentYearData && currentYearData.obligations_percent
      );

      return {
        id: year,
        title: year,
        values: [
          {
            id: 0,
            name: 'Кассовое исполнение',
            value: item.cash_expenses / 1e6,
            color: color[statusColor ? `${statusColor}Lighten3` : 'grayLighten']
          },
          {
            id: 1,
            name: 'Прин. БО',
            value: item.obligations / 1e6,
            color: color[statusColor ? `${statusColor}Lighten2` : 'grayLighten']
          },
          {
            id: 2,
            name: 'План',
            value: item.total_budget / 1e6,
            color: color[statusColor ? `${statusColor}Lighten` : 'grayLighten']
          }
        ],
        actualityDate
      };
    });

    const max = d3max(bars, (b) => d3max(b.values, (v) => v.value));

    return {
      max,
      bars,
      cashExpensesPercent:
        currentYearData && currentYearData.cash_expenses_percent,
      obligationsPercent: currentYearData && currentYearData.obligations_percent
    };
  }, [financesData, currentYear, isAfterJune, actualityDate]);

  const backgroundColor =
    financesChartData &&
    getStatusByValue(
      isAfterJune
        ? financesChartData.cashExpensesPercent
        : financesChartData.obligationsPercent
    );

  return (
    <div className={classNames(s.IndicatorsView, className)}>
      <ProgressView
        className={s.IndicatorsView__finance}
        loadingData={financesQuery.check.spinner}
        regionsForSelect={regionsForSelect}
        selectedRegion={selectedRegion}
        financesChartData={financesChartData}
        backgroundColor={backgroundColor}
        isAfterJune={isAfterJune}
      />
      <Ratings regionsAndRatings={regionsAndRatings} />
      <div className={s.IndicatorsView__additional}>
        <Button
          onClick={onOpenGoals}
          variant={Button.SECONDARY}
          className={s.IndicatorsView__goals}
        >
          Цели и показатели
          <ArrowRight className={s.IndicatorsView__svg} />
        </Button>
        <DownloadButton
          className={s.IndicatorsView__download}
          popupPosition={'left'}
          data={fpFilesPath.np}
          size={DownloadButton.LARGE}
        />
      </div>
    </div>
  );
});
