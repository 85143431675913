import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './Tag.module.scss';

const getStatus = (status) => {
  switch (status) {
    case Tag.SUCCESS:
      return s._success;
    case Tag.WARN:
      return s._warn;
    case Tag.ERROR:
      return s._error;
    default:
      return '';
  }
};

export function Tag({ value, status, className, ...props }) {
  return (
    <div {...props} className={classNames(s.tag, className, getStatus(status))}>
      {value}
    </div>
  );
}

Tag.SUCCESS = 'success';
Tag.WARN = 'warn';
Tag.ERROR = 'error';

Tag.propTypes = {
  value: PropTypes.any,
  className: PropTypes.string,
  status: PropTypes.oneOf(['success', 'warn', 'error'])
};
