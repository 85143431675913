import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { IconButton } from '../../../common/ui/IconButton/IconButton';
import { ReactComponent as BackIcon } from '../../../assets/Icons/Arrow/Back.svg';
import { ButtonComponent } from '../../../common/ui/Button/Button';
import { ReactComponent as ArrowIcon } from '../../../assets/Icons/Arrow.svg';
import { ReactComponent as ArrowFrontIcon } from '../../../assets/Icons/Arrow/Front.svg';
import { useWindowSize } from '../../../store/useWindowSize';
import { BackButtonBox } from '../../../common/ui/BackButton/BackButton';
import { shortenText } from '../../../utils/string';
import { ReactComponent as InfoIcon } from '../../../assets/Icons/Info.svg';
import { NBSP } from '../../../utils/utf';
import Dropdown from '../../../common/ui/Dropdown/Dropdown';
import { SwipeBox } from '../../../common/SwipeBox/SwipeBox';
import { ParagraphedText } from '../../../common/ParagraphedText';
import s from './Title.module.scss';

export const Description = ({ description }) => {
  if (!description) return null;

  return (
    <>
      <Dropdown
        className={s.Description}
        top={({ opened, setOpened }) => (
          <ButtonComponent
            className={s.Description__button}
            onClick={() => {
              setOpened(!opened);
            }}
          >
            Описание результата
            <ArrowIcon
              className={classNames(s.Description__ArrowIcon, {
                [s._active]: opened
              })}
            />
          </ButtonComponent>
        )}
      >
        {({ opened }) =>
          opened && (
            <div className={s.Description__text}>
              <ParagraphedText text={description} />
            </div>
          )
        }
      </Dropdown>
    </>
  );
};

const TitleComponent = ({ title, onClick, hideButton, currentRegion }) => {
  return (
    <div className={s.Title__title}>
      <div className={s.Title__name}>
        {title}
        {!hideButton ? (
          <IconButton
            className={s.Title__icon}
            onClick={onClick}
            icon={InfoIcon}
          />
        ) : null}
      </div>
      {currentRegion ? <div>{currentRegion}</div> : null}
    </div>
  );
};

export function Title({
  title: titleProp,
  splitLength = 85,
  currentRegion,
  resultName,
  description,
  to,
  onGoBack,
  onPopupOpen
}) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;
  const [tooltip, setTooltip] = useState(null);

  const adjustedTitle = titleProp || '...';
  const splitedTitle = shortenText(adjustedTitle, splitLength);

  const [title, setTitle] = useState(false);

  const isSplited = splitedTitle !== adjustedTitle;

  return (
    <div className={s.Title}>
      <div className={s.Title__header}>
        {isMobile ? (
          <BackButtonBox onClick={onGoBack} className={s.BackButtonBox} />
        ) : (
          <IconButton
            className={s.Title__backIcon}
            icon={BackIcon}
            onClick={onGoBack}
          />
        )}
        {isMobile ? (
          <TitleComponent
            title={splitedTitle}
            hideButton={!isSplited}
            onClick={() => setTooltip(true)}
            currentRegion={currentRegion}
          />
        ) : (
          <TitleComponent
            title={title ? adjustedTitle : splitedTitle}
            hideButton={!isSplited}
            onClick={() => {
              setTitle(!title);
            }}
            currentRegion={currentRegion}
          />
        )}
      </div>
      {resultName ? (
        <div className={s.Title__resultName}>
          {resultName}
          {NBSP}
          <Link to={to} className={s.Title__resultName_link}>
            Подробнее
          </Link>
        </div>
      ) : null}

      {isMobile ? (
        <ButtonComponent
          className={s.Description__button}
          onClick={() => setTooltip(true)}
        >
          Описание результата
          <ArrowFrontIcon className={s.Description__ArrowIcon} />
        </ButtonComponent>
      ) : (
        <Description description={description} />
      )}

      {isMobile ? (
        <SwipeBox
          isOpened={tooltip}
          onClose={() => setTooltip(false)}
          contentClassName={s.popup}
        >
          <div className={s.Title__swipeBox}>
            <h1>{adjustedTitle}</h1>
            <ParagraphedText text={description} />
          </div>
        </SwipeBox>
      ) : null}
    </div>
  );
}
