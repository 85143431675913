import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import s from './LineProgressIndicator.module.scss';

export function LineProgressIndicator({ className }) {
  return (
    <div className={classNames(s.linearProgressMaterial, className)}>
      <div className={classNames(s.bar, s.bar1)} />
      <div className={classNames(s.bar, s.bar2)} />
    </div>
  );
}

LineProgressIndicator.propTypes = {};
