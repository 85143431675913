import React from 'react';
import { ProjectSelector } from '../../ui/Project/ProjectSelector';
import { HorizontalScroll } from '../../HorizontalScroll/HorizontalScroll';
import { useWindowSize } from '../../../store/useWindowSize';
import { ProjectsFilter } from '../../Filters/ProjectsFilter';
import { DatePicker } from '../../Filters/DatePicker';
import s from './TopBarProjectsContent.module.scss';

export const TopBarProjectsContent = ({
  projects,
  selectedFpCode,
  onProjectClick,
  ...props
}) => {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  return (
    <div className={s.TopBar__filters} {...props}>
      <div className={s.TopBar__year}>
        <DatePicker />
      </div>
      <div className={s.TopBar__projectsWrapper}>
        {projects &&
          (isMobile ? (
            <ProjectsFilter
              projects={projects}
              onProjectChange={onProjectClick}
              selectedFpCode={selectedFpCode}
            />
          ) : (
            <HorizontalScroll
              containerCN={s.TopBar__HorizontalScroll}
              listCN={s.TopBar__projects}
              buttonPosition={HorizontalScroll.OUTSIDE}
              buttonPositionRight={HorizontalScroll.INSIDE}
              disableArrow={isMobile}
              scrollItems={isMobile ? 1 : 3}
            >
              {projects.map((project, i) => {
                const number = +project.project_code;
                return (
                  <div key={i} className={s.TopBar__project}>
                    <ProjectSelector
                      className={s.TopBar__projectSelector}
                      title={project.name_full}
                      active={selectedFpCode === number}
                      number={number}
                      onClick={() => onProjectClick(project)}
                    />
                  </div>
                );
              })}
            </HorizontalScroll>
          ))}
      </div>
    </div>
  );
};
