import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { QUERY_MAIN_GRBS } from '../../graphql/queries/finance';
import { formatNumber, formatValueUnit } from '../../utils/formatNumber';
import { queryLoader } from '../../common/QueryLoader';
import { compareSimple, makeComparator } from '../../utils/data';
import s from './IndexPage.module.scss';

function GrbsTableView({ data }) {
  if (!data) return null;
  return (
    <table className={s.ChartTable}>
      <thead>
        <tr>
          <th className={s.ChartTable__headerCell}>ГРБС</th>
          <th className={s.ChartTable__headerCell}>План</th>
          <th className={s.ChartTable__headerCell}>Принято бюдж. обяз.></th>
          <th className={s.ChartTable__headerCell}>Кассовое исполнение</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, iRow) => {
          const tdcn = classNames(
            s.ChartTable__mainText,
            s.ChartTable__cell,
            s.ChartTable__active
          );

          return (
            <tr key={iRow} className={classNames(s.ChartTable__row)}>
              <td className={tdcn} style={{ width: '100%' }}>
                {row.grbs.name_full}
              </td>
              <td className={tdcn}>{formatNumber(row.total_budget / 1e9)}</td>
              <td className={tdcn}>
                {formatNumber(row.obligations / 1e9)} <br />
                {formatValueUnit(
                  (row.obligations / row.total_budget) * 100,
                  '%',
                  1
                )}
              </td>
              <td className={tdcn}>
                {formatNumber(row.cash_expenses / 1e9)} <br />
                {formatValueUnit(
                  (row.cash_expenses / row.total_budget) * 100,
                  '%',
                  1
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const grbsQueryOptions = {
  query: QUERY_MAIN_GRBS,
  skipQuery: skipIfNull(null)
};

export function GrbsTable({ yearMonth }) {
  const grbsQuery = useGraphqlQuery({
    queryOptions: grbsQueryOptions,
    variables: yearMonth ? { yearMonth } : null
  });

  const grbsData = grbsQuery.state.data;

  const grbsSorted = useMemo(
    () =>
      grbsData?.grbs
        .slice()
        .sort(makeComparator(compareSimple, (a) => a.grbs.code)),
    [grbsData]
  );

  return queryLoader(grbsQuery) || <GrbsTableView data={grbsSorted} />;
}
