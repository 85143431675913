import {
  MUTATION_ROADMAP_DOCUMENT_TOKEN,
  MUTATION_ROADMAP_FILE_TOKEN
} from '../graphql/mutations/roadmap';
import {
  MUTATION_FINANCIAL_REPORT_DOCUMENT_TOKEN,
  MUTATION_INFOGRAPHICS_EVENT_REPORT_DOCUMENT_TOKEN,
  MUTATION_INTERNET_REPORT_DOCUMENT_TOKEN,
  MUTATION_OPERATIONAL_REPORT_DOCUMENT_TOKEN,
  MUTATION_REFERENCE_EVENT_REPORT_DOCUMENT_TOKEN,
  MUTATION_REFERENCE_REPORT_DOCUMENT_TOKEN,
  MUTATION_RESULTS_REPORT_DOCUMENT_TOKEN,
  MUTATION_SUBJECT_REPORT_DOCUMENT_TOKEN
} from '../graphql/mutations/reports';

export const reportScopeType = {
  REFERENCE_DOCUMENT_FINANCIAL: 'REFERENCE_DOCUMENT_FINANCIAL',
  REFERENCE_DOCUMENT_INTERNET: 'REFERENCE_DOCUMENT_INTERNET',
  REFERENCE_DOCUMENT_SUBJECTS: 'REFERENCE_DOCUMENT_SUBJECTS',
  REFERENCE_DOCUMENT_RESULTS: 'REFERENCE_DOCUMENT_RESULTS',
  REFERENCE_DOCUMENT_OPERATIONAL: 'REFERENCE_DOCUMENT_OPERATIONAL',
  REFERENCE_DOCUMENT_REPORT: 'REFERENCE_DOCUMENT_REPORT',
  REFERENCE_DOCUMENT_EVENTS_REPORT: 'REFERENCE_DOCUMENT_EVENTS_REPORT',
  REFERENCE_DOCUMENT_INFOGRAPHICS_PDF: 'REFERENCE_DOCUMENT_INFOGRAPHICS_PDF'
};

export const reportTypeMutation = {
  [reportScopeType.REFERENCE_DOCUMENT_FINANCIAL]: {
    mutation: MUTATION_FINANCIAL_REPORT_DOCUMENT_TOKEN,
    dataPath: 'createReferenceFinancialDocumentOneTimeToken'
  },
  [reportScopeType.REFERENCE_DOCUMENT_INTERNET]: {
    mutation: MUTATION_INTERNET_REPORT_DOCUMENT_TOKEN,
    dataPath: 'createReferenceInternetDocumentOneTimeToken'
  },
  [reportScopeType.REFERENCE_DOCUMENT_SUBJECTS]: {
    mutation: MUTATION_SUBJECT_REPORT_DOCUMENT_TOKEN,
    dataPath: 'createReferenceSubjectsDocumentOneTimeToken'
  },
  [reportScopeType.REFERENCE_DOCUMENT_RESULTS]: {
    mutation: MUTATION_RESULTS_REPORT_DOCUMENT_TOKEN,
    dataPath: 'createReferenceResultsDocumentOneTimeToken'
  },
  [reportScopeType.REFERENCE_DOCUMENT_OPERATIONAL]: {
    mutation: MUTATION_OPERATIONAL_REPORT_DOCUMENT_TOKEN,
    dataPath: 'createReferenceOperationalDocumentOneTimeToken'
  },
  [reportScopeType.REFERENCE_DOCUMENT_REPORT]: {
    mutation: MUTATION_REFERENCE_REPORT_DOCUMENT_TOKEN,
    dataPath: 'createReferenceReportDocumentOneTimeToken'
  },
  [reportScopeType.REFERENCE_DOCUMENT_EVENTS_REPORT]: {
    mutation: MUTATION_REFERENCE_EVENT_REPORT_DOCUMENT_TOKEN,
    dataPath: 'createReferenceEventsReportDocumentOneTimeToken'
  },
  [reportScopeType.REFERENCE_DOCUMENT_INFOGRAPHICS_PDF]: {
    mutation: MUTATION_INFOGRAPHICS_EVENT_REPORT_DOCUMENT_TOKEN,
    dataPath: 'createReferenceInfographicsPdfDocumentOneTimeToken'
  }
};

export class DocumentTokenStore {
  constructor({ client }) {
    this.client = client;
  }

  async roadmapFileToken(id) {
    const query = await this.client.mutate({
      mutation: MUTATION_ROADMAP_FILE_TOKEN,
      variables: { id }
    });
    return query.data.createRoadmapDocumentFileOneTimeToken;
  }

  async roadmapDocumentFileToken(id) {
    const query = await this.client.mutate({
      mutation: MUTATION_ROADMAP_DOCUMENT_TOKEN,
      variables: { id }
    });
    return query.data.createRoadmapDocumentOneTimeToken;
  }

  /**
   * @param {reportScopeType} type - one of reportScopeType
   * @returns {Promise<string|null>} - Возвращает токен
   */
  async reportFilesToken(type) {
    const options = reportTypeMutation[type];
    if (!options) {
      return null;
    }

    const query = await this.client.mutate({
      mutation: options.mutation
    });
    return query.data[options.dataPath];
  }
}
