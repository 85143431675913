import { useEffect, useRef } from 'react';
import { SingleTimeoutManager } from './SingleTimeoutManager';

export const useTimeoutRef = () => {
  /** @type {React.MutableRefObject<SingleTimeoutManager>} */
  const ref = useRef(null);

  if (!ref.current) {
    ref.current = new SingleTimeoutManager();
  }

  useEffect(() => {
    return () => {
      if (ref.current) ref.current.clear();
    };
  }, []);

  return ref.current;
};
