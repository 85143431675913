import React, { useCallback } from 'react';
import s from './BaseButton.module.scss';

function BaseButton({ onMouseDown, ...props }) {
  const handleMouseDown = useCallback(
    (event) => {
      const { currentTarget } = event;
      currentTarget.classList.add('_pressed');
      const callback = () => {
        currentTarget.blur();
        currentTarget.classList.remove('_pressed');
        window.removeEventListener('mouseup', callback);
      };
      window.addEventListener('mouseup', callback);
      onMouseDown && onMouseDown(event);
    },
    [onMouseDown]
  );

  return (
    <button
      onMouseDown={handleMouseDown}
      className={s.BaseButton}
      type="button"
      {...props}
    />
  );
}

export default BaseButton;
