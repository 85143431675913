import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as DownloadIcon } from '../../../assets/Icons/Download.svg';
import { ReactComponent as Close } from '../../../assets/Icons/Close.svg';
import { useClickOutside } from '../../../utils/useClickOutside';
import BaseButton from '../Button/BaseButton';
import { LoaderDownloadButtonLink } from './DownloadButtonLink';
import s from './DownloadButton.module.scss';

const [NORMAL, LARGE, SMALL] = ['normal', 'large', 'small'];

/**
 *
 * @param {{fileName: string, url: string}[]} data
 * @param {('left'|'right')} popupPosition - одно из значений
 * @param {('large'|'normal'|'small')} size
 * @param customDownloadButton - Элемент для другой отрисовки кнопки
 * @returns {*}
 * @constructor
 */
export function DownloadButton({
  data,
  popupPosition,
  size,
  customDownloadButton
}) {
  const [menuShowed, changeMenuStatus] = useState(false);
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const [position, setPosition] = useState({ left: 0 });

  const hideMenuOnClickOutside = useMemo(() => {
    if (menuShowed) {
      return () => changeMenuStatus(false);
    }
    return null;
  }, [menuShowed]);

  const onDownloadFinish = useCallback(() => {
    if (data && data.length === 1) {
      changeMenuStatus(false);
    }
  }, [data, changeMenuStatus]);

  useClickOutside(hideMenuOnClickOutside, [buttonRef, popupRef]);

  useEffect(() => {
    if (popupRef.current) {
      const position = popupRef.current.getBoundingClientRect();
      const windowWidth = document.body.clientWidth;
      const windowHeight = document.body.clientHeight;

      let newPosition = {};

      if (popupPosition) {
        newPosition = popupPosition === 'left' ? { right: 0 } : { left: 0 };
      } else {
        if (windowWidth - position.left < position.width) {
          newPosition.right = 0;
        } else {
          newPosition.left = 0;
        }
      }
      if (position.bottom > windowHeight) {
        newPosition.top = -8 - position.height + 'px';
      }

      setPosition(newPosition);
    }
  }, [popupPosition]);

  return (
    <div
      className={classNames(s.DownloadButton, {
        [s._active]: menuShowed
      })}
      ref={buttonRef}
    >
      <BaseButton
        onClick={() => changeMenuStatus(!menuShowed)}
        className={classNames(s.DownloadButton__button, {
          [s.Button_large]: size === LARGE,
          [s.Button_small]: size === SMALL
        })}
      >
        <DownloadIcon className={s.DownloadButton__download} />
        <Close className={s.DownloadButton__close} />
      </BaseButton>
      <div
        ref={popupRef}
        style={position}
        className={classNames(s.DownloadButton__tooltip, {
          [s.tooltip_large]: size === LARGE,
          [s.tooltip_small]: size === SMALL
        })}
      >
        {data.map((item, iItem) => {
          if (customDownloadButton) {
            const Comp = customDownloadButton;
            return (
              <Comp
                key={iItem}
                url={item.url}
                fileName={item.fileName}
                onDownloadFinish={onDownloadFinish}
                cookieLoader={item.cookieLoader}
              />
            );
          }

          return (
            <LoaderDownloadButtonLink
              key={iItem}
              url={item.url}
              fileName={item.fileName}
              onDownloadFinish={onDownloadFinish}
              cookieLoader={item.cookieLoader}
            />
          );
        })}
      </div>
    </div>
  );
}

DownloadButton.NORMAL = NORMAL;
DownloadButton.LARGE = LARGE;
DownloadButton.SMALL = SMALL;

DownloadButton.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string
    })
  ),
  popupPosition: PropTypes.oneOf(['right', 'left']),
  size: PropTypes.oneOf([NORMAL, LARGE, SMALL])
};

DownloadButton.defaultProps = {
  data: []
};
