import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { color as d3color, max as d3Max, sum as d3sum } from 'd3';
import { QUERY_GET_REGION_INDICATORS } from '../../graphql/queries/finance';
import { MapPageLayout } from '../_layouts/MapPageLayout';
import { filterSearch } from '../../utils/filterSearch';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { SearchInput } from '../../common/ui/SearchInput/SearchInput';
import { ReactComponent as BackArrow } from '../../assets/Icons/Arrow/Back.svg';
import { IconButton } from '../../common/ui/IconButton/IconButton';
import { TopBarAllProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { TopBar } from '../../common/TopBar/TopBar';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import BaseButton from '../../common/ui/Button/BaseButton';
import { HorizontalBarChart } from '../../common/charts/HorizontalBarChart';
import { ResizeContainer } from '../../common/ResizeContainer';
import { useWindowSize } from '../../store/useWindowSize';
import { SwipeBox } from '../../common/SwipeBox/SwipeBox';
import { FlyingBox } from '../../common/FlyingBox/FlyingBox';
import { ReactComponent as RFLogo } from '../../assets/Icons/russian_federation.svg';
import { getStatusByValue, getStatusColor } from '../../utils/constants';
import { GrbsFilter } from '../../common/Filters/GrbsFilter';
import { ActualityDate } from '../../common/NavBar/ActualityDate';
import RegionView from './RegionView';
import MapView from './MapView';
import s from './RegionsIndicatorPage.module.scss';

const mbtFinanceOptions = {
  query: QUERY_GET_REGION_INDICATORS,
  skipQuery: skipIfNull(null)
};

function RightContentButton({
  regionsQuery,
  currentRegion,
  regionsFiltered,
  onClick
}) {
  if (regionsQuery.check.spinner) return null;

  const region = currentRegion
    ? regionsFiltered.find((item) => +item.region.code_nalog === +currentRegion)
    : null;

  return (
    <div className={s.RightContentButton} onClick={onClick}>
      <div className={s.RightContentButton__icon}>
        {!!region ? (
          <img
            src={`/img/regions/${region.region.code_nalog}.png`}
            alt="Герб"
          />
        ) : (
          <RFLogo />
        )}
      </div>
      <p className={s.RightContentButton__title}>
        {!!region ? region.region.name : 'Российская Федерация'}
      </p>
    </div>
  );

  //region.region.name
}

function RightContent({
  regionsQuery,
  currentRegion,
  regionsFiltered,
  sumCashExecution,
  sumPof,
  onClose
}) {
  return regionsQuery.check.spinner ? (
    <CircleProgressIndicator />
  ) : currentRegion ? (
    <RegionView
      region={regionsFiltered.find(
        (item) => +item.region.code_nalog === +currentRegion
      )}
      onClose={onClose}
    />
  ) : (
    <MapView pof={sumPof} cash_expenses={sumCashExecution} />
  );
}

function RegionsIndicatorPage({ history, location }) {
  const [query, changeQuery] = useUrlQuery();
  const currentRegion = query.region ? query.region : null;
  const search = query.search ? query.search : '';
  const { yearMonth } = useQueryPeriodFast(query, changeQuery);
  const currentFp = query.fp || '0';
  const grbs = query.grbs;

  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const [rightOpened, setRightOpened] = useState(false);

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  const regionsVariables = useMemo(() => {
    const regionsVariables = {
      yearMonth
    };
    if (currentFp !== '0') {
      regionsVariables.federal_project_codes = currentFp;
    }
    if (grbs && grbs !== 'all') {
      regionsVariables.grbs_codes = [grbs];
    }
    return regionsVariables;
  }, [yearMonth, currentFp, grbs]);

  const regionsQuery = useGraphqlQuery({
    queryOptions: mbtFinanceOptions,
    variables: regionsVariables
  });

  const regionsData = regionsQuery.state.data;

  const regionsFiltered = useMemo(() => {
    if (!regionsData) return [];
    const { mbt, subjects, pof } = regionsData;
    return filterSearch(search, mbt, (x) => x.region.name)
      .sort(({ region: { name } }, { region: { name: nextName } }) =>
        name < nextName ? -1 : 1
      )
      .map((item) => {
        const finances = subjects.find((s) => s.region_id === item.region_id);
        const pofSum = pof?.find((p) => p.region_id === item.region_id)?.sum;
        return {
          ...item,
          ...finances,
          pofSum
        };
      });
  }, [regionsData, search]);

  const [sumCashExecution, sumPof] = useMemo(
    () =>
      regionsData
        ? [
            d3sum(regionsData.mbt, (v) => v.otfg_kr_total) / 1e9,
            d3sum(regionsData.mbt, (v) => v.plan) / 1e9
          ]
        : [null, null],
    [regionsData]
  );

  const chartTotal = d3Max(regionsFiltered, (x) => x.plan) / 1e6;

  const onClose = () => {
    changeQuery({ region: undefined }, true);
  };

  // todo почему MapPageLayout а не RightPaneLayout ?
  return (
    <MapPageLayout className="_with-column">
      <TopBar>
        <TopBarAllProjects replaceQuery={true} />
      </TopBar>
      <div className={s.indicators}>
        <div className={s.indicators__title}>
          <IconButton
            className={s.indicators__title_btn}
            icon={BackArrow}
            onClick={() => history.goBack()}
          />
          Кассовое исполнение субъектами ПОФ
        </div>
        <div className={s.indicators__filters}>
          <div>
            <GrbsFilter />
          </div>
          <div className={s.indicators__search}>
            <SearchInput
              placeholder={'Введите регион'}
              size={SearchInput.SMALL}
              defaultValue={search}
              onChange={(event) => {
                changeQuery({ search: event.target.value }, true);
              }}
              onCloseBtnClick={() => {
                changeQuery({ search: '' }, true);
              }}
            />
          </div>
          {regionsData && (
            <div>
              <ActualityDate date={regionsData.subjectDate} />
            </div>
          )}
        </div>
        {regionsQuery.check.spinner ? (
          <CircleProgressIndicator />
        ) : (
          <div className={s.indicators__items}>
            {regionsFiltered.map((item) => {
              const percent = (item.otfg_kr_total / item.plan) * 100;
              const status = getStatusByValue(percent);
              const color = getStatusColor(status);
              const colorPlan = d3color(color)
                .copy({ opacity: 0.3 })
                .formatRgb();
              const pofSumColor = d3color(color)
                .copy({ opacity: 0.6 })
                .formatRgb();

              let chartData = [
                {
                  id: 'fact',
                  value: item.otfg_kr_total / 1e6,
                  color: color
                },
                {
                  id: 'pofSum',
                  value: item.pofSum ? item.pofSum / 1e6 : 0,
                  color: pofSumColor
                },
                {
                  id: 'plan',
                  value: item.plan / 1e6,
                  color: colorPlan
                }
              ];

              chartData = chartData.sort(
                (ch, nextCh) => ch.value - nextCh.value
              );

              return (
                <BaseButton
                  key={item.region.code_nalog}
                  className={classNames(s.region, {
                    [s._active]: +item.region.code_nalog === +currentRegion
                  })}
                  onClick={() => {
                    if (+item.region.code_nalog === +currentRegion) {
                      changeQuery({ region: undefined }, true);
                    } else {
                      changeQuery({ region: item.region.code_nalog }, true);
                      onRightOpen();
                    }
                  }}
                >
                  <div className={s.region__title}>{item.region.name}</div>
                  <div className={s.region__indicator}>
                    <ResizeContainer>
                      {({ width }) => {
                        return (
                          <HorizontalBarChart
                            data={chartData}
                            total={chartTotal}
                            decimals={1}
                            sizeHeight={16 + 8 + 16}
                            sizeWidth={width}
                            className={s.region__indicator_line}
                            paddingLeft={24}
                            paddingRight={24}
                          />
                        );
                      }}
                    </ResizeContainer>
                  </div>
                </BaseButton>
              );
            })}
          </div>
        )}
      </div>
      {isMobile ? (
        <>
          <SwipeBox isOpened={rightOpened} onClose={onRightClose}>
            <RightContent
              regionsQuery={regionsQuery}
              currentRegion={currentRegion}
              regionsFiltered={regionsFiltered}
              sumCashExecution={sumCashExecution}
              sumPof={sumPof}
              onClose={onClose}
            />
          </SwipeBox>
          <FlyingBox>
            <RightContentButton
              regionsQuery={regionsQuery}
              currentRegion={currentRegion}
              regionsFiltered={regionsFiltered}
              onClick={onRightOpen}
            />
          </FlyingBox>
        </>
      ) : (
        <RightContent
          regionsQuery={regionsQuery}
          currentRegion={currentRegion}
          regionsFiltered={regionsFiltered}
          sumCashExecution={sumCashExecution}
          sumPof={sumPof}
          onClose={onClose}
        />
      )}
    </MapPageLayout>
  );
}

RegionsIndicatorPage.propTypes = {};

export default RegionsIndicatorPage;
