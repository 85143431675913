import gql from 'graphql-tag';

export const QUERY_GET_OPERATIVE_REFERENCE = gql`
  query getOperativeReference($input: ReferenceInput) {
    operativeReference(input: $input) {
      html
      docxUrl
      pdfUrl
    }
  }
`;

export const QUERY_GET_REPORT = gql`
  query getReport($input: ReportInputFilter) {
    report(input: $input) {
      html
      docxUrl
      pdfUrl
      xlsUrl
    }
  }
`;

export const QUERY_GET_EVENT_REPORT = gql`
  query getEventsReport($input: ReportInputFilter) {
    eventsReport(input: $input) {
      html
      docxUrl
      pdfUrl
      xlsUrl
    }
  }
`;
