import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';
import {
  bbox,
  booleanClockwise,
  centerOfMass,
  featureReduce,
  getCoord,
  lineEach
} from '@turf/turf';
import { GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'proj4leaflet';
import {
  highlightFeature,
  resetHighlight,
  toLeafletBounds
} from '../../RegionMap/utils';
import { CountMarker, InternetMarker } from '../../RegionMap/ObjectMarker';
import { INDICATOR_INTERNET_SPEED } from '../../../utils/indicators';
import { BaseMap } from './BaseMap';
import colors from '../../../colors.scss';
import s from './CommonMap.module.scss';

const MIN_ZOOM = -16;
const MAX_ZOOM = -1;

function CommonMap({
  className,
  data,
  mapItems,
  mapItemProp,
  onNavigate,
  indicator_type
}) {
  const geojsonLayerRef = useRef(null);
  const centers = useMemo(
    () =>
      featureReduce(
        data,
        (previousValue, feature) => {
          const code = feature.properties.code;
          let maxLine = null;
          let maxArea = null;
          lineEach(feature, (line) => {
            if (booleanClockwise(line)) {
              const lineBbox = bbox(line);
              const lineArea =
                (lineBbox[3] - lineBbox[1]) * (lineBbox[2] - lineBbox[0]);
              if (!maxArea || lineArea > maxArea) {
                maxLine = line;
                maxArea = lineArea;
              }
            }
          });
          const centerMass = centerOfMass(maxLine);
          const [x, y] = getCoord(centerMass);
          const position = [y, x];
          previousValue.push({ code, position });
          return previousValue;
        },
        []
      ),
    [data]
  );
  const cbounds = toLeafletBounds(bbox(data));

  const dataRef = useRef({});
  dataRef.current.getItem = (code) =>
    mapItems.find((r) => r[mapItemProp] === code);
  dataRef.current.navigate = onNavigate;

  // processFeature вызывается только один раз при маунте GeoJson, поэтому
  // используем хитрости с рефами, чтобы отображать актуальный тултип
  const processFeature = useCallback((feature, layer) => {
    const code = feature.properties.code;
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: () => dataRef.current.navigate(code)
    });
    layer.bindTooltip(() => {
      const item = dataRef.current.getItem(code);
      if (item) {
        return item.tooltipText || item.name;
      } else {
        return '';
      }
    });
  }, []);

  const markers = useMemo(
    () =>
      centers.map((center, iCenter) => {
        const mapItem =
          mapItems.find((item) => item[mapItemProp] === center.code) || {};
        const {
          colorMarkers,
          name,
          tooltipText,
          count_organizations,
          average_internet_speed
        } = mapItem;

        const markerData = {
          ...center,
          name: tooltipText || name,
          count: count_organizations ? count_organizations[0].count : 0,
          color: colorMarkers || colors.success
        }; // todo переделать, когда вернется массив

        if (indicator_type === INDICATOR_INTERNET_SPEED) {
          const upload =
            average_internet_speed && average_internet_speed.upload_speed;
          const download =
            average_internet_speed && average_internet_speed.download_speed;

          return (
            <InternetMarker
              key={iCenter}
              object={markerData}
              onClick={dataRef.current.navigate}
              upload={upload}
              download={download}
            />
          );
        }

        return (
          <CountMarker
            key={iCenter}
            object={markerData}
            onClick={dataRef.current.navigate}
          />
        );
      }),
    [centers, mapItems, indicator_type, mapItemProp]
  );

  const stylize = useCallback(
    (feature) => {
      const code = feature.properties.code;
      const item = mapItems.find((r) => r[mapItemProp] === code);
      return { color: (item && item.color) || colors.gray, weight: 1 };
    },
    [mapItems, mapItemProp]
  );

  return (
    <BaseMap
      className={classNames(s.RegionMap, className)}
      bounds={cbounds}
      minZoom={MIN_ZOOM}
      maxZoom={MAX_ZOOM}
    >
      <GeoJSON
        ref={geojsonLayerRef}
        data={data}
        style={stylize}
        onEachFeature={processFeature}
      />
      {markers}
    </BaseMap>
  );
}

export default CommonMap;
