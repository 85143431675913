import gql from 'graphql-tag';

const FRAGMENT_ORGANIZATION_INFO = gql`
  fragment OrganizationInfo on OrganizationType {
    id
    inn
    name_short
    name_full
    registration_date
    bs_address
    bs_chief_manager
    bs_organization_type
    bs_organization_subtype
    bs_organization_site
    is_small_school
    emails
    phones
    directors: responsible_persons(type: DIRECTOR) {
      organization_id
      individual_id
      responsibility_type_id
      period
      individual {
        id
        name
      }
    }
  }
`;

export const QUERY_GET_ORGANIZATION = gql`
  query organization($id: ID!) {
    organization(id: $id) {
      ...OrganizationInfo
      regional_projects {
        id
        code
        name_full
        federal_project {
          id
          code
          project_code
          name_full
        }
      }
      region {
        id
        name
        code_nalog
      }
    }
  }
  ${FRAGMENT_ORGANIZATION_INFO}
`;

export const QUERY_GET_RESULTS = gql`
  query results($organization_id: ID!) {
    resultFederals(input: { filter: { organization_id: $organization_id } }) {
      list {
        id
        code
        project_code
        name_full
        owner_task {
          id
          owner_id
        }
      }
    }
  }
`;

export const QUERY_SEARCH_ORGANIZATIONS = gql`
  query searchOrganizations(
    $input: OrganizationPageInput!
    $averageSpeed: BaseInternetSpeedInNodeFilter
  ) {
    organizations(input: $input) {
      header {
        page
        totalCount
        onePage
        hasNext
      }
      list {
        id
        inn
        name_full
        name_short
        bs_latitude
        bs_longitude
        bs_organization_type
        bs_organization_type_okved_id
        average_internet_speed(filter: $averageSpeed) {
          upload_speed
          download_speed
        }
      }
    }
  }
`;

export const QUERY_SEARCH_SUBSIDIARY_ENTITIES = gql`
  query getSubsidiaryEntities($input: SubsidiaryEntitiesPageInput) {
    subsidiaryEntitiesPage(input: $input) {
      header {
        page
        totalCount
        onePage
        hasNext
      }
      list {
        id
        name
        name_full
        base_organization_id
        base_organization_name
        base_organization {
          id
          name_full
          name_short
          inn
          kpp
        }
      }
    }
  }
`;

export const QUERY_GET_SUBSIDIARY_ENTITIES = gql`
  query getSubsidiaryEntities(
    $filter: SubsidiaryEntityFilter
    $date: String
    $yearMonth: String
  ) {
    subsidiaryEntitiesPage(input: { filter: $filter }) {
      header {
        page
        totalCount
        onePage
        hasNext
      }
      list {
        id
        name
        name_full
        owner_roadmap {
          id
          name
          name_full
          result {
            id
            owner_task {
              id
              owner_id
            }
          }
        }
        dc_completion(filter: { date: $date }) {
          dc_completion_percent
          completed_events
          total_events
        }
        finances(filter: { month: $yearMonth }) {
          cash_expenses_percent
        }
      }
    }
  }
`;

export const QUERY_GET_SUBSIDIARY_ENTITIES_FOR_MAP = gql`
  query getSubsidiaryEntitiesForMap($input: SubsidiaryEntitiesPageInput) {
    subsidiaryEntitiesPage(input: $input) {
      list {
        id
        longitude
        latitude
        inn
        name_full
        base_organization_name
        base_organization {
          id
          name_full
          bs_latitude
          bs_longitude
          bs_organization_type
          bs_organization_type_okved_id
          inn
          name_short
        }
        name
        se_type {
          id
          name
          name_full
        }
      }
    }
  }
`;

export const QUERY_GET_SUBSIDIARY_ENTITY = gql`
  query getSubsidiaryEntity($id: ID!, $date: String, $yearMonth: String) {
    subsidiaryEntity(id: $id) {
      id
      name
      name_full
      address
      building
      code
      form_of_incorporation
      house
      index
      inn
      kpp
      locality
      region_string
      street
      version
      year_of_creation
      supervisor {
        id
        first_name
        surname
        patronymic
        phones
        emails
      }
      region {
        id
        name
        code_nalog
      }
      base_organization {
        ...OrganizationInfo
      }
      owner_roadmap {
        id
        name
        name_full
        subsidiary_entity_plans(filter: { active: true }) {
          year
          amount
          region {
            id
            name
            code_nalog
          }
        }
        result {
          id
          owner_task {
            id
            owner_id
            federal_project {
              id
              code
              project_code
              name
              name_full
            }
          }
        }
      }
      dc_completion(filter: { date: $date }) {
        dc_completion_percent
        completed_events
        total_events
      }
      finances(filter: { month: $yearMonth }) {
        cash_expenses_percent
      }
    }
  }
  ${FRAGMENT_ORGANIZATION_INFO}
`;

export const QUERY_GET_ORGANIZATION_INTERNET = gql`
  query getOrganizationInternet($id: ID!, $year: Int!, $yearMonth: String!) {
    last: internetSpeedInNodes(
      filter: {
        organization_ids: [$id]
        years: [$year]
        yearMonths: [$yearMonth]
        onlyLast: true
      }
    ) {
      computer_number
      upload_speed
      download_speed
      date
    }
    history: internetSpeedGrouped(
      filter: { organization_ids: [$id], years: [$year] }
      groupBy: [year, month]
    ) {
      year
      month
      upload_speed
      download_speed
    }
  }
`;
