import React from 'react';
import classNames from 'classnames';
import s from './GridLayout.module.scss';

export const GridLayout = ({ pageClassName, className, popup, children }) => {
  return (
    <div className={classNames(s.GridOffset, pageClassName)}>
      <div className={classNames(s.GridLayout, className)}>{children}</div>
      {popup}
    </div>
  );
};
