import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { useRegions } from '../../store/useRegions';
import {
  useFederalProjectResults,
  useFederalProjects
} from '../../store/useFederalProjects';
import {
  currentMonthObject,
  currentYearObject,
  months,
  years
} from '../../utils/constants';
import { useMemoSuggestions } from '../../utils/useSuggestions';
import { Project } from '../../data/project';
import { createPreservedLink } from '../../store/usePreserveQuery';
import { Select } from '../../common/ui/Select/Select';
import { Button } from '../../common/ui/Button/Button';
import ReportRadioGroup from './common/ReportRadioGroup';
import s from './ReportsPage.module.scss';

export const STANDARD_REPORT_TYPE = 'standard';
export const EVENTS_REPORT_TYPE = 'events';

const reportTypes = [
  { title: 'Стандартный отчет', value: STANDARD_REPORT_TYPE },
  { title: 'Отчет по мероприятиям', value: EVENTS_REPORT_TYPE }
];

const initialState = {
  radio: STANDARD_REPORT_TYPE,
  region: null,
  project: null,
  result: null,
  month: months[0],
  year: years[0]
};

const getFpName = (fp) => fp.name;
const getCodeNalog = (reg) => reg.code_nalog;

const FORM_TYPES = {
  REGION: 'region',
  PROJECT: 'project',
  RESULT: 'result',
  YEAR: 'year',
  MONTH: 'month'
};

function reducer(state, { type, payload }) {
  switch (type) {
    case 'changeSingle':
      return {
        ...state,
        [payload.name]: payload.value
      };
    case 'changeDefaults':
      return {
        ...state,
        ...payload
      };
    default:
      throw new Error();
  }
}

export const ReportsForm = ({ history, location }) => {
  const [query, changeQuery] = useUrlQuery();
  const {
    fp = null,
    region_code = null,
    reportType = null,
    year,
    month
  } = query;
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialized = useRef(false);

  const handleChangeSingleSelect = useCallback(
    (type) => (data, action) => {
      if (type === FORM_TYPES.PROJECT) {
        dispatch({
          type: 'changeSingle',
          payload: { name: FORM_TYPES.RESULT, value: null }
        });
      }
      dispatch({
        type: 'changeSingle',
        payload: { name: action?.name || type, value: data }
      });
    },
    []
  );

  const handleChangeRadio = useCallback(
    (data) =>
      dispatch({
        type: 'changeSingle',
        payload: { name: 'radio', value: data }
      }),
    []
  );

  const regionsQuery = useRegions();
  const federalProjectsQuery = useFederalProjects();
  const projectResults = useMemo(
    () =>
      state.project
        ? {
            input: {
              filter: {
                federal_project_codes: [state.project.value + '']
              }
            }
          }
        : null,
    [state.project]
  );
  const federalProjectResultsQuery = useFederalProjectResults(projectResults);
  if (
    !initialized.current &&
    regionsQuery.state.data &&
    federalProjectsQuery.state.data
  ) {
    initialized.current = true;
    let subject = region_code
      ? regionsQuery.state.data.find(
          ({ code_nalog }) => code_nalog === region_code
        )
      : null;
    subject = subject
      ? { value: subject.code_nalog, label: subject.name }
      : subject;

    let project = fp
      ? federalProjectsQuery.state.data.find((p) => +p.project_code === +fp)
      : null;
    project = project
      ? { value: +project.project_code, label: project.name }
      : project;

    let type = reportType
      ? reportTypes.find((t) => t.value === reportType)
      : reportTypes[0];

    dispatch({
      type: 'changeDefaults',
      payload: {
        region: subject,
        project: project || null,
        radio: type.value,
        year: years.find((y) => y.value === year) || currentYearObject,
        month: months.find((q) => q.value === +month) || currentMonthObject
      }
    });
  }

  const regions = useMemoSuggestions(
    regionsQuery.state.data,
    getFpName,
    getCodeNalog
  );
  const federalProjects = useMemoSuggestions(
    federalProjectsQuery.state.data,
    getFpName,
    Project.getFederalProjectCode
  );
  const federalProjectsResults = useMemoSuggestions(
    federalProjectResultsQuery.state.data
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state);
    history.push(
      createPreservedLink('/reports/test', {
        region_code: state.region?.value || undefined,
        fp: (state.project && state.project.value) || undefined,
        year: state.year.value || undefined,
        month: state.month.value,
        result: state.result?.value,
        report: state.radio
      })
    );
  };

  const isSubmitDisabled = useMemo(() => {
    const { region, project, result } = state;
    return !(region || project || result || state.radio === EVENTS_REPORT_TYPE);
  }, [state]);

  return (
    <form className={s.Form} onSubmit={handleSubmit}>
      <div className={s.InputSelector}>
        <Select
          className={s.InputSelector__item}
          loading={regionsQuery.check.spinner}
          clearable={true}
          name={FORM_TYPES.REGION}
          onChange={handleChangeSingleSelect(FORM_TYPES.REGION)}
          placeholder="Субъект"
          searchable={true}
          showPlaceholder={true}
          value={state.region}
          values={regions}
        />
        <Select
          className={s.InputSelector__item}
          loading={federalProjectsQuery.check.spinner}
          clearable={true}
          name={FORM_TYPES.PROJECT}
          onChange={handleChangeSingleSelect(FORM_TYPES.PROJECT)}
          placeholder="Федеральный проект"
          searchable={true}
          showPlaceholder={true}
          value={state.project}
          values={federalProjects}
        />
        <Select
          className={s.InputSelector__item}
          disabled={!state.project}
          loading={federalProjectResultsQuery.check.spinner}
          clearable={true}
          name={FORM_TYPES.RESULT}
          onChange={handleChangeSingleSelect(FORM_TYPES.RESULT)}
          placeholder="Результат"
          searchable={true}
          showPlaceholder={true}
          value={state.result}
          values={federalProjectsResults}
        />
      </div>
      <div className={s.SelectsGroup}>
        <Select
          className={s.SelectsGroup__selectYear}
          name={FORM_TYPES.YEAR}
          onChange={handleChangeSingleSelect(FORM_TYPES.YEAR)}
          placeholder="Год"
          showPlaceholder={true}
          values={years}
          value={state.year}
        />
        <Select
          className={s.SelectsGroup__selectMonth}
          name={FORM_TYPES.MONTH}
          onChange={handleChangeSingleSelect(FORM_TYPES.MONTH)}
          placeholder="Месяц"
          showPlaceholder={true}
          values={months}
          value={state.month}
        />
      </div>
      <ReportRadioGroup
        onChange={handleChangeRadio}
        options={reportTypes}
        selected={state.radio}
      />
      <Button
        disabled={isSubmitDisabled}
        type="submit"
        className={s.Form__submit}
        //loading={isSubmitting}
      >
        Сформировать отчёт
      </Button>
    </form>
  );
};
