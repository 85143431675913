import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import s from './CircleProgressIndicator.module.scss';

export function CircleProgressIndicator({
  color,
  size,
  className,
  indicatorClassName,
  absolute,
  ...props
}) {
  return (
    <div
      className={classNames(s.spinner, { [s._absolute]: absolute }, className)}
      {...props}
    >
      <svg
        className={classNames(s.spinner__svg, indicatorClassName)}
        width={size}
        height={size}
        viewBox="0 0 66 66"
        stroke={color}
      >
        <circle
          className={s.circle}
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  );
}

CircleProgressIndicator.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  indicatorClassName: PropTypes.string
};

CircleProgressIndicator.defaultProps = {
  size: 60,
  color: 'rgb(0, 142, 131)'
};
