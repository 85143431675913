import { positiveMod } from '../../../utils/math';
import { districtColors } from './constants';

export function getDistrictsData(subjectFinancesData) {
  const {
    subjectDistricts,
    subjectRegions,
    subjectMbtRegions,
    subjectMbtDistricts,
    subjectRbRegions,
    subjectRbDistricts
  } = subjectFinancesData;

  return subjectDistricts
    .slice()
    .sort((a, b) => a.federal_district.code - b.federal_district.code)
    .map((district, index) => {
      const cardColors =
        districtColors[positiveMod(index, districtColors.length)];
      const { federal_district_id } = district;

      return {
        colors: cardColors,
        data: district,
        mbt: subjectMbtDistricts.find(
          (s) => s.federal_district_id === federal_district_id
        ),
        rb: subjectRbDistricts.find(
          (s) => s.federal_district_id === federal_district_id
        ),
        regions: subjectRegions
          .filter((r) => r.region.federal_district_id === federal_district_id)
          .sort((a, b) => a.region.code - b.region.code)
          .map((data) => ({
            data,
            colors: cardColors,
            mbt: subjectMbtRegions.find((s) => s.region_id === data.region_id),
            rb: subjectRbRegions.find((s) => s.region_id === data.region_id)
          }))
      };
    });
}
