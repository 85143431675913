import React from 'react';
import classNames from 'classnames';
import s from './IndicatorPageLayout.module.scss';

export function IndicatorPageLayout({
  className,
  right,
  bottom,
  topBar,
  extra,
  popup,
  children
}) {
  return (
    <>
      <div className={s.IndicatorPageLayout__wrapper}>
        <div className={classNames(s.IndicatorPageLayout, className)}>
          <div className={s.IndicatorPageLayout__main}>{children}</div>
          <div className={s.IndicatorPageLayout__right}>{right}</div>
          <div className={s.IndicatorPageLayout__bottom}>{bottom}</div>
          {topBar}
          {extra}
        </div>
      </div>
      {popup}
    </>
  );
}
