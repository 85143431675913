import React, { useMemo } from 'react';
import { divIcon } from 'leaflet';
import { Marker } from 'react-leaflet';
import { renderToString } from 'react-dom/server';

export function createDivIcon({ content, ...props }) {
  const html = renderToString(<>{content}</>);
  return divIcon({
    html,
    ...props
  });
}

export function JsxMarker({ content, className, iconProps, ...props }) {
  const html = renderToString(<>{content}</>);

  const icon = useMemo(
    () =>
      divIcon({
        className: className,
        html,
        ...iconProps
      }),
    [className, html, iconProps]
  );

  return <Marker icon={icon} {...props} />;
}
