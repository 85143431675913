import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { format } from 'date-fns';

import ResizeObserver from 'react-resize-observer';

import { TopBar } from '../../common/TopBar/TopBar';
import { MapPageLayout } from '../_layouts/MapPageLayout';
import {
  processRegionsWithFinancialRatings,
  useRegionsWithFinancialRatings
} from '../../store/useRegions';
import { useSearch } from '../../utils/useSearch';
import { PageTitle } from '../../common/PageTitle/PageTitle';
import { SearchInput } from '../../common/ui/SearchInput/SearchInput';
import { Tag } from '../../common/ui/Tag/Tag';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import BaseButton from '../../common/ui/Button/BaseButton';
import { ReactComponent as SortIcon } from '../../assets/Icons/Sort.svg';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { getStatusByValue, STATUS_TYPES } from '../../utils/constants';
import { TopBarAllProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import {
  INDICATOR_ACCEPT,
  INDICATOR_CASH_COMPLETION,
  INDICATOR_DC_COMPLETION,
  INDICATOR_RESULT_ACH,
  INDICATOR_TARGET_ACH,
  indicators
} from '../../utils/indicators';
import { useSort } from '../../utils/useSort';
import { Select } from '../../common/ui/Select/Select';
import { useQueryDistrict } from '../../utils/useQueryDistrict';
import { GrbsFilter } from '../../common/Filters/GrbsFilter';
import {
  DATE_FORMAT_RUSSIAN,
  formatDateIso,
  getIndicatorsAndResultsDate,
  getPlanAndObligationDate,
  tryParseIso
} from '../../utils/date';
import { EMDASH } from '../../utils/utf';
import { formatNumber } from '../../utils/formatNumber';
import { BaseTooltip } from '../../common/ui/ToolTip/BaseTooltip';

import { TextTooltip } from '../../common/ui/ToolTip/TextTooltip';

import { hideAcceptBOInRatingPage } from '../../config';
import { unificationNumber } from '../../utils/math';
import s from './RatingsPage.module.scss';

const noMbtFinances =
  'Отсутствие субсидии на софинансирование и бюджета субъекта на реализацию РП';

const getAnotherDirection = (direction) =>
  direction === 'asc' ? 'desc' : 'asc';

function RatingsPage({ history }) {
  return <RatingsPageContent history={history} />;
}

const RatingsPageContent = React.memo(function RatingsPageContent({ history }) {
  return (
    <MapPageLayout className={s.RatingsLayout}>
      <TopBar>
        <TopBarAllProjects />
      </TopBar>
      <Ratings history={history} />
    </MapPageLayout>
  );
});

function Ratings({ history }) {
  const [query, changeQuery] = useUrlQuery();
  const preserved = usePreserveQueryFast(query);
  const { search, handleSearch, handleChange, clearInput } = useSearch(
    query,
    changeQuery,
    true,
    true
  );

  const { district, districts, setDistrict } = useQueryDistrict(
    query,
    changeQuery
  );

  return (
    <div className={s.Ratings}>
      <div className={s.Ratings__scroll}>
        <PageTitle
          text="Рейтинг субъектов"
          onClick={() => history.goBack(preserved.createLink('/indicators'))}
        />
        <div className={s.Ratings__options}>
          <Select
            className={classNames(s.Ratings__select, s.Ratings__selectFo)}
            values={districts}
            value={district}
            onChange={setDistrict}
          />
          <GrbsFilter className={s.Ratings__select} />
          <form onSubmit={handleSearch}>
            <SearchInput
              className={s.Ratings__search}
              onCloseBtnClick={clearInput}
              defaultValue={search}
              onChange={handleChange}
              placeholder="Поиск"
            />
          </form>
        </div>
        <div className={s.Ratings__tableContainer}>
          <RatingsTable
            filter={{
              federal_district_id:
                district.value === '0' ? null : district.value,
              search
            }}
          />
        </div>
      </div>
    </div>
  );
}

const SortButton = ({ title, className = '', onClick, actualityDate }) => (
  <BaseButton
    className={classNames(s.SortButton, className)}
    onMouseUp={onClick}
  >
    <p className={s.SortButton__title}>{title}</p>
    <p className={s.SortButton__actualityDate}>
      {actualityDate ? format(actualityDate, DATE_FORMAT_RUSSIAN) : EMDASH}
    </p>
    <span className={s.SortButton__icon}>
      <SortIcon />
    </span>
  </BaseButton>
);

const ButtonHeaderCell = ({
  item,
  index,
  onChangeSort,
  sort,
  actualityDate
}) => {
  const { value, label } = item;
  const isCurrentIndicatorClicked = value === sort.indicator;
  const handleSortChange = useCallback(
    () =>
      onChangeSort({
        indicator: value,
        direction: isCurrentIndicatorClicked
          ? getAnotherDirection(sort.direction)
          : 'desc'
      }),
    [value, isCurrentIndicatorClicked, onChangeSort, sort]
  );
  return (
    <th
      className={classNames(s.Table__cell, s.Table__buttonCell, {
        [s._active]: isCurrentIndicatorClicked
      })}
      key={index}
    >
      <SortButton
        className={classNames({
          [s._up]: sort.direction === 'asc' && isCurrentIndicatorClicked,
          [s._active]: isCurrentIndicatorClicked
        })}
        title={label}
        onClick={handleSortChange}
        actualityDate={actualityDate}
      />
    </th>
  );
};

const RegionRow = ({
  regionAndRatings,
  hasFpMbtFinances,
  position,
  activeSort,
  isHeader = false
}) => {
  const { region, ratings } = regionAndRatings;
  if (!region || !ratings) {
    return null;
  }

  return isHeader ? (
    <tr>
      <td className={s.Table__cell}>{position}</td>
      <td className={s.Table__cell}>
        <Link className={s.Link} to={`/regions/${region.code_nalog}`}>
          {region.name}
        </Link>
      </td>
    </tr>
  ) : (
    <tr>
      {indicators.map((indicator, index) => {
        if (hideAcceptBOInRatingPage && indicator.value === INDICATOR_ACCEPT) {
          return null;
        }

        const rating = ratings[indicator.value];
        const hasMbtFinance =
          indicator.value === INDICATOR_ACCEPT ||
          indicator.value === INDICATOR_CASH_COMPLETION
            ? hasFpMbtFinances
            : true;

        return (
          <td
            className={classNames(s.Table__cell, {
              [s._active]: activeSort.indicator === indicator.value
            })}
            key={index}
          >
            {hasMbtFinance ? (
              <Tag
                style={{ minWidth: 32, width: 'auto', padding: '0 4px' }}
                className={s.Table__tag}
                value={
                  indicator.value === INDICATOR_CASH_COMPLETION
                    ? rating
                      ? formatNumber(unificationNumber(rating, 100), 1) + '%'
                      : formatNumber(unificationNumber(rating, 100), 1)
                    : formatNumber(unificationNumber(rating, 100), 0)
                }
                status={getStatusByValue(
                  rating,
                  indicator.value === INDICATOR_DC_COMPLETION
                    ? STATUS_TYPES.COMPLETION
                    : STATUS_TYPES.RATING
                )}
              />
            ) : (
              <BaseTooltip tooltip={<TextTooltip text={noMbtFinances} />}>
                <div>
                  <Tag className={s.Table__tag} value={'*'} />
                </div>
              </BaseTooltip>
            )}
          </td>
        );
      })}
    </tr>
  );
};

const RatingsTableContent = ({
  regionsAndRatingsData,
  sort,
  setSort,
  actualityDate,
  dcCompletionActualityDate,
  obligationDate,
  indicatorsRatingActualityDate,
  resultsRatingActualityDate
}) => {
  const headRowsBoxRef = useRef(null);
  const contentRowsBoxRef = useRef(null);

  const setHeights = () => {
    const headRows =
      headRowsBoxRef.current && headRowsBoxRef.current.childNodes;
    const contentRows =
      contentRowsBoxRef.current && contentRowsBoxRef.current.childNodes;

    if (
      headRows &&
      headRows.length > 0 &&
      contentRows &&
      contentRows.length > 0
    ) {
      for (let i = 0; i < headRows.length; i++) {
        const headRow = headRows[i];
        const contentRow = contentRows[i];
        const headRowHeight = headRow.clientHeight;
        const contentRowHeight = contentRow.clientHeight;

        const height = Math.max(headRowHeight, contentRowHeight);
        headRow.style.height = height + 'px';
        contentRow.style.height = height + 'px';
      }
    }
  };

  useEffect(() => {
    if (regionsAndRatingsData) {
      setHeights();
    }
  }, [regionsAndRatingsData]);

  const getActualityDate = (item) => {
    switch (item.value) {
      case INDICATOR_ACCEPT:
        return obligationDate;
      case INDICATOR_CASH_COMPLETION:
        return actualityDate;
      case INDICATOR_DC_COMPLETION:
        return dcCompletionActualityDate;
      case INDICATOR_RESULT_ACH:
        return resultsRatingActualityDate;
      case INDICATOR_TARGET_ACH:
        return indicatorsRatingActualityDate;
      default:
        return null;
    }
  };

  return (
    <div className={s.Ratings__tableInner}>
      <ResizeObserver onResize={setHeights} />
      <div className={s.Ratings__tablePositions}>
        <table className={s.Ratings__table}>
          <thead>
            <tr>
              <th className={s.Table__cell} />
              <th className={s.Table__cell}>%</th>
            </tr>
          </thead>
          <tbody ref={headRowsBoxRef}>
            {regionsAndRatingsData ? (
              regionsAndRatingsData.map((regionAndRatings, index) => (
                <RegionRow
                  regionAndRatings={regionAndRatings}
                  hasFpMbtFinances={regionAndRatings.region.hasMbt}
                  key={regionAndRatings.region.code_nalog}
                  position={index + 1}
                  isHeader={true}
                />
              ))
            ) : (
              <tr>
                <td colSpan="2" />
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className={s.Ratings__tableContent}>
        <table className={s.Ratings__table}>
          <thead>
            <tr>
              {indicators.map((item, index) => {
                if (
                  hideAcceptBOInRatingPage &&
                  item.value === INDICATOR_ACCEPT
                ) {
                  return null;
                }

                return (
                  <ButtonHeaderCell
                    key={index}
                    index={index}
                    item={item}
                    onChangeSort={setSort}
                    sort={sort}
                    actualityDate={getActualityDate(item)}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody ref={contentRowsBoxRef}>
            {regionsAndRatingsData ? (
              regionsAndRatingsData.map((regionAndRatings, index) => (
                <RegionRow
                  activeSort={sort}
                  regionAndRatings={regionAndRatings}
                  hasFpMbtFinances={regionAndRatings.region.hasMbt}
                  key={regionAndRatings.region.code_nalog}
                />
              ))
            ) : (
              <tr>
                <td colSpan={indicators.length + 1}>Нет данных</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const RatingsTable = ({ filter }) => {
  const { sort, setSort } = useSort({
    indicator: indicators[0].value,
    direction: 'desc'
  });

  const [query, changeQuery] = useUrlQuery();
  const fpCode = +query.fp || 0;
  const grbs = query.grbs || 'all';
  const { yearMonth, year } = useQueryPeriodFast(query, changeQuery);

  const dcCompletionFilter = {
    year,
    federal_project_codes: fpCode ? [fpCode + ''] : undefined,
    date: formatDateIso(new Date())
  };

  const regionsWithRatingsQuery = useRegionsWithFinancialRatings(yearMonth, {
    federalProjectCodes: fpCode ? [fpCode + ''] : undefined,
    grbsCodes: grbs && grbs !== 'all' ? [grbs] : undefined,
    dcCompletionFilter
  });

  const regionsWithRatingsQueryData = regionsWithRatingsQuery.state.data;

  const actualityDate =
    regionsWithRatingsQueryData &&
    tryParseIso(regionsWithRatingsQueryData.actualityDate);
  const dcCompletionActualityDate = new Date();

  const [planDate, obligationDate] = getPlanAndObligationDate(
    regionsWithRatingsQueryData && regionsWithRatingsQueryData.finances
  );

  const [
    indicatorsRatingActualityDate,
    resultsRatingActualityDate
  ] = getIndicatorsAndResultsDate(regionsWithRatingsQueryData);

  const regionsAndRatingsData = useMemo(
    () =>
      regionsWithRatingsQueryData &&
      regionsWithRatingsQueryData.regions &&
      processRegionsWithFinancialRatings({
        regionsAndRatings: regionsWithRatingsQueryData.regions,
        filter,
        sort
      }),
    [sort, regionsWithRatingsQueryData, filter]
  );

  if (regionsWithRatingsQuery.check.spinner) {
    return <CircleProgressIndicator />;
  }

  return (
    <div className={s.Ratings__tableWRapper}>
      <RatingsTableContent
        regionsAndRatingsData={regionsAndRatingsData}
        setSort={setSort}
        sort={sort}
        actualityDate={actualityDate}
        obligationDate={obligationDate}
        dcCompletionActualityDate={dcCompletionActualityDate}
        indicatorsRatingActualityDate={indicatorsRatingActualityDate}
        resultsRatingActualityDate={resultsRatingActualityDate}
      />
    </div>
  );
};

export default RatingsPage;
