import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BaseButton from '../Button/BaseButton';
import s from './Tab.module.scss';

const PRIMARY = 'PRIMARY';
const SECONDARY = 'SECONDARY';

export function Tab({ children, active, className, variant, ...props }) {
  return (
    <BaseButton
      className={classNames(
        s.Tab,
        { [s._secondary]: variant === SECONDARY },
        className
      )}
      {...props}
    >
      <div
        className={classNames(s.Tab__article, {
          [s._active]: active
        })}
      >
        {children}
      </div>
    </BaseButton>
  );
}

Tab.PRIMARY = PRIMARY;
Tab.SECONDARY = SECONDARY;

Tab.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
  variant: PropTypes.oneOf([PRIMARY, SECONDARY])
};
