import React, { useCallback, useEffect, useState } from 'react';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import classNames from 'classnames';
import { formatValueUnit } from '../../../utils/formatNumber';
import {
  getStatusByValue,
  getStatusColor,
  STATUS_TYPES
} from '../../../utils/constants';
import { INDICATOR_DC_COMPLETION } from '../../../utils/indicators';
import s from './RangeSlider.module.scss';

function getHandleColor(value, indicatorId) {
  const statusType =
    indicatorId === INDICATOR_DC_COMPLETION
      ? STATUS_TYPES.COMPLETION
      : STATUS_TYPES.RATING;

  return getStatusColor(getStatusByValue(value, statusType));
}

const RangeSlider = ({
  min,
  max,
  step,
  value,
  onChange,
  formatValue,
  isMobile,
  collapsed,
  indicatorId
}) => {
  const [currentRange, setCurrentRange] = useState(value);

  useEffect(() => {
    const valueMin = Math.max(value[0], min);
    const valueMax = Math.min(value[1], max);
    if (valueMin !== value[0] || valueMax !== value[1]) {
      onChange([valueMin, valueMax]);
    } else {
      setCurrentRange(value);
    }
  }, [value, min, max, onChange]);

  const handleChange = useCallback((e) => setCurrentRange(e), []);

  const leftOffsetStyle = {
    width: currentRange[0] + '%'
  };
  const rightOffsetStyle = {
    left: currentRange[1] + '%'
  };

  return (
    <div className={s.RangeContainer}>
      <Range
        className={classNames(s.Range, {
          [s._mobile]: isMobile,
          [s._collapsed]: collapsed,
          [s['_type-completion']]: indicatorId === INDICATOR_DC_COMPLETION
        })}
        defaultValue={currentRange}
        min={min}
        max={max}
        marks={{
          [currentRange[0]]: formatValue(currentRange[0]),
          [currentRange[1]]: formatValue(currentRange[1])
        }}
        allowCross={false}
        pushable={1}
        onChange={handleChange}
        onAfterChange={onChange}
        step={step}
        handleStyle={[
          {
            borderColor: getHandleColor(currentRange[0], indicatorId)
          },
          {
            borderColor: getHandleColor(currentRange[1], indicatorId)
          }
        ]}
      >
        <div className={s.Range__axis}>
          <div className={s.Range__leftOffset} style={leftOffsetStyle} />
          <div className={s.Range__rightOffset} style={rightOffsetStyle} />
        </div>
      </Range>
    </div>
  );
};

RangeSlider.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  formatValue: (x) => formatValueUnit(x, '%', 0)
};

export default RangeSlider;
