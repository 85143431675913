import React, { useEffect, useRef, useState } from 'react';
import { useContextStore } from '@proscom/prostore-react';
import { __RouterContext as RouterContext } from 'react-router';

import AppRoutes from './routes';
import { ErrorBoundary } from './common/ErrorBoundary';
import ErrorPage from './common/ErrorPage';
import { useRouter } from './store/useRouter';
import { STORE_LOCATION } from './store/stores';
import { UserTracker } from './UserTracker';

export default function App() {
  // Подписываемся на изменения от react-router, получаем контекст роутера
  const routerContext = useRouter();

  // Инжектируем наш LocationStore
  const locationStore = useContextStore(STORE_LOCATION);

  // При обновлении location в react-router, меняем его в нашем сторе
  const { location } = routerContext;
  useEffect(() => {
    locationStore.updateLocation(location);
  }, [locationStore, location]);

  // Когда меняется наш стор, обновляем сохраненный контекст роутера
  // Таким образом контекст роутера синхронизирован с нашим стором
  // При этом все подписчики locationStore в том числе этот
  // получают обновления синхронно, поэтому реакт собирает их в кучу
  // и оптимизирует

  // Место для хранения синхронизированного контекста
  const [syncedContext, setSyncedContext] = useState(null);

  // Запоминаем последний актуальный контекст
  const routerContextRef = useRef(routerContext);
  routerContextRef.current = routerContext;

  // При обновлении нашего стора синхронизируем контекст
  useEffect(() => {
    const subscription = locationStore.state$.subscribe(() => {
      setSyncedContext(routerContextRef.current);
    });
    return () => subscription.unsubscribe();
  }, [locationStore.state$]);

  if (!syncedContext) return false;
  return (
    <RouterContext.Provider value={syncedContext}>
      <ErrorBoundary component={ErrorPage}>
        <AppRoutes />
        <UserTracker />
      </ErrorBoundary>
    </RouterContext.Provider>
  );
}
