import React from 'react';
import { Button } from '../../common/ui/Button/Button';
import s from './IndexPage.module.scss';

const getBtnOptions = (tab, chartPart) => {
  if (tab === 'budget_assign') {
    return chartPart === 'federal'
      ? { title: 'Подробнее по федеральному бюджету', link: '/indicators' }
      : chartPart === 'mbt'
      ? { title: 'Подробнее по МБТ', link: '/indicators' }
      : null;
  } else if (tab === 'cash_exec') {
    return chartPart === 'federal'
      ? { title: 'Подробнее с внебюджетными источ.', link: '/indicators' }
      : chartPart === 'mbt'
      ? {
          title: 'Подробнее о региональном бюджете',
          link: '/indicators/regions'
        }
      : null;
  }
};

export function TableInfoButton({ createLink, tab, chartPart }) {
  const btnOptions = getBtnOptions(tab, chartPart);

  return btnOptions ? (
    <Button
      className={s.TableContainer__link}
      variant={Button.PRIMARY}
      to={createLink(btnOptions.link || '/indicators')}
    >
      {btnOptions.title}
    </Button>
  ) : null;
}
