import React, { useMemo } from 'react';
import mapImage from '../../assets/Icons/mapImage.svg';
import { ReactComponent as RFLogo } from '../../assets/Icons/emblems/RF.svg';
import { CircleIndicator } from '../../common/ui/Project/ProjectIndicator';
import { formatNumber } from '../../utils/formatNumber';
import s from './RegionsIndicatorPage.module.scss';

function MapView({ pof, cash_expenses }) {
  const tabs = useMemo(
    () => [
      {
        statuses: ['default'],
        value: pof,
        title: 'ПОФ, млрд'
      },
      {
        statuses: ['success', 'warn', 'error'],
        value: cash_expenses,
        title: 'Кассовое исполнение, млрд'
      }
    ],
    [pof, cash_expenses]
  );

  return (
    <div className={s.MapView}>
      <div className={s.MapView__map}>
        <div
          style={{
            background: `url(${mapImage}) no-repeat bottom`,
            width: '100%',
            height: '100%'
          }}
        />
      </div>
      <div className={s.MapView__content}>
        <div className={s.MapView__flag}>
          <RFLogo />
        </div>
        <div className={s.MapView__title}>Российская Федерация</div>
        <div className={s.finance}>
          {tabs.map((item) => (
            <div key={item.title} className={s.finance__item}>
              {item.statuses.map((item, index) => (
                <div key={index} className={s.finance__status}>
                  <CircleIndicator
                    status={item}
                    className={s.finance__status_svg}
                  />
                </div>
              ))}
              <div className={s.finance__data}>
                <div className={s.finance__number}>
                  {formatNumber(item.value, 2)}
                </div>
                <div className={s.finance__description}>{item.title}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

MapView.propTypes = {};

export default MapView;
