import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { addMonths, format, isSameDay, setYear, startOfMonth } from 'date-fns';

import { TopBar } from '../../common/TopBar/TopBar';
import { ReactComponent as BackIcon } from '../../assets/Icons/Arrow/Back.svg';
import { ReactComponent as FrontIcon } from '../../assets/Icons/Arrow/Front.svg';
import { IconButton } from '../../common/ui/IconButton/IconButton';
import { Button } from '../../common/ui/Button/Button';
import { TopBarAllProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { RightPaneLayout } from '../../common/GridLayout/RightPaneLayout';
import {
  useAllCalendarEvents,
  useCalendarEventsStatus
} from '../../store/useCalendarEvents';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { DATE_FORMAT_ISO_DATE, lFormat, tryParseIso } from '../../utils/date';
import { RightMenuPopup } from '../../common/ui/RightMenuPopup/RightMenuPopup';
import { useFederalProjects } from '../../store/useFederalProjects';
import { useWindowSize } from '../../store/useWindowSize';
import { FlyingButton } from '../../common/FlyingBox/FlyingBox';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';

import { clampYear } from '../../utils/constants';
import { useValueRef } from '../../utils/useValueRef';
import { useCurrentDate } from '../../utils/useCurrentDate';
import { queryLoader } from '../../common/QueryLoader';

import { FiltersPopup } from '../../common/FiltersPopup/FiltersPopup';
import {
  URL_QUERY_EVENT_TYPE,
  URL_QUERY_LEVEL,
  URL_QUERY_REGION_CODE
} from '../../store/stores';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';

import { DayEventList } from './EventList/DayEventList';
import Calendar from './Calendar/Calendar';
import { EventsFilter } from './EventsFilter/EventsFilter';
import { FullEvent } from './FullEvent';

import s from './CalendarPage.module.scss';

const CalendarPageContent = React.memo(function CalendarPageContent() {
  const federalProjectsQuery = useFederalProjects();
  const federalProjects = federalProjectsQuery.state.data;
  return (
    queryLoader(federalProjectsQuery) || (
      <CalendarPageContentInner federalProjects={federalProjects} />
    )
  );
});

function CalendarPageContentInner({ federalProjects }) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;
  const [rightOpened, setRightOpened] = useState(false);
  const popupRef = useRef(null);

  const currentDate = useCurrentDate();
  const [query, changeQuery] = useUrlQuery();
  const calendarDate = tryParseIso(query.date) || currentDate;
  const [currentMonth, setCurrentMonth] = useState(() =>
    startOfMonth(calendarDate)
  );
  const fpCode = +query.fp || 0;
  const federalProject =
    federalProjects &&
    federalProjects.find((fp) => +fp.project_code === fpCode);
  const { createLink } = usePreserveQueryFast(query);

  const eventType = query[URL_QUERY_EVENT_TYPE];
  const regionCode = query[URL_QUERY_REGION_CODE];
  const eventLevel = query[URL_QUERY_LEVEL];

  const allEvents = useCalendarEventsStatus(
    {
      month: currentMonth,
      federalProjects: federalProject && [federalProject.id],
      regionCode: regionCode,
      eventLevel
    },
    eventType
  );

  const calendarEvents = allEvents.state.data || [];

  const currentDayQuery = useAllCalendarEvents(
    {
      day: calendarDate,
      federalProjects: federalProject && [federalProject.id],
      regionCode: regionCode,
      eventLevel
    },
    eventType
  );

  const previousValuesRef = useRef({});
  const year = query.year;
  const currentValuesRef = useValueRef({
    calendarDate,
    year,
    currentMonth
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const previous = previousValuesRef.current;
    const current = currentValuesRef.current;
    if (!isSameDay(current.calendarDate, previous.calendarDate)) {
      setCurrentMonth(startOfMonth(current.calendarDate));
    } else if (current.year !== previous.year) {
      setCurrentMonth(startOfMonth(setYear(currentMonth, current.year)));
    } else if (current.currentMonth !== previous.currentMonth) {
      const fullYear = current.currentMonth.getFullYear();
      const newYear = clampYear(fullYear);
      if (+newYear !== +current.year) {
        changeQuery({ year: newYear });
      }
    }
    previousValuesRef.current = current;
  });

  const currentDayEvents = currentDayQuery.state.data || [];

  const onChangeCurrentMonth = (newDate) => {
    setCurrentMonth(startOfMonth(newDate));
  };

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  const setCalendarDate = useCallback(
    (date) => {
      changeQuery({ date: format(date, DATE_FORMAT_ISO_DATE) });
    },
    [changeQuery]
  );

  const onPopupClose = useCallback(() => changeQuery({ event: undefined }), [
    changeQuery
  ]);

  const selectedEvent =
    query.event && currentDayEvents?.find(({ id }) => id === query.event);

  const filtersCount = useMemo(() => {
    let count = 0;
    const eventType = query[URL_QUERY_EVENT_TYPE];
    const level = query[URL_QUERY_LEVEL];
    const regionCode = query[URL_QUERY_REGION_CODE];
    if (eventType) {
      count++;
      if (level) {
        count++;
        if (regionCode) {
          count++;
        }
      }
    }
    return count;
  }, [query]);

  return (
    <RightPaneLayout
      topBar={
        <TopBar>
          <TopBarAllProjects />
        </TopBar>
      }
      right={
        <div className={s.CalendarPage__Calendar}>
          <div className={s.Calendar}>
            <div className={s.Calendar__header}>
              <div className={s.Calendar__month}>
                <span>{lFormat(currentMonth, 'LLLL yyyy')}</span>
              </div>
              {allEvents.check.spinner && (
                <CircleProgressIndicator className={s.Calendar__monthSpinner} />
              )}
              <div className={s.Calendar__controls}>
                <IconButton
                  icon={BackIcon}
                  onClick={() =>
                    onChangeCurrentMonth(addMonths(currentMonth, -1))
                  }
                />
                <IconButton
                  icon={FrontIcon}
                  onClick={() =>
                    onChangeCurrentMonth(addMonths(currentMonth, 1))
                  }
                />
              </div>
            </div>
            <div className={s.Calendar__calendar}>
              <Calendar
                events={calendarEvents}
                selectedMonth={currentMonth}
                calendarDate={calendarDate}
                currentDate={currentDate}
                onChangeCalendarDate={setCalendarDate}
                isLoading={allEvents.check.spinner}
              />
            </div>
          </div>
          <Button
            className={s.CalendarPage__report}
            to={createLink('/reports', {
              reportType: 'events'
            })}
          >
            Отчёт по мероприятиям
          </Button>
        </div>
      }
      extra={
        <>
          {isMobile && (
            <FlyingButton onClick={onRightOpen}>
              <span>Календарь</span>
            </FlyingButton>
          )}
          <RightMenuPopup
            className={s.CalendarPageSidebar}
            onClose={onPopupClose}
            isActive={selectedEvent}
            outsideRefs={[popupRef]}
          >
            {selectedEvent && (
              <FullEvent
                date={calendarDate}
                event={selectedEvent}
                popupRef={popupRef}
              />
            )}
          </RightMenuPopup>
        </>
      }
      onRightClose={onRightClose}
      rightOpened={rightOpened}
    >
      {ws.isMobile ? (
        <FiltersPopup filtersCount={filtersCount} title={'Настройки'}>
          <EventsFilter />
        </FiltersPopup>
      ) : (
        <EventsFilter />
      )}
      {queryLoader(currentDayQuery) || (
        <DayEventList
          events={currentDayEvents}
          date={calendarDate}
          onDateChange={setCalendarDate}
          className={s.CalendarPage__EventList}
          onEventClick={(item) => changeQuery({ event: item.id })}
        />
      )}
    </RightPaneLayout>
  );
}

export default function CalendarPage() {
  return <CalendarPageContent />;
}
