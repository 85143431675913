import React, { useCallback, useState } from 'react';
import { RightPaneLayout } from '../../common/GridLayout/RightPaneLayout';
import { useWindowSize } from '../../store/useWindowSize';
import { FlyingButton } from '../../common/FlyingBox/FlyingBox';
import { ReactComponent as ReportIcon } from '../../assets/Icons/Document.svg';
import { ReportSidebar, ReportSwipeBoxContent } from './common/ReportSidebar';
import { ReportsForm } from './ReportsForm';
import { ReportView } from './ReportsView';
import s from './ReportsPage.module.scss';

function ReportsPage({ history, match, location }) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const [rightOpened, setRightOpened] = useState(false);

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  const handleGoBack = useCallback(() => {
    history.goBack('/');
  }, [history]);

  return (
    <RightPaneLayout
      topBar={false}
      fixed={true}
      right={
        isMobile ? (
          <ReportSwipeBoxContent title="Конструктор отчетов">
            <ReportsForm history={history} location={location} />
          </ReportSwipeBoxContent>
        ) : (
          <ReportSidebar title="Конструктор отчетов">
            <ReportsForm history={history} location={location} />
          </ReportSidebar>
        )
      }
      extra={
        isMobile && (
          <FlyingButton
            className={s.Reports__flyingButton}
            onClick={onRightOpen}
          >
            <ReportIcon />
            <span>Конструктор отчетов</span>
          </FlyingButton>
        )
      }
      onRightClose={onRightClose}
      rightOpened={rightOpened}
    >
      <ReportView onGoBack={handleGoBack} isMobile={ws.isMobile} />
    </RightPaneLayout>
  );
}

export default ReportsPage;
