import React, { useCallback, useMemo, useState } from 'react';
import { BaseErrorMessage, ErrorMessage } from '../../common/ErrorMessage';
import { useRegionsWithRProjects } from '../../store/useRegions';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { useFederalProjects } from '../../store/useFederalProjects';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { useWindowSize } from '../../store/useWindowSize';
import { IndicatorsPageFpComponent } from './IndicatorsPageFpComponent';

export const IndicatorsPageFp = React.memo(function IndicatorsPageFp({
  history
}) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const regionsQuery = useRegionsWithRProjects();
  const [query] = useUrlQuery();
  const fpCode = +query.fp || 0;
  const federalProjectsQuery = useFederalProjects();
  const federalProjects = federalProjectsQuery.state.data;
  const federalProject =
    federalProjects &&
    federalProjects.find((fp) => +fp.project_code === fpCode);
  const regionCode = +query.region_code || null;

  const regionsData = regionsQuery.state.data;
  const region =
    regionsData && regionsData.find((r) => +r.code_nalog === regionCode);
  const selectedRegionalProject = useMemo(() => {
    if (!regionCode || !region || !federalProject) return null;
    return region.regional_projects.find(
      (rp) => rp.federal_project_id === federalProject.id
    );
  }, [region, regionCode, federalProject]);

  const [isGoalsOpen, setIsGoalsOpen] = useState(false);

  const chartFilter = useMemo(
    () =>
      federalProject
        ? {
            federal_project_ids: federalProject.id,
            federal_project_codes: federalProject.project_code
          }
        : null,
    [federalProject]
  );

  const chartFilterRp = useMemo(
    () =>
      fpCode && region && region.id
        ? {
            federalProjectCodes: [fpCode + ''],
            regionIds: [region.id]
          }
        : null,
    [fpCode, region]
  );

  const [rightOpened, setRightOpened] = useState(false);

  const onRightOpen = useCallback(() => {
    setRightOpened(true);
  }, []);
  const onRightClose = useCallback(() => {
    setRightOpened(false);
  }, []);

  if (federalProjectsQuery.check.spinner) {
    return <CircleProgressIndicator />;
  }
  if (federalProjectsQuery.check.error) {
    return <ErrorMessage error={federalProjectsQuery.state.error} />;
  }
  if (!federalProject) {
    return (
      <BaseErrorMessage
        text={`Федеральный проект с кодом ${fpCode} не найден`}
      />
    );
  }

  return (
    <IndicatorsPageFpComponent
      {...{
        regionsQuery,
        fpCode,
        federalProject,
        selectedRegionalProject,
        setIsGoalsOpen,
        isGoalsOpen,
        onRightOpen,
        onRightClose,
        rightOpened,
        chartFilter,
        chartFilterRp,
        isMobile,
        history
      }}
    />
  );
});
