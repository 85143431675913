import React from 'react';
import classNames from 'classnames';
import {
  CONTROL_POINT,
  CONTROL_POINT_EVENT,
  EventCard,
  RegionalEventCard
} from '../ui/Card/EventCard';
import { getDataName } from '../../utils/data';
import s from './EventsList.module.scss';

function ControlPoint({ event, totalRegions }) {
  const name = getDataName(event);
  // todo Данных по статусам, фактическим срокам и документам пока нету.
  //  Подключить когда будут
  return (
    <EventCard
      title={name}
      type={CONTROL_POINT}
      event={event}
      total={totalRegions}
      overdue={true}
    />
  );
}

function ControlPointEvents({ events, totalRegions }) {
  return events.map((event, icpe) => {
    const name = getDataName(event);
    // todo Данных по статусам, фактическим срокам и документам пока нету.
    //  Подключить когда будут
    return (
      <EventCard
        key={icpe}
        title={name}
        event={event}
        type={CONTROL_POINT_EVENT}
        total={totalRegions}
        overdue={true}
      />
    );
  });
}

function ControlPointRegional({ event }) {
  const name = getDataName(event);
  // todo Данных по статусам, фактическим срокам и документам пока нету.
  //  Подключить когда будут
  return <RegionalEventCard title={name} type={CONTROL_POINT} overdue={true} />;
}

function ControlPointEventRegional({ events }) {
  return events.map((event, icpe) => {
    const name = getDataName(event);
    // todo Данных по статусам, фактическим срокам и документам пока нету.
    //  Подключить когда будут
    return (
      <RegionalEventCard
        key={icpe}
        title={name}
        type={CONTROL_POINT_EVENT}
        overdue={true}
      />
    );
  });
}

function ControlPoints({ controlPoints, totalRegions }) {
  return (
    <div>
      {controlPoints.map((cp, iCp) => {
        return (
          <div key={iCp}>
            <ControlPoint key={iCp} event={cp} totalRegions={totalRegions} />
            <div className={s.ControlPointsList__events}>
              {cp.events ? <ControlPointEvents events={cp.events} /> : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function ControlPointsRegional({ controlPoints, totalRegions }) {
  return (
    <div>
      {controlPoints.map((cp, iCp) => {
        return (
          <div key={iCp}>
            <ControlPointRegional key={iCp} event={cp} />
            <div className={s.ControlPointsList__events}>
              {cp.events ? (
                <ControlPointEventRegional events={cp.events} />
              ) : null}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export function ControlPointsList({
  controlPoints,
  currentRegion,
  totalRegions,
  className,
  regionCode
}) {
  if (!controlPoints) {
    return;
  }

  return (
    <div className={classNames(s.RoadmapEventsList, className)}>
      {/*{currentRegion ? (*/}
      {regionCode ? (
        <ControlPointsRegional controlPoints={controlPoints} />
      ) : (
        <ControlPoints
          controlPoints={controlPoints}
          totalRegions={totalRegions}
        />
      )}
    </div>
  );
}
