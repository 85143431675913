import React from 'react';
import classNames from 'classnames';
import { format, isValid, parseISO } from 'date-fns';
import { DATE_FORMAT_RUSSIAN } from '../../utils/date';
import { EMDASH } from '../../utils/utf';
import s from './ActualityDate.module.scss';

const Variant = {
  GRAY: 'gray',
  NAV: 'nav',
  DESCRIPTION: 'description'
};

export function ActualityDate({
  date,
  className,
  variant = Variant.GRAY,
  isActuality,
  text
}) {
  const dateObj = typeof date === 'string' ? parseISO(date) : date;
  const formatted = isValid(dateObj)
    ? format(dateObj, DATE_FORMAT_RUSSIAN)
    : EMDASH;

  return (
    <div
      className={classNames(s.ActualityDate, className, {
        [s.ActualityDate_nav]: variant === Variant.NAV,
        [s.ActualityDate_gray]: variant === Variant.GRAY,
        [s.ActualityDate_description]: variant === Variant.DESCRIPTION
      })}
    >
      <p>{text || (isActuality ? 'Актуально' : 'По состоянию')}</p>
      <p className={s.ActualityDate__date}>
        {(text && ' ') ||
          (variant === Variant.GRAY && 'на ') ||
          (variant === Variant.DESCRIPTION && ' на ') ||
          ''}
        {formatted}
      </p>
    </div>
  );
}

ActualityDate.Variant = Variant;
