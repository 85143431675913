import React from 'react';
import PropTypes from 'prop-types';
import { arc, interpolateHcl, path, sum } from 'd3';
import { pie } from '../../utils/pie';
import { degreeToRad } from '../../utils/math';
import { BaseTooltip } from '../ui/ToolTip/BaseTooltip';

const PIPI = 2 * Math.PI;

function DonutPieChart({
  activeId,
  data,
  color,
  endColor,
  dashColor,
  total,
  dashWidth,
  isDash,
  size,
  innerRadius,
  outerRadius,
  gapAngle,
  maxAngle = PIPI,
  strokeDasharray,
  className,
  clickInnerRadius,
  clickOuterRadius,
  onPathClick,
  tooltip,
  pieTooltip,
  collapseEmpty = true,
  onPieClick,
  backgroundDash
}) {
  // Создание и настройка генератора размера частей диаграммы
  const pieGenerator = pie()
    .collapseEmpty(collapseEmpty)
    .value((item) => item.value)
    .maxAngle(maxAngle)
    .minSegmentAngle(0.02);

  // Задаем максимальный угол диаграммы
  if (total) {
    pieGenerator.totalCount(total);
  }

  // Задаем угол разделителя
  if (gapAngle) {
    pieGenerator.gapAngle(gapAngle);
  }

  // Преобразование данных в угловые координаты для графика
  const chartData = pieGenerator(data);

  // Создание генератора сегментов графика (для svg)
  const arcGenerator = arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius);

  const clickArc = arc()
    .innerRadius(clickInnerRadius || innerRadius)
    .outerRadius(clickOuterRadius || outerRadius);

  // Интерполятор цветов
  const colorInterpolator =
    color && endColor ? interpolateHcl(color, endColor) : () => color;

  // Обьединяем данные для создания svg элемента
  const pathArray = chartData.map((item, index) => {
    return {
      id: item.data.id ? item.data.id : item.index,
      // Если есть диапазон цвета, то интерполируем
      // Если нет, то создаем функцию возвращающую исходный цвет
      color:
        item.data.color || colorInterpolator((index + 1) / chartData.length),
      path: arcGenerator({
        startAngle: item.startAngle,
        endAngle: item.endAngle
      }),
      data: item.data,
      clickPath: clickArc({
        startAngle: item.startAngle,
        endAngle: item.endAngle
      })
    };
  });

  const fullArc = clickArc({
    startAngle: 0,
    endAngle: 2 * Math.PI
  });

  // если диаграмма не полная и нужен пунктир
  let dash = null;
  const radius = (innerRadius + outerRadius) / 2;

  const sumData = sum(data.map((item) => item.value));
  if (isDash && total && total > sumData) {
    const pathContext = path();

    const startAngle = (sumData / total) * 2 * Math.PI - degreeToRad(90);
    const endAngle = Math.PI * 2 - degreeToRad(90);

    pathContext.arc(0, 0, radius, startAngle, endAngle);
    dash = pathContext.toString();
  }
  if (isDash && backgroundDash) {
    const pathContext = path();

    pathContext.arc(0, 0, radius, degreeToRad(0), degreeToRad(360));
    dash = pathContext.toString();
  }

  return (
    <svg
      className={className}
      viewBox={`${-size / 2} ${-size / 2} ${size} ${size}`}
      width={size}
      height={size}
    >
      {pathArray.map((item) => {
        return (
          <path
            key={item.id}
            d={item.path}
            strokeWidth={0}
            fill={item.color}
            opacity={
              !activeId ||
              activeId === item.id ||
              item.id.split('&')[1] === activeId
                ? 1
                : 0.06
            }
          />
        );
      })}
      {isDash && dash ? (
        <path
          fill="none"
          stroke={dashColor}
          strokeWidth={dashWidth}
          key="isDash"
          d={dash}
          strokeDasharray={strokeDasharray}
        />
      ) : null}
      {onPieClick || onPathClick || tooltip || pieTooltip ? (
        <g onClick={onPieClick}>
          {pieTooltip ? (
            <BaseTooltip tooltip={pieTooltip(pathArray)}>
              <path d={fullArc} fill="transparent" />
            </BaseTooltip>
          ) : (
            onPieClick && <path d={fullArc} fill="transparent" />
          )}
          {(onPathClick || tooltip) &&
            pathArray.map((item) => {
              const path = (
                <path
                  onClick={
                    onPathClick ? (e) => onPathClick(item.id, e) : undefined
                  }
                  key={item.id}
                  d={item.clickPath}
                  fill="transparent"
                />
              );
              if (tooltip) {
                return (
                  <BaseTooltip
                    key={`tooltip__donut__${item.id}`}
                    tooltip={tooltip(item.data)}
                  >
                    {path}
                  </BaseTooltip>
                );
              }
              return path;
            })}
        </g>
      ) : (
        false
      )}
    </svg>
  );
}

DonutPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      value: PropTypes.number.isRequired
    })
  ).isRequired,
  size: PropTypes.number.isRequired,
  outerRadius: PropTypes.number.isRequired,
  color: PropTypes.string,
  endColor: PropTypes.string,
  dashColor: PropTypes.string,
  total: PropTypes.number,
  dashWidth: PropTypes.string,
  strokeDasharray: PropTypes.string,
  className: PropTypes.string,
  innerRadius: PropTypes.number,
  isDash: PropTypes.bool,
  gapAngle: PropTypes.number,
  onPathClick: PropTypes.func,
  activeId: PropTypes.string,
  tooltipRender: PropTypes.func
};

DonutPieChart.defaultProps = {
  color: 'blue',
  endColor: 'blue',
  dashColor: 'blue',
  dashWidth: '2px',
  strokeDasharray: '4,4',
  padAngle: '2px',
  innerRadius: 0,
  gapAngle: 0
};

export default DonutPieChart;
