/**
 * Преобразует data у результата useGraphqlQuery
 * @param query - результат запроса
 * @param mapFn - функция преобразователь
 */
export function mapRequestQuery(query, mapFn) {
  return {
    ...query,
    state: {
      ...query.state,
      data: query.state.data && mapFn(query.state.data)
    }
  };
}
