import { uniqBy } from 'lodash-es';
import { compareSimple, makeComparator } from '../../utils/data';

/*
Парсим "План субсидиарных сущностей" ДК для того, чтобы выбрать из записей уникальные регионы.
subsidiaryEntityPlans должно быть отфильтровано до того, как попасть сюда. Например, на бэкенде
 */
export function processSubsidiaryEntityPlans(
  subsidiaryEntityPlans = [],
  selectedRegionCode
) {
  const regions = subsidiaryEntityPlans?.map((plan) => plan.region);

  const uniqueRegions = regions
    ? uniqBy(regions, (x) => x.id).sort(
        makeComparator(compareSimple, (region) => region.name)
      )
    : [];

  const selectedRegion =
    uniqueRegions?.find((item) => +item.code_nalog === +selectedRegionCode) ||
    null;

  const totalRegions = uniqueRegions?.length || 0;

  return {
    regionsOfImplementation: uniqueRegions,
    selectedRegion,
    totalRegions
  };
}
