import { isValidNumber } from '../utils/math';

export const Organization = {
  getCoordinates(object) {
    if (!object.bs_latitude || !object.bs_longitude) {
      return null;
    }
    const lat = +object.bs_latitude;
    const lng = +object.bs_longitude;
    if (isValidNumber(lat) && isValidNumber(lng)) {
      return [lat, lng];
    } else {
      return null;
    }
  }
};
