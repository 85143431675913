import React, { useCallback, useRef } from 'react';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { useReportRequest } from '../ReportPage/ReportPage';
import { InfographicCharts } from '../ReportPage/infographic/InfographicCharts';
import { InfographicEvents } from '../ReportPage/infographic/InfographicEvents';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { getMonthName } from '../../utils/constants';
import s from './common.module.scss';

export default function InfographicsReportPage({ location }) {
  const [query, changeQuery] = useUrlQuery();
  const { yearMonth, year, month } = useQueryPeriodFast(query, changeQuery);
  const { result, region, federalProject } = useReportRequest(query);

  const onLoad = useCallback(() => {
    window.status = 'ready';
    console.log('ready to export pdf');
  }, []);

  const promisesRef = useRef({});
  const resolversRef = useRef({});

  // Создадим список промисов, для ожидания окончания загрузки данных
  if (!promisesRef.chartsPromise) {
    promisesRef.current = {
      chartsPromise: new Promise(
        (resolve) => (resolversRef.current.charts = resolve)
      ),
      roadmapEventPromise: new Promise(
        (resolve) => (resolversRef.current.roadmapEventsInfo = resolve)
      ),
      controlPointEventPromise: new Promise(
        (resolve) => (resolversRef.current.controlPointEventsInfo = resolve)
      )
    };
    Promise.all([
      promisesRef.current.chartsPromise,
      promisesRef.current.roadmapEventPromise,
      promisesRef.current.controlPointEventPromise
    ]).then(onLoad);
  }

  const nextProps = {
    region,
    result,
    federalProject,
    yearMonth,
    year,
    month
  };

  return (
    <div className={s.PreviewLayout}>
      <div className={s.content}>
        <h1>{`Отчёт за ${getMonthName(month)} ${year}`}</h1>
        <InfographicCharts
          {...nextProps}
          onLoad={resolversRef.current.charts}
        />
        <InfographicEvents
          {...nextProps}
          onLoadRoadmapEvent={resolversRef.current?.roadmapEventsInfo}
          onLoadControlPointEvent={resolversRef.current?.controlPointEventsInfo}
        />
      </div>
    </div>
  );
}
