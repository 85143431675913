import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { QUERY_SEARCH_ROADMAPS } from '../../../graphql/queries/results';
import { useSearchArrayQuery } from './_util';

const searchRoadmapsQueryOptions = {
  query: QUERY_SEARCH_ROADMAPS,
  mapData: (result) => result.federalProjectRoadmaps
};

export function useSearchRoadmaps(search) {
  const query = useGraphqlQuery({
    queryOptions: searchRoadmapsQueryOptions
  });
  return useSearchArrayQuery(query, search, (d) => d.name_full || d.name);
}
