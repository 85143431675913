import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Popover } from '../Popover/Popover';
import s from './BaseToolTip.module.scss';

export function BaseTooltip({
  id,
  tooltip,
  isOpen,
  initialOpen,
  children,
  pointerColor,
  style,
  className,
  margin,
  ...props
}) {
  const [isActive, setIsActive] = useState(initialOpen ? initialOpen : false);
  const tooltipRef = useRef(null);
  const targetRef = useRef(null);

  const showTooltip = useCallback(() => {
    setIsActive(true);
  }, []);

  const hideTooltip = useCallback(() => {
    setIsActive(false);
  }, []);

  useEffect(() => {
    if (isActive) {
      const listener = (e) => {
        if (
          e.target !== targetRef.current &&
          !targetRef.current.contains(e.target)
        ) {
          hideTooltip();
        }
      };
      window.addEventListener('touchstart', listener);
      return () => window.removeEventListener('touchstart', listener);
    }
  }, [isActive, hideTooltip]);

  return (
    <>
      {tooltip && isActive ? (
        <Popover targetNode={targetRef.current} interactive={false}>
          <div
            ref={tooltipRef}
            className={classNames(s.ToolTip, className)}
            {...props}
          >
            {tooltip}
          </div>
        </Popover>
      ) : null}

      {React.cloneElement(children, {
        ref: targetRef,
        onMouseOver: showTooltip,
        onMouseLeave: hideTooltip,
        onTouchStart: showTooltip
      })}
    </>
  );
}

BaseTooltip.propTypes = {
  id: PropTypes.number,
  tooltip: PropTypes.any,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  pointerColor: PropTypes.string,
  isOpen: PropTypes.bool,
  initialOpen: PropTypes.bool,
  margin: PropTypes.bool
};
