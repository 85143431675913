import React, { useCallback } from 'react';
import { Redirect } from 'react-router';
import { FastField, Formik } from 'formik';
import { useStore } from '@proscom/prostore-react';
import { STORE_AUTH } from '../../store/stores';
import { Button } from '../../common/ui/Button/Button';
import { required } from '../../utils/validators';
import {
  getGraphqlErrorMessage,
  mutationErrors
} from '../../graphql/graphqlErrors';
import { FormikTextInputQuestions } from '../../common/ui/TextInputQuestions/FormikTextInputQuestions';
import { LoginPageLayout } from '../_layouts/LoginPageLayout';
import s from './LoginPage.module.scss';

export default function LoginPage({ history }) {
  const [, authStore] = useStore(STORE_AUTH);
  if (authStore.isLoggedIn()) {
    return <Redirect to="/" />;
  }
  return (
    <LoginPageLayout title="Войдите в систему">
      <LoginForm history={history} login={authStore.loginWithEmail} />
    </LoginPageLayout>
  );
}

const LoginForm = ({ history, login }) => {
  const handleSubmit = useCallback(
    (values, actions) => {
      actions.setStatus('');
      login(values.login, values.password)
        .then(() => {
          history.push('/');
        })
        .catch((e) => {
          const error = getGraphqlErrorMessage(e, mutationErrors.login);
          actions.setStatus(error);
        })
        .then(() => {
          actions.setSubmitting(false);
        });
    },
    [history, login]
  );

  return (
    <Formik
      initialValues={{ login: '', password: '' }}
      component={FormView}
      onSubmit={handleSubmit}
    />
  );
};

const FormView = ({ handleSubmit, status, errors, touched, isSubmitting }) => {
  return (
    <form className={s.Form} onSubmit={handleSubmit}>
      <FastField
        name="login"
        component={FormikTextInputQuestions}
        errorMessage={touched.login && errors.login}
        placeholder="Логин"
        wrapperClassName={s.Form__input}
        validate={required}
        autoCapitalize="none"
        autoCorrect="off"
      />
      <FastField
        name="password"
        component={FormikTextInputQuestions}
        errorMessage={touched.password && errors.password}
        type="password"
        placeholder="Пароль"
        wrapperClassName={s.Form__input}
        validate={required}
      />
      <Button
        disabled={isSubmitting}
        type="submit"
        className={s.Form__submit}
        loading={isSubmitting}
      >
        Войти
      </Button>
      {status && <p className={s.Form__error}>{status}</p>}
    </form>
  );
};
