import url from 'url';
import React, { useCallback, useMemo, useRef } from 'react';
import { parse as parseQuery, stringify as formatQuery } from 'query-string';
import { isEqual } from 'lodash-es';
import { nanoid } from 'nanoid';
import { useUrlQueryParams } from '../utils/useUrlQuery';
import {
  URL_QUERY_FP,
  URL_QUERY_MONTH,
  URL_QUERY_QUARTER,
  URL_QUERY_RP,
  URL_QUERY_YEAR
} from './stores';

let defaultQueries = [
  URL_QUERY_YEAR,
  URL_QUERY_QUARTER,
  URL_QUERY_MONTH,
  URL_QUERY_FP,
  URL_QUERY_RP
];

function usePreserveQueryBase(query, queries = defaultQueries) {
  const filteredQueryRef = useRef({});
  const filteredQuery = filterQuery(query, queries);

  if (!isEqual(filteredQuery, filteredQueryRef.current)) {
    filteredQueryRef.current = filteredQuery;
  }

  const createLinkCb = useCallback((link, query, exclude = []) => {
    return createPreservedLinkBase(
      link,
      query,
      filteredQueryRef.current,
      exclude
    );
  }, []);

  const filteredQueryLatest = filteredQueryRef.current;

  return useMemo(
    () => ({
      query: filteredQueryLatest,
      createLink: createLinkCb
    }),
    [createLinkCb, filteredQueryLatest]
  );
}

export function usePreserveQueryFast(query, queries = []) {
  return usePreserveQueryBase(query, [...defaultQueries, ...queries]);
}

const empty = [];
export function usePreserveQuery(queries = empty) {
  const list = useMemo(() => [...defaultQueries, ...queries], [queries]);
  const [query] = useUrlQueryParams(list);
  return usePreserveQueryBase(query, list);
}

function filterQuery(query, queries = []) {
  const filtered = {};
  queries.forEach((item) => {
    if (query[item]) {
      filtered[item] = query[item];
    }
  });
  return filtered;
}

export function createPreservedLink(
  link,
  query,
  currentQuery = {},
  include = [],
  exclude = []
) {
  return createPreservedLinkBase(
    link,
    query,
    filterQuery(currentQuery, [...defaultQueries, ...include]),
    exclude
  );
}

function createPreservedLinkBase(link, query, filteredQuery, exclude = []) {
  const linkParsed = url.parse(link);
  const queryObj = {
    ...filteredQuery,
    ...parseQuery(linkParsed.search),
    ...query
  };
  for (const key of exclude) {
    delete queryObj[key];
  }
  const newQuery = formatQuery(queryObj);
  return {
    ...linkParsed,
    query: newQuery,
    search: newQuery && '?' + newQuery
  };
}

export function createDownloadLink(link, id = nanoid(20)) {
  return url.format(
    createPreservedLinkBase(link, { download: true, download_id: id })
  );
}
