import gql from 'graphql-tag';

export const MUTATION_ROADMAP_FILE_TOKEN = gql`
  mutation createRoadmapDocumentFileToken($id: String!) {
    createRoadmapDocumentFileOneTimeToken(id: $id)
  }
`;

export const MUTATION_ROADMAP_DOCUMENT_TOKEN = gql`
  mutation createRoadmapDocumentToken($id: String!) {
    createRoadmapDocumentOneTimeToken(id: $id)
  }
`;
