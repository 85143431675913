import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Select } from '../../ui/Select/Select';
import { Button } from '../../ui/Button/Button';
import { ReactComponent as FilterIcon } from '../../../assets/Icons/Filtr.svg';
import { ActualityDate } from '../../NavBar/ActualityDate';
import {
  INDICATOR_ACCEPT,
  mapIndicators,
  organizationTypes
} from '../../../utils/indicators';
import { useActualityDateByFinance } from '../../../store/useActualityDateByFinance';
import { useQueryPeriod } from '../../../utils/useQueryPeriod';
import { FiltersPopup } from '../../FiltersPopup/FiltersPopup';
import s from './MapFilters.module.scss';

function GroupedFilters({
  indicatorType,
  additionalClassName,
  changeQuery,
  organizationType
}) {
  return (
    <>
      {/* Категории */}
      <div className={s.MapFilters__filter}>
        <Select
          height={Select.SMALL}
          className={classNames(
            s.MapFilters__filterSelect,
            additionalClassName
          )}
          value={indicatorType}
          variant={Select.DARKER}
          values={mapIndicators}
          onChange={(indicators) =>
            changeQuery({ indicator_type: indicators.value }, true)
          }
        />
      </div>
      {/* Типы организаций */}
      <div className={s.MapFilters__filter}>
        <Select
          height={Select.SMALL}
          className={classNames(
            s.MapFilters__filterSelect,
            additionalClassName
          )}
          value={organizationType}
          variant={Select.DARKER}
          values={organizationTypes}
          onChange={(type) =>
            changeQuery({ organization_type: type.value }, true)
          }
        />
      </div>
    </>
  );
}

export const MapFilters = ({
  className,
  changeQuery,
  onOpenFilter,
  queryParams,
  organizationType,
  indicatorType,
  selectedFp,
  actualityDate,
  districtCode,
  isMobile,
  cards
}) => {
  const { yearMonth, year, month } = useQueryPeriod();
  const { organization_type, indicator_type, res, roadmap } = queryParams;

  const hasQueryFilters = (res && res.split(',').length > 0) || false;

  const countOrganizationsFilter = useMemo(
    () => ({
      bs_organization_type_okved_ids:
        organization_type && organization_type !== 'ALL'
          ? [organization_type]
          : null,
      federal_project_ids: selectedFp ? selectedFp.id : undefined,
      result_ids: res ? res.split(',') : undefined,
      roadmap_ids: roadmap ? [roadmap] : undefined,
      roadmap_year: roadmap && year,
      federalProjectCodes: selectedFp ? [selectedFp.project_code] : undefined,
      federalDistrictCodes: districtCode ? [districtCode] : undefined
    }),
    [organization_type, selectedFp, res, roadmap, year, districtCode]
  );

  const [planDate, obligationDate] = useActualityDateByFinance({
    yearMonth,
    ...countOrganizationsFilter
  });

  const currentActualityDate =
    typeof actualityDate !== 'undefined'
      ? actualityDate
      : indicator_type === INDICATOR_ACCEPT
      ? obligationDate
      : new Date();

  const cardProcessed = useMemo(() => {
    const cardsData = [{ label: 'Все дорожные карты', value: undefined }];
    if (!cards) {
      return cardsData;
    }
    cards.forEach((card) => {
      cardsData.push({
        label: card.name,
        value: card.id
      });
    });
    return cardsData;
  }, [cards]);

  const selectedCard = useMemo(() => {
    const selectedRoadmap = queryParams.roadmap;
    return cardProcessed.find((data) => {
      return data.value === selectedRoadmap;
    });
  }, [cardProcessed, queryParams.roadmap]);

  return (
    <div className={classNames(s.MapFilters, className)}>
      <div className={s.MapFilters__row}>
        {isMobile ? (
          <FiltersPopup
            buttonClassName={s.MapFilters__mobileFiltersBtn}
            title={'Настройки'}
          >
            <GroupedFilters
              additionalClassName={s.MapFilters__popupSelect}
              indicatorType={indicatorType}
              changeQuery={changeQuery}
              organizationType={organizationType}
            />
            {/* Фильтр по дорожным картам, скрытым в мобильной версии */}
            <div className={s.MapFilters__filter}>
              <Select
                height={Select.SMALL}
                className={classNames(
                  s.MapFilters__filterSelect,
                  s.MapFilters__popupSelect
                )}
                value={selectedCard}
                defaultValue={cardProcessed[0]}
                variant={Select.DARKER}
                values={cardProcessed}
                onChange={(roadmap) =>
                  changeQuery({ roadmap: roadmap.value }, true)
                }
              />
            </div>
          </FiltersPopup>
        ) : (
          <GroupedFilters
            indicatorType={indicatorType}
            changeQuery={changeQuery}
            organizationType={organizationType}
          />
        )}

        {/* Расширенные настройки */}
        <div className={s.MapFilters__filter}>
          <Button
            variant={Button.DARKER}
            className={classNames(s.MapFilters__btn, {
              [s.MapFilters__btnActive]: hasQueryFilters
            })}
            onClick={onOpenFilter}
          >
            <FilterIcon className={s.MapFilters__btnIcon} />
          </Button>
          {!isMobile && (
            <div className={s.MapFilters__actualityDate}>
              <ActualityDate date={currentActualityDate} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

MapFilters.propTypes = {
  changeQuery: PropTypes.func.isRequired,
  onOpenFilter: PropTypes.func.isRequired,
  queryParams: PropTypes.shape().isRequired
};
