import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { QUERY_GET_FEDERAL_PROJECTS_ROADMAPS } from '../graphql/queries/federalProjects';

const fpRoadmapOptions = {
  query: QUERY_GET_FEDERAL_PROJECTS_ROADMAPS,
  mapData: (result) => result.federalProjectRoadmaps
};

export const useFPRoadmapQuery = (
  filter,
  resultFinancesFilter,
  dcCompletionFilter
) => {
  return useGraphqlQuery({
    queryOptions: fpRoadmapOptions,
    variables: {
      filter,
      resultFinancesFilter,
      dcCompletionFilter
    }
  });
};
