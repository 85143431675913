import React from 'react';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import { formatNumber } from '../../../utils/formatNumber';
import { QUERY_GET_PURCHASE_FINANCES } from '../../../graphql/queries/region';
import DonutPieChart from '../../../common/charts/DonutPieChart';
import { ResizeContainer } from '../../../common/ResizeContainer';
import { NumberTooltip } from '../../../common/ui/ToolTip/NumberTooltip';
import { queryLoader } from '../../../common/QueryLoader';
import { BaseTooltip } from '../../../common/ui/ToolTip/BaseTooltip';
import colors from '../../../colors.scss';
import s from './Purchases.module.scss';

const purchaseFinancesQueryOptions = {
  query: QUERY_GET_PURCHASE_FINANCES,
  skipQuery: skipIfNull(null)
};

const barDonutData = (value, max, title, color) => [
  {
    id: 0,
    value: value,
    title: title,
    color: color
  },
  {
    id: 1,
    title: title,
    value: max - value,
    color: colors.grayLight
  }
];

function PurchasesBlocks({ data, total, actualityDate }) {
  const totalBudget = total.total_budget / 1e6;
  const financingTotal = data.data.financing_sfb / 1e6;
  const contractSummTotal = data.data.contract_summ_sfb / 1e6;
  const cashExpenses = data.schedule[0]?.sum / 1e6 || 0;
  const max = Math.max(
    totalBudget,
    financingTotal,
    contractSummTotal,
    cashExpenses
  );
  const today = new Date();

  return (
    <ResizeContainer>
      {({ width }) => {
        const outerRadius = (width * 0.8) / 2;
        const barWidth = 4;
        const lineHeight = 24;

        const ls = (radius) => ({
          top: `${(1 - ((radius - barWidth / 2) * 2) / width) * 50}%`
        });

        const barValues = [
          {
            value: financingTotal,
            title: 'Объявлено',
            radius: outerRadius,
            color: colors.blue
          },
          {
            value: contractSummTotal,
            title: 'Законтрактовано',
            radius: outerRadius - lineHeight,
            color: colors.blue
          },
          {
            value: cashExpenses,
            title: 'Кассовое исполнение',
            radius: outerRadius - lineHeight * 2,
            color: colors.blue
          }
        ];

        return (
          <>
            {barValues.map((bar, iBar) => (
              <DonutPieChart
                key={iBar}
                className={s.Purchases__pie}
                size={width}
                data={barDonutData(bar.value, max, bar.title, bar.color)}
                outerRadius={bar.radius}
                innerRadius={bar.radius - barWidth}
                gapAngle={0}
                maxAngle={(Math.PI * 3) / 2}
                total={max}
              />
            ))}
            {barValues.map((bar, iBar) => (
              <BaseTooltip
                key={iBar}
                tooltip={
                  <NumberTooltip
                    data={[
                      {
                        id: 0,
                        name: bar.title,
                        color: bar.color,
                        value: bar.value
                      }
                    ]}
                    actualityDate={today}
                  />
                }
              >
                <div
                  key={iBar}
                  className={s.Purchases__label}
                  style={ls(bar.radius)}
                >
                  <div className={s.Purchases__label__title}>{bar.title}</div>
                  <div className={s.Purchases__label__value}>
                    {formatNumber(bar.value, 1)}
                  </div>
                </div>
              </BaseTooltip>
            ))}
            <div className={s.Purchases__total}>
              <BaseTooltip
                tooltip={
                  <NumberTooltip
                    data={[
                      {
                        id: 0,
                        name: 'План',
                        color: '#ffffff',
                        value: totalBudget
                      }
                    ]}
                    actualityDate={actualityDate}
                  />
                }
              >
                <div className={s.Purchases__totalData}>
                  <div className={s.Purchases__total__value}>
                    {formatNumber(totalBudget, 1)}
                  </div>
                  <div className={s.Purchases__total__title}>План</div>
                </div>
              </BaseTooltip>
            </div>
          </>
        );
      }}
    </ResizeContainer>
  );
}

export const Purchases = ({ regionId, totalQuery, actualityDate }) => {
  const query = useGraphqlQuery({
    queryOptions: purchaseFinancesQueryOptions,
    variables: regionId ? { regionId } : null
  });

  const total = totalQuery.state.data && totalQuery.state.data.total;

  return (
    <div className={s.Purchases}>
      <PageSubTitle className={s.Purchases__title} text={'Закупки, млн руб.'} />
      <div className={s.Purchases__chartView}>
        {queryLoader(query) ||
          queryLoader(totalQuery) ||
          (query.state.data && total ? (
            <PurchasesBlocks
              data={query.state.data}
              actualityDate={actualityDate}
              total={total}
            />
          ) : (
            <div>Нет данных</div>
          ))}
      </div>
    </div>
  );
};
