import { CSSTransition } from 'react-transition-group';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import DonutPieChart from '../../../common/charts/DonutPieChart';
import color from '../../../colors.scss';
import s from './ProjectsChartView.module.scss';

export const DonutChartWrapper = ({ children, isShown }) => {
  return (
    <CSSTransition
      in={isShown}
      timeout={200}
      classNames="fade-in"
      mountOnEnter
      unmountOnExit
    >
      <div className={s.ChartView__donutPieChart}>{children}</div>
    </CSSTransition>
  );
};

export const getDonutPositions = (adaptiveRatio) => [
  {
    size: 348 * adaptiveRatio,
    outerRadius: (348 * adaptiveRatio) / 2,
    innerRadius: (308 * adaptiveRatio) / 2,
    color: s.chartLightBlue,
    endColor: s.chartLightBlue2L
  },
  {
    size: 300 * adaptiveRatio,
    outerRadius: (300 * adaptiveRatio) / 2,
    innerRadius: (260 * adaptiveRatio) / 2,
    color: color.chartBlue2,
    endColor: s.chartBlue2L
  },
  {
    size: 260 * adaptiveRatio,
    outerRadius: (240 * adaptiveRatio) / 2,
    innerRadius: (232 * adaptiveRatio) / 2,
    color: color.chartBlue2,
    endColor: s.chartBlue2L,
    clickOuterRadius: (252 * adaptiveRatio) / 2,
    clickInnerRadius: (212 * adaptiveRatio) / 2
  },
  {
    size: 200 * adaptiveRatio,
    outerRadius: (200 * adaptiveRatio) / 2,
    innerRadius: (160 * adaptiveRatio) / 2,
    color: s.chartAqua,
    endColor: s.chartAqua2
  },
  {
    size: 160 * adaptiveRatio,
    outerRadius: (138 * adaptiveRatio) / 2,
    innerRadius: (130 * adaptiveRatio) / 2,
    color: s.chartAqua,
    endColor: s.chartAqua2,
    clickOuterRadius: (152 * adaptiveRatio) / 2,
    clickInnerRadius: (112 * adaptiveRatio) / 2
  },
  {
    size: 248 * adaptiveRatio,
    outerRadius: (248 * adaptiveRatio) / 2,
    innerRadius: (208 * adaptiveRatio) / 2,
    color: s.chartAqua,
    endColor: s.chartAqua2
  }
];

const dashProps = {
  strokeDasharray: '6,6',
  isDash: true,
  dashColor: color.chartDashLine
};

export function Donuts({
  className,
  selectedChartView,
  pies,
  adaptiveRatio,
  activeId,
  onSegmentClick,
  onMainChartClick,
  isChartViewOpen
}) {
  const chartPositions = getDonutPositions(adaptiveRatio);
  const handleSegmentClick = useCallback(
    (id) => onSegmentClick(id === activeId ? null : id),
    [activeId, onSegmentClick]
  );
  const handleDonutPartClick = (id) => () => onMainChartClick({ chart: id });

  return (
    <div className={classNames(s.ChartView__donutPieCharts, className)}>
      {/* 0) Голубой */}
      <DonutChartWrapper isShown={selectedChartView === 'federal'}>
        <DonutPieChart
          gapAngle={1}
          {...pies[0]}
          {...chartPositions[0]}
          activeId={activeId}
          onPathClick={handleSegmentClick}
        />
      </DonutChartWrapper>
      {/* 1) Синий */}
      <DonutChartWrapper
        isShown={!selectedChartView || selectedChartView === 'federal'}
      >
        <DonutPieChart
          gapAngle={1}
          onPathClick={isChartViewOpen ? handleSegmentClick : undefined}
          onPieClick={
            !isChartViewOpen ? handleDonutPartClick('federal') : undefined
          }
          {...pies[1]}
          {...chartPositions[1]}
          activeId={activeId}
        />
      </DonutChartWrapper>
      {/* 2) Синий тонкий */}
      <DonutChartWrapper
        isShown={!selectedChartView || selectedChartView === 'federal'}
      >
        <DonutPieChart
          gapAngle={1}
          onPathClick={isChartViewOpen ? handleSegmentClick : undefined}
          onPieClick={
            !isChartViewOpen ? handleDonutPartClick('federal') : undefined
          }
          {...dashProps}
          {...pies[2]}
          {...chartPositions[2]}
          activeId={activeId}
        />
      </DonutChartWrapper>

      {/* 5) Голубой внешний */}
      <DonutChartWrapper isShown={selectedChartView === 'mbt'}>
        {pies[5] && (
          <DonutPieChart
            gapAngle={1}
            onPathClick={handleSegmentClick}
            {...pies[5]}
            {...chartPositions[5]}
            activeId={activeId}
          />
        )}
      </DonutChartWrapper>

      {/* 3) Голубой */}
      <DonutChartWrapper
        isShown={!selectedChartView || selectedChartView === 'mbt'}
      >
        <DonutPieChart
          gapAngle={1}
          onPathClick={isChartViewOpen ? handleSegmentClick : undefined}
          onPieClick={
            !isChartViewOpen ? handleDonutPartClick('mbt') : undefined
          }
          {...dashProps}
          {...pies[3]}
          {...chartPositions[3]}
          activeId={activeId}
        />
      </DonutChartWrapper>
      {/* 4) Голубой тонкий */}
      <DonutChartWrapper
        isShown={!selectedChartView || selectedChartView === 'mbt'}
      >
        <DonutPieChart
          gapAngle={1}
          onPathClick={isChartViewOpen ? handleSegmentClick : undefined}
          onPieClick={
            !isChartViewOpen ? handleDonutPartClick('mbt') : undefined
          }
          {...dashProps}
          {...pies[4]}
          {...chartPositions[4]}
          activeId={activeId}
        />
      </DonutChartWrapper>
    </div>
  );
}
