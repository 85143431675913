import { getDataName } from '../../../utils/data';
import { SubsidiaryEntity } from '../../../data/subsidiaryEntity';

export const ORGANIZATIONS = 'organizations';
export const REGIONS = 'regions';
export const FEDERAL_PROJECTS = 'federal_projects';
export const RESULTS = 'results';
export const ROADMAPS = 'roadmaps';
export const SUBSIDIARY_ENTITY = 'subsidiaryEntity';

const getDataId = (i) => i.id;

export const entities = [
  {
    title: 'Субъекты',
    value: REGIONS,
    props: {
      getDataId,
      getDataName,
      getDataLink: (i) => `/regions/${i.code_nalog}`
    }
  },
  {
    title: 'Федеральные проекты',
    value: FEDERAL_PROJECTS,
    props: {
      getDataId,
      getDataName,
      getDataLink: (i) => `/indicators?fp=${i.project_code}`
    }
  },
  {
    title: 'Организации',
    value: ORGANIZATIONS,
    props: {
      getDataId,
      getDataName,
      getDataLink: (i) => `/organizations/${i.id}`
    }
  },
  {
    title: 'Результаты',
    value: RESULTS,
    props: {
      getDataId,
      getDataName,
      getDataLink: (i) => `/indicators/results/${i.id}`
    }
  },
  {
    title: 'Дорожные карты',
    value: ROADMAPS,
    props: {
      getDataId,
      getDataName,
      getDataLink: (i) => `/indicators/roadmap/${i.id}`
    }
  },
  {
    title: 'Субсидиарные сущности',
    value: SUBSIDIARY_ENTITY,
    props: {
      getDataId,
      getDataName: SubsidiaryEntity.getCompleteName,
      getDataLink: SubsidiaryEntity.getLink
    }
  }
];

export const tabRadio = [
  {
    title: 'Все',
    value: null
  },
  ...entities
];
