import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
  useGraphqlQuery,
  useGraphqlQueryVars
} from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { TopBar } from '../../common/TopBar/TopBar';
import { PageSubTitle, PageTitle } from '../../common/PageTitle/PageTitle';
import {
  QUERY_GET_ORGANIZATION_INTERNET,
  QUERY_GET_RESULTS
} from '../../graphql/queries/organization';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { useOrganization } from '../../store/useOrganization';
import { TopBarProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { RightPaneLayout } from '../../common/GridLayout/RightPaneLayout';
import { useWindowSize } from '../../store/useWindowSize';
import { FlyingButton } from '../../common/FlyingBox/FlyingBox';
import { ReactComponent as ReportIcon } from '../../assets/Icons/Document.svg';
import { queryLoader } from '../../common/QueryLoader';
import { Project } from '../../data/project';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { IndicatorsLegendItem } from '../SubjectPage/IndicatorsLegend';
import { ReactComponent as IconArrowFront } from '../../assets/Icons/Arrow/Front.svg';
import { ActualityDate } from '../../common/NavBar/ActualityDate';
import { formatNumber } from '../../utils/formatNumber';
import { PercentChart } from '../ResultPage/ResultsInfoView/PercentChart';
import { shortMonthNames } from '../../utils/constants';
import { Organization } from '../../data/organization';
import { ReactHelmet } from '../../common/ReactHelmet';
import { ObjectView } from './ObjectView';
import { MainCards } from './MainCards/MainCards';
import { Results } from './Results/Results';
import s from './ObjectPage.module.scss';
import colors from '../../colors.scss';

const resultsQueryOptions = {
  query: QUERY_GET_RESULTS,
  mapData: (result) =>
    result.resultFederals.list.map((result) => ({ ...result, status: 1 }))
};

function InternetInfoComputer({ title, date, down, up }) {
  return (
    <div className={s.InternetInfoComputer}>
      <div className={s.InternetInfoComputer__title}>{title}</div>
      <ActualityDate
        date={date}
        variant={ActualityDate.Variant.DESCRIPTION}
        text={'Дата измерения'}
      />
      <div className={s.InternetInfoComputer__speed}>
        <div className={classNames(s.Speed, s.Speed_down)}>
          <IconArrowFront className={s.Speed__icon} />
          <div className={s.Speed__value}>{formatNumber(down, 1)}</div>
        </div>
        <div className={classNames(s.Speed, s.Speed_up)}>
          <IconArrowFront className={s.Speed__icon} />
          <div className={s.Speed__value}>{formatNumber(up, 1)}</div>
        </div>
      </div>
    </div>
  );
}

const internetQuery = {
  query: QUERY_GET_ORGANIZATION_INTERNET,
  skipQuery: skipIfNull(null)
};

const computerTitles = [
  'Компьютер учащегося, расположенный в классе информатики',
  'Компьютер преподавателя в любом классе',
  'Другой компьютер'
];

const internetChartIndices = [
  {
    id: 0,
    name: 'Загрузка',
    color: colors.chartBlue,
    showCircles: true
  },
  {
    id: 1,
    name: 'Выгрузка',
    color: colors.chartBlue2,
    showCircles: true
  }
];

function InternetInfo({ organizationId, year, yearMonth }) {
  const speedQuery = useGraphqlQuery({
    queryOptions: internetQuery,
    variables: {
      id: organizationId,
      year,
      yearMonth
    }
  });
  const speedData = speedQuery.state.data;

  const chartData = useMemo(() => {
    if (!speedData) return null;
    return shortMonthNames.map((month, iMonth) => {
      const monthData = speedData.history.find((d) => d.month === iMonth + 1);
      return {
        id: iMonth,
        name: month,
        values: [monthData?.download_speed, monthData?.upload_speed]
      };
    });
  }, [speedData]);

  if (speedQuery.check.component && !speedData?.history?.length) {
    return false;
  }

  return (
    <div className={s.InfoView__internet}>
      <PageSubTitle
        text={'Мониторинг входящего и исходящего интернет-соединения, мб/сек'}
      />
      <div className={s.Internet__legend}>
        <IndicatorsLegendItem name={'Загрузка'} color={colors.chartBlue} />
        <IndicatorsLegendItem name={'Выгрузка'} color={colors.chartBlue2} />
      </div>
      <div className={s.Internet__computers}>
        {queryLoader(speedQuery) ||
          [1, 2, 3].map((iComputer) => {
            const computer = speedData?.last?.find(
              (c) => c.computer_number === iComputer
            );

            return (
              <InternetInfoComputer
                key={iComputer}
                title={computerTitles[iComputer - 1]}
                date={computer?.date}
                down={computer?.download_speed}
                up={computer?.upload_speed}
              />
            );
          })}
      </div>
      <div className={s.Internet__chart}>
        {chartData && (
          <PercentChart
            data={chartData}
            indices={internetChartIndices}
            title={'Динамика изменения интернет-соединения'}
            classes={{
              title: s.Internet__chartTitle
            }}
          />
        )}
      </div>
    </div>
  );
}

const InfoView = ({
  organization,
  federalProject,
  createEntityLink,
  className,
  year,
  yearMonth,
  history
}) => {
  const query = useGraphqlQueryVars({
    queryOptions: resultsQueryOptions,
    variableCreator: [
      () => ({
        organization_id: organization.id
      }),
      [organization.id]
    ]
  });

  return (
    <div className={classNames(s.InfoView, className)}>
      <PageTitle
        text={Organization.getName(organization)}
        onClick={() =>
          history.goBack(
            (organization.region &&
              `/regions/${organization.region.code_nalog}`) ||
              '/'
          )
        }
      />
      <MainCards
        createLink={createEntityLink}
        organization={organization}
        federalProject={federalProject}
        year={year}
        yearMonth={yearMonth}
      />
      <InternetInfo
        organizationId={organization.id}
        year={year}
        yearMonth={yearMonth}
      />
      <div className={s.InfoView__results}>
        <PageSubTitle
          className={s.InfoView__resultsTitle}
          text={'Результаты федеральных и региональных проектов'}
        />
        {queryLoader(query) || (
          <Results results={query.state.data} federalProject={federalProject} />
        )}
      </div>
    </div>
  );
};

export default function ObjectPage({ history, match, location }) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const [rightOpened, setRightOpened] = useState(false);

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  const { id } = match.params;

  const [query, changeQuery] = useUrlQuery();
  const selectedFpCode = +query.fp || 0;

  const { createLink } = usePreserveQueryFast(query);
  const { year, yearMonth } = useQueryPeriodFast(query, changeQuery);
  const orgQuery = useOrganization(id);
  const createEntityLink = useCallback(
    (entityId) => createLink(`/organizations/${id}/entities/${entityId}`),
    [id, createLink]
  );

  const org = orgQuery.state.data;

  const regionalProjects = org && org.regional_projects;
  const federalProjects = useMemo(() => {
    return regionalProjects && regionalProjects.map((rp) => rp.federal_project);
  }, [regionalProjects]);

  const regionalProject =
    (selectedFpCode &&
      regionalProjects &&
      regionalProjects.find(
        (p) =>
          Project.getFederalProjectCode(p.federal_project) === selectedFpCode
      )) ||
    null;
  const federalProject = regionalProject && regionalProject.federal_project;

  return (
    <RightPaneLayout
      topBar={
        <TopBar>
          <TopBarProjects projects={federalProjects} replaceQuery={true} />
        </TopBar>
      }
      right={queryLoader(orgQuery) || <ObjectView organization={org} />}
      extra={
        isMobile && (
          <FlyingButton
            className={s.Object__flyingButton}
            onClick={onRightOpen}
          >
            <ReportIcon />
            <span>Информация об объекте</span>
          </FlyingButton>
        )
      }
      onRightClose={onRightClose}
      rightOpened={rightOpened}
    >
      <ReactHelmet entity={Organization.getName(org)} type={'Организация'} />
      {queryLoader(orgQuery) || (
        <InfoView
          organization={org}
          federalProject={federalProject}
          createEntityLink={createEntityLink}
          history={history}
          year={year}
          yearMonth={yearMonth}
        />
      )}
    </RightPaneLayout>
  );
}
