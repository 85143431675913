import React from 'react';
import classNames from 'classnames';
import { CardSmallLayout } from './CardSmallLayout';
import s from './CardSmallLayout.module.scss';

// todo propTypes

function resolveCell(cell) {
  if (typeof cell === 'string') {
    return { value: cell };
  }
  return cell;
}

export function CardTotal({
  title,
  loading,
  className,
  layoutClassName,
  type,
  data,
  headCells,
  to,
  layoutProps,
  ...props
}) {
  return (
    <div
      className={classNames(s.CardTotal, className, {
        [s[type]]: !!type
      })}
      {...props}
    >
      <CardSmallLayout
        spinnerColor={'#fff'}
        spinnerBGColor={'dark'}
        loading={loading}
        to={to}
        className={classNames(s.CardCharts__background, layoutClassName)}
        {...layoutProps}
      >
        <div className={s.CardCharts__title}>{title}</div>
        <table className={s.CardTotal__table}>
          <thead>
            <tr className={s.CardTotal__headRow}>
              {headCells.map((cell, i) => {
                const { value, className } = resolveCell(cell);
                return (
                  <th
                    key={i}
                    className={classNames(s.CardTotal__tableHeaderCell, {
                      [s[className]]: className
                    })}
                  >
                    {value}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((cells, i) => (
              <tr key={i} className={s.CardTotal__row}>
                {cells.map((cell, k) => {
                  const { value, className } = resolveCell(cell);
                  return (
                    <td
                      key={k}
                      className={classNames(s.CardTotal__tableCell, {
                        [s[className]]: className
                      })}
                    >
                      {value}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </CardSmallLayout>
    </div>
  );
}
