import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { TabRadio } from '../../../common/ui/Tab/TabRadio';
import { ResizeContainer } from '../../../common/ResizeContainer';
import { useWindowSize } from '../../../store/useWindowSize';
import { ChartViewTabsSlider } from '../../../common/ChartView/ChartViewTabsSlider';
import { Tab } from '../../../common/ui/Tab/Tab';
import s from '../../../common/ChartView/ChartView.module.scss';

export const ChartView = ({
  tabs,
  tabProps,
  controls,
  renderChart,
  className,
  wrapperClassName
}) => {
  const ws = useWindowSize();
  // 1152 размер для нормальной адаптации переноса строк.
  const isMobile = ws.window.innerWidth < 1152;

  const activeTabIndex = useMemo(() => {
    return tabs.findIndex((tab) => {
      const tabObj = tabProps(tab.value);
      return tabObj.value === tabObj.selected;
    });
  }, [tabs, tabProps]);

  const onSliderIndexChange = useCallback(
    (index) => {
      const activeTab = tabs[index];
      tabProps(activeTab.value).onChange(activeTab.value);
    },
    [tabs, tabProps]
  );

  return (
    <div className={classNames(s.ChartView, className)}>
      <div className={classNames(s.ChartView__wrapper, wrapperClassName)}>
        <div className={s.ChartView__tabs}>
          {isMobile ? (
            <ChartViewTabsSlider
              className={s.ChartViewTabsSlider}
              selectedIndex={activeTabIndex}
              onChangeIndex={onSliderIndexChange}
            >
              {tabs.map((tab, iTab) => {
                const currTabProps = tabProps(tab.value);
                return (
                  <Tab
                    key={iTab}
                    className={s.ChartView__tab}
                    active={currTabProps.value === currTabProps.selected}
                  >
                    {tab.label}
                  </Tab>
                );
              })}
            </ChartViewTabsSlider>
          ) : (
            tabs.map((tab, iTab) => (
              <TabRadio
                key={iTab}
                className={s.ChartView__tab}
                {...tabProps(tab.value)}
              >
                {tab.label}
              </TabRadio>
            ))
          )}
        </div>
        <div className={s.ChartView__selects}>{controls}</div>
        <div className={s.ChartView__content}>
          <div className={s.ChartView__scroll}>
            <div className={s.ChartView__inner}>
              <ResizeContainer>{renderChart}</ResizeContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
