import { max as d3max } from 'd3';
import { Project } from '../../../data/project';
import { positiveMod } from '../../../utils/math';
import { projectsColors } from './constants';

export function getFederalProjectsData(financesData) {
  if (!financesData) return null;
  const { projects, projectsSubject, projectsMbt } = financesData;

  const maxProjectTotalBudget = d3max(projects, (p) => p.total_budget);

  return projects.map((project) => {
    const code = Project.getFederalProjectCode(project.federal_project);
    const index = code - 1;
    const cardColors =
      projectsColors[positiveMod(index, projectsColors.length)];

    return {
      colors: cardColors,
      data: project,
      size: project.total_budget / maxProjectTotalBudget,
      subjectData: projectsSubject.find(
        (ps) => ps.federal_project_id === project.federal_project_id
      ),
      projectsMbt: projectsMbt.find(
        (ps) => ps.federal_project_id === project.federal_project_id
      )
    };
  });
}
