import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IconBack } from '../../../assets/Icons/Arrow/Back.svg';
import s from './BackButton.module.scss';

export function BackButton({ icon, text, onClick }) {
  return (
    <div className={s.BackButton} onClick={onClick}>
      <div className={s.BackButton__content}>
        {icon || <IconBack />}
        <span>{text || 'Назад'}</span>
      </div>
    </div>
  );
}

export function BackButtonBox({ icon, text, className, children, ...props }) {
  return (
    <div className={classNames(s.BackButtonBox, className)}>
      {children || <BackButton icon={icon} text={text} {...props} />}
    </div>
  );
}
