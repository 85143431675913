import { onError } from 'apollo-link-error';
import { switchMap } from 'rxjs/operators';
import { from, of, throwError } from 'rxjs';

export const createCheckAuthenticationErrorLink = (authStore) =>
  onError(({ graphQLErrors, networkError, response, operation, forward }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      const { message } = graphQLErrors[0];
      if (message && message === 'Invalid Token') {
        if (authStore.canRefreshToken()) {
          return from(authStore.refreshToken()).pipe(
            switchMap((state) => {
              if (state.accessToken) {
                return forward(operation);
              } else if (networkError) {
                return throwError(networkError);
              } else {
                return of(response);
              }
            })
          );
        }
      }
    }
  });
