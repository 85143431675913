import gql from 'graphql-tag';

import { FRAGMENT_ROADMAP_EVENT_DOCUMENT } from './roadmapEvents';

export const FRAGMENT_FEDERAL_RESULT_FOR_RESULT_PAGE = gql`
  fragment federalResult on ResultFederalType {
    id
    name
    name_full
  }
`;

const FRAGMENT_ROADMAP_EVENT = gql`
  fragment roadmapFederalEvent on RoadmapEventType {
    id
    code
    name
    name_full
    upload_arbitrary_documents
    plan_term
    event_result
    plan_roadmap {
      event_result
      responsible_string
      term
    }
    count_region_status {
      status
      count
    }
    roadmap_event_document_slots {
      id
      code
      name
      name_full
      federal
      type
    }
    roadmap_event_documents {
      ...RoadmapEventDocument
    }
    fact_term
  }

  ${FRAGMENT_ROADMAP_EVENT_DOCUMENT}
`;

export const QUERY_GET_FEDERAL_PROJECTS = gql`
  query federalProjects {
    federalProjects {
      id
      code
      name
      name_full
      project_code
    }
  }
`;

export const QUERY_GET_FEDERAL_PROJECT_RESULTS = gql`
  query resultFederals($input: FederalResultsPageInput!) {
    resultFederals(input: $input) {
      list {
        id
        code
        name
        name_full
        project_code
        responsible_executor_id
        kbk_id
      }
    }
  }
`;

export const QUERY_GET_FEDERAL_PROJECT_RESULTS_WITH_FINANCES = gql`
  query resultFederals($input: FederalResultsPageInput!, $yearMonth: String!) {
    resultFederals(input: $input) {
      list {
        id
        code
        name
        name_full
        project_code
        responsible_executor_id
        kbk_id
        finances(filter: { month: $yearMonth, budget_levels: [federal] }) {
          cash_expenses_percent
          obligations_percent
        }
      }
    }
  }
`;

export const QUERY_GET_REGIONAL_PROJECT_RESULT_WITH_FINANCES = gql`
  query resultRegionals(
    $input: RegionalResultsPageInput!
    $yearMonth: String!
  ) {
    resultRegionals(input: $input) {
      list {
        id
        code
        name
        name_full
        project_code
        responsible_executor_id
        kbk_id
        finances(
          filter: {
            month: $yearMonth
            budget_levels: [subject]
            intersect_mbt: intersect
          }
        ) {
          cash_expenses_percent
          obligations_percent
        }
      }
    }
  }
`;

export const QUERY_GET_FEDERAL_PROJECTS_ROADMAPS = gql`
  query getFederalProjectRoadmaps(
    $filter: RoadmapFilterInput
    $dcCompletionFilter: DcCompletionFilter
    $resultFinancesFilter: FinancesQueryFilter!
  ) {
    federalProjectRoadmaps(filter: $filter) {
      id
      owner_id
      fp_roadmap
      predefined_id
      code
      name
      main_data_area
      responsible
      responsible
      name_full
      federal_level
      result {
        id
        finances(filter: $resultFinancesFilter) {
          cash_expenses_percent
          obligations_percent
        }
      }
      dc_completion(filter: $dcCompletionFilter) {
        dc_completion_percent
      }
    }
  }
`;

export const QUERY_GET_ROADMAP = gql`
  query getRoadmap(
    $id: ID!
    $subsidiaryEntityPlanFilter: SubsidiaryEntityPlanFilterInput
  ) {
    roadmap(id: $id) {
      id
      name
      name_full
      federal_level
      result {
        ...federalResult
        unit {
          id
          name
        }
        owner_task {
          id
          owner_id
          federal_project {
            id
            code
            name
            name_full
            project_code
          }
        }
        values {
          plan_data_id
          value_number
          text
          period {
            id
            name
            start
            end
          }
        }
        fact_values {
          fact_data_id
          value_number
          text
          period {
            id
            name
            start
            end
          }
        }
        expenses_direction {
          id
        }
      }
      subsidiary_entity_plans(filter: $subsidiaryEntityPlanFilter) {
        region {
          id
          name
          code_nalog
        }
      }
    }
  }

  ${FRAGMENT_FEDERAL_RESULT_FOR_RESULT_PAGE}
`;

export const QUERY_GET_ROADMAP_EVENTS = gql`
  query getRoadmapEvents(
    $filter: RoadmapEventsFilter
    $regionalEventsFilter: RegionalRoadmapEventsFilter
  ) {
    roadmapEventsPage(
      input: {
        filter: $filter
        sorting: [{ field: plan_term, direction: ASC }]
      }
    ) {
      list {
        ...roadmapFederalEvent
        regional_roadmap_events(filter: $regionalEventsFilter) {
          id
          region_id
          fact_term
          status
          roadmap_event_documents {
            ...RoadmapEventDocument
          }
        }
      }
    }
  }

  ${FRAGMENT_ROADMAP_EVENT}
  ${FRAGMENT_ROADMAP_EVENT_DOCUMENT}
`;

export const QUERY_GET_ROADMAP_FEDERAL_EVENTS = gql`
  query getRoadmapFederalEvents($filter: RoadmapEventsFilter) {
    roadmapEventsPage(
      input: {
        filter: $filter
        sorting: [{ field: plan_term, direction: ASC }]
      }
    ) {
      list {
        ...roadmapFederalEvent
      }
    }
  }
  ${FRAGMENT_ROADMAP_EVENT}
`;

export const QUERY_GET_RESULT_FEDERAL = gql`
  query getResultFederal(
    $id: ID!
    $subsidiaryEntityPlansFilter: SubsidiaryEntityPlanFilterInput
  ) {
    resultFederal(id: $id) {
      id
      roadmap_id
      name
      name_full
      description
      unit {
        id
        name
      }
      owner_task {
        id
        owner_id
        federal_project {
          id
          code
          name
          name_full
          project_code
        }
      }
      roadmap {
        id
        subsidiary_entity_plans(filter: $subsidiaryEntityPlansFilter) {
          year
          amount
          region {
            id
            name
            code_nalog
          }
        }
      }
      control_points {
        id
        name
        name_full
        description
        events {
          id
          name
          description
          date_start
          date_end
        }
      }
      expenses_direction {
        id
      }
      values {
        value_number
        value_type
        period {
          id
          name
          start
          end
        }
      }
      fact_values {
        value_number
        value_type
        period {
          id
          name
          start
          end
        }
      }
      characteristics {
        id
        name
        name_full
        unit {
          id
          name
          name_local
          name_local_short
        }
        plan_result_characteristics {
          period_id
          period {
            id
            name
            start
            end
            type
          }
          value_number
          text
          value_type
        }
        fact_result_characteristics {
          period_id
          period {
            id
            name
            start
            end
            type
          }
          value_number
          text
          value_type
        }
      }
    }
  }
`;

export const QUERY_GET_RESULT_REGIONAL = gql`
  query getResultRegional(
    $filter: RegionalResultFilter!
    $subsidiaryEntityPlansFilter: SubsidiaryEntityPlanFilterInput
  ) {
    resultRegional(filter: $filter) {
      id
      roadmap_id
      name
      name_full
      description
      result_federal {
        id
        roadmap_id
        name
        name_full
        description
        unit {
          id
          name
        }
        owner_task {
          id
          owner_id
          federal_project {
            id
            code
            name
            name_full
            project_code
          }
        }
        roadmap {
          id
          subsidiary_entity_plans(filter: $subsidiaryEntityPlansFilter) {
            year
            amount
            region {
              id
              name
              code_nalog
            }
          }
        }
        control_points {
          id
          name
          name_full
          description
          events {
            id
            name
            description
            date_start
            date_end
          }
        }
        expenses_direction {
          id
        }
      }
      values {
        value_number
        value_type
        period {
          id
          name
          start
          end
        }
      }
      fact_values {
        value_number
        value_type
        period {
          id
          name
          start
          end
        }
      }
    }
  }
`;

export const QUERY_GET_RESULT_FEDERALS = gql`
  query getResultFederals($ids: [ID!]) {
    resultFederals(input: { filter: { ids: $ids } }) {
      list {
        id
        name
        name_full
        description
      }
    }
  }
`;
