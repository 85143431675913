import React, { useMemo } from 'react';
import { useRequestStore } from '@proscom/prostore-react';

import CommonMap from '../../common/Map/CommonMap/CommonMap';
import { STORE_MAP } from '../../store/stores';
import { useRegionsWithFinancialRatings } from '../../store/useRegions';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { tryNumber } from '../../utils/math';
import {
  INDICATOR_INTERNET_SPEED,
  SPEED_TYPE_COUNTRYSIDE
} from '../../utils/indicators';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { useColorifiedMapItems } from '../../utils/useColoredRegionsData';
import { formatDateIso } from '../../utils/date';

export const DistrictMap = ({
  code,
  changeQuery,
  queryParams,
  selectedFp,
  ...props
}) => {
  const mapVariables = useMemo(
    () =>
      code
        ? {
            type: 'district',
            code: `FD${code}`
          }
        : null,
    [code]
  );
  const query = useRequestStore(STORE_MAP, mapVariables);
  const {
    organization_type,
    indicator_type,
    res,
    roadmap,
    speed_type
  } = queryParams;
  const { year, yearMonth, month } = useQueryPeriodFast(
    queryParams,
    changeQuery
  );
  const rangeMin = tryNumber(queryParams.range_min, 0);
  const rangeMax = tryNumber(queryParams.range_max, 100);

  const countOrganizationsFilter = useMemo(
    () => ({
      bs_organization_type_okved_ids:
        organization_type && organization_type !== 'ALL'
          ? [organization_type]
          : undefined,
      federal_project_ids: selectedFp ? selectedFp.id : undefined,
      result_ids: res ? res.split(',') : undefined,
      roadmap_ids: roadmap ? [roadmap] : undefined,
      roadmap_year: roadmap && year,
      is_rural: speed_type === SPEED_TYPE_COUNTRYSIDE,
      internet_speed_filter:
        indicator_type === INDICATOR_INTERNET_SPEED ? {} : undefined
    }),
    [
      organization_type,
      selectedFp,
      res,
      roadmap,
      year,
      speed_type,
      indicator_type
    ]
  );

  const regionsRatingsQuery = useRegionsWithFinancialRatings(yearMonth, {
    federalProjectCodes: selectedFp ? [selectedFp.project_code] : undefined,
    federalDistrictCodes: [code],
    countOrganizationsFilter,
    federalProjectId: selectedFp ? selectedFp.id : undefined,
    internetSpeedFilter: {
      yearMonths: [yearMonth],
      organizations_filter: countOrganizationsFilter
    },
    dcCompletionFilter: {
      federal_project_ids: selectedFp ? selectedFp.id : undefined,
      roadmap_ids: roadmap ? [roadmap] : undefined,
      year: year,
      date: formatDateIso(new Date())
    }
  });

  const regionsWithRatingsData = regionsRatingsQuery.state.data;

  const coloredRegionsData = useColorifiedMapItems(
    regionsWithRatingsData && regionsWithRatingsData.regions,
    {
      speed_type,
      indicator_type,
      rangeMax,
      query,
      rangeMin
    }
  );

  if (
    query.check.spinner ||
    !query.state.data ||
    !regionsRatingsQuery.state.data ||
    !regionsRatingsQuery.state.loaded
  ) {
    return <CircleProgressIndicator absolute />;
  }

  return (
    <>
      <CommonMap
        {...props}
        data={query.state.data}
        mapItems={coloredRegionsData}
        mapItemProp="code_nalog"
        indicator_type={indicator_type}
      />
      {(query.state.loading || regionsRatingsQuery.state.loading) && (
        <CircleProgressIndicator absolute />
      )}
    </>
  );
};
