import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ProjectIndicator } from '../Project/ProjectIndicator';
import { ChipText } from '../Chip/ChipText';
import { CircleProgressIndicator } from '../ProgressIndicator/CircleProgressIndicator';
import { formatNumber } from '../../../utils/formatNumber';
import {
  getStatusByValue,
  RatingStatus,
  STATUS_TYPES
} from '../../../utils/constants';
import { INDICATOR_DC_COMPLETION } from '../../../utils/indicators';
import s from './Card.module.scss';

const [SUCCESS, WARN, ERROR, DEFAULT] = [
  RatingStatus.SUCCESS,
  RatingStatus.WARN,
  RatingStatus.ERROR,
  'default'
];

const getColor = (status) => {
  switch (status) {
    case SUCCESS:
      return s._success;
    case WARN:
      return s._warn;
    case ERROR:
      return s._error;
    default:
      return s._default;
  }
};

const getTitle = (status) => {
  switch (status) {
    case SUCCESS:
      return 'Положительно';
    case WARN:
      return 'Умеренно';
    case ERROR:
      return 'Критично';
    default:
      return 'Нет данных';
  }
};

export function LineIndicator({ status, value, className }) {
  if (!status) {
    status = getStatusByValue(value);
  }
  return (
    <div className={classNames(s.chartLine, getColor(status), className)}>
      <div
        className={s.Card__chartLineFill}
        style={{
          width: (value || 0) + '%'
        }}
      />
    </div>
  );
}

export function CardChart({ item, status, decimals }) {
  return (
    <div className={s.Card__chart}>
      <div className={s.Card__description}>
        <div className={s.Card__title}>{item.title}</div>
        <div className={s.Card__percent}>
          {formatNumber(item.value, decimals)}
          {item.unit}
        </div>
      </div>
      <LineIndicator
        className={s.Card__chartLine}
        status={status}
        value={item.value}
      />
    </div>
  );
}

export function Card({
  loading,
  status,
  description,
  charts,
  decimal,
  className,
  ...props
}) {
  if (loading) {
    return (
      <div className={s.Card} {...props}>
        <CircleProgressIndicator />
      </div>
    );
  }
  return (
    <div className={classNames(s.Card, className)} {...props}>
      <ChipText
        title={getTitle(status)}
        className={classNames(getColor(status), s.Card__status)}
        icon={() => (
          <ProjectIndicator className={s.Card__svg} status={status} />
        )}
      />
      <div
        className={classNames(s.Card__article, {
          // [s._small]: charts && charts.length > 1
        })}
        // title={description}
      >
        {description}
      </div>
      <div className={s.Card__charts}>
        {charts &&
          charts.map((item, iItem) => {
            return (
              <CardChart
                key={iItem}
                item={item}
                status={
                  item.type === INDICATOR_DC_COMPLETION
                    ? getStatusByValue(item.value, STATUS_TYPES.COMPLETION)
                    : status
                }
                decimals={decimal}
              />
            );
          })}
      </div>
    </div>
  );
}

Card.SUCCESS = SUCCESS;
Card.WARN = WARN;
Card.ERROR = ERROR;
Card.DEFAULT = DEFAULT;

Card.propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.oneOf([SUCCESS, WARN, ERROR, DEFAULT]),
  description: PropTypes.string,
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.shape,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      unit: PropTypes.string
    })
  ),
  decimal: PropTypes.number,
  className: PropTypes.string
};
