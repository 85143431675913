import React from 'react';
import { useQueryPeriod } from '../../utils/useQueryPeriod';
import { NBSP } from '../../utils/utf';
import { useStateTabs } from '../../common/ui/Tab/TabRadio';
// import { Select } from '../../common/ui/Select/Select';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { GrbsFilter } from '../../common/Filters/GrbsFilter';
import { useUrlQueryParams } from '../../utils/useUrlQuery';
import {
  URL_QUERY_FP,
  URL_QUERY_GRBS,
  URL_QUERY_MONTH,
  URL_QUERY_QUARTER,
  URL_QUERY_YEAR
} from '../../store/stores';
import { ChartViewChart } from '../../common/ChartView/ChartView';
import { useBudgetChart } from './useBudgetChart';
import { ChartView } from './ChartView/ChartView';
import s from '../../common/ChartView/ChartView.module.scss';

const chartTypes = [
  {
    label: 'Бюджеты',
    value: 'budget'
  },
  {
    label: 'Субсидии и гранты',
    value: 'grants'
  }
];

const tabs = [
  {
    value: 'kass',
    label: 'Кассовое исполнение'
  },
  {
    value: 'budget',
    label: `Объем федерального бюджета`
  },
  {
    value: 'grants',
    label: 'Субсидии и гранты'
  }
];

const queryParams = [
  URL_QUERY_GRBS,
  URL_QUERY_YEAR,
  URL_QUERY_MONTH,
  URL_QUERY_QUARTER,
  URL_QUERY_FP,
  'type'
];

export const BudgetChart = React.memo(function BudgetChart({
  filter = {},
  className,
  wrapperClassName
}) {
  const [query, setQuery] = useUrlQueryParams(queryParams);
  const { yearMonth } = useQueryPeriod();
  const [tab, tabProps] = useStateTabs(tabs, (x) => x.value);

  const grbs = query.grbs || 'all';
  const {
    budgetCharts,
    kassCharts,
    grants,
    budgetData,
    pof,
    isLoading
  } = useBudgetChart({
    filter: {
      ...filter,
      grbs_codes: grbs && grbs !== 'all' ? [grbs] : undefined
    },
    yearMonth,
    query
  });

  const data =
    tab === 'budget'
      ? budgetCharts
      : tab === 'kass'
      ? kassCharts
      : tab === 'grants'
      ? grants
      : null;

  return (
    <>
      <ChartView
        tabs={tabs}
        tabProps={tabProps}
        className={className}
        wrapperClassName={wrapperClassName}
        controls={<GrbsFilter />}
        renderChart={({ width }) => {
          return isLoading || !data ? (
            <CircleProgressIndicator className={s.ChartView__loader} />
          ) : (
            <ChartViewChart
              data={data}
              legend
              width={width}
              height={192}
              minValueOptions={
                grbs === '073' && { barCount: 4, name: 'ПОФ', value: pof }
              }
            />
          );
        }}
      />
    </>
  );
});
