import React from 'react';
import { getMonthName } from '../../../utils/constants';
import s from './InfographicChart.module.scss';

export function ReportChartView({ title, year, month, children }) {
  return (
    <div className={s.ReportChartView}>
      <div className={s.ReportChartView__title}>{title}</div>
      <div className={s.ReportChartView__date}>
        <span>{getMonthName(month)}</span>
        <span>{year}</span>
      </div>
      <div className={s.ReportChartView__content}>
        <div className={s.ReportChartView__children}>
          <div className={s.ReportChartView__inner}>{children}</div>
        </div>
      </div>
    </div>
  );
}
