import React from 'react';
import { CardDownload } from '../../../common/ui/CardSmall/CardDownload';
import { useReportAuthDownload } from '../../../utils/useReportAuthDownload';

export function CardReportStaticDownload({
  href,
  loading,
  cookieLoader,
  onDownloadFinish,
  type,
  onClick,
  ...props
}) {
  const [downloadId, handleDownload, isLoading] = useReportAuthDownload({
    cookieLoader,
    onDownloadFinish,
    onClick,
    type,
    url: href
  });

  return (
    <CardDownload
      {...props}
      downloadId={downloadId}
      onClick={handleDownload}
      isLoading={loading || isLoading}
    />
  );
}
