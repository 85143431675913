import React, { useCallback, useState } from 'react';
import { Heading2 } from '../../../common/PageTitle/Heading';
import { RoadmapEventsList } from '../../../common/EventsList/RoadmapEvent';
import { RoadmapEventPopup } from '../../../common/EventsList/RoadmapEventPopup';
import { queryLoader } from '../../../common/QueryLoader';
import s from '../ResultPage.module.scss';

export function RoadmapEvents({
  eventsQuery,
  totalRegions,
  currentRegion,
  allRegions,
  regionCode
}) {
  const [openedEvent, setOpenedEvent] = useState(null);
  const handleCloseEventPopup = useCallback(() => {
    setOpenedEvent(null);
  }, []);

  const eventsData = eventsQuery.state.data;
  const regionCodeNotFound = regionCode !== undefined && !currentRegion;

  return (
    <>
      <div className={s.RoadmapPage__eventListContainer}>
        <Heading2 className={s.RoadmapPage__heading}>
          Мероприятия дорожной карты
        </Heading2>
        {regionCodeNotFound ? (
          <div className={s.RoadmapPage__eventListPlaceholder}>
            <p>В текущем году мероприятий нет</p>
          </div>
        ) : (
          queryLoader(eventsQuery) || (
            <RoadmapEventsList
              className={s.RoadmapPage__eventList}
              events={eventsData}
              totalRegions={totalRegions}
              onOpenModal={setOpenedEvent}
              currentRegion={currentRegion}
            />
          )
        )}
      </div>
      {!currentRegion && (
        <RoadmapEventPopup
          isOpen={!!openedEvent}
          onClose={handleCloseEventPopup}
          event={openedEvent}
          regions={allRegions}
          totalRegions={totalRegions}
        />
      )}
    </>
  );
}

export function RegionalRoadmapEvents({
  eventsQuery,
  currentRegion,
  regionCode
}) {
  const eventsData = eventsQuery.state.data;
  const regionCodeNotFound = regionCode !== undefined && !currentRegion;

  return (
    <>
      <div className={s.RoadmapPage__eventListContainer}>
        <Heading2 className={s.RoadmapPage__heading}>
          Мероприятия дорожной карты
        </Heading2>
        {regionCodeNotFound ? (
          <div className={s.RoadmapPage__eventListPlaceholder}>
            <p>В текущем году мероприятий нет</p>
          </div>
        ) : (
          queryLoader(eventsQuery) || (
            <RoadmapEventsList
              className={s.RoadmapPage__eventList}
              events={eventsData}
              currentRegion={currentRegion}
              onlyRegional={true}
            />
          )
        )}
      </div>
    </>
  );
}
