import { useRef } from 'react';

/**
 * Позволяет использовать функцию-фабрику для первоначального значения рефа
 *
 * @param factory {Function} - функция-фабрика,
 *    возвращающая первоначальное значение рефа
 * @returns {React.MutableRefObject<any>}
 */
export function useFactoryRef(factory) {
  const ref = useRef(null);
  if (!ref.current) {
    ref.current = factory();
  }
  return ref;
}
