import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseBtn } from '../../../assets/Icons/Close.svg';
import { IconButton } from '../../../common/ui/IconButton/IconButton';
import { Portal } from '../../../common/portal';
import { useClickOutside } from '../../../utils/useClickOutside';
import s from './RightMenuPopup.module.scss';

export function RightMenuPopup({
  onClose,
  isActive,
  outsideRefs = [],
  children
}) {
  const buttonRef = useRef(null);
  const popupRef = useRef(null);
  const backgroundRef = useRef(null);

  const hideMenuOnClickOutside = useMemo(() => {
    if (isActive) {
      return () => {
        onClose(false);
      };
    }
    return null;
  }, [isActive, onClose]);

  useClickOutside(hideMenuOnClickOutside, [
    buttonRef,
    popupRef,
    backgroundRef,
    ...outsideRefs
  ]);

  const onCloseClick = (event) => {
    // На ipad проблема с клик событиями на fixed и absolute блоках
    event.preventDefault();
    if (typeof onClose === 'function') {
      onClose(false);
    }
  };

  return (
    <div
      ref={popupRef}
      className={classNames(s.Goals, { [s._active]: isActive })}
    >
      <Portal id={'backgroundEffect'}>
        <div
          ref={backgroundRef}
          onClick={onCloseClick}
          className={classNames(s.backgroundWrapper, { [s._active]: isActive })}
        />
      </Portal>
      <div ref={buttonRef} className={s.Goals__close}>
        <IconButton onClick={onCloseClick} icon={CloseBtn} />
      </div>
      {children}
    </div>
  );
}
