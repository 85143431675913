import gql from 'graphql-tag';

export const FRAGMENT_CONTROL_POINT_EVENT = gql`
  fragment ControlPointEvent on ControlPointEventType {
    id
    name
    date_start
    date_end
    description
    result_specification
    executor {
      id
      name
    }
    plan_events {
      plan_data {
        federal_project {
          id
          name
          name_full
          project_code
        }
        regional_project {
          id
          name
          name_full
          code
          federal_project {
            id
            project_code
          }
        }
      }
      date_start
      date_end
      date_result
    }
    control_point {
      id
      name
      name_full
      description
      owner_federal_result {
        id
        name_full
        description
      }
      owner_regional_result {
        id
        name
        name_full
        region {
          id
          name
          code_nalog
        }
      }
    }
  }
`;

export const FRAGMENT_ROADMAP_EVENT = gql`
  fragment RoadmapEvent on RoadmapEventType {
    id
    name
    name_full
    responsible_person {
      id
      first_name
      surname
    }
    responsibility_level_vo
    responsibility_level_rf_subject
    fact_term
    plan_term
    event_result
    roadmap {
      id
      name_full
      responsible
      subsidiary_entity_plans(filter: { active: true }) {
        year
        amount
        region {
          id
          name
          code_nalog
        }
      }
      result {
        id
        name_full
        owner_task {
          id
          owner_id
        }
      }
    }
    count_region_status {
      status
      count
    }
    plan_roadmap {
      event_result
      term
      plan_data {
        federal_project {
          id
          name
          name_full
          project_code
        }
        regional_project {
          id
          name
          name_full
          code
          federal_project {
            project_code
          }
        }
      }
    }
  }
`;

export const FRAGMENT_CALENDAR_CONTROL_POINTS = gql`
  fragment ControlPoint on ControlPointType {
    id
    name
    name_full
    description
    owner_federal_result {
      id
      name
      name_full
    }
    owner_regional_result {
      id
      name
      name_full
      region {
        id
        name
        code_nalog
      }
    }
    plan_events {
      plan_data {
        federal_project {
          id
          name
          name_full
          project_code
        }
        regional_project {
          id
          name
          name_full
          code
          federal_project {
            id
            project_code
          }
        }
      }
      implementation_period_of_cp
    }
  }
`;

export const QUERY_GET_CONTROL_POINT_EVENTS = gql`
  query getCalendarControlPointEvents($filter: CalendarFilterInput) {
    calendarControlPointEvents(filter: $filter) {
      ...ControlPointEvent
    }
  }

  ${FRAGMENT_CONTROL_POINT_EVENT}
`;

export const QUERY_GET_ROADMAPS_CALENDAR_EVENTS = gql`
  query getCalendarRoadmapEvents($filter: CalendarFilterInput) {
    calendarRoadmapEvents(filter: $filter) {
      ...RoadmapEvent
    }
  }

  ${FRAGMENT_ROADMAP_EVENT}
`;

export const QUERY_GET_CALENDAR_CONTROL_POINTS = gql`
  query getCalendarControlPoints($filter: CalendarFilterInput) {
    calendarControlPoints(filter: $filter) {
      ...ControlPoint
    }
  }

  ${FRAGMENT_CALENDAR_CONTROL_POINTS}
`;

export const QUERY_GET_ALL_CALENDAR_EVENTS = gql`
  query getAllCalendarEvents($filter: CalendarFilterInput) {
    controlPointEvents: calendarControlPointEvents(filter: $filter) {
      ...ControlPointEvent
    }
    roadmapEvents: calendarRoadmapEvents(filter: $filter) {
      ...RoadmapEvent
    }
    controlPoints: calendarControlPoints(filter: $filter) {
      ...ControlPoint
    }
  }

  ${FRAGMENT_CONTROL_POINT_EVENT}
  ${FRAGMENT_ROADMAP_EVENT}
  ${FRAGMENT_CALENDAR_CONTROL_POINTS}
`;

export const QUERY_GET_ALL_CALENDAR_EVENTS_STATUS = gql`
  fragment calendarStatuses on CalendarStatusesType {
    date
    success
    in_progress
    error
    warn
  }

  query getAllCalendarEventsStatus($filter: CalendarFilterInput) {
    controlPointEvents: calendarControlPointEventsStatus(filter: $filter) {
      ...calendarStatuses
    }
    roadmapEvents: calendarRoadmapEventsStatus(filter: $filter) {
      ...calendarStatuses
    }
    controlPoints: calendarControlPointsStatus(filter: $filter) {
      ...calendarStatuses
    }
  }
`;
