import React from 'react';
import PropTypes from 'prop-types';
import { color as dColor, interpolateHcl, max as d3max, scaleLinear } from 'd3';
import { HorizontalBar } from './HorizontalBar';
import colorVars from '../../../colors.scss';

export function HorizontalChart({
  data,
  total,
  color,
  barHeight,
  barGap,
  legendPadding,
  sizeWidth,
  paddingRight
}) {
  let colorInterpolator = () => '#fff';

  if (color) {
    const opacityColor = dColor(color);
    opacityColor.opacity = 0;
    const endColor = opacityColor.formatRgb();

    colorInterpolator = interpolateHcl(endColor, color);
  }
  const totalCount = total || d3max(data, (d) => d.value) || 1;

  const valueInterpolator = scaleLinear()
    .domain([0, totalCount])
    .range([1, sizeWidth - paddingRight - legendPadding])
    .clamp(true);

  const barsData = data.map(
    ({ id, name, titleIfValueZero, value, legend, color }, index) => {
      const barData = {
        x: legendPadding,
        y: barHeight * index + barGap * index,
        width: valueInterpolator(value),
        legend: legend,
        color: color ? color : colorInterpolator((index + 1) / data.length)
      };

      let title = value;
      let titleColor = null;
      if (value === 0 && titleIfValueZero) {
        const dashSize = totalCount * 0.01;

        title = titleIfValueZero;
        barData.width = valueInterpolator(dashSize);
        titleColor = colorVars.blueDark;
      }

      return (
        <HorizontalBar
          key={id}
          id={id}
          index={index}
          name={name}
          title={title}
          titleColor={titleColor}
          barHeight={barHeight}
          legendPadding={legendPadding}
          data={barData}
        />
      );
    }
  );

  const sizeHeight = barHeight * data.length;

  return (
    <svg
      style={{ width: sizeWidth, height: sizeHeight }}
      viewBox={`${0} ${0} ${sizeWidth} ${sizeHeight}`}
    >
      {barsData}
    </svg>
  );
}

HorizontalChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string,
      titleIfValueZero: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string
    })
  ),
  legend: PropTypes.string,
  color: PropTypes.string,
  total: PropTypes.number,
  barGap: PropTypes.number,
  legendPadding: PropTypes.number,
  sizeWidth: PropTypes.number
};

HorizontalChart.defaultProps = {
  barGap: 0,
  barHeight: 16,
  sizeWidth: 100,
  legendPadding: 28,
  paddingRight: 0
};
