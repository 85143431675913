import {
  useGraphqlQuery,
  useGraphqlQueryVars
} from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { useMemo } from 'react';
import {
  QUERY_GET_REGION,
  QUERY_GET_REGION_RATING_ACHIEVEMENT,
  QUERY_GET_REGIONS,
  QUERY_GET_REGIONS_WITH_FINANCIAL_RATINGS,
  QUERY_GET_REGIONS_WITH_RP
} from '../graphql/queries/region';
import { filterSearch } from '../utils/filterSearch';
import { compareNumericStrings, findItemByFiledName } from '../utils/data';
import {
  INDICATOR_RESULT_ACH,
  INDICATOR_TARGET_ACH
} from '../utils/indicators';
import { QUERY_RESULT_AND_INDICATOR_ACHIEVEMENT_DATE } from '../graphql/queries/federalDistricts';

const getRegionsQueryOptions = {
  query: QUERY_GET_REGIONS,
  mapData: (result) =>
    [...result.regions].sort((a, b) => {
      const aName = a.name_full || a.name;
      const bName = b.name_full || b.name;
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    })
};

const federalDistrictAchActualityDateQueryOptions = {
  query: QUERY_RESULT_AND_INDICATOR_ACHIEVEMENT_DATE,
  mapData: (data) => data.subjectRatingActualityDate
};

const getRegionsWithRProjectsQueryOptions = {
  query: QUERY_GET_REGIONS_WITH_RP,
  mapData: (result) =>
    [...result.regions].sort((a, b) => {
      const aName = a.name_full || a.name;
      const bName = b.name_full || b.name;
      return aName < bName ? -1 : aName > bName ? 1 : 0;
    })
};

const getRegionQueryOptions = {
  query: QUERY_GET_REGION,
  mapData: (result) => {
    if (
      result.dcCompletion &&
      result.dcCompletion.length > 0 &&
      result.region
    ) {
      result.region.dc_completion_percent =
        result.dcCompletion[0].dc_completion_percent;
    }
    return result.region;
  },
  skipQuery: skipIfNull(null)
};

const getRegionsWithFinancialRatingsQueryOptions = {
  query: QUERY_GET_REGIONS_WITH_FINANCIAL_RATINGS,
  skipQuery: skipIfNull(null),
  mapData: (result) => {
    for (const region of result.regions) {
      region.hasMbt = findItemByFiledName(
        result.mbtRegionalProject,
        region.id,
        'region_id',
        null
      );

      const finance = findItemByFiledName(
        result.finances,
        region.id,
        'region_id'
      );
      if (finance) {
        region.accept = finance.accept;
        region.cash_completion = finance.cash_completion;
      }
      const dcCompletion = findItemByFiledName(
        result.dcCompletion,
        region.id,
        'region_id'
      );
      if (dcCompletion) {
        region.dc_completion_percent = dcCompletion.dc_completion_percent;
      }
      const result_ach = findItemByFiledName(
        result.resultsRatings,
        region.id,
        'subject_id'
      );
      if (result_ach) {
        region.result_ach = result_ach.value;
      }
      const target_ach = findItemByFiledName(
        result.indicatorsRatings,
        region.id,
        'subject_id'
      );
      if (target_ach) {
        region.target_ach = target_ach.value;
      }
    }
    return result;
  }
};

const variables = [];

export function useRegions(filter) {
  return useGraphqlQueryVars({
    queryOptions: getRegionsQueryOptions,
    variableCreator: [() => (filter ? { filter } : null), [filter]]
  });
}

export function useRegionsWithRProjects() {
  return useGraphqlQuery({
    queryOptions: getRegionsWithRProjectsQueryOptions,
    variables
  });
}

export function useRegion(codeNalog, variables = {}) {
  return useGraphqlQueryVars({
    queryOptions: getRegionQueryOptions,
    variableCreator: [
      () => ({
        code_nalog: codeNalog,
        ...variables
      }),
      [codeNalog, variables]
    ]
  });
}

const levelRatingEnum = {
  REGION_LEVEL: 'regional_project',
  REGION: 'region'
};

export function useRegionsWithFinancialRatings(yearMonth, filter = undefined) {
  const levelRating = useMemo(() => {
    const hasFp = !!filter?.federalProjectCodes || !!filter?.federalProjectId;
    return hasFp ? levelRatingEnum.REGION_LEVEL : levelRatingEnum.REGION;
  }, [filter]);

  return useGraphqlQuery({
    queryOptions: getRegionsWithFinancialRatingsQueryOptions,
    variables: yearMonth ? { yearMonth, levelRating, ...filter } : null
  });
}

/**
 *
 * @param federalProjectId {ID}
 * @param yearMonth {string}
 * @returns {{string: Date}}
 */
export function useRegionAchActualityDate(federalProjectId, yearMonth) {
  const levelRating = useMemo(() => {
    const hasFp = !!federalProjectId;
    return hasFp ? levelRatingEnum.REGION_LEVEL : levelRatingEnum.REGION;
  }, [federalProjectId]);

  const actualityDate = {};

  const resultAchActualityDateQuery = useGraphqlQuery({
    queryOptions: federalDistrictAchActualityDateQueryOptions,
    variables: {
      yearMonth,
      data_type: 'results_achievement_percentage',
      level: levelRating
    }
  });

  const indicatorAchActualityDateQuery = useGraphqlQuery({
    queryOptions: federalDistrictAchActualityDateQueryOptions,
    variables: {
      yearMonth,
      data_type: 'indicators_achievement_percentage',
      level: levelRating
    }
  });
  actualityDate[INDICATOR_TARGET_ACH] =
    indicatorAchActualityDateQuery.state.data;
  actualityDate[INDICATOR_RESULT_ACH] = resultAchActualityDateQuery.state.data;
  return actualityDate;
}

export function processRegionsWithFinancialRatings({
  regionsAndRatings,
  filter,
  sort
}) {
  let result = regionsAndRatings;
  if (filter) {
    if (filter.federal_district_id) {
      result = result.filter(
        (region) => region.federal_district_id === filter.federal_district_id
      );
    }
    if (filter.search) {
      result = filterSearch(
        filter.search,
        regionsAndRatings,
        (region) => region.name
      );
    }
  }

  // todo проверить правильность округления (нужен floor или round)
  result =
    result &&
    result.map((region) => ({
      region: region,
      ratings: {
        accept: Math.floor(region.accept),
        cash_completion: region.cash_completion,
        dc_completion_percent: Math.floor(region.dc_completion_percent),
        result_ach: Math.floor(region.result_ach),
        target_ach: Math.floor(region.target_ach)
      }
    }));
  if (sort) {
    result.sort((first, second) => {
      const fValue = first.ratings[sort.indicator];
      const sValue = second.ratings[sort.indicator];
      return compareNumericStrings(
        fValue,
        sValue,
        sort.direction === 'asc' ? 1 : -1
      );
    });
  }
  return result;
}

const getRegionAchievementQueryOptions = {
  query: QUERY_GET_REGION_RATING_ACHIEVEMENT,
  skipQuery: skipIfNull(null)
};

export const useRatingAchievement = (filter) => {
  const levelRating = useMemo(() => {
    const hasFp = !!filter?.federalProjectCodes || !!filter?.federalProjectId;
    return hasFp ? levelRatingEnum.REGION_LEVEL : levelRatingEnum.REGION;
  }, [filter]);

  return useGraphqlQuery({
    queryOptions: getRegionAchievementQueryOptions,
    variables: filter ? { levelRating, ...filter } : null
  });
};
