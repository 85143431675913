import React, { useCallback, useState } from 'react';
import { useStore } from '@proscom/prostore-react';
import { Button } from '../../common/ui/Button/Button';
import { STORE_AUTH, STORE_CACHE } from '../../store/stores';
import { userRoles } from '../../store/AuthStore';
import {
  getGraphqlErrorMessage,
  mutationErrors
} from '../../graphql/graphqlErrors';
import { User } from '../../data/user';
import { LoginPageLayout } from '../_layouts/LoginPageLayout';
import s from './LogoutPage.module.scss';

const getUserProfileDescription = (role, region) =>
  role === userRoles.ADMIN
    ? 'ЛК администратора'
    : role === userRoles.FEDERAL_ADMIN
    ? 'ЛК федерального проектного офиса'
    : role === userRoles.REGION_ADMIN
    ? `ЛК регионального ведомственного проектного офиса (${region &&
        region.name})`
    : 'Личный кабинет';

const LogoutPage = ({ history }) => {
  const [auth, authStore] = useStore(STORE_AUTH);
  const [cache, cacheStore] = useStore(STORE_CACHE);

  const [isLoading, setLoading] = useState(false);
  const [isCacheLoading, setIsCacheLoading] = useState(false);

  const handleLogout = useCallback(() => {
    setLoading(true);
    return authStore
      .logOut()
      .then(() => history.push('/login'))
      .catch((e) => {
        setLoading(false);
        const error = getGraphqlErrorMessage(e, mutationErrors.logOut);
        console.error(error);
      });
  }, [authStore, history]);
  const user = User.getUser(auth);
  const region = user && user.region;

  const handleResetCache = useCallback(() => {
    setIsCacheLoading(true);
    return cacheStore
      .resetCache()
      .then(() => {
        setIsCacheLoading(false);
      })
      .catch((err) => {
        setIsCacheLoading(false);
        console.error(err);
      });
  }, [cacheStore]);

  if (!user) return false;

  return (
    <LoginPageLayout>
      <div className={s.UserInfo}>
        <h1 className={s.UserInfo__name}>{user.name || 'Пользователь'}</h1>
        <p className={s.UserInfo__description}>
          {getUserProfileDescription(user.role, region)}
        </p>
        {user.role === userRoles.ADMIN && (
          <Button
            variant={Button.SECONDARY}
            loading={isCacheLoading}
            type="button"
            className={s.ClearCacheButton}
            onClick={handleResetCache}
          >
            Сбросить кэши
          </Button>
        )}
        <Button
          loading={isLoading}
          type="button"
          className={s.LogoutButton}
          onClick={handleLogout}
        >
          Выйти из аккаунта
        </Button>
      </div>
    </LoginPageLayout>
  );
};

export default LogoutPage;
