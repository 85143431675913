import { compareDateStrings, makeComparator } from '../utils/data';

export const RoadmapEventStatus = {
  COMPLETED: 'COMPLETED',
  APPROVAL: 'APPROVAL',
  NOT_COMPLETED: 'NOT_COMPLETED'
};

export const RoadmapEventDocumentStatus = {
  APPROVED: 'APPROVED',
  NOT_APPROVED: 'NOT_APPROVED',
  APPROVAL: 'APPROVAL'
};

export const reorderRoadmapEvents = (events) => {
  return (
    events &&
    events
      .slice()
      .sort(
        makeComparator(
          compareDateStrings,
          (s) => (s.plan_roadmap && s.plan_roadmap.term) || null
        )
      )
  );
};
