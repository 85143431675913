import React, { Fragment } from 'react';
import classNames from 'classnames';
import { SwipeBox } from '../SwipeBox/SwipeBox';
import { useWindowSize } from '../../store/useWindowSize';
import s from './RightPaneLayout.module.scss';

export function RightPaneLayout({
  className,
  children,
  right,
  rightTitle,
  extra,
  fixed,
  topBar,
  popup,
  onRightClose,
  rightOpened
}) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  return (
    <Fragment>
      <div
        className={classNames(s.RightPaneLayout, className, {
          [s._topbar]: !!topBar
        })}
      >
        {isMobile ? (
          <SwipeBox isOpened={rightOpened} onClose={onRightClose}>
            {right}
          </SwipeBox>
        ) : (
          <div className={s.RightPaneLayout__right}>
            <div className={s.RightPaneLayout__rightScroll}>{right}</div>
          </div>
        )}
        <div
          className={classNames(s.RightPaneLayout__main, {
            [s._fixed]: fixed
          })}
        >
          <div className={s.RightPaneLayout__mainScroll}>{children}</div>
        </div>
        {topBar}
        {extra}
      </div>
      {popup}
    </Fragment>
  );
}
