import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { findMinWrapped } from '../../../utils/math';
import HistogramSectionChart from './HistogramSectionChart';

function shiftTargetAngle(prevAngle, targetAngle) {
  return findMinWrapped(prevAngle, targetAngle, 360);
}

function HistogramPieChart({
  data,
  colors,
  size,
  innerRadius,
  lineStroke,
  innerBarRadius,
  outerBarRadius,
  barGapAngle,
  barWidthAngle,
  segmentOpacity,
  targetSection,
  offsetZeroAngle,
  total,
  onSectionClick,
  onBarClick,
  rotateAngle,
  className,
  style,
  isMobile
}) {
  const prevRotationAngleRef = useRef(0);
  const prevRotationAngle = prevRotationAngleRef.current;

  const totalSectionsCount = data.length;
  let totalElementCount = 0;

  for (let item of data) {
    totalElementCount += item.values.length;
  }

  const gapAngle =
    (360 -
      (totalElementCount * (barGapAngle + barWidthAngle) +
        barGapAngle * totalSectionsCount)) /
    totalSectionsCount;

  let alpha = 0;
  let angleArray = [0];
  let targetAngle = 0;

  data.forEach((item, index) => {
    const elementCount = item.values.length;
    const totalAngle =
      elementCount * barWidthAngle + elementCount * barGapAngle + barGapAngle;

    if (index === targetSection) {
      targetAngle = alpha + offsetZeroAngle + totalAngle / 2;
    }

    const currentAngle = totalAngle + alpha + gapAngle;
    angleArray.push(currentAngle);

    alpha = currentAngle;
  });

  let rotationAngle = shiftTargetAngle(prevRotationAngle, targetAngle);
  rotationAngle = isMobile ? rotationAngle - 90 : rotationAngle;

  prevRotationAngleRef.current = rotationAngle;

  return (
    <svg
      viewBox={`${-size / 2} ${-size / 2} ${size} ${size}`}
      className={className}
      style={style}
    >
      <g
        style={{
          transition: 'transform 0.5s ease',
          transform: `rotate(${-rotationAngle + 90}deg)`
        }}
      >
        {data.map((item, index) => (
          <g key={index}>
            <HistogramSectionChart
              data={item}
              color={item.color || colors[index]}
              size={size}
              rotateAngle={angleArray[index]}
              innerRadius={innerRadius}
              segmentOpacity={segmentOpacity}
              lineStroke={lineStroke}
              innerBarRadius={innerBarRadius}
              outerBarRadius={outerBarRadius}
              barGapAngle={barGapAngle}
              barWidthAngle={barWidthAngle}
              total={total}
              onSectionClick={(e) => onSectionClick(index, e)}
              onBarClick={(bar, iBar, e) => onBarClick(bar, iBar, index, e)}
            />
          </g>
        ))}
      </g>
    </svg>
  );
}

HistogramPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      color: PropTypes.string,
      values: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          value: PropTypes.number.isRequired
        })
      ).isRequired
    })
  ).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  size: PropTypes.number,
  innerRadius: PropTypes.number,
  lineStroke: PropTypes.number,
  innerBarRadius: PropTypes.number,
  outerBarRadius: PropTypes.number,
  offsetZeroAngle: PropTypes.number,
  barGapAngle: PropTypes.number,
  segmentOpacity: PropTypes.number,
  barWidthAngle: PropTypes.number,
  rotateAngle: PropTypes.number,
  total: PropTypes.number,
  targetSection: PropTypes.number
};

HistogramPieChart.defaultProps = {
  size: 200,
  innerRadius: 60,
  lineStroke: 2,
  innerBarRadius: 70,
  outerBarRadius: 100,
  segmentOpacity: 0.12,
  offsetZeroAngle: 0,
  barGapAngle: 2,
  rotateAngle: 0,
  barWidthAngle: 6,
  total: 100,
  targetSection: 0
};

export default HistogramPieChart;
