import { sum as d3sum } from 'd3';

import { formatNumber, formatPercent } from '../../../utils/formatNumber';
import { ExpensesType } from '../../../data/expensesType';

function getIndices(data) {
  return data
    .map((v, i) => ({ ...v.expenses_type, index: i }))
    .sort((a, b) => a.code - b.code);
}

function createGetData(indices, array, type) {
  return (fieldName) => {
    return indices.map((vr) => {
      return {
        id: `${vr.id}&${fieldName}_${type}`,
        vr: vr.id,
        field: fieldName,
        type,
        pie: `${fieldName}_${type}`,
        value: array[vr.index][fieldName] / 1e9
      };
    });
  };
}

const getExpensesTypeTableMapper = (types, type) => (expenseType) => {
  const { id, name_full, index } = expenseType;
  const { obligations, total_budget, cash_expenses } = types[index];
  return [
    { id, value: ExpensesType.getDisplayName(expenseType) },
    {
      id: `${id}&total_budget_${type}`,
      value: formatNumber(total_budget / 1e9, 2)
    },
    {
      id: `${id}&obligations_${type}`,
      value: `${formatNumber(obligations / 1e9, 2)}\n${formatPercent(
        obligations,
        total_budget
      )}`
    },
    {
      id: `${id}&cash_expenses_${type}`,
      value: `${formatNumber(cash_expenses / 1e9, 2)}\n${formatNumber(
        (cash_expenses / total_budget) * 100,
        1
      )}%`
    }
  ];
};

function getSectionSum(arr) {
  return d3sum(arr, (v) => +v.value);
}

export function getCentralChartDataVR(financesData) {
  const { federal, federalTypes, subjectTypes } = financesData;

  let { total_budget, budget_limits, cash_expenses, obligations } = federal;

  const dividedTotalBudget = total_budget / 1e9;

  const vrs = getIndices(federalTypes);
  const vrsRp = getIndices(subjectTypes);

  const getData = createGetData(vrs, federalTypes, 'fed');
  const getDataRp = createGetData(vrsRp, subjectTypes, 'subj');

  const federalBudgetLimitsPieData = getData('budget_limits');
  const totalBudgetPieData = getData('total_budget');
  const obligationsPieData = getData('obligations');
  const federalCashExpensesPieData = getData('cash_expenses');
  const regionalTotalBudgetPieData = getDataRp('total_budget');
  const regionalObligationsPieData = getDataRp('obligations');
  const regionalCashExpensesPieData = getDataRp('cash_expenses');

  const budgetLimitsSum = getSectionSum(federalBudgetLimitsPieData);
  const totalBudgetSum = getSectionSum(totalBudgetPieData);
  const obligationsSum = getSectionSum(obligationsPieData);
  const fedCashSum = getSectionSum(federalCashExpensesPieData);
  const subjTotalBudget = getSectionSum(regionalTotalBudgetPieData);
  const subjObligations = getSectionSum(regionalObligationsPieData);
  const subjCashSum = getSectionSum(regionalCashExpensesPieData);

  const federalTableData = {
    data: vrs.map(getExpensesTypeTableMapper(federalTypes, 'fed')),
    togglers: [
      {
        id: 'total_budget_fed',
        values: [
          'Бюджетные ассигнования',
          formatNumber(totalBudgetSum),
          formatPercent(1)
        ]
      },
      {
        id: 'obligations_fed',
        values: [
          'Принято бюджетных обязательств',
          formatNumber(obligationsSum),
          formatPercent(obligationsSum, totalBudgetSum)
        ]
      },
      {
        id: 'cash_expenses_fed',
        values: [
          'Факт',
          formatNumber(fedCashSum),
          formatPercent(fedCashSum, totalBudgetSum)
        ]
      }
    ]
  };
  const subjectsTableData = {
    data: vrsRp.map(getExpensesTypeTableMapper(subjectTypes, 'subj')),
    togglers: [
      {
        id: 'total_budget_subj',
        values: [
          'План регионального бюджета',
          formatNumber(subjTotalBudget),
          formatPercent(subjTotalBudget, subjTotalBudget)
        ]
      },
      {
        id: 'obligations_subj',
        values: [
          'Региональный бюджет (с федеральным бюджетом), принято бюджетных обязательств',
          formatNumber(subjObligations),
          formatPercent(subjObligations, subjTotalBudget)
        ]
      },
      {
        id: 'cash_expenses_subj',
        values: [
          'Кассовое исполнение',
          formatNumber(subjCashSum),
          formatPercent(subjCashSum, subjTotalBudget)
        ]
      }
    ]
  };

  return {
    chart: {
      total_budget: dividedTotalBudget,
      mbt_total_budget: subjTotalBudget,
      pies: [
        {
          data: obligationsPieData,
          total: dividedTotalBudget,
          sum: obligationsSum
        },
        {
          data: totalBudgetPieData,
          total: dividedTotalBudget,
          sum: totalBudgetSum
        },
        {
          data: federalCashExpensesPieData,
          total: dividedTotalBudget,
          sum: fedCashSum
        },
        {
          data: regionalTotalBudgetPieData,
          total: subjTotalBudget,
          sum: subjTotalBudget
        },
        {
          data: regionalCashExpensesPieData,
          total: subjTotalBudget,
          sum: subjCashSum
        },
        {
          data: regionalObligationsPieData,
          total: subjTotalBudget,
          sum: subjObligations
        }
      ]
    },
    federalTable: federalTableData,
    mbtTable: subjectsTableData
  };
}
