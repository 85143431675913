import React, { useMemo } from 'react';
import classNames from 'classnames';
import ReactSlick from 'react-slick';
import { formatValueUnit } from '../../../utils/formatNumber';
import { useWindowSize } from '../../../store/useWindowSize';
import { ChipRadio } from '../../../common/ui/Chip/ChipRadio';
import { ButtonComponent } from '../../../common/ui/Button/Button';
import { useSubsidiaryEntities } from '../../../store/useSubsidiaryEntities';
import { useStateTabs } from '../../../common/ui/Tab/TabRadio';
import { queryLoader } from '../../../common/QueryLoader';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import {
  getStatusByValue,
  getStatusTitle,
  RatingStatus,
  STATUS_TYPES
} from '../../../utils/constants';
import colors from '../../../colors.scss';
import s from './MainCards.module.scss';

const MainCard = ({ data, createLink }) => {
  const dcCompletionPercent = data.dc_completion?.dc_completion_percent;
  const cashExpensesPercent = data.finances?.cash_expenses_percent;
  const status = getStatusByValue(
    dcCompletionPercent || 0,
    STATUS_TYPES.COMPLETION
  );
  const label = getStatusTitle(status);

  const indicators = [
    {
      value: dcCompletionPercent,
      valueUnit: '%',
      title: 'Выполнение мероприятий',
      type: STATUS_TYPES.COMPLETION
    },
    {
      value: cashExpensesPercent,
      valueUnit: '%',
      title: 'Кассовое исполнение'
    }
  ];

  return (
    <ButtonComponent
      className={classNames(s.MainCard, status && s[`_${status}`])}
      to={createLink(data.id)}
    >
      <div className={s.MainCard__wrapper}>
        <div className={s.MainCard__label}>
          <p>{label}</p>
        </div>
        <div className={s.MainCard__title}>
          <p>{data.name_full || data.name}</p>
        </div>
        <div className={s.MainCard__indicators}>
          {indicators.map((ind, i) => {
            // const status = getStatusByValue(ind.value, ind.type);
            const barStyle = {
              width: `${+ind.value || 0}%`
              // background: getStatusColor(status)
            };

            return (
              <div key={i} className={s.MainCard__indicator}>
                <div className={s.MainCard__indicatorInfo}>
                  <div className={s.MainCard__indicatorTitle}>
                    <p>{ind.title}</p>
                  </div>
                  <div className={s.MainCard__indicatorValue}>
                    <span>{formatValueUnit(ind.value, ind.valueUnit)}</span>
                  </div>
                </div>
                <div
                  className={classNames(s.MainCard__indicatorBar, {
                    [s._grey]: !status
                  })}
                >
                  <div
                    className={s.MainCard__indicatorBarValue}
                    style={barStyle}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ButtonComponent>
  );
};

const MainCardsSlider = ({ data, createLink }) => {
  return (
    <div className={s.MainCards__slider}>
      <ReactSlick
        className={s.Slick}
        dots={false}
        arrows={false}
        slidesToShow={1}
        centerPadding={0}
        swipeToSlide
        focusOnSelect
        variableWidth={true}
        infinite={false}
        initialSlide={0}
      >
        {data.map((card, i) => (
          <MainCard key={i} data={card} createLink={createLink} />
        ))}
      </ReactSlick>
    </div>
  );
};

const MainCardsList = ({ data, createLink }) => {
  return (
    <div className={s.MainCards__list}>
      {data.map((card, i) => (
        <MainCard key={i} data={card} createLink={createLink} />
      ))}
    </div>
  );
};

const tabs = [
  { title: 'Все' },
  { title: 'Критично', color: colors.error, status: RatingStatus.ERROR },
  { title: 'Умеренно', color: colors.warn, status: RatingStatus.WARN },
  { title: 'Положительно', color: colors.success, status: RatingStatus.SUCCESS }
];

export const MainCards = ({
  organization,
  federalProject,
  createLink,
  year,
  yearMonth
}) => {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  const subsidiaryEntitiesQuery = useSubsidiaryEntities(
    organization && organization.id,
    year,
    yearMonth
  );
  const subsidiaryEntities = subsidiaryEntitiesQuery.state.data;

  const [tabSubsidiary, getTabSubsidiaryProps] = useStateTabs(
    tabs,
    (x, i) => i
  );

  const filteredEntities = useMemo(() => {
    if (!subsidiaryEntities) return null;
    return subsidiaryEntities
      .map((entity) => ({
        ...entity,
        status: getStatusByValue(entity.finances?.cash_expenses_percent)
      }))
      .filter(
        (s) =>
          !federalProject ||
          s.owner_roadmap?.result?.owner_task?.owner_id === federalProject.id
      );
  }, [subsidiaryEntities, federalProject]);

  const tabEntities = useMemo(() => {
    if (!filteredEntities) return null;
    const status = tabs[tabSubsidiary]?.status;
    if (!status) return filteredEntities;
    return filteredEntities.filter((s) => !status || s.status === status);
  }, [filteredEntities, tabSubsidiary]);

  // const subsidiaryEntitiesCount = subsidiaryEntities
  //   ? subsidiaryEntities.length
  //   : 0;

  // const [visibleCount, setVisibleCount] = useState(4);
  // const notVisibleCount =
  //   subsidiaryEntitiesCount - visibleCount >= 0
  //     ? subsidiaryEntitiesCount - visibleCount
  //     : 0;

  return (
    <div className={s.MainCards}>
      {queryLoader(subsidiaryEntitiesQuery) ||
        (!filteredEntities?.length ? (
          false
        ) : (
          <>
            <PageSubTitle
              className={s.MainCards__title}
              text={'Дорожные карты'}
            />
            <div className={s.MainCards__filters}>
              <div className={s.MainCards__filtersWrapper}>
                <div className={s.MainCards__filtersItems}>
                  {tabs.map((item, i) => (
                    <ChipRadio
                      key={i}
                      className={s.MainCards__filter}
                      title={item.title}
                      color={item.color}
                      {...getTabSubsidiaryProps(i)}
                    />
                  ))}
                </div>
              </div>
            </div>
            {isMobile ? (
              <MainCardsSlider data={tabEntities} createLink={createLink} />
            ) : (
              <MainCardsList data={tabEntities} createLink={createLink} />
            )}
          </>
        ))}
      {/*{!isMobile && subsidiaryEntitiesCount > visibleCount && (*/}
      {/*  <div className={s.MainCards__showAll}>*/}
      {/*    <Button*/}
      {/*      className={s.MainCards__showAllBtn}*/}
      {/*      onClick={() => setVisibleCount(visibleCount + 4)}*/}
      {/*    >*/}
      {/*      Показать еще ({notVisibleCount})*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};
