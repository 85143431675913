import React, { useMemo } from 'react';
import { usePreserveQuery } from '../../../store/usePreserveQuery';
import { useWindowSize } from '../../../store/useWindowSize';
import CardsView from './CardsView';
import { SubjectCard } from './SubjectCard';
import { useHistoryWithEnableScroll } from './ProjectCardsView';

export function SubjectCardsView({
  data,
  onCardClick,
  onChangeIndex,
  selectedIndex,
  selectedRegCode,
  ...props
}) {
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;
  const { createLink } = usePreserveQuery();
  const onClickCard = useHistoryWithEnableScroll();

  const dataItems = useMemo(() => {
    if (data && data.length > 0) {
      const items = data.map((item, i) => ({
        index: i,
        ...item
      }));

      if (isMobile) {
        items.reverse();
      }

      return items;
    } else return [];
  }, [data, isMobile]);

  const reverseIndex = (index) => dataItems.length - index - 1;

  const handleIndexChange = (newIndex) => {
    onChangeIndex(isMobile ? reverseIndex(newIndex) : newIndex);
  };

  const activeIndex = isMobile ? reverseIndex(selectedIndex) : selectedIndex;

  if (!data) return null;

  const activeItemColors =
    (data[selectedIndex] && data[selectedIndex].colors) || null;

  return (
    <CardsView
      selectedIndex={activeIndex}
      dotColors={activeItemColors}
      onChangeIndex={handleIndexChange}
      {...props}
    >
      {dataItems.map((item) => {
        const { code_nalog } = item.data.region || {};
        return (
          <SubjectCard
            key={item.index}
            item={item}
            active={item.index === selectedIndex}
            onClick={() => {
              if (item.index === selectedIndex && onCardClick) {
                onCardClick(item);
              } else if (item.index === selectedIndex && selectedRegCode) {
                const link = createLink(`/regions/${code_nalog}`);
                onClickCard(link);
              } else {
                handleIndexChange(item.index);
              }
            }}
          />
        );
      })}
    </CardsView>
  );
}
