import 'symbol-observable';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/fonts.scss';
import './assets/fonts/ruble/stylesheet.css';
import './index.scss';
import { ProstoreContext } from '@proscom/prostore-react';
import { Router } from 'react-router-dom';
import {
  ApolloClientsContext,
  ApolloClientsManager
} from '@proscom/prostore-apollo-react';
import Modal from 'react-modal';
import App from './App';
import {
  STORE_AUTH,
  STORE_CACHE,
  STORE_DOCUMENT_TOKEN,
  STORE_LOCATION,
  STORE_MAP,
  STORE_MOBILE_APP,
  STORE_PORTALS
} from './store/stores';

import { apiGraphqlUrl } from './config';

import { MapStore } from './store/MapStore';
import { LocalStorageStore } from './store/LocalStorageStore';
import { AuthStore } from './store/AuthStore';
import {
  createBrowserClientWithAuth,
  createSimpleBrowserClient
} from './graphql/client';
import { createAppHistory } from './appHistory';
import { PortalsStore } from './store/PortalsStore';
import { LocationStore } from './store/LocationStore';

import { MobileAppStore } from './store/MobileAppStore';
import { DocumentTokenStore } from './store/DocumentTokenStore';
import { CacheStore } from './store/CacheStore';
import { YandexMetrika } from './YandexMetrika';

const appHistory = createAppHistory();

const localStorageStore = new LocalStorageStore(localStorage);
localStorageStore.registerListener();

const apolloClient = createSimpleBrowserClient({ uri: apiGraphqlUrl });

const authStore = new AuthStore({
  localStorageStore,
  client: apolloClient
});
authStore.subscribe();

const clientWithAuth = createBrowserClientWithAuth({
  uri: apiGraphqlUrl,
  authStore
});

const mapStore = new MapStore();
const portalsStore = new PortalsStore();
const locationStore = new LocationStore({
  history: appHistory
});
const mobileAppStore = new MobileAppStore();
const tokenStore = new DocumentTokenStore({ client: clientWithAuth });
const cacheStore = new CacheStore({ client: clientWithAuth });

const stores = {
  [STORE_AUTH]: authStore,
  [STORE_MAP]: mapStore,
  [STORE_PORTALS]: portalsStore,
  [STORE_LOCATION]: locationStore,
  [STORE_MOBILE_APP]: mobileAppStore,
  [STORE_DOCUMENT_TOKEN]: tokenStore,
  [STORE_CACHE]: cacheStore
};

const apolloContext = new ApolloClientsManager({
  default: clientWithAuth,
  simple: apolloClient
});

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Router history={appHistory}>
    <ProstoreContext.Provider value={stores}>
      <ApolloClientsContext.Provider value={apolloContext}>
        <YandexMetrika />
        <App />
      </ApolloClientsContext.Provider>
    </ProstoreContext.Provider>
  </Router>,
  rootElement
);

Modal.setAppElement(rootElement);
