import React, { useMemo, useState } from 'react';
import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { useWindowSize } from '../../store/useWindowSize';
import { QUERY_GET_RESULT_REGIONAL } from '../../graphql/queries/federalProjects';
import { Result } from '../../data/result';
import { reorderRoadmapEvents } from '../../data/roadmapEvent';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { useFederalProjects } from '../../store/useFederalProjects';
import { RightPaneLayout } from '../../common/GridLayout/RightPaneLayout';
import { TopBar } from '../../common/TopBar/TopBar';
import { TopBarProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { RightMenuPopup } from '../../common/ui/RightMenuPopup/RightMenuPopup';
import { FlyingButton } from '../../common/FlyingBox/FlyingBox';
import { ReactComponent as ReportIcon } from '../../assets/Icons/Document.svg';
import { queryLoader } from '../../common/QueryLoader';
import { ControlPointsList } from '../../common/EventsList/ControlPointsList';
import { useRegion } from '../../store/useRegions';
import { useActualityDateContext } from '../../store/useActualityDateContext';
import { useRoadmapEvents } from '../../store/useRoadmapEvents';
import { useBudgetChartRpBase } from '../IndicatorsPage/useBudgetChartRp';
import { RegionalRoadmapEvents } from './RoadmapEvents/RoadmapEvents';
import { MainChart } from './MainCharts/MainCharts';
import { Title } from './Title.js/Title';
import { RegionalResultInfoView } from './ResultsInfoView/ResultInfoView';
import s from './ResultPage.module.scss';

const resultOptions = {
  query: QUERY_GET_RESULT_REGIONAL,
  mapData: (data) => {
    const result = data.resultRegional;
    const federalResult = data.result?.result_federal || {};
    return {
      ...result,
      description: federalResult.description
    };
  },
  skipQuery: skipIfNull(null)
};

const useResultQuery = (id, subsidiaryEntityPlanFilter) => {
  return useGraphqlQuery({
    queryOptions: resultOptions,
    variables: id
      ? {
          filter: { ids: [id] },
          subsidiaryEntityPlanFilter: {
            active: true,
            ...subsidiaryEntityPlanFilter
          }
        }
      : null
  });
};

function RegionalResultPage({ match, history }) {
  const { codeNalog, resultId } = match.params;
  const [query, changeQuery] = useUrlQuery();
  const preserved = usePreserveQueryFast(query);
  const { year, yearMonth } = useQueryPeriodFast(query, changeQuery);
  const grbs = query.grbs;
  const actualityDate = useActualityDateContext();
  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const [popupActive, setPopupActive] = useState(false);
  const [rightOpened, setRightOpened] = useState(false);

  const onPopupOpen = () => {
    setPopupActive(true);
  };
  const onPopupClose = () => {
    setPopupActive(false);
  };

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  const federalProjectsQuery = useFederalProjects();
  const regionQuery = useRegion(codeNalog);
  const regionResultQuery = useResultQuery(resultId);

  const federalProjects = federalProjectsQuery.state.data;
  const region = regionQuery.state.data;
  const regionResult = regionResultQuery.state.data;

  const federalResult = regionResult?.result_federal;
  const federalResultId = federalResult?.id;
  const roadmapId = federalResult?.roadmap?.id;
  const resultFPId = federalResult?.owner_task?.owner_id;

  const projects = federalProjects?.filter((item) => item.id === resultFPId);

  const fpCode = projects?.[0]?.project_code;

  const linkQuery = {};

  if (fpCode) {
    linkQuery.fp = fpCode;
  }

  const characteristic = federalResult?.characteristics?.[0];

  const eventsQuery = useRoadmapEvents(roadmapId, year, codeNalog);

  const budgetsFilter = useMemo(
    () =>
      region && federalResultId
        ? {
            regionIds: [region.id],
            resultIds: [federalResultId],
            federalProjectCodes: fpCode ? [fpCode + ''] : undefined,
            grbsCodes: grbs && grbs !== 'all' ? [grbs] : undefined,
            expensesDirectionIds: federalResult?.expenses_direction?.id
              ? federalResult.expenses_direction.id
              : undefined
          }
        : null,
    [region, federalResultId, fpCode, grbs, federalResult]
  );

  const budgetCharts = useBudgetChartRpBase({
    yearMonth,
    filter: budgetsFilter
  });

  return (
    <RightPaneLayout
      topBar={
        <TopBar>
          <TopBarProjects projects={projects} noCommon={true} />
        </TopBar>
      }
      right={
        queryLoader(regionResultQuery) || (
          <RegionalResultInfoView
            resultId={federalResultId}
            year={year}
            yearMonth={yearMonth}
            regionCodeNalog={codeNalog}
            grbsCodes={
              query.grbs && query.grbs !== 'all' ? [query.grbs] : undefined
            }
            budgetCharts={budgetCharts}
          />
        )
      }
      popup={
        federalResult?.description && (
          <RightMenuPopup isActive={popupActive} onClose={onPopupClose}>
            <div className={s.Popup__descriptionTitle}>Описание результата</div>
            {federalResult.description.split('\n').map((item, index) => (
              <p key={index}>{item}</p>
            ))}
          </RightMenuPopup>
        )
      }
      extra={
        isMobile && (
          <FlyingButton
            className={s.Results__flyingButton}
            onClick={onRightOpen}
          >
            <ReportIcon />
            <span>Финансирование</span>
          </FlyingButton>
        )
      }
      onRightClose={onRightClose}
      rightOpened={rightOpened}
    >
      <Title
        title={Result.getName(regionResult)}
        description={federalResult?.description}
        onGoBack={() =>
          history.goBack(
            preserved.createLink('/indicators?tab=results', linkQuery)
          )
        }
        onPopupOpen={onPopupOpen}
        currentRegion={region ? region.name : null}
      />
      {queryLoader(regionResultQuery) || (
        <>
          <MainChart
            resultData={regionResult}
            year={year}
            characteristic={characteristic}
            actualityDate={actualityDate}
          />
          {roadmapId ? (
            <div className={s.ControlPoints}>
              <RegionalRoadmapEvents
                currentRegion={region}
                eventsQuery={eventsQuery}
                regionCode={codeNalog}
              />
            </div>
          ) : (
            <div className={s.ControlPoints}>
              <div className={s.SubTitle}>Контрольные точки</div>
              {federalResult?.controlPoints ? (
                <ControlPointsList
                  className={s.TaskStatus}
                  controlPoints={federalResult.controlPoints}
                />
              ) : (
                <div className={s.RoadmapPage__eventListPlaceholder}>
                  <p>В текущем году контрольных точек нет</p>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </RightPaneLayout>
  );
}

export default RegionalResultPage;
