import React from 'react';
import PropTypes from 'prop-types';
import { arc, color, interpolateHcl } from 'd3';
import { degreeToRad, polarToCartesian } from '../../utils/math';
import { pie } from '../../utils/pie';
import { createSVGGradient } from '../../utils/colors';

function PlanetPieChart({
  value,
  total,
  size,
  ringCount,
  rotateAngle,
  ringDistance,
  valueColor,
  totalColor,
  moon,
  chartInnerRadius,
  chartOuterRadius,
  linearGradientLibrary,
  moonColor,
  moonSize,
  ringsStrokeWidth,
  shadow,
  ...props
}) {
  const pieGenerator = pie();

  // вычисляем необходимый угол для того, чтобы центрировать элемент
  let halfChartAngle = (value / total) * (360 / 2);

  pieGenerator.rotateAngle(-halfChartAngle).totalCount(total);

  const chartData = pieGenerator([value]);

  const arcGenerator = arc()
    .innerRadius(0)
    .outerRadius(chartOuterRadius);

  const chartPath = arcGenerator({
    startAngle: chartData[0].startAngle,
    endAngle: chartData[0].endAngle
  });

  arcGenerator.outerRadius(chartInnerRadius);

  const fullCircle = {
    startAngle: 0,
    endAngle: 2 * Math.PI
  };

  const circlePath = arcGenerator(fullCircle);

  const endRingColor = color(valueColor);
  endRingColor.opacity = 0;
  const ringColorInterpolator = interpolateHcl(
    valueColor,
    endRingColor.formatRgb()
  );

  const ringRadius = (i) =>
    chartOuterRadius + ringDistance * i - ringDistance / 2;

  const ringsArray = [];
  for (let i = 1; i <= ringCount; i++) {
    arcGenerator.outerRadius(ringRadius(i));
    ringsArray.push({
      id: `ring_${i}`,
      path: arcGenerator(fullCircle),
      color: ringColorInterpolator((i - 1) / ringCount)
    });
  }

  const moonColorParsed = color(moonColor);
  moonColorParsed.opacity = 0.1;

  const moonPosition = polarToCartesian(
    ringRadius(ringCount),
    degreeToRad(-90)
  );

  const linearGradient = createSVGGradient(linearGradientLibrary);

  return (
    <>
      <svg {...props} viewBox={`${-size / 2} ${-size / 2} ${size} ${size}`}>
        <defs>{linearGradient}</defs>
        <g style={{ transform: `rotate(${rotateAngle}deg)`, transition: '2s' }}>
          {/* background circle */}
          <path d={circlePath} strokeWidth={0} fill={totalColor} />

          {/* chart */}
          <path
            d={chartPath}
            strokeWidth={0}
            fill={
              linearGradientLibrary
                ? `url(#${linearGradientLibrary[0].id})`
                : valueColor
            }
          />

          {/* rings */}
          {ringsArray.map((item, index) => (
            <path
              key={item.id}
              d={item.path}
              strokeWidth={ringsStrokeWidth}
              stroke={item.color}
              fill="none"
            />
          ))}
          {moon && ringCount > 0 ? (
            <>
              <circle
                cx={moonPosition.x}
                cy={moonPosition.y}
                r={moonSize * 2}
                fill={moonColorParsed + ''}
              />
              <circle
                cx={moonPosition.x}
                cy={moonPosition.y}
                r={moonSize}
                fill={moonColor}
              />
            </>
          ) : null}
        </g>
      </svg>
      {shadow}
    </>
  );
}

PlanetPieChart.propTypes = {
  value: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  size: PropTypes.number,
  chartInnerRadius: PropTypes.number,
  chartOuterRadius: PropTypes.number,
  ringCount: PropTypes.number,
  rotateAngle: PropTypes.number,
  ringDistance: PropTypes.number,
  valueColor: PropTypes.string,
  totalColor: PropTypes.string,
  moon: PropTypes.bool,
  moonColor: PropTypes.string,
  moonSize: PropTypes.number,
  ringsStrokeWidth: PropTypes.number
};

PlanetPieChart.defaultProps = {
  value: 55,
  total: 100,
  size: 100,
  chartInnerRadius: 90,
  chartOuterRadius: 100,
  ringCount: 2,
  rotateAngle: 0,
  ringDistance: 16,
  valueColor: 'blue',
  totalColor: 'dark-blue',
  moon: false,
  moonColor: 'red',
  moonSize: 1,
  ringsStrokeWidth: 0.5
};

export default PlanetPieChart;
