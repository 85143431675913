import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { ButtonComponent } from '../Button/Button';
import { CardSmallLayout } from './CardSmallLayout';
import s from './CardSmallLayout.module.scss';

export function CardTitle({
  description,
  to,
  number,
  loading,
  additionalData,
  ...props
}) {
  const [query, changeQuery] = useUrlQuery();
  const isActive = query.roadmap === additionalData.id;

  return (
    <ButtonComponent
      className={s.CardTitle}
      {...props}
      onClick={() => {
        if (isActive) {
          changeQuery({ roadmap: undefined }, true);
        } else {
          changeQuery({ roadmap: additionalData.id }, true);
        }
      }}
    >
      <CardSmallLayout
        className={classNames(s.CardTitle__layout, { [s._active]: isActive })}
        loading={loading}
        to={to}
      >
        <div className={s.CardTitle__number}>{number}</div>
        <div className={s.CardTitle__title}>{description}</div>
      </CardSmallLayout>
    </ButtonComponent>
  );
}

CardTitle.propTypes = {
  number: PropTypes.number,
  description: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string
};

CardTitle.defaultProps = {
  number: 0
};
