import React from 'react';
import { RatingStatus } from '../../../utils/constants';
import { ReactComponent as CheckIcon } from '../../../assets/Icons/Check.svg';
import { ReactComponent as ReloadIcon } from '../../../assets/Icons/Reload.svg';
import { ReactComponent as CloseIcon } from '../../../assets/Icons/Close.svg';
import { ChipText } from './ChipText';

const statusIcons = {
  [RatingStatus.SUCCESS]: CheckIcon,
  [RatingStatus.WARN]: ReloadIcon,
  [RatingStatus.ERROR]: CloseIcon
};

export function StatusChipText({ status, ...props }) {
  return <ChipText status={status} icon={statusIcons[status]} {...props} />;
}
