import gql from 'graphql-tag';

export const FRAGMENT_GOAL = gql`
  fragment Goal on GoalType {
    id
    code
    name_full
    indicators {
      id
      code
      name_full
      unit {
        id
        name
        name_local_short
        name_local
      }
      values {
        indicator_id
        value_number
        period {
          id
          name
          start
          end
        }
      }
      fact_values {
        indicator_id
        value_number
        period {
          id
          name
          start
          end
        }
      }
    }
  }
`;

export const QUERY_GET_GENERAL_INDICATORS = gql`
  query generalIndicators {
    goals(general: true) {
      ...Goal
    }
    npoTasks {
      id
      name_full: description
      milestones {
        id
        name_full: description
        unit {
          id
          name
        }
        values: plan_milestone_tasks {
          value_number: value_n
          period {
            id
            name
            start
            end
          }
        }
        fact_values: fact_milestone_tasks {
          value_number: value_n
          period {
            id
            name
            start
            end
          }
        }
      }
    }
  }
  ${FRAGMENT_GOAL}
`;

export const QUERY_GET_FP_INDICATORS = gql`
  query fpIndicators($federalProjectId: ID!) {
    goals(federal_project_ids: [$federalProjectId]) {
      id
      code
      name_full
      indicators(project_ids: [$federalProjectId]) {
        id
        code
        name_full
        unit {
          name
          name_local_short
          name_local
        }
        values(project_ids: [$federalProjectId]) {
          indicator_id
          value_number
          period {
            id
            name
            start
            end
          }
        }
        fact_values(project_ids: [$federalProjectId]) {
          indicator_id
          value_number
          period {
            id
            name
            start
            end
          }
        }
      }
    }
  }
`;

export const QUERY_GET_RP_INDICATORS = gql`
  query rpIndicators($federalProjectId: ID!, $regionalProjectId: ID!) {
    goals(federal_project_ids: [$federalProjectId]) {
      id
      code
      name_full
      indicators(project_ids: [$regionalProjectId]) {
        id
        code
        name_full
        unit {
          id
          name
          name_local_short
          name_local
        }
        values(project_ids: [$regionalProjectId]) {
          indicator_id
          value_number
          period {
            id
            name
            start
            end
          }
        }
        fact_values(project_ids: [$regionalProjectId]) {
          indicator_id
          value_number
          period {
            id
            name
            start
            end
          }
        }
      }
    }
  }
`;

export const QUERY_GET_GENERAL_INDICATORS_AP = gql`
  query indicators_ap {
    indicators_ap {
      id
      code
      name_full
      unit {
        id
        name
        name_local_short
        name_local
      }
      values {
        indicator_id
        value_number
        period {
          id
          name
          start
          end
        }
      }
      fact_values {
        indicator_id
        value_number
        period {
          id
          name
          start
          end
        }
      }
    }
  }
`;

export const QUERY_GET_INDICATORS_CITIC = gql`
  query indicators_citis($federalProjectId: ID!) {
    indicators_citis(federal_project_ids: [$federalProjectId]) {
      id
      code
      name_full
      unit {
        id
        name
        name_local_short
        name_local
      }
      values {
        indicator_id
        value_number
        period {
          id
          name
          start
          end
        }
      }
      fact_values: citis_values {
        indicator_id
        value_number
        period {
          id
          name
          start
          end
        }
      }
    }
  }
`;

export const QUERY_GET_ROADMAP_INDICATORS = gql`
  query getRoadmapIndicators($filter: SubsidiaryEntityRoadmapIndicatorsFilter) {
    seRoadmapIndicatorsSum(filter: $filter, groupBy: [indicator]) {
      indicator_id
      value_from_tender_documentation
      value_fact
      indicator {
        id
        name
        name_full
        plan_roadmap_indicator {
          min_numeric_value_per_year
          min_numeric_value_per_year_formula
        }
      }
    }
  }
`;
