import { skipIfNull } from '@proscom/prostore';
import {
  QUERY_SEARCH_RESULT_REGIONALS,
  QUERY_SEARCH_RESULTS
} from '../../../graphql/queries/results';
import { useSearchQuery } from './_util';

const searchResultsQueryOptions = {
  query: QUERY_SEARCH_RESULTS,
  mapData: (result) => result.resultFederals,
  skipQuery: skipIfNull(null)
};

const searchResultRegionalsQueryOptions = {
  query: QUERY_SEARCH_RESULT_REGIONALS,
  mapData: (result) => result.resultRegionals,
  skipQuery: skipIfNull(null)
};

export function useSearchResults(search, filter, showEmpty = false) {
  const searchQueryOptions = filter?.region_code_nalogs
    ? searchResultRegionalsQueryOptions
    : searchResultsQueryOptions;

  return useSearchQuery(searchQueryOptions, search, filter, showEmpty);
}
