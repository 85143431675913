import React, { useEffect } from 'react';
import { NBSP } from '../../../utils/utf';
import {
  useResultBudgetChart,
  useResultFinanceQuery
} from '../../ResultPage/ResultsInfoView/useResultBudgetChart';
import { ChartViewChart } from '../../../common/ChartView/ChartView';
import { queryLoader } from '../../../common/QueryLoader';
import { ReportChartView } from './ReportChartView';

function InfographicChartsResultComp({ data, year, month, budgetQuery }) {
  const { budgetCharts, kassCharts, grants, budgetData } = useResultBudgetChart(
    data
  );

  return (
    <div>
      <ReportChartView
        title={`Бюджетные ассигнования ФБ${NBSP}и${NBSP}принятие${NBSP}БО`}
        year={year}
        month={month}
      >
        {queryLoader(budgetQuery) ||
          (budgetCharts && (
            <ChartViewChart
              data={budgetCharts}
              legend
              width={482}
              height={192}
            />
          ))}
      </ReportChartView>
      <ReportChartView title={`Субсидии и гранты`} year={year} month={month}>
        {queryLoader(budgetQuery) ||
          (grants && (
            <ChartViewChart data={grants} legend width={482} height={192} />
          ))}
      </ReportChartView>
      <ReportChartView
        title={`Кассовое исполнение по видам бюджетов в разрезе ВР`}
        year={year}
        month={month}
      >
        {queryLoader(budgetQuery) ||
          (kassCharts && (
            <ChartViewChart data={kassCharts} legend width={482} height={192} />
          ))}
      </ReportChartView>
    </div>
  );
}

export function InfographicChartsResult(props) {
  const { regions, results, year, yearMonth, onLoad = () => {} } = props;

  const query = useResultFinanceQuery({
    resultIds: results.map((r) => r.id),
    regionIds: regions && regions.length > 0 && regions.map((r) => r.id),
    year: +year,
    yearMonth
  });

  useEffect(() => {
    if (!query.check.spinner) {
      onLoad(true);
    }
  }, [query.check.spinner, onLoad]);

  return (
    <InfographicChartsResultComp
      data={query.state.data}
      budgetQuery={query}
      {...props}
    />
  );
}
