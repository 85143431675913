import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { defaultDataIdFromObject, InMemoryCache } from 'apollo-cache-inmemory';
import { createStringifyErrorMessagesLink } from './links/stringifyErrorMessages';
import { createRetryOnNetworkErrorLink } from './links/retryOnNetworkError';
import { createWaitForTokenRefreshLink } from './links/waitForTokenRefresh';
import { createCheckAuthenticationErrorLink } from './links/checkAuthenticationError';
import { createSetAccessTokenContextLink } from './links/setAccessTokenContext';

export function createApolloCache({ restoreCache } = {}) {
  const cache = new InMemoryCache({
    dataIdFromObject(value) {
      const { __typename } = value;
      if (__typename === 'OrganizationResponsiblePersonType') {
        return `${__typename}-${value.organization_id}-${value.responsibility_type_id}`;
      }
      return defaultDataIdFromObject(value);
    }
  });

  if (restoreCache) {
    cache.restore(restoreCache);
  }

  return cache;
}

export function createSimpleBrowserClient({ uri }) {
  const stringifyErrorMessages = createStringifyErrorMessagesLink();

  const sendHttpRequest = createHttpLink({
    uri
  });

  return new ApolloClient({
    link: ApolloLink.from([stringifyErrorMessages, sendHttpRequest]),
    cache: createApolloCache()
  });
}

export function createBrowserClientWithAuth({ uri, authStore }) {
  const stringifyErrorMessages = createStringifyErrorMessagesLink();
  const retryOnNetworkError = createRetryOnNetworkErrorLink();
  const waitForTokenRefresh = createWaitForTokenRefreshLink(authStore);
  const checkAuthenticationError = createCheckAuthenticationErrorLink(
    authStore
  );
  const setAccessTokenContext = createSetAccessTokenContextLink(authStore);
  const sendHttpRequest = createHttpLink({
    uri
  });

  return new ApolloClient({
    link: ApolloLink.from([
      stringifyErrorMessages,
      retryOnNetworkError,
      waitForTokenRefresh,
      checkAuthenticationError,
      setAccessTokenContext,
      sendHttpRequest
    ]),
    cache: createApolloCache()
  });
}
