import { Redirect } from 'react-router';
import React from 'react';
import { Routes } from '../common/Routes';
import { User } from '../data/user';
import {
  ActualityDateLayout,
  DefaultLayout,
  EmptyLayout,
  LoginLayout
} from './layouts';
import IndexPage from './IndexPage/IndexPage';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import DistrictPage from './DistrictPage/DistrictPage';
import SubjectPage from './SubjectPage/SubjectPage';
import ObjectPage from './ObjectPage/ObjectPage';
import SearchPage from './SearchPage/SearchPage';
import MapPage from './MapPage/MapPage';
import LoginPage from './LoginPage/LoginPage';
import LogoutPage from './LogoutPage/LogoutPage';
import RatingsPage from './RatingsPage/RatingsPage';
import ReportsPage from './ReportsPage/ReportsPage';
import HasNoAccessPage from './HasNoAccessPage/HasNoAccessPage';
import IndicatorsPage from './IndicatorsPage/IndicatorsPage';
import { RequireAuth, RequireAuthCustom } from './RequireAuth';
import ResultPage from './ResultPage/ResultPage';
import ReportPage from './ReportPage/ReportPage';
import CalendarPage from './CalendarPage/CalendarPage';
import SubsidiaryEntityPage from './SubsidiaryEntityPage/SubsidiaryEntityPage';
import RoadmapPage from './ResultPage/RoadmapPage';
import RegionsIndicatorPage from './RegionsIndicatorPage/RegionsIndicatorPage';
import InfographicsReportPage from './InfographicsReportPage/InfographicsReportPage';
import RegionalResultPage from './ResultPage/RegionalResultPage';

const userHasRole = (roles) => (auth) => {
  const user = User.getUser(auth);
  if (!user) return false;
  return roles.includes(user.role);
};

const userFederalAdmin = userHasRole([
  User.roles.ADMIN,
  User.roles.FEDERAL_ADMIN
]);

const routes = [
  {
    path: '/',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(
        RequireAuthCustom(IndexPage, (auth, props) => {
          return User.canAccessMainPage(User.getUser(auth)) ? (
            false
          ) : (
            <Redirect to={'/map'} />
          );
        })
      ),
      'Главная'
    )
  },
  {
    path: '/search',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(SearchPage)),
      'Поиск'
    )
  },
  {
    path: '/indicators',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(IndicatorsPage, userFederalAdmin)),
      'Показатели'
    )
  },
  {
    path: '/map',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(MapPage)),
      'Российская Федерация'
    )
  },
  {
    path: '/districts/:code',
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(DistrictPage)),
      'Округ'
    )
  },
  {
    path: '/regions/:codeNalog',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(SubjectPage)),
      'Регион'
    )
  },
  {
    path: '/regions/:codeNalog/results/:resultId',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(RegionalResultPage)),
      'Результат'
    )
  },
  {
    path: '/organizations/:id',
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(ObjectPage)),
      'Организация'
    ),
    exact: true
  },
  {
    path: '/organizations/:organizationId/entities/:subsidiaryEntityId',
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(SubsidiaryEntityPage)),
      'Субсидиарная сущность'
    ),
    exact: true
  },
  {
    path: '/entities/:subsidiaryEntityId',
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(SubsidiaryEntityPage)),
      'Субсидиарная сущность'
    ),
    exact: true
  },
  {
    path: '/indicators/ratings',
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(RatingsPage, userFederalAdmin)),
      'Рейтинг субъектов'
    )
  },
  {
    path: '/indicators/regions',
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(RegionsIndicatorPage, userFederalAdmin)),
      'Региональные показатели'
    )
  },
  {
    path: '/indicators/results/:resultId',
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(ResultPage, userFederalAdmin)),
      'Результат'
    )
  },
  {
    path: '/indicators/roadmap/:roadmapId',
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(RoadmapPage)),
      'Дорожная карта'
    )
  },
  {
    path: '/reports',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(ReportsPage, userFederalAdmin)),
      'Отчеты'
    )
  },
  {
    path: '/reports/:reportId',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(ReportPage, userFederalAdmin)),
      'Отчет'
    )
  },
  {
    path: '/calendar',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(CalendarPage)),
      'Календарь'
    )
  },
  {
    path: '/reports/preview/infographics',
    exact: true,
    component: EmptyLayout(RequireAuth(InfographicsReportPage), 'Отчет')
  },
  {
    path: '/login',
    exact: true,
    component: LoginLayout(LoginPage, 'Вход')
  },
  {
    path: '/logout',
    exact: true,
    component: DefaultLayout(
      ActualityDateLayout(RequireAuth(LogoutPage)),
      'Выход'
    )
  },
  {
    path: '/no-access',
    exact: true,
    component: HasNoAccessPage
  },
  {
    component: NotFoundPage
  }
];

const AppRoutes = Routes(routes);

export default AppRoutes;
