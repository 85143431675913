import { useContextStore } from '@proscom/prostore-react';
import { useCallback } from 'react';
import { STORE_DOCUMENT_TOKEN } from '../store/stores';
import { createDownloadLink } from '../store/usePreserveQuery';
import { applyUrlAuth } from './url';
import { useCookieLoader } from './useCookieLoader';

export function useReportAuthDownload({
  cookieLoader,
  onDownloadFinish,
  onClick,
  type,
  url
}) {
  const tokensStore = useContextStore(STORE_DOCUMENT_TOKEN);
  const { downloadId, handleClick, isLoading } = useCookieLoader(
    cookieLoader,
    onDownloadFinish,
    onClick
  );

  const handleSubmit = useCallback(() => {
    tokensStore.reportFilesToken(type).then((auth) => {
      const link = applyUrlAuth(createDownloadLink(url, downloadId), auth);
      window.open(link);
      handleClick();
    });
  }, [downloadId, handleClick, url, tokensStore, type]);

  return [downloadId, handleSubmit, isLoading];
}
