import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import { Select } from '../../../common/ui/Select/Select';
import { ProgressBar } from '../../../common/ProgressBar/ProgressBar';
import { INDICATOR_DC_COMPLETION, indicators } from '../../../utils/indicators';
import { ActualityDate } from '../../../common/NavBar/ActualityDate';
import { useRegionalFinance } from '../../../store/useRegionalFinance';
import { useQueryPeriod } from '../../../utils/useQueryPeriod';
import { STATUS_TYPES } from '../../../utils/constants';
import { formatDateIso } from '../../../utils/date';
import s from './RegionProjects.module.scss';

export const RegionProjects = ({
  projects,
  regionId,
  regionCode,
  selectedFpCode,
  federalDate
}) => {
  const [dataType, setDataType] = useState(indicators[1]);
  const { yearMonth, year } = useQueryPeriod();
  const now = new Date();

  const filterFinance = useMemo(
    () => ({
      regionIds: regionId ? [regionId] : undefined,
      yearMonth,
      dcCompletionFilter: {
        year,
        region_ids: regionId ? [regionId] : undefined,
        federal_project_codes: selectedFpCode
          ? [selectedFpCode + '']
          : undefined,
        date: formatDateIso(new Date())
      }
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [regionId, selectedFpCode, year, yearMonth]
  );
  const [data, actualityDate] = useRegionalFinance(projects, filterFinance);
  const currentData = data[dataType.value] || projects;

  const currentActualityDate = actualityDate[dataType.value];

  return (
    <div className={s.RegionProjects}>
      <PageSubTitle
        className={s.RegionProjects__title}
        text={'Региональные проекты'}
      />
      <div className={s.RegionProjects__filters}>
        <div className={s.RegionProjects__filter}>
          <Select
            height={Select.SMALL}
            className={s.RegionProjects__filterSelect}
            onChange={setDataType}
            values={indicators}
            value={dataType}
          />
        </div>
        <ActualityDate
          className={s.RegionProjects__actualityDate}
          date={currentActualityDate}
        />
      </div>
      <div className={s.RegionProjects__charts}>
        {currentData
          .filter((item) => item.federal_project)
          .map((item, i) => (
            <Link
              to={`/indicators?fp=${item.federal_project.project_code}&region_code=${regionCode}`}
              key={i}
              className={s.RegionProjects__chart}
            >
              <ProgressBar
                value={item.value}
                text={item.federal_project.name_full}
                statusType={
                  dataType.value === INDICATOR_DC_COMPLETION
                    ? STATUS_TYPES.COMPLETION
                    : STATUS_TYPES.RATING
                }
              />
            </Link>
          ))}
      </div>
    </div>
  );
};
