import React from 'react';
import classNames from 'classnames';
import { Button } from '../ui/Button/Button';
import s from './FlyingBox.module.scss';

export function FlyingBox({ className, children }) {
  return (
    <div className={classNames(s.FlyingBox, className)}>
      <div className={s.FlyingBox__content}>
        {React.Children.map(children, (item, i) => (
          <div key={i} className={s.FlyingBox__item}>
            {item}
          </div>
        ))}
      </div>
    </div>
  );
}

export function FlyingButton({ className, boxClassName, children, ...props }) {
  return (
    <FlyingBox className={boxClassName}>
      <Button {...props} className={classNames(s.FlyingButton, className)}>
        {children}
      </Button>
    </FlyingBox>
  );
}
