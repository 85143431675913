import React, { Fragment } from 'react';
import ErrorPage from '../../common/ErrorPage';
import { ReactHelmet } from '../../common/ReactHelmet';

export default function HasNoAccessPage(props) {
  return (
    <Fragment>
      <ReactHelmet title={'Нет доступа'} />
      <ErrorPage
        text="У вас нет доступа к этой странице"
        description="Ошибка доступа"
      />
    </Fragment>
  );
}
