import React from 'react';
import { useStore } from '@proscom/prostore-react';
import { Redirect } from 'react-router';
import { STORE_AUTH } from '../store/stores';
import HasNoAccessPage from './HasNoAccessPage/HasNoAccessPage';

export function RequireAuth(Component, checkAccess = false) {
  return function RequireAuthComponent(props) {
    const [auth, authStore] = useStore(STORE_AUTH);

    if (!auth.loaded) return false;
    if (!authStore.isLoggedIn()) {
      return <Redirect to={'/login'} />;
    }
    if (checkAccess && !checkAccess(auth)) {
      return <HasNoAccessPage />;
    }

    return (Component && <Component {...props} />) || false;
  };
}

export function RequireAuthCustom(Component, renderError) {
  return function RequireAuthComponent(props) {
    const [auth, authStore] = useStore(STORE_AUTH);

    if (!auth.loaded) return false;
    if (!authStore.isLoggedIn()) {
      return <Redirect to={'/login'} />;
    }
    if (renderError) {
      const result = renderError(auth, props);
      if (result) {
        return result || false;
      }
    }

    return (Component && <Component {...props} />) || false;
  };
}
