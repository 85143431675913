import React, { useState } from 'react';
import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { parseISO } from 'date-fns';
import { QUERY_GET_REGION_STATS } from '../../../graphql/queries/region';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { HorizontalChart } from '../../../common/charts/HorizontalChart/HorizontalChart';
import { Select } from '../../../common/ui/Select/Select';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import { IndicatorsLegend } from '../IndicatorsLegend';
import { Indicator } from '../Indicator';
import { ResizeContainer } from '../../../common/ResizeContainer';
import {
  INDICATOR_TYPE_P,
  indicators,
  indicatorTypes,
  indicatorTypesMap
} from './indicatorsInfo';
import colors from '../../../colors.scss';
import s from './AdditionalIndicators.module.scss';

const regionStatsQueryOptions = {
  query: QUERY_GET_REGION_STATS,
  mapData: (result) => result.regionStats,
  skipQuery: skipIfNull(null)
};

export const AdditionalIndicators = ({ region }) => {
  const [type, setType] = useState(INDICATOR_TYPE_P);

  const query = useGraphqlQueryVars({
    queryOptions: regionStatsQueryOptions,
    variableCreator: [
      () => ({
        regionId: region.id
      }),
      [region.id]
    ]
  });

  if (query.check.spinner) {
    return <CircleProgressIndicator />;
  }

  const stats = query.state.data;

  const statsSorted = [...stats]
    .sort((a, b) => (a.period.start > b.period.start ? 1 : -1))
    .filter((item) => {
      // Удаление всех показателей после 2019 (включая 2019)
      return !(parseISO(item.period.start) >= new Date(2019, 0, 1));
    });

  const legends = statsSorted.map((item) => item.period.name);

  const data = indicators[type].map(({ title, field }) => {
    return {
      title,
      values: statsSorted
        .map((item, index) => {
          if (field === 'count_students_shift3') {
            return {
              id: item.period.id,
              legend: item.period.name,
              name: item.period.name,
              titleIfValueZero: 'Отсутствуют',
              value: item[field],
              color: legends[index].color
            };
          }

          return {
            id: item.period.id,
            legend: item.period.name,
            name: item.period.name,
            value: item[field],
            color: legends[index].color
          };
        })
        .filter((item) => item !== null)
    };
  });

  return (
    <div className={s.Indicators}>
      <PageSubTitle
        className={s.Indicators__title}
        text={'Дополнительные показатели'}
      />
      <div className={s.Indicators__filter}>
        <Select
          height={Select.SMALL}
          className={s.Indicators__filterSelect}
          value={indicatorTypesMap[type]}
          values={indicatorTypes}
          onChange={(v) => setType(v.value)}
        />
      </div>
      <IndicatorsLegend color={colors.chartPurple} legends={legends} />
      <div className={s.Indicators__list}>
        <ResizeContainer>
          {({ width }) =>
            data.map((item, i) => (
              <Indicator key={i} title={item.title}>
                <HorizontalChart
                  data={item.values}
                  color={colors.chartPurple}
                  sizeWidth={width}
                  paddingRight={80}
                />
              </Indicator>
            ))
          }
        </ResizeContainer>
      </div>
    </div>
  );
};
