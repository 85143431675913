import React, { useCallback } from 'react';
import Control from 'react-leaflet-control';
import classNames from 'classnames';
import { Button } from '../../ui/Button/Button';
import { ReactComponent as PlusIcon } from '../../../assets/Icons/Plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/Icons/Minus.svg';
import s from './CommonMap.module.scss';

export function ZoomControl({ onZoomChange }) {
  const onZoomIn = useCallback(() => onZoomChange(1), [onZoomChange]);
  const onZoomOut = useCallback(() => onZoomChange(-1), [onZoomChange]);

  return (
    <Control position="topleft">
      <Button
        className={classNames(s.ZoomButton, s._plus)}
        onClick={onZoomIn}
        variant={Button.DARKER}
      >
        <PlusIcon />
      </Button>
      <Button
        className={classNames(s.ZoomButton, s._minus)}
        onClick={onZoomOut}
        variant={Button.DARKER}
      >
        <MinusIcon />
      </Button>
    </Control>
  );
}
