import { format, parse } from 'url';
import React from 'react';
import classNames from 'classnames';
import s from './ExternalLink.module.scss';

export function ExternalLink({ className, ...props }) {
  return (
    // Контент для ссылки передается через props.children
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    <a
      className={classNames(s.ExternalLink, className)}
      target="_blank"
      rel="noreferrer noopener"
      {...props}
    />
  );
}

export function ExternalLinkDomain({ href, ...props }) {
  try {
    const url = parse(href);
    if (!url.protocol) {
      url.protocol = 'http';
    }

    return (
      <ExternalLink href={format(url)} {...props}>
        {url.host || href}
      </ExternalLink>
    );
  } catch (e) {
    console.error(e);
    return null;
  }
}
