import React from 'react';
import { useContextStore, useObservableState } from '@proscom/prostore-react';
import { PORTAL_ACTUALITY_DATE, STORE_PORTALS } from '../../store/stores';
import { NodePortal } from '../portal';
import { ActualityDate } from './ActualityDate';

export function NavBarActualityDate({ date }) {
  const portalsStore = useContextStore(STORE_PORTALS);
  const portalNode = useObservableState(
    portalsStore.get$(PORTAL_ACTUALITY_DATE)
  );

  return (
    <NodePortal target={portalNode}>
      <ActualityDate
        date={date}
        variant={ActualityDate.Variant.NAV}
        isActuality
      />
    </NodePortal>
  );
}
