import React, { useMemo } from 'react';
import classNames from 'classnames';
import { RatingStatus } from '../../../utils/constants';
import { ActualityDate } from '../../../common/NavBar/ActualityDate';
import s from './MainCards.module.scss';

const MainCard = ({ label, value, status, actualityDate }) => {
  const style = useMemo(() => {
    if (status === RatingStatus.ERROR) {
      return s._error;
    } else if (status === RatingStatus.WARN) {
      return s._warn;
    } else if (status === RatingStatus.SUCCESS) {
      return s._success;
    }
    return null;
  }, [status]);

  return (
    <div className={classNames(s.MainCard__card, style)}>
      <div className={s.MainCard__title}>{label}</div>
      {actualityDate && (
        <ActualityDate
          date={actualityDate}
          className={s.MainCard__actualityDate}
          variant={ActualityDate.Variant.DESCRIPTION}
        />
      )}
      <div className={s.MainCard__percent}>{value}</div>
    </div>
  );
};

export const MainCards = ({ className, cards }) => {
  return (
    <div className={s.MainCard}>
      {cards.map((card, i) => (
        <MainCard
          key={i}
          label={card.label}
          value={card.value}
          status={card.status}
          actualityDate={card.actualityDate}
        />
      ))}
    </div>
  );
};
