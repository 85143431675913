import { ReactComponent as PlanetChartIcon } from '../../../assets/Icons/IndexProjects.svg';
import { ReactComponent as StackedChartIcon } from '../../../assets/Icons/IndexSubjects.svg';
import { hideSubjectsOnIndexPage } from '../../../config';
import colors from '../../../colors.scss';

export const projectsColors = [
  {
    darkenColor: 'rgba(31,84,178,1)',
    firstColor: 'rgba(30,96,238,1)',
    secondColor: 'rgba(64,153,248,1)'
  },
  {
    darkenColor: 'rgba(164,3,111,1)',
    firstColor: 'rgba(183,2,129,1)',
    secondColor: 'rgba(245,16,176,1)'
  },
  {
    darkenColor: 'rgba(20,86,86,1)',
    firstColor: 'rgba(19,106,138,1)',
    secondColor: 'rgba(38,120,113,1)'
  },
  {
    darkenColor: 'rgba(111,50,157,1)',
    firstColor: 'rgba(106,48,147,1)',
    secondColor: 'rgba(160,68,255,1)'
  },
  {
    darkenColor: 'rgba(179,23,48,1)',
    firstColor: 'rgba(199,26,49,1)',
    secondColor: 'rgba(255,56,104,1)'
  },
  {
    textColor: colors.blueDark,
    darkenColor: 'rgba(208,208,208,1)',
    firstColor: 'rgba(244,244,244,1)',
    secondColor: 'rgba(229,229,229,1)'
  },
  {
    textColor: colors.blueDark,
    darkenColor: 'rgba(242,163,74,1)',
    firstColor: 'rgba(242,153,74,1)',
    secondColor: 'rgba(242,201,76,1)'
  },
  {
    textColor: colors.blueDark,
    darkenColor: 'rgba(108,151,232,1)',
    firstColor: 'rgba(127,171,253,1)',
    secondColor: 'rgba(167,191,232,1)'
  },
  {
    textColor: colors.blueDark,
    darkenColor: 'rgb(96,193,91,1)',
    firstColor: 'rgb(98,219,99,1)',
    secondColor: 'rgba(178,255,157,1)'
  },
  {
    textColor: colors.blueDark,
    darkenColor: 'rgba(98,171,182,1)',
    firstColor: 'rgba(76,161,175,1)',
    secondColor: 'rgba(196,224,229,1)'
  }
];

export const PROJECTS_LEVEL = 'projects';
export const SUBJECTS_LEVEL = 'subjects';

export const pageLevels = [
  {
    icon: PlanetChartIcon,
    label: 'Проекты',
    id: PROJECTS_LEVEL
  }
];

if (!hideSubjectsOnIndexPage) {
  pageLevels.push({
    icon: StackedChartIcon,
    label: 'Субъекты',
    id: SUBJECTS_LEVEL
  });
}

export const pageTabs = [
  {
    label: 'Кассовое исполнение',
    id: 'cash_exec'
  },
  {
    label: 'Объем федерального бюджета',
    id: 'budget_assign'
  }
];

export const districtColors = [
  {
    textColor: colors.blueDark,
    darkenColor: 'rgba(108,151,232,1)',
    firstColor: 'rgba(127,171,253,1)',
    secondColor: 'rgba(167,191,232,1)'
  },
  {
    darkenColor: 'rgba(164,3,111,1)',
    firstColor: 'rgba(183,2,129,1)',
    secondColor: 'rgba(245,16,176,1)'
  },
  {
    textColor: colors.blueDark,
    darkenColor: 'rgba(208,208,208,1)',
    firstColor: 'rgba(244,244,244,1)',
    secondColor: 'rgba(229,229,229,1)'
  },
  {
    darkenColor: 'rgba(111,50,157,1)',
    firstColor: 'rgba(106,48,147,1)',
    secondColor: 'rgba(160,68,255,1)'
  },
  {
    textColor: colors.blueDark,
    darkenColor: 'rgba(242,163,74,1)',
    firstColor: 'rgba(242,153,74,1)',
    secondColor: 'rgba(242,201,76,1)'
  },
  {
    darkenColor: 'rgba(31,84,178,1)',
    firstColor: 'rgba(30,96,238,1)',
    secondColor: 'rgba(64,153,248,1)'
  },
  {
    textColor: colors.blueDark,
    darkenColor: 'rgba(96,193,91,1)',
    firstColor: 'rgba(98,219,99,1)',
    secondColor: 'rgba(178,255,157,1)'
  },
  {
    darkenColor: 'rgba(20,86,86,1)',
    firstColor: 'rgba(19,106,138,1)',
    secondColor: 'rgba(38,120,113,1)'
  }
];

export const selectionChartParts = new Set(['federal', 'mbt']);
