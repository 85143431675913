import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { QUERY_REGIONAL_RESULT } from '../graphql/queries/results';

const regionalResultOptions = {
  query: QUERY_REGIONAL_RESULT,
  mapData: (result) => result.resultRegional,
  skipQuery: skipIfNull(null)
};

export const useRegionalResultQuery = (filter) => {
  return useGraphqlQuery({
    queryOptions: regionalResultOptions,
    variables: filter
      ? {
          filter
        }
      : null
  });
};
