import { useMemo } from 'react';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { keyBy } from 'lodash-es';
import { createPreservedLink } from '../../store/usePreserveQuery';
import { QUERY_FINANCES_BUDGET } from '../../graphql/queries/finance';
import { ExpensesType } from '../../data/expensesType';
import { getPlanAndObligationDate } from '../../utils/date';
import { getFirst } from '../../utils/data';
import { hideOutBudgetSources } from '../../config';
import { unificationNumber } from '../../utils/math';

const chartData7 = [
  {
    id: 0,
    name: 'План',
    values: []
  },
  {
    id: 3,
    name: 'Факт',
    values: []
  }
];

const budgetQueryOptions = {
  query: QUERY_FINANCES_BUDGET
};

const getIndices = (values) => {
  return values
    .map((item, iItem) => ({
      id: iItem,
      expenses_type_id: item.expenses_type_id,
      name: ExpensesType.getDisplayName(item.expenses_type),
      unit: ' млн руб.'
    }))
    .sort((a, b) => (a.name > b.name ? -1 : 1))
    .map((item, iItem) => ({
      ...item,
      id: iItem
    }));
};

const createValueMapper = (indices, federalData) => {
  const federalDataMap = keyBy(federalData, (item) => item.expenses_type_id);
  return (mapValue) =>
    indices.map((index) => ({
      id: index.id,
      name: index.name,
      value: mapValue(federalDataMap[index.expenses_type_id])
    }));
};

export function useBudgetChart({ yearQuarter, yearMonth, filter, query }) {
  const budgetQuery = useGraphqlQuery({
    queryOptions: budgetQueryOptions,
    variables: {
      yearQuarter,
      yearMonth,
      ...filter
    }
  });

  const budgetData = budgetQuery.state.data;

  const federalDate =
    budgetData && budgetData.federalDate ? budgetData.federalDate : null;

  const chartData1 = useMemo(() => {
    if (!budgetData) return null;

    const {
      total_budget,
      budget_limits,
      obligations,
      cash_expenses
    } = budgetData.federal;

    // let [planDate, obligationDate] = getPlanAndObligationDate(
    //   budgetData.federal
    // );

    return [
      {
        id: 0,
        name: 'План',
        values: [
          { id: 0, name: 'Доведённые', value: budget_limits / 1e6 },
          {
            id: 1,
            name: 'Недоведённые',
            value: (total_budget - budget_limits) / 1e6
          }
        ],
        actualityDate: federalDate
      },
      {
        id: 2,
        name: 'Бюдж. обяз.',
        values: [
          { id: 2, name: 'Принятые', value: obligations / 1e6 },
          {
            id: 3,
            name: 'Непринятые',
            value: unificationNumber((budget_limits - obligations) / 1e6, null)
          }
        ],
        actualityDate: federalDate
      },
      {
        id: 3,
        name: 'Факт',
        values: [{ id: 4, name: 'Факт', value: cash_expenses / 1e6 }],
        actualityDate: federalDate
      }
    ];
  }, [budgetData, federalDate]);

  const chartData2 = useMemo(() => {
    if (!budgetData) return null;

    const { total_budget, budget_limits, cash_expenses } = budgetData.mbt;

    // const [planDate, obligationDate] = getPlanAndObligationDate(budgetData.mbt);

    return [
      {
        id: 0,
        name: 'План',
        values: [
          { id: 0, name: 'Доведённые', value: budget_limits / 1e6 },
          {
            id: 1,
            name: 'Недоведённые',
            value: (total_budget - budget_limits) / 1e6
          }
        ],
        actualityDate: federalDate
      },
      {
        id: 2,
        name: 'Перечисл. субъектам',
        values: [{ id: 4, name: 'Факт', value: cash_expenses / 1e6 }],
        actualityDate: federalDate
      }
    ];
  }, [budgetData, federalDate]);

  const chartData3 = useMemo(() => {
    if (!budgetData) return null;

    const { otfg_kr_total, plan } = budgetData.mbtSubjects;

    return [
      {
        id: 0,
        name: 'План',
        values: [{ id: 0, name: 'План', value: plan / 1e6 }],
        actualityDate: federalDate
      },
      {
        id: 3,
        name: 'Факт',
        values: [{ id: 4, name: 'Факт', value: otfg_kr_total / 1e6 }],
        actualityDate: federalDate
      }
    ];
  }, [budgetData, federalDate]);

  const chartData4 = useMemo(() => {
    if (!budgetData) return null;

    const { otfg_kr } = budgetData.mbtSubjects;

    return [
      {
        id: 0,
        name: 'Факт',
        values: [{ id: 0, name: 'Факт', value: otfg_kr / 1e6 }],
        actualityDate: federalDate
      }
    ];
  }, [budgetData, federalDate]);

  const budgetCharts =
    chartData1 && chartData2
      ? [
          {
            name: 'Бюджетные ассигнования',
            data: chartData1
          },
          { name: 'Межбюджетные трансферты', data: chartData2 },
          { name: 'Междбюджетные трансферты субъекта', data: chartData3 },
          {
            name: 'Межбюджетные трансферты мун. образ.',
            data: chartData4
          }
        ]
      : null;

  const chartData5 = useMemo(() => {
    if (!budgetData) return null;

    const federalData = budgetData.federalTypes;

    const indices = getIndices(federalData);
    const mapValues = createValueMapper(indices, federalData);

    // const [planDate, obligationDate] = getPlanAndObligationDate(
    //   budgetData.federalTypes
    // );

    return [
      {
        id: 0,
        name: 'План',
        values: mapValues((item) => item.total_budget / 1e6),
        actualityDate: federalDate
      },
      {
        id: 2,
        name: 'Прин. бюдж. обяз.',
        values: mapValues((item) => item.obligations / 1e6),
        actualityDate: federalDate
      },
      {
        id: 3,
        name: 'Факт',
        values: mapValues((item) => item.cash_expenses / 1e6),
        actualityDate: federalDate
      }
    ];
  }, [budgetData, federalDate]);

  const chartData6 = useMemo(() => {
    if (!budgetData) return null;

    const subjectData = budgetData.subjectTypes;

    const indices = getIndices(subjectData);
    const mapValues = createValueMapper(indices, subjectData);

    const [planDate, obligationDate] = getPlanAndObligationDate(
      budgetData.subjectTypes
    );

    return [
      {
        id: 0,
        name: 'План',
        values: mapValues((item) => item.total_budget / 1e6),
        actualityDate: planDate
      },
      {
        id: 2,
        name: 'Прин. бюдж. обяз.',
        values: mapValues((item) => item.obligations / 1e6),
        actualityDate: obligationDate
      },
      {
        id: 3,
        name: 'Факт',
        values: mapValues((item) => item.cash_expenses / 1e6),
        actualityDate: federalDate
      }
    ];
  }, [budgetData, federalDate]);

  const grants = useMemo(() => {
    if (!budgetData || (budgetData && !budgetData.grants)) return null;
    const {
      grants,
      subsidies_regions,
      subsidies_organizations
    } = budgetData.grants;

    const [grantsPlanDate] = getPlanAndObligationDate(grants);
    const [regionsPlanDate] = getPlanAndObligationDate(subsidies_regions);
    const [organizationsPlanDate] = getPlanAndObligationDate(
      subsidies_organizations
    );

    return [
      {
        name: 'Субсидии регионам',
        data: [
          {
            id: 0,
            name: 'План',
            values: [
              {
                id: 0,
                name: 'План',
                value: subsidies_regions.total_budget / 1e6
              }
            ],
            actualityDate: regionsPlanDate
          },
          {
            id: 1,
            name: 'Факт',
            values: [
              {
                id: 0,
                name: 'Факт',
                value: subsidies_regions.cash_expenses / 1e6
              }
            ],
            actualityDate: federalDate
          }
        ]
      },
      {
        name: 'Субсидии учреждениям',
        data: [
          {
            id: 0,
            name: 'План',
            values: [
              {
                id: 0,
                name: 'План',
                value: subsidies_organizations.total_budget / 1e6
              }
            ],
            actualityDate: organizationsPlanDate
          },
          {
            id: 1,
            name: 'Факт',
            values: [
              {
                id: 0,
                name: 'Факт',
                value: subsidies_organizations.cash_expenses / 1e6
              }
            ],
            actualityDate: federalDate
          }
        ]
      },
      {
        name: 'Гранты',
        data: [
          {
            id: 0,
            name: 'План',
            values: [{ id: 0, name: 'План', value: grants.total_budget / 1e6 }],
            actualityDate: grantsPlanDate
          },
          {
            id: 1,
            name: 'Факт',
            values: [
              { id: 0, name: 'Факт', value: grants.cash_expenses / 1e6 }
            ],
            actualityDate: federalDate
          }
        ]
      }
    ];
  }, [budgetData, federalDate]);

  const pof = useMemo(() => {
    return getFirst(budgetData?.pof)?.sum / 1e6;
  }, [budgetData]);

  const kassCharts =
    chartData5 && chartData6
      ? [
          { name: 'Федеральный бюджет', data: chartData5 },
          {
            name: 'Региональный бюджет с федеральным бюджетом',
            data: chartData6,
            additionalLink: query
              ? createPreservedLink('/indicators/regions', {}, query)
              : undefined
          },
          ...(!hideOutBudgetSources
            ? [{ name: 'Внебюджетные источники', data: chartData7 }]
            : [])
        ]
      : null;

  return {
    budgetCharts,
    kassCharts,
    budgetData,
    grants,
    budgetQuery,
    pof,
    actualityDate: federalDate,
    isLoading: budgetQuery.check.spinner
  };
}
