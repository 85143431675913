import React from 'react';
import classNames from 'classnames';
import Dropdown from '../../../common/ui/Dropdown/Dropdown';
import { IndicatorGroupTitle } from './IndicatorGroup';
import { IndicatorCharts } from './IndicatorGoals';
import s from './Goals.module.scss';

export function NpoTasks({ npoTasks, year, actualityDate }) {
  return (
    (npoTasks &&
      npoTasks.map((task, iTask) => {
        return (
          <Dropdown
            key={iTask}
            className={classNames(s.Task)}
            childrenClassName={s.Task__dropdown}
            childrenOpenedClassName={s.GoalGroup__dropdown_opened}
            top={({ opened, setOpened }) => (
              <IndicatorGroupTitle
                className={s.TaskTitle}
                title={task.name_full}
                opened={opened}
                setOpened={setOpened}
              />
            )}
          >
            {({ opened }) =>
              opened ? (
                <IndicatorCharts
                  indicators={task.milestones}
                  activeYear={year}
                  actualityDate={actualityDate}
                />
              ) : null
            }
          </Dropdown>
        );
      })) ||
    false
  );
}
