import React from 'react';
import { Button } from '../Button/Button';
import { createDownloadLink } from '../../../store/usePreserveQuery';
import { CircleProgressIndicator } from '../ProgressIndicator/CircleProgressIndicator';
import { ReactComponent as DownloadIcon } from '../../../assets/Icons/Download.svg';
import { useCookieLoader } from '../../../utils/useCookieLoader';
import s from './DownloadButton.module.scss';

export function LoaderDownloadButtonLink({
  cookieLoader,
  onClick,
  onDownloadFinish,
  ...props
}) {
  const { downloadId, handleClick, isLoading } = useCookieLoader(
    cookieLoader,
    onDownloadFinish,
    onClick
  );

  return (
    <DownloadButtonLink
      {...props}
      downloadId={downloadId}
      onClick={handleClick}
      isLoading={isLoading}
    />
  );
}

export function DownloadButtonLink({
  url,
  fileName,
  cookieLoader,
  onClick,
  onDownloadFinish,
  downloadId,
  isLoading,
  ...props
}) {
  return (
    <Button
      target="_blank"
      rel="noopener noreferrer"
      download
      href={url && createDownloadLink(url, downloadId)}
      className={s.DownloadButton__link}
      onClick={onClick}
      {...props}
      disabled={isLoading}
    >
      {isLoading ? (
        <CircleProgressIndicator size={24} />
      ) : (
        <>
          <div className={s.DownloadButton__fileName}>{fileName}</div>
          <DownloadIcon className={s.DownloadButton__icon} />
        </>
      )}
    </Button>
  );
}
