import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import { QUERY_GET_ORGANIZATION } from '../graphql/queries/organization';

const organizationQueryOptions = {
  query: QUERY_GET_ORGANIZATION,
  mapData: (result) => result.organization,
  skipQuery: skipIfNull(null)
};

export function useOrganization(id) {
  return useGraphqlQueryVars({
    queryOptions: organizationQueryOptions,
    variableCreator: [() => ({ id }), [id]]
  });
}
