import React, { useMemo, useState } from 'react';
import { max as d3max } from 'd3';
import ResizeObserver from 'react-resize-observer';
import { ExpensesType } from '../../../data/expensesType';
import { shortMonthNames } from '../../../utils/constants';
import { HorizontalBarChart } from '../../../common/charts/HorizontalBarChart';
import { Select } from '../../../common/ui/Select/Select';
import { useBudgetChartRpBase } from '../../IndicatorsPage/useBudgetChartRp';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { MainChart } from '../../../common/RegionalBudgetCharts/RegionalBudgetCharts';
import { queryLoader } from '../../../common/QueryLoader';
import { hideFinancialData } from '../../../config';
import { useResultFinanceQuery } from './useResultBudgetChart';
import { ChartData } from './ChartData';
import { PercentChart } from './PercentChart';
import colors from '../../../colors.scss';
import s from './ResultsInfoView.module.scss';

const resultsLineIndices = [
  {
    id: 1,
    name: 'План',
    unit: ' млн руб.',
    color: 'pink',
    decimals: 2,
    strokeWidth: 6,
    showCircles: false
  },
  {
    id: 2,
    name: 'Кассовое исполнение',
    unit: ' млн руб.',
    color: 'green',
    decimals: 2,
    strokeWidth: 6,
    showCircles: false
  }
];

const legendsData = [
  {
    title: 'Факт',
    color: 'rgb(50, 80, 187)'
  },
  {
    title: 'План',
    color: 'rgba(50, 80, 187, 0.5)'
  }
];

const BUDGET_FEDERAL = 'federal';
const BUDGET_SUBJECT = 'subject';
const BUDGET_LOCAL = 'local';

const budgetTypes = [
  // { value: BUDGET_FEDERAL, label: 'Федеральный бюджет' },
  { value: BUDGET_SUBJECT, label: 'Региональные бюджеты' }
  // { value: BUDGET_LOCAL, label: 'Местные бюджеты' }
];

function TotalDataChart({ item, max }) {
  const [chartWidth, setChartWidth] = useState(326);

  const onResize = (size) => {
    setChartWidth(size.width);
  };

  return (
    <div className={s.TotalData__chart}>
      <div className={s.TotalData__chartTitle}>{item.title}</div>
      <ResizeObserver onResize={onResize} />
      <HorizontalBarChart
        className={s.TotalData__horizontalChart}
        data={item.chartData}
        total={max}
        decimals={1}
        color={colors.blue}
        sizeHeight={16 + 16 + 16}
        sizeWidth={chartWidth}
      />
    </div>
  );
}

function TotalData({ title, horizontalCharts }) {
  const [budgetType, setBudgetType] = useState(budgetTypes[0]);
  const bars = horizontalCharts && horizontalCharts[budgetType.value];
  const max = bars ? d3max(bars, (b) => b.total) : 0;

  return (
    <div className={s.TotalData}>
      <div className={s.TotalData__title}>{title}</div>
      <Select
        className={s.TotalData__select}
        value={budgetType}
        values={budgetTypes}
        onChange={setBudgetType}
      />
      {!hideFinancialData && bars && bars.length > 0 ? (
        <>
          <div className={s.TotalData__legends}>
            {legendsData.map((item, index) => (
              <div key={index} className={s.TotalData__legends__item}>
                <div
                  className={s.TotalData__legends__dots}
                  style={{ background: item.color }}
                />
                <div className={s.TotalData__legends__title}>{item.title}</div>
              </div>
            ))}
          </div>
          <div className={s.TotalData__charts}>
            {bars.map((item) => (
              <TotalDataChart key={item.id} item={item} max={max} />
            ))}
          </div>
        </>
      ) : (
        <div style={{ height: 200 }}>Нет данных</div>
      )}
    </div>
  );
}

const useFinanceData = ({
  resultId,
  year,
  yearMonth,
  grbsCodes,
  regionCodeNalog,
  expensesDirectionId
}) => {
  const financeQuery = useResultFinanceQuery({
    resultIds: resultId,
    regionCodeNalogs: regionCodeNalog ? [regionCodeNalog] : undefined,
    year,
    yearMonth,
    grbsCodes,
    expensesDirectionIds: expensesDirectionId
      ? [expensesDirectionId]
      : undefined
  });

  const financeData = financeQuery.state.data;
  const monthlyChartData = useMemo(() => {
    if (!financeData || !financeData.monthly) return null;
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    return months.map((month) => {
      const item = financeData.monthly.find((row) => row.month === month);
      return {
        id: month,
        name: shortMonthNames[month - 1],
        values: item ? [item.total_budget / 1e6, item.cash_expenses / 1e6] : []
      };
    });
  }, [financeData]);

  const bars = useMemo(() => {
    if (!financeData) return null;

    const extractVrData = (data) =>
      data
        ? data
            .map((item) => {
              return {
                id: item.expenses_type.code,
                title: ExpensesType.getDisplayName(item.expenses_type),
                chartData: [
                  {
                    id: 0,
                    value: item.cash_expenses / 1e6
                  },
                  {
                    id: 1,
                    value: item.total_budget / 1e6
                  }
                ],
                total: Math.max(
                  item.total_budget / 1e6,
                  item.cash_expenses / 1e6
                )
              };
            })
            .sort((a, b) => a.id - b.id)
        : null;

    return {
      [BUDGET_FEDERAL]: extractVrData(financeData.federalTypes),
      [BUDGET_SUBJECT]: extractVrData(financeData.subjectTypes),
      [BUDGET_LOCAL]: extractVrData(financeData.localTypes)
    };
  }, [financeData]);

  return {
    bars,
    financeData,
    monthlyChartData,
    financeQuery
  };
};

export default function ResultInfoView({
  resultId,
  year,
  yearMonth,
  grbsCodes
}) {
  const { bars, financeData, monthlyChartData, financeQuery } = useFinanceData({
    resultId,
    year,
    yearMonth,
    grbsCodes
  });

  return (
    <div className={s.ResultsInfoView}>
      <ChartData budgetData={financeData} query={financeQuery} />
      {queryLoader(financeQuery, {
        onlyFirst: true,
        className: s.ResultsInfoView__bottomLoader
      }) || (
        <>
          {!hideFinancialData && bars && (
            <TotalData
              title={'Исполнение результата по видам расходов, млн руб.'}
              horizontalCharts={bars}
            />
          )}
          {monthlyChartData && (
            <PercentChart
              data={monthlyChartData}
              indices={resultsLineIndices}
              title={
                'Кассовое исполнение результата по федеральному бюджету, млн руб.'
              }
            />
          )}
        </>
      )}
    </div>
  );
}

export function RegionalResultInfoView({
  resultId,
  year,
  yearMonth,
  grbsCodes,
  budgetCharts,
  regionCodeNalog
}) {
  const { bars, monthlyChartData, financeQuery } = useFinanceData({
    resultId,
    year,
    yearMonth,
    grbsCodes,
    regionCodeNalog
  });

  return (
    <div className={s.ResultsInfoView}>
      {/*<NavBarActualityDate date={financeData && financeData.federalDate} />*/}
      <MainChart {...budgetCharts} />
      {queryLoader(financeQuery, {
        onlyFirst: true,
        className: s.ResultsInfoView__bottomLoader
      }) || (
        <>
          {!hideFinancialData && bars && (
            <TotalData
              title={'Исполнение результата по видам расходов, млн руб.'}
              horizontalCharts={bars}
            />
          )}
          {monthlyChartData && (
            <PercentChart
              data={monthlyChartData}
              indices={resultsLineIndices}
              title={
                'Кассовое исполнение результата по федеральному бюджету, млн руб.'
              }
            />
          )}
        </>
      )}
    </div>
  );
}
