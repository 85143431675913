import React, { useMemo } from 'react';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { keyBy } from 'lodash-es';
import { skipIfNull } from '@proscom/prostore';
import { max as d3Max } from 'd3';
import { QUERY_GET_REGION_FINANCES } from '../../graphql/queries/finance';
import { ExpensesType } from '../../data/expensesType';
import { getPlanAndObligationDate } from '../../utils/date';
import { getFirst } from '../../utils/data';
import { unificationNumber } from '../../utils/math';
import { hideOutBudgetSources } from '../../config';

const outbudgetChart = [
  {
    id: 1,
    name: 'План',
    values: [
      {
        id: 2,
        name: 'План',
        value: 0
      }
    ]
  },
  {
    id: 2,
    name: 'Факт',
    values: [
      {
        id: 1,
        name: 'Факт',
        value: 0
      }
    ]
  }
];

const budgetQueryOptions = {
  query: QUERY_GET_REGION_FINANCES,
  skipQuery: skipIfNull(null)
};

const getIndices = (values) => {
  return values
    .map((item, iItem) => ({
      id: iItem,
      expenses_type_id: item.expenses_type_id,
      name: ExpensesType.getDisplayName(item.expenses_type),
      unit: ' млн руб.'
    }))
    .sort((a, b) => (a.name > b.name ? -1 : 1))
    .map((item, iItem) => ({
      ...item,
      id: iItem
    }));
};

const createValueMapper = (indices, federalData) => {
  const federalDataMap = keyBy(federalData, (item) => item.expenses_type_id);
  return (mapValue) =>
    indices.map((index) => ({
      id: index.id,
      name: index.name,
      value: mapValue(federalDataMap[index.expenses_type_id])
    }));
};

export function useBudgetChartRpBase({ yearQuarter, yearMonth, filter }) {
  const budgetQuery = useGraphqlQuery({
    queryOptions: budgetQueryOptions,
    variables: filter ? { yearQuarter, yearMonth, ...filter } : null
  });

  const budgetData = budgetQuery.state.data;
  const federalDate =
    budgetData && budgetData.federalDate ? budgetData.federalDate : null;

  const criteria = useMemo(() => {
    const result = {};
    if (!budgetData) {
      return null;
    }

    if (budgetData.total) {
      result.accept = budgetData.total.accept;
      result.cash_completion = budgetData.total.cash_completion;
    }
    if (budgetData.dcCompletion && budgetData.dcCompletion.length > 0) {
      result.dc_completion_percent =
        budgetData.dcCompletion[0].dc_completion_percent;
    }
    return result;
  }, [budgetData]);

  const vrChart = useMemo(() => {
    if (!budgetData) return null;

    const { types } = budgetData;
    const indices = getIndices(types);
    const getValues = createValueMapper(indices, types);

    const [planDate, obligationDate] = getPlanAndObligationDate(types);

    return [
      {
        id: 0,
        name: 'План',
        values: getValues((r) => r.total_budget / 1e6),
        actualityDate: planDate
      },
      {
        id: 2,
        name: 'Бюдж. обяз.',
        values: getValues((r) => r.obligations / 1e6),
        actualityDate: obligationDate
      },
      {
        id: 3,
        name: 'Факт',
        values: getValues((r) => r.cash_expenses / 1e6),
        actualityDate: federalDate
      }
    ];
  }, [budgetData, federalDate]);

  const sourcesChart = useMemo(() => {
    if (!budgetData) return null;

    const { sources, total, pof } = budgetData;

    const [sourcesPlanDate, sourcesObligationDate] = getPlanAndObligationDate(
      sources
    );
    const [totalPlanDate, totalObligationDate] = getPlanAndObligationDate(
      total
    );
    const planDate = d3Max([sourcesPlanDate, totalPlanDate]);
    const obligationDate = d3Max([sourcesObligationDate, totalObligationDate]);

    return [
      {
        id: 1,
        name: 'План',
        values: [
          {
            id: 1,
            name: 'Федеральный бюджет',
            value: sources.plan / 1e6
          },
          {
            id: 2,
            name: 'Региональный бюджет',
            value: unificationNumber(
              (total.total_budget - sources.plan) / 1e6,
              null
            )
          }
        ],
        actualityDate: planDate
      },
      {
        id: 2,
        name: 'Принято бюдж. обязательств',
        values: [
          {
            id: 1,
            name: 'Принято БО',
            value: total.obligations / 1e6
          }
        ],
        actualityDate: obligationDate
      },
      {
        id: 3,
        name: 'Предел. объемы финансирования',
        values: [
          {
            id: 1,
            name: 'ПОФ',
            value: getFirst(pof)?.sum / 1e6
          }
        ],
        actualityDate: federalDate
      },
      {
        id: 4,
        name: 'Факт',
        values: [
          {
            id: 1,
            name: 'Федеральный бюджет',
            value: sources.otfg_kr_total / 1e6
          },
          {
            id: 2,
            name: 'Региональный бюджет',
            value: unificationNumber(
              (total.cash_expenses - sources.otfg_kr_total) / 1e6,
              null
            )
          }
        ],
        actualityDate: federalDate
      }
    ];
  }, [budgetData, federalDate]);

  const localChart = useMemo(() => {
    if (!budgetData) return null;

    const { local } = budgetData;

    const [planDate] = getPlanAndObligationDate(local);

    return [
      {
        id: 1,
        name: 'План',
        values: [
          {
            id: 2,
            name: 'План',
            value: local.total_budget / 1e6
          }
        ],
        actualityDate: planDate
      },
      {
        id: 2,
        name: 'Факт',
        values: [
          {
            id: 1,
            name: 'Факт',
            value: local.cash_expenses / 1e6
          }
        ],
        actualityDate: federalDate
      }
    ];
  }, [budgetData, federalDate]);

  const pof = useMemo(() => {
    if (!budgetData?.pof) {
      return null;
    }
    const pofSum = budgetData.pof.sum;

    return pofSum ? pofSum / 1e6 : 0;
  }, [budgetData]);

  return {
    vrChart,
    sourcesChart,
    localChart,
    outbudgetChart,
    budgetData,
    budgetQuery,
    federalDate,
    pof,
    criteria
  };
}

export function useBudgetChartRp({ yearQuarter, yearMonth, filter }) {
  const {
    vrChart,
    sourcesChart,
    localChart,
    outbudgetChart,
    pof,
    budgetData,
    budgetQuery
  } = useBudgetChartRpBase({ yearQuarter, yearMonth, filter });

  const typesCharts = vrChart
    ? [
        {
          name: '',
          data: vrChart
        }
      ]
    : null;

  const sourcesCharts =
    sourcesChart && localChart
      ? [
          {
            name: 'Бюджет субъекта',
            data: sourcesChart
          },
          {
            name: 'Муниципальные образования',
            data: localChart
          },
          ...(!hideOutBudgetSources
            ? [{ name: 'Внебюджетные источники', data: outbudgetChart }]
            : [])
        ]
      : null;

  return {
    typesCharts,
    sourcesCharts,
    budgetData,
    budgetQuery,
    pof
  };
}
