import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { ReactComponent as CornerIcon } from '../../../assets/Icons/Corner.svg';
import { DATE_FORMAT_RUSSIAN } from '../../../utils/date';
import { NBSP } from '../../../utils/utf';
import s from './FileCard.module.scss';

const FileCard = ({ className, icon: Icon, title }) => {
  return (
    <div className={classNames(s.FileCard, className)}>
      <div className={s.FileCard__icon}>
        <Icon />
      </div>
      <h2 className={s.FileCard__params}>{title}</h2>
      <p className={s.FileCard__additionalInfo}>
        По состоянию на {format(new Date(), DATE_FORMAT_RUSSIAN)}
        <br />
        Предпросмотр файла невозможен. Для скачивания воспользуйтесь кнопкой в
        {NBSP}верхнем углу экрана
      </p>
      <div className={s.FileCard__corner}>
        <CornerIcon className={classNames(s.FileCard__cornerIcon, s._blue)} />
        <CornerIcon
          className={classNames(s.FileCard__cornerIcon, s._transparent)}
        />
      </div>
    </div>
  );
};

export default FileCard;
