import React, { useCallback, useMemo, useRef } from 'react';
import classNames from 'classnames';

import { CardTotal } from '../../../common/ui/CardSmall/CardTotal';
import { CardCharts } from '../../../common/ui/CardSmall/CardCharts';
import { formatNumber } from '../../../utils/formatNumber';

import { unificationNumber } from '../../../utils/math';
import colors from '../../../colors.scss';
import s from './CardsView.module.scss';

export const ProjectCard = ({
  item: { data = {}, subjectData = {}, projectsMbt = {}, colors: itemColors },
  pageTab,
  onClick,
  active,
  ...rest
}) => {
  const clickTimerRef = useRef();
  const Card = pageTab === 'cash_exec' ? CardTotal : CardCharts;
  const headerCells = useMemo(() => ['', 'План/Факт', '%'], []);

  const handleMouseDown = useCallback((event) => {
    clickTimerRef.current = new Date();
  }, []);

  // Костыль, потому что slick говно
  const handleMouseUp = useCallback(
    (event) => {
      const date = new Date();
      if (date - clickTimerRef.current < 300) {
        if (typeof onClick === 'function') {
          onClick();
        }
      }
    },
    [onClick]
  );

  const getValidData = (_data) => ({
    total_budget: _data.total_budget || 0,
    cash_expenses: _data.cash_expenses || 0
  });

  const validData = getValidData(data);
  const subjectValidData = getValidData(subjectData);

  return (
    <Card
      className={classNames(s.CardsView__item, {
        [s._active]: active
      })}
      type={pageTab}
      onMouseDown={handleMouseDown}
      onTouchStart={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
      data={
        pageTab === 'cash_exec'
          ? [
              [
                'Итого',
                `${formatNumber(
                  (validData.total_budget + subjectValidData.total_budget) /
                    1e9,
                  1
                )}/${formatNumber(
                  (validData.cash_expenses + subjectValidData.cash_expenses) /
                    1e9,
                  1
                )}`,
                formatNumber(
                  ((validData.cash_expenses + subjectValidData.cash_expenses) /
                    (validData.total_budget + subjectValidData.total_budget)) *
                    100,
                  2
                )
              ],
              [
                'ФБ',
                `${formatNumber(data.total_budget / 1e9, 1)}/${formatNumber(
                  data.cash_expenses / 1e9,
                  1
                )}`,
                formatNumber((data.cash_expenses / data.total_budget) * 100, 2)
              ],
              [
                'РБ',
                `${formatNumber(
                  subjectData.total_budget / 1e9,
                  1
                )}/${formatNumber(subjectData.cash_expenses / 1e9, 1)}`,
                formatNumber(
                  (subjectData.cash_expenses / subjectData.total_budget) * 100,
                  2
                )
              ]
            ]
          : {
              budget: data.total_budget / 1e9,
              brought: data.budget_limits / 1e9,
              accepted: data.obligations / 1e9,
              notAccepted: unificationNumber(
                (data.budget_limits - data.obligations) / 1e9
              )
            }
      }
      headCells={headerCells}
      layoutProps={
        active
          ? {
              style: {
                background: `linear-gradient(210.42deg, ${itemColors.firstColor} 0%, ${itemColors.secondColor} 100%)`,
                color: itemColors.textColor
              }
            }
          : {
              style: {
                background: colors.blueDark,
                color: colors.white
              }
            }
      }
      title={data.federal_project.name_full}
      {...rest}
    />
  );
};
