import React from 'react';
import classNames from 'classnames';
import s from './PageTitle.module.scss';

export const Heading2 = ({ className, children, ...props }) => {
  return (
    <h2 className={classNames(s.Heading2, className)} {...props}>
      {children}
    </h2>
  );
};
