import React, { useMemo, useState } from 'react';
import { addDays } from 'date-fns';
import { skipIfNull } from '@proscom/prostore';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { useStoreState } from '@proscom/prostore-react';
import { QUERY_GET_ROADMAP } from '../../graphql/queries/federalProjects';
import { CircleProgressIndicator } from '../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { usePreserveQueryFast } from '../../store/usePreserveQuery';
import { TopBarProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { Result } from '../../data/result';
import { RightPaneLayout } from '../../common/GridLayout/RightPaneLayout';
import { useQueryPeriodFast } from '../../utils/useQueryPeriod';
import { ErrorMessage } from '../../common/ErrorMessage';
import { useWindowSize } from '../../store/useWindowSize';
import { FlyingButton } from '../../common/FlyingBox/FlyingBox';
import { ReactComponent as ReportIcon } from '../../assets/Icons/Document.svg';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { TopBar } from '../../common/TopBar/TopBar';
import { STORE_AUTH } from '../../store/stores';
import { User } from '../../data/user';
import HasNoAccessPage from '../HasNoAccessPage/HasNoAccessPage';
import { RF_VALUE } from '../IndicatorsPage/ProgressView/ProgressView';
import { useRoadmapEvents } from '../../store/useRoadmapEvents';
import { useRegionalResultQuery } from '../../store/useResult';
import { ReactHelmet } from '../../common/ReactHelmet';
import { useBudgetChartRpBase } from '../IndicatorsPage/useBudgetChartRp';
import { Title } from './Title.js/Title';
import ResultInfoView, {
  RegionalResultInfoView
} from './ResultsInfoView/ResultInfoView';
import { MainChart } from './MainCharts/MainCharts';
import { processSubsidiaryEntityPlans } from './processSubsidiaryEntityPlans';
import { RoadmapEvents } from './RoadmapEvents/RoadmapEvents';
import s from './ResultPage.module.scss';

const roadmapOptions = {
  query: QUERY_GET_ROADMAP,
  mapData: (result) => result.roadmap,
  skipQuery: skipIfNull(null)
};

const useRoadmapQuery = (id, subsidiaryEntityPlanFilter) => {
  return useGraphqlQuery({
    queryOptions: roadmapOptions,
    variables: id
      ? {
          id,
          subsidiaryEntityPlanFilter: {
            active: true,
            ...subsidiaryEntityPlanFilter
          }
        }
      : null
  });
};

function RoadmapPageComponent({ match, history, query, changeQuery }) {
  const roadmapId = match.params.roadmapId;
  const preserved = usePreserveQueryFast(query);
  const { year, yearMonth } = useQueryPeriodFast(query, changeQuery);
  const regionCode =
    query.region_code !== RF_VALUE ? query.region_code : undefined;
  const grbs = query.grbs && query.grbs !== 'all' ? [query.grbs] : undefined;

  const ws = useWindowSize();
  const isMobile = ws.isMobile || ws.isTablet;

  const [rightOpened, setRightOpened] = useState(false);

  const onRightOpen = () => {
    setRightOpened(true);
  };
  const onRightClose = () => {
    setRightOpened(false);
  };

  // Запрашиваем саму дорожную карту
  // Фильтруем "План субсидиарных сущностей" по году, чтобы дальше узнать, в каких регионах в выбранном году реализуется ДК
  const roadmapQuery = useRoadmapQuery(roadmapId, { year });
  const roadmapData = roadmapQuery.state.data;

  // Если выбран код региона, отображаем данные по региональному результату.
  // Для этого ищем региональный результат по id федерального результата и кода региона
  const regionalResultFilter = useMemo(() => {
    if (!regionCode || !roadmapData) {
      return null;
    }
    return {
      region_code_nalogs: [regionCode],
      result_federal_ids: [roadmapData.result.id]
    };
  }, [regionCode, roadmapData]);

  const regionalResultQuery = useRegionalResultQuery(regionalResultFilter);
  const regionalResultData = regionalResultQuery.state.data;

  // Если код региона не выбран, будет использоваться федеральный результат
  const federalResultData = roadmapData && roadmapData?.result;

  const resultData = regionCode ? regionalResultData : federalResultData;

  const federalProject =
    federalResultData && federalResultData?.owner_task?.federal_project;

  const subsidiaryEntityPlans =
    roadmapData && roadmapData?.subsidiary_entity_plans;

  const eventsQuery = useRoadmapEvents(roadmapId, year, regionCode);

  const budgetsFilter = useMemo(
    () =>
      regionCode && federalResultData
        ? {
            regionCodeNalog: regionCode,
            resultIds: [federalResultData.id],
            federalProjectCodes: federalProject?.project_code
              ? [federalProject.project_code + '']
              : undefined,
            grbsCodes: grbs,
            expensesDirectionIds: federalResultData?.expenses_direction?.id
              ? federalResultData.expenses_direction.id
              : undefined
          }
        : null,
    [regionCode, federalResultData, federalProject, grbs]
  );

  const budgetCharts = useBudgetChartRpBase({
    yearMonth,
    filter: budgetsFilter
  });

  const {
    regionsOfImplementation,
    selectedRegion,
    totalRegions
  } = useMemo(() => {
    return processSubsidiaryEntityPlans(subsidiaryEntityPlans, regionCode);
  }, [subsidiaryEntityPlans, regionCode]);

  // todo graphql date
  const actualityDate = addDays(new Date(), -1);

  return (
    <RightPaneLayout
      topBar={
        <TopBar>
          <TopBarProjects
            projects={federalProject ? [federalProject] : undefined}
            noCommon={true}
          />
        </TopBar>
      }
      right={
        roadmapQuery.check.spinner ? (
          <CircleProgressIndicator />
        ) : regionCode ? (
          regionalResultData && (
            <RegionalResultInfoView
              resultId={regionalResultData.result_federal.id}
              year={year}
              yearMonth={yearMonth}
              regionCodeNalog={regionCode}
              grbsCodes={grbs}
              budgetCharts={budgetCharts}
            />
          )
        ) : (
          federalResultData && (
            <ResultInfoView
              resultId={federalResultData.id}
              year={year}
              yearMonth={yearMonth}
              grbsCodes={grbs}
            />
          )
        )
      }
      extra={
        isMobile && (
          <FlyingButton
            className={s.Roadmap__flyingButton}
            onClick={onRightOpen}
          >
            <ReportIcon />
            <span>Финансирование</span>
          </FlyingButton>
        )
      }
      onRightClose={onRightClose}
      rightOpened={rightOpened}
    >
      <ReactHelmet
        entity={roadmapData && roadmapData.name_full}
        type={'Дорожная карта'}
      />
      <Title
        splitLength={200}
        title={roadmapData ? roadmapData.name_full : '...'}
        currentRegion={regionCode ? selectedRegion?.name || '...' : null}
        resultName={roadmapData && Result.getName(resultData)}
        to={
          regionCode
            ? preserved.createLink(
                `/regions/${regionCode}/results/${regionalResultData?.id}`
              )
            : preserved.createLink(
                `/indicators/results/${federalResultData?.id}`
              )
        }
        onGoBack={() =>
          history.goBack(
            federalResultData && preserved.createLink(`/indicators?tab=roadmap`)
          )
        }
      />
      {roadmapQuery.check.spinner ? (
        <CircleProgressIndicator />
      ) : roadmapQuery.check.error ? (
        <ErrorMessage error={roadmapQuery.state.error} />
      ) : (
        <>
          <MainChart
            resultData={resultData}
            year={year}
            actualityDate={actualityDate}
          />
          <RoadmapEvents
            allRegions={regionsOfImplementation}
            eventsQuery={eventsQuery}
            totalRegions={totalRegions}
            currentRegion={selectedRegion}
            regionCode={regionCode}
          />
        </>
      )}
    </RightPaneLayout>
  );
}

function RoadmapPage({ match, history }) {
  const [query, changeQuery] = useUrlQuery();
  const auth = useStoreState(STORE_AUTH);
  const user = User.getUser(auth);

  if (!query.region_code && user.role === User.roles.REGION_ADMIN) {
    return <HasNoAccessPage />;
  }

  return (
    <RoadmapPageComponent
      match={match}
      history={history}
      query={query}
      changeQuery={changeQuery}
    />
  );
}

export default RoadmapPage;
