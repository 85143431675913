import React from 'react';
import classNames from 'classnames';
import s from './Actions.module.scss';

export const Actions = ({ className, children }) => {
  return (
    <div className={classNames(s.Actions, className)}>
      {React.Children.map(children, (child, i) => (
        <div key={i} className={s.Action}>
          {child}
        </div>
      ))}
    </div>
  );
};
