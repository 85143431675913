import React from 'react';
import { color } from 'd3';
import { degreeToRad } from './math';

export function createFadingPalette(start, length, minOpacity = 0.2) {
  const base = color(start);
  return (index) =>
    base.copy({
      opacity: ((length - index) / length) * (1 - minOpacity) + minOpacity
    });
}

export function createSVGGradient(gradientLibrary, options = {}) {
  if (!gradientLibrary) return null;
  const { reCalcPosition } = options;
  return gradientLibrary.map((item, index) => {
    const anglePI = degreeToRad(item.rotate ? item.rotate : 0);
    const angleCoords = reCalcPosition
      ? {
          x1: Math.round(50 + Math.sin(anglePI) * 50) + '%',
          y1: Math.round(50 + Math.cos(anglePI) * 50) + '%',
          x2: Math.round(50 + Math.sin(anglePI + Math.PI) * 50) + '%',
          y2: Math.round(50 + Math.cos(anglePI + Math.PI) * 50) + '%'
        }
      : {};

    return (
      <linearGradient
        key={`linearGradientLibrary_${index}`}
        id={item.id}
        gradientTransform={item.rotate ? `rotate(${item.rotate})` : null}
        gradientUnits={reCalcPosition ? 'userSpaceOnUse' : null}
        {...angleCoords}
      >
        {item.colors.map((colItem, colIndex) => (
          <stop
            key={`linearGradient_color_${colIndex}`}
            offset={colItem.offset + '%'}
            stopColor={colItem.color}
            stopOpacity={colItem.opacity}
          />
        ))}
      </linearGradient>
    );
  });
}
