import { useCallback, useState } from 'react';
import { nanoid } from 'nanoid';
import Cookies from 'js-cookie';
import { useTimeoutRef } from './useTimeoutRef';

const noop = () => {};

export function useCookieLoader(
  cookieLoader = true,
  onDownloadFinish = noop,
  onClick = noop
) {
  const [downloadId, setDownloadId] = useState(nanoid(20));
  const [isLoading, setLoading] = useState(false);
  const timeout = useTimeoutRef();

  // Хитрый способ узнать что файл скачался при использовании download=true
  // https://stackoverflow.com/questions/1106377/detect-when-browser-receives-file-download
  const handleClick = useCallback(
    (e) => {
      if (cookieLoader) {
        setLoading(true);
        let ticks = 0;

        const tick = () => {
          ticks++;
          if (ticks === 60) {
            setLoading(false);
            setDownloadId(nanoid(20));
            return;
          }

          const value = Cookies.get('downloadId_' + downloadId);
          if (value) {
            setLoading(false);
            setDownloadId(nanoid(20));
            onDownloadFinish();
          } else {
            enqueue();
          }
        };

        const enqueue = () => timeout.set(tick, 1000);

        enqueue();
      } else {
        onDownloadFinish();
      }

      if (onClick) {
        onClick(e);
      }
    },
    [cookieLoader, downloadId, onClick, onDownloadFinish, timeout]
  );

  return { handleClick, downloadId, isLoading };
}
