import React from 'react';
import PropTypes from 'prop-types';
import { ActualityDate } from '../../../common/NavBar/ActualityDate';
import s from '../ReportsPage.module.scss';

const ReportTextContent = ({ className, actualityDate, html, text }) => (
  <div className={s.Report__Content}>
    {actualityDate && (
      <ActualityDate
        date={actualityDate}
        variant={ActualityDate.Variant.DESCRIPTION}
      />
    )}
    {text ? (
      <p className={s.Report__mainText}>{text}</p>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: html }} />
    )}
  </div>
);

ReportTextContent.propTypes = {
  text: PropTypes.string
};

export default ReportTextContent;
