import { getDataName, joinNonEmpty } from '../utils/data';
import { EMDASH } from '../utils/utf';
import { Organization } from './organization';

export class SubsidiaryEntity {
  static combineAddress({
    house,
    index,
    locality,
    region_string,
    street,
    building,
    address
  }) {
    let parts = [
      index,
      region_string,
      locality,
      street,
      house && `д. ${house}`,
      building && `стр./к. ${building}`
    ];

    const nonEmpty = parts.filter((p) => p);
    if (nonEmpty.length > region_string ? 1 : 0) {
      return nonEmpty.join(', ');
    }
    if (region_string && address) {
      return `${region_string}, ${address}`;
    }
    return region_string || null;
  }

  static getCompleteName(entity) {
    const organizationName = SubsidiaryEntity.getBaseOrganizationName(
      entity,
      true
    );
    return joinNonEmpty([getDataName(entity), organizationName], ` ${EMDASH} `);
  }

  static getBaseOrganizationName(entity, complete = false) {
    if (!entity) return null;
    return (
      (complete
        ? Organization.getCompleteName(entity.base_organization)
        : Organization.getName(entity.base_organization)) ||
      entity.base_organization_name
    );
  }

  static getLink(entity) {
    const baseOrganizationId =
      entity.base_organization_id || entity.base_organization?.id;
    if (baseOrganizationId) {
      return `/organizations/${baseOrganizationId}/entities/${entity.id}`;
    } else {
      return `/entities/${entity.id}`;
    }
  }
}
