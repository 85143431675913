import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Select } from '../ui/Select/Select';
import s from './ProjectsFilter.module.scss';

export const ProjectsFilter = ({
  className,
  selectClassName,
  projects,
  selectedFpCode,
  onProjectChange,
  ...props
}) => {
  const options = projects.map((proj) => ({
    label: proj.name_full,
    value: +proj.project_code,
    ...proj
  }));

  const value = useMemo(() => {
    return options.find((opt) => +opt.value === +selectedFpCode);
  }, [selectedFpCode, options]);

  return (
    <div className={classNames(s.ProjectsFilter, className)}>
      <Select
        height={Select.SMALL}
        className={classNames(s.ProjectsFilter__select, selectClassName)}
        value={value}
        values={options}
        onChange={onProjectChange}
        {...props}
      />
    </div>
  );
};
