import L, { latLng, latLngBounds } from 'leaflet';
import proj4 from 'proj4';
import { scaleLinear } from 'd3';
import crsData from '../../crs';
import colors from '../../colors.scss';

proj4.defs([['lambert', crsData.lambert]]);

export function toLeafletBounds(bbox) {
  return latLngBounds(latLng(bbox[1], bbox[0]), latLng(bbox[3], bbox[2]));
}

export function projectLatLng(pnt) {
  return proj4(proj4.WGS84, 'lambert', [pnt.lng, pnt.lat]);
}

export function projectCoords(pnt) {
  return proj4(proj4.WGS84, 'lambert', pnt);
}

export function unprojectCoords(pnt) {
  return proj4('lambert', proj4.WGS84, pnt);
}

export function projectBounds(bbox) {
  return [
    projectLatLng({ lat: bbox._northEast.lat, lng: bbox._northEast.lng }),
    projectLatLng({ lat: bbox._southWest.lat, lng: bbox._southWest.lng })
  ];
}

export function toLeafletCoords(pnt) {
  return [pnt.y, pnt.x];
}

export function getBboxCenter(bbox) {
  return [(bbox[0][0] + bbox[1][0]) / 2, (bbox[0][1] + bbox[1][1]) / 2];
}

export function styleFunc(feature) {
  return {
    color: colors.mapBorder,
    weight: 1
  };
}

export function highlightFeature(e) {
  const layer = e.target;

  layer.setStyle({
    fillOpacity: 0.7
  });

  if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
    layer.bringToFront();
  }
}

export function resetHighlight(e) {
  e.target.setStyle({
    fillOpacity: 0.2
  });
}
