import gql from 'graphql-tag';

export const QUERY_GET_DISTRICTS = gql`
  query getDistricts($filter: OrganizationPageFilter) {
    federalDistricts {
      id
      name
      code
      count_organizations(filter: $filter) {
        count
        indicator_level
      }
    }
  }
`;

export const QUERY_RESULT_AND_INDICATOR_ACHIEVEMENT_DATE = gql`
  query getResultAndIndicatorActualityDate(
    $data_type: SubjectRatingDataTypesEnum!
    $level: SubjectRatingLevelsEnum!
    $yearMonth: String!
  ) {
    subjectRatingActualityDate(
      filter: { data_type: $data_type, level: $level, yearMonth: $yearMonth }
    )
  }
`;

export const QUERY_GET_DISTRICTS_WITH_FINANCIAL_RATINGS = gql`
  query getDistrictsWithFinancialRatings(
    $countOrganizationsFilter: OrganizationPageFilter
    $dcCompletionFilter: DcCompletionFilter
    $yearMonth: String!
    $federalProjectIds: [ID!]
    $internetSpeedFilter: AverageInternetSpeedFilter
  ) {
    districts: federalDistricts {
      id
      name
      code
      count_organizations(filter: $countOrganizationsFilter) {
        count
        indicator_level
      }
      average_internet_speed(filter: $internetSpeedFilter) {
        upload_speed
        download_speed
      }
    }
    dcCompletion(filter: $dcCompletionFilter, groupBy: [federal_district]) {
      federal_district_id
      dc_completion_percent
    }
    indicatorsRatings: subjectRatings(
      filter: {
        data_type: indicators_achievement_percentage
        level: federal_district
        yearMonth: $yearMonth
        federal_project_ids: $federalProjectIds
      }
    ) {
      subject_id
      settlement_date
      value
    }
    resultsRatings: subjectRatings(
      filter: {
        data_type: results_achievement_percentage
        level: federal_district
        yearMonth: $yearMonth
        federal_project_ids: $federalProjectIds
      }
    ) {
      subject_id
      settlement_date
      value
    }
    finances: financesGrouped(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        intersect_mbt: intersect
        federal_project_ids: $federalProjectIds
      }
      groupBy: [federal_district]
    ) {
      federal_district_id
      accept: obligations_percent
      cash_completion: cash_expenses_percent
    }
  }
`;

export const QUERY_GET_ACTUALITY_DATE_BY_FINANCE = gql`
  query getActualityDateByFinance(
    $federalProjectCodes: [String!]
    $grbsCodes: [String!]
    $federalDistrictCodes: [String!]
    $yearMonth: String
    $federalProjectIds: [ID!]
  ) {
    finances(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        federal_project_codes: $federalProjectCodes
        mbt_grbs_codes: $grbsCodes
        federal_district_codes: $federalDistrictCodes
        federal_project_ids: $federalProjectIds
      }
    ) {
      actuality_date_of_obligations
      actuality_date_of_total_budget
    }
  }
`;
