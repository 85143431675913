import React, { useEffect, useMemo } from 'react';
import { addDays } from 'date-fns';
import {
  calendarControlPointEventsAdapter,
  useCalendarControlPointEvents
} from '../../../store/useCalendarEvents';
import { ActualityDate } from '../../../common/NavBar/ActualityDate';
import { queryLoader } from '../../../common/QueryLoader';
import { RoadmapEventsInfo } from './RoadmapEventsInfo';
import {
  groupBy,
  groupsToArray,
  projectCodeSort,
  RoadmapEvent,
  StatusView
} from './Events';
import s from '../ReportPage.module.scss';

const ControlPointEventsList = ({ projects, statusGroups, eventsQuery }) => {
  // todo дата актуальности из gql
  const actualityDate = addDays(new Date(), -1);

  return (
    <div className={s.InfoGroup}>
      <h2 className={s.InfoGroup__title}>
        Сведения о выполнении мероприятий контрольных точек
      </h2>
      {queryLoader(eventsQuery) || (
        <>
          <ActualityDate
            className={s.InfoGroup__actualDate}
            variant={ActualityDate.Variant.DESCRIPTION}
            date={actualityDate}
          />
          <StatusView statusGroups={statusGroups} />
          <div>
            {projects &&
              projects.map((project, iProject) => {
                return (
                  <div className={s.project} key={iProject}>
                    <div className={s.project__name}>{project.name}</div>
                    <div className={s.project__roadmapEventsList}>
                      {project.controlPoints &&
                        project.controlPoints.map((controlPoint, iRoadmap) => {
                          return (
                            <div className={s.roadmapEventsList} key={iRoadmap}>
                              <div className={s.roadmapEventsList__name}>
                                {controlPoint.name}
                              </div>
                              <div className={s.roadmapEventsList__title}>
                                {controlPoint.title}
                              </div>
                              <ul className={s.roadmapEventsList__list}>
                                {controlPoint.events.map((event, i) => (
                                  <RoadmapEvent
                                    key={i}
                                    isControlPoints
                                    event={event}
                                  />
                                ))}
                              </ul>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </div>
  );
};

function ControlPointEventsInfo({
  yearMonth,
  federalProject,
  onLoadControlPointEvent = () => {}
}) {
  const eventsQuery = useCalendarControlPointEvents({
    yearMonth,
    federalProjects: federalProject && [federalProject.id]
  });

  const events = useMemo(() => calendarControlPointEventsAdapter(eventsQuery), [
    eventsQuery
  ]);

  useEffect(() => {
    if (!eventsQuery.check.spinner) {
      onLoadControlPointEvent(true);
    }
  }, [eventsQuery.check.spinner, onLoadControlPointEvent]);

  const projects = useMemo(() => {
    const controlPoints = Object.values(
      groupBy(
        [...events].sort((a, b) => a.date - b.date),
        (e) => (e.owner && e.owner.id) || 'null',
        (e) => ({
          ...e.owner,
          project: e.project
        }),
        'events'
      )
    );
    return groupsToArray(
      groupBy(
        controlPoints,
        (e) => (e.project && e.project.id) || 'null',
        (e) => e.project,
        'controlPoints'
      ),
      projectCodeSort
    );
  }, [events]);

  const statusGroups = useMemo(() => {
    const result = {};
    for (const event of events) {
      const status = event.status || 'null';
      if (!result[event.status]) {
        result[event.status] = 1;
      } else {
        result[event.status]++;
      }
    }
    return result;
  }, [events]);

  return (
    <div>
      <ControlPointEventsList
        projects={projects}
        statusGroups={statusGroups}
        eventsQuery={eventsQuery}
      />
    </div>
  );
}

export function InfographicEvents(props) {
  return (
    <div>
      <RoadmapEventsInfo {...props} />
      <ControlPointEventsInfo {...props} />
    </div>
  );
}
