import gql from 'graphql-tag';

const FRAGMENT_FINANCES = gql`
  fragment finances on FinancesType {
    total_budget
    budget_limits
    cash_expenses
    obligations
    actuality_date_of_obligations
    actuality_date_of_total_budget
  }
`;

const FRAGMENT_FINANCES_GROUPED = gql`
  fragment financesGrouped on FinancesGroupedType {
    total_budget
    budget_limits
    cash_expenses
    obligations
    actuality_date_of_obligations
    actuality_date_of_total_budget
  }
`;

export const QUERY_FINANCES_BUDGET = gql`
  query getFinancesBudget(
    $yearQuarter: String
    $yearMonth: String
    $federal_project_ids: [ID!]
    $federal_project_codes: [String!]
    $grbs_codes: [String!]
    $expensesDirectionIds: [ID!]
  ) {
    federalDate: financesActualityDate(
      filter: { month: $yearMonth, quarter: $yearQuarter }
    )
    federal: finances(
      filter: {
        month: $yearMonth
        quarter: $yearQuarter
        federal_project_ids: $federal_project_ids
        grbs_codes: $grbs_codes
      }
    ) {
      ...finances
    }
    mbt: finances(
      filter: {
        month: $yearMonth
        quarter: $yearQuarter
        federal_project_ids: $federal_project_ids
        expenses_type_code_prefixes: "500"
        grbs_codes: $grbs_codes
      }
    ) {
      ...finances
    }
    pof: pofSumGrouped(
      input: {
        filter: {
          yearMonth: $yearMonth
          federal_project_codes: $federal_project_codes
          expenses_direction_ids: $expensesDirectionIds
        }
        groupBy: [federal_project]
      }
    ) {
      region_id
      sum
    }
    grants: finances_grants_and_subsidies(
      filter: {
        month: $yearMonth
        quarter: $yearQuarter
        federal_project_ids: $federal_project_ids
        expenses_type_level: 1
        grbs_codes: $grbs_codes
      }
    ) {
      grants {
        total_budget
        cash_expenses
        actuality_date_of_obligations
        actuality_date_of_total_budget
      }
      subsidies_regions {
        total_budget
        cash_expenses
        actuality_date_of_obligations
        actuality_date_of_total_budget
      }
      subsidies_organizations {
        total_budget
        cash_expenses
        actuality_date_of_obligations
        actuality_date_of_total_budget
      }
    }
    federalTypes: financesGrouped(
      filter: {
        month: $yearMonth
        quarter: $yearQuarter
        federal_project_ids: $federal_project_ids
        expenses_type_level: 1
        grbs_codes: $grbs_codes
      }
      groupBy: [expenses_type]
    ) {
      ...financesGrouped
      expenses_type_id
      expenses_type {
        id
        code
        name_full
        name_short
      }
    }
    mbtSubjects: mbtFinances(
      filter: {
        month: $yearMonth
        quarter: $yearQuarter
        federal_project_ids: $federal_project_ids
        grbs_codes: $grbs_codes
      }
    ) {
      otfg_kr_total
      otfg_kr
      plan
    }
    subjectTypes: financesGrouped(
      filter: {
        month: $yearMonth
        quarter: $yearQuarter
        federal_project_ids: $federal_project_ids
        budget_levels: [subject]
        intersect_mbt: intersect
        mbt_grbs_codes: $grbs_codes
      }
      groupBy: [expenses_type_dash]
    ) {
      ...financesGrouped
      expenses_type_id
      expenses_type {
        id
        code
        name_full
        name_short
      }
    }
  }

  ${FRAGMENT_FINANCES}
  ${FRAGMENT_FINANCES_GROUPED}
`;

export const QUERY_MAIN_GRBS = gql`
  query getMainGrbs($yearMonth: String!) {
    grbs: financesGrouped(filter: { month: $yearMonth }, groupBy: [grbs]) {
      total_budget
      budget_limits
      cash_expenses
      obligations
      grbs_id
      grbs {
        id
        code
        name_full
      }
    }
  }
`;

export const FRAGMENT_FINANCES_GROUPED_REGION = gql`
  fragment FinancesGroupedRegion on FinancesGroupedType {
    total_budget
    budget_limits
    cash_expenses
    obligations
    region_id
    region {
      id
      code
      code_nalog
      name
      federal_district_id
    }
  }
`;

export const FRAGMENT_FINANCES_GROUPED_DISTRICT = gql`
  fragment FinancesGroupedDistrict on FinancesGroupedType {
    total_budget
    budget_limits
    cash_expenses
    obligations
    federal_district_id
    federal_district {
      id
      code
      name
    }
  }
`;

export const QUERY_FINANCES_MAIN = gql`
  query getFinancesMain($yearMonth: String!) {
    federalDate: financesActualityDate(
      filter: { month: $yearMonth, budget_levels: [federal] }
    )
    subjectDate: financesActualityDate(
      filter: { month: $yearMonth, budget_levels: [subject] }
    )
    federal: finances(filter: { month: $yearMonth }) {
      total_budget
      budget_limits
      cash_expenses
      obligations
    }
    subjects: finances(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        intersect_mbt: intersect
      }
    ) {
      total_budget
      budget_limits
      cash_expenses
      obligations
    }
    mbt: finances(
      filter: { month: $yearMonth, expenses_type_code_prefixes: "500" }
    ) {
      total_budget
      budget_limits
      cash_expenses
      obligations
    }
    projects: financesGrouped(
      filter: { month: $yearMonth }
      groupBy: [federal_project]
    ) {
      total_budget
      budget_limits
      cash_expenses
      obligations
      federal_project_id
      federal_project {
        id
        project_code
        name_full
      }
    }
    projectsMbt: financesGrouped(
      filter: { month: $yearMonth, expenses_type_code_prefixes: "500" }
      groupBy: [federal_project]
    ) {
      total_budget
      budget_limits
      cash_expenses
      obligations
      federal_project_id
    }
    projectsSubject: financesGrouped(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        intersect_mbt: intersect
      }
      groupBy: [federal_project]
    ) {
      total_budget
      budget_limits
      cash_expenses
      obligations
      federal_project_id
      federal_project {
        id
        project_code
        name_full
      }
    }
    federalTypes: financesGrouped(
      filter: { month: $yearMonth, expenses_type_level: 1 }
      groupBy: [expenses_type]
    ) {
      total_budget
      budget_limits
      cash_expenses
      obligations
      expenses_type_id
      expenses_type {
        id
        code
        name_full
        name_short
      }
    }
    subjectTypes: financesGrouped(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        intersect_mbt: intersect
      }
      groupBy: [expenses_type_dash]
    ) {
      total_budget
      budget_limits
      cash_expenses
      obligations
      expenses_type_id
      expenses_type {
        id
        code
        name_full
        name_short
      }
    }
    mbtSubjects: mbtFinances(filter: { month: $yearMonth }) {
      plan
      otfg_received
      otfg_kr_total
    }
  }
`;

export const QUERY_GET_MAIN_SUBJECT_FINANCES = gql`
  query getMainSubjectFinances($yearMonth: String!) {
    subjectDate: financesActualityDate(
      filter: { month: $yearMonth, budget_levels: [subject] }
    )
    subjectRegions: financesGrouped(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        intersect_mbt: intersect
      }
      groupBy: [region]
    ) {
      ...FinancesGroupedRegion
    }
    subjectDistricts: financesGrouped(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        intersect_mbt: intersect
      }
      groupBy: [federal_district]
    ) {
      ...FinancesGroupedDistrict
    }
    subjectRbRegions: financesGrouped(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        intersect_mbt: exclude
      }
      groupBy: [region]
    ) {
      ...FinancesGroupedRegion
    }
    subjectRbDistricts: financesGrouped(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        intersect_mbt: exclude
      }
      groupBy: [federal_district]
    ) {
      ...FinancesGroupedDistrict
    }
    subjectMbtRegions: mbtFinancesGrouped(
      filter: { month: $yearMonth }
      groupBy: [region]
    ) {
      plan
      otfg_received
      otfg_kr_total
      region_id
    }
    subjectMbtDistricts: mbtFinancesGrouped(
      filter: { month: $yearMonth }
      groupBy: [federal_district]
    ) {
      plan
      otfg_received
      otfg_kr_total
      federal_district_id
    }
  }

  ${FRAGMENT_FINANCES_GROUPED_DISTRICT}
  ${FRAGMENT_FINANCES_GROUPED_REGION}
`;

export const QUERY_GET_FINANCES_YEARLY = gql`
  query getFinancesYearly(
    $federal_project_codes: [String!]
    $region_ids: [ID!]
    $budget_levels: [FinancesBudgetLevel!]
    $intersect_mbt: IntersectMbtType
  ) {
    actualityDate: financesActualityDate(
      filter: {
        federal_project_codes: $federal_project_codes
        region_ids: $region_ids
        budget_levels: $budget_levels
        intersect_mbt: $intersect_mbt
      }
    )
    data: financesDynamicYearly(
      filter: {
        federal_project_codes: $federal_project_codes
        region_ids: $region_ids
        budget_levels: $budget_levels
        intersect_mbt: $intersect_mbt
      }
    ) {
      year
      total_budget
      budget_limits
      cash_expenses
      cash_expenses_percent
      obligations
      obligations_percent
      obligations
    }
  }
`;

export const QUERY_GET_RESULT_FINANCES = gql`
  query getResultFinances(
    $resultIds: [ID!]
    $yearMonth: String
    $year: Int!
    $grbsCodes: [String!]
    $expensesDirectionIds: [ID!]
    $regionCodeNalogs: [String!]
  ) {
    federalDate: financesActualityDate(
      filter: { month: $yearMonth, region_code_nalogs: $regionCodeNalogs }
    )
    monthly: financesDynamicMonthly(
      filter: {
        result_ids: $resultIds
        region_code_nalogs: $regionCodeNalogs
        budget_levels: [subject]
      }
      year: $year
    ) {
      year
      month
      total_budget
      budget_limits
      cash_expenses
      obligations
      actuality_date_of_obligations
      actuality_date_of_total_budget
    }
    grants: finances_grants_and_subsidies(
      filter: {
        result_ids: $resultIds
        month: $yearMonth
        grbs_codes: $grbsCodes
        region_code_nalogs: $regionCodeNalogs
      }
    ) {
      grants {
        total_budget
        cash_expenses
        actuality_date_of_obligations
        actuality_date_of_total_budget
      }
      subsidies_regions {
        total_budget
        cash_expenses
        actuality_date_of_obligations
        actuality_date_of_total_budget
      }
      subsidies_organizations {
        total_budget
        cash_expenses
        actuality_date_of_obligations
        actuality_date_of_total_budget
      }
    }
    federalTypes: financesGrouped(
      filter: {
        result_ids: $resultIds
        expenses_type_level: 1
        month: $yearMonth
        grbs_codes: $grbsCodes
        region_code_nalogs: $regionCodeNalogs
      }
      groupBy: [expenses_type_dash]
    ) {
      ...financesGrouped
      expenses_type_id
      expenses_type {
        id
        code
        name_full
        name_short
      }
    }
    subjectTypes: financesGrouped(
      filter: {
        result_ids: $resultIds
        month: $yearMonth
        budget_levels: [subject]
        expenses_direction_ids: $expensesDirectionIds
        mbt_grbs_codes: $grbsCodes
        region_code_nalogs: $regionCodeNalogs
      }
      groupBy: [expenses_type_dash]
    ) {
      ...financesGrouped
      expenses_type_id
      expenses_type {
        id
        code
        name_full
        name_short
      }
    }
    localTypes: financesGrouped(
      filter: {
        result_ids: $resultIds
        month: $yearMonth
        budget_levels: [local]
        mbt_grbs_codes: $grbsCodes
        region_code_nalogs: $regionCodeNalogs
      }
      groupBy: [expenses_type_dash]
    ) {
      ...financesGrouped
      expenses_type_id
      expenses_type {
        id
        code
        name_full
        name_short
      }
    }
    federal: finances(
      filter: {
        month: $yearMonth
        result_ids: $resultIds
        grbs_codes: $grbsCodes
        region_code_nalogs: $regionCodeNalogs
      }
    ) {
      ...finances
    }
    mbt: finances(
      filter: {
        month: $yearMonth
        result_ids: $resultIds
        expenses_type_code_prefixes: "500"
        grbs_codes: $grbsCodes
        region_code_nalogs: $regionCodeNalogs
      }
    ) {
      ...finances
    }
    mbtSubjects: mbtFinances(
      filter: {
        month: $yearMonth
        result_ids: $resultIds
        grbs_codes: $grbsCodes
        region_code_nalogs: $regionCodeNalogs
      }
    ) {
      otfg_kr_total
      otfg_kr
      plan
    }
  }

  ${FRAGMENT_FINANCES}
  ${FRAGMENT_FINANCES_GROUPED}
`;

export const QUERY_GET_REGION_FINANCES = gql`
  query getRegionFinances(
    $yearQuarter: String
    $yearMonth: String
    $regionIds: [ID!]
    $resultIds: [ID!]
    $federalProjectCodes: [String!]
    $grbsCodes: [String!]
    $expensesDirectionIds: [ID!]
  ) {
    federalDate: financesActualityDate(
      filter: {
        quarter: $yearQuarter
        month: $yearMonth
        result_ids: $resultIds
      }
    )
    dcCompletion(
      filter: {
        region_ids: $regionIds
        federal_project_codes: $federalProjectCodes
      }
      groupBy: [region]
    ) {
      region_id
      dc_completion_percent
    }
    types: financesGrouped(
      filter: {
        quarter: $yearQuarter
        month: $yearMonth
        region_ids: $regionIds
        result_ids: $resultIds
        federal_project_codes: $federalProjectCodes
        budget_levels: [subject]
        intersect_mbt: intersect
        mbt_grbs_codes: $grbsCodes
      }
      groupBy: [expenses_type_dash]
    ) {
      ...financesGrouped
      expenses_type_id
      expenses_type {
        id
        code
        name_full
        name_short
      }
    }
    pof: pofSumGrouped(
      input: {
        filter: {
          yearMonth: $yearMonth
          region_ids: $regionIds
          expenses_direction_ids: $expensesDirectionIds
          federal_project_codes: $federalProjectCodes
        }
        groupBy: [region]
      }
    ) {
      region_id
      sum
    }
    total: finances(
      filter: {
        quarter: $yearQuarter
        month: $yearMonth
        region_ids: $regionIds
        result_ids: $resultIds
        federal_project_codes: $federalProjectCodes
        budget_levels: [subject]
        intersect_mbt: intersect
        mbt_grbs_codes: $grbsCodes
      }
    ) {
      ...finances
      accept: obligations_percent
      cash_completion: cash_expenses_percent
    }

    sources: mbtFinances(
      filter: {
        quarter: $yearQuarter
        month: $yearMonth
        region_ids: $regionIds
        result_ids: $resultIds
        federal_project_codes: $federalProjectCodes
        grbs_codes: $grbsCodes
      }
    ) {
      otfg_received
      otfg_kr_total
      plan
    }
    local: finances(
      filter: {
        quarter: $yearQuarter
        month: $yearMonth
        region_ids: $regionIds
        result_ids: $resultIds
        federal_project_codes: $federalProjectCodes
        budget_levels: [local]
        mbt_grbs_codes: $grbsCodes
        intersect_mbt: intersect
      }
    ) {
      ...finances
    }
  }

  ${FRAGMENT_FINANCES}
  ${FRAGMENT_FINANCES_GROUPED}
`;

export const QUERY_GET_REGION_INDICATORS = gql`
  query getMbtFinancesGrouped(
    $yearQuarter: String
    $yearMonth: String
    $federal_project_codes: [String!]
    $grbs_codes: [String!]
  ) {
    subjectDate: financesActualityDate(
      filter: {
        quarter: $yearQuarter
        month: $yearMonth
        budget_levels: [subject]
      }
    )
    pof: pofSumGrouped(
      input: {
        filter: {
          yearMonth: $yearMonth
          federal_project_codes: $federal_project_codes
        }
        groupBy: [region]
      }
    ) {
      region_id
      sum
    }
    mbt: mbtFinancesGrouped(
      groupBy: [region]
      filter: {
        quarter: $yearQuarter
        month: $yearMonth
        federal_project_codes: $federal_project_codes
        grbs_codes: $grbs_codes
      }
    ) {
      region_id
      region {
        id
        code
        code_nalog
        name
      }
      otfg_kr_total
      plan
      otfg_received
    }
    subjects: financesGrouped(
      groupBy: [region]
      filter: {
        quarter: $yearQuarter
        month: $yearMonth
        federal_project_codes: $federal_project_codes
        budget_levels: [subject]
        intersect_mbt: intersect
        mbt_grbs_codes: $grbs_codes
      }
    ) {
      region_id
      total_budget
      budget_limits
      cash_expenses
      obligations
    }
  }
`;

export const QUERY_GET_SUBJECT_FINANCE = gql`
  query getFinancesGrouped($region_ids: [ID!], $yearMonth: String!) {
    financesGrouped(
      filter: {
        month: $yearMonth
        region_ids: $region_ids
        budget_levels: [subject]
      }
      groupBy: [expenses_type_dash]
    ) {
      total_budget
      budget_limits
      cash_expenses
      obligations
      expenses_type_id
      expenses_type {
        id
        code
        name_full
        name_short
      }
    }
  }
`;

export const QUERY_GET_SUBJECT_FINANCE_AND_RATING_BY_FP = gql`
  query getFinancesAndRatingGrouped(
    $regionIds: [ID!]
    $month: String!
    $quarter: String
    $dcCompletionFilter: DcCompletionFilter
  ) {
    financesGrouped(
      filter: {
        quarter: $quarter
        month: $month
        region_ids: $regionIds
        budget_levels: [subject]
        intersect_mbt: intersect
      }
      groupBy: [federal_project]
    ) {
      cash_expenses_percent
      obligations_percent
      federal_project_id
      actuality_date_of_obligations
      actuality_date_of_total_budget
    }
    resultRatingActualityDate: subjectRatingActualityDate(
      filter: {
        data_type: results_achievement_percentage
        level: regional_project
        yearMonth: $month
      }
    )
    resultRating: subjectRatings(
      filter: {
        data_type: results_achievement_percentage
        level: regional_project
        yearMonth: $month
        region_ids: $regionIds
      }
    ) {
      regional_project {
        id
        name
        name_full
        federal_project_id
      }
      value
    }
    dcCompletion(filter: $dcCompletionFilter, groupBy: [regional_project]) {
      dc_completion_percent
      regional_project {
        id
        federal_project_id
        name
      }
    }
    indicatorRatingActualityDate: subjectRatingActualityDate(
      filter: {
        data_type: indicators_achievement_percentage
        level: regional_project
        yearMonth: $month
      }
    )
    indicatorRating: subjectRatings(
      filter: {
        data_type: indicators_achievement_percentage
        level: regional_project
        yearMonth: $month
        region_ids: $regionIds
      }
    ) {
      regional_project {
        id
        name
        name_full
        federal_project_id
      }
      value
    }
  }
`;

export const QUERY_GET_FEDERAL_DATE = gql`
  query getFederalDate(
    $yearQuarter: String
    $yearMonth: String
    $budgetLevels: [FinancesBudgetLevel!]
  ) {
    financesActualityDate(
      filter: {
        quarter: $yearQuarter
        month: $yearMonth
        budget_levels: $budgetLevels
      }
    )
  }
`;
