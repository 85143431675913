import React from 'react';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import {
  InfoItemsList,
  MultiInfoItem,
  SingleInfoItem,
  TitleInfoItem
} from '../../../common/InfoItem/InfoItem';
import IconLoc from '../../../assets/img/icons/objectAbout/i-loc.svg';
import IconFlag from '../../../assets/img/icons/objectAbout/i-flag.svg';
import IconPhone from '../../../assets/img/icons/objectAbout/i-phone.svg';
import IconLetter from '../../../assets/img/icons/objectAbout/i-letter.svg';
import IconAccess from '../../../assets/img/icons/objectAbout/i-access.svg';
import IconPerson from '../../../assets/img/icons/objectAbout/i-person.svg';
import { SubsidiaryEntity } from '../../../data/subsidiaryEntity';
import { Individual } from '../../../data/individual';
import s from '../../ObjectPage/ObjectAbout/ObjectAbout.module.scss';

export const SubsidiaryEntityAbout = ({ subsidiaryEntity }) => {
  const { address, supervisor } = subsidiaryEntity;
  const displayAddress =
    SubsidiaryEntity.combineAddress(subsidiaryEntity) || address;
  const supervisorName = Individual.combineName(supervisor);

  return (
    <div className={s.ObjectAbout}>
      <PageSubTitle
        className={s.ObjectAbout__title}
        text={subsidiaryEntity.name_full}
      />
      <div className={s.ObjectAbout__infos}>
        <InfoItemsList>
          <TitleInfoItem
            icon={IconFlag}
            item={subsidiaryEntity.year_of_creation}
            title="Год создания"
          />
          <SingleInfoItem icon={IconLoc} item={displayAddress} />
          <SingleInfoItem
            icon={IconAccess}
            item={subsidiaryEntity.inn ? `ИНН ${subsidiaryEntity.inn}` : null}
          />
          <SingleInfoItem
            icon={IconAccess}
            item={subsidiaryEntity.kpp ? `КПП ${subsidiaryEntity.kpp}` : null}
          />
          <TitleInfoItem
            icon={IconPerson}
            item={supervisorName}
            title="Руководитель"
          />
          <MultiInfoItem icon={IconPhone} items={supervisor?.phones} />
          <MultiInfoItem icon={IconLetter} items={supervisor?.emails} />
        </InfoItemsList>
      </div>
    </div>
  );
};
