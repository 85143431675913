import React from 'react';
import { PageSubTitle } from '../../../common/PageTitle/PageTitle';
import {
  InfoItemsList,
  MultiInfoItem,
  PersonItem,
  SingleInfoItem
} from '../../../common/InfoItem/InfoItem';
import IconPhone from '../../../assets/img/icons/objectAbout/i-phone.svg';
import IconLetter from '../../../assets/img/icons/objectAbout/i-letter.svg';
import s from './SubjectInfo.module.scss';

export const SubjectInfo = ({ persons, countSmallSchools }) => {
  return (
    <div className={s.SubjectInfo}>
      {persons.length > 0 ? (
        <div>
          <PageSubTitle className={s.SubjectInfo__title} text={'Информация'} />
          <div className={s.SubjectInfo__items}>
            <InfoItemsList>
              {persons.map((person, i) => (
                <PersonItem key={i} title={person.title} name={person.name}>
                  <SingleInfoItem icon={IconPhone} item={person.phone} />
                  <MultiInfoItem icon={IconLetter} items={person.emails} />
                </PersonItem>
              ))}
            </InfoItemsList>
          </div>
        </div>
      ) : null}
      {countSmallSchools && (
        <div className={s.SchoolsInfo}>
          <PageSubTitle text={'Количество малокомплектных школ'} />
          <div className={s.SchoolsInfo__countSmallSchools}>
            {countSmallSchools}
          </div>
        </div>
      )}
    </div>
  );
};
