import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import ReactSlick from 'react-slick';
import s from './ChartViewTabsSlider.module.scss';

export function ChartViewTabsSlider({
  className,
  selectedIndex,
  onChangeIndex,
  children
}) {
  const slickRef = useRef(null);
  const currentSlide = useRef(selectedIndex);
  const initialSlideRef = useRef(selectedIndex);
  const initialSlide = initialSlideRef.current;

  useEffect(() => {
    if (slickRef.current) {
      const slideIndex = currentSlide.current;
      if (selectedIndex !== slideIndex) {
        slickRef.current.slickGoTo(selectedIndex);
      }
    }
  }, [selectedIndex]);

  const onBeforeChange = useCallback(
    (v, nv) => {
      if (currentSlide.current !== nv) {
        currentSlide.current = nv;
        onChangeIndex(nv);
      }
    },
    [onChangeIndex]
  );

  return (
    <div className={classNames(s.ChartViewTabsSlider, className)}>
      <ReactSlick
        className={s.Slick}
        ref={slickRef}
        dots={false}
        arrows={false}
        slidesToShow={1}
        slidesToScroll={1}
        centerPadding={0}
        swipeToSlide
        focusOnSelect
        initialSlide={initialSlide}
        variableWidth={true}
        // infinite={false}
        beforeChange={onBeforeChange}
      >
        {children}
      </ReactSlick>
    </div>
  );
}
