import gql from 'graphql-tag';

export const QUERY_GET_INVENTORY = gql`
  query inventory($region_id: ID, $organization_id: ID) {
    objectsInventory(region_id: $region_id, organization_id: $organization_id) {
      count_hours_idle
      count_objects_hours_idle
      objects_area

      object_type
      period {
        id
        name
        start
      }
    }
    specialistsInventory(
      region_id: $region_id
      organization_id: $organization_id
    ) {
      avg_additional_hours_weekly
      count_additional_hours
      count_specialists_ready

      period {
        id
        name
        start
      }
    }
  }
`;
