import React from 'react';
import { HorizontalScroll } from '../../../common/HorizontalScroll/HorizontalScroll';
import { CardStatus } from '../../../common/ui/CardSmall/CardStatus';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { usePreserveQuery } from '../../../store/usePreserveQuery';
import s from './CardsScroll.module.scss';

export const CardsScroll = ({ cards, isMobile }) => {
  const { createLink } = usePreserveQuery();

  if (!cards) {
    return <CircleProgressIndicator />;
  }

  return (
    <div className={s.CardsScroll}>
      <HorizontalScroll
        height={130}
        containerCN={s.CardsScroll__scroll}
        listCN={s.CardsScroll__list}
        variant={HorizontalScroll.DARKER}
        buttonPosition={HorizontalScroll.INSIDE}
        disableArrow={isMobile}
        scrollItems={isMobile ? 1 : 3}
      >
        {cards.map((card, i) => (
          <div key={i} className={s.CardsScroll__item}>
            <CardStatus
              to={createLink(`/map`, {
                indicator_type: card.indicator
              })}
              description={card.description}
              title={card.title}
              status={card.status}
              loading={!card}
            />
          </div>
        ))}
      </HorizontalScroll>
    </div>
  );
};
