import React from 'react';
import { CircleProgressIndicator } from './ui/ProgressIndicator/CircleProgressIndicator';
import { ErrorMessage } from './ErrorMessage';

export function QueryLoader({ query, render }) {
  return query.check.spinner ? (
    <CircleProgressIndicator />
  ) : query.check.error ? (
    <ErrorMessage error={query.state.error} onReload={query.load} />
  ) : (
    render()
  );
}

export function queryLoader(query, options = {}) {
  if (!query) return null;
  const { onlyFirst, className } = options;
  const spinner = onlyFirst
    ? query.state.loading && !query.state.loaded
    : query.check.spinner;
  return spinner ? (
    <CircleProgressIndicator className={className} />
  ) : query.check.error ? (
    <ErrorMessage error={query.state.error} onReload={query.load} />
  ) : null;
}
