import gql from 'graphql-tag';
import { FRAGMENT_FEDERAL_RESULT_FOR_RESULT_PAGE } from './federalProjects';

export const QUERY_SEARCH_RESULTS = gql`
  query searchResults($input: FederalResultsPageInput!) {
    resultFederals(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        id
        name
        name_full
      }
    }
  }
`;

export const QUERY_SEARCH_RESULT_REGIONALS = gql`
  query searchResultRegionals($input: RegionalResultsPageInput!) {
    resultRegionals(input: $input) {
      header {
        hasNext
        onePage
        page
        totalCount
      }
      list {
        id
        name
        name_full
      }
    }
  }
`;

export const QUERY_SEARCH_ROADMAPS = gql`
  query searchRoadmaps($filter: RoadmapFilterInput) {
    federalProjectRoadmaps(filter: $filter) {
      id
      name_full
    }
  }
`;

export const QUERY_REGIONAL_RESULT = gql`
  query resultRegional($filter: RegionalResultFilter!) {
    resultRegional(filter: $filter) {
      id
      name
      name_full
      values {
        plan_data_id
        value_number
        text
        period {
          id
          name
          start
          end
        }
      }
      fact_values {
        fact_data_id
        value_number
        text
        period {
          id
          name
          start
          end
        }
      }
      result_federal {
        ...federalResult
      }
    }
  }

  ${FRAGMENT_FEDERAL_RESULT_FOR_RESULT_PAGE}
`;
