import gql from 'graphql-tag';

export const QUERY_GET_REGIONS = gql`
  query getRegions($filter: OrganizationPageFilter) {
    regions {
      id
      name
      federal_district_id
      code
      code_nalog
      code_okato
      count_organizations(filter: $filter) {
        count
        indicator_level
      }
    }
  }
`;

export const QUERY_GET_REGIONS_WITH_FINANCIAL_RATINGS = gql`
  query getRegionsRatings(
    $yearMonth: String!
    $federalProjectCodes: [String!]
    $grbsCodes: [String!]
    $federalDistrictCodes: [String!]
    $countOrganizationsFilter: OrganizationPageFilter
    $internetSpeedFilter: AverageInternetSpeedFilter
    $dcCompletionFilter: DcCompletionFilter
    $levelRating: SubjectRatingLevelsEnum!
  ) {
    actualityDate: financesActualityDate(
      filter: { month: $yearMonth, budget_levels: [subject] }
    )
    regions {
      id
      name
      federal_district_id
      code
      code_nalog
      code_okato
      count_organizations(filter: $countOrganizationsFilter) {
        count
        indicator_level
      }
      average_internet_speed(filter: $internetSpeedFilter) {
        upload_speed
        download_speed
      }
    }
    indicatorsRatings: subjectRatings(
      filter: {
        data_type: indicators_achievement_percentage
        level: $levelRating
        yearMonth: $yearMonth
        federal_project_codes: $federalProjectCodes
      }
    ) {
      subject_id
      settlement_date
      value
    }
    indicatorsRatingActualityDate: subjectRatingActualityDate(
      filter: {
        data_type: indicators_achievement_percentage
        level: $levelRating
        yearMonth: $yearMonth
      }
    )
    resultsRatings: subjectRatings(
      filter: {
        data_type: results_achievement_percentage
        level: $levelRating
        yearMonth: $yearMonth
        federal_project_codes: $federalProjectCodes
      }
    ) {
      subject_id
      settlement_date
      value
    }
    resultsRatingActualityDate: subjectRatingActualityDate(
      filter: {
        data_type: results_achievement_percentage
        level: $levelRating
        yearMonth: $yearMonth
      }
    )
    dcCompletion(filter: $dcCompletionFilter, groupBy: [region]) {
      region_id
      dc_completion_percent
    }
    finances: financesGrouped(
      filter: {
        month: $yearMonth
        budget_levels: [subject]
        intersect_mbt: intersect
        federal_project_codes: $federalProjectCodes
        mbt_grbs_codes: $grbsCodes
        federal_district_codes: $federalDistrictCodes
      }
      groupBy: [region]
    ) {
      region_id
      actuality_date_of_obligations
      actuality_date_of_total_budget
      accept: obligations_percent
      cash_completion: cash_expenses_percent
    }
    mbtRegionalProject: mbtFinancesRegionalProjects(
      filter: { federal_project_codes: $federalProjectCodes, month: $yearMonth }
    ) {
      id
      region_id
    }
  }
`;

export const QUERY_GET_REGIONS_WITH_RP = gql`
  query getRegionsWithRP {
    regions {
      id
      name
      federal_district_id
      code
      code_nalog
      code_okato
      regional_projects {
        id
        name
        federal_project_id
      }
    }
  }
`;

export const QUERY_GET_REGION = gql`
  query region(
    $code_nalog: String!
    $averageSpeed: AverageInternetSpeedFilter
  ) {
    region(code_nalog: $code_nalog) {
      id
      name
      federal_district_id
      federal_district {
        id
        code
      }
      code
      code_nalog
      code_okato
      average_internet_speed(filter: $averageSpeed) {
        upload_speed
        download_speed
      }
      count_organizations(filter: { is_small_school: true }) {
        count
        indicator_level
      }
      minister {
        id
        name
        emails
        individual_data {
          occupation
        }
      }
      cusers {
        id
        description
        emails
      }
      regional_projects {
        id
        code
        name
        name_full
        federal_project_id
        federal_project {
          id
          code
          project_code
          name_full
        }
      }
    }
  }
`;

export const QUERY_GET_REGION_STATS = gql`
  query regionStats($regionId: ID!, $periodType: PeriodTypeEnum) {
    regionStats(regionId: $regionId, periodType: $periodType) {
      region_id
      row_id
      line_number
      period_id
      period {
        id
        name
        custom
        start
        end
        type
      }

      share_of_education_expenses
      count_youth
      population
      population_0_3
      population_3_7
      population_5_18
      population_18_25

      rel_avg_wage_teacher_region
      avg_wage_teacher
      count_students
      count_students_city
      count_students_county
      count_students_shifts
      count_students_shift1
      count_students_shift2
      count_students_shift3
      count_teachers
      count_directors
      count_additional

      d_availability_kinder_0_3
      d_availability_kinder_3_7
      d_count_area_students_0_3
      d_count_area_students_3_7
      d_rel_avg_wage_teacher_region
      d_avg_wage_teacher
      d_count_students
      d_count_students_city
      d_count_students_county
      d_count_nom_students_0_3
      d_count_nom_students_3_7
      d_count_acc_students_0_3
      d_count_acc_students_3_7
      d_count_teacher

      s_avg_wage_teacher
      s_count_staff
      s_count_teachers
      s_count_directors
      s_count_students
      s_count_additional
      s_avg_teacher_salary_to_avg_monthly_salary_ration

      v_rel_avg_wage_teacher_region
      v_avg_wage_teacher
      v_count_admins
      v_count_engineers
      v_count_scientists
      v_count_additional
      v_count_producers
      v_count_professors
      v_count_directors
      v_count_students
      v_count_helpers

      dpo_count_students
      dpo_count_organizations
    }
  }
`;

export const QUERY_GET_PURCHASE_FINANCES = gql`
  query getPurchaseFinances($regionId: ID!) {
    data: purchaseFinances(filter: { region_ids: [$regionId] }) {
      financing_sfb
      contract_summ_sfb
    }
    schedule: groupedPurchaseScheduleSum(
      filter: { region_ids: [$regionId] }
      group_by: [region_id]
    ) {
      region_id
      sum
    }
  }
`;

export const QUERY_GET_REGION_RATING_ACHIEVEMENT = gql`
  query getRegionRatingAchievement(
    $yearMonth: String!
    $federalProjectCodes: [String!]
    $levelRating: SubjectRatingLevelsEnum!
    $regionIds: [ID!]
  ) {
    indicatorsRatingActualityDate: subjectRatingActualityDate(
      filter: {
        data_type: indicators_achievement_percentage
        level: $levelRating
        yearMonth: $yearMonth
      }
    )
    indicatorsRatings: subjectRatings(
      filter: {
        data_type: indicators_achievement_percentage
        level: $levelRating
        yearMonth: $yearMonth
        federal_project_codes: $federalProjectCodes
        region_ids: $regionIds
      }
    ) {
      subject_id
      settlement_date
      value
    }

    resultsRatingActualityDate: subjectRatingActualityDate(
      filter: {
        data_type: results_achievement_percentage
        level: $levelRating
        yearMonth: $yearMonth
      }
    )
    resultsRatings: subjectRatings(
      filter: {
        data_type: results_achievement_percentage
        level: $levelRating
        yearMonth: $yearMonth
        region_ids: $regionIds
        federal_project_codes: $federalProjectCodes
      }
    ) {
      subject_id
      settlement_date
      value
    }
  }
`;
