import { useRef } from 'react';
import { isSameDay } from 'date-fns';

export function useCurrentDate() {
  const currentDateRef = useRef(null);
  const newCurrentDate = new Date();
  if (
    !currentDateRef.current ||
    !isSameDay(newCurrentDate, currentDateRef.current)
  ) {
    currentDateRef.current = newCurrentDate;
  }
  return currentDateRef.current;
}
