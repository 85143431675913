import React from 'react';
import { useReportAuthDownload } from '../../../utils/useReportAuthDownload';
import { DownloadButtonLink } from './DownloadButtonLink';

export function DownloadReportButton({
  url,
  cookieLoader,
  onClick,
  onDownloadFinish,
  type,
  ...props
}) {
  const [downloadId, handleDownload, isLoading] = useReportAuthDownload({
    cookieLoader,
    onDownloadFinish,
    onClick,
    type,
    url
  });

  return (
    <DownloadButtonLink
      {...props}
      downloadId={downloadId}
      onClick={handleDownload}
      isLoading={isLoading}
    />
  );
}
