import React from 'react';
import classNames from 'classnames';
import { ReactComponent as IndicatorIcon } from '../../../assets/Icons/CircleEmpty.svg';
import { formatIndexValue } from '../../../utils/formatNumber';
import { ActualityDate } from '../../NavBar/ActualityDate';
import s from './TooltipCommon.module.scss';

export function NumberTooltip({ data, className, title, actualityDate }) {
  return (
    <div className={classNames(s.TooltipChart, className)}>
      {title ? <div className={s.TooltipChart__title}>{title}</div> : null}
      {data.map((item, index) => (
        <div
          key={`TooltipedChart_${item.id}_${index}`}
          className={s.TooltipChart__item}
        >
          <div className={s.TooltipChart__name}>
            <IndicatorIcon
              style={{ color: item.color }}
              className={s.TooltipChart__icon}
            />
            {item.name}
          </div>
          <div className={s.TooltipChart__value}>
            {formatIndexValue(item.value, item)} {item.unit}
          </div>
        </div>
      ))}
      {actualityDate &&
        (typeof actualityDate === 'function' ? (
          actualityDate()
        ) : (
          <ActualityDate
            className={s.TooltipChart__actualityDate}
            date={actualityDate}
            variant={ActualityDate.Variant.DESCRIPTION}
          />
        ))}
    </div>
  );
}
