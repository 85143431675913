import React from 'react';
import classNames from 'classnames';
import { ChartLegend } from './ChartLegend';
import s from './ChartWithLegend.module.scss';

export function ChartWithLegend({
  className,
  width,
  height,
  children,
  paddingTop = 16,
  paddingRight = 8,
  paddingBottom = 8,
  legendWidth = 48,
  legendPadding = 16,
  legendHeight = 16,
  ...props
}) {
  const chartLeft = legendWidth - legendPadding;

  return (
    <div
      className={classNames(s.ChartWithLegend, className)}
      style={{ width, height }}
    >
      <ChartLegend
        className={s.ChartWithLegend__legend}
        sizeHeight={height}
        sizeWidth={width}
        paddingTop={paddingTop}
        paddingBottom={legendHeight}
        legendWidth={legendWidth}
        legendPadding={legendPadding}
        lineRightPadding={paddingRight}
        {...props}
      />
      {children &&
        children({
          className: s.ChartWithLegend__chart,
          width: width - chartLeft,
          height: height - legendHeight + paddingBottom,
          paddingTop,
          paddingBottom,
          paddingLeft: legendPadding,
          paddingRight,
          style: {
            left: chartLeft
          }
        })}
    </div>
  );
}
