import React, { Fragment, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { useGraphqlQueryVars } from '@proscom/prostore-apollo-react';
import { max as d3max } from 'd3-array';
import { skipIfNull } from '@proscom/prostore';
import { ReactComponent as RFLogo } from '../../assets/Icons/russian_federation.svg';
import { useUrlQuery } from '../../utils/useUrlQuery';
import { filesUrl } from '../../config';
import {
  getCurrentMonth,
  getCurrentYear,
  getStatusByValue,
  yearNumbers
} from '../../utils/constants';
import { tryParseIso } from '../../utils/date';
import { TopBar } from '../../common/TopBar/TopBar';
import { TopBarAllProjects } from '../../common/TopBar/TopBarContent/TopBarProjects';
import { RightPaneLayout } from '../../common/GridLayout/RightPaneLayout';
import { FlyingBox } from '../../common/FlyingBox/FlyingBox';
import { PageTitle } from '../../common/PageTitle/PageTitle';
import { QUERY_GET_FINANCES_YEARLY } from '../../graphql/queries/finance';
import { Button } from '../../common/ui/Button/Button';
import { compareSimple, makeComparator } from '../../utils/data';
import { BudgetChart } from './BudgetChart';
import { BudgetChartRp } from './BudgetChartRp';
import { RoadmapsAndResultsCards } from './RoadmapsAndResultsCards/RoadmapsAndResultsCards';
import { GoalsFp } from './Goals/GoalsFp';
import { GoalsRp } from './Goals/GoalsRp';
import { fpFilesPath } from './filesPath';
import {
  ProgressView,
  ProgressViewAdditional,
  RF_VALUE,
  russia
} from './ProgressView/ProgressView';
import color from '../../colors.scss';
import s from './IndicatorsPage.module.scss';

const progressFinancesQueryOptions = {
  query: QUERY_GET_FINANCES_YEARLY,
  skipQuery: skipIfNull(null)
};

const ProgressViewButton = React.memo(function ProgressViewButton({
  selectedRegion,
  backgroundColor,
  onClick
}) {
  return (
    <Button
      className={classNames(s.ProgressViewButton, s[`_${backgroundColor}`])}
      onClick={onClick}
    >
      <div className={s.ProgressViewButton__icon}>
        <RFLogo />
      </div>
      <p className={s.ProgressViewButton__title}>{selectedRegion.label}</p>
    </Button>
  );
});

export function IndicatorsPageFpComponent({
  regionsQuery,
  fpCode,
  federalProject,
  selectedRegionalProject,
  setIsGoalsOpen,
  isGoalsOpen,
  onRightOpen,
  onRightClose,
  rightOpened,
  chartFilter,
  chartFilterRp,
  isMobile
}) {
  const regions = regionsQuery;
  const selectedFpCode = fpCode;
  const selectedFp = federalProject;

  const [query, changeQuery] = useUrlQuery();

  const regionsForSelect = useMemo(() => {
    if (!regions.state.data) return [];
    return [
      russia,
      ...regions.state.data
        .filter((item) =>
          item?.regional_projects?.some(
            (item) => item.federal_project_id === selectedFp.id
          )
        )
        .sort(makeComparator(compareSimple, (a) => a.name.toLowerCase()))
        .map((item) => ({
          label: item.name,
          value: item.code_nalog,
          region: item
        }))
    ];
  }, [regions, selectedFp.id]);

  const regionCode = query.region_code || null;
  const selectedRegion = useMemo(() => {
    if (!regions.state.data) {
      return russia;
    }

    const result =
      regionCode &&
      regionsForSelect.find((item) => +item.value === +regionCode);

    return result || russia;
  }, [regionCode, regions.state.data, regionsForSelect]);

  const downloadLinks = useMemo(() => {
    if (selectedRegion.value === RF_VALUE) {
      return fpFilesPath.federalProject.filter(
        (item) => item.fpCode === selectedFpCode
      );
    }
    return [
      {
        fileName: `docx`,
        url: `${filesUrl}/rp/РП. Е${selectedFpCode}. ${selectedRegion.value}. ${selectedRegion.label}.docx`
      }
    ];
  }, [selectedFpCode, selectedRegion]);

  const federalProjectCode = query.fp;
  const currentYear = +query.year || getCurrentYear();
  const financesQuery = useGraphqlQueryVars({
    queryOptions: progressFinancesQueryOptions,
    variableCreator: [
      () =>
        federalProjectCode
          ? selectedRegion && selectedRegion.region
            ? {
                federal_project_codes: federalProjectCode,
                region_ids: selectedRegion.region.id,
                budget_levels: 'subject',
                intersect_mbt: 'intersect'
              }
            : {
                federal_project_codes: federalProjectCode,
                budget_levels: 'federal'
              }
          : null,
      [federalProjectCode, selectedRegion]
    ]
  });
  const financesData = financesQuery.state.data;

  const actualityDate = financesData && tryParseIso(financesData.actualityDate);

  // По июнь включительно = false
  const isAfterJune = query.month ? query.month > 6 : getCurrentMonth() > 6;

  const financesChartData = useMemo(() => {
    if (!financesData || !financesData.data) return null;

    const currentYearData = financesData.data.find(
      (row) => row.year === currentYear
    );

    const bars = yearNumbers.map((year) => {
      const item = financesData.data.find((row) => row.year === year);
      if (!item) {
        return {
          id: year,
          title: year,
          values: [],
          actualityDate
        };
      }

      const statusColor = getStatusByValue(
        isAfterJune
          ? currentYearData && currentYearData.cash_expenses_percent
          : currentYearData && currentYearData.obligations_percent
      );

      return {
        id: year,
        title: year,
        values: [
          {
            id: 0,
            name: 'Кассовое исполнение',
            value: item.cash_expenses / 1e6,
            color: color[statusColor ? `${statusColor}Lighten3` : 'grayLighten']
          },
          {
            id: 1,
            name: 'Прин. бюдж. обяз.',
            value: item.obligations / 1e6,
            color: color[statusColor ? `${statusColor}Lighten2` : 'grayLighten']
          },
          {
            id: 2,
            name: 'План',
            value: item.total_budget / 1e6,
            color: color[statusColor ? `${statusColor}Lighten` : 'grayLighten']
          }
        ],
        actualityDate
      };
    });

    const max = d3max(bars, (b) => d3max(b.values, (v) => v.value));

    return {
      max,
      bars,
      cashExpensesPercent:
        currentYearData && currentYearData.cash_expenses_percent,
      obligationsPercent: currentYearData && currentYearData.obligations_percent
    };
  }, [financesData, currentYear, isAfterJune, actualityDate]);

  const backgroundColor =
    financesChartData &&
    getStatusByValue(
      isAfterJune
        ? financesChartData.cashExpensesPercent
        : financesChartData.obligationsPercent
    );

  const topBar = useMemo(
    () => (
      <TopBar>
        <TopBarAllProjects replaceQuery={true} />
      </TopBar>
    ),
    []
  );

  const handleBackClick = useCallback(() => changeQuery({ fp: 0 }, true), [
    changeQuery
  ]);

  const handleGoalsOpen = useCallback(() => setIsGoalsOpen(true), [
    setIsGoalsOpen
  ]);
  const handleGoalsClose = useCallback(() => setIsGoalsOpen(false), [
    setIsGoalsOpen
  ]);

  return (
    <RightPaneLayout
      topBar={topBar}
      right={
        <ProgressView
          regions={regions}
          loading={financesQuery.check.spinner}
          regionsForSelect={regionsForSelect}
          selectedRegion={selectedRegion}
          financesChartData={financesChartData}
          backgroundColor={backgroundColor}
          isAfterJune={isAfterJune}
          additional={
            !isMobile && (
              <ProgressViewAdditional
                handleOpenGoals={handleGoalsOpen}
                downloadLinks={downloadLinks}
              />
            )
          }
        />
      }
      extra={
        <Fragment>
          {selectedRegionalProject ? (
            <GoalsRp
              federalProjectId={federalProject && federalProject.id}
              regionalProjectId={selectedRegionalProject.id}
              onClose={handleGoalsClose}
              isActive={isGoalsOpen}
            />
          ) : (
            <GoalsFp
              federalProjectId={federalProject && federalProject.id}
              onClose={handleGoalsClose}
              isActive={isGoalsOpen}
            />
          )}
          {isMobile && (
            <FlyingBox>
              <ProgressViewButton
                selectedRegion={selectedRegion}
                financesChartData={financesChartData}
                backgroundColor={backgroundColor}
                onClick={onRightOpen}
              />
            </FlyingBox>
          )}
        </Fragment>
      }
      onRightClose={onRightClose}
      rightOpened={rightOpened}
    >
      <div className={s.IndicatorsPage__ChartView}>
        <PageTitle
          text={federalProject && federalProject.name_full}
          onClick={handleBackClick}
        />
        {!regionCode && chartFilter && (
          <BudgetChart
            wrapperClassName={s.BudgetChart__wrapper}
            filter={chartFilter}
          />
        )}
        {regionCode && chartFilterRp && (
          <BudgetChartRp
            wrapperClassName={s.BudgetChart__wrapper}
            filter={chartFilterRp}
          />
        )}
        <RoadmapsAndResultsCards
          federalProjectCode={federalProject?.project_code}
          regionCode={regionCode}
        />
        {isMobile && (
          <ProgressViewAdditional
            handleOpenGoals={handleGoalsOpen}
            downloadLinks={downloadLinks}
          />
        )}
      </div>
    </RightPaneLayout>
  );
}
