import React from 'react';
import Control from 'react-leaflet-control';
import { ReactComponent as PositionIcon } from '../../../assets/Icons/Position.svg';
import { Button } from '../../ui/Button/Button';
import s from './CommonMap.module.scss';

const ResetControl = ({ onClick }) => {
  return (
    <Control position="topleft">
      <Button
        className={s.ResetButton}
        onClick={onClick}
        variant={Button.DARKER}
      >
        <PositionIcon />
      </Button>
    </Control>
  );
};

export default ResetControl;
