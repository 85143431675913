import classNames from 'classnames';
import React from 'react';
import { ReactComponent as Circle } from '../../assets/Icons/CircleEmpty.svg';
import s from './IndexPage.module.scss';

export function BudgetAssignmentTable({ activeSegmentId, data, onClick }) {
  return (
    <table className={s.ChartTable}>
      <thead>
        <tr>
          <th className={s.ChartTable__headerCell} />
          <th className={s.ChartTable__headerCell}>Млрд</th>
          <th className={s.ChartTable__headerCell}>Проценты</th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ id, label, hasPadding, value, percent }, i) => (
          <tr
            key={i}
            className={classNames(s.ChartTable__row, {
              [s.ChartTable__clickable]: !!id,
              [s.ChartTable__activeMode]: activeSegmentId,
              [s.ChartTable__active]: !activeSegmentId || activeSegmentId === id
            })}
            onClick={() => onClick(id)}
          >
            <td
              className={classNames(s.ChartTable__mainText, s.ChartTable__cell)}
            >
              {id ? (
                <div
                  className={classNames(s.ChartTable__cellContent, {
                    [s._withPadding]: hasPadding
                  })}
                >
                  <span className={s.ChartTable__cellIcon}>
                    <Circle />
                  </span>
                  <span>{label}</span>
                </div>
              ) : (
                label
              )}
            </td>
            <td
              className={classNames(s.ChartTable__mainText, s.ChartTable__cell)}
            >
              {value}
            </td>
            <td
              className={classNames(s.ChartTable__mainText, s.ChartTable__cell)}
            >
              {percent}%
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
