import {
  endOfWeek,
  format,
  isBefore,
  isEqual,
  isSameWeek,
  isValid,
  parseISO,
  startOfWeek
} from 'date-fns';
import { max as d3Max } from 'd3';
import ruLocale from 'date-fns/locale/ru';
import { EMDASH } from './utf';

export const DATE_FORMAT_RUSSIAN = 'dd.MM.yyyy';
export const DATE_FORMAT_ISO_DATE = 'yyyy-MM-dd';

export function reformatDate(isoDate, defaultValue = EMDASH) {
  if (!isoDate) return defaultValue;
  const date = parseISO(isoDate);
  if (!date) return defaultValue;
  return format(date, DATE_FORMAT_RUSSIAN);
}

export function lStartOfWeek(date) {
  return startOfWeek(date, { weekStartsOn: 1 });
}

export function lEndOfWeek(date) {
  return endOfWeek(date, { weekStartsOn: 1 });
}

export function lIsSameWeek(dateLeft, dateRight) {
  return isSameWeek(dateLeft, dateRight, { weekStartsOn: 1 });
}

export function lFormat(date, formatString, options = {}) {
  return format(date, formatString, { ...options, locale: ruLocale });
}

export function formatDateIso(date) {
  return format(date, DATE_FORMAT_ISO_DATE);
}

export function isBeforeOrEqual(dateLeft, dateRight) {
  return isBefore(dateLeft, dateRight) || isEqual(dateLeft, dateRight);
}

export function tryParseIso(string) {
  if (!string) return null;
  const date = parseISO(string);
  if (isValid(date)) {
    return date;
  }
  return null;
}

export function getPlanAndObligationDate(data) {
  if (!data) {
    return [null, null];
  }
  if (Array.isArray(data)) {
    let planDate = d3Max(data, (item) =>
      tryParseIso(item.actuality_date_of_total_budget)
    );
    let obligationDate = d3Max(data, (item) =>
      tryParseIso(item.actuality_date_of_obligations)
    );

    return [planDate, obligationDate];
  }

  return [
    tryParseIso(data.actuality_date_of_total_budget),
    tryParseIso(data.actuality_date_of_obligations)
  ];
}

export function getIndicatorsAndResultsDate(data) {
  if (!data) {
    return [null, null];
  }
  return [
    tryParseIso(data.indicatorsRatingActualityDate),
    tryParseIso(data.resultsRatingActualityDate)
  ];
}
