import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useGraphqlQuery } from '@proscom/prostore-apollo-react';
import { skipIfNull } from '@proscom/prostore';
import {
  TabRadio,
  useQueryTabs,
  useStateTabs
} from '../../../common/ui/Tab/TabRadio';
import {
  QUERY_GET_FEDERAL_PROJECT_RESULTS_WITH_FINANCES,
  QUERY_GET_REGIONAL_PROJECT_RESULT_WITH_FINANCES
} from '../../../graphql/queries/federalProjects';
import { useFPRoadmapQuery } from '../../../store/useRoadmaps';
import { ChipRadio } from '../../../common/ui/Chip/ChipRadio';
import { Card } from '../../../common/ui/Card/Card';
import { usePreserveQuery } from '../../../store/usePreserveQuery';
import { useQueryPeriod } from '../../../utils/useQueryPeriod';
import { getStatusByValue, RatingStatus } from '../../../utils/constants';
import { URL_QUERY_REGION_CODE } from '../../../store/stores';
import { INDICATOR_DC_COMPLETION } from '../../../utils/indicators';
import { formatDateIso } from '../../../utils/date';
import { queryLoader } from '../../../common/QueryLoader';
import colors from '../../../colors.scss';
import s from './RoadmapsAndResultsCards.module.scss';

const SUCCESS = RatingStatus.SUCCESS;
const WARN = RatingStatus.WARN;
const ERROR = RatingStatus.ERROR;
const chipRadioData = [
  {
    title: 'все',
    value: null
  },
  {
    title: 'Критично',
    value: ERROR,
    color: colors.error
  },
  {
    title: 'Умеренно',
    value: WARN,
    color: colors.warn
  },
  {
    title: 'Положительно',
    value: SUCCESS,
    color: colors.success
  }
];
const tabsRadioData = [
  { title: 'Дорожные карты', value: 'roadmap' },
  { title: 'Результаты', value: 'results' }
];

const fpResultsOptions = {
  query: QUERY_GET_FEDERAL_PROJECT_RESULTS_WITH_FINANCES,
  mapData: (result) => result.resultFederals.list,
  skipQuery: skipIfNull(null)
};

const useFPResultsQuery = (federalProjectCode, yearMonth, regionCode) => {
  const fpResultsVariables =
    yearMonth && !regionCode
      ? {
          input: federalProjectCode
            ? {
                filter: {
                  federal_project_codes: [federalProjectCode]
                }
              }
            : undefined,
          yearMonth: yearMonth ? yearMonth : undefined
        }
      : null;
  return useGraphqlQuery({
    queryOptions: fpResultsOptions,
    variables: fpResultsVariables
  });
};

const rpResultsOptions = {
  query: QUERY_GET_REGIONAL_PROJECT_RESULT_WITH_FINANCES,
  mapData: (result) => result.resultRegionals.list,
  skipQuery: skipIfNull(null)
};

const useRPResultQuery = ({ federalProjectCode, yearMonth, regionCode }) => {
  const fpResultsVariables =
    yearMonth && regionCode
      ? {
          input: {
            filter: {
              federal_project_codes: federalProjectCode
                ? [federalProjectCode]
                : undefined,
              region_code_nalogs: regionCode ? [regionCode] : undefined
            }
          },
          yearMonth: yearMonth ? yearMonth : undefined
        }
      : null;
  return useGraphqlQuery({
    queryOptions: rpResultsOptions,
    variables: fpResultsVariables
  });
};

export const RoadmapsAndResultsCards = React.memo(function Roadmaps({
  federalProjectCode,
  regionCode,
  className
}) {
  const { year, yearMonth } = useQueryPeriod();

  const rqVars = useMemo(
    () => ({
      federal_project_codes: federalProjectCode,
      years: year,
      region_code_nalog: regionCode
    }),
    [federalProjectCode, year, regionCode]
  );
  const fpRoadmapQuery = useFPRoadmapQuery(
    rqVars,
    {
      month: yearMonth,
      budget_levels: regionCode ? 'subject' : 'federal',
      region_code_nalogs: regionCode || undefined
    },
    {
      year,
      federal_project_codes: [federalProjectCode],
      region_codes: regionCode || undefined,
      date: formatDateIso(new Date())
    }
  );

  const fpResultsQuery = useFPResultsQuery(
    federalProjectCode,
    yearMonth,
    regionCode
  );

  const rpResultsQuery = useRPResultQuery({
    federalProjectCode,
    yearMonth,
    regionCode
  });

  const [tab, tabProps] = useQueryTabs(
    tabsRadioData,
    (x, i) => x.value,
    'tab',
    true
  );
  const [status, statusProps] = useStateTabs(chipRadioData, (x, i) => x.value);

  const activeTabQuery =
    tab === 'roadmap'
      ? fpRoadmapQuery
      : regionCode
      ? rpResultsQuery
      : fpResultsQuery;

  const cards = useMemo(() => {
    if (tab === 'roadmap') {
      return fpRoadmapQuery.state.data?.map((card) => {
        return {
          ...card,
          status: getStatusByValue(card.dc_completion?.dc_completion_percent)
        };
      });
    } else if (!regionCode) {
      return fpResultsQuery.state.data?.map((card) => {
        return {
          ...card,
          status: getStatusByValue(card.finances?.cash_expenses_percent)
        };
      });
    } else {
      return rpResultsQuery.state.data?.map((card) => {
        return {
          ...card,
          link: `regions/${regionCode}/results/${card.id}`,
          status: getStatusByValue(card.finances?.cash_expenses_percent)
        };
      });
    }
  }, [
    tab,
    fpRoadmapQuery.state.data,
    fpResultsQuery.state.data,
    rpResultsQuery.state.data,
    regionCode
  ]);

  return (
    <div className={classNames(s.RoadMap, className)}>
      <div className={s.RoadMap__wrapper}>
        <div className={s.RoadMap__buttons}>
          {tabsRadioData.map((item) => (
            <TabRadio
              key={`tabRadio__${item.value}`}
              className={classNames(s.RoadMap__buttonsItem, {
                [s._active]: tab === item.value
              })}
              {...tabProps(item.value)}
            >
              {item.title}
            </TabRadio>
          ))}
        </div>
        <div className={s.RoadMap__filter}>
          <div className={s.RoadMap__filterWrapper}>
            <div className={s.RoadMap__filterItems}>
              {chipRadioData.map((item) => (
                <ChipRadio
                  key={`chipRadio__${item.value}`}
                  className={s.CardView__filterItem}
                  title={item.title}
                  color={item.color}
                  {...statusProps(item.value)}
                />
              ))}
            </div>
          </div>
        </div>
        <CardsMap
          query={activeTabQuery}
          data={cards}
          tab={tab}
          status={status}
        />
      </div>
    </div>
  );
});

const CardsMap = ({ query, ...props }) => {
  return (
    queryLoader(query, {
      className: s.RoadMap__ProgressIndicator
    }) || <CardsMapContent {...props} />
  );
};

const queryParams = [URL_QUERY_REGION_CODE];
const CardsMapContent = ({ data, tab, status }) => {
  const { createLink } = usePreserveQuery(queryParams);

  const cardsFiltered = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.filter((card) => !status || status === card.status);
  }, [data, status]);

  return (
    <div className={s.CardsMap}>
      {cardsFiltered.length !== 0 ? (
        <>
          <div className={classNames(s.CardsMap__grid, s['_' + tab])}>
            {cardsFiltered.map((item, index) => (
              <Link
                key={`cardLink__${index}`}
                to={createLink(
                  item.link ? item.link : `/indicators/${tab}/${item.id}`
                )}
                className={s.CardsMap__link}
              >
                <Card
                  className={s.CardsMap__cardsItem}
                  charts={
                    tab === 'roadmap'
                      ? [
                          {
                            title: 'Выполнение мероприятий',
                            value: item.dc_completion?.dc_completion_percent,
                            unit: '%',
                            type: INDICATOR_DC_COMPLETION
                          },
                          {
                            title: 'Кассовое исполнение',
                            value:
                              item.result?.finances?.cash_expenses_percent ||
                              null,
                            unit: '%'
                          }
                        ]
                      : [
                          {
                            title: 'Достижение результата',
                            value: null,
                            unit: '%'
                          },
                          {
                            title: 'Кассовое исполнение',
                            value: item.finances?.cash_expenses_percent,
                            unit: '%'
                          }
                        ]
                  }
                  decimal={2}
                  status={item.status}
                  description={item.name_full || item.name}
                  loading={!item}
                />
              </Link>
            ))}
          </div>
        </>
      ) : (
        <div className={s.RoadMap__noData}>Данные отсутствуют</div>
      )}
    </div>
  );
};
