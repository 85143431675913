import React from 'react';
import { TextInput } from '../TextInput/TextInput';
import { TextInputQuestions } from './TextInputQuestions';

export function FormikTextInputQuestions({
  field,
  form,
  onChange: propsOnChange,
  ...props
}) {
  const { onChange: fieldOnChange, ...fieldProps } = field;
  const onChange = propsOnChange || fieldOnChange;
  const status = props.status || (props.errorMessage ? TextInput.ERROR : null);

  return (
    <TextInputQuestions
      {...fieldProps}
      {...props}
      onChange={onChange}
      status={status}
    />
  );
}
