import React, { useMemo } from 'react';
import classNames from 'classnames';
import { CardTotal } from '../../../common/ui/CardSmall/CardTotal';
import { formatNumber } from '../../../utils/formatNumber';
import { EMDASH } from '../../../utils/utf';
import colors from '../../../colors.scss';
import s from './CardsView.module.scss';

export const SubjectCard = ({ onCardClick, item, active, ...rest }) => {
  const {
    data = {},
    mbt = {},
    rb = {},
    colors: itemColors,
    regions = []
  } = item;
  const headerCells = useMemo(() => {
    return [
      {
        value: ''
      },
      {
        value: 'План'
      },
      {
        value: 'П. БО'
      },
      {
        value: '%',
        className: 'percent'
      },
      {
        value: 'Факт'
      },
      {
        value: '%',
        className: 'percent'
      }
    ];
  }, []);

  return (
    <CardTotal
      className={classNames(s.CardsView__item, {
        [s._active]: active
      })}
      data={[
        [
          {
            value: 'ФБ+РБ'
          },

          // План
          {
            value: formatNumber(data.total_budget / 1e9, 1)
          },

          // Принятие БО
          {
            value: formatNumber(data.obligations / 1e9, 1)
          },
          {
            value: formatNumber(
              Math.min(0, (data.obligations / data.total_budget) * 100),
              0
            ),
            className: 'percent'
          },

          // Факт
          {
            value: formatNumber(data.cash_expenses / 1e9, 1)
          },
          {
            value: formatNumber(
              Math.min(0, (data.cash_expenses / data.total_budget) * 100),
              0
            ),
            className: 'percent'
          }
        ],
        [
          {
            value: 'ФБ'
          },

          // План
          {
            value: formatNumber(mbt.plan / 1e9, 1)
          },

          // Принятие БО
          {
            value: EMDASH
          },
          {
            value: EMDASH,
            className: 'percent'
          },

          // Факт
          {
            value: formatNumber(mbt.otfg_kr_total / 1e9, 1)
          },
          {
            value: formatNumber(
              Math.min(0, (mbt.otfg_kr_total / mbt.plan) * 100),
              0
            ),
            className: 'percent'
          }
        ],
        [
          {
            value: 'РБ*'
          },

          // План
          {
            value: formatNumber(rb.total_budget / 1e9, 1)
          },

          // Принятие БО
          {
            value: formatNumber(rb.obligations / 1e9, 1)
          },
          {
            value: formatNumber(
              Math.min(0, (rb.obligations / rb.total_budget) * 100),
              0
            ),
            className: 'percent'
          },

          // Факт
          {
            value: formatNumber(rb.cash_expenses / 1e9, 1)
          },
          {
            value: formatNumber(
              Math.min(0, (rb.cash_expenses / rb.total_budget) * 100),
              0
            ),
            className: 'percent'
          }
        ]
      ]}
      headCells={headerCells}
      layoutProps={
        active
          ? {
              style: {
                background: `linear-gradient(210.42deg, ${itemColors.firstColor} 0%, ${itemColors.secondColor} 100%)`,
                color: itemColors.textColor
              }
            }
          : {
              style: {
                background: colors.blueDark,
                color: colors.white
              }
            }
      }
      title={
        regions.length
          ? data.federal_district.name.replace('федеральный округ', 'ФО')
          : data.region.name
      }
      {...rest}
    />
  );
};
