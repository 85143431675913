import React, { useMemo, useState } from 'react';
import { max as d3max, scaleLinear, sum as d3sum } from 'd3';
import classNames from 'classnames';
import { useWindowSize } from '../../store/useWindowSize';
import { getRoundLegendSteps, roundToRound } from '../../utils/math';
import { ChartLegend } from '../charts/ChartLegend';
import { RUBLE } from '../../utils/utf';
import StackedBarChart from '../charts/StackedBarChart/StackedBarChart';
import { ButtonComponent } from '../ui/Button/Button';
import { NumberTooltip } from '../ui/ToolTip/NumberTooltip';
import { BaseTooltip } from '../ui/ToolTip/BaseTooltip';
import s from './ChartView.module.scss';

export const ChartViewChart = ({
  data,
  legend,
  width,
  height,
  chartProps,
  titleClassName,
  actualityDate,
  minValueOptions
}) => {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  const [selected, setSelected] = useState(null);

  const totalWidth = width;
  const chartHeight = height;
  const leftLegendPaddingVertical = 6;
  const chartTopLegendHeight = 24;
  const hasLegend = isMobile ? false : !!legend;
  const leftLegendWidth = hasLegend ? 60 : 0;
  const chartWidth = totalWidth - leftLegendWidth;
  const totalBars = data.reduce((res, datum) => res + datum.data.length, 0);
  const chartsGap = 16;
  const barGap = 8;
  const barWidth = Math.floor(
    (chartWidth - barGap * (totalBars - 1) - chartsGap * (data.length - 1)) /
      totalBars
  );
  const widths = data.map((datum) => {
    const bars = datum.data.length;
    const width = barWidth * bars + barGap * (bars - 1);
    return {
      bars,
      width
    };
  });
  const chartMax = (d) => d3max(d.data, (d) => d3sum(d.values, (v) => v.value));
  const baseMax =
    selected === null ? d3max(data, chartMax) : chartMax(data[selected]);
  const max = legend ? roundToRound(baseMax) : baseMax;

  const minLine = useMemo(() => {
    if (!minValueOptions || typeof minValueOptions !== 'object') {
      return null;
    }
    const { barCount, name, value } = minValueOptions;
    if (value === null || typeof value === 'undefined') {
      return null;
    }
    const svgWidth = width - leftLegendWidth;
    const svgHeight = height - 24;

    // Расчитаем количество переходов между графиками
    let chartsGapCount = 0;
    let prevCount = 0;
    for (let i = data.length; i--; i >= 0) {
      prevCount += data[i] && data[i].data.length;
      if (prevCount < barCount) {
        chartsGapCount++;
      }
    }

    const lineWidth = barCount
      ? svgWidth -
        barCount * (barGap + barWidth) +
        barGap -
        chartsGapCount * chartsGap
      : 0;

    const valueInterpolator = scaleLinear()
      .domain([0, max])
      .range([svgHeight, 0]);

    return (
      <svg
        width={svgWidth}
        height={svgHeight}
        className={s.ChartView__minValue}
      >
        <BaseTooltip
          key={'minValueTooltip'}
          tooltip={
            <NumberTooltip
              data={[
                {
                  id: 0,
                  name: 'ПОФ',
                  value: value,
                  color: null,
                  decimal: 2
                }
              ]}
              title={''}
            />
          }
        >
          <g>
            <circle
              cx={svgWidth - 12}
              cy={valueInterpolator(value) - 12}
              r={12}
              fillOpacity={0}
            />
            <text
              x={svgWidth}
              y={valueInterpolator(value) - 8}
              textAnchor={'end'}
            >
              {name}
            </text>
          </g>
        </BaseTooltip>
        <line
          x1={svgWidth}
          x2={lineWidth}
          y1={valueInterpolator(value)}
          y2={valueInterpolator(value)}
        />
      </svg>
    );
  }, [barWidth, data, height, leftLegendWidth, max, minValueOptions, width]);

  return (
    <div className={s.ChartView__chart}>
      {hasLegend && (
        <ChartLegend
          className={s.ChartView__legend}
          step={getRoundLegendSteps(max)}
          sizeWidth={totalWidth}
          sizeHeight={
            chartHeight - chartTopLegendHeight + leftLegendPaddingVertical * 2
          }
          max={max}
          min={0}
          legendPadding={4}
          legendWidth={leftLegendWidth}
          unit={`млн ${RUBLE}`}
        />
      )}
      {minLine}
      <div
        className={s.ChartView__chartBody}
        style={{
          left: leftLegendWidth,
          width: width - leftLegendWidth,
          height
        }}
      >
        {data.map((item, iItem) => (
          <div
            key={iItem}
            style={{ width: widths[iItem].width }}
            className={classNames(s.ChartView__chartItem, {
              [s._inactive]: selected !== null && selected !== iItem
            })}
          >
            <StackedBarChart
              wrapperClassName={s.ChartView__chartItemWrapper}
              barGap={barGap}
              barWidth={barWidth}
              sizeWidth={widths[iItem].width}
              size={chartHeight}
              actualityDate={actualityDate}
              data={item.data}
              total={max}
              {...chartProps}
            />
            <div
              className={classNames(s.ChartView__chartTitle, titleClassName)}
            >
              {item.name && (
                <ButtonComponent
                  className={s.ChartView__chartTitleButton}
                  onClick={() =>
                    selected === null || selected !== iItem
                      ? setSelected(iItem)
                      : setSelected(null)
                  }
                >
                  {item.name}
                </ButtonComponent>
              )}
              {item.additionalLink && (
                <ButtonComponent
                  className={s.ChartView__additionalLink}
                  to={item.additionalLink}
                >
                  Подробнее
                </ButtonComponent>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
