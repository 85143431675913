import { useMemo } from 'react';
import {
  getInternetColor,
  percentageToColor
} from '../common/Map/CommonMap/utils';
import { INDICATOR_INTERNET_SPEED } from './indicators';
import { formatValueUnit } from './formatNumber';
import { DOWNARROW, UPARROW } from './utf';
import colors from '../colors.scss';

export const useColorifiedMapItems = (
  data,
  { indicator_type, rangeMin, rangeMax, speed_type }
) =>
  useMemo(() => {
    return (
      data &&
      data.map((item) => {
        const result = { ...item };

        if (!indicator_type || indicator_type === 'none') {
          if (item.count_organizations) {
            result.color = colors.success;
          } else {
            result.color = colors.gray;
          }
          result.colorMarkers = result.color;
        } else if (indicator_type === INDICATOR_INTERNET_SPEED) {
          const indicatorValue = item[indicator_type];
          const unit = ' мбит/с';
          const upload = indicatorValue?.upload_speed;
          const download = indicatorValue?.download_speed;

          result.color = getInternetColor(
            indicatorValue,
            speed_type,
            rangeMin,
            rangeMax
          );
          result.colorMarkers = result.color;

          result.tooltipText = `${item.name} (${formatValueUnit(
            upload,
            unit,
            1
          )} ${UPARROW} / ${formatValueUnit(download, unit, 1)} ${DOWNARROW})`;
        } else {
          const indicatorValue = item[indicator_type];
          result.color = percentageToColor(
            indicatorValue,
            rangeMin,
            rangeMax,
            indicator_type
          );
          result.colorMarkers = result.color;
          const unit = '%';
          result.tooltipText = `${item.name} (${formatValueUnit(
            indicatorValue,
            unit,
            1
          )})`;
        }

        return result;
      })
    );
  }, [data, indicator_type, rangeMax, rangeMin, speed_type]);
