import {
  useGraphqlQuery,
  useGraphqlQueryVars
} from '@proscom/prostore-apollo-react';
import { pickBy } from 'lodash-es';
import {
  QUERY_GET_DISTRICTS,
  QUERY_GET_DISTRICTS_WITH_FINANCIAL_RATINGS,
  QUERY_RESULT_AND_INDICATOR_ACHIEVEMENT_DATE
} from '../graphql/queries/federalDistricts';
import { findItemByFiledName } from '../utils/data';
import {
  INDICATOR_RESULT_ACH,
  INDICATOR_TARGET_ACH
} from '../utils/indicators';

const getFederalDistrictsQueryOptions = {
  query: QUERY_GET_DISTRICTS,
  mapData: (result) => result.federalDistricts
};

export function useFederalDistricts(variables) {
  const filter = pickBy(variables, (a) => a);
  return useGraphqlQueryVars({
    queryOptions: getFederalDistrictsQueryOptions,
    variableCreator: [() => ({ filter }), [filter]]
  });
}

const federalDistrictAchActualityDateQueryOptions = {
  query: QUERY_RESULT_AND_INDICATOR_ACHIEVEMENT_DATE,
  mapData: (data) => data.subjectRatingActualityDate
};

const getFederalDistrictsWithFinancialRatingsQueryOptions = {
  query: QUERY_GET_DISTRICTS_WITH_FINANCIAL_RATINGS,
  mapData: (result) => {
    for (const district of result.districts) {
      const finance = findItemByFiledName(
        result.finances,
        district.id,
        'federal_district_id'
      );
      if (finance) {
        district.accept = finance.accept;
        district.cash_completion = finance.cash_completion;
      }
      const dcCompletion = findItemByFiledName(
        result.dcCompletion,
        district.id,
        'federal_district_id'
      );
      if (dcCompletion) {
        district.dc_completion_percent = dcCompletion.dc_completion_percent;
      }
      const result_ach = findItemByFiledName(
        result.resultsRatings,
        district.id,
        'subject_id'
      );
      if (result_ach) {
        district.result_ach = result_ach.value;
      }
      const target_ach = findItemByFiledName(
        result.indicatorsRatings,
        district.id,
        'subject_id'
      );
      if (target_ach) {
        district.target_ach = target_ach.value;
      }
    }
    return result.districts;
  }
};

export function useFederalDistrictsWithFinancialRatings(variables) {
  return useGraphqlQuery({
    queryOptions: getFederalDistrictsWithFinancialRatingsQueryOptions,
    variables
  });
}

export function useFederalDistrictAchActualityDate(yearMonth) {
  const actualityDate = {};

  const resultAchActualityDateQuery = useGraphqlQuery({
    queryOptions: federalDistrictAchActualityDateQueryOptions,
    variables: {
      yearMonth,
      data_type: 'results_achievement_percentage',
      level: 'federal_district'
    }
  });

  const indicatorAchActualityDateQuery = useGraphqlQuery({
    queryOptions: federalDistrictAchActualityDateQueryOptions,
    variables: {
      yearMonth,
      data_type: 'indicators_achievement_percentage',
      level: 'federal_district'
    }
  });
  actualityDate[INDICATOR_TARGET_ACH] =
    indicatorAchActualityDateQuery.state.data;
  actualityDate[INDICATOR_RESULT_ACH] = resultAchActualityDateQuery.state.data;
  return actualityDate;
}
