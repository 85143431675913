import { skipIfNull } from '@proscom/prostore';
import { QUERY_SEARCH_SUBSIDIARY_ENTITIES } from '../../../graphql/queries/organization';
import { useSearchQuery } from './_util';

const searchResultsQueryOptions = {
  query: QUERY_SEARCH_SUBSIDIARY_ENTITIES,
  mapData: (result) => result.subsidiaryEntitiesPage,
  skipQuery: skipIfNull(null)
};

export function useSearchSubsidiaryEntity(search, filter, showEmpty = false) {
  return useSearchQuery(searchResultsQueryOptions, search, filter, showEmpty);
}
