import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { CircleProgressIndicator } from '../ProgressIndicator/CircleProgressIndicator';
import s from './CardSmallLayout.module.scss';

export function CardSmallLayout({
  loading,
  className,
  spinnerColor,
  spinnerBGColor,
  children,
  spinner,
  to,
  ...props
}) {
  if (loading) {
    return (
      <div className={classNames(s.CardSmallLayout, className)} {...props}>
        <div
          className={classNames(s.CardSmallLayout__spinner, {
            [s._dark]: spinnerBGColor === 'dark'
          })}
        >
          <CircleProgressIndicator size={28} color={spinnerColor} />
        </div>
      </div>
    );
  }

  if (to) {
    return (
      <Link
        className={classNames(
          s.CardSmallLayout,
          s.CardSmallLayout__link,
          className
        )}
        {...props}
        to={to}
      >
        {children}
      </Link>
    );
  }

  return (
    <div className={classNames(s.CardSmallLayout, className)} {...props}>
      {children}
    </div>
  );
}

CardSmallLayout.propTypes = {
  loading: PropTypes.bool,
  className: PropTypes.string,
  spinnerColor: PropTypes.string,
  children: PropTypes.any
};
