export const INDICATOR_TYPE_P = 'INDICATOR_TYPE_P';
export const INDICATOR_TYPE_O = 'INDICATOR_TYPE_O';
export const INDICATOR_TYPE_D = 'INDICATOR_TYPE_D';
export const INDICATOR_TYPE_S = 'INDICATOR_TYPE_S';
export const INDICATOR_TYPE_V = 'INDICATOR_TYPE_V';
export const INDICATOR_TYPE_DO = 'INDICATOR_TYPE_DO';

export const indicatorTypes = [
  {
    value: INDICATOR_TYPE_P,
    label: 'Общие сведения'
  },
  {
    value: INDICATOR_TYPE_D,
    label: 'Дошкольное образование'
  },
  {
    value: INDICATOR_TYPE_O,
    label: 'Общее образование'
  },
  {
    value: INDICATOR_TYPE_S,
    label: 'Среднее профессиональное образование'
  },
  {
    value: INDICATOR_TYPE_V,
    label: 'Высшее образование'
  },
  {
    value: INDICATOR_TYPE_DO,
    label: 'Дополнительное образование детей'
  }
];

export const indicatorTypesMap = {};
for (const type of indicatorTypes) {
  indicatorTypesMap[type.value] = type;
}

export const indicators = {
  [INDICATOR_TYPE_P]: [
    {
      title: 'Доля расходов на образование в общем бюджете субъекта, %',
      field: 'share_of_education_expenses'
    },
    {
      title: 'Количество молодежи, чел.',
      field: 'count_youth'
    },
    { field: 'population', title: 'Численность населения, чел.' },
    {
      field: 'population_0_3',
      title: 'Численность населения (до 3 лет), чел.'
    },
    { field: 'population_3_7', title: 'Численность населения (3–7 лет), чел.' },
    {
      field: 'population_5_18',
      title: 'Численность населения (5–18 лет), чел.'
    },
    {
      field: 'population_18_25',
      title: 'Численность населения (18–25 лет), чел.'
    }
  ],
  [INDICATOR_TYPE_O]: [
    {
      title: 'Численность обучающихся, чел.',
      field: 'count_students'
    },
    {
      title: 'Численность обучающихся в городской местности, чел.',
      field: 'count_students_city'
    },
    {
      title: 'Численность обучающихся в сельской местности, чел.',
      field: 'count_students_county'
    },
    {
      title: 'Численность обучающихся по сменам, всего, чел.',
      field: 'count_students_shifts'
    },
    {
      title: 'Численность обучающихся в 1 смену, чел.',
      field: 'count_students_shift1'
    },
    {
      title: 'Численность обучающихся во 2 смену, чел.',
      field: 'count_students_shift2'
    },
    {
      title: 'Численность обучающихся в 3 смену, чел.',
      field: 'count_students_shift3'
    },
    {
      title: 'Численность педагогических работников, чел.',
      field: 'count_teachers'
    },
    {
      title: 'Численность руководящих работников, чел.',
      field: 'count_directors'
    },
    {
      title: 'Численность учебно-вспомогательного персонала, чел.',
      field: 'count_additional'
    },
    {
      title: 'Средняя заработная плата педагогических работников, руб.',
      field: 'avg_wage_teacher'
    },
    {
      title:
        'Отношение средней заработной платы педагогических работников ОО к среднемесячной заработной плате по региону, %',
      field: 'rel_avg_wage_teacher_region'
    }
  ],
  [INDICATOR_TYPE_D]: [
    {
      title: 'Доступность дошкольного образования (0–3 года), %',
      field: 'd_availability_kinder_0_3'
    },
    {
      title: 'Доступность дошкольного образования (3–7 лет), %',
      field: 'd_availability_kinder_3_7'
    },
    {
      title:
        'Количество детей, охваченных услугами дошкольного образования (0–3 года), чел.',
      field: 'd_count_area_students_0_3'
    },
    {
      title:
        'Количество детей, охваченных услугами дошкольного образования (3–7 лет), чел.',
      field: 'd_count_area_students_3_7'
    },
    {
      title:
        'Численность детей, поставленных на учет для предоставления места в организациях 0-3 лет, чел.',
      field: 'd_count_acc_students_0_3'
    },
    {
      title:
        'Численность детей, поставленных на учет для предоставления места в организациях 3-7 лет, чел.',
      field: 'd_count_acc_students_3_7'
    },
    {
      title: 'Численность детей, не обеспеченных местом (0–3 года), чел.',
      field: 'd_count_nom_students_0_3'
    },
    {
      title: 'Численность детей, не обеспеченных местом (3–7 лет), чел.',
      field: 'd_count_nom_students_3_7'
    },
    {
      title: 'Численность воспитанников, чел.',
      field: 'd_count_students'
    },
    {
      title: 'Численность воспитанников в городе, чел.',
      field: 'd_count_students_city'
    },
    {
      title: 'Численность воспитанников в селе, чел.',
      field: 'd_count_students_county'
    },
    {
      title: 'Численность педагогических работников, чел.',
      field: 'd_count_teacher'
    },
    {
      title: 'Средняя заработная плата педагогических работников, руб.',
      field: 'd_avg_wage_teacher'
    },
    {
      title:
        'Отношение средней заработной платы педагогических работников ДО к среднемесячной заработной плате по региону, %',
      field: 'd_rel_avg_wage_teacher_region'
    }
  ],
  [INDICATOR_TYPE_S]: [
    {
      title: 'Численность студентов, чел.',
      field: 's_count_students'
    },
    {
      title: 'Численность педагогических работников, чел.',
      field: 's_count_teachers'
    },
    {
      title: 'Численность руководящих работников, чел.',
      field: 's_count_directors'
    },
    {
      title: 'Численность учебно-вспомогательного персонала, чел.',
      field: 's_count_additional'
    },
    {
      title: 'Численность обслуживающего персонала, чел.',
      field: 's_count_staff'
    },
    {
      title: 'Средняя заработная плата преподавателей, руб',
      field: 's_avg_wage_teacher'
    },
    {
      title:
        'Отношение средней заработной платы преподавателей к средней заработной плате по региону, %',
      field: 's_avg_teacher_salary_to_avg_monthly_salary_ration'
    }
  ],
  [INDICATOR_TYPE_V]: [
    {
      title: 'Численность студентов, чел.',
      field: 'v_count_students'
    },
    {
      title: 'Численность профессорско-преподавательского состава, чел.',
      field: 'v_count_professors'
    },
    {
      title: 'Численность руководящих работников, чел.',
      field: 'v_count_directors'
    },
    {
      title: 'Численность научных работников, чел.',
      field: 'v_count_scientists'
    },
    {
      title: 'Численность инженерно-технического персонала, чел.',
      field: 'v_count_engineers'
    },
    {
      title: 'Численность административно-хозяйственного персонала, чел.',
      field: 'v_count_admins'
    },
    {
      title: 'Численность производственного персонала, чел.',
      field: 'v_count_producers'
    },
    {
      title: 'Численность учебно-вспомогательного персонала, чел.',
      field: 'v_count_helpers'
    },
    {
      title: 'Численность обслуживающего персонала, чел.',
      field: 'v_count_additional'
    },

    {
      title: 'Средняя заработная плата преподавателей, руб.',
      field: 'v_avg_wage_teacher'
    },
    {
      title:
        'Отношение средней заработной платы преподавателей к среднемесячной заработной плате по региону, %',
      field: 'v_rel_avg_wage_teacher_region'
    }
  ],
  [INDICATOR_TYPE_DO]: [
    {
      title: 'Численность учащихся по направлениям ДОД, чел.',
      field: 'dpo_count_students'
    },
    {
      title:
        'Количество организаций, осуществляющих образовательную деятельность по ДОД, шт.',
      field: 'dpo_count_organizations'
    }
  ]
};
