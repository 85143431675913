import { useFederalProjects } from '../../../store/useFederalProjects';
import { useSearchArrayQuery } from './_util';

export function useSearchFederalProjects(search, sortBy, array = undefined) {
  const fpQuery = useFederalProjects();
  return useSearchArrayQuery(
    fpQuery,
    search,
    (d) => d.name_full || d.name,
    sortBy,
    array
  );
}
