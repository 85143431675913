import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import ReactSlick from 'react-slick';
import { findMinWrapped } from '../../../utils/math';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { mediaWidthMobile, mediaWidthTablet } from '../../../_vars.scss';
import s from './CardsView.module.scss';

const slickResponsive = [
  {
    breakpoint: parseFloat(mediaWidthTablet),
    settings: {
      vertical: false,
      verticalSwiping: false,
      focusOnSelect: true,
      centerMode: true,
      slidesToShow: 3
    }
  },
  {
    breakpoint: parseFloat(mediaWidthMobile),
    settings: {
      vertical: false,
      verticalSwiping: false,
      focusOnSelect: true,
      centerMode: true,
      variableWidth: true,
      slidesToShow: 1
    }
  }
];

function CardsView({
  className,
  onChangeIndex,
  selectedIndex,
  onCardClick,
  scrollToClosest,
  dotColors,
  isLoading,
  children,
  ...props
}) {
  const slickRef = useRef(null);
  const initialSlideRef = useRef(selectedIndex);
  const initialSlide = initialSlideRef.current;
  const currentSlide = useRef(selectedIndex);
  const numSlides = React.Children.count(children);
  const isOutsideInitiate = useRef(false);

  useEffect(() => {
    if (slickRef.current && numSlides > 0) {
      const slideIndex = currentSlide.current;

      const next = scrollToClosest
        ? findMinWrapped(slideIndex, selectedIndex, numSlides)
        : selectedIndex;

      if (selectedIndex !== slideIndex) {
        isOutsideInitiate.current = true;
        if (next >= -2 && next <= numSlides + 2) {
          slickRef.current.slickGoTo(next);
          currentSlide.current = next;
        } else if (next > numSlides + 2) {
          slickRef.current.slickGoTo(next - numSlides);
          currentSlide.current = next - numSlides;
        } else if (next < numSlides - 2) {
          slickRef.current.slickGoTo(next + numSlides);
          currentSlide.current = next + numSlides;
        }
      }
    }
  }, [selectedIndex, numSlides, scrollToClosest]);

  const onAfterChange = useCallback(
    (v) => {
      currentSlide.current = v;
      onChangeIndex(v);
    },
    [onChangeIndex]
  );

  return (
    <div
      className={classNames(s.CardsView, className, {
        [s._loading]: isLoading
      })}
      {...props}
    >
      <div className={s.before} />
      {isLoading && <CircleProgressIndicator className={s.CardsView__loader} />}
      <div
        className={s.CardsView__colorDot}
        style={{
          background: dotColors && dotColors.firstColor
        }}
      />
      {numSlides > 0 && (
        <ReactSlick
          className={s.Slick}
          ref={slickRef}
          dots={false}
          vertical
          verticalSwiping
          slidesToShow={1}
          centerPadding={0}
          // focusOnSelect
          centerMode
          infinite
          initialSlide={initialSlide}
          afterChange={onAfterChange}
          arrows={false}
          responsive={slickResponsive}
        >
          {children}
        </ReactSlick>
      )}
      <div className={s.after} />
    </div>
  );
}

CardsView.propTypes = {};

export default CardsView;
