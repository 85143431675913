import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { throttle } from 'lodash-es';
import StackedBarChart from '../../../common/charts/StackedBarChart/StackedBarChart';
import { formatNumber } from '../../../utils/formatNumber';
import { Button } from '../../../common/ui/Button/Button';
import { ReactComponent as ArrowDown } from '../../../assets/Icons/Arrow.svg';
import { ReactComponent as ArrowRight } from '../../../assets/Icons/Arrow/Front.svg';
import { ReactComponent as Close } from '../../../assets/Icons/Close.svg';
import { ReactComponent as RFLogo } from '../../../assets/Icons/russian_federation.svg';
import { DownloadButton } from '../../../common/ui/DownloadButton/DownloadButton';
import { SearchInput } from '../../../common/ui/SearchInput/SearchInput';
import { useUrlQuery } from '../../../utils/useUrlQuery';
import { CircleProgressIndicator } from '../../../common/ui/ProgressIndicator/CircleProgressIndicator';
import { ReactComponent as Check } from '../../../assets/Icons/Check.svg';
import { IconButton } from '../../../common/ui/IconButton/IconButton';
import { unificationNumber } from '../../../utils/math';
import color from '../../../colors.scss';
import s from './ProgressView.module.scss';

const gradients = [
  {
    id: 'greenWhite',
    rotate: 90,
    colors: [
      { offset: 0, color: color.chartGreenL },
      { offset: 100, color: color.white }
    ]
  }
];

export const RF_VALUE = 'RF';

export const russia = { label: 'Российская Федерация', value: RF_VALUE };

export const ProgressViewAdditional = React.memo(
  function ProgressViewAdditional({ handleOpenGoals, downloadLinks }) {
    const onOpenGoals = useCallback(
      (event) => {
        if (typeof handleOpenGoals === 'function') {
          handleOpenGoals(event);
        }
      },
      [handleOpenGoals]
    );

    return (
      <div className={s.ProgressView__additional}>
        <Button
          variant={Button.SECONDARY}
          onClick={onOpenGoals}
          className={s.ProgressView__goals}
        >
          Цели и показатели
          <ArrowRight className={s.ProgressView__svg} />
        </Button>
        <DownloadButton
          className={s.ProgressView__download}
          popupPosition={'left'}
          data={downloadLinks}
          size={DownloadButton.LARGE}
        />
      </div>
    );
  }
);

export const ProgressView = React.memo(function ProgressView({
  loadingData,
  regionsForSelect,
  selectedRegion,
  className,
  additional,
  financesChartData,
  backgroundColor,
  isAfterJune
}) {
  const [query, setQuery] = useUrlQuery();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => setMenuOpen((state) => !state), [
    setMenuOpen
  ]);

  const selectItem = useCallback(
    (val) => {
      setQuery({ region_code: val });
      setMenuOpen(false);
    },
    [setQuery, setMenuOpen]
  );

  return (
    <div className={classNames(s.ProgressView, className)}>
      {isMenuOpen ? (
        <SelectMenu
          items={regionsForSelect}
          selectedValue={query.region_code}
          selectItem={selectItem}
          toggleMenu={toggleMenu}
        />
      ) : (
        <>
          <div className={classNames(s.progress, s[`_${backgroundColor}`])}>
            <div className={s.progress__RFLogo}>
              <RFLogo />
            </div>
            <p className={s.progress__title} onClick={toggleMenu}>
              <span className={s.progress__titleText}>
                {selectedRegion.label}
              </span>
              <span className={s.progress__titleIcon}>
                <ArrowDown className={s.ProgressView__svg} />
              </span>
            </p>
            {financesChartData && (
              <div className={s.progress__progress}>
                <div className={s.progress__percent}>
                  {isAfterJune
                    ? formatNumber(
                        unificationNumber(
                          financesChartData.cashExpensesPercent,
                          100
                        ),
                        1
                      )
                    : formatNumber(
                        unificationNumber(
                          financesChartData.obligationsPercent,
                          100
                        ),
                        1
                      )}{' '}
                  %
                </div>
                <div className={s.progress__text}>
                  {isAfterJune
                    ? 'Кассового исполнения'
                    : 'Принятия бюджетных обязательств'}
                </div>
              </div>
            )}
            <div className={s.progress__chart}>
              {financesChartData && !loadingData ? (
                <StackedBarChart
                  data={financesChartData.bars}
                  barWidth={50}
                  barGap={1}
                  size={129}
                  sizeWidth={358}
                  total={financesChartData.max}
                  hideLegend
                  isSegmented={false}
                />
              ) : (
                <CircleProgressIndicator />
              )}
            </div>
          </div>
          {additional}
        </>
      )}
    </div>
  );
});

const SelectMenu = ({ items, selectedValue, selectItem, toggleMenu }) => {
  const [searchInput, changeSearch] = useState('');
  const filteredItems = useMemo(() => {
    return items.filter(({ label }) => {
      const trimmedInput = searchInput.toLowerCase().trim();
      return (
        trimmedInput.length === 0 ||
        label.toLowerCase().indexOf(trimmedInput) !== -1
      );
    });
  }, [items, searchInput]);
  const search = useMemo(() => throttle(changeSearch, 500), [changeSearch]);
  return (
    <div className={s.progressView__selectMenu}>
      <div className={s.selectMenu__title}>
        <p className={s.selectMenu__titleName}>Субъекты</p>
        <IconButton onClick={toggleMenu} icon={Close} />
      </div>
      <SearchInput
        className={s.selectMenu__search}
        onChange={(e) => search(e.target.value)}
        onCloseBtnClick={() => changeSearch('')}
        placeholder="Субъект"
      />
      <ul className={s.selectMenu__optionsList}>
        {filteredItems.map(({ label, value }, i) => {
          const isItemSelected = selectedValue === value;
          return (
            <li
              className={classNames(s.selectMenu__option, {
                [s._selected]: isItemSelected
              })}
              onClick={() => selectItem(isItemSelected ? undefined : value)}
              key={i}
            >
              <p>{label}</p>
              {isItemSelected ? (
                <Check className={s.selectMenu__check} />
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
