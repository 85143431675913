import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as CloseImg } from '../../../assets/Icons/Close.svg';
import { ReactComponent as CheckImg } from '../../../assets/Icons/Check.svg';
import s from './TextInput.module.scss';

export function TextInput({
  status,
  errorMessage,
  children,
  disabled,
  className,
  wrapperClassName,
  ...props
}) {
  return (
    <div
      className={classNames(s.TextInput, wrapperClassName, {
        [s._disabled]: disabled
      })}
    >
      <input
        type="text"
        disabled={disabled}
        {...props}
        className={classNames(
          s.TextInput__input,
          {
            [s._error]: status === TextInput.ERROR
          },
          className
        )}
      />
      {children}

      {status === TextInput.SUCCESS ? (
        <CheckImg className={classNames(s.TextInput__icon, s._success)} />
      ) : null}
      {status === TextInput.ERROR ? (
        <>
          <CloseImg className={classNames(s.TextInput__icon, s._error)} />
          <div className={s.TextInput__error}>{errorMessage}</div>
        </>
      ) : null}
    </div>
  );
}

TextInput.ERROR = 'error';
TextInput.SUCCESS = 'success';

TextInput.propTypes = {
  wrapperClassName: PropTypes.string,
  status: PropTypes.oneOf([TextInput.ERROR, TextInput.SUCCESS]),
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.shape(),
  form: PropTypes.shape()
};
