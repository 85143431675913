import React, { useMemo } from 'react';
import qs from 'query-string';
import { addDays } from 'date-fns';
import { useStoreState } from '@proscom/prostore-react';
import { Actions } from '../../../common/RegionPages/Actions/Actions';
import { Button } from '../../../common/ui/Button/Button';
import { PageInventory } from '../../../common/RegionPages/Inventory/PageInventory';
import { AboutContainer } from '../../../common/RegionPages/AboutContainer/AboutContainer';
import { RegionProjects } from '../RegionProjects/RegionProjects';
import { AdditionalIndicators } from '../AdditionalIndicators/AdditionalIndicators';
import { SubjectInfo } from '../SubjectInfo/SubjectInfo';
import { Purchases } from '../Purchases/Purchases';
import { SubjectIndicators } from '../Indicators/Indicators';
import { MainChart } from '../../../common/RegionalBudgetCharts/RegionalBudgetCharts';
import { RegionalRoadmapCards } from '../RegionalRoadmapCards/RegionalRoadmapCards';
import { STORE_AUTH } from '../../../store/stores';
import { User } from '../../../data/user';
import { useWindowSize } from '../../../store/useWindowSize';
import s from './SubjectView.module.scss';

const SubjectView = ({
  region,
  yearMonth,
  projects,
  queryParams,
  selectedFpCode,
  budgetCharts,
  regionalRoadmapQuery
}) => {
  const ws = useWindowSize();
  const isMobile = ws.isMobile;

  const auth = useStoreState(STORE_AUTH);
  const user = User.getUser(auth);

  const inventoryVars = useMemo(
    () => ({
      region_id: region.id
    }),
    [region.id]
  );

  const cuser = region.cusers[0];
  const persons = [];
  if (cuser) {
    persons.push({
      title: 'Руководитель регионального ведомственного проектного офиса',
      name: cuser.description,
      emails: cuser.emails
      // phone: '+7 123 456-78-90'
    });
  }
  if (region.minister) {
    persons.push({
      title:
        region.minister?.individual_data?.occupation || 'Министр образования',
      name: region.minister.name,
      emails: region.minister.emails
      // phone: '+7 1234 56-78-90'
    });
  }

  const federalDate = budgetCharts.federalDate;
  const yesterday = addDays(new Date(), -1);
  const countSmallSchools =
    region.count_organizations && region.count_organizations[0].count;

  return (
    <div className={s.SubjectView}>
      <MainChart {...budgetCharts} />
      <AboutContainer className={s.SubjectView__aboutContainer}>
        <RegionProjects
          projects={projects}
          federalDate={federalDate}
          regionCode={region.code_nalog}
          regionId={region.id}
          selectedFpCode={selectedFpCode}
        />
        <SubjectIndicators
          regionalRoadmapQuery={regionalRoadmapQuery}
          region={region}
        />
        <Purchases
          regionId={region.id}
          federalDate={federalDate}
          totalQuery={budgetCharts.budgetQuery}
        />
        <AdditionalIndicators region={region} />
        <PageInventory
          classNameInventory={s.SubjectView__inventory}
          variables={inventoryVars}
          actualityDate={yesterday}
          isMobile={isMobile}
        />
        <SubjectInfo persons={persons} countSmallSchools={countSmallSchools} />
        {user.role !== User.roles.REGION_ADMIN && (
          <Actions>
            <Button
              variant={Button.SECONDARY}
              to={{
                pathname: '/reports',
                search: qs.stringify({
                  region_code: region.code_nalog,
                  fp: queryParams.fp
                })
              }}
            >
              Отчет
            </Button>
          </Actions>
        )}
      </AboutContainer>
      <RegionalRoadmapCards
        regionalRoadmapQuery={regionalRoadmapQuery}
        region={region}
      />
    </div>
  );
};

export default SubjectView;
