import { useMemo } from 'react';
import {
  URL_QUERY_MONTH,
  URL_QUERY_QUARTER,
  URL_QUERY_YEAR
} from '../store/stores';
import { useActualityDateContext } from '../store/useActualityDateContext';
import { getCurrentMonth, getCurrentYear } from './constants';
import { useUrlQueryParams } from './useUrlQuery';

export const getYearQuarter = (year, quarter) => `${year}-${quarter}`;
export const getYearMonth = (year, month) => `${year}-${month}`;

const periodParams = [URL_QUERY_YEAR, URL_QUERY_QUARTER, URL_QUERY_MONTH];

export function useQueryPeriodFast(query, changeQuery) {
  const callbacks = useMemo(() => {
    const changeMonth = (month) => changeQuery({ month }, true);
    const changeQuarter = (q) => changeQuery({ quarter: q }, true);
    const changeYear = (y) => changeQuery({ year: y }, true);
    const changeYearMonth = (year, month) => changeQuery({ year, month }, true);

    return {
      changeMonth,
      changeQuarter,
      changeYear,
      changeYearMonth
    };
  }, [changeQuery]);

  const actualityDate = useActualityDateContext();

  const year = +query.year || actualityDate?.year || getCurrentYear();
  const quarter = +query.quarter;
  const month = +query.month || actualityDate?.month || getCurrentMonth();

  const yearQuarter = getYearQuarter(year, quarter);
  const yearMonth = getYearMonth(year, month);

  return {
    year,
    quarter,
    month,
    yearQuarter,
    yearMonth,
    ...callbacks
  };
}

export function useQueryPeriod() {
  const [query, changeQuery] = useUrlQueryParams(periodParams);
  return useQueryPeriodFast(query, changeQuery);
}
