import React, { useMemo } from 'react';
import classNames from 'classnames';
import { max as d3max } from 'd3';
import { TabRadio, useStateTabs } from '../../../common/ui/Tab/TabRadio';
import { formatNumber } from '../../../utils/formatNumber';
import LineChart from '../../../common/charts/LineChart/LineChart';
import { getRoundLegendSteps, roundToRound } from '../../../utils/math';
import { ChartWithLegend } from '../../../common/charts/ChartWithLegend';
import { ResizeContainer } from '../../../common/ResizeContainer';
import {
  createQuarterValues,
  fillQuarterValues,
  mapQuarterValues
} from '../../../utils/fillQuarterValues';
import colors from '../../../colors.scss';
import s from './MainCharts.module.scss';

const tabs = ['Достижение результата', 'Характеристика'];
const defaultIndexParams = {
  decimals: 2,
  strokeWidth: 4,
  showCircles: true,
  color: colors.grayDark
};

export function MainChart({ resultData, year, characteristic, actualityDate }) {
  const [tab, tabProps] = useStateTabs(tabs, (x, i) => i);

  const mainChartData = useMemo(() => {
    if (!resultData || (!resultData.values && !resultData.fact_values))
      return null;

    const quarterValues = createQuarterValues();
    fillQuarterValues(quarterValues, resultData.values, (plan) => plan);
    fillQuarterValues(quarterValues, resultData.fact_values, (fact) => ({
      value_fact: fact.value_number
    }));

    return mapQuarterValues(quarterValues, year);
  }, [resultData, year]);

  const current = useMemo(() => {
    if (!mainChartData) return null;

    const planItem = mainChartData.find((item) => item.active);

    return {
      plan: planItem?.values[0],
      fact: planItem?.values[1]
    };
  }, [mainChartData]);

  const unit = resultData?.unit?.name;

  const characteristicUnit = characteristic?.unit?.name;
  const characteristicName = characteristic?.name_full || characteristic?.name;

  const characteristicPlanData = characteristic?.plan_result_characteristics;
  const characteristicFactData = characteristic?.fact_result_characteristics;

  const characteristicChartData = useMemo(() => {
    if (!characteristicPlanData || !characteristicFactData) return null;
    const characteristicData = [];

    characteristicPlanData.forEach((item) => {
      const itemYear = +item.period.start.slice(0, 4);
      characteristicData[item.period.name] = {
        id: item.period_id,
        name: item.period.name,
        values: [item.value_number],
        active: year === itemYear
      };
    });

    characteristicFactData.forEach((item) => {
      characteristicData[item.period.name].values.push(item.value_number);
    });

    return Object.values(characteristicData);
  }, [characteristicPlanData, characteristicFactData, year]);

  const characteristicCurrent = useMemo(() => {
    if (!characteristicChartData) return null;
    characteristicChartData.forEach((x) => console.log(x.name.slice(0, 4)));
    const item = characteristicChartData.find(
      (p) => p.name.slice(0, 4) === year + ''
    );
    return { plan: item.values[0], fact: item.values[1] };
  }, [characteristicChartData, year]);

  if (!mainChartData) return null;

  const data = (tab === 0 ? mainChartData : characteristicChartData) || [];

  const maxPlan = d3max(data, (d) => d.values[0]);
  const maxFact = d3max(data, (d) => d.values[1]);
  const totalMax = roundToRound(d3max([maxPlan, maxFact]));
  const legendSteps = getRoundLegendSteps(totalMax);

  const charTitle = characteristicName && characteristicName.length < 50;

  return (
    <div className={s.MainChart}>
      <div className={s.MainChart__tabs}>
        <TabRadio {...tabProps(0)}>{tabs[0]}</TabRadio>
        {characteristic ? (
          <TabRadio {...tabProps(1)}>
            {charTitle ? characteristicName : tabs[1]}
          </TabRadio>
        ) : null}
      </div>
      <div className={s.MainChart__info}>
        {tab === 0 ? (
          <>
            <div className={s.MainChart__block}>
              <div className={s.MainChart__number}>
                <div className={s.MainChart__dots} />
                <div>{formatNumber(current && current.plan, 2)}</div>
              </div>
              <div className={s.MainChart__text}>
                {unit}
                <br />
                План
              </div>
            </div>
            <div className={s.MainChart__block}>
              <div className={s.MainChart__number}>
                <div className={classNames(s.MainChart__dots, s._blue)} />
                <div>{formatNumber(current && current.fact, 2)}</div>
              </div>
              <div className={s.MainChart__text}>
                {unit}
                <br />
                Факт
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={s.MainChart__block}>
              {characteristicName && !charTitle && (
                <div className={s.MainChart__subtitle}>
                  {characteristicName}
                </div>
              )}
            </div>
            <div className={s.MainChart__block}>
              <div className={s.MainChart__number}>
                <div className={s.MainChart__dots} />
                <div>{formatNumber(characteristicCurrent?.plan, 2)}</div>
              </div>
              <div className={s.MainChart__text}>
                {characteristicUnit}
                <br />
                План
              </div>
            </div>
            <div className={s.MainChart__block}>
              <div className={s.MainChart__number}>
                <div className={classNames(s.MainChart__dots, s._blue)} />
                <div>{formatNumber(characteristicCurrent?.fact, 2)}</div>
              </div>
              <div className={s.MainChart__text}>
                {characteristicUnit}
                <br />
                Факт
              </div>
            </div>
          </>
        )}
      </div>
      <div className={s.MainChart__chart}>
        <ResizeContainer>
          {({ width, height }) => (
            <ChartWithLegend
              width={width}
              height={height}
              paddingRight={24}
              min={0}
              max={totalMax}
              steps={legendSteps}
              unit={tab === 0 ? unit : characteristicUnit}
              legendWidth={58}
              columnData={data.map((item) => item.name)}
            >
              {({ width, height, ...props }) => (
                <LineChart
                  data={data}
                  total={totalMax}
                  sizeWidth={width}
                  sizeHeight={height}
                  actualityDate={actualityDate}
                  circleStrokeWidth={4}
                  circleRadius={4}
                  indices={
                    tab === 0
                      ? [
                          {
                            ...defaultIndexParams,
                            id: 0,
                            name: 'План',
                            unit
                          },
                          {
                            ...defaultIndexParams,
                            id: 1,
                            name: 'Факт',
                            unit,
                            color: colors.chartBlue
                          }
                        ]
                      : [
                          {
                            ...defaultIndexParams,
                            id: 0,
                            name: 'План',
                            unit: characteristicUnit
                          },
                          {
                            ...defaultIndexParams,
                            id: 1,
                            name: 'Факт',
                            unit: characteristicUnit,
                            color: colors.chartBlue
                          }
                        ]
                  }
                  {...props}
                />
              )}
            </ChartWithLegend>
          )}
        </ResizeContainer>
      </div>
    </div>
  );
}

MainChart.propTypes = {};
