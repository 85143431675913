import { BehaviorStore } from '@proscom/prostore';
import { distinctUntilChanged, map } from 'rxjs/operators';

export class PortalsStore extends BehaviorStore {
  constructor() {
    super({});
  }

  get$(item) {
    return this.state$.pipe(
      map((state) => state[item]),
      distinctUntilChanged()
    );
  }

  set(item, node) {
    if (node !== this.state[item]) {
      this.setState({
        [item]: node
      });
    }
  }
}
